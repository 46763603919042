import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-center rounded-t-2xl space-y-6 bg-white w-full absolute overflow-y-auto max-h-[50%] bottom-0 left-0 px-6 pt-6 pb-10" }
const _hoisted_2 = { class: "flex flex-col space-y-6" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "flex items-center space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_normalText = _resolveComponent("normalText")!
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_normalText, { "custom-class": 'font-[700]' }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Recieve Payment ")
          ])),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_AppIcon, {
            "custom-class": 'h-6',
            name: 'close-modal'
          })
        ])
      ]),
      _createElementVNode("div", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalList, (v, i) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(v.link)),
            key: i,
            class: _normalizeClass(` ${
            i + 1 == _ctx.modalList.length ? 'border-none' : ''
          } flex justify-between p-3 items-center border-b border-grey-50`)
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AppIcon, {
                "custom-class": 'h-9',
                name: v.icon
              }, null, 8, ["name"]),
              _createVNode(_component_normalText, { color: 'text-base-black' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(v.text), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _createVNode(_component_AppIcon, {
              "custom-class": '',
              name: 'arrow-right-sidebar'
            })
          ], 10, _hoisted_4))
        }), 128))
      ])
    ])
  ]))
}