<template>
  <auth-layout @go-back="Logic.Common.goBack()" :go-back="true">
    <template v-slot:header-text>Reset Password</template>

    <app-form-wrapper
      :parentRefs="parentRefs"
      ref="formComp"
      class="w-full flex flex-col space-y-4 pt-4 pb-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E] dark:!text-primary-100">
          Input your new password and confirm to continue
        </app-normal-text>
      </div>

      <AppTextField
        :focus-border="'border-primary-400'"
        :type="'password'"
        :has-title="true"
        :custom-class="'!bg-grey-50 '"
        :placeholder="'Input Value'"
        name="Password"
        ref="passwordRef"
        v-model="formData.password"
        :rules="[FormValidations.RequiredRule]"
        required
      >
        <template v-slot:title>New Password </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'padlock'" :customClass="'h-[18px]'" />
          </span>
        </template>
      </AppTextField>

      <AppTextField
        :focus-border="'border-primary-400'"
        :type="'password'"
        :has-title="true"
        :custom-class="'!bg-grey-50 '"
        :placeholder="'Input Value'"
        name="Confirm Password"
        ref="confirmPasswordRef"
        v-model="formData.confirmPassword"
        :rules="[
          FormValidations.RequiredRule,
          FormValidations.customValidator(
            formData.password == formData.confirmPassword,
            'Password and Confirm Password do not match'
          ),
        ]"
        required
      >
        <template v-slot:title>Confirm Password </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'padlock'" :customClass="'h-[18px]'" />
          </span>
        </template>
      </AppTextField>

      <div class="w-full flex-col flex pt-2">
        <AppButton
          :padding="'py-3'"
          :custom-class="`rounded-[40px] ${
            formData.password && formData.confirmPassword
              ? 'opacity-100'
              : 'opacity-50'
          }`"
          :text-color="'text-white'"
          @click.prevent="handleResetPassword"
          :loading="loaderSetup.loading"
        >
          Reset Password
        </AppButton>
      </div>
    </app-form-wrapper>
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import { FormValidations } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import {
  AppTextField,
  AppButton,
  AppNormalText,
  AppIcon,
  AppFormWrapper,
} from "../../../components";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppIcon,
    AppFormWrapper,
    AuthLayout,
  },
  name: "ResetPasswordPage",
  setup() {
    const formComp = ref();

    const formData = reactive({
      password: "",
      confirmPassword: "",
    });

    const handleResetPassword = () => {
      const state = formComp.value?.validate();
      if (state) {
        Logic.Auth.UpdatePasswordForm = {
          password: formData.password,
          old_password: "",
          user_uuid: localStorage.getItem("reset_acc_uuid") || "",
        };

        Logic.Auth.UpdatePassword(true, true);
      }
    };

    return {
      Logic,
      formData,
      formComp,
      FormValidations,
      loaderSetup,
      handleResetPassword,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
