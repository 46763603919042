<template>
  <div class="w-full flex flex-col py-4 pt-3">
    <div class="w-full grid grid-cols-2 gap-3">
      <AppBoxBotton
        v-for="(item, index) in modalList"
        @click="
          Logic.Common.showModal({ show: false });
          Logic.Common.GoToRoute(item.link);
        "
        :icon="item.icon"
        :text="item.text"
        :icon-size="item.icon_size"
        :key="index"
      ></AppBoxBotton>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppIcon from "../AppIcon";
import AppBoxBotton from "../AppBoxBotton";
import normalText from "../AppTypography/normalText.vue";

export default defineComponent({
  components: { AppIcon, normalText, AppBoxBotton },
  name: "addNewOrders",

  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const modalList = ref([
      {
        icon: "add-product",
        text: "products",
        link: "/page/inventory",
        icon_size: "h-[36px]",
      },
      {
        icon: "add-video",
        text: "videos",
        link: "/page/video",
        icon_size: "h-[33px]",
      },
      {
        icon: "add-service",
        text: "services",
        link: "/page/inventory",
        icon_size: "h-[38px]",
      },
      {
        icon: "add-category",
        text: "categories",
        link: "/page/category",
        icon_size: "h-[35px]",
      },
    ]);

    return {
      modalList,
      Logic,
    };
  },
});
</script>
