<template>
  <app-wrapper :class="'!overflow-y-hidden'">
    <auth-layout
      @button-clicked="Logic.Common.GoToRoute('/auth/login')"
      @go-back="Logic.Common.goBack()"
      :go-back="true"
      :right-button="false"
      :hasBottomBorder="false"
    >
      <div class="w-full flex flex-col space-y-[1px]">
        <div class="w-full relative h-[8px] bg-gray-100 rounded-full">
          <div
            class="absolute top-0 left-0 w-[90%] h-[8px] bg-primary-500 rounded-full"
          ></div>
        </div>
        <div class="w-full flex flex-row justify-end z-50">
          <app-normal-text class="!text-grey-500 dark:!text-grey-400">
            90%
          </app-normal-text>
        </div>
      </div>

      <div class="w-full flex flex-col pt-4 space-y-4">
        <app-header-text class="!text-left">
          Select the categories where you spend most to boost cashback in these
          areas.
        </app-header-text>

        <div class="w-full flex flex-col space-y-1">
          <app-normal-text class="!text-left pb-1">
            Which categories do you spend the most money on?
          </app-normal-text>

          <app-normal-text class="!text-left !text-gray-500">
            Choose at least 5 categories.
          </app-normal-text>

          <div class="w-full flex flex-row flex-wrap">
            <div
              class="py-2 pr-2"
              v-for="(category, index) in shoppingCategories()
                .filter((item) => item.is_parent)
                .sort((a, b) => a.name.localeCompare(b.name))"
              :key="index"
            >
              <div
                :class="`border-[1px]  ${
                  !selectedCategory.includes(category.name)
                    ? 'border-gray-300'
                    : 'border-primary-main'
                } rounded-[5px] py-1 px-2 space-x-2 items-center flex flex-row ${
                  !selectedCategory.includes(category.name)
                    ? ''
                    : '!bg-primary-main'
                }`"
                @click="toggleCategory(category.name)"
              >
                <app-normal-text
                  :class="` ${
                    !selectedCategory.includes(category.name)
                      ? '!text-[#240046]'
                      : '!text-white'
                  }`"
                >
                  {{ category.name }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <div
          class="w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black"
          style="
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
        >
          <AppButton
            :padding="'py-3'"
            :custom-class="`rounded-[40px] ${
              selectedCategory.length >= 5 ? 'opacity-100' : 'opacity-50'
            }`"
            :bg-color="'bg-primary-main'"
            :text-color="'text-white'"
            @click="continueButtonClicked"
          >
            Continue
          </AppButton>
        </div>
      </div>
    </auth-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppButton,
  AppHeaderText,
  shoppingCategories,
} from "@shpt/ui-components";

export default defineComponent({
  components: {
    AppWrapper,
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  name: "SpendingCategoriesPage",
  layout: "Onboarding",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategories",
        method: "GetShoplistProductCategories",
        params: [1, 300, "CREATED_AT", "DESC", ""],
        requireAuth: false,
      },
    ],
    tracking_data: {
      lable: "Spending Categories Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Spending Categories",
    });

    localStorage.setItem("user_onboarded", "true");

    const selectedCategory = ref<string[]>([]);

    const toggleCategory = (category: string) => {
      if (selectedCategory.value.includes(category)) {
        selectedCategory.value = selectedCategory.value.filter(
          (item) => item != category
        );
      } else {
        selectedCategory.value.push(category);
      }
    };

    const continueButtonClicked = () => {
      localStorage.setItem(
        "user_interests",
        JSON.stringify(selectedCategory.value)
      );
      Logic.Common.GoToRoute("/auth/onboarding/about");
    };

    return {
      Logic,
      selectedCategory,
      continueButtonClicked,
      shoppingCategories,
      toggleCategory,
    };
  },
});
</script>
