import { Contacts, PermissionStatus } from '@capacitor-community/contacts'
import { Logic } from '.'
import { ref } from 'vue'

export const pickAContact = async (useCountryCode = true) => {
  Logic.User.SaveUserActivity(`Pick Phone Contact`, 'action')
  const permissionState: PermissionStatus = await Contacts.requestPermissions()
  if (permissionState.contacts === 'granted') {
    const response = await Contacts.pickContact({
      projection: {
        phones: true,
      },
    })

    let phoneNumber = ''

    if (response.contact.phones) {
      if (useCountryCode) {
        phoneNumber = Logic.Form.getPhoneNumber(
          '+234',
          response.contact.phones[0]?.number?.replaceAll(' ', '') || '',
        )
      } else {
        phoneNumber =
          response.contact.phones[0]?.number?.replaceAll(' ', '') || ''
      }
    }

    return phoneNumber
  }

  return null
}

export const loaderSetup = ref(Logic.Common.loaderSetup)

export const showSettings = ref(false)
