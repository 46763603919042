import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-start space-x-4 pb-3" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 pb-4 xs:pb-2" }
const _hoisted_7 = { class: "w-full flex flex-row space-x-1 justify-end flex-grow" }
const _hoisted_8 = { class: "font-semibold text-primary-500 dark:!text-primary-200 px-1" }
const _hoisted_9 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Buy Airtime' }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_button, { class: "!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: `beneficiary${
                _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`,
                  "custom-class": "!h-[12px]"
                }, null, 8, ["name"]),
                _createVNode(_component_app_normal_text, { class: "!text-primary-500 dark:!text-primary-100" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Beneficiaries ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_text_field, {
                  placeholder: 'Enter phone number',
                  hasTitle: true,
                  type: 'tel',
                  name: "Phone number",
                  ref: "phoneNumberRef",
                  rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.phoneNumber.length == 11,
                'Phone number must be valid'
              ),
            ],
                  "update-value": _ctx.phoneNumber,
                  "content-rule": {
              addAfterCount: 0,
              characterToAdd: ``,
              max: 11,
            },
                  modelValue: _ctx.phoneNumber,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event))
                }, _createSlots({
                  title: _withCtx(() => [
                    _cache[5] || (_cache[5] = _createTextVNode(" Phone number "))
                  ]),
                  _: 2
                }, [
                  (_ctx.Logic.Common.currentBuildType() != 'web')
                    ? {
                        name: "inner-suffix",
                        fn: _withCtx(() => [
                          _createVNode(_component_app_icon, {
                            name: 'phonebook',
                            customClass: 'h-[18px]',
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchPhone()))
                          })
                        ]),
                        key: "0"
                      }
                    : undefined
                ]), 1032, ["rules", "update-value", "modelValue"]),
                _createVNode(_component_app_checkbox, {
                  modelValue: _ctx.saveAsBeneficiary,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.saveAsBeneficiary) = $event)),
                  standard: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "dark:!text-white" }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" Save as beneficiary ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"]),
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "w-full border-t-[1px] border-grey-50 dark:!border-grey-500" }, null, -1)),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_app_normal_text, { class: "!text-[12px] text-left dark:!text-white" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Select Network Provider ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allNetworks, (network, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex items-center justify-center",
                        key: index
                      }, [
                        _createElementVNode("div", {
                          class: "w-[56px] h-[56px] rounded-full relative cursor-pointer",
                          onClick: ($event: any) => (_ctx.selectedNetwork = network.id)
                        }, [
                          _createVNode(_component_app_image_loader, {
                            "photo-url": network.logo,
                            class: _normalizeClass(`h-[56px] w-[56px] ${
                      _ctx.selectedNetwork == network.id
                        ? 'border-[2px] border-primary-400 rounded-full'
                        : ' rounded-full'
                    } `)
                          }, null, 8, ["photo-url", "class"]),
                          (_ctx.selectedNetwork == network.id)
                            ? (_openBlock(), _createBlock(_component_app_icon, {
                                key: 0,
                                name: 'mark',
                                customClass: 'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '
                              }))
                            : _createCommentVNode("", true)
                        ], 8, _hoisted_5)
                      ]))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    rules: [_ctx.FormValidations.RequiredRule],
                    ref: "amount",
                    name: "Amount",
                    modelValue: _ctx.amountValue,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.amountValue) = $event))
                  }, {
                    title: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "dark:!text-gray-200" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" ₦ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rules", "modelValue"]),
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_app_icon, {
                      name: 'cashback-icon',
                      customClass: 'h-[20px]'
                    }),
                    _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap dark:!text-white' }, {
                      default: _withCtx(() => [
                        _cache[10] || (_cache[10] = _createTextVNode(" You'll get ")),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.getProductPoint(
                        "airtime",
                        _ctx.amountValue?.replace(/,/g, "")
                      ),
                      false,
                      ""
                    )) + " Points", 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }, 8, ["parentRefs"])
          ]),
          _createVNode(_component_fixed_container, { class: "pt-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "bg-color": 'bg-primary-400',
                  "text-color": 'text-white',
                  class: _normalizeClass(`w-full ${_ctx.formIsValid ? '' : 'opacity-50'} `),
                  onClick: _ctx.purchaseAirtime
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["class", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}