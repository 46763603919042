<template>
  <div
    class="flex w-full flex-col justify-center space-y-2"
    @click="pickFiles()"
  >
    <template v-if="isWrapper">
      <div class="flex flex-row relative h-full w-full">
        <slot name="content" />
        <slot />
      </div>
    </template>
    <template v-else>
      <div class="flex justify-center w-full">
        <div
          class="rounded flex flex-row w-fit items-center justify-center relative space-x-2 px-1 py-4 bg-grayBackground border-dashed"
        >
          <app-icon :name="`${iconName}`" :customClass="'h-[20px]'" />
          <app-normal-text
            color="text-base-black"
            customClass="w-full text-left !font-semibold line-clamp-1"
          >
            {{ selectedFileName != "" ? selectedFileName : placeholder }}
          </app-normal-text>
        </div>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AppNormalText from "../AppTypography/normalText.vue";
import AppIcon from "../AppIcon/index.vue";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
  },
  props: {
    placeholder: {
      type: String,
      default: "Upload File",
    },
    iconName: {
      type: String,
      default: "upload",
    },
    accept: {
      type: String,
      default: "*",
    },
    modelValue: {
      required: false,
    },
    isWrapper: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "update:localFileUrl", "update:base64Data"],
  name: "AppFileAttachment",
  setup(props: any, context: any) {
    const files = ref<File[]>();

    const selectedFileName = ref("");

    const fileListArray = ref<any[]>([]);

    const toDataURL = (url: string, callback: any) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    };

    const pickFiles = async () => {
      const filesResponse = await Logic.Common.pickFiles(
        props.accept.split(",")
      );

      files.value = filesResponse;
    };

    watch(files, () => {
      if (files.value) {
        fileListArray.value = [];
        for (let index = 0; index < files.value.length; index++) {
          const file = files.value[index];
          fileListArray.value.push(file);
        }

        context.emit(
          "update:modelValue",
          props.isMultiple ? fileListArray.value : fileListArray.value[0]
        );

        if (!props.isMultiple) {
          selectedFileName.value = files.value[0].name;

          // create readable url
          const fr = new FileReader();
          if (files.value) {
            fr.readAsDataURL(files.value[0]);
            fr.addEventListener("load", () => {
              context.emit(
                "update:localFileUrl",
                fr.result?.toString() ? fr.result?.toString() : ""
              );
              toDataURL(fr.result?.toString() || "", (dataUrl: any) => {
                context.emit("update:base64Data", dataUrl);
              });
            });
          }
        } else {
          for (let index = 0; index < files.value.length; index++) {
            const file = files.value[index];
            const allLocalFileUrls: string[] = [];

            if (file) {
              const fr = new FileReader();
              fr.readAsDataURL(file);
              fr.addEventListener("load", () => {
                allLocalFileUrls.push(fr.result?.toString() || "");
                context.emit("update:localFileUrl", allLocalFileUrls);
              });
            }
          }
        }
      }
    });

    watch(props, () => {
      if (props.placeholder) {
        selectedFileName.value = "";
      }
    });

    onMounted(() => {
      if (props.modelValue) {
        fileListArray.value = props.modelValue;
      }
    });

    const removeFile = (index: number) => {
      fileListArray.value = fileListArray.value.filter((file, fileIndex) => {
        return fileIndex != index;
      });
      context.emit(
        "update:modelValue",
        props.isMultiple ? fileListArray.value : fileListArray.value[0]
      );
    };

    return {
      fileListArray,
      removeFile,
      pickFiles,
      selectedFileName,
    };
  },
});
</script>
