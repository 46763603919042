<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center pb-[130px]"
  >
    <app-icon :customClass="'h-[100px]'" :name="'new-win'" :extension="'png'" />
    <div
      v-if="data.point"
      class="w-full flex flex-row py-4 px-4 rounded-[10px] bg-white bg-opacity-10 items-center justify-center space-x-2"
    >
      <div>
        <app-icon :name="'coin'" :customClass="'h-[44px]'" :extension="'png'" />
      </div>
      <div class="flex flex-col justify-start">
        <app-normal-text
          :custom-class="'uppercase text-[10px]'"
          :color="'text-white'"
        >
          You have claimed
        </app-normal-text>
        <app-header-text :color="'text-white'">
          {{ data.point }} pts
        </app-header-text>
      </div>
    </div>

    <div class="w-[80%] px-4 items-center justify-center flex">
      <app-normal-text
        :color="'!text-white'"
        :customClass="'!font-semibold text-center'"
      >
        {{ data.title }}
      </app-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";
export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        title: string;
        point: number;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const dataRef = toRef(props, "data");
    const showConfetti = async () => {
      const canvas: any = document.getElementById("transparentModal");
      // @ts-ignore
      canvas.confetti =
        canvas.confetti ||
        // @ts-ignore
        (await window.confetti.create(canvas, { resize: true }));

      const duration = 5 * 1000,
        animationEnd = Date.now() + duration,
        defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

      function randomInRange(min: any, max: any) {
        return Math.random() * (max - min) + min;
      }

      const interval: any = setInterval(function () {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);

        // since particles fall down, start a bit higher than random
        // @ts-ignore
        canvas.confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        // @ts-ignore
        canvas.confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 250);
    };

    watch(dataRef, () => {
      setTimeout(() => {
        showConfetti();
      }, 200);
    });

    onMounted(() => {
      setTimeout(() => {
        showConfetti();
      }, 200);
    });

    return {};
  },
});
</script>
