<template>
  <div
    class="w-full flex flex-col space-y-5 py-4 z-50 justify-between h-full pl-4"
  >
    <!-- Referral box -->
    <div class="w-full flex flex-col space-y-2">
      <app-header-text class="!text-base !text-left !font-semibold">
        Refer and earn
      </app-header-text>
      <div
        class="w-full flex flex-col bg-primary-400 rounded-[10px] px-3 py-3 relative cursor-pointer"
        @click="goToPath('/others/referral')"
      >
        <img
          src="/images/referral-coins.svg"
          class="h-[80%] absolute right-[5%] bottom-0"
        />

        <div class="w-full flex flex-col space-y-[2px]">
          <app-normal-text class="!text-left !text-white !text-xs">
            Refer Friends, Earn more
          </app-normal-text>
          <app-normal-text
            class="!font-semibold !text-white text-left !text-sm"
          >
            Get 10% Bonus
          </app-normal-text>
        </div>

        <div class="w-full flex flex-row pt-3">
          <div
            class="px-3 py-[2px] bg-primary-800 rounded-[30px] flex items-center justify-center"
          >
            <app-normal-text class="!text-white !text-[11px]">
              Refer & Earn
            </app-normal-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { ref } from "vue";
import AppImageLoader from "../AppImageLoader";

export default {
  components: {
    AppNormalText,
    AppHeaderText,
    AppIcon,
    AppImageLoader,
  },
  name: "AppWebSideBar",
  setup() {
    const hoverTab = ref("");

    const goToPath = (path: string) => {
      Logic.Common.GoToRoute(path);
    };

    return {
      hoverTab,
      Logic,
      goToPath,
    };
  },
};
</script>
