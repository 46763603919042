import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-1 gap-3 py-3" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentOptions, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "col-span-1 border-[1px] border-gray-100 dark:!border-grey-700 rounded-[8px] py-3 px-4 flex flex-row items-center justify-between space-x-2 relative cursor-pointer",
        onClick: ($event: any) => (item.action())
      }, [
        _createVNode(_component_app_normal_text, { class: "!text-black !font-semibold dark:!text-white" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1024),
        _createVNode(_component_app_icon, {
          name: item.logo,
          customClass: item.logo_size
        }, null, 8, ["name", "customClass"])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}