import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", null, [
    _createElementVNode("img", {
      src: _ctx.qrcodeSrc,
      class: _normalizeClass(`${_ctx.customClass}`)
    }, null, 10, _hoisted_1)
  ]))
}