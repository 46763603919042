<template>
  <auth-layout
    @go-back="Logic.Common.goBack()"
    :go-back="true"
    :hasBottomBorder="hasBottomBorder"
  >
    <template v-slot:header-text>{{ title }}</template>

    <slot name="top-section" />

    <app-form-wrapper
      ref="formComp"
      :parentRefs="parentRefs"
      class="w-full flex flex-col space-y-4 pt-4 pb-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E]" v-if="!hasCustomSubTitle">
          Create a password to complete your account setup
        </app-normal-text>
        <slot name="sub-title" />
      </div>

      <AppTextField
        :focus-border="'border-primary-400'"
        :type="'password'"
        :has-title="true"
        :custom-class="'!bg-grey-50 '"
        :placeholder="'Input Value'"
        :name="'Password'"
        ref="password"
        :rules="[FormValidations.RequiredRule]"
        v-model="formData.password"
      >
        <template v-slot:title>Password </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'padlock'" :customClass="'h-[18px]'" />
          </span>
        </template>
      </AppTextField>

      <AppTextField
        :focus-border="'border-primary-400'"
        :type="'password'"
        :has-title="true"
        :custom-class="'!bg-grey-50 '"
        :name="'Confirm password'"
        ref="confirm_password"
        :rules="[
          FormValidations.RequiredRule,
          FormValidations.customValidator(
            formData.confirm_password == formData.password,
            'Password do not match'
          ),
        ]"
        :placeholder="'Input Value'"
        v-model="formData.confirm_password"
      >
        <template v-slot:title>Re-type Password </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'padlock'" :customClass="'h-[18px]'" />
          </span>
        </template>
      </AppTextField>

      <div class="w-full flex-col flex pt-2">
        <AppButton
          :padding="'py-3'"
          :custom-class="'rounded-[40px]'"
          :bg-color="'bg-primary-main'"
          :text-color="'text-white'"
          @click.prevent="updatePassword()"
          :loading="loaderSetup.loading"
        >
          Continue
        </AppButton>
      </div>
    </app-form-wrapper>
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import {
  AppTextField,
  AppButton,
  AppNormalText,
  AppIcon,
  AppFormWrapper,
} from "../../../components";
import { FormValidations } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Create Password",
    },
    hasBottomBorder: {
      type: Boolean,
      default: true,
    },
    hasCustomSubTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppIcon,
    AppFormWrapper,
    AuthLayout,
  },
  name: "SetPasswordPage",
  setup() {
    const formComp = ref();

    const formData = reactive({
      password: "",
      confirm_password: "",
    });

    const updatePassword = () => {
      const state = formComp.value.validate();

      if (state) {
        Logic.Auth.UpdatePasswordForm = {
          user_uuid: Logic.Auth.AuthUser?.uuid || "",
          old_password: formData.password,
          password: formData.password,
        };

        Logic.Auth.UpdatePassword(true, false)?.then(async (data: any) => {
          if (data) {
            Logic.Auth.SignInForm = {
              email: localStorage.getItem("acc_email") || "",
              password: localStorage.getItem("acc_password") || "",
            };
            await Logic.Auth.SignIn(true);
            Logic.Common.hideLoader();
            Logic.Common.GoToRoute("/auth/verify-phone");
          }
        });
      }
    };

    return {
      Logic,
      formComp,
      formData,
      FormValidations,
      loaderSetup,
      updatePassword,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
