<template>
  <app-wrapper>
    <subpage-layout :title="'Edit Profile'">
      <div class="w-full flex flex-col space-y-2 flex-grow px-4">
        <div
          class="w-full flex flex-col py-4 px-4 rounded-[10px] space-y-4 justify-center items-center"
        >
          <app-file-attachment
            :is-wrapper="true"
            v-model="profileUpdateData.photo"
            v-model:local-file-url="photoUrl"
            :accept="`image/png, image/gif, image/jpeg`"
            class="flex flex-row items-center justify-center !w-auto"
          >
            <template v-slot:content>
              <div
                :style="`background-image: url(${photoUrl});  background-size: cover;
       background-repeat: no-repeat;
       background-position: center;`"
                class="h-[85px] w-[85px] rounded-full bg-gray-100 flex flex-row items-center justify-center border-[1px] border-grey-100"
              >
                <app-icon :name="'image-purple'" :customClass="'h-[15px]'" />
              </div>
            </template>
          </app-file-attachment>
        </div>

        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-4 pt-3"
        >
          <!-- First name -->
          <app-text-field
            :placeholder="'First name'"
            :hasTitle="true"
            :type="'text'"
            ref="firstNameRef"
            v-model="profileUpdateData.first_name"
            :update-value="profileUpdateData.first_name"
            :rules="[FormValidations.RequiredRule]"
            :name="'First name'"
          >
            <template v-slot:title> First name </template>
          </app-text-field>

          <!-- Last name -->
          <app-text-field
            :placeholder="'Last name'"
            :hasTitle="true"
            :type="'text'"
            ref="lastNameRef"
            v-model="profileUpdateData.last_name"
            :update-value="profileUpdateData.last_name"
            :rules="[FormValidations.RequiredRule]"
            :name="'Last name'"
          >
            <template v-slot:title> Last name </template>
          </app-text-field>

          <!-- User name -->
          <div class="w-full flex flex-col space-y-2">
            <app-text-field
              :placeholder="'Your shoppoint handle'"
              :hasTitle="true"
              :type="'text'"
              ref="usernameRef"
              v-model="username"
              :name="'Username'"
              :rules="[
                FormValidations.RequiredRule,
                FormValidations.customValidator(
                  username.indexOf(' ') <= 0,
                  'Username cannot contain white space'
                ),
                FormValidations.Alphanumeric,
              ]"
              :update-value="username"
              :content-rule="{
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              }"
            >
              <template v-slot:inner-prefix>
                <app-normal-text> @ </app-normal-text>
              </template>
              <template v-slot:title> Shoppoint handle </template>
            </app-text-field>
            <div class="w-full flex flex-row justify-end">
              <app-normal-text
                :color="'text-primary-main'"
                v-if="resolvingUsername"
              >
                Resolving handle...
              </app-normal-text>
              <template
                v-if="
                  !resolvingUsername &&
                  username != AuthUser?.username &&
                  username
                "
              >
                <app-normal-text
                  :color="'text-success-main'"
                  v-if="!usernameExist"
                >
                  <span class="px-1">Available</span> ✅
                </app-normal-text>
                <app-normal-text :color="'text-error-main'" v-else>
                  <span class="px-1">Not available</span> ❌
                </app-normal-text>
              </template>
            </div>
          </div>

          <!-- Email -->
          <app-text-field
            :placeholder="'Email'"
            :hasTitle="true"
            :type="'email'"
            :disabled="true"
            :custom-class="'opacity-70 !placeholder-[#313131] dark:!placeholder-grey-100'"
            v-model="profileUpdateData.email"
            :update-value="profileUpdateData.email"
          >
            <template v-slot:title> Email address </template>
          </app-text-field>

          <!-- Gender -->
          <app-select
            v-if="showGender"
            :placeholder="'Select your gender'"
            :hasTitle="true"
            :paddings="'py-3 px-3'"
            :options="genderOption"
            v-model="profileUpdateData.gender"
            :theme="Logic.Common.currentAppTheme()"
            :custom-class="'dark:!bg-grey-800'"
          >
            <template v-slot:title> Gender </template>
          </app-select>

          <!-- Date of birth -->
          <app-text-field
            :placeholder="'Set your date of birth'"
            :hasTitle="true"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                mustBe18OrAbove(profileUpdateData.date_of_birth),
                `You must be 18 years old or above to use Shoppoint`
              ),
            ]"
            v-model="profileUpdateData.date_of_birth"
            :update-value="profileUpdateData.date_of_birth"
            name="Date of birth"
            ref="dob"
            type="date"
            v-if="!AuthUser?.profile?.date_of_birth"
          >
            <template v-slot:title> Date of birth </template>
          </app-text-field>

          <app-text-field
            v-else
            :placeholder="'Set your date of birth'"
            :hasTitle="true"
            :type="'text'"
            :disabled="true"
            :custom-class="'!opacity-70 !placeholder-[#313131] dark:!placeholder-grey-100'"
            v-model="profileUpdateData.date_of_birth"
            :update-value="profileUpdateData.date_of_birth"
          >
            <template v-slot:title> Date of birth </template>
          </app-text-field>
        </app-form-wrapper>

        <div class="!h-[100px]"></div>
      </div>

      <!-- Continue button -->
      <fixed-container>
        <app-button
          :customClass="`w-full ${usernameExist ? '!opacity-60' : ''}`"
          :padding="'py-3 xs:py-3'"
          @click="saveProfile()"
          :loading="loaderSetup.loading"
        >
          Save
        </app-button>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { FormValidations, tabTitle } from "@shpt/ui-components/src/composable";
import { onIonViewDidLeave, onIonViewWillEnter } from "@ionic/vue";
import {
  AppTextField,
  AppIcon,
  AppButton,
  AppNormalText,
  AppSelect,
  AppFileAttachment,
  AppFormWrapper,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import { SelectOption } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppIcon,
    AppButton,
    AppNormalText,
    AppSelect,
    AppFileAttachment,
    AppFormWrapper,
    AppWrapper,
    FixedContainer,
  },
  name: "EditProfilePage",
  middlewares: {
    fetchRules: [
      {
        domain: "Auth",
        property: "SingleUser",
        method: "GetAuthUser",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Edit Profile Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Edit Profile",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const photoUrl = ref("");

    const username = ref("");

    const formComp = ref();

    const showGender = ref(false);

    const usernameExist = ref(false);

    const resolvingUsername = ref(false);

    const genderOption = reactive<SelectOption[]>([
      {
        key: "female",
        value: "Female",
      },
      {
        key: "male",
        value: "Male",
      },
    ]);

    const profileUpdateData = reactive({
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      service_rendered: "",
      photo: "",
      date_of_birth: "",
      gender: "",
    });

    const setDefault = () => {
      profileUpdateData.first_name = AuthUser.value?.first_name || "";
      profileUpdateData.last_name = AuthUser.value?.last_name || "";
      profileUpdateData.email = AuthUser.value?.email || "";
      profileUpdateData.date_of_birth =
        AuthUser.value?.profile?.date_of_birth || "";
      photoUrl.value = AuthUser.value?.profile?.photo_url || "";
      username.value = AuthUser.value?.username || "";
      profileUpdateData.gender = AuthUser.value?.profile?.gender || "";
    };

    onIonViewWillEnter(() => {
      setDefault();
      setTimeout(() => {
        showGender.value = true;
      }, 500);
    });

    onIonViewDidLeave(() => {
      showGender.value = false;
    });

    const saveProfile = () => {
      if (!usernameExist.value) {
        Logic.User.UpdateProfileForm = {
          first_name: profileUpdateData.first_name,
          last_name: profileUpdateData.last_name,
          username: username.value,
          profile_image: profileUpdateData.photo
            ? profileUpdateData.photo
            : undefined,
          date_of_birth: profileUpdateData.date_of_birth,
          gender: profileUpdateData.gender,
        };

        Logic.User.UpdateProfile()?.then((data) => {
          if (data) {
            Logic.Common.showSuccess(
              "Your profile update has been saved",
              () => {
                Logic.Common.showModal({ show: false });
                Logic.Common.goBack();
              }
            );
          }
        });
      }
    };

    watch(username, () => {
      Logic.Common.debounce(() => {
        if (AuthUser.value?.username != username.value) {
          if (username.value.length < 4) {
            usernameExist.value = true;
            return;
          }
          resolvingUsername.value = true;
          usernameExist.value = true;
          Logic.Auth.CheckIfUsernameExist(username.value).then((data) => {
            if (data) {
              usernameExist.value = data.data?.CheckIfUsernameExist || false;
            }
            resolvingUsername.value = false;
          });
        } else {
          usernameExist.value = false;
        }
      });
    });

    const mustBe18OrAbove = (date: string) => {
      const yearDifference = Logic.Common.momentInstance(new Date()).diff(
        Logic.Common.momentInstance(date),
        "years"
      );

      return yearDifference > 17;
    };

    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        setDefault();
        setTimeout(() => {
          showGender.value = true;
        }, 500);
      }
    });

    onUnmounted(() => {
      showGender.value = false;
    });

    return {
      FormValidations,
      profileUpdateData,
      photoUrl,
      username,
      resolvingUsername,
      usernameExist,
      formComp,
      AuthUser,
      loaderSetup,
      genderOption,
      showGender,
      Logic,
      saveProfile,
      mustBe18OrAbove,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
