<template>
  <Teleport to="#modal">
    <transition name="fade" appear>
      <div
        v-if="setup"
        :class="`fixed top-0 w-screen h-screen lg:text-sm innerModal items-end justify-end mdlg:!items-center mdlg:!justify-center md:!items-center md:!justify-center mdlg:text-[12px] text-xs bg-black !bg-opacity-30 dark:!bg-opacity-50 flex flex-col overflow-y-auto `"
        style="z-index: 999999999999999"
        id="innerModal"
        @click.stop="
          setup.preventClose
            ? setup?.closeAction
              ? setup.closeAction()
              : null
            : Logic.Common.showModal({ show: false })
        "
      >
        <div
          @click.stop="null"
          id="confetti-canvas"
          class="absolute z-10 h-[100%] w-full top-0 left-0"
        ></div>

        <div
          class="relative w-full mdlg:!w-[60%] md:!w-[80%] grid grid-cols-12 h-full z-50"
          id="modalContent"
        >
          <!-- Left side -->
          <div
            class="hidden col-span-3 mdlg:!col-span-3 md:!col-span-2 mdlg:!flex md:!flex flex-col sticky top-0"
          ></div>

          <!-- Main section -->
          <div
            class="col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-row justify-center items-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center h-full overflow-y-hidden rounded-t-[20px] mdlg:!rounded-[10px] md:!rounded-[10px] absolute w-full top-0 left-0 mdlg:!relative md:!relative"
          >
            <div
              @click.stop="null"
              :class="`min-h-[100px] w-full  flex h-auto  flex-col relative bg-white dark:!bg-black  dark:border-[1px] dark:border-gray-100 max-h-[94%] mdlg:!max-h-[600px] md:!max-h-[600px] rounded-t-[20px] overflow-y-auto pb-[calc(env(safe-area-inset-bottom))] mdlg:!pb-[calc(16px)] md:!pb-[calc(16px)] mdlg:!rounded-[10px] md:!rounded-[10px]`"
            >
              <div
                :class="`w-full flex flex-row items-center justify-between z-50 pt-4 pb-2 px-4 sticky  top-0  bg-white dark:!bg-black`"
              >
                <div class="flex flex-row items-center space-x-3">
                  <app-header-text :customClass="'!text-base'">
                    {{ setup.title }}
                  </app-header-text>
                </div>

                <app-icon
                  :name="`close-modal${
                    Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`"
                  :customClass="`h-[24px] !cursor-pointer`"
                  v-if="!setup.preventClose"
                  @click.stop="
                    setup.preventClose
                      ? setup?.closeAction
                        ? setup.closeAction()
                        : null
                      : Logic.Common.showModal({ show: false })
                  "
                />
              </div>
              <div class="px-4 w-full flex flex-col">
                <!-- Content -->
                <shoplistType
                  v-if="setup?.type == 'shoplist_type'"
                  :action="setup?.action"
                ></shoplistType>
                <promotionType
                  v-if="setup?.type == 'promotion_type'"
                  :action="setup?.action"
                  :data="setup.extraData"
                >
                </promotionType>

                <addCard v-if="setup.type == 'add_card'" ref="add_card" />
                <sendGiftCard
                  v-if="setup.type == 'send_gift_card'"
                  ref="send_gift_card"
                  :action="setup.action"
                />
                <cardTermsAndCondition
                  v-if="setup.type == 'card_terms_and_condition'"
                  :action="setup.action"
                />

                <filterExpenses
                  v-if="setup.type == 'filter_expenses'"
                  ref="filter_expenses"
                  :action="setup.action"
                />
                <editReceipt
                  v-if="setup.type == 'edit_receipt'"
                  ref="edit_receipt"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <policyContent
                  v-if="setup.type == 'policy_content'"
                  :data="setup.extraData"
                />
                <paymentProvider
                  v-if="setup.type == 'payment_provider'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <info
                  v-if="setup.type == 'info'"
                  ref="info"
                  :data="setup.extraData"
                />

                <addBankAccount
                  v-if="setup.type == 'add_bank_account'"
                  ref="add_bank_account"
                  :action="setup.action"
                />

                <enterTransactionPin
                  v-if="setup.type == 'enter_transaction_pin'"
                  :action="setup.action"
                  :data="setup.extraData"
                />
                <qrCode
                  v-if="setup.type == 'qr_code'"
                  :data="setup.extraData"
                />

                <subscriptionPlan
                  v-if="setup.type == 'subscription_plan'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <topupCashback
                  v-if="setup.type == 'topup_cashback'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <changePassword
                  v-if="setup.type == 'change_password'"
                  ref="change_password"
                  :action="setup.action"
                />

                <payment
                  v-if="setup.type == 'payment'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <requestFeedback
                  v-if="setup.type == 'request_feedback'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <askForPermission
                  v-if="setup.type == 'ask_for_permission'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <newBadge
                  v-if="setup.type == 'new_badge'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <productRewardSetting
                  v-if="setup.type == 'product_reward_setting'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <addDeliveryArea
                  v-if="setup.type == 'add_delivery_area'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <addProducts
                  v-if="setup.type == 'add_products'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <reportReceipt
                  v-if="setup.type == 'report_receipt'"
                  ref="report_receipt"
                  :action="setup.action"
                />
                <addShoplist
                  v-if="setup.type == 'add_shoplist'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <sendInvitation
                  v-if="setup.type == 'send_invitation'"
                  :data="setup.extraData"
                  :action="setup.action"
                />
                <deliveryInfo
                  v-if="setup.type == 'delivery_info'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <businessInfo
                  v-if="setup.type == 'business_info'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <orderInfo
                  v-if="setup.type == 'order_info'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <joinBusiness
                  v-if="setup.type == 'join_business'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <verifyEmailWithOTP
                  v-if="setup.type == 'verify_email_with_otp'"
                  :action="setup.action"
                />

                <badgeInfo
                  v-if="setup.type == 'badge_info'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <storeAdminUpdate
                  v-if="setup.type == 'store_admin_update'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <chooseInstagramVideo
                  v-if="setup.type == 'choose_instagram_video'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <addShopifyProducts
                  v-if="setup.type == 'add_shopify_products'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <addProductToShoplists
                  v-if="setup.type == 'add_product_to_shoplists'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <productTypes
                  v-if="setup.type == 'product_types'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <addWoocommerceProducts
                  v-if="setup.type == 'add_woocommerce_products'"
                  :data="setup.extraData"
                  :action="setup.action"
                />

                <verifyPhone
                  v-if="setup.type == 'verify_phone'"
                  :action="setup.action"
                />

                <verifyEmail v-if="setup.type == 'verify_email'" />
                <!-- Spacer -->
              </div>

              <!-- Padding -->
              <div :class="`'h-[20px]`"></div>
            </div>
          </div>

          <!-- Right side -->
          <div
            class="hidden col-span-3 mdlg:!col-span-3 md:!col-span-2 mdlg:!flex flex-col sticky top-0"
          ></div>
        </div>
      </div>
    </transition>
    <!-- Bottom blank -->
    <div
      :class="`w-full fixed bottom-0 left-0  z-[99999999999999999] bg-white`"
      style="height: calc(env(safe-area-inset-bottom))"
    ></div>
  </Teleport>
</template>
<script lang="ts">
import showRecieveOptions from "./showRecieveOptions.vue";
import addNewOrders from "./addNewOrders.vue";
import { Logic } from "../../composable";
import { AppHeaderText } from "../AppTypography";
import AppIcon from "../AppIcon";
import shoplistType from "./shoplistType.vue";
import promotionType from "./promotionType.vue";
import addCard from "./addCard.vue";
import enterTransactionPin from "./enterTransactionPin.vue";
import qrCode from "./qrCode.vue";
import addBankAccount from "./addBankAccount.vue";
import changePassword from "./changePassword.vue";
import subscriptionPlan from "./subscriptionPlan.vue";
import payment from "./payment.vue";
import requestFeedback from "./requestFeedback.vue";
import newBadge from "./newBadge.vue";
import reportReceipt from "./reportReceipt.vue";
import sendGiftCard from "./sendGiftCard.vue";
import topupCashback from "./topupCashback.vue";
import cardTermsAndCondition from "./cardTermsAndCondition.vue";
import filterExpenses from "./filterExpenses.vue";
import editReceipt from "./editReceipt.vue";
import paymentProvider from "./paymentProvider.vue";
import policyContent from "./policyContent.vue";
import info from "./info.vue";
import verifyEmail from "./verifyEmail.vue";
import askForPermission from "./askForPermission.vue";
import productRewardSetting from "./productRewardSetting.vue";
import addDeliveryArea from "./addDeliveryArea.vue";
import addProducts from "./addProducts.vue";
import addShoplist from "./addShoplist.vue";
import deliveryInfo from "./deliveryInfo.vue";
import orderInfo from "./orderInfo.vue";
import verifyPhone from "./verifyPhone.vue";
import verifyEmailWithOTP from "./verifyEmailWithOTP.vue";
import businessInfo from "./businessInfo.vue";
import badgeInfo from "./badgeInfo.vue";
import sendInvitation from "./sendInvitation.vue";
import joinBusiness from "./joinBusiness.vue";
import storeAdminUpdate from "./storeAdminUpdate.vue";
import chooseInstagramVideo from "./chooseInstagramVidoe.vue";
import addProductToShoplists from "./addProductToShoplists.vue";
import productTypes from "./productTypes.vue";
import addShopifyProducts from "./addShopifyProducts.vue";
import addWoocommerceProducts from "./addWoocommerceProducts.vue";
import { ModalSetup } from "../../types";

export default {
  components: {
    AppHeaderText,
    AppIcon,
    showRecieveOptions,
    addNewOrders,
    shoplistType,
    promotionType,
    addCard,
    enterTransactionPin,
    qrCode,
    addBankAccount,
    changePassword,
    subscriptionPlan,
    payment,
    requestFeedback,
    newBadge,
    reportReceipt,
    sendGiftCard,
    topupCashback,
    cardTermsAndCondition,
    filterExpenses,
    editReceipt,
    paymentProvider,
    policyContent,
    info,
    verifyEmail,
    askForPermission,
    productRewardSetting,
    addDeliveryArea,
    addProducts,
    addShoplist,
    deliveryInfo,
    orderInfo,
    verifyPhone,
    verifyEmailWithOTP,
    businessInfo,
    badgeInfo,
    sendInvitation,
    joinBusiness,
    storeAdminUpdate,
    chooseInstagramVideo,
    addProductToShoplists,
    productTypes,
    addShopifyProducts,
    addWoocommerceProducts,
  },
  props: {
    canClose: {
      type: Boolean,
      default: true,
    },
    close: {
      type: Function,
      required: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    setup: {
      type: Object as () => ModalSetup,
    },
  },
  name: "AppModal",
  setup(props: any) {
    const closeModal = () => {
      if (props.canClose) {
        props.close();
      }
    };

    return {
      closeModal,
      showRecieveOptions,
      Logic,
    };
  },
};
</script>
