<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Search and add -->
    <div class="w-full flex flex-row space-x-2 items-center">
      <AppTextField :placeholder="`Search products`" v-model="searchQuery">
        <template v-slot:inner-prefix>
          <AppIcon :custom-class="''" :name="'search'"></AppIcon>
        </template>
        <!-- <template v-slot:inner-suffix>
          <AppIcon :custom-class="''" :name="'filter-icon'"></AppIcon>
        </template> -->
      </AppTextField>
    </div>

    <div class="w-full flex flex-col">
      <app-switch :items="productSwitch" v-model="selectedSwitch" />
    </div>

    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[300px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <!-- Products -->
    <div class="w-full flex flex-col space-y-3" v-else>
      <template v-if="products.length > 0">
        <div
          class="w-full flex flex-col"
          v-if="selectedSwitch == 'affiliate_products'"
        >
          <div
            class="w-full flex flex-row rounded-[8px] bg-primary-50 px-3 py-3"
          >
            <app-normal-text
              class="!text-primary-500 dark:!text-primary-500 !text-left"
            >
              You won't be able to customize your offer once you add an
              affiliate product.
            </app-normal-text>
          </div>
        </div>

        <app-virtual-scroller
          :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
          :data="products"
          :pagination="paginatorInfo"
          :fetchMore="fetchMoreProducts"
          :direction="'vertical'"
          ref="productVirtualScrollerRef"
        >
          <template #item-content="{ index, item }">
            <div class="col-span-1 flex flex-col" :key="index">
              <app-product
                @click="toggleAddedProducts(item)"
                :data="item"
                :show-commission="selectedSwitch == 'affiliate_products'"
                :class="` px-3 py-2 rounded-[6px] ${
                  selectedProducts.filter((eachItem) => eachItem.id == item.id)
                    .length > 0
                    ? '!border-primary-300 !border-[2px] '
                    : '!border-grey-100 !border-[1px] '
                }`"
              />
            </div>
          </template>
          <template #skeleton-loaders>
            <div class="col-span-1 flex flex-col">
              <div
                class="!h-[200px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
              ></div>
            </div>
          </template>
        </app-virtual-scroller>
      </template>

      <div
        v-else
        class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
      >
        <app-empty-state
          :title="'No Products Here'"
          :subTitle="'All available products will show up here'"
        >
        </app-empty-state>
      </div>
    </div>

    <!-- Spacer -->
    <div class="h-[50px]"></div>

    <!-- Bottom button -->
    <div
      class="w-full flex flex-row py-4 bg-white dark:bg-black sticky bottom-0 z-[99999999]"
    >
      <app-button class="w-full !py-3" @click.prevent="selectProducts()">
        Add Product{{ selectedProducts.length > 1 ? "s" : "" }} ({{
          selectedProducts.length
        }})
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import AppSwitch from "../AppSwitch";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { AppTextField } from "../AppForm";
import AppProduct from "../AppProduct";
import { PaginatorInfo, ShoplistProductPaginator } from "../../gql/graphql";
import AppEmptyState from "../AppEmptyState";
import AppButton from "../AppButton";
import AppVirtualScroller from "../AppVirtualScroller";

interface BaseProduct {
  base_image_url: string;
  title: string;
  price: number;
  resale_commission: string;
  cashback: string;
  available_stock: number;
  merchant: {
    id: number;
    name: string;
    profile_image_url: string;
  };
  id: string;
}

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppSwitch,
    AppTextField,
    AppProduct,
    AppEmptyState,
    AppButton,
    AppVirtualScroller,
  },
  props: {
    data: {
      type: Object as () => {
        selected: {
          id: string;
          image_url: string;
          title: string;
          price: number;
          business_id: number;
        }[];
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const ManyShoplistProducts = ref<ShoplistProductPaginator>();

    const isLoading = ref(false);

    const selectedProducts = ref<
      {
        id: string;
        image_url: string;
        title: string;
        price: number;
        business_id: number;
      }[]
    >([]);

    const selectedSwitch = ref("your_products");

    const paginatorInfo = ref<PaginatorInfo | undefined>(undefined);

    const productSwitch = reactive([
      {
        name: "Your Products",
        key: "your_products",
      },
      {
        name: "Affiliate Products",
        key: "affiliate_products",
      },
    ]);

    const products = reactive<BaseProduct[]>([]);

    const setProducts = () => {
      products.length = 0;

      ManyShoplistProducts.value?.data?.forEach((product) => {
        let moveForward = true;

        if (!Logic.Auth.CurrentBusinessIsSuperAdmin()) {
          let subBusinessIsPartOfDeliveryLocation = false;

          product.delivery_areas?.forEach((eachItem) => {
            if (
              eachItem.delivery_location?.business_admin?.id ==
                Logic.Auth.AuthUser?.business_admin?.id &&
              !subBusinessIsPartOfDeliveryLocation
            ) {
              subBusinessIsPartOfDeliveryLocation = true;
            }
          });

          if (!subBusinessIsPartOfDeliveryLocation) {
            moveForward = false;
          }
        }

        if (moveForward) {
          const generalReward = product.rewards.filter(
            (item) => item.quantity == "-1"
          );
          products.push({
            base_image_url: product.primary_image_url,
            title: product.name,
            price: parseFloat(product.regular_price),
            available_stock: product.stock_quantity,
            cashback: generalReward[0]?.percentage,
            merchant: {
              id: parseInt(product.business?.id || "0"),
              name: product.business?.business_name || "",
              profile_image_url: product.business?.photo_url || "",
            },
            resale_commission: product.affiliate_commission || "",
            id: product.id,
          });
        }
      });

      paginatorInfo.value = ManyShoplistProducts.value?.paginatorInfo;
    };

    const getProducts = () => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
      AND: {
        column: STATUS
        operator: EQ
        value: "active"
      }
    }`
        : `AND: {
        column: STATUS
        operator: EQ
        value: "active"
      }`;
      if (selectedSwitch.value == "your_products") {
        isLoading.value = true;
        Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`,
          true
        ).then((data: any) => {
          isLoading.value = false;

          if (data) {
            ManyShoplistProducts.value = data;
            setProducts();
          }
        });
      } else {
        isLoading.value = true;
        Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: NEQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                       ${searchString}
                    }`,
          true,
          "true"
        ).then((data: any) => {
          isLoading.value = false;

          if (data) {
            ManyShoplistProducts.value = data;
            setProducts();
          }
        });
      }
    };

    const fetchMoreProducts = (nextPage: number) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
      AND: {
        column: STATUS
        operator: EQ
        value: "active"
      }
    }`
        : "";

      if (selectedSwitch.value == "your_products") {
        return Logic.Shoplist.GetShoplistProducts(
          nextPage,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`,
          true
        )
          .then((response) => {
            if (response) {
              const existingData = JSON.parse(
                JSON.stringify(ManyShoplistProducts.value)
              );

              if (existingData.data && response.data) {
                existingData.data = existingData.data.concat(response.data);
                existingData.paginatorInfo = response.paginatorInfo;
              }

              ManyShoplistProducts.value = existingData;

              return true;
            }
          })
          .catch(() => {
            return false;
          });
      } else {
        return Logic.Shoplist.GetShoplistProducts(
          nextPage,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: NEQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`,
          true,
          "true"
        )
          .then((response) => {
            if (response) {
              const existingData = JSON.parse(
                JSON.stringify(ManyShoplistProducts.value)
              );

              if (existingData.data && response.data) {
                existingData.data = existingData.data.concat(response.data);
                existingData.paginatorInfo = response.paginatorInfo;
              }

              ManyShoplistProducts.value = existingData;

              return true;
            }
          })
          .catch(() => {
            return false;
          });
      }
    };

    const toggleAddedProducts = (data: BaseProduct) => {
      if (
        selectedProducts.value.filter((item) => item.id == data.id).length > 0
      ) {
        selectedProducts.value = selectedProducts.value.filter(
          (eachItem) => eachItem.id != data.id
        );
      } else {
        selectedProducts.value.push({
          id: data.id,
          image_url: data.base_image_url,
          title: data.title,
          price: data.price,
          business_id: data.merchant.id,
        });
      }
    };

    const selectProducts = () => {
      if (props.action) {
        props.action(selectedProducts.value);
      }
    };

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        getProducts();
      }, 400);
    });

    watch(ManyShoplistProducts, () => {
      if (ManyShoplistProducts.value) {
        paginatorInfo.value = ManyShoplistProducts.value.paginatorInfo;
      }
      setProducts();
    });

    watch(selectedSwitch, () => {
      getProducts();
    });

    onMounted(() => {
      selectedProducts.value = props.data?.selected || [];
      getProducts();
    });

    return {
      searchQuery,
      ManyShoplistProducts,
      selectedSwitch,
      productSwitch,
      products,
      isLoading,
      paginatorInfo,
      selectedProducts,
      toggleAddedProducts,
      selectProducts,
      fetchMoreProducts,
    };
  },
});
</script>
