<template>
  <div
    class="flex flex-col items-center pt-4 z-[400] w-full justify-center !font-inter fixed left-0 bottom-0 mdlg:!hidden md:!hidden bg-white dark:bg-black border-t-[1px] border-t-gray-200 dark:!border-t-gray-500"
    :style="`
        padding-bottom: calc(env(safe-area-inset-bottom) + 12px) !important;`"
  >
    <app-cart v-if="Logic.Common.currentAccountType() == 'client'" />

    <div :class="`w-full  flex flex-row items-center justify-between `">
      <router-link
        v-for="(tab, index) in tabs"
        :key="index"
        :to="tab.path"
        class="flex flex-col space-y-[6px] items-center justify-center"
        :style="`width: ${100 / tabs.length}% ;`"
      >
        <app-icon
          :name="
            hoverTab == tab.icon || tabIsActive(tab.routeTag)
              ? `bottom-bar/${tab.icon}-active${
                  Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`
              : `bottom-bar/${tab.icon}${
                  Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`
          "
          :custom-class="` h-[20px] `"
        />

        <app-normal-text
          :color="` ${
            tabIsActive(tab.routeTag)
              ? 'text-primary-main dark:!text-white'
              : 'text-gray-500 dark:!text-gray-300'
          } `"
          :customClass="`!text-[11px] ${
            tabIsActive(tab.routeTag) ? '!font-semibold' : ''
          }`"
        >
          {{ tab.name }}</app-normal-text
        >
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";
import { ref } from "vue";
import { Logic } from "../../composable";
import AppCart from "../AppCart";

export default {
  components: {
    AppIcon,
    AppNormalText,
    AppCart,
  },
  props: {
    tabIsActive: {
      type: Function,
      required: true,
    },
    options: {
      required: false,
    },
    accountType: {
      type: String,
      default: "",
    },
    spaceBetween: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array as () => any[],
    },
  },
  name: "AppBottomBar",
  setup() {
    const hoverTab = ref("");

    return {
      hoverTab,
      Logic,
    };
  },
};
</script>
