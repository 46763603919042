import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col space-y-4"
}
const _hoisted_2 = { class: "flex flex-col justify-end absolute z-50 bottom-0 h-full right-0 w-full p-3 space-y-2" }
const _hoisted_3 = { class: "flex space-x-2" }
const _hoisted_4 = { class: "flex items-center space-x-1" }
const _hoisted_5 = { class: "flex items-center space-x-1" }
const _hoisted_6 = { class: "flex justify-start flex-row pt-1" }
const _hoisted_7 = { class: "w-full flex flex-row space-x-3 items-start h-[72px]" }
const _hoisted_8 = { class: "flex flex-col h-[72px]" }
const _hoisted_9 = { class: "flex flex-col space-y-1 justify-between !h-full" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_11 = { class: "flex space-x-3" }
const _hoisted_12 = { class: "flex items-center space-x-2" }
const _hoisted_13 = { class: "flex items-center space-x-2" }
const _hoisted_14 = {
  key: 1,
  class: "flex flex-row"
}
const _hoisted_15 = { class: "absolute right-0 top-[10%]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_AppImageLoader = _resolveComponent("AppImageLoader")!
  const _component_AppProgress = _resolveComponent("AppProgress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.mode == 'grid')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppImageLoader, {
            "custom-class": 'relative rounded-[8px] w-full h-[210px] px-2 py-2 flex flex-col justify-end space-y-3 relative p',
            "photo-url": $props.imageUrl
          }, {
            default: _withCtx(() => [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "rounded-[8px] z-30 absolute top-0 bg-[#0000004D] h-full right-0 w-full" }, null, -1)),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_AppIcon, {
                      "custom-class": 'h-2',
                      name: 'viewed'
                    }),
                    _createVNode(_component_AppNormalText, { color: 'text-white' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.viewed), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_AppIcon, {
                      "custom-class": 'h-2.5',
                      name: 'length'
                    }),
                    _createVNode(_component_AppNormalText, { color: 'text-white' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.length), 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_AppNormalText, {
                  color: 'text-white',
                  size: 'small'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.title), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_app_badge, {
                    color: 'outline-white',
                    customClass: '!py-[1px] !text-[10px] '
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.buttonText), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          }, 8, ["photo-url"])
        ]))
      : _createCommentVNode("", true),
    ($props.mode == 'list')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(`${$props.customClass} flex items-start w-full justify-between rounded-lg py-2 relative`)
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_AppImageLoader, {
                "custom-class": 'rounded-lg w-[72px] h-[72px] ',
                "photo-url": $props.imageUrl
              }, null, 8, ["photo-url"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_AppNormalText, { color: 'text-black !line-clamp-1' }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.title), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_AppIcon, {
                      "custom-class": 'h-[10]',
                      name: 'viewed-black'
                    }),
                    _createVNode(_component_AppNormalText, { color: 'text-grey-700' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.viewed), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_AppIcon, {
                      "custom-class": 'h-[16px]',
                      name: 'length-black'
                    }),
                    _createVNode(_component_AppNormalText, { color: 'text-grey-700' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.length), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              ($props.progress)
                ? (_openBlock(), _createBlock(_component_AppProgress, {
                    key: 0,
                    progress: $props.progress
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.progress) + "% ", 1)
                    ]),
                    _: 1
                  }, 8, ["progress"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_app_badge, {
                      color: 'outline-black',
                      customClass: '!py-[1px] !text-[10px]'
                    }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Category ")
                      ])),
                      _: 1
                    })
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            (!$props.rightSuffix)
              ? (_openBlock(), _createBlock(_component_AppIcon, {
                  key: 0,
                  "custom-class": $props.rightIconSize,
                  name: $props.rightIcon
                }, null, 8, ["custom-class", "name"]))
              : _renderSlot(_ctx.$slots, "rightSuffix", { key: 1 })
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}