import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'What should we call your friend?',
        hasTitle: true,
        type: 'text',
        padding: 'py-4 px-4',
        name: "Friend name",
        ref: "friend_name",
        modelValue: _ctx.formData.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.name) = $event)),
        rules: [_ctx.FormValidations.RequiredRule]
      }, {
        title: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Name ")
        ])),
        _: 1
      }, 8, ["modelValue", "rules"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter email',
        rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.EmailRule],
        modelValue: _ctx.formData.email,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.email) = $event)),
        hasTitle: true,
        type: "email",
        padding: 'py-4 px-4',
        name: "Friend Email",
        ref: "friend_email"
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Email Address ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: "w-full py-3",
          onClick: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Send ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}