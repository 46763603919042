import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-center h-[150px]"
}
const _hoisted_4 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_5 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_8 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_9 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_11 = { class: "w-full grid grid-cols-3 gap-3 px-4 pt-2" }
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_challenge = _resolveComponent("app-challenge")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Badges' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] dark:!text-primary-100 px-4 !text-sm" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Earned Badges ")
                ])),
                _: 1
              }),
              (_ctx.badges.filter((item) => item.won).length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_app_empty_state, {
                      title: '',
                      subTitle: 'No earned badges yet'
                    })
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.filter((item) => item.won), (badge, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(`flex flex-col space-y-2 justify-center items-center w-auto cursor-pointer`),
                      key: index,
                      onClick: ($event: any) => (_ctx.showBadgeInfo(badge.data, badge.won))
                    }, [
                      _createVNode(_component_app_image_loader, {
                        photoUrl: badge.image_url,
                        class: "!h-[90px] !w-[90px] mdlg:!h-[100px] mdlg:!w-[100px] rounded-full"
                      }, null, 8, ["photoUrl"]),
                      _createVNode(_component_app_normal_text, { class: "!text-center line-clamp-1 !font-semibold !text-[11px] mdlg:!text-xs" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(badge.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ], 8, _hoisted_6))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] px-4 !text-sm dark:!text-primary-100" }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Top Challenges ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.challenges, (challenge, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-[280px] flex flex-col"
                    }, [
                      _createVNode(_component_app_challenge, {
                        data: challenge,
                        class: "cursor-pointer",
                        onClick: ($event: any) => (
                    _ctx.Logic.Common.GoToRoute(
                      `/others/challenges/${challenge.uuid}`
                    )
                  )
                      }, null, 8, ["data", "onClick"])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] px-4 !text-sm dark:!text-primary-100" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Badges to unlock ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.filter((item) => !item.won), (badge, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "col-span-1 flex flex-col space-y-1 justify-center items-center cursor-pointer",
                    onClick: ($event: any) => (_ctx.showBadgeInfo(badge.data, badge.won))
                  }, [
                    _createVNode(_component_app_image_loader, {
                      photoUrl: '/images/lock-badge.svg',
                      class: "!h-[75px] !w-[75px] mdlg:!h-[85px] mdlg:!w-[85px] rounded-full"
                    }),
                    _createVNode(_component_app_normal_text, { class: "!text-center line-clamp-1 !font-semibold !text-[10px] mdlg:!text-[12px]" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(badge.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 8, _hoisted_12))
                }), 128))
              ])
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h-[60px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}