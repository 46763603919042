<template>
  <div class="flex items-center w-full justify-between rounded-lg py-2.5">
    <div class="flex items-center space-x-3 w-3/4">
      <div>
        <div
          :class="`w-[40px] h-[40px] rounded-full ${bgColor} grid place-content-center`"
        >
          <AppIcon
            :custom-class="iconSize"
            :name="isSuccess ? 'success-icon' : isError ? 'failure-icon' : icon"
          ></AppIcon>
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <AppNormalText :color="upperTextColor" :custom-class="'font-[700]'">
          <slot name="upperText" />
        </AppNormalText>

        <AppNormalText :color="'text-grey-700'">
          <slot name="bottomText" />
        </AppNormalText>
      </div>
    </div>

    <div v-if="isSuccess || isError">
      <AppNormalText :custom-class="'font-[700]'">N{{ amount }} </AppNormalText>
      <div class="flex items-center space-x-2">
        <div
          :class="`w-[5px] h-[5px] rounded-full ${
            isSuccess ? 'bg-green-500' : isError ? 'bg-red-500' : ''
          }`"
        ></div>
        <AppNormalText
          :color="`${
            isSuccess ? 'text-green-500' : isError ? 'text-red-500' : ''
          }`"
        >
          Successful
        </AppNormalText>
      </div>
    </div>
    <slot v-if="!isSuccess && !isError" name="rightSuffix" />
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppIcon,
  },
  props: {
    amount: {
      type: String,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    isSuccess: {
      type: Boolean,
      default: false,
    },
    upperTextColor: {
      type: String,
      default: "text-black",
    },
    bgColor: {
      type: String,
      default: "bg-grey-50",
    },
    iconSize: {
      type: String,
      default: "h-4",
    },
    icon: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppList",
};
</script>
