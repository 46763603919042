import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full invisible flex flex-row items-center justify-center border-[1px] border-primary-500 `)
}
const _hoisted_2 = { class: "w-full flex flex-col px-4" }
const _hoisted_3 = { class: "w-full grid grid-cols-3 gap-2 border-b-[1px] border-[#EFE8F5] dark:border-grey-700" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "absolute w-full h-[4px] rounded-t-[20px] bg-primary-400 bottom-0 left-0"
}
const _hoisted_6 = { class: "w-full flex flex-col space-y-0" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2 pt-4 pb-1 px-3 bg-white rounded-[10px] dark:bg-black" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-[2px] items-center justify-center" }
const _hoisted_9 = { class: "w-full flex flex-row space-x-[5px] items-center justify-center pt-2" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "w-full flex flex-col space-y-2 pt-4 pb-4 px-3 bg-white dark:bg-black rounded-[10px] !-mt-[10px]" }
const _hoisted_12 = {
  key: 0,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_13 = {
  key: 1,
  class: "h-[200px] w-full flex flex-row items-center justify-center text-center px-4"
}
const _hoisted_14 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col items-center justify-start space-y-2 fixed h-screen !pt-0 top-0 left-0 z-[9999999999999] bg-black  px-4`)
}
const _hoisted_15 = {
  class: "w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_16 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_17 = { class: "w-[50px]" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_19 = { class: "w-full absolute top-0 left-0 h-full flex flex-col rounded-[22px] overflow-y-auto" }
const _hoisted_20 = { class: "w-full flex flex-col h-full" }
const _hoisted_21 = {
  class: "w-full flex flex-col pt-3 absolute bottom-0 left-0 px-4 z-[9999999999]",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_App_icon = _resolveComponent("App-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_expenses_line_chart = _resolveComponent("app-expenses-line-chart")!
  const _component_app_expenses_transaction = _resolveComponent("app-expenses-transaction")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_interactive = _resolveComponent("app-image-interactive")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { centerTitle: 'Expenses' }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_App_icon, {
              name: 'help',
              customClass: 'h-[18px]'
            })
          ])
        ]),
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spendOptions, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "col-span-1 pb-3 flex items-center justify-center relative cursor-pointer",
                  key: index,
                  onClick: ($event: any) => (_ctx.selectedSpend = item.key)
                }, [
                  _createVNode(_component_app_normal_text, {
                    class: _normalizeClass(`!text-[12px]  ${
                  item.key == _ctx.selectedSpend
                    ? '!font-bold'
                    : '!text-grey-700 dark:!text-grey-400'
                }`)
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1032, ["class"]),
                  (item.key == _ctx.selectedSpend)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                    : _createCommentVNode("", true)
                ], 8, _hoisted_4))
              }), 128))
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_app_normal_text, { class: "!text-[12px] !text-primary-400 !font-semibold text-center dark:!text-primary-200" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Total Expenses ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_header_text, { class: "!font-bold text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalAmount, false, "ngn")), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periodFilter, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(`px-2 py-[2px] rounded-[50px] cursor-pointer ${
                _ctx.selectedFilter == item.key
                  ? 'bg-primary-50 border-[1px] border-primary-300 dark:bg-primary-500 dark:border-primary-50'
                  : '!bg-[#F7F7F7] border-[1px] border-[#F7F7F7] dark:!bg-grey-700 dark:border-grey-50'
              }`),
                    key: index,
                    onClick: ($event: any) => (_ctx.selectFilter(item.key))
                  }, [
                    _createVNode(_component_app_normal_text, {
                      class: _normalizeClass(`${
                  _ctx.selectedFilter == item.key
                    ? '!text-primary-400 dark:!text-primary-50'
                    : '!text-gray-600 dark:!text-grey-50'
                } !text-[11px]`)
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ], 10, _hoisted_10))
                }), 128))
              ]),
              _createVNode(_component_app_expenses_line_chart, { data: _ctx.expensesChartData }, null, 8, ["data"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_app_header_text, {
                class: _normalizeClass('!text-base')
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Transaction History ")
                ])),
                _: 1
              }),
              (_ctx.expensesTransactions.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.expensesTransactions, (transaction, index) => {
                      return (_openBlock(), _createBlock(_component_app_expenses_transaction, {
                        key: index,
                        class: "cursor-pointer",
                        data: transaction,
                        onClick: ($event: any) => (transaction.handler())
                      }, null, 8, ["data", "onClick"]))
                    }), 128))
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                    _createVNode(_component_app_empty_state, {
                      title: 'No Transactions Yet',
                      subTitle: 'Your transactions will show up here'
                    })
                  ]))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ]),
          (_ctx.receiptInfoSetup.show)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("div", {
                        class: _normalizeClass(`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full flex flex-row items-center justify-center border-[1px] border-white cursor-pointer`),
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.receiptInfoSetup.show = false))
                      }, [
                        _createVNode(_component_app_icon, {
                          name: 'close-white',
                          customClass: 'h-[12px]'
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _createVNode(_component_app_header_text, { color: '!text-white' }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Snapped Receipt ")
                        ])),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_app_image_interactive, {
                      url: _ctx.receiptInfoSetup.image_url || '',
                      customClass: ''
                    }, null, 8, ["url"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_21, [
                  _createVNode(_component_app_button, {
                    customClass: `w-full`,
                    padding: 'py-3 xs:py-3',
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showReceiptInfo(_ctx.receiptInfoSetup.data)))
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" See Receipt Details ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}