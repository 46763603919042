<template>
  <div class="flex flex-col justify-between p-4 h-full">
    <div class="flex flex-col space-y-4">
      <div class="flex justify-between items-center">
        <AppHeaderText>More</AppHeaderText>
        <slot name="close" />
      </div>
      <div class="w-full h-[1px] bg-grey-50"></div>
      <div class="w-full flex flex-col space-y-3">
        <div
          class="flex justify-between pr-2 items-center py-2"
          v-for="(item, index) in sideBarRoutes"
          :key="index"
        >
          <div class="flex items-center space-x-3">
            <AppIcon
              :custom-class="`${item.icon.size}`"
              :name="`${item.icon.name}`"
            ></AppIcon>
            <AppNormalText :color="'text-base-black'">
              {{ item.name }}
            </AppNormalText>
          </div>
          <AppIcon :custom-class="''" :name="'arrow-right-sidebar'"></AppIcon>
        </div>
      </div>
    </div>

    <AppButton
      :paddings="'py-3'"
      :bg-color="'bg-red-50'"
      :text-color="'text-red-500'"
    >
      Logout
    </AppButton>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import { reactive } from "vue";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppIcon,
  },
  props: {
    customClass: {
      type: String,
      required: false,
    },
  },
  emits: ["last-text-event"],
  setup() {
    const sideBarRoutes = reactive([
      {
        name: "Account",
        icon: {
          name: "single-user",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Admin",
        icon: {
          name: "admin-user",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Earnings",
        icon: {
          name: "dollar-circle",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Categories",
        icon: {
          name: "grid-sidebar",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Settings",
        icon: {
          name: "settings-sidebar",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Help",
        icon: {
          name: "help-circle-black",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "About Us",
        icon: {
          name: "check-verified",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Privacy Policy",
        icon: {
          name: "file-shield",
          size: "h-[18px]",
        },
        route_path: "#",
      },
      {
        name: "Terms of use",
        icon: {
          name: "file",
          size: "h-[18px]",
        },
        route_path: "#",
      },
    ]);
    return {
      sideBarRoutes,
    };
  },
  name: "AppSidebar",
};
</script>
