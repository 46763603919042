import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full col-span-full flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full items-center flex flex-row justify-between px-2" }
const _hoisted_3 = {
  key: 0,
  class: "w-full grid grid-cols-3 gap-3"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 1,
  class: "w-full grid grid-cols-3 gap-3"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-center" }
const _hoisted_8 = { class: "w-full flex flex-row flex-wrap items-center justify-start" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "h-[38px] w-[38px] rounded-md flex items-center justify-center !bg-grey-50 dark:!bg-gray-900",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToPage('prev')))
      }, [
        _createVNode(_component_app_icon, {
          name: "arrow-left",
          "custom-class": "h-[14px]"
        })
      ]),
      _createVNode(_component_app_normal_text, {
        "custom-class": "!font-semibold underline cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectView()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.currentMonth), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", {
        class: "h-[38px] w-[38px] rounded-md flex items-center justify-center bg-grey-50 dark:!bg-gray-900",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToPage('next')))
      }, [
        _createVNode(_component_app_icon, {
          name: "arrow-right-black",
          "custom-class": "h-[14px]"
        })
      ])
    ]),
    (_ctx.viewType == 'years_12')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.years12, (year, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "py-4 flex flex-row items-center col-span-1 justify-center rounded-md hover:bg-grey-100 dark:!hover:bg-gray-700",
              onClick: ($event: any) => (_ctx.selectYear(year)),
              key: index
            }, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(year), 1)
                ]),
                _: 2
              }, 1024)
            ], 8, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.viewType == 'year')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthsOptions, (month, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "py-4 flex flex-row items-center col-span-1 justify-center rounded-md hover:bg-grey-100 dark:!hover:bg-gray-700",
              onClick: ($event: any) => (_ctx.selectYearMonth(index + 1)),
              key: index
            }, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(month), 1)
                ]),
                _: 2
              }, 1024)
            ], 8, _hoisted_6))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.viewType == 'month')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysHeaders, (day, index) => {
              return (_openBlock(), _createBlock(_component_app_normal_text, {
                key: index,
                "custom-class": "!font-semibold flex items-center justify-center !text-grey-800 !text-[11px] w-[14.2857142857%] dark:!text-gray-200"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(day), 1)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(_ctx.firstDayPosition).keys()], (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-[14.2857142857%] flex flex-col items-center justify-center py-[6px] px-[6px]",
                key: index
              }))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([...Array(_ctx.monthDaysCount).keys()], (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`w-[14.2857142857%] flex flex-col items-center justify-center py-[6px] px-[6px]  ${
            _ctx.disableDate(
              _ctx.Logic.Common.momentInstance(
                `${
                  (item + 1).toString().length == 1 ? `0${item + 1}` : item + 1
                } ` + _ctx.currentMonth
              ).add(1, 'hours')
            ) && _ctx.preventBackDate
              ? 'opacity-[50%]'
              : ''
          }`),
                key: index,
                onClick: ($event: any) => (
            _ctx.disableDate(
              _ctx.Logic.Common.momentInstance(
                `${
                  (item + 1).toString().length == 1 ? `0${item + 1}` : item + 1
                } ` + _ctx.currentMonth
              ).add(1, 'hours')
            ) && _ctx.preventBackDate
              ? ''
              : _ctx.selectDate(item + 1)
          )
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(`w-[36px] h-[36px] rounded-md ${
              _ctx.dateIsSelected(item + 1)
                ? 'bg-gray-800 dark:!bg-gray-200'
                : 'bg-gray-100 dark:!bg-gray-700'
            }  flex justify-center items-center`)
                }, [
                  _createVNode(_component_app_normal_text, {
                    color: `${
                _ctx.dateIsSelected(item + 1)
                  ? '!text-white dark:!text-black'
                  : '!text-gray-800 dark:!text-gray-200'
              } `
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item + 1), 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ], 2)
              ], 10, _hoisted_9))
            }), 128))
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}