<template>
  <div
    :class="`w-full flex flex-col space-y-2  bottom-0 left-0 items-center justify-center pt-4  ${
      isRelative ? 'relative' : 'fixed'
    } `"
  >
    <app-normal-text class="!text-[#240046] dark:!text-primary-200">
      Or continue with
    </app-normal-text>

    <div class="w-full flex flex-col space-y-3 pt-1">
      <!-- Google -->
      <app-button
        :bgColor="'!bg-grey-50 dark:!bg-grey-800'"
        :text-color="'!text-black dark:!text-white'"
        :padding="'py-3'"
        @click="authenticateGoogle"
      >
        <span class="flex flex-row space-x-2 items-center">
          <app-icon name="google-logo" :customClass="'!h-[20px]'" />
          <app-normal-text class="!text-black pt-[1px] dark:!text-white">
            {{
              fromAction == "signUp"
                ? "Sign up with Google"
                : "Sign in with Google"
            }}
          </app-normal-text>
        </span>
      </app-button>

      <!-- Apple -->
      <app-button
        :bgColor="'!bg-grey-50 dark:!bg-grey-800'"
        :text-color="'!text-black dark:!text-white'"
        :padding="'py-3'"
        @click="authenticateApple"
        v-if="canUseApp"
      >
        <span class="flex flex-row space-x-2 items-center">
          <app-icon name="apple-logo" :customClass="'!h-[20px]'" />
          <app-normal-text class="!text-black pt-[2px] dark:!text-white">
            {{
              fromAction == "signUp"
                ? "Sign up with Apple"
                : "Sign in with Apple"
            }}
          </app-normal-text>
        </span>
      </app-button>
    </div>
  </div>
</template>

<script lang="ts">
import { getPlatforms } from "@ionic/vue";
import { AppButton, AppIcon, AppNormalText } from "../../components";
import { computed, defineComponent, onMounted, reactive } from "vue";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from "@capacitor-community/apple-sign-in";
import { Logic } from "../../index";
import { authMoveForward, isBusinessFocused } from "../../composable";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppButton,
  },
  name: "SSOComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    fromAction: {
      type: String,
      default: "signUp",
    },
    isRelative: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    onMounted(() => {
      GoogleAuth.initialize();
    });

    const formData = reactive({
      email: "",
      first_name: "",
      last_name: "",
    });

    const options: SignInWithAppleOptions = {
      clientId: "com.getshoppoint.app",
      redirectURI: "https://app.getshoppoint.com",
      scopes: "email name",
      state: Logic.Common.makeid(10),
      nonce: "nonce",
    };

    const authenticateUser = (userId: string) => {
      if (props.fromAction == "signUp") {
        Logic.Auth.SignUpForm = {
          email: formData.email,
          first_name: formData.first_name,
          last_name: formData.last_name,
          is_sso: true,
          sso_id: userId,
        };

        Logic.Auth.SignUp(true)?.then((data) => {
          if (data?.SignUp) {
            localStorage.setItem(`social_${userId}`, formData.email);
            if (!data?.SignUp.has_password) {
              Logic.Common.GoToRoute("/auth/set-password");
            } else {
              if (!Logic.Auth.AuthUser?.phone_verified_at) {
                Logic.Common.GoToRoute("/auth/verify-phone");
                return;
              } else {
                if (Logic.Common.currentAccountType() == "client") {
                  Logic.Common.GoToRoute("/auth/onboarding/savings-target");
                } else {
                  Logic.Common.GoToRoute("/onboarding");
                }
              }
            }
          }
        });
      } else {
        Logic.Auth.SignInForm = {
          email:
            formData.email || localStorage.getItem(`social_${userId}`) || "",
          sso_id: userId,
        };

        Logic.Auth.SignIn(true)?.then(async (data) => {
          if (data?.SignIn) {
            Logic.Auth.GetAuthUser();
            Logic.Common.hideLoader();
            if (!data?.SignIn.user.has_password) {
              Logic.Common.GoToRoute("/auth/set-password");
              return;
            }

            authMoveForward(data?.SignIn.user);
          }
        });
      }
    };

    const canUseApp = computed(() => {
      return (
        getPlatforms()[0] != "android" &&
        Logic.Common.currentBuildType() == "mobile"
      );
    });

    const authenticateGoogle = async () => {
      try {
        const response = await GoogleAuth.signIn();
        formData.email = response.email;
        formData.first_name = response.givenName;
        formData.last_name = response.familyName;

        authenticateUser(response.id);
      } catch (error) {
        //
      }
    };

    const authenticateApple = () => {
      SignInWithApple.authorize(options)
        .then((result: SignInWithAppleResponse) => {
          // Handle user information
          // Validate token with server and create new session

          const response = result.response;

          formData.email = response.email || "";
          formData.first_name = response.givenName || "";
          formData.last_name = response.familyName || "";

          authenticateUser(response.user || "");
        })
        .catch((error: any) => {
          // Handle error
          console.log(error);
        });
    };

    return {
      canUseApp,
      getPlatforms,
      authenticateGoogle,
      authenticateApple,
    };
  },
});
</script>
