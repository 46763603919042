import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 h-full flex-grow px-4" }
const _hoisted_2 = { class: "w-full flex flex-col items-start space-y-2" }
const _hoisted_3 = { class: "w-full flex flex-col items-start space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_nav_card = _resolveComponent("app-nav-card")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, {
    title: 'Support',
    mainSectionClass: "!h-fit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_header_text, { customClass: 'text-left !text-base' }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode(" Email Us ")
            ])),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.emailUs, (item, index) => {
            return (_openBlock(), _createBlock(_component_app_nav_card, {
              data: item,
              onClick: ($event: any) => (item.action()),
              key: index,
              hasMasterLink: false
            }, null, 8, ["data", "onClick"]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_header_text, { customClass: 'text-left !text-base' }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Social media ")
            ])),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socialMedia, (item, index) => {
            return (_openBlock(), _createBlock(_component_app_nav_card, {
              data: item,
              key: index,
              onClick: ($event: any) => (item.action()),
              hasMasterLink: false
            }, null, 8, ["data", "onClick"]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}