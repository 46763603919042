<template>
  <app-wrapper>
    <subpage-layout
      :title="'Brands by category'"
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
      :showBottomPadding="false"
    >
      <template v-slot:extra-topbar-row>
        <!-- Banner ad -->
        <div class="w-full flex flex-col px-4 pb-1">
          <app-banner-ad />
        </div>
        <!-- Categories -->
        <div class="w-full flex flex-col pl-4">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
            id="categoryContainerShoplistBrands"
          >
            <div class="flex flex-row py-2 pr-4">
              <div
                class="flex flex-row cursor-pointer pr-2"
                v-for="(category, index) in categoryOptions"
                :key="index"
                @click="selectedCategory = category.key"
                :id="`category${category.key}`"
              >
                <div
                  :class="` ${
                    selectedCategory == category.key
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-gray-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`"
                >
                  <app-normal-text
                    :class="`!whitespace-nowrap ${
                      selectedCategory == category.key
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-50'
                    }`"
                  >
                    {{ category.value }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-2 px-4">
        <div class="w-full flex flex-col pt-4">
          <app-virtual-scroller
            :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
            :data="ManyBrandsByCategory?.data || []"
            :pagination="ManyBrandsByCategory?.paginatorInfo"
            :fetchMore="fetchMoreBrands"
            :fetchNew="fetchNewBrands"
            :direction="'vertical'"
            ref="productVirtualScrollerRef"
          >
            <template #item-content="{ index, item }">
              <app-business
                :item="item"
                :key="index"
                @click="Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`)"
              />
            </template>
            <template #skeleton-loaders>
              <div class="col-span-1">
                <div
                  class="!h-[180px] mdlg:!h-[180px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
              <div class="col-span-1">
                <div
                  class="!h-[180px] mdlg:!h-[180px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
            </template>
          </app-virtual-scroller>
        </div>
        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppVirtualScroller,
  AppBannerAd,
  AppBusiness,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";
import { SelectOption } from "@shpt/ui-components/src/types";
import { scrollToSpecificItem } from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppWrapper,
    AppVirtualScroller,
    AppBannerAd,
    AppBusiness,
  },
  name: "ShoppingBrandsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategoriesWithProducts",
        method: "GetCategoriesWithProducts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyBrandsByCategory",
        method: "GetBrandsByCategory",
        params: [1, 10, false],
        requireAuth: true,
        ignoreProperty: true,
        useRouteQuery: true,
        queries: ["key"],
      },
    ],
    tracking_data: {
      lable: "Brands By Category Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Brands By Category",
    });

    const categoryOptions = reactive<SelectOption[]>([]);

    const selectedCategory = ref("All");
    const productVirtualScrollerRef = ref();

    const ManyCategoriesWithProducts = ref(Logic.Shoplist.ManyCategoriesWithProducts);
    const ManyBrandsByCategory = ref(Logic.Shoplist.ManyBrandsByCategory);

    const fetchNewBrands = () => {
      const categoryId =
        categoryOptions.find((category) => category.key === selectedCategory.value)
          ?.extras || "0";

      return Logic.Shoplist.GetBrandsByCategory(parseInt(categoryId), 1, 10, false)
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreBrands = (nextPage: number) => {
      const categoryId =
        categoryOptions.find((category) => category.key === selectedCategory.value)
          ?.extras || "0";

      return Logic.Shoplist.GetBrandsByCategory(parseInt(categoryId), nextPage, 10, true)
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyBrandsByCategory)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyBrandsByCategory = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const setCategory = () => {
      categoryOptions.length = 0;
      categoryOptions.push({
        key: "All",
        value: "All Categories",
      });
      ManyCategoriesWithProducts.value?.forEach((item) => {
        categoryOptions.push({
          key: item.uuid,
          value: item.name,
          extras: item.id.toString(),
        });
      });
    };

    const focusOnCategory = (key: string) => {
      scrollToSpecificItem("categoryContainerShoplistBrands", `category${key}`);
    };

    onIonViewWillEnter(() => {
      const queryKey = Logic.Common.route?.query.key as string;
      selectedCategory.value = queryKey == "0" ? "All" : queryKey;
      setCategory();
      setTimeout(() => {
        focusOnCategory(selectedCategory.value);
      }, 500);
    });

    watch(selectedCategory, () => {
      // Empty ManyBrandsByCategory
      Logic.Shoplist.ManyBrandsByCategory = undefined;
      productVirtualScrollerRef.value?.resetList();
      focusOnCategory(selectedCategory.value);
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("ManyBrandsByCategory", ManyBrandsByCategory);
      Logic.Shoplist.watchProperty(
        "ManyCategoriesWithProducts",
        ManyCategoriesWithProducts
      );
      if (Logic.Common.currentBuildType() == "web") {
        const queryKey = Logic.Common.route?.query.key as string;
        selectedCategory.value = queryKey == "0" ? "All" : queryKey;
        setCategory();
        setTimeout(() => {
          focusOnCategory(selectedCategory.value);
        }, 500);
      }
    });

    return {
      Logic,
      selectedCategory,
      categoryOptions,
      ManyBrandsByCategory,
      productVirtualScrollerRef,
      fetchNewBrands,
      fetchMoreBrands,
    };
  },
});
</script>
