<template>
  <subpage-layout :title="'FAQs'" mainSectionClass="!h-fit">
    <template v-slot:extra-topbar-row>
      <div class="w-full flex flex-col pl-4">
        <div
          class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
        >
          <div class="flex flex-row space-x-3 pr-4">
            <div
              v-for="(item, index) in filters"
              :key="index"
              :class="`px-4 py-1 ${
                selectedFilter == item.id
                  ? 'bg-primary-main'
                  : 'bg-primary-50 dark:bg-gray-700'
              }  rounded-[20px]  flex flex-row items-center justify-center`"
              @click="selectedFilter = item.id"
            >
              <app-normal-text
                :color="`${
                  selectedFilter == item.id
                    ? 'text-white'
                    : 'text-primary-main dark:text-gray-50'
                }`"
                :custom-class="'whitespace-nowrap'"
              >
                {{ item.title }}
              </app-normal-text>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      class="w-full flex flex-col space-y-7 flex-grow pt-3 px-3"
      v-if="questionsAndAnwsers"
    >
      <div
        class="w-full flex flex-row space-x-3 items-start"
        v-for="(item, index) in questionsAndAnwsers"
        :key="index"
        @click="selectedAnswer = item.id"
      >
        <div class="w-[25px]">
          <app-icon
            :name="selectedAnswer == item.id ? 'faq-open' : 'faq-close'"
            :customClass="'h-[23px]'"
          />
        </div>
        <div class="w-full flex flex-col space-y-4">
          <app-normal-text :customClass="'!text-xs !font-semibold'">
            {{ item.title }}
          </app-normal-text>
          <div
            class="w-full flex flex-row border-l-[1px] border-secondary-400 px-3"
            v-if="selectedAnswer == item.id"
          >
            <app-normal-text :customClass="'!text-left'" v-html="item.content">
            </app-normal-text>
          </div>
        </div>
      </div>
      <div class="!h-[70px]"></div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { AppNormalText, AppIcon } from "../../../../components";
import { Logic } from "../../../../index";
import SubpageLayout from "../../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    SubpageLayout,
  },
  name: "FAQsIndexPage",
  setup() {
    const ClientAppContent = ref(Logic.User.ClientAppContent);

    const filters = reactive<
      {
        title: string;
        id: number;
      }[]
    >([]);

    const selectedFilter = ref(0);

    const selectedAnswer = ref(0);

    const questionsAndAnwsers = reactive<
      {
        title: string;
        content: string;
        id: number;
      }[]
    >([]);

    const setFAQs = () => {
      filters.length = 0;

      const faqContent: {
        content: {
          question: string;
          answer: string;
        }[];
        label: string;
      }[] = JSON.parse(ClientAppContent.value?.faqs_page || "[]");

      if (faqContent) {
        faqContent.forEach((item, index) => {
          filters.push({
            title: item.label,
            id: index,
          });
        });
      }
    };

    const setQuestions = () => {
      if (filters.length) {
        const faqContent: {
          content: {
            question: string;
            answer: string;
          }[];
          label: string;
        }[] = JSON.parse(ClientAppContent.value?.faqs_page || "[]");

        questionsAndAnwsers.length = 0;

        faqContent[selectedFilter.value]?.content?.forEach((content, index) => {
          questionsAndAnwsers.push({
            content: content.answer,
            title: content.question,
            id: index,
          });
        });

        selectedAnswer.value = 0;
      }
    };

    watch(selectedFilter, () => {
      setQuestions();
    });

    watch(ClientAppContent, () => {
      setFAQs();
      setQuestions();
    });

    onMounted(() => {
      Logic.User.watchProperty("ClientAppContent", ClientAppContent);
      setFAQs();
      setQuestions();
    });

    return {
      questionsAndAnwsers,
      Logic,
      selectedAnswer,
      selectedFilter,
      filters,
    };
  },
});
</script>
