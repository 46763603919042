<template>
  <div
    class="w-[165px] mdlg:!w-[240px] h-fit cursor-pointer"
    v-if="item"
    @click="Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`)"
  >
    <div
      class="w-[165px] mdlg:!w-[240px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2"
    >
      <div>
        <app-image-loader
          :photo-url="item.photo_url || ''"
          :class="'!w-[80px] !h-[80px] !rounded-full'"
        />
      </div>
      <div class="w-full flex flex-col space-y-1 items-center justify-center">
        <app-normal-text :customClass="'!text-sm !font-semibold !line-clamp-1'">
          {{ item.business_name }}
        </app-normal-text>
        <div class="flex flex-row space-x-1 items-center">
          <app-normal-text :customClass="'!text-gray-500 !text-[12px]'">
            @{{ item.business_tag }}
          </app-normal-text>
          <app-icon :name="'verified'" class="!h-[14px]" />
        </div>
      </div>

      <!-- Follow / unfollo button -->
      <div class="w-full flex flex-row items-center justify-center">
        <app-button
          @click.stop="followUnfollowBusinessInner"
          :customClass="`!w-auto !px-4 !py-1 !text-[11px] ${
            item.is_following
              ? '!bg-primary-50 !text-primary-main'
              : '!bg-primary-main !text-white'
          }`"
        >
          {{ item.is_following ? "Following" : "Follow" }}
        </app-button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import { Business } from "@shpt/logic/src/gql/graphql";
import { Logic } from "../../";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import { followUnfollowBusiness } from "../../composable/user";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppImageLoader,
    AppButton,
  },
  props: {
    item: Object as () => Business,
  },
  setup(props) {
    const followUnfollowBusinessInner = () => {
      if (props.item) {
        followUnfollowBusiness(props.item);
      }
    };

    return {
      Logic,
      followUnfollowBusinessInner,
    };
  },
  name: "AppBusiness",
});
</script>
