import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col  space-y-3 bg-white dark:bg-black rounded-[8px] border-[1px] border-grey-50 cursor-pointer`)
}
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between px-3 pt-3" }
const _hoisted_3 = { class: "flex flex-row space-x-2 items-start" }
const _hoisted_4 = { class: "w-[40px]" }
const _hoisted_5 = {
  key: 0,
  class: "h-[40px] w-[40px] rounded-full bg-primary-50 items-center justify-center flex"
}
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "flex flex-col space-y-1" }
const _hoisted_8 = { class: "flex flex-col h-[60px] justify-between" }
const _hoisted_9 = { class: "flex flex-row" }
const _hoisted_10 = { class: "w-full flex flex-col px-3 pb-3 border-t-[1px] border-grey-50 pt-3" }
const _hoisted_11 = { class: "flex flex-row space-x-1" }
const _hoisted_12 = { class: "font-semibold text-black dark:text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_badge = _resolveComponent("app-badge")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (!_ctx.item.is_image)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_app_icon, {
                      name: _ctx.item.icon,
                      customClass: 'h-[20px] xs:h-[18px]'
                    }, null, 8, ["name"])
                  ]))
                : (_openBlock(), _createBlock(_component_app_image_loader, {
                    key: 1,
                    "photo-url": _ctx.item.icon,
                    "enforce-thumbnail": _ctx.item.enforce_thumbnail,
                    customClass: 'h-[40px] w-[40px] rounded-full border-[1px] border-grey-100'
                  }, null, 8, ["photo-url", "enforce-thumbnail"]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_normal_text, {
                color: 'text-[#240046]',
                customClass: '!font-semibold  capitalize !text-left'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.title), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_normal_text, { class: "text-grey-700 !text-left" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.item.subtitle), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "text-grey-700 !text-left" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.item.datetime), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_app_normal_text, {
              color: 'text-[#2A2A2A] dark:!text-white',
              customClass: '!font-bold text-right !text-sm'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.total_spend), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_app_badge, {
                color: _ctx.item.status_color
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item.status), 1)
                ]),
                _: 1
              }, 8, ["color"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_app_normal_text, {
              color: 'text-grey-700 dark:!text-gray-400',
              customClass: '!whitespace-nowrap '
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createTextVNode(" Earnings: ")),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.item.points) + " Pts (" + _toDisplayString(_ctx.item.amount) + ")", 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}