import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center justify-around space-x-1 z-40" }
const _hoisted_2 = ["id", "onUpdate:modelValue", "disabled", "onKeyup", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.numberOfInput, (index) => {
      return (_openBlock(), _createElementBlock("span", {
        key: index + '' + _ctx.uniqueKey
      }, [
        _withDirectives(_createElementVNode("input", {
          id: '' + _ctx.uniqueKey + index,
          "onUpdate:modelValue": ($event: any) => ((_ctx.otps[index - 1]) = $event),
          type: "tel",
          class: _normalizeClass(`${_ctx.customClass} rounded-lg w-16 h-16 text-lg text-center focus:outline-none  custom-border focus:border-primary-400 dark:focus:border-primary-200 text-black dark:!text-white border border-primary-50 bg-grey-50 dark:!bg-grey-900`),
          disabled: _ctx.isDisabled,
          onKeypress: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onKeyPress && _ctx.onKeyPress(...args))),
          onKeyup: [
            _withKeys(($event: any) => (_ctx.focusInputByRef('' + _ctx.uniqueKey + (index + 1))), ["right"]),
            _withKeys(($event: any) => (_ctx.focusInputByRef('' + _ctx.uniqueKey + (index - 1))), ["left"]),
            _withKeys(($event: any) => (_ctx.focusInputByRef('' + _ctx.uniqueKey + (index - 1))), ["delete"])
          ],
          onPaste: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onPaste && _ctx.onPaste(...args))),
          onInput: ($event: any) => {
          _ctx.onInput($event, '' + _ctx.uniqueKey + (index + 1));
          _ctx.setValue();
        }
        }, null, 42, _hoisted_2), [
          [_vModelText, _ctx.otps[index - 1]]
        ])
      ]))
    }), 128))
  ]))
}