<template>
  <normalText>
    <span :class="`${customClass}`" ref="counter">00:00</span>
  </normalText>


</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import normalText from "../AppTypography/normalText.vue";
export default defineComponent({
  components: { normalText },
  props: {
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    minuteToCount: {
      type: Number,
      required: false,
      default: 5
    }
  },
  name: "AppCountdownTimer",

  setup(props) {
    const counter: any = ref(undefined)
    onMounted(() => {
      var d = new Date();

      // Add minutes to date
      d.setMinutes(d.getMinutes() + props.minuteToCount)

      // Count by 1 seconds
      var x = setInterval(function () {
        var now = new Date().getTime();
        var distance = d.getTime() - now;

        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (counter.value != undefined || counter.value != null) {
          counter.value.innerHTML = ((minutes).toString().length > 1 ? minutes : "0" + minutes) + ":" + seconds;
          if (distance < 0) {
            clearInterval(x);
            counter.value.innerHTML = "00:00";
          }
        }
      }, 1000);

    })

    return { counter }
  },
});
</script>
