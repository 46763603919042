import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2" }
const _hoisted_2 = { class: "w-full py-4 px-4 bg-gray_50 rounded-[12px] items-center justify-center space-y-1 flex flex-col" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 px-0 py-3 bg-gray_50 rounded-[14px]" }
const _hoisted_4 = { class: "px-4 flex flex-row items-center space-x-2 justify-between" }
const _hoisted_5 = { class: "flex flex-col space-y-1" }
const _hoisted_6 = { class: "px-4 flex pb-4 flex-row items-center space-x-2 justify-between" }
const _hoisted_7 = { class: "flex flex-col space-y-1" }
const _hoisted_8 = {
  key: 0,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_9 = { class: "w-full flex flex-row justify-between" }
const _hoisted_10 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin  h-5 w-5  text-shop_purple_400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_11 = { class: "w-full flex flex-col space-y-3 px-0 py-3 items-center justify-center bg-gray_50 rounded-[14px]" }
const _hoisted_12 = { class: "flex flex-row space-x-2" }
const _hoisted_13 = {
  key: 1,
  class: "w-full flex flex-col pt-1 xs:pb-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_normal_text, { color: 'text-shop_dark_400' }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Amount to pay ")
        ])),
        _: 1
      }),
      _createVNode(_component_app_header_text, { "custom-class": '!font-extrabold !text-xl' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data.amount, true, "ngn")), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_normal_text, {
        customClass: 'w-full px-4  pb-2   border-b-[1px] border-opacity-30 border-[#BDA3D7]',
        color: 'text-shop_dark_400'
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Send money to ")
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_app_normal_text, { color: 'text-shop_dark_200 ' }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Bank Name ")
            ])),
            _: 1
          }),
          _createVNode(_component_app_normal_text, { color: 'text-shop_dark_400 !font-semibold ' }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Kuda Microfinance Bank ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_app_normal_text, { color: 'text-shop_dark_200 ' }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Account Number ")
            ])),
            _: 1
          }),
          _createVNode(_component_app_normal_text, { color: 'text-shop_dark_400 !font-semibold ' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.funding_account?.account_number), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_app_button, {
          padding: 'px-3 py-2',
          border: 'border-b-[3px]',
          customClass: '!flex flex-row space-x-2 items-center',
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyAccountNumber(_ctx.data.funding_account?.account_number)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.accountCopied ? "Copied!" : "Copy") + " ", 1),
            _createVNode(_component_app_icon, {
              name: 'copy-white',
              customClass: 'h-[17px] pl-2'
            })
          ]),
          _: 1
        })
      ])
    ]),
    (_ctx.timer.instance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
              default: _withCtx(() => _cache[7] || (_cache[7] = [
                _createTextVNode(" Waiting for payment... ")
              ])),
              _: 1
            }),
            _createElementVNode("span", null, [
              (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[8] || (_cache[8] = [
                _createElementVNode("circle", {
                  class: "opacity-25",
                  cx: "12",
                  cy: "12",
                  r: "10",
                  stroke: "currentColor",
                  "stroke-width": "4"
                }, null, -1),
                _createElementVNode("path", {
                  class: "opacity-75",
                  fill: "currentColor",
                  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                }, null, -1)
              ])))
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_app_normal_text, { class: "!text-base !font-bold !text-shop_purple_400" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formattedTime), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (!_ctx.timer.instance)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_app_button, {
            customClass: 'w-full',
            padding: 'py-4',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.continuePayment()))
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" I've made the payment ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}