<template>
  <div
    :class="`w-full flex flex-col  space-y-3 bg-transparent rounded-[10px] border-[1px] border-grey-100`"
    v-if="data"
  >
    <div class="w-full flex flex-row items-center justify-between px-3 pt-3">
      <div class="flex flex-row space-x-2 items-start">
        <div class="w-[36px]">
          <div
            class="h-[35px] w-[35px] rounded-full bg-primary-50 items-center justify-center flex"
          >
            <app-icon :name="data.icon" :customClass="'h-[20px] xs:h-[18px]'" />
          </div>
        </div>

        <div class="flex flex-col space-y-[2px]">
          <app-normal-text :customClass="'!font-semibold !text-xs capitalize'">
            {{ data.title }}
          </app-normal-text>
          <div class="flex flex-col space-y-1 mt-1">
            <app-normal-text :color="'text-grey-600'" class="!text-xs">
              {{ data.date }}
            </app-normal-text>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-between space-y-[2px]">
        <app-normal-text :customClass="'!font-bold text-right !text-xs'">
          {{ data.base_amount }}
        </app-normal-text>

        <span
          class="px-2 py-[2px] bg-success-main !bg-opacity-10 text-success-main rounded-[40px] !text-[11px] mt-1"
          v-if="data.uniqueId"
        >
          {{ data.uniqueId }}
        </span>
      </div>
    </div>

    <div
      class="w-full flex flex-col px-3 pb-3 border-t-[1px] border-[#E8E8E8] pt-3"
    >
      <div class="flex flex-row space-x-1 items-center justify-between">
        <app-normal-text
          :color="'text-grey-500 '"
          :customClass="'!whitespace-nowrap !text-xs'"
        >
          Earnings:
          <span class="font-semibold">
            {{ data.points }}
            Pts ({{ data.amount }})</span
          >
        </app-normal-text>

        <div
          v-if="data.type == 'receipt'"
          class="flex flex-row items-center space-x-1"
        >
          <app-normal-text class="!text-grey-500 !text-xs">
            View
          </app-normal-text>
          <app-icon name="view-more-receipt" :customClass="'!text-[20px]'" />
        </div>

        <app-normal-text
          class="!text-grey-500 !text-xs"
          v-if="data.type == 'card'"
        >
          {{ data.extra_info }}
        </app-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
  },
  props: {
    data: {
      type: Object as () => {
        icon: string;
        type: string;
        title: string;
        date: string;
        base_amount: string;
        uniqueId: string;
        points: string;
        amount: string;
        extra_info: string;
      },
    },
  },
  name: "AppExpensesTransaction",
});
</script>
