import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "footer",
  class: "fixed bottom-0 w-full border-t-[1px] border-primary-100 pt-4 bg-white lg:text-sm mdlg:text-[12px] text-xs",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important","font-family":"BricolageGrotesque"}
}
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center justify-between px-4" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex flex-col items-center justify-center space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (v, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          onClick: ($event: any) => (v.action ? v.action() : $setup.Logic.Common.GoToRoute(v.path)),
          class: "flex flex-col spacey-y-[2px] items-center"
        }, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AppIcon, {
              "custom-class": 'w-5 h-5',
              name: $props.tabIsActive(v.routeTag) ? `${v.icon}-active` : v.icon
            }, null, 8, ["name"]),
            _createVNode(_component_AppNormalText, {
              color: `${
              $props.tabIsActive(v.routeTag) ? 'text-primary-500' : 'text-grey-main'
            }`,
              class: _normalizeClass('!text-[11px]')
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(v.name), 1)
              ]),
              _: 2
            }, 1032, ["color"])
          ])
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}