import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "relative h-auto w-full bg-black z-[99999999] pb-3 flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-col items-center justify-between" }
const _hoisted_3 = { class: "w-full flex flex-row items-center justify-between py-3 px-3" }
const _hoisted_4 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_5 = { class: "w-full h-full flex flex-col justify-between px-3 flex-grow pt-2" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_7 = { class: "w-full flex flex-col" }
const _hoisted_8 = { class: "w-full flex flex-col" }
const _hoisted_9 = { class: "w-full flex flex-col" }
const _hoisted_10 = { class: "w-full flex flex-col" }
const _hoisted_11 = { class: "w-full flex flex-col" }
const _hoisted_12 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_13 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_14 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_15 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_16 = {
  key: 2,
  class: "w-full flex flex-col"
}
const _hoisted_17 = { class: "w-full flex flex-col" }
const _hoisted_18 = { class: "w-full flex flex-col" }
const _hoisted_19 = { class: "w-full flex flex-col" }
const _hoisted_20 = { class: "w-full flex flex-col" }
const _hoisted_21 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_22 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_23 = { class: "w-full flex flex-col" }
const _hoisted_24 = {
  key: 0,
  class: "w-full flex flex-col space-y-1"
}
const _hoisted_25 = { class: "w-full flex flex-col" }
const _hoisted_26 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_27 = { class: "w-full flex flex-col" }
const _hoisted_28 = { class: "w-full flex flex-col" }
const _hoisted_29 = {
  key: 2,
  class: "w-full flex flex-row"
}
const _hoisted_30 = { class: "w-full flex flex-col" }
const _hoisted_31 = { class: "w-full flex flex-col" }
const _hoisted_32 = { class: "w-full flex flex-col" }
const _hoisted_33 = { class: "w-full flex flex-col pt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_content_editable = _resolveComponent("app-content-editable")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_icon, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goBackHandler())),
            "custom-class": 'h-[32px]',
            name: 'goback-dark-white'
          }),
          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-sm !text-white" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          })
        ]),
        _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-100 ' }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString("Step " +
            `(${_ctx.promotionCreationStep.current + "/" + _ctx.promotionCreationStep.total})`), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", {
        class: _normalizeClass(`${
          _ctx.promotionCreationStep.current == _ctx.promotionCreationStep.total
            ? 'bg-highlight-400'
            : 'bg-highlight-50'
        }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.promotionCreationStep.total, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: item,
            class: _normalizeClass(`${
            _ctx.promotionCreationStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
          } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
            index == _ctx.promotionCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
          } w-full h-1`)
          }, null, 2))
        }), 128))
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_ctx.promotionCreationStep.current == 1)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_text_field, {
                  "has-title": false,
                  placeholder: 'Promotion Name',
                  padding: "px-3 py-2",
                  name: "Promotion Name",
                  rules: [_ctx.FormValidations.RequiredRule],
                  modelValue: _ctx.newPromotion.basic.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newPromotion.basic.name) = $event)),
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white"
                }, null, 8, ["rules", "modelValue"])
              ]),
              (_ctx.newPromotion.basic.type == 'website_visit')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(_component_app_select, {
                        "has-title": true,
                        options: _ctx.ctaOptions,
                        paddings: "px-3 py-2",
                        modelValue: _ctx.newPromotion.basic.cta,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPromotion.basic.cta) = $event)),
                        autoComplete: "",
                        placeholder: 'Select CTA',
                        customClass: '!bg-grey-800',
                        inputStyle: "!text-white",
                        theme: 'dark'
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                            default: _withCtx(() => _cache[23] || (_cache[23] = [
                              _createTextVNode(" Call To Action (CTA) ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["options", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_app_text_field, {
                        "has-title": true,
                        placeholder: 'https://example.com',
                        padding: "px-3 py-2",
                        name: "Landing Page URL",
                        rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.WebsiteRule],
                        modelValue: _ctx.newPromotion.basic.landing_page_url,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newPromotion.basic.landing_page_url) = $event)),
                        customClass: '!bg-grey-800',
                        inputStyle: "!text-white"
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                            default: _withCtx(() => _cache[24] || (_cache[24] = [
                              _createTextVNode(" Landing Page URL ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["rules", "modelValue"])
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.newPromotion.basic.type == 'polls')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_app_text_field, {
                        "has-title": true,
                        placeholder: 'Enter question',
                        padding: "px-3 py-2",
                        name: "Question",
                        "is-textarea": "",
                        rules: [_ctx.FormValidations.RequiredRule],
                        "max-character": 50,
                        textAreaRow: '1',
                        modelValue: _ctx.newPromotion.basic.polls.question,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newPromotion.basic.polls.question) = $event)),
                        customClass: '!bg-grey-800',
                        inputStyle: "!text-white"
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                            default: _withCtx(() => _cache[25] || (_cache[25] = [
                              _createTextVNode(" Question ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["rules", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                          default: _withCtx(() => _cache[26] || (_cache[26] = [
                            _createTextVNode(" Options ")
                          ])),
                          _: 1
                        }),
                        (_ctx.newPromotion.basic.polls.options.length < 4)
                          ? (_openBlock(), _createBlock(_component_app_normal_text, {
                              key: 0,
                              class: "!text-grey-500",
                              onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addOption()))
                            }, {
                              default: _withCtx(() => _cache[27] || (_cache[27] = [
                                _createElementVNode("span", { class: "!text-primary-200" }, "+ Add New", -1)
                              ])),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("div", _hoisted_13, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPromotion.basic.polls.options, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "w-full flex flex-row justify-between items-center border-b-[1px] border-gray-100 py-2 pr-2"
                          }, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_app_file_attachment, {
                                isWrapper: "",
                                accept: 'image/jpeg, image/png, image/gif',
                                "local-file-url": item.image_url,
                                "onUpdate:localFileUrl": ($event: any) => ((item.image_url) = $event),
                                "model-value": item.image_blob,
                                "onUpdate:modelValue": ($event: any) => ((item.image_blob) = $event),
                                class: "!w-auto"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_app_image_loader, {
                                    "photo-url": item.image_url,
                                    class: "h-[40px] w-[40px] rounded-[7px] bg-gray-50 flex items-center justify-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_app_icon, {
                                        name: 'upload-image',
                                        class: "!h-[13px]"
                                      })
                                    ]),
                                    _: 2
                                  }, 1032, ["photo-url"])
                                ]),
                                _: 2
                              }, 1032, ["local-file-url", "onUpdate:localFileUrl", "model-value", "onUpdate:modelValue"]),
                              _createVNode(_component_app_content_editable, {
                                defaultValue: item.value,
                                modelValue: item.value,
                                "onUpdate:modelValue": ($event: any) => ((item.value) = $event),
                                onContentChanged: ($event: any) => (item.slug = _ctx.Logic.Common.convertToSlug(item.value)),
                                class: "!text-white !text-xs !outline-none focus:outline-none min-w-[40px] !whitespace-nowrap !bg-grey-800 px-2"
                              }, null, 8, ["defaultValue", "modelValue", "onUpdate:modelValue", "onContentChanged"])
                            ]),
                            _createElementVNode("div", _hoisted_15, [
                              (item.image_url)
                                ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                    key: 0,
                                    class: "!text-red-500 !text-[11px] px-2 py-[2px] bg-red-50 rounded-[2px]",
                                    onClick: ($event: any) => (item.image_url = '')
                                  }, {
                                    default: _withCtx(() => _cache[28] || (_cache[28] = [
                                      _createTextVNode(" Remove Image ")
                                    ])),
                                    _: 2
                                  }, 1032, ["onClick"]))
                                : _createCommentVNode("", true),
                              (_ctx.newPromotion.basic.polls.options.length > 2)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 1,
                                    name: 'cross-black',
                                    customClass: 'h-[10px]',
                                    onClick: ($event: any) => (_ctx.removeOption(index))
                                  }, null, 8, ["onClick"]))
                                : _createCommentVNode("", true)
                            ])
                          ]))
                        }), 128))
                      ])
                    ])
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.newPromotion.basic.type == 'banner')
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    _createVNode(_component_app_text_field, {
                      "has-title": true,
                      placeholder: 'https://example.com',
                      padding: "px-3 py-2",
                      name: "Landing Page URL",
                      rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.WebsiteRule],
                      modelValue: _ctx.newPromotion.basic.landing_page_url,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newPromotion.basic.landing_page_url) = $event)),
                      customClass: '!bg-grey-800',
                      inputStyle: "!text-white"
                    }, {
                      title: _withCtx(() => [
                        _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                          default: _withCtx(() => _cache[29] || (_cache[29] = [
                            _createTextVNode(" Landing Page URL ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["rules", "modelValue"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.promotionCreationStep.current == 2)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_app_select, {
                  "has-title": false,
                  options: _ctx.ageRangeOptions,
                  paddings: "px-3 py-3",
                  modelValue: _ctx.newPromotion.targeting.age_ranges,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.newPromotion.targeting.age_ranges) = $event)),
                  isMultiple: true,
                  autoComplete: "",
                  placeholder: 'Select age ranges',
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white",
                  theme: 'dark'
                }, null, 8, ["options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_app_select, {
                  "has-title": false,
                  options: _ctx.genderOptions,
                  paddings: "px-3 py-3",
                  modelValue: _ctx.newPromotion.targeting.gender,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.newPromotion.targeting.gender) = $event)),
                  placeholder: 'Select gender',
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white",
                  theme: 'dark'
                }, null, 8, ["options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_app_select, {
                  "has-title": false,
                  options: _ctx.locationOptions,
                  paddings: "px-3 py-3",
                  modelValue: _ctx.newPromotion.targeting.locations,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.newPromotion.targeting.locations) = $event)),
                  isMultiple: true,
                  autoComplete: "",
                  placeholder: 'Select locations',
                  customClass: '!bg-grey-800',
                  ref: "locationSelect",
                  inputStyle: "!text-white",
                  theme: 'dark'
                }, null, 8, ["options", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_app_select, {
                  "has-title": false,
                  options: _ctx.behaviouralDemographiesOptions,
                  paddings: "px-3 py-3",
                  modelValue: _ctx.newPromotion.targeting.behavioural_demographies,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.newPromotion.targeting.behavioural_demographies) = $event)),
                  isMultiple: true,
                  autoComplete: "",
                  placeholder: 'Choose behavioural demographies',
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white",
                  ref: "behaviouralDemographySelect",
                  theme: 'dark'
                }, null, 8, ["options", "modelValue"])
              ])
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.promotionCreationStep.current == 3)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("div", _hoisted_22, [
                  _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                    default: _withCtx(() => _cache[30] || (_cache[30] = [
                      _createTextVNode(" Total Budget ")
                    ])),
                    _: 1
                  })
                ]),
                _createVNode(_component_app_text_field, {
                  "has-title": false,
                  placeholder: 'Total Ad Budget',
                  padding: "px-3 py-2",
                  name: "Total Ad Budget",
                  "is-formatted": "",
                  type: "tel",
                  rules: [
                _ctx.FormValidations.customValidator(
                  parseInt(_ctx.newPromotion.budgeting.total.replace(/,/g, '')) > 4999,
                  'Budget must be greater than or equals to ₦5,000'
                ),
              ],
                  modelValue: _ctx.newPromotion.budgeting.total,
                  "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.newPromotion.budgeting.total) = $event)),
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white"
                }, {
                  "inner-prefix": _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                      default: _withCtx(() => _cache[31] || (_cache[31] = [
                        _createTextVNode(" ₦ ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["rules", "modelValue"])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createVNode(_component_app_select, {
                  "has-title": false,
                  options: _ctx.biddingStrategiesOptions(),
                  paddings: "px-3 py-3",
                  modelValue: _ctx.newPromotion.budgeting.strategy,
                  "onUpdate:modelValue": [
                    _cache[12] || (_cache[12] = ($event: any) => ((_ctx.newPromotion.budgeting.strategy) = $event)),
                    _ctx.onBiddingStategySelected
                  ],
                  placeholder: 'Choose bidding stategy',
                  customClass: '!bg-grey-800',
                  inputStyle: "!text-white",
                  theme: 'dark'
                }, null, 8, ["options", "modelValue", "onUpdate:modelValue"])
              ]),
              (_ctx.showMaximimAmount)
                ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                    _createVNode(_component_app_text_field, {
                      "has-title": true,
                      placeholder: `Maximum ${_ctx.newPromotion.budgeting.strategy.toUpperCase()}`,
                      padding: "px-3 py-2",
                      name: `Maximum ${_ctx.newPromotion.budgeting.strategy.toUpperCase()}`,
                      "is-formatted": "",
                      type: "tel",
                      rules: [
                _ctx.FormValidations.customValidator(
                  parseInt(_ctx.newPromotion.budgeting.max_amount.replace(/,/g, '')) >=
                    _ctx.getMinimumStrategyAmount(_ctx.newPromotion.budgeting.strategy),
                  `${_ctx.newPromotion.budgeting.strategy.toUpperCase()} amount must be greater than or equals to ${_ctx.Logic.Common.convertToMoney(
                    _ctx.getMinimumStrategyAmount(_ctx.newPromotion.budgeting.strategy),
                    false,
                    'ngn'
                  )}`
                ),
                _ctx.FormValidations.customValidator(
                  parseInt(_ctx.newPromotion.budgeting.max_amount.replace(/,/g, '')) <=
                    _ctx.getMaximumStrategyAmount(_ctx.newPromotion.budgeting.strategy),
                  `${_ctx.newPromotion.budgeting.strategy.toUpperCase()} amount must be less than or equals to ${_ctx.Logic.Common.convertToMoney(
                    _ctx.getMaximumStrategyAmount(_ctx.newPromotion.budgeting.strategy),
                    false,
                    'ngn'
                  )}`
                ),
              ],
                      modelValue: _ctx.newPromotion.budgeting.max_amount,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.newPromotion.budgeting.max_amount) = $event)),
                      customClass: '!bg-grey-800',
                      inputStyle: "!text-white"
                    }, {
                      title: _withCtx(() => [
                        _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(`Set Maximum ${_ctx.newPromotion.budgeting.strategy.toUpperCase()}`), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      "inner-prefix": _withCtx(() => [
                        _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                          default: _withCtx(() => _cache[32] || (_cache[32] = [
                            _createTextVNode(" ₦ ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["placeholder", "name", "rules", "modelValue"])
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.promotionCreationStep.current == 4)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createVNode(_component_app_text_field, {
                "has-title": true,
                placeholder: 'Start date',
                padding: "px-3 py-2",
                name: "Start date",
                type: "date",
                "prevent-back-date": "",
                "miminum-date": new Date().toString(),
                rules: [_ctx.FormValidations.RequiredRule],
                modelValue: _ctx.newPromotion.scheduling.start,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.newPromotion.scheduling.start) = $event)),
                customClass: '!bg-grey-800',
                inputStyle: "!text-white"
              }, {
                title: _withCtx(() => [
                  _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                    default: _withCtx(() => _cache[33] || (_cache[33] = [
                      _createTextVNode(" Start date ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["miminum-date", "rules", "modelValue"]),
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_app_checkbox, {
                  standard: true,
                  modelValue: _ctx.newPromotion.run_until_paused,
                  "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.newPromotion.run_until_paused) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createTextVNode(" Run until paused ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              (!_ctx.newPromotion.run_until_paused)
                ? (_openBlock(), _createBlock(_component_app_text_field, {
                    key: 0,
                    "has-title": true,
                    placeholder: 'End date',
                    padding: "px-3 py-2",
                    name: "End date",
                    type: "date",
                    "prevent-back-date": "",
                    "miminum-date": new Date(_ctx.newPromotion.scheduling.start).toString(),
                    rules: [_ctx.FormValidations.RequiredRule],
                    modelValue: _ctx.newPromotion.scheduling.end,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.newPromotion.scheduling.end) = $event)),
                    customClass: '!bg-grey-800',
                    inputStyle: "!text-white"
                  }, {
                    title: _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createTextVNode(" End date ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["miminum-date", "rules", "modelValue"]))
                : _createCommentVNode("", true),
              false
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createVNode(_component_app_select, {
                        "has-title": true,
                        options: _ctx.timeOfTheDayOptions,
                        paddings: "px-3 py-2",
                        modelValue: _ctx.newPromotion.scheduling.ad_start_time,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.newPromotion.scheduling.ad_start_time) = $event)),
                        placeholder: 'Start time',
                        customClass: '!bg-grey-800',
                        inputStyle: "!text-white",
                        theme: 'dark'
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                            default: _withCtx(() => _cache[36] || (_cache[36] = [
                              _createTextVNode(" Ad Start Time ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["options", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createVNode(_component_app_select, {
                        "has-title": true,
                        options: _ctx.timeOfTheDayOptions,
                        paddings: "px-3 py-2",
                        placeholder: 'End time',
                        modelValue: _ctx.newPromotion.scheduling.ad_end_time,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.newPromotion.scheduling.ad_end_time) = $event)),
                        customClass: '!bg-grey-800',
                        inputStyle: "!text-white",
                        theme: 'dark'
                      }, {
                        title: _withCtx(() => [
                          _createVNode(_component_app_normal_text, { class: "!text-gray-400" }, {
                            default: _withCtx(() => _cache[37] || (_cache[37] = [
                              _createTextVNode(" Ad End Time ")
                            ])),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["options", "modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.endTimeMustBeMoreThanStartTime())
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                    _createVNode(_component_app_normal_text, { class: "!text-error-main" }, {
                      default: _withCtx(() => _cache[38] || (_cache[38] = [
                        _createTextVNode(" End time must be more than start time ")
                      ])),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.promotionCreationStep.current == 5)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
              _createElementVNode("div", _hoisted_30, [
                _createVNode(_component_app_checkbox, {
                  standard: true,
                  modelValue: _ctx.newPromotion.legal.merchant_terms,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.newPromotion.legal.merchant_terms) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[39] || (_cache[39] = [
                        _createTextVNode(" I have read and agree to the Shoppoint Merchant Platform’s "),
                        _createElementVNode("span", { class: "!text-primary-200 underline" }, "Terms and Conditions", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_31, [
                _createVNode(_component_app_checkbox, {
                  standard: true,
                  modelValue: _ctx.newPromotion.legal.privacy,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.newPromotion.legal.privacy) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[40] || (_cache[40] = [
                        _createTextVNode(" I understand and agree to the "),
                        _createElementVNode("span", { class: "!text-primary-200 underline" }, "Privacy Policy", -1),
                        _createTextVNode(" regarding user data collected through my promotion ")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_32, [
                _createVNode(_component_app_checkbox, {
                  standard: true,
                  modelValue: _ctx.newPromotion.legal.ad_guidelines,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.newPromotion.legal.ad_guidelines) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[41] || (_cache[41] = [
                        _createTextVNode(" I confirm that my promotion complies with Shoppoint’s "),
                        _createElementVNode("span", { class: "!text-primary-200 underline" }, "Advertising Guidelines", -1)
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createVNode(_component_AppButton, {
          loading: _ctx.newPromotion.progress.loading,
          padding: 'py-3',
          "bg-color": 'bg-primary-400',
          "text-color": 'text-white',
          class: _normalizeClass(`w-full ${_ctx.formIsValid ? 'opacity-100' : 'opacity-70'}`),
          onClick: _cache[22] || (_cache[22] = ($event: any) => (_ctx.moveForward()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.promotionCreationStep.current == 5 ? "Submit for review" : "Continue"), 1)
          ]),
          _: 1
        }, 8, ["loading", "class"])
      ])
    ])
  ]))
}