import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 py-4 pt-2 relative md:!pb-0 mdlg:!pb-0"
}
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-row justify-center items-center py-3"
}
const _hoisted_3 = { class: "w-full flex flex-col pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.data.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_app_icon, {
                name: _ctx.data.icon,
                customClass: 'h-[100px]'
              }, null, 8, ["name"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_app_normal_text, {
          customClass: 'text-left',
          class: "!text-gray-700"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.info), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_button, {
            class: "w-full py-3",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.action ? _ctx.action() : () => {}))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.cta_copy), 1)
            ]),
            _: 1
          })
        ])
      ]))
    : _createCommentVNode("", true)
}