<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[500px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <!-- Shoplists -->
    <div class="w-full grid grid-cols-2 gap-3" v-if="!isLoading">
      <template v-if="instagramVideos.length > 0">
        <div
          v-for="(item, index) in instagramVideos"
          :key="index"
          :class="`col-span-1 flex flex-col space-y-2 border-[1px] border-gray-300 dark:border-gray-500 rounded-[10px] px-3 py-3 cursor-pointer ${
            selectedMedia == item.media_id
              ? 'border-primary-400 dark:border-primary-400 border-[2px]'
              : ''
          }`"
          @click="selectInstagramVideo(item)"
        >
          <app-image-loader
            :photo-url="item.media_thumbnail"
            class="w-full h-[200px] rounded-[10px] md:h-[300px] mdlg:h-[300px] relative"
          >
            <div
              class="absolute w-full h-full rounded-[10px] bg-black !bg-opacity-30 flex items-center justify-center"
            >
              <app-icon :name="'play'" class="!h-[23px]" />
            </div>
          </app-image-loader>
          <app-normal-text class="text-left">
            {{ item.media_title }}
          </app-normal-text>
        </div>
      </template>

      <div
        v-else
        class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
      >
        <app-empty-state
          :title="'No Instagram Videos Here'"
          :subTitle="'All available instagram videos will show up here'"
        >
        </app-empty-state>
      </div>
    </div>

    <!-- Spacer -->
    <div class="h-[50px]"></div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppTextField } from "../AppForm";
import { InstagramMedia, InstagramMediaData } from "../../gql/graphql";
import AppEmptyState from "../AppEmptyState";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppTextField,
    AppEmptyState,
    AppImageLoader,
    AppNormalText,
  },
  props: {
    data: {
      type: Object as () => {
        selected: string;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const ManyInstagramVideos = ref<InstagramMediaData>();

    const isLoading = ref(false);

    const selectedMedia = ref("");

    const instagramVideos = reactive<InstagramMedia[]>([]);

    const setInstagramVideos = () => {
      instagramVideos.length = 0;

      ManyInstagramVideos.value?.list?.forEach((item) => {
        if (item) {
          instagramVideos.push(item);
        }
      });
    };

    const getInstagramVideos = (isUpdate = false, after = "", before = "") => {
      isLoading.value = true;
      Logic.Shoplist.GetUserInstagramMedia(after, before, isUpdate)
        .then((data: any) => {
          setInstagramVideos();
          isLoading.value = false;
        })
        .catch((error: any) => {
          console.log(error);
          isLoading.value = false;
        });
    };

    const selectInstagramVideo = (data: any) => {
      if (props.action) {
        props.action(data);
      }
    };

    watch(ManyInstagramVideos, () => {
      setInstagramVideos();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("InstagramMedia", ManyInstagramVideos);
      selectedMedia.value = props.data?.selected || "";
      getInstagramVideos();
    });

    return {
      searchQuery,
      isLoading,
      ManyInstagramVideos,
      selectedMedia,
      instagramVideos,
      selectInstagramVideo,
    };
  },
});
</script>
