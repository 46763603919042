import { Logic } from './'
import { Business, FollowAction } from '../gql/graphql'

export const followUnfollowBusiness = (bussiness: Business) => {
  Logic.User.FollowUnfollowBusinessForm = {
    action: bussiness.is_following
      ? FollowAction.Unfollow
      : FollowAction.Follow,
    business_uuid: bussiness.uuid,
  }

  if (bussiness.is_following) {
    bussiness.is_following = false
  } else {
    bussiness.is_following = true
  }

  Logic.User.FollowUnfollowBusiness()?.then((data) => {
    if (data) {
      //
    }
  })
}
