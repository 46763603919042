import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, Fragment as _Fragment, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  class: "flex flex-row items-center py-0 z-[400] w-full justify-between !font-inter px-4 bg-white dark:bg-black",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_2 = { class: "flex flex-row space-x-2" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col justify-start"
}
const _hoisted_4 = { class: "flex flex-row space-x-3 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_image_loader, {
        photoUrl: 
          _ctx.Logic.Common.currentAccountType() == 'merchant'
            ? _ctx.Logic.Auth.GetCurrentBusiness()?.photo_url || ''
              ? _ctx.Logic.Auth.GetCurrentBusiness()?.photo_url || ''
              : '/images/temp/vendor.svg'
            : _ctx.Logic.Auth.AuthUser?.profile?.photo_url
            ? _ctx.Logic.Auth.AuthUser?.profile?.photo_url
            : '/images/avatar-1.png'
        ,
        customClass: '!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openSideBar()))
      }, null, 8, ["photoUrl"]),
      (_ctx.type == 'full')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-700 dark:!text-gray-200 !text-left" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.greetings), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_app_header_text, { class: "!text-sm !line-clamp-1 !text-left" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.Logic.Auth.AuthUser?.first_name), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.type != 'full')
      ? (_openBlock(), _createBlock(_component_app_header_text, { key: 0 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.Logic.Common.currentAccountType() == 'client')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_ctx.hasSearch)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 0,
                  name: 'search-circle',
                  customClass: '!h-[38px]'
                }))
              : _createCommentVNode("", true),
            (_ctx.hasNotify)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 1,
                  name: 'notify-circle',
                  customClass: '!h-[38px] cursor-pointer',
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/notifications')))
                }))
              : _createCommentVNode("", true),
            (_ctx.hasCart)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 2,
                  name: 'cart-circle',
                  customClass: '!h-[38px]'
                }))
              : _createCommentVNode("", true),
            (_ctx.hasStory)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 3,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showStory())),
                  name: `story-circle${
            _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`,
                  customClass: '!h-[38px] cursor-pointer'
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            (_ctx.hasShoppingBasket)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 4,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/basket'))),
                  name: `shopping-basket-circle${
            _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`,
                  customClass: '!h-[38px] cursor-pointer '
                }, null, 8, ["name"]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.hasNotify || !_ctx.hasAddButton)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 0,
                  name: `notify-circle${
            _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`,
                  customClass: '!h-[38px] cursor-pointer',
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/notifications')))
                }, null, 8, ["name"]))
              : _createCommentVNode("", true),
            (_ctx.hasAddButton)
              ? (_openBlock(), _createBlock(_component_app_icon, {
                  key: 1,
                  name: `add-circle${
            _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`,
                  customClass: '!h-[38px] cursor-pointer',
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addButtonAction()))
                }, null, 8, ["name"]))
              : _createCommentVNode("", true)
          ], 64)),
      _renderSlot(_ctx.$slots, "extra-top-bar-right")
    ])
  ]))
}