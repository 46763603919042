<template>
  <AppImageLoader
    :custom-class="'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500'"
    :photo-url="'/images/card-bg.svg'"
  >
    <div class="w-full flex flex-row space-x-2">
      <div
        class="w-[18px] h-[18px] bg-primary-400 rounded-full grid place-content-center"
      >
        <AppIcon :custom-class="'h-[11px]'" :name="'wallet'"></AppIcon>
      </div>
      <AppNormalText :color="'text-white'"> {{ title }}</AppNormalText>
    </div>

    <div class="flex flex-col space-y-1">
      <div class="flex flex-row space-x-2 items-center">
        <AppHeaderText :color="'text-white'">{{
          canView ? `${balance}` : "*********"
        }}</AppHeaderText>
        <AppIcon
          v-if="canView"
          @click="canView = false"
          :custom-class="'w-[16px]'"
          :name="'show-white'"
        ></AppIcon>
        <AppIcon
          @click="canView = true"
          v-if="!canView"
          :custom-class="'w-[16px]'"
          :name="'hide-white'"
        ></AppIcon>
      </div>

      <div class="flex space-x-2 items-center">
        <AppIcon :custom-class="'w-1.5'" :name="'arrow-right-up'"></AppIcon>
        <AppNormalText
          :size="'small'"
          :custom-class="'!font-extralight'"
          class="font-extralight"
          :color="'text-white'"
        >
          + {{ statsAmount }} This Week
        </AppNormalText>
      </div>
    </div>

    <div
      v-if="showBottomArea"
      class="flex flex-row items-center justify-between border-t-[1px] border-primary-300 pt-3"
    >
      <div class="flex flex-row items-center space-x-2">
        <AppNormalText :color="'text-white'" :size="'small'">
          Total Offers: {{ totalProduct }}
        </AppNormalText>
      </div>
      <AppButton
        @click="$emit('button-clicked')"
        :bg-color="'bg-primary-400'"
        :padding="'py-[2px] px-3'"
        :custom-class="'!text-[12px]'"
      >
        View all
      </AppButton>
    </div>
  </AppImageLoader>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import { ref } from "vue";
import { Logic } from "../../composable";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
  },
  props: {
    title: {
      type: String,
    },
    balance: {
      type: String,
      default: "200,000,898.00",
    },
    statsAmount: {
      type: String,
      default: "38,000",
    },
    statsPercent: {
      type: String,
      default: "26",
    },
    totalProduct: {
      type: String,
      default: "128",
    },
    outOfStock: {
      type: String,
      default: "19",
    },
    showBottomArea: {
      type: Boolean,
      default: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  emits: ["button-clicked"],
  setup() {
    const canView = ref(true);

    return {
      canView,
      Logic,
    };
  },
  name: "AppBalanceCard",
};
</script>
