<template>
  <app-wrapper>
    <subpage-layout :title="'Buy Cable'">
      <!-- Beneficiaries -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            class="!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center"
          >
            <app-icon
              :name="`beneficiary${
                Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`"
              custom-class="!h-[12px]"
            />
            <app-normal-text class="!text-primary-500 dark:!text-primary-100">
              Beneficiaries
            </app-normal-text>
          </app-button>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-3">
        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-3"
        >
          <!-- Billers -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text
              class="!text-[12px] text-left px-4 dark:!text-white"
            >
              Select Billers
            </app-normal-text>

            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
            >
              <div class="flex flex-row space-x-3 pr-4">
                <div
                  class="flex items-center justify-center"
                  v-for="(biller, index) in allBillers"
                  :key="index"
                >
                  <div
                    class="w-[56px] h-[56px] rounded-full relative cursor-pointer"
                    @click="selectedBiller = biller.id"
                  >
                    <app-image-loader
                      :photo-url="biller.logo"
                      :class="`h-[56px] w-[56px] dark:border-[1px] dark:border-gray-50 ${
                        selectedBiller == biller.id
                          ? 'border-[2px] border-primary-400 rounded-full'
                          : ' rounded-full'
                      } `"
                    />
                    <app-icon
                      v-if="selectedBiller == biller.id"
                      :name="'mark'"
                      :customClass="'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full flex flex-col px-4 pt-3">
              <div
                class="w-full border-t-[1px] border-grey-50 dark:!border-grey-500"
              ></div>
            </div>
          </div>
          <!-- Form items -->
          <div class="w-full flex flex-col space-y-4 px-4">
            <!-- Select payment item -->
            <app-select
              :paddings="'py-3 px-4'"
              :hasTitle="true"
              :placeholder="'Select payment plan'"
              :options="paymentOptions"
              :updateKey="selectedPaymentItem"
              custom-class="dark:!bg-grey-900"
              v-model="selectedPaymentItem"
              @on-option-selected="handlePaymentItemSelected"
              :theme="Logic.Common.currentAppTheme()"
            >
              <template v-slot:title> Payment item </template>
            </app-select>

            <!-- Customer Id -->
            <div class="w-full flex flex-col space-y-2">
              <app-text-field
                :placeholder="'Enter smartcard number'"
                :hasTitle="true"
                :type="'text'"
                :rules="[FormValidations.RequiredRule]"
                name="Smartcard number"
                v-model="smartcardNumber"
                :update-value="smartcardNumber"
              >
                <template v-slot:title> Smartcard number </template>
              </app-text-field>

              <div
                class="w-full flex flex-row items-center justify-end"
                v-if="smartcardNumber.length >= 7"
              >
                <app-normal-text
                  :class="
                    isResolvingNumber
                      ? '!text-secondary-main dark:!text-secondary-100'
                      : resolveIsError
                      ? '!text-red-500 dark:!text-red-300'
                      : '!text-primary-500 dark:!text-primary-300'
                  "
                >
                  {{
                    isResolvingNumber
                      ? "Validating number..."
                      : resolveIsError
                      ? "Unable to resolve meter number"
                      : cableTVBiller
                  }}
                </app-normal-text>
              </div>

              <app-checkbox
                v-model="saveAsBeneficiary"
                :standard="true"
                class="w-full"
              >
                <app-normal-text class="dark:!text-white">
                  Save as beneficiary
                </app-normal-text>
              </app-checkbox>
            </div>

            <!-- Amount field -->
            <app-text-field
              :placeholder="'Enter amount'"
              :hasTitle="true"
              :type="'tel'"
              :isFormatted="true"
              :custom-class="''"
              :disabled="true"
              :update-value="amountValue"
            >
              <template v-slot:title> Amount </template>
              <template v-slot:inner-prefix>
                <app-normal-text class="dark:!text-gray-200">
                  ₦
                </app-normal-text>
              </template>
            </app-text-field>

            <div class="w-full flex flex-row space-x-1 justify-end flex-grow">
              <app-icon :name="'cashback-icon'" :customClass="'h-[20px]'" />
              <app-normal-text
                :customClass="'!whitespace-nowrap dark:!text-white'"
              >
                You'll get
                <span
                  class="font-semibold text-primary-500 dark:!text-primary-200 px-1"
                  >{{
                    Logic.Common.convertToMoney(
                      getProductPoint(
                        "cable_tv",
                        amountValue?.replace(/,/g, "")
                      ),
                      false,
                      ""
                    )
                  }}
                  Points</span
                >
              </app-normal-text>
            </div>
          </div>

          <!-- Bottom section -->
          <fixed-container class="pt-4">
            <div class="w-full flex flex-col">
              <AppButton
                :padding="'py-3'"
                :bg-color="'bg-primary-400'"
                :text-color="'text-white'"
                :class="formIsValid ? 'w-full' : 'opacity-50'"
                @click="purchaseCableTv"
              >
                Continue
              </AppButton>
            </div>
          </fixed-container>
        </app-form-wrapper>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppFormWrapper,
  AppIcon,
  AppNormalText,
  AppImageLoader,
  AppSelect,
  AppTextField,
  AppCheckbox,
  AppButton,
} from "@shpt/ui-components";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import {
  getProductPoint,
  makePurchase,
  processProductData,
  selectedBeneficiary,
  SingleProduct,
} from "@shpt/ui-components/src/composable/shop";
import { SelectOption } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppFormWrapper,
    AppIcon,
    AppNormalText,
    AppImageLoader,
    AppSelect,
    AppTextField,
    AppCheckbox,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "BuyCablePage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Buy Cable TV Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Shop",
        property: "ShopData",
        subProperty: "cable_tv",
        method: "GetProductsByCategory",
        params: ["cable_tv"],
        requireAuth: false,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Buy Cable TV",
    });

    const selectedBiller = ref("DSTV");
    const saveAsBeneficiary = ref(true);
    const selectedPaymentType = ref("");
    const selectedPaymentItem = ref("");
    const smartcardNumber = ref("");
    const amountValue = ref("");
    const cashPerPoint = ref("");
    const cableTVBiller = ref("");
    const resolveIsError = ref(false);
    const isResolvingNumber = ref(false);

    const allBillers = reactive<
      {
        logo: string;
        id: string;
        options: any;
      }[]
    >([
      {
        logo: "/images/brands/dstv.png",
        id: "DSTV",
        options: {},
      },
      {
        logo: "/images/brands/gotv.png",
        id: "GoTV",
        options: {},
      },
      {
        logo: "/images/brands/startimes.png",
        id: "startimes",
        options: {},
      },
      {
        logo: "/images/brands/showmax.png",
        id: "showmax",
        options: {},
      },
    ]);

    const paymentOptions = reactive<SelectOption[]>([]);
    const paymentItems = reactive<SelectOption[]>([]);

    const formIsValid = computed(() => {
      return (
        smartcardNumber.value.length >= 7 &&
        !isResolvingNumber.value &&
        !resolveIsError.value
      );
    });

    const setCableTVOptions = () => {
      const cableTVDeals = processProductData(
        Logic.Shop.ShopData.cable_tv || []
      );
      const cableTVDealsData = cableTVDeals.map((item) => item.data);
      const groupedData = Logic.Common.groupArrayBy(
        cableTVDealsData,
        "merchant_name"
      );

      allBillers.forEach((item) => {
        const productData = groupedData[item.id][0];
        item.logo = productData.image_url;
      });

      allBillers.forEach((item, index) => {
        allBillers[index].options = groupedData[item.id];
      });

      setPaymentOption();
    };

    const setPaymentOption = () => {
      if (selectedBiller.value) {
        const currentCableTv = allBillers.filter((item) => {
          return item.id == selectedBiller.value;
        })[0];

        paymentOptions.length = 0;

        currentCableTv?.options?.forEach((item: any) => {
          const productMetaData = JSON.parse(item.metadata);
          paymentOptions.push({
            key: item.code,
            value: productMetaData.name,
            extras: item,
          });
        });

        if (paymentOptions.length) {
          selectedPaymentItem.value = paymentOptions[0].key;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cashPerPoint.value = getProductPoint(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paymentOptions[0].extras.product_category.slug,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            paymentOptions[0].extras.price / 100
          );
        }
      }
    };

    const handlePaymentTypeOptionSelected = (data: any) => {
      cashPerPoint.value = data.extras?.cash_per_point;
    };

    const handlePaymentItemSelected = (data: any) => {
      const extraData = data.extras;

      const metaData = JSON.parse(data.extras.metadata);
      amountValue.value = (metaData.amount / 100).toString();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      cashPerPoint.value = getProductPoint(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraData.product_category.slug,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        extraData.price / 100
      );

      selectedPaymentType.value = data.key;
    };

    watch(selectedBiller, () => {
      setPaymentOption();
    });

    const setCableData = () => {
      setTarget();
      setCableTVOptions();
      // set default
      setTimeout(() => {
        if (SingleProduct.value) {
          selectedBiller.value = SingleProduct.value.merchant_name || "";
          const metadata: any = JSON.parse(
            SingleProduct.value.metadata || "[]"
          );

          amountValue.value = (metadata.amount / 100).toString();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          cashPerPoint.value = getProductPoint(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            SingleProduct.value.product_category.slug,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            SingleProduct.value.price / 100
          );

          setPaymentOption();
          selectedPaymentType.value = SingleProduct.value.code;
        }
      }, 300);
      smartcardNumber.value = "";
      if (selectedBeneficiary.value) {
        smartcardNumber.value = selectedBeneficiary.value.id;
      }
    };

    const resolveSmartcardNumber = () => {
      Logic.Common.debounce(() => {
        if (smartcardNumber.value.length >= 7) {
          Logic.Shop.ValidatePaymentCustomerForm = {
            customer_id: smartcardNumber.value,
            payment_code: selectedPaymentItem.value,
          };
          isResolvingNumber.value = true;
          cableTVBiller.value = "";
          Logic.Shop.ValidatePaymentCustomer()
            ?.then((data) => {
              if (data) {
                cableTVBiller.value = data.ValidatePaymentCustomer;
              }
              resolveIsError.value = false;
              isResolvingNumber.value = false;
            })
            .catch(() => {
              resolveIsError.value = true;
              isResolvingNumber.value = false;
              cableTVBiller.value = "";
            });
        }
      }, 300);
    };

    const purchaseCableTv = () => {
      if (
        smartcardNumber.value &&
        selectedPaymentType.value &&
        selectedPaymentItem.value
      ) {
        makePurchase(
          {
            code: selectedPaymentType.value,
            amount: amountValue.value.toString(),
            customerId: smartcardNumber.value,
            monthsPaidFor: selectedPaymentItem.value.toString(),
          },
          saveAsBeneficiary.value
        );
      }
    };

    const setTarget = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        selectedBiller.value = currentPathQuery.target?.toString() || "DSTV";
      }
    };

    watch(smartcardNumber, () => {
      resolveSmartcardNumber();
    });

    watch(selectedPaymentItem, () => {
      resolveSmartcardNumber();
    });

    onMounted(() => {
      setCableData();
    });

    watch(selectedBeneficiary, () => {
      setCableData();
    });

    onIonViewWillEnter(() => {
      setCableData();
    });

    return {
      allBillers,
      selectedBiller,
      paymentOptions,
      paymentItems,
      selectedPaymentType,
      selectedPaymentItem,
      amountValue,
      Logic,
      cashPerPoint,
      smartcardNumber,
      FormValidations,
      saveAsBeneficiary,
      loaderSetup,
      cableTVBiller,
      resolveIsError,
      isResolvingNumber,
      SingleProduct,
      formIsValid,
      handlePaymentTypeOptionSelected,
      handlePaymentItemSelected,
      purchaseCableTv,
      getProductPoint,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
