<template>
  <span
    :class="` text-black dark:text-white
  ${customClass} ${color}
  ${size == 'base' ? 'lg:text-sm mdlg:text-[12px] text-xs' : ''}
  ${size == 'small' ? ' text-[12px]' : ''}`"
  >
    <slot />
  </span>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    size: {
      type: String,
      default: "base",
    },
    color: {
      type: String,
      default: "",
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "AppNormalText",
});
</script>
