<template>
  <img :src="`${imageUrl}`" :class="`${customClass}`" alt="" />
</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from "vue";

export default defineComponent({
  name: "AppPlainImageLoader",
  props: {
    photoUrl: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const image = ref("");
    const imageUrl = ref("");

    onMounted(() => {
      imageUrl.value = props.photoUrl || "";
      const highResImage = new Image();

      highResImage.onload = function () {
        image.value = imageUrl.value;
      };
      highResImage.src = imageUrl.value;
    });

    return {
      image,
      imageUrl,
    };
  },
});
</script>
<!-- <style scoped>
.blend-in {
  animation: fadein 0.15s;
  -moz-animation: fadein 0.15s; /* Firefox */
  -webkit-animation: fadein 0.15s; /* Safari and Chrome */
  -o-animation: fadein 0.15s; /* Opera */
}
</style> -->
