<template>
  <app-wrapper>
    <app-support-page />
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { AppSupportPage } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppSupportPage,
    AppWrapper,
  },
  name: "SupportPage",
  middlewares: {
    tracking_data: {
      lable: "Support Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Support",
    });

    return {};
  },
});
</script>
