<template>
  <app-wrapper>
    <subpage-layout
      :title="'Cashback Rewards'"
      mainSectionClass="!bg-white dark:!bg-black !overflow-y-hidden !fixed top-0 left-0 mdlg:!relative md:!relative  w-full"
      :hasPadding="false"
      :topBarStyle="'!bg-transparent'"
      :parentContClass="'md:!h-screen'"
      class="!overflow-y-hidden md:!overflow-y-hidden"
      theme="light"
      :style="`padding-bottom: calc(env(safe-area-inset-bottom)) !important;`"
      :showBottomPadding="false"
    >
      <!-- Background icon -->
      <div class="absolute top-[1%] -right-0">
        <img src="/images/challenge-bg-image.svg" class="h-[100px]" />
      </div>

      <div
        class="w-full flex flex-col h-full flex-grow relative overflow-y-hidden"
      >
        <div class="xs:w-[90%] w-[85%] flex flex-row px-4">
          <app-normal-text :class="'!text-black dark:!text-white'">
            Participate in challenges and earn cashback rewards
          </app-normal-text>
        </div>

        <div class="w-full flex flex-col px-4 h-full pt-3">
          <div
            class="w-full flex flex-col flex-grow h-full pt-4 z-50 overflow-y-hidden rounded-t-[10px] relative"
          >
            <div
              class="w-full flex flex-col space-y-3 relative overflow-y-auto scrollbar-hide"
            >
              <app-tabs :data="filters" v-model="selectedFilter" />
              <div class="w-full flex flex-col space-y-3">
                <app-challenge
                  v-for="(item, index) in challenges"
                  :key="index"
                  class="cursor-pointer"
                  :data="item"
                  @click="
                    Logic.Common.GoToRoute(`/others/challenges/${item.uuid}`)
                  "
                />
                <div class="h-[80px]"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { tabTitle } from "@shpt/ui-components/src/composable";
import { AppNormalText, AppChallenge, AppTabs } from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppTabs,
    AppChallenge,
    AppWrapper,
  },
  name: "ChallengesPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "ManyChallenges",
        method: "GetChallenges",
        params: [],
        requireAuth: true,
        silentUpdate: true,
      },
    ],
    tracking_data: {
      lable: "Challenges Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Challenges",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);
    const ManyChallenges = ref(Logic.Game.ManyChallenges);

    const selectedFilter = ref("all");

    const filters = reactive([
      {
        id: "all",
        title: "All Challenges",
      },
      {
        id: "my_challenges",
        title: "My Challenges",
      },
    ]);

    const challenges = reactive<
      {
        image_url: string;
        title: string;
        points: string;
        uuid: string;
      }[]
    >([]);

    const setChallenges = () => {
      challenges.length = 0;
      ManyChallenges.value?.forEach((item) => {
        if (selectedFilter.value == "all") {
          challenges.push({
            uuid: item.uuid,
            title: item.title,
            image_url: item.photo_url || "",
            points: Logic.Common.convertToMoney(
              item.rule?.reward_amount || 0,
              false,
              ""
            ),
          });
        }

        if (selectedFilter.value == "my_challenges") {
          if (item.active_instance?.participant) {
            challenges.push({
              uuid: item.uuid,
              title: item.title,
              image_url: item.photo_url || "",
              points: Logic.Common.convertToMoney(
                item.rule?.reward_amount || 0,
                false,
                ""
              ),
            });
          }
        }
      });
    };

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Game.watchProperty("ManyChallenges", ManyChallenges);
      setChallenges();
      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 1000);
    });

    watch(ManyChallenges, () => {
      setChallenges();
    });

    watch(selectedFilter, () => {
      setChallenges();
    });

    return {
      filters,
      selectedFilter,
      challenges,
      Logic,
    };
  },
});
</script>
