import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`flex flex-row w-full space-x-1 px-1 py-1 bg-grey-50 dark:bg-gray-900 rounded-[40px] justify-between`)
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(`rounded-[30px] items-center justify-center flex flex-row px-2 py-2 cursor-pointer ${
        _ctx.currentSelectedItem == item.key ? `bg-white dark:!bg-gray-700` : ''
      }`),
        onClick: ($event: any) => (_ctx.currentSelectedItem = item.key),
        style: _normalizeStyle(`width: ${100 / _ctx.items.length}%`)
      }, [
        _createVNode(_component_app_normal_text, {
          color: `${
          _ctx.currentSelectedItem == item.key
            ? `!text-gray-800 dark:!text-white !font-semibold`
            : `!text-gray-500 dark:!text-grey-300`
        }`,
          customClass: `!text-[12px] mdlg:!text-xs`
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(item.name), 1)
          ]),
          _: 2
        }, 1032, ["color"])
      ], 14, _hoisted_2))
    }), 128))
  ]))
}