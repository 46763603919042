import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-[45px] w-[45px] -mt-1 rounded-full border-[4px] border-primary-100 bg-primary-400 flex flex-row items-center justify-center" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-6 h-full flex-grow z-10 px-4" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2 items-center justify-center" }
const _hoisted_4 = { class: "w-full flex flex-col h-[140px] relative" }
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-row flex-wrap justify-start items-start h-full absolute top-0 left-0 z-0`),
  id: "guessBoxContainer"
}
const _hoisted_6 = { class: "w-full flex flex-col space-y-4 pt-6" }
const _hoisted_7 = { class: "w-full flex flex-col h-[140px]" }
const _hoisted_8 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-row flex-wrap  justify-center items-center h-full`),
  id: "wordBoxContainer"
}
const _hoisted_9 = {
  style: {"font-family":"Inter"},
  class: "text-black !font-extrablod !text-[40px] xs:!text-[35px] wordText"
}
const _hoisted_10 = { class: "w-full flex flex-row items-center justify-center space-x-3" }
const _hoisted_11 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col `)
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Word Twist',
        class: "!overflow-y-hidden !h-screen",
        hasPadding: false,
        topBarStyle: '!bg-transparent',
        bottomBlankStyle: '!bg-transparent',
        mainSectionClass: '!overflow-y-hidden !h-screen',
        showBottomPadding: false,
        theme: "dark"
      }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_app_normal_text, {
              color: '!text-white',
              customClass: '!text-lg !font-semibold !mt-[4px]'
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.currentCountdown.main), 1)
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _cache[9] || (_cache[9] = _createElementVNode("div", { class: "absolute top-0 left-0 w-full h-full z-[0] overflow-y-hidden" }, [
            _createElementVNode("img", {
              src: "/images/word-twist-bg.png",
              class: "w-full"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_normal_text, { color: 'text-white' }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Guess the longest possible word ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guessedAnswer, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "h-[70px] w-[67px] xs:h-[60px] xs:w-[57px] flex flex-row items-center justify-center px-1 py-1 isGuess",
                      key: index
                    }, _cache[4] || (_cache[4] = [
                      _createElementVNode("div", { class: "h-full w-full bg-primary-300 rounded-[12px] shadow-inner" }, null, -1)
                    ])))
                  }), 128))
                ]),
                _cache[5] || (_cache[5] = _createElementVNode("div", {
                  class: _normalizeClass(`w-full flex flex-row flex-wrap justify-start items-start h-full  z-20`),
                  id: "guessBoxContainerMain"
                }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_ctx.showGuessWord)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.guessedWord, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "h-[70px] w-[67px] xs:h-[60px] xs:w-[57px] flex flex-row items-center justify-center px-1 py-1 draggable-source isWordBox",
                          "data-movetime": "0",
                          key: index
                        }, [
                          _createVNode(_component_app_image_loader, {
                            photoUrl: '/images/word-box-bg.svg',
                            customClass: 'h-full w-full flex flex-col items-center justify-center'
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_9, _toDisplayString(item.text), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: _normalizeClass(` flex flex-col items-center justify-center h-[50px] w-[180px] relative !bg-primary-main focus:outline-none ${
                _ctx.shuffleIsClicked ? '' : 'border-b-[3px]'
              }  border-[#bf8f52] rounded-[35px]`),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickEffectShuffle()))
                }, [
                  _createVNode(_component_app_normal_text, {
                    customClass: '!text-base !font-extrabold',
                    color: 'text-white'
                  }, {
                    default: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Shuffle ")
                    ])),
                    _: 1
                  })
                ], 2),
                (_ctx.SingleChallenge?.active_instance?.participant?.points != '-1')
                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                      key: 0,
                      color: 'text-white'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(3 - _ctx.gameSettings.trialLeft) + " more trial" + _toDisplayString(3 - _ctx.gameSettings.trialLeft > 1 ? "s" : "") + " left ", 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.gameSettings.canPlay)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(`w-full flex flex-row items-center justify-center pt-2 ${
            !_ctx.gameSettings.canPlay ? 'opacity-70' : ''
          } `)
                }, [
                  _createElementVNode("div", {
                    class: _normalizeClass(`  flex flex-col items-center justify-center h-[50px] w-full !bg-primary-main focus:outline-none ${
              _ctx.enterIsClicked ? '' : 'border-b-[3px]'
            }  border-[#bf8f52] rounded-[30px] `),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clickEffectEnter()))
                  }, [
                    _createVNode(_component_app_normal_text, {
                      customClass: '!text-2xl !font-extrabold',
                      color: 'text-white'
                    }, {
                      default: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Enter ")
                      ])),
                      _: 1
                    })
                  ], 2)
                ], 2))
              : (_openBlock(), _createBlock(_component_fixed_container, {
                  key: 1,
                  class: _normalizeClass(`${_ctx.gameSettings.trialLeft >= 3 ? 'opacity-50' : ''}`)
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_app_button, {
                        padding: 'px-6 py-3',
                        border: 'border-b-[3px]',
                        customClass: 'w-full',
                        type: 'light',
                        loading: _ctx.loaderSetup.loading,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.watchAd()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.gameSettings.trialLeft >= 3
                  ? "Try again tomorrow"
                  : "Try again (Watch an Ad)"), 1)
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ])
                  ]),
                  _: 1
                }, 8, ["class"]))
          ]),
          (!_ctx.startGame)
            ? (_openBlock(), _createBlock(_component_fixed_container, {
                key: 0,
                class: "!top-0 !left-0 items-center justify-center z-[9999999999] !bg-black !bg-opacity-80"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_header_text, {
                    color: '!text-white',
                    customClass: '!text-[60px] text-center'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.currentCountdown.start), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_normal_text, {
                    color: 'text-white',
                    customClass: 'text-center'
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Your game will start in ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}