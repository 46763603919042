import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-row px-4 pt-4"
}
const _hoisted_3 = { class: "flex flex-row py-1 px-4 bg-secondary-400 rounded-[19px]" }
const _hoisted_4 = {
  key: 1,
  src: "/images/icons/level-bg.svg",
  class: "right-0 top-[10%] h-[90px] absolute"
}
const _hoisted_5 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "w-full flex flex-col px-4 py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Levels' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levels, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(`${
            _ctx.currentLevel == item.id ? 'bg-primary-main' : 'bg-grey-50'
          }  w-full flex flex-col rounded-[10px] relative`),
                key: index
              }, [
                (_ctx.currentLevel == item.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_app_normal_text, { customClass: '!uppercase text-[10px] !font-semibold' }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode(" YOUR CURRENT LEVEL ")
                          ])),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.currentLevel == item.id)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_4))
                  : _createCommentVNode("", true),
                _createElementVNode("div", {
                  class: _normalizeClass(`px-4 py-3  ${
              _ctx.currentLevel == item.id
                ? 'border-primary-400 border-b-[1px]'
                : 'border-[#E8E8E8] border-b-[1px]'
            }  flex flex-row items-center justify-between`)
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_app_image_loader, {
                      "photo-url": item.image_url,
                      class: "h-[34px] w-[34px]"
                    }, null, 8, ["photo-url"]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_app_normal_text, {
                        color: 
                    _ctx.currentLevel != item.id ? '!text-black' : 'text-white'
                  ,
                        customClass: '!text-sm !uppercase font-semibold'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["color"]),
                      _createVNode(_component_app_normal_text, {
                        customClass: `!uppercase !text-[10px] ${
                    _ctx.currentLevel != item.id ? '' : 'opacity-60'
                  }`,
                        color: 
                    _ctx.currentLevel != item.id ? '!text-grey-800' : 'text-white'
                  
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.nick_name), 1)
                        ]),
                        _: 2
                      }, 1032, ["customClass", "color"])
                    ])
                  ]),
                  _createVNode(_component_app_normal_text, {
                    color: _ctx.currentLevel != item.id ? '!text-black' : 'text-white',
                    customClass: '!font-semibold '
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.point_range) + " pts ", 1)
                    ]),
                    _: 2
                  }, 1032, ["color"])
                ], 2),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_normal_text, {
                    customClass: ` !text-left  xs:!text-xs   ${
                _ctx.currentLevel != item.id ? '' : 'opacity-60'
              }`,
                    color: _ctx.currentLevel != item.id ? '!text-grey-800' : 'text-white'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.description), 1)
                    ]),
                    _: 2
                  }, 1032, ["customClass", "color"])
                ])
              ], 2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}