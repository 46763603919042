import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_4 = { class: "w-[37px]" }
const _hoisted_5 = { class: "w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center dark:bg-slate-300 bg-white" }
const _hoisted_6 = { class: "pr-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, {
    title: 'Privacy & Support',
    mainSectionClass: "!h-fit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center cursor-pointer",
            onClick: ($event: any) => (_ctx.handleSettings(item))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_icon, {
                    name: item.icon,
                    class: _normalizeClass(item.icon_size)
                  }, null, 8, ["name", "class"])
                ])
              ]),
              _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_icon, {
                name: 'chevron-right-grey',
                class: "!h-[13px]"
              })
            ])
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}