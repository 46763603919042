<template>
  <app-wrapper>
    <app-reset-password-page />
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { AppResetPasswordPage } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppResetPasswordPage,
    AppWrapper,
  },
  name: "ResetPasswordPage",
  layout: "Auth",
  middlewares: {
    tracking_data: {
      lable: "Reset Password Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Reset Password",
    });

    return {};
  },
});
</script>
