import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "flex flex-col items-center pt-4 z-[400] w-full justify-center !font-inter fixed left-0 bottom-0 mdlg:!hidden md:!hidden bg-white dark:bg-black border-t-[1px] border-t-gray-200 dark:!border-t-gray-500",
  style: `
        padding-bottom: calc(env(safe-area-inset-bottom) + 12px) !important;`
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(`w-full  flex flex-row items-center justify-between `)
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_cart = _resolveComponent("app-cart")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.Logic.Common.currentAccountType() == 'client')
      ? (_openBlock(), _createBlock(_component_app_cart, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.tabs, (tab, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index,
          to: tab.path,
          class: "flex flex-col space-y-[6px] items-center justify-center",
          style: _normalizeStyle(`width: ${100 / $props.tabs.length}% ;`)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_icon, {
              name: 
            $setup.hoverTab == tab.icon || $props.tabIsActive(tab.routeTag)
              ? `bottom-bar/${tab.icon}-active${
                  $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`
              : `bottom-bar/${tab.icon}${
                  $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`
          ,
              "custom-class": ` h-[20px] `
            }, null, 8, ["name"]),
            _createVNode(_component_app_normal_text, {
              color: ` ${
            $props.tabIsActive(tab.routeTag)
              ? 'text-primary-main dark:!text-white'
              : 'text-gray-500 dark:!text-gray-300'
          } `,
              customClass: `!text-[11px] ${
            $props.tabIsActive(tab.routeTag) ? '!font-semibold' : ''
          }`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(tab.name), 1)
              ]),
              _: 2
            }, 1032, ["color", "customClass"])
          ]),
          _: 2
        }, 1032, ["to", "style"]))
      }), 128))
    ])
  ]))
}