<template>
  <div class="w-full h-full flex flex-col" :id="`pan${tabIndex}`">
    <img
      :src="
        url
          ?.replaceAll(
            'https://roof-income.nyc3.digitaloceanspaces.com',
            'https://shpt.blob.core.windows.net/shpt'
          )
          .replaceAll(
            'https://shpt.blob.core.windows.net/',
            'https://shptstorage-grazapdjemh4bzct.z02.azurefd.net/'
          ) || ''
      "
    />
  </div>
</template>
<script lang="ts">
import { onMounted } from "vue";
import AppImageLoader from "../AppImageLoader";
import panzoom from "panzoom";

export default {
  components: {
    AppImageLoader,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
      required: true,
    },
  },
  name: "AppImageInteractive",
  setup() {
    const tabIndex = Math.floor(Math.random() * 100000);

    onMounted(() => {
      setTimeout(() => {
        const container = document.getElementById(`pan${tabIndex}`);
        if (container != null) {
          panzoom(container);
        }
      });
    });

    return {
      tabIndex,
    };
  },
};
</script>
