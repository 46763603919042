<template>
  <span
    contenteditable="true"
    :class="`${isPlaceholderVisible ? '!text-gray-400 dark:!text-gray-400' : ''}`"
    @input="handleInput"
    @focus="handleFocus"
    @blur="handleBlur"
    ref="editable"
  >
  </span>
</template>

<script lang="ts">
import { computed, watch } from "vue";
import { ref, defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "AppContentEditable",
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    defaultValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue", "contentChanged"],
  setup(props, context) {
    const spanRef = ref();
    const placeholder = "Type something...";
    const isPlaceholderVisible = ref(false);
    const editable = ref();

    // Track the actual content
    const content = ref(props.defaultValue);

    onMounted(() => {
      if (props.defaultValue) {
        editable.value.textContent = props.defaultValue;
        isPlaceholderVisible.value = false;
      } else {
        editable.value.textContent = props.placeholder;
        isPlaceholderVisible.value = true;
      }
    });

    const placeholderText = computed(() =>
      isPlaceholderVisible.value ? placeholder : ""
    );

    const handleInput = (event: any) => {
      context.emit("update:modelValue", event.target.innerText);
      content.value = event.target.innerText;
      context.emit("contentChanged", event.target.innerText);
    };

    const handleFocus = () => {
      if (content.value.length == 0) {
        isPlaceholderVisible.value = false;
        editable.value.textContent = "";
      }
    };

    const handleBlur = () => {
      if (content.value.length == 0) {
        isPlaceholderVisible.value = true;
        editable.value.textContent = props.placeholder;
      }
    };

    return {
      spanRef,
      placeholderText,
      editable,
      isPlaceholderVisible,
      handleInput,
      handleFocus,
      handleBlur,
    };
  },
});
</script>
<style scoped></style>
