import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row" }
const _hoisted_2 = { class: "w-full flex-col flex pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_auth_layout = _resolveComponent("auth-layout")!

  return (_openBlock(), _createBlock(_component_auth_layout, {
    onGoBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.goBack())),
    "go-back": true,
    hasBottomBorder: _ctx.hasBottomBorder
  }, {
    "header-text": _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "top-section"),
      _createVNode(_component_app_form_wrapper, {
        ref: "formComp",
        parentRefs: _ctx.parentRefs,
        class: "w-full flex flex-col space-y-4 pt-4 pb-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (!_ctx.hasCustomSubTitle)
              ? (_openBlock(), _createBlock(_component_app_normal_text, {
                  key: 0,
                  class: "!text-[#664D7E]"
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Verify Your Phone Number. ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.hasCustomSubTitle)
              ? (_openBlock(), _createBlock(_component_app_header_text, { key: 1 }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "sub-title")
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_app_text_field, {
            placeholder: 'Enter phone number',
            modelValue: _ctx.phoneVerifyForm.phone,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneVerifyForm.phone) = $event)),
            hasTitle: true,
            type: "tel",
            padding: 'py-3 px-3',
            name: "Phone Number",
            ref: "phone",
            updateValue: _ctx.phoneVerifyForm.phone,
            rules: [
          _ctx.FormValidations.RequiredRule,
          _ctx.FormValidations.customValidator(
            _ctx.phoneVerifyForm.phone.length == 11,
            'Phone number must be valid'
          ),
        ],
            "content-rule": {
          addAfterCount: 0,
          characterToAdd: ``,
          max: 11,
        }
          }, {
            title: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Phone Number ")
            ])),
            _: 1
          }, 8, ["modelValue", "updateValue", "rules"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AppButton, {
              padding: 'py-3',
              "custom-class": 'rounded-[40px]',
              "bg-color": 'bg-primary-main',
              "text-color": 'text-white',
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.verifyPhone()), ["prevent"])),
              loading: _ctx.loaderSetup.loading
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Continue ")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 3
      }, 8, ["parentRefs"])
    ]),
    _: 3
  }, 8, ["hasBottomBorder"]))
}