import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, renderList as _renderList } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[100px]"
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-row items-start space-x-1 justify-between"
}
const _hoisted_5 = {
  key: 2,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[2] || (_cache[2] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ])))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.showAddNewShoplist)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
          !_ctx.data?.fromBusiness
            ? (_ctx.showAddNewShoplist = true)
            : _ctx.Logic.Common.GoToRoute('/shoplists/create')
        )),
                class: "w-full px-3 py-3 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
              }, [
                _createVNode(_component_app_normal_text, { class: "text-center text-gray-700" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(!_ctx.data?.fromBusiness ? "+ New Shoplist" : "+ New Offer"), 1)
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_app_text_field, {
                  hasTitle: false,
                  modelValue: _ctx.newShoplistName,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newShoplistName) = $event)),
                  "custom-class": "w-full !px-3 !py-2  !rounded-[5px]",
                  placeholder: "Enter Shoplist Name",
                  rules: [_ctx.FormValidations.RequiredRule],
                  name: 'Name'
                }, null, 8, ["modelValue", "rules"]),
                _createVNode(_component_app_button, {
                  loading: _ctx.addShoplistIsLoading,
                  class: "px-3 py-2 !rounded-[5px]",
                  onClick: _ctx.addNewShoplist
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Add ")
                  ])),
                  _: 1
                }, 8, ["loading", "onClick"])
              ]))
        ], 64))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          ((_ctx.ManyShoplists?.data?.length || 0) > 0 && _ctx.ManyShoplists)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                (_ctx.showShoplists)
                  ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.ManyShoplists.data, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-span-2 flex flex-row items-center justify-between px-3 py-3 pr-0 border-[2px] border-gray-200 rounded-[10px] cursor-pointer",
                        onClick: ($event: any) => (_ctx.addOrRemoveProductFromShoplist(item.id, item.name))
                      }, [
                        _createVNode(_component_app_normal_text, { class: "text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", null, [
                          _createVNode(_component_app_checkbox, {
                            standard: true,
                            modelValue: _ctx.selectedShoplists[item.id],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.selectedShoplists[item.id]) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ], 8, _hoisted_6))
                    }), 128))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.ManyShoplists.data, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-span-2 flex flex-row items-center justify-between px-3 py-3 pr-0 border-[2px] border-gray-200 rounded-[10px] cursor-pointer",
                        onClick: ($event: any) => (_ctx.addOrRemoveProductFromShoplist(item.id, item.name))
                      }, [
                        _createVNode(_component_app_normal_text, { class: "text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", null, [
                          _createVNode(_component_app_checkbox, {
                            standard: true,
                            modelValue: _ctx.selectedShoplists[item.id],
                            "onUpdate:modelValue": ($event: any) => ((_ctx.selectedShoplists[item.id]) = $event)
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ])
                      ], 8, _hoisted_7))
                    }), 128))
              ], 64))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "h-[50px]" }, null, -1))
  ]))
}