import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 cursor-pointer"
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(`absolute right-[6%]  top-[6%]`)
}
const _hoisted_3 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 !rounded-[10px] z-[999] flex flex-row items-center justify-center"
}
const _hoisted_4 = { class: "w-full flex flex-col space-y-[2px] items-start justify-start" }
const _hoisted_5 = { class: "w-full flex flex-row space-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_media_box, {
          data: _ctx.item,
          "is-video": !_ctx.item.is_grid,
          "is-grid": _ctx.item.is_grid,
          class: "!h-[160px] mdlg:!h-[200px] !rounded-[10px] border-[1px] border-grey-200 dark:border-grey-800"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, [
              _createElementVNode("span", {
                class: _normalizeClass(`px-2 py-1 rounded-[4px] ${
            _ctx.item.reward_type == 'cashback' ? 'bg-primary-900' : 'bg-[#FFAA00]'
          } flex flex-row items-center justify-center`)
              }, [
                _createVNode(_component_app_normal_text, { class: "!text-white !text-[10px] text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.item.reward_type == "cashback"
                ? `${parseFloat(
                    _ctx.item?.reward_percentage?.toFixed(2) || "0"
                  )}% Back`
                : `Save ${parseFloat(
                    _ctx.item?.reward_percentage?.toFixed(2) || "0"
                  )}%`), 1)
                  ]),
                  _: 1
                })
              ], 2)
            ]),
            (!_ctx.item.is_grid && !_ctx.item.video_url)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_app_normal_text, { class: "!text-white !text-[12px]" }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode(" Processing... ")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["data", "is-video", "is-grid"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_normal_text, { class: "!line-clamp-1 text-left !text-black dark:!text-white !text-xs font-semibold" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.title), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_icon, {
              name: 'shoplist-icon',
              customClass: 'h-[13px]'
            }),
            _createVNode(_component_app_normal_text, { class: "!text-[11px] dark:!text-white" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.stats?.items) + " Items ", 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}