import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 relative" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col pt-1 pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_otp_input = _resolveComponent("app-otp-input")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, { class: "w-full text-left !text-gray-600" }, {
      default: _withCtx(() => [
        _createTextVNode(" A 4 digit code has been sent to your phone (" + _toDisplayString(_ctx.localStorage.getItem("acc_phone")) + ") ", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_otp_input, {
        numberOfInput: 4,
        sizeStyle: 'w-[74px] h-[70px] xs:w-[60px] xs:h-[50px]',
        "otp-value": _ctx.otpForm.otp,
        onOnFilled: _ctx.onOtpIsFilled,
        type: 'text',
        onChangeOTP: _ctx.onChangeOTP
      }, null, 8, ["otp-value", "onOnFilled", "onChangeOTP"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_button, {
        customClass: 'w-full',
        padding: 'py-4',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.verifyOtp())),
        loading: _ctx.loading
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Verify ")
        ])),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}