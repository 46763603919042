import { CombinedError } from 'urql'
import {
  AcceptQuoteForRefundRequest,
  Airports,
  AppliedRefundResponse,
  FlightBooking,
  FlightBookingPaginator,
  FlightRevalidationDetails,
  MutationRequestQuoteforrefundArgs,
  QueryGetFlightFareRulesArgs,
  Quoteforrefund,
  SearchFlightRequest,
  SingleFlight,
  SingleFlightFareRules,
  TripDetails,
  UpdateflightoptionsRequest,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'
import { Logic } from '..'

export default class Travel extends Common {
  constructor() {
    super()
  }

  // Base variables
  public ManyAirports: Airports[] | undefined
  public ManyFlights: SingleFlight[] | undefined
  public SingleTripDetails: TripDetails | undefined
  public SingleQuoteforrefund: Quoteforrefund | undefined
  public SingleAppliedRefund: AppliedRefundResponse | undefined
  public ManyFlightFareRules: SingleFlightFareRules[] | undefined
  public SingleFlightValidationDetails: FlightRevalidationDetails | undefined
  public UserFlightBookings: FlightBookingPaginator | undefined
  public SearchFlightInput: SearchFlightRequest | undefined
  public GetFlightFareRulesInput: QueryGetFlightFareRulesArgs | undefined
  public SingleFlightBooking: FlightBooking | undefined

  // Mutation input variables
  public UpdateflightoptionsInput: UpdateflightoptionsRequest | undefined
  public RequestQuoteforrefundInput:
    | MutationRequestQuoteforrefundArgs
    | undefined
  public AcceptQuoteForRefundInput: AcceptQuoteForRefundRequest | undefined

  // Queries
  public SearchAirports = (query: string) => {
    return $api.travel
      .SearchAirports(query)
      .then((response) => {
        this.ManyAirports = response.data?.SearchAirports
        return response.data?.SearchAirports
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetFlightBooking = (reference: string) => {
    return $api.travel
      .GetFlightBooking(reference)
      .then((response) => {
        this.SingleFlightBooking = response.data?.FlightBooking
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserFlightBookings = () => {
    return $api.travel
      .UserFlightBookings(1, 50, Logic.Auth.AuthUser?.uuid || '')
      .then((response) => {
        this.UserFlightBookings = response.data?.GetUserFlightBookings
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public SearchFlight = () => {
    if (this.SearchFlightInput) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: true,
      })
      return $api.travel
        .SearchFlight(this.SearchFlightInput)
        .then((response) => {
          this.ManyFlights = response.data?.SearchFlight
          Logic.Common.hideLoader()
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          throw error
        })
    }
  }

  public GetTripDetails = (booking_reference: string) => {
    return $api.travel
      .GetTripDetails(booking_reference)
      .then((response) => {
        this.SingleTripDetails = response.data?.GetTripDetails
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetQuoteforrefund = (quote_id: string) => {
    return $api.travel
      .GetQuoteforrefund(quote_id)
      .then((response) => {
        this.SingleQuoteforrefund = response.data?.GetQuoteforrefund
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetAppliedRefundRequest = (booking_reference: string) => {
    return $api.travel
      .GetAppliedRefundRequest(booking_reference)
      .then((response) => {
        this.SingleAppliedRefund = response.data?.GetAppliedRefundRequest
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetFlightFareRules = () => {
    if (this.GetFlightFareRulesInput) {
      return $api.travel
        .GetFlightFareRules(this.GetFlightFareRulesInput)
        .then((response) => {
          this.ManyFlightFareRules = response.data?.GetFlightFareRules
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          throw error
        })
    }
  }

  public RevalidateFlight = (fare_source_code: string) => {
    return $api.travel
      .RevalidateFlight(fare_source_code)
      .then((response) => {
        this.SingleFlightValidationDetails = response.data?.RevalidateFlight
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  // Mutations

  public Updateflightoptions = () => {
    if (this.UpdateflightoptionsInput) {
      return $api.travel
        .Updateflightoptions(this.UpdateflightoptionsInput)
        .then((response) => {
          return response.data?.Updateflightoptions
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public OrderFlightTicker = (booking_reference: string) => {
    return $api.travel
      .OrderFlightTicker(booking_reference)
      .then((response) => {
        return response.data?.OrderFlightTicket
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public CancelBooking = (booking_reference: string) => {
    return $api.travel
      .CancelBooking(booking_reference)
      .then((response) => {
        this.SingleFlightBooking = response.data?.CancelBooking
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public AcceptSchedulechange = (booking_reference: string) => {
    return $api.travel
      .AcceptSchedulechange(booking_reference)
      .then((response) => {
        return response.data?.AcceptSchedulechange
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public ApplyForRefund = (booking_reference: string) => {
    return $api.travel
      .ApplyForRefund(booking_reference)
      .then((response) => {
        return response.data?.ApplyForRefund
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public RequestQuoteforrefund = () => {
    if (this.RequestQuoteforrefundInput) {
      return $api.travel
        .RequestQuoteforrefund(this.RequestQuoteforrefundInput)
        .then((response) => {
          return response.data?.RequestQuoteforrefund
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public AcceptQuoteForRefund = () => {
    if (this.AcceptQuoteForRefundInput) {
      return $api.travel
        .AcceptQuoteForRefund(this.AcceptQuoteForRefundInput)
        .then((response) => {
          return response.data?.AcceptQuoteForRefund
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }
}
