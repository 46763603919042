import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 pb-4 relative items-center justify-center md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "py-2 flex items-center justify-center px-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_image_loader, {
      photoUrl: _ctx.data.isWon ? _ctx.data.data.photo_url : '/images/lock-badge.svg',
      class: "!h-[100px] !w-[100px] rounded-full"
    }, null, 8, ["photoUrl"]),
    _createVNode(_component_app_header_text, { class: "!text-base text-center" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.data.title) + " Badge ", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_normal_text, { class: "!text-[#664D7E] dark:!text-primary-100 text-center" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.data.description), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_button, {
        class: _normalizeClass(`w-full py-3 ${_ctx.data.isWon ? '' : 'opacity-50'}`)
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Share Achievement ")
        ])),
        _: 1
      }, 8, ["class"])
    ])
  ]))
}