import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row justify-end" }
const _hoisted_2 = { class: "w-full flex flex-col items-center justify-center pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-2 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select, {
        placeholder: 'Select...',
        hasTitle: true,
        paddings: 'py-3 px-4',
        modelValue: _ctx.bankCode,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.bankCode) = $event)),
        "auto-complete": true,
        ref: "bank_code",
        customClass: 'dark:!bg-grey-800',
        theme: _ctx.Logic.Common.currentAppTheme(),
        options: _ctx.CommercialBanksOptions
      }, {
        title: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Select bank ")
        ])),
        _: 1
      }, 8, ["modelValue", "theme", "options"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter account number',
        hasTitle: true,
        type: 'tel',
        padding: 'py-3 px-4',
        name: "Account number",
        ref: "bank_account",
        modelValue: _ctx.accountNumber,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accountNumber) = $event)),
        rules: [_ctx.FormValidations.RequiredRule],
        "content-rule": {
        addAfterCount: 0,
        characterToAdd: ``,
        max: 10,
      }
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Account number ")
        ])),
        _: 1
      }, 8, ["modelValue", "rules"]),
      _createElementVNode("div", _hoisted_1, [
        (!_ctx.accountResolved && _ctx.accountNumber.length >= 10)
          ? (_openBlock(), _createBlock(_component_app_normal_text, {
              key: 0,
              class: _normalizeClass('!text-secondary-main')
            }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Resolving account... ")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.accountResolved && _ctx.formData.account_name)
          ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 1 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formData.account_name), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_app_button, {
          onClick: _withModifiers(_ctx.validateForm, ["prevent"]),
          class: _normalizeClass(`!py-3 w-full  ${_ctx.accountResolved ? '' : 'opacity-50'}`)
        }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Add account ")
          ])),
          _: 1
        }, 8, ["onClick", "class"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}