<template>
  <div
    :class="`w-full grid ${
      data.length == 2 ? 'grid-cols-2' : 'grid-cols-3'
    }  px-1 py-1 bg-primary-50 rounded-[8px] dark:!bg-gray-700 sticky top-0 z-40 ${customClass}`"
    v-if="data"
  >
    <div
      v-for="(item, index) in data"
      :key="index"
      :class="`flex flex-row items-center justify-center py-2 space-x-1 col-span-1 cursor-pointer ${
        item.id == selectedItem
          ? 'bg-white dark:bg-gray-900 rounded-[6px] customShadow '
          : ''
      }`"
      @click="selectedItem = item.id"
    >
      <app-normal-text
        :class="` !font-semibold !text-xs ${
          item.id == selectedItem
            ? '!text-black dark:!text-white'
            : '!text-grey-700 dark:!text-gray-400'
        }`"
      >
        {{ item.title }}
      </app-normal-text>

      <div
        :class="`w-[16px] h-[16px] rounded-full  ${
          item.id == selectedItem ? badgeColors.active : badgeColors.inactive
        } flex flex-row items-center justify-center`"
        v-if="item.unread"
      >
        <app-normal-text :color="'text-white'" :customClass="'!text-[10px]'">
          {{ item.unread }}
        </app-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";

export default defineComponent({
  components: {
    AppImageLoader,
    AppNormalText,
    AppIcon,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    badgeColors: {
      type: Object as () => {
        active: string;
        inactive: string;
      },
      default: {
        active: "bg-red-500",
        inactive: "bg-[#664D7E]",
      },
      required: false,
    },
    data: {
      type: Array as () => {
        title: string;
        id: string;
        unread?: number;
      }[],
      required: true,
    },
  },
  emits: ["update:modelValue"],
  name: "AppTabs",
  setup(props, context) {
    const selectedItem = ref("");

    const setSelectedItem = () => {
      context.emit("update:modelValue", selectedItem.value);
    };

    onMounted(() => {
      if (props.data) {
        selectedItem.value = props.data[0].id;
        setSelectedItem();
      }
    });

    watch(selectedItem, () => {
      setSelectedItem();
    });

    return {
      selectedItem,
    };
  },
});
</script>
<style scoped>
.customShadow {
  box-shadow: 0px 8px 12px 0px rgba(66, 66, 66, 0.08);
}
</style>
