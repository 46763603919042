import { createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col py-3 px-3 rounded-[10px] bg-purple-50 dark:bg-purple-300"
}
const _hoisted_3 = { class: "pr-1" }
const _hoisted_4 = { class: "pr-1" }
const _hoisted_5 = { class: "flex flex-col space-y-3 pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_SSO = _resolveComponent("SSO")!
  const _component_auth_layout = _resolveComponent("auth-layout")!

  return (_openBlock(), _createBlock(_component_auth_layout, {
    onButtonClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login'))),
    onGoBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Logic.Common.goBack())),
    "go-back": false,
    "right-button": true
  }, {
    "button-text": _withCtx(() => _cache[6] || (_cache[6] = [
      _createTextVNode("Login")
    ])),
    "header-text": _withCtx(() => _cache[7] || (_cache[7] = [
      _createTextVNode("Sign Up")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_app_form_wrapper, {
        ref: "formComp",
        parentRefs: _ctx.parentRefs,
        class: "w-full flex flex-col space-y-4 pt-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.Logic.Common.currentAccountType() == 'client')
              ? (_openBlock(), _createBlock(_component_app_normal_text, {
                  key: 0,
                  class: "!text-[#664D7E] dark:!text-primary-100"
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Join Shoppoint and get "),
                    _createElementVNode("span", { class: "text-primary-400 font-semibold dark:!text-primary-50" }, "1,000 points", -1),
                    _createTextVNode(" sign up bonus ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.Logic.Common.currentAccountType() == 'merchant')
              ? (_openBlock(), _createBlock(_component_app_normal_text, {
                  key: 1,
                  class: "!text-[#664D7E] dark:!text-primary-100"
                }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Signup and get "),
                    _createElementVNode("span", { class: "text-primary-400 font-semibold dark:!text-primary-50" }, "Free N100,000 Advert Credit", -1),
                    _createTextVNode(" to promote your products ")
                  ])),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_app_select, {
            placeholder: 'Choose Country',
            hasTitle: true,
            paddings: 'py-3 px-3',
            customClass: 'dark:!bg-grey-800',
            options: _ctx.countries,
            "update-key": _ctx.formData.country,
            modelValue: _ctx.formData.country,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.country) = $event)),
            theme: _ctx.Logic.Common.currentAppTheme()
          }, {
            title: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Country of residence ")
            ])),
            _: 1
          }, 8, ["options", "update-key", "modelValue", "theme"]),
          (_ctx.formData.country != 'NG')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_app_normal_text, { class: "!flex !flex-row !text-center !space-x-2" }, {
                  default: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createElementVNode("span", { class: "text-left !text-gray-700" }, [
                      _createElementVNode("span", { class: "!font-extrabold !text-black" }, "Shoppoint Rewards"),
                      _createTextVNode(" is coming soon to your country! ")
                    ], -1)
                  ])),
                  _: 1
                })
              ]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createVNode(_component_AppTextField, {
                  "has-title": true,
                  "custom-class": '',
                  placeholder: 'Input Value',
                  "focus-border": 'border-primary-400',
                  name: "Full name",
                  ref: "full_name",
                  rules: [
            _ctx.FormValidations.RequiredRule,
            _ctx.FormValidations.customValidator(
              _ctx.formData.full_name.length > 5,
              'Require atleast 5 characters'
            ),
          ],
                  modelValue: _ctx.formData.full_name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.full_name) = $event))
                }, {
                  title: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Full Name ")
                  ])),
                  "inner-prefix": _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, [
                      _createVNode(_component_app_icon, {
                        name: 'user',
                        customClass: 'h-[16px]'
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["rules", "modelValue"]),
                _createVNode(_component_AppTextField, {
                  "has-title": true,
                  "custom-class": '',
                  placeholder: 'Input Value',
                  type: 'email',
                  ref: "email",
                  name: "Email",
                  "focus-border": 'border-primary-400',
                  rules: [_ctx.FormValidations.EmailRule],
                  modelValue: _ctx.formData.email,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.email) = $event))
                }, {
                  title: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Email ")
                  ])),
                  "inner-prefix": _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, [
                      _createVNode(_component_app_icon, {
                        name: 'envelope',
                        customClass: 'h-[14px]'
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["rules", "modelValue"]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_AppButton, {
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.signUp()), ["prevent"])),
                    padding: 'py-3',
                    "bg-color": 'bg-primary-main',
                    "text-color": 'text-white',
                    loading: _ctx.loaderSetup.loading
                  }, {
                    default: _withCtx(() => _cache[14] || (_cache[14] = [
                      _createTextVNode(" Continue ")
                    ])),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _createElementVNode("div", null, [
                  _createVNode(_component_app_normal_text, { customClass: 'text-left md:!text-center mdlg:!text-center' }, {
                    default: _withCtx(() => _cache[15] || (_cache[15] = [
                      _createTextVNode(" By signing up, you agree to our "),
                      _createElementVNode("a", {
                        href: "https://www.getshoppoint.com/terms",
                        target: "_blank",
                        class: "text-primary-400 dark:!text-primary-200"
                      }, "Terms of Service", -1),
                      _createTextVNode(" and "),
                      _createElementVNode("a", {
                        href: "https://www.getshoppoint.com/privacy-policy",
                        target: "_blank",
                        class: "text-primary-400 dark:!text-primary-200"
                      }, "Privacy Policy", -1),
                      _createTextVNode(". ")
                    ])),
                    _: 1
                  })
                ])
              ], 64))
        ]),
        _: 1
      }, 8, ["parentRefs"]),
      (_ctx.formData.country == 'NG')
        ? (_openBlock(), _createBlock(_component_SSO, {
            key: 0,
            fromAction: 'signUp',
            class: "pt-5",
            isRelative: true
          }))
        : _createCommentVNode("", true),
      _cache[16] || (_cache[16] = _createElementVNode("div", {
        class: "w-full flex flex-row justify-center items-center",
        style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
      }, null, -1))
    ]),
    _: 1
  }))
}