import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full flex flex-col items-center justify-start h-full relative space-y-2",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px)"}
}
const _hoisted_2 = { class: "w-full flex flex-col items-center justify-center px-4 mdlg:!pt-2 md:!pt-2 pb-2" }
const _hoisted_3 = { class: "w-full flex flex-col justify-center items-center h-[70%] z-40" }
const _hoisted_4 = {
  class: "w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}
const _hoisted_5 = { class: "w-full flex flex-col items-center justify-start h-full relative" }
const _hoisted_6 = { class: "w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4" }
const _hoisted_7 = { class: "w-full flex flex-col justify-center items-center h-[70%] z-40" }
const _hoisted_8 = {
  class: "w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}
const _hoisted_9 = {
  class: "w-full flex flex-col items-center justify-start h-full relative",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px)"}
}
const _hoisted_10 = { class: "w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4" }
const _hoisted_11 = { class: "w-full flex flex-col justify-center items-center h-[70%] z-40" }
const _hoisted_12 = {
  class: "w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}
const _hoisted_13 = { class: "w-full flex flex-col items-center justify-start h-full relative" }
const _hoisted_14 = { class: "w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4" }
const _hoisted_15 = { class: "w-full flex flex-col justify-center items-center h-[70%] z-40" }
const _hoisted_16 = {
  class: "w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}
const _hoisted_17 = {
  class: "w-full flex flex-col items-center justify-start h-full relative",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px)"}
}
const _hoisted_18 = { class: "w-full flex flex-col justify-center items-center h-[55%] z-10" }
const _hoisted_19 = {
  key: 0,
  class: "w-full flex flex-col space-y-4 items-center justify-between space-x-3 px-4 pt-4 absolute bottom-0 left-0 right-0 h-auto z-30 bg-white rounded-t-[10px]",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px)"}
}
const _hoisted_20 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_21 = { class: "w-full flex flex-col space-y-3 justify-center items-center" }
const _hoisted_22 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_SSO = _resolveComponent("SSO")!
  const _component_app_swiper = _resolveComponent("app-swiper")!

  return (_openBlock(), _createBlock(_component_app_swiper, {
    "free-mode": false,
    "show-pagination": false,
    "space-between": 0,
    "slide-per-view": 1,
    currentSlidePosition: _ctx.currentSlidePosition,
    "custom-class": "!h-full ",
    swiperClass: '',
    modelValue: _ctx.slidePosition,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.slidePosition) = $event)),
    "auto-play": 
      _ctx.Logic.Common.currentBuildType() == 'web'
        ? {
            delay: 7000,
          }
        : false
    ,
    id: "swiperContainer"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_swiper_slide, { class: "!h-screen !flex !flex-col items-start relative justify-center bg-[#470087]" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_header_text, { class: "!text-white font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" Reward Loyalty ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-white text-center !font-regular !text-xl xs:!text-sm font-figtree" }, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" Give your customers rewards "),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" every time they buy from you. ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_image_loader, {
                photoUrl: '/images/splash/splash-1.png',
                class: "w-[90%] h-full rounded-[10px]"
              })
            ]),
            _cache[15] || (_cache[15] = _createElementVNode("div", {
              class: "w-full flex flex-col absolute bottom-0 left-0 right-0 h-[50%]",
              style: {"background":"url('/images/splash/bottom-white-bg.png') no-repeat top\n              center","background-size":"cover"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                customClass: `!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              _ctx.Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`,
                border: '',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.currentSlidePosition = 4))
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Skip ")
                ])),
                _: 1
              }, 8, ["customClass"]),
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                "custom-class": '!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentSlidePosition++)),
                border: ''
              }, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Next ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, { class: "!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _cache[20] || (_cache[20] = _createElementVNode("div", {
              style: {"height":"calc(env(safe-area-inset-top) + 16px)"},
              class: "w-full"
            }, null, -1)),
            _cache[21] || (_cache[21] = _createElementVNode("div", {
              class: "w-full flex flex-col absolute top-0 left-0 right-0 h-[50%]",
              style: {"background":"url('/images/splash/top-white-bg.png') no-repeat bottom\n              center","background-size":"cover"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_header_text, { class: "!text-black font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree" }, {
                default: _withCtx(() => _cache[16] || (_cache[16] = [
                  _createTextVNode(" Boost Retail Sales ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-black text-center !font-regular !text-xl xs:!text-sm font-figtree" }, {
                default: _withCtx(() => _cache[17] || (_cache[17] = [
                  _createTextVNode(" Drive more sales to your shop "),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" with exciting cashback offers. ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_app_image_loader, {
                photoUrl: '/images/splash/splash-2.png',
                class: "w-[90%] h-full rounded-[10px]"
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                customClass: `!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              _ctx.Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`,
                border: '',
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.currentSlidePosition = 4))
              }, {
                default: _withCtx(() => _cache[18] || (_cache[18] = [
                  _createTextVNode(" Skip ")
                ])),
                _: 1
              }, 8, ["customClass"]),
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                "custom-class": '!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree',
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.currentSlidePosition++)),
                border: ''
              }, {
                default: _withCtx(() => _cache[19] || (_cache[19] = [
                  _createTextVNode(" Next ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, { class: "!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_app_header_text, { class: "!text-white font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree" }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode(" Engage Customers ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-white text-center !font-regular !text-xl xs:!text-sm font-figtree" }, {
                default: _withCtx(() => _cache[23] || (_cache[23] = [
                  _createTextVNode(" Easily create special deals "),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" that match your customers' needs. ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_app_image_loader, {
                photoUrl: '/images/splash/splash-3.png',
                class: "w-[90%] h-full rounded-[10px]"
              })
            ]),
            _cache[26] || (_cache[26] = _createElementVNode("div", {
              class: "w-full flex flex-col absolute bottom-0 left-0 right-0 h-[50%]",
              style: {"background":"url('/images/splash/bottom-white-bg.png') no-repeat top\n              center","background-size":"cover"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                customClass: `!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              _ctx.Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`,
                border: '',
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentSlidePosition = 4))
              }, {
                default: _withCtx(() => _cache[24] || (_cache[24] = [
                  _createTextVNode(" Skip ")
                ])),
                _: 1
              }, 8, ["customClass"]),
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                "custom-class": '!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree',
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.currentSlidePosition++)),
                border: ''
              }, {
                default: _withCtx(() => _cache[25] || (_cache[25] = [
                  _createTextVNode(" Next ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, { class: "!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_13, [
            _cache[31] || (_cache[31] = _createElementVNode("div", {
              style: {"height":"calc(env(safe-area-inset-top) + 16px)"},
              class: "w-full"
            }, null, -1)),
            _cache[32] || (_cache[32] = _createElementVNode("div", {
              class: "w-full flex flex-col absolute top-0 left-0 right-0 h-[50%]",
              style: {"background":"url('/images/splash/top-white-bg.png') no-repeat bottom\n              center","background-size":"cover"}
            }, null, -1)),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_app_header_text, { class: "!text-black font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree" }, {
                default: _withCtx(() => _cache[27] || (_cache[27] = [
                  _createTextVNode(" Monetise Engagement ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-black text-center !font-regular !text-xl xs:!text-sm font-figtree" }, {
                default: _withCtx(() => _cache[28] || (_cache[28] = [
                  _createTextVNode(" Link products and "),
                  _createElementVNode("br", null, null, -1),
                  _createTextVNode(" monetise your social media videos. ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_app_image_loader, {
                photoUrl: '/images/splash/splash-4.png',
                class: "w-[90%] h-full rounded-[10px]"
              })
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                customClass: `!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              _ctx.Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`,
                border: '',
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.currentSlidePosition = 4))
              }, {
                default: _withCtx(() => _cache[29] || (_cache[29] = [
                  _createTextVNode(" Skip ")
                ])),
                _: 1
              }, 8, ["customClass"]),
              _createVNode(_component_app_button, {
                padding: 'px-9 py-3 xs:!px-6 xs:!py-2',
                "custom-class": '!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree',
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.currentSlidePosition++)),
                border: ''
              }, {
                default: _withCtx(() => _cache[30] || (_cache[30] = [
                  _createTextVNode(" Next ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, { class: "!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("div", _hoisted_18, [
              _createVNode(_component_app_icon, {
                name: "base-logo",
                class: "h-[35px]"
              })
            ]),
            (_ctx.Logic.Common.currentBuildType() == 'mobile')
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_app_header_text, { class: "!text-black font-bold text-center !text-sm xs:!text-xs" }, {
                      default: _withCtx(() => _cache[33] || (_cache[33] = [
                        _createTextVNode(" Get Started ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_button, {
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login'))),
                      "custom-class": 'rounded-[40px] font-500 text-[14px]',
                      "bg-color": 'bg-primary-50',
                      "text-color": 'text-primary-500',
                      padding: 'px-3 py-[2px]'
                    }, {
                      default: _withCtx(() => _cache[34] || (_cache[34] = [
                        _createTextVNode(" Login ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_app_button, {
                        onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login')), ["prevent"])),
                        padding: 'py-3',
                        "bg-color": 'bg-primary-main',
                        "text-color": 'text-white',
                        class: "w-full"
                      }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createTextVNode(" Sign in with Email ")
                        ])),
                        _: 1
                      })
                    ]),
                    _createVNode(_component_SSO, {
                      fromAction: 'signIn',
                      isRelative: true
                    })
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["currentSlidePosition", "modelValue", "auto-play"]))
}