<template>
  <div
    :class="`flex flex-row w-full space-x-1 px-1 py-1 bg-grey-50 dark:bg-gray-900 rounded-[40px] justify-between`"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      :class="`rounded-[30px] items-center justify-center flex flex-row px-2 py-2 cursor-pointer ${
        currentSelectedItem == item.key ? `bg-white dark:!bg-gray-700` : ''
      }`"
      @click="currentSelectedItem = item.key"
      :style="`width: ${100 / items.length}%`"
    >
      <app-normal-text
        :color="`${
          currentSelectedItem == item.key
            ? `!text-gray-800 dark:!text-white !font-semibold`
            : `!text-gray-500 dark:!text-grey-300`
        }`"
        :customClass="`!text-[12px] mdlg:!text-xs`"
      >
        {{ item.name }}
      </app-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { AppNormalText } from "../AppTypography";
export default defineComponent({
  components: {
    AppNormalText,
  },
  props: {
    customClass: {
      type: String,
      required: false,
    },
    items: {
      type: Array as () => {
        name: string;
        key: string;
      }[],
      default: () => [],
    },
  },
  name: "AppSwitch",
  emits: ["update:modelValue"],
  setup(props, context) {
    const currentSelectedItem = ref("");

    onMounted(() => {
      if (props.items.length) {
        currentSelectedItem.value = props.items[0].key;
      }
    });

    watch(currentSelectedItem, () => {
      context.emit("update:modelValue", currentSelectedItem.value);
    });

    return {
      currentSelectedItem,
    };
  },
});
</script>
