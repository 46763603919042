import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col z-40 h-full justify-between" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-1 px-3" }
const _hoisted_5 = { class: "w-full flex flex-row rounded-[38px] px-2 py-2 bg-[#340163] items-center space-x-2" }
const _hoisted_6 = { class: "w-[22px]" }
const _hoisted_7 = { class: "font-semibold" }
const _hoisted_8 = { class: "w-full flex flex-col px-4 py-4 rounded-[10px] bg-[#F7F7F7] space-y-3" }
const _hoisted_9 = { class: "w-full flex flex-row items-center justify-between px-3 py-2 rounded-[40px] border-[1px] border-[#E8E8E8]" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_11 = { class: "w-full flex flex-col space-y-5" }
const _hoisted_12 = { class: "w-[42px]" }
const _hoisted_13 = { class: "w-[42px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Referral' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_image_loader, {
                photoUrl: '/images/purple-bg.svg',
                class: "w-full flex flex-col space-y-3 h-[150px] mdlg:!h-[200px] px-2 py-2 !overflow-y-visible relative rounded-[10px]"
              }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("img", {
                    src: "/images/dropping-coins.svg",
                    class: "absolute bottom-[5%] right-[2%] h-[85%] z-0"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_app_normal_text, { class: "text-left !text-white" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Refer Friends, Earn more ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_header_text, { class: "!text-xl !text-white" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Get " + _toDisplayString(_ctx.AuthUser?.referral_summary?.point_per_referral) + "% Bonus ", 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_app_icon, {
                          name: 'referral-badge',
                          class: "!h-[25px]"
                        })
                      ]),
                      _createVNode(_component_app_normal_text, { class: "!text-left !text-white !font-light" }, {
                        default: _withCtx(() => [
                          _cache[3] || (_cache[3] = _createTextVNode(" Referral Points: ")),
                          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.AuthUser?.referral_summary?.point_gained,
                        true,
                        ""
                      )) + " pts ", 1)
                        ]),
                        _: 1
                      })
                    ])
                  ])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_app_normal_text, { class: "!text-left !text-[#664D7E] !text-[11px]" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" REFERAL CODE ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_app_normal_text, { class: "!text-[#240046]" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" SHPT-" + _toDisplayString(_ctx.AuthUser?.referral_summary?.referral_code), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  class: "flex flex-row space-x-1 items-center justify-end cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.copyCode()))
                }, [
                  _createVNode(_component_app_normal_text, { class: "!text-[#898989]" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.codeCopied ? "Copied!" : "Copy"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_icon, {
                    name: 'copy-grey',
                    class: "!h-[16px]"
                  })
                ])
              ]),
              _createVNode(_component_app_button, {
                class: "w-full flex flex-row items-center space-x-2 bg-[#9D4EDD]",
                padding: 'py-2',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.shareCode()))
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Share Code ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_app_header_text, { class: "!text-left" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" How it works ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.howItWorks, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "w-full flex flex-row items-center space-x-2"
                  }, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_app_icon, {
                          name: item.icon,
                          class: "h-[40px]"
                        }, null, 8, ["name"])
                      ])
                    ]),
                    _createVNode(_component_app_normal_text, { class: "!text-left !text-[12px] mdlg:!text-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.content), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ])
            ]),
            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}