import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pt-0 space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-4 md:!justify-center lg:!justify-center mdlg:!justify-center justify-center md:!h-[450px] lg:!h-[450px] mdlg:!h-[450px] h-[70vh]" }
const _hoisted_3 = { class: "w-full md:!hidden flex flex-col items-center justify-center pb-3 pt-4" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-4 md:!hidden" }
const _hoisted_5 = { class: "w-full flex flex-col pt-4 justify-center items-center pb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_auth_layout = _resolveComponent("auth-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_auth_layout, {
        onButtonClicked: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login'))),
        onGoBack: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.goBack())),
        "go-back": false,
        "right-button": false
      }, {
        "button-text": _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Login")
        ])),
        "header-text": _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Welcome to Shoppoint!")
        ])),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_normal_text, { class: "!text-gray-500 dark:!text-gray-400 !text-left hidden md:!block pt-4" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Unlock personalized cashback savings and rewards. ")
              ])),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_icon, {
                  name: 'logo-large',
                  customClass: 'h-[140px] w-[140px]'
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_normal_text, { class: "!text-gray-500 dark:!text-gray-400 !text-left" }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Unlock personalized cashback savings and rewards. ")
                  ])),
                  _: 1
                })
              ]),
              _createVNode(_component_app_header_text, { class: "type-welcome !text-2xl text-left !text-primary-500 dark:!text-primary-300 md:!text-center lg:!text-center mdlg:!text-center min-h-[100px]" }),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_button, {
                  class: "!w-fit !px-5 !py-2",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/signup')))
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode("Get Started")
                  ])),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}