import { capitalize } from 'vue'
import { Logic } from '.'
import { Badge, PointEarned } from '../gql/graphql'
import { RewardData } from '../types'
import { showOrderInfo } from './shop'

const badgeIsWon = (id: string) => {
  const currentBadge = Logic.Auth.AuthUser?.game_profile?.badges?.filter(
    (item) => item.id == id,
  )

  return (currentBadge?.length || 0) > 0
}

const shopSlugs = [
  'AIRTIME_PURCHASE',
  'DATA_PURCHASE',
  'CABLE_TV_PURCHASE',
  'ELECTRICITY_PURCHASE',
  'SHOPLIST_PURCHASED',
]

const paySlugs = ['EXTERNAL_BANK_TRANSFER', 'SHPT_TO_SHPT_TRANSFER']

const scanSlugs = ['SCANNED_RECEIPT']

const travelSlugs = ['NEW_FLIGHT_BOOKED']

const showBadgeInfo = (data: Badge, isWon: boolean) => {
  Logic.Common.showModal({
    show: true,
    type: 'badge_info',
    extraData: {
      data: data,
      isWon: isWon,
    },
  })
}

const getSingleReward = (reward: PointEarned, selectedFilter = 'shop') => {
  let title = reward.description.replace('Shoppoint for', '')
  let subtitle = ''
  let icon = 'reward'
  let isImage = false
  let enforceThumbnail = false

  // if (reward.points <= 0) {
  //   reward.claimed = true;
  // }

  if (reward.event_slug == 'SCANNED_RECEIPT') {
    title = capitalize(
      `${reward.scanned_receipt?.merchant_name?.toLocaleLowerCase() || ''}`,
    )
    subtitle = `#${
      reward.scanned_receipt?.invoice_no?.toLocaleLowerCase() || ''
    }`
    icon = reward.scanned_receipt?.image_url || ''
    enforceThumbnail = true
    isImage = true
  } else if (shopSlugs.includes(reward.event_slug)) {
    title = title.replace('Shoppoint for ', '')
    icon = 'shop_items'

    if (reward.sale) {
      title = `Bought ${Logic.Common.convertToMoney(
        reward.sale.total_amount,
        false,
        'ngn',
      )} ${reward.sale?.product?.description.replace('Buy ', '')}`
      icon = reward.sale?.product?.image_url || ''
      isImage = true
    }
  } else if (reward.event_slug == 'SHPT_TO_SHPT_TRANSFER') {
    title = `Shpt-to-shpt money transfer`
    icon = 'make-payment'

    if (reward.transaction) {
      title = `${Logic.Common.convertToMoney(
        reward.transaction.amount,
        false,
        'ngn',
      )} Sent to ${reward.transaction?.description?.replace(
        'Money transfer | ',
        '',
      )}`

      const extraData = JSON.parse(
        JSON.parse(reward.transaction.extra_data || ''),
      )
      if (extraData['shoppoint_user_uuid']) {
        subtitle = `@${extraData['shoppoint_username']}`
      }
    }
  } else if (reward.event_slug == 'EXTERNAL_BANK_TRANSFER') {
    title = title.replace('Shpt. for ', '')
    icon = 'make-payment'
  }

  let status = 'Claimed'
  let status_color: 'green' | 'red' | 'yellow' | 'blue' = 'green'

  if (!reward.claimed) {
    if (reward.processing_status == 'processing') {
      status = 'Processing'
      status_color = 'yellow'
    } else if (reward.processing_status == 'processed') {
      const expiryMinutesLeft = Logic.Common.momentInstance(
        reward.expire_at,
      ).diff(Logic.Common.momentInstance(new Date()), 'minute')

      if (expiryMinutesLeft > 0) {
        const daysDiff = Logic.Common.momentInstance(reward.expire_at).diff(
          Logic.Common.momentInstance(new Date()),
          'days',
        )
        status = `Expires in ${daysDiff} day${daysDiff > 1 ? 's' : ''}`
        status_color = 'blue'
      } else {
        status = 'Expired'
        status_color = 'red'
      }
    } else {
      status = 'Failed'
      status_color = 'red'
    }
  }

  if (reward.event_slug == 'SCANNED_RECEIPT') {
    if (reward.scanned_receipt?.status != 'successful') {
      status = capitalize(reward.scanned_receipt?.status || '')
      status_color = 'red'
      reward.points = 0
      reward.game_points = 0
    }
  }

  if (reward.event_slug == 'SHOPLIST_PURCHASED') {
    const orderStatus = reward.order?.status

    if (orderStatus == 'cancelled' || orderStatus == 'failed') {
      status = 'Cancelled'
      status_color = 'red'
    } else if (orderStatus != 'completed') {
      status = capitalize(orderStatus || '')
      status_color = 'yellow'
    }
    title = `${reward.order?.collection?.name} Purchase`
    subtitle = `#${reward.order?.uuid.split('-')[0].toUpperCase()}`
    isImage = true
    icon = reward.order?.business?.photo_url || ''
  }

  const isClaimed = reward.claimed

  const rewardData: RewardData = {
    title,
    subtitle,
    is_image: isImage,
    datetime: Logic.Common.fomartDate(reward.created_at, 'D MMM YYYY | H:mma'),
    icon,
    amount: Logic.Common.convertToMoney(reward.points, false, 'ngn'),
    points: Logic.Common.convertToMoney(
      reward.game_points > 0 ? reward.game_points : reward.points,
      false,
      '',
    ),
    status,
    status_color,
    uuid: reward.uuid,
    enforce_thumbnail: enforceThumbnail,
    total_spend: Logic.Common.convertToMoney(
      selectedFilter == 'bonus' ? reward.points : reward.money_amount,
      false,
      'ngn',
    ),
    action: () => {
      if (isClaimed) {
        if (reward.scanned_receipt && reward.event_slug == 'SCANNED_RECEIPT') {
          Logic.Shop.SingleScannedReceipt = {
            point_to_claim: reward.points,
            scanned_receipt: reward.scanned_receipt,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            game_points:
              reward.game_points > 0 ? reward.game_points : reward.points,
          }

          Logic.Common.GoToRoute(`/snap/info?earned=true&isEditable=true`)
        } else if (shopSlugs.includes(reward.event_slug)) {
          if (reward.sale?.transaction) {
            Logic.Common.GoToRoute(
              `/savings/transactions/${reward.sale?.transaction?.uuid}?ignoreBackRoute=true`,
            )
          } else if (reward.sale?.point_transaction) {
            Logic.Common.GoToRoute(
              `/savings/points/${reward.sale?.point_transaction?.uuid}?ignoreBackRoute=true`,
            )
          } else if (reward.event_slug == 'SHOPLIST_PURCHASED') {
            if (reward.order) {
              showOrderInfo(
                reward.order?.uuid?.split('-')[0] || '',
                reward.order,
                false,
              )
            }
          }
        } else if (reward.event_slug == 'SHPT_TO_SHPT_TRANSFER') {
          if (reward.transaction) {
            Logic.Common.GoToRoute(
              `/savings/transactions/${reward.entity_uuid}?ignoreBackRoute=true`,
            )
          } else if (reward.entity_uuid) {
            Logic.Common.GoToRoute(
              `/savings/points/${reward.entity_uuid}?ignoreBackRoute=true`,
            )
          }
        } else if (reward.event_slug == 'EXTERNAL_BANK_TRANSFER') {
          Logic.Common.GoToRoute(
            `/savings/transactions/${reward.entity_uuid?.replace(
              'transfer-',
              '',
            )}&ignoreBackRoute=true`,
          )
        } else if (reward.event_slug == 'NEW_FLIGHT_BOOKED') {
          Logic.Common.GoToRoute(
            `/rewards/bookings/flight/${reward.sale?.booked_flight?.reference}`,
          )
        } else {
          Logic.Common.GoToRoute(
            `/others/new_point_earned?point_earned_uuid=${reward.uuid}`,
          )
        }
      } else {
        if (reward.event_slug == 'SHOPLIST_PURCHASED') {
          if (reward.order) {
            showOrderInfo(
              reward.order?.uuid?.split('-')[0] || '',
              reward.order,
              false,
            )
          }
          return
        }
        Logic.Common.GoToRoute(
          `/others/new_point_earned?point_earned_uuid=${reward.uuid}`,
        )
      }
    },
  }
  return rewardData
}

export { badgeIsWon, showBadgeInfo, getSingleReward }
