<template>
  <div class="w-full flex flex-col space-y-3 pt-2 relative">
    <div
      class="w-full flex flex-row items-start justify-start"
      v-if="data.is_current"
    >
      <span class="px-3 py-1 bg-gray_50 rounded-[22px]">
        <app-normal-text
          :color="'!text-shop_dark_400'"
          :customClass="'!text-[10px] !uppercase'"
        >
          Your current tier
        </app-normal-text>
      </span>
    </div>
    <app-normal-text :custom-class="'!text-sm'">
      {{
        data.price
          ? `${Logic.Common.convertToMoney(
              data.price,
              false,
              "",
              false,
              "NGN "
            )}/Month`
          : "Free"
      }}
    </app-normal-text>

    <div
      :class="`w-full flex flex-col items-start rounded-[16px] px-4 py-4 ${data.bg_color} bg-opacity-10`"
    >
      <app-normal-text
        :customClass="'text-left'"
        :htmlContent="data.description"
      >
      </app-normal-text>
    </div>
    <div class="h-[40px]"></div>

    <div
      class="w-full flex flex-col pt-3 sticky bottom-0 left-0 pb-4"
      v-if="!data.is_current"
    >
      <app-button :customClass="'w-full'" :padding="'py-3'" @click="action()">
        Subscribe
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import AppNavCard from "../AppNavCard";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppNavCard,
    AppIcon,
    AppNormalText,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        is_current: boolean;
        price: number;
        description: string;
        uuid: string;
        bg_color: string;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppModalSubscriptionPlan",
  setup(props) {
    return {
      Logic,
    };
  },
});
</script>
