<template>
  <app-wrapper>
    <subpage-layout
      :title="'Limited Offers'"
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
      :showBottomPadding="false"
    >
      <template v-slot:extra-topbar-row>
        <!-- Banner ad -->
        <div class="w-full flex flex-col px-4 pb-1">
          <app-banner-ad />
        </div>
        <!-- Categories -->
        <div class="w-full flex flex-col pl-4">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
            id="categoryContainerShoplistFeatured"
          >
            <div class="flex flex-row py-2 pr-4">
              <div
                class="flex flex-row cursor-pointer pr-2"
                v-for="(category, index) in categoryOptions"
                :key="index"
                @click="selectedCategory = category.key"
                :id="`category${category.key}`"
              >
                <div
                  :class="` ${
                    selectedCategory == category.key
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-gray-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`"
                >
                  <app-normal-text
                    :class="`!whitespace-nowrap ${
                      selectedCategory == category.key
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-50'
                    }`"
                  >
                    {{ category.value }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-2 px-4">
        <div class="w-full flex flex-col pt-4">
          <app-virtual-scroller
            :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
            :data="featuredShoplists.data"
            :pagination="featuredShoplists.paginatorInfo"
            :fetchMore="fetchMoreShoplist"
            :fetchNew="fetchNewShoplist"
            :direction="'vertical'"
            ref="productVirtualScrollerRef"
          >
            <template #item-content="{ index, item }">
              <div class="col-span-1 flex flex-col">
                <app-shoplist-explore
                  class="w-full"
                  :key="index"
                  @click="handleShoplsistClick(item)"
                  :item="item"
                  :mediaBoxStyle="'!h-[140px] mdlg:!h-[200px] md:!h-[200px]'"
                  :theme="Logic.Common.currentAppTheme()"
                />
              </div>
            </template>
            <template #skeleton-loaders>
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
            </template>
          </app-virtual-scroller>
        </div>
        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppVirtualScroller,
  AppShoplistExplore,
  AppBannerAd,
} from "@shpt/ui-components";
import { MediaBox } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import { SelectOption } from "@shpt/ui-components/src/types";
import {
  ContentType,
  PaginatorInfo,
} from "@shpt/ui-components/src/gql/graphql";
import { getMediaBox } from "@shpt/ui-components/src/composable";
import { scrollToSpecificItem } from "@shpt/ui-components/src/composable";
import { Collection } from "@shpt/logic/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";

export default defineComponent({
  components: {
    AppNormalText,
    AppWrapper,
    AppVirtualScroller,
    AppShoplistExplore,
    AppBannerAd,
  },
  name: "ShoppingFeaturedPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategoriesWithProducts",
        method: "GetCategoriesWithProducts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyCollectionsByCategory",
        method: "GetCollectionsByCategory",
        params: [1, 10, false],
        requireAuth: true,
        ignoreProperty: true,
        useRouteQuery: true,
        queries: ["key"],
      },
    ],
    tracking_data: {
      lable: "Featured Shoplist Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Featured Shoplist",
    });

    const categoryOptions = reactive<SelectOption[]>([]);

    const selectedCategory = ref("All");
    const productVirtualScrollerRef = ref();

    const ManyCategoriesWithProducts = ref(
      Logic.Shoplist.ManyCategoriesWithProducts
    );
    const ManyCollectionsByCategory = ref(
      Logic.Shoplist.ManyCollectionsByCategory
    );

    const featuredShoplists = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const fetchNewShoplist = () => {
      const categoryId =
        categoryOptions.find(
          (category) => category.key === selectedCategory.value
        )?.extras || "0";

      return Logic.Shoplist.GetCollectionsByCategory(
        parseInt(categoryId),
        1,
        10,
        false
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreShoplist = (nextPage: number) => {
      const categoryId =
        categoryOptions.find(
          (category) => category.key === selectedCategory.value
        )?.extras || "0";

      return Logic.Shoplist.GetCollectionsByCategory(
        parseInt(categoryId),
        nextPage,
        10,
        false
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyCollectionsByCategory)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyCollectionsByCategory = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const setManyCollectionsByCategory = () => {
      featuredShoplists.data.length = 0;

      ManyCollectionsByCategory.value?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        featuredShoplists.data.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "collection",
          colors: {
            bg: "",
            text: "",
          },
        });

        // Add results to Logic.User.ExploreContent if the collection item is not there already

        const existingContent = JSON.parse(
          JSON.stringify(Logic.User.ExploreContent)
        );

        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });
      featuredShoplists.paginatorInfo =
        ManyCollectionsByCategory.value?.paginatorInfo;
    };

    const setCategory = () => {
      categoryOptions.length = 0;
      categoryOptions.push({
        key: "All",
        value: "All Categories",
      });
      ManyCategoriesWithProducts.value?.forEach((item) => {
        categoryOptions.push({
          key: item.uuid,
          value: item.name,
          extras: item.id.toString(),
        });
      });
    };

    const focusOnCategory = (key: string) => {
      scrollToSpecificItem(
        "categoryContainerShoplistFeatured",
        `category${key}`
      );
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(
          `/shoplist/product/${item.data.product_data?.uuid}`
        );
      }
    };

    watch(ManyCollectionsByCategory, () => {
      setManyCollectionsByCategory();
    });

    onIonViewWillEnter(() => {
      const queryKey = Logic.Common.route?.query.key as string;
      selectedCategory.value = queryKey == "0" ? "All" : queryKey;
      setCategory();
      setManyCollectionsByCategory();
      focusOnCategory(selectedCategory.value);
    });

    watch(selectedCategory, () => {
      featuredShoplists.data.length = 0;
      productVirtualScrollerRef.value?.resetList();
      setTimeout(() => {
        focusOnCategory(selectedCategory.value);
      }, 500);
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyCollectionsByCategory",
        ManyCollectionsByCategory
      );
      Logic.Shoplist.watchProperty(
        "ManyCollectionsByCategory",
        ManyCollectionsByCategory
      );
      if (Logic.Common.currentBuildType() == "web") {
        const queryKey = Logic.Common.route?.query.key as string;
        selectedCategory.value = queryKey == "0" ? "All" : queryKey;
        setCategory();
        setManyCollectionsByCategory();
        setTimeout(() => {
          focusOnCategory(selectedCategory.value);
        }, 500);
      }
    });

    return {
      Logic,
      selectedCategory,
      categoryOptions,
      featuredShoplists,
      productVirtualScrollerRef,
      fetchNewShoplist,
      fetchMoreShoplist,
      handleShoplsistClick,
    };
  },
});
</script>
