import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row items-center justify-start px-2 -mb-[33px]" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-5 xs:space-y-4 pt-[50px]" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-col space-y-4"
}
const _hoisted_4 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_5 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_6 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "w-full flex flex-col px-4" }
const _hoisted_9 = { class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-500 dark:bg-secondary-200 !bg-opacity-60" }
const _hoisted_10 = { class: "!text-black dark:!text-grey-50" }
const _hoisted_11 = { class: "!font-semibold" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_13 = { class: "w-full flex flex-col space-y-1 items-start justify-start" }
const _hoisted_14 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 px-4"
}
const _hoisted_15 = { class: "flex flex-row space-x-1 justify-end flex-grow" }
const _hoisted_16 = { class: "font-semibold text-primary-500 dark:!text-primary-300 px-1" }
const _hoisted_17 = { class: "w-full flex flex-row justify-end" }
const _hoisted_18 = { class: "font-semibold pl-1" }
const _hoisted_19 = {
  key: 1,
  class: "w-full flex flex-col space-y-3 px-4 min-h-[150px]"
}
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_22 = { class: "w-[24px]" }
const _hoisted_23 = { class: "flex flex-col" }
const _hoisted_24 = { class: "text-primary-500 dark:!text-primary-200 pl-1" }
const _hoisted_25 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: `Buy Gift Card`,
        type: 'image_card',
        photoUrl: '/images/gift-card-bg.png',
        brandImage: _ctx.brandImage
      }, {
        "extra-topbar-special": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_header_text, { customClass: '!font-bold dark!text-white' }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.SingleProduct.value?.merchant_name) + " Gift Cards ", 1)
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.SingleProduct)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_app_normal_text, { class: "!text-[12px] text-left px-4 uppercase" }, {
                      default: _withCtx(() => _cache[1] || (_cache[1] = [
                        _createTextVNode(" Select country ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billerCountries, (country, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "flex items-center justify-center",
                            key: index
                          }, [
                            _createElementVNode("div", {
                              class: "w-[56px] h-[56px] rounded-full relative cursor-pointer",
                              onClick: ($event: any) => (_ctx.selectedCountry = country.id)
                            }, [
                              _createVNode(_component_app_image_loader, {
                                "photo-url": country.logo,
                                class: _normalizeClass(`h-[56px] w-[56px] dark:border-[1px] dark:border-grey-50 ${
                        _ctx.selectedCountry == country.id
                          ? 'border-[2px] border-primary-400 rounded-full'
                          : ' rounded-full'
                      } `)
                              }, null, 8, ["photo-url", "class"]),
                              (_ctx.selectedCountry == country.id)
                                ? (_openBlock(), _createBlock(_component_app_icon, {
                                    key: 0,
                                    name: 'mark',
                                    customClass: 'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '
                                  }))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_7)
                          ]))
                        }), 128))
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("span", _hoisted_10, [
                        _cache[2] || (_cache[2] = _createTextVNode(" These gift cards can only be used in ")),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.selectedCountry), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("div", _hoisted_13, [
                      (
                  _ctx.selectedCountryItems.length == 1 ||
                  _ctx.selectedCountryItems[0]?.amount_In_Naira == 0
                )
                        ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                            _createVNode(_component_app_text_field, {
                              placeholder: 'Enter amount',
                              hasTitle: true,
                              type: 'tel',
                              name: 'Amount',
                              isFormatted: true,
                              modelValue: _ctx.amountValue,
                              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.amountValue) = $event)),
                              rules: [
                    _ctx.FormValidations.customValidator(
                      parseFloat(_ctx.amountValue?.replace(/,/g, '')) <=
                        parseFloat(
                          _ctx.selectedCountryItems[0].local_Product_Value_Max
                        ) &&
                        parseFloat(_ctx.amountValue?.replace(/,/g, '')) >=
                          parseFloat(
                            _ctx.selectedCountryItems[0].local_Product_Value_Min
                          ),
                      `Amount must within the range below`
                    ),
                  ]
                            }, {
                              title: _withCtx(() => _cache[3] || (_cache[3] = [
                                _createTextVNode(" Enter amount ")
                              ])),
                              "inner-prefix": _withCtx(() => [
                                _createVNode(_component_app_normal_text, { class: "dark:!text-white" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.selectedCountryItems[0].country.currency), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              "inner-suffix": _withCtx(() => [
                                _createElementVNode("div", _hoisted_15, [
                                  _createVNode(_component_app_icon, {
                                    name: 'cashback-icon',
                                    customClass: 'h-[20px]'
                                  }),
                                  _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap dark:!text-white' }, {
                                    default: _withCtx(() => [
                                      _cache[4] || (_cache[4] = _createTextVNode(" You'll get ")),
                                      _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                              _ctx.getProductPoint(
                                _ctx.selectedCountryItems[0].product_category_slug,
                                (
                                  parseFloat(_ctx.amountValue?.replace(/,/g, "")) *
                                  _ctx.selectedCountryItems[0].rate
                                ).toString()
                              ),
                              false,
                              ""
                            )) + " Points", 1)
                                    ]),
                                    _: 1
                                  })
                                ])
                              ]),
                              _: 1
                            }, 8, ["modelValue", "rules"]),
                            _createElementVNode("div", _hoisted_17, [
                              _createVNode(_component_app_normal_text, { customClass: "!pb-1 " }, {
                                default: _withCtx(() => [
                                  _cache[5] || (_cache[5] = _createTextVNode(" Range: ")),
                                  _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                          _ctx.selectedCountryItems[0].local_Product_Value_Min,
                          false,
                          "",
                          false,
                          `${_ctx.selectedCountryItems[0].country.currency}`
                        )) + " - " + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                          _ctx.selectedCountryItems[0].local_Product_Value_Max,
                          false,
                          "",
                          false,
                          `${_ctx.selectedCountryItems[0].country.currency}`
                        )), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedCountryItems, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "w-full flex flex-row justify-between items-center bg-[#F7F7F7] dark:bg-gray-800 rounded-[14px] px-3 py-3 cursor-pointer",
                                key: index,
                                onClick: ($event: any) => (_ctx.selectItem(item))
                              }, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("div", _hoisted_22, [
                                    _createVNode(_component_app_icon, {
                                      name: 
                          _ctx.billerFormData.payment_code == item.code
                            ? `selected${
                                _ctx.Logic.Common.currentAppTheme() == 'light'
                                  ? ''
                                  : '-white'
                              }`
                            : 'not-selected'
                        ,
                                      customClass: 'h-[19px]'
                                    }, null, 8, ["name"])
                                  ]),
                                  _createElementVNode("div", _hoisted_23, [
                                    _createVNode(_component_app_normal_text, { customClass: '!font-semibold' }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(`${
                            item.country.currency
                          } ${_ctx.Logic.Common.convertToMoney(
                            item.local_Product_Value_Max,
                            true,
                            ""
                          )}`), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_app_normal_text, {
                                      class: _normalizeClass('!text-gray-600 dark:!text-gray-200')
                                    }, {
                                      default: _withCtx(() => [
                                        _cache[6] || (_cache[6] = _createTextVNode(" You'll get ")),
                                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                              _ctx.getProductPoint(
                                item.product_category_slug,
                                item.amount_In_Naira.toString()
                              ),
                              false,
                              ""
                            )) + " pts", 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ])
                                ]),
                                _createVNode(_component_app_normal_text, {
                                  class: _normalizeClass('!font-semibold !text-sm xs:!text-[13px] !whitespace-nowrap')
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                        item.amount_In_Naira,
                        false,
                        "",
                        false,
                        "NGN "
                      )), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 8, _hoisted_20))
                            }), 128))
                          ]))
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, { class: "pt-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_25, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "bg-color": 'bg-primary-400',
                  "text-color": 'text-white',
                  class: _normalizeClass(_ctx.formIsValid ? 'w-full' : 'opacity-50'),
                  onClick: _ctx.purchaseGiftCard
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["class", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["brandImage"])
    ]),
    _: 1
  }))
}