<template>
  <template v-if="Logic.Common.currentBuildType() == 'mobile'">
    <ion-page
      :registerIonPage="registerIonPage"
      id="ionPageApp"
      :class="`${innerClass}`"
    >
      <ion-content :scroll-y="false" id="mainContent" :class="`${innerClass}`">
        <slot />
      </ion-content>
    </ion-page>
  </template>
  <template v-if="Logic.Common.currentBuildType() == 'web'">
    <div :class="`w-full h-full ${innerClass} `" id="ionPageApp">
      <slot />
    </div>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { IonContent, IonPage } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { ionHandlers } from "@shpt/ui-components/src/composable";

export default defineComponent({
  props: {
    class: {
      type: String,
      default: "",
    },
    registerIonPage: {
      type: Function,
      default: () => {
        //
      },
    },
    innerClass: {
      type: String,
      default: "bg-white dark:bg-black",
    },
  },
  components: {
    IonContent,
    IonPage,
  },
  name: "AppWrapper",
  setup() {
    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        document.body.classList.add("!bg-white", "dark:!bg-black");
      } else {
        document.body.classList.add("!bg-transparent");
      }
    });
    return {
      Logic,
      ionHandlers,
    };
  },
});
</script>
