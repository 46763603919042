<template>
  <app-wrapper>
    <subpage-layout :title="'Buy Airtime'">
      <!-- Beneficiaries -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            class="!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center"
          >
            <app-icon
              :name="`beneficiary${
                Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`"
              custom-class="!h-[12px]"
            />
            <app-normal-text class="!text-primary-500 dark:!text-primary-100">
              Beneficiaries
            </app-normal-text>
          </app-button>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-3 px-4">
        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-3"
        >
          <!-- Phone number -->
          <app-text-field
            :placeholder="'Enter phone number'"
            :hasTitle="true"
            :type="'tel'"
            name="Phone number"
            ref="phoneNumberRef"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                phoneNumber.length == 11,
                'Phone number must be valid'
              ),
            ]"
            :update-value="phoneNumber"
            :content-rule="{
              addAfterCount: 0,
              characterToAdd: ``,
              max: 11,
            }"
            v-model="phoneNumber"
          >
            <template v-slot:title> Phone number </template>
            <template
              v-slot:inner-suffix
              v-if="Logic.Common.currentBuildType() != 'web'"
            >
              <app-icon
                :name="'phonebook'"
                :customClass="'h-[18px]'"
                @click="searchPhone()"
              />
            </template>
          </app-text-field>

          <app-checkbox v-model="saveAsBeneficiary" :standard="true">
            <app-normal-text class="dark:!text-white">
              Save as beneficiary
            </app-normal-text>
          </app-checkbox>

          <div
            class="w-full border-t-[1px] border-grey-50 dark:!border-grey-500"
          ></div>

          <!-- Networks -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text class="!text-[12px] text-left dark:!text-white">
              Select Network Provider
            </app-normal-text>

            <div
              class="w-full flex flex-row items-center justify-start space-x-4 pb-3"
            >
              <div
                class="flex items-center justify-center"
                v-for="(network, index) in allNetworks"
                :key="index"
              >
                <div
                  class="w-[56px] h-[56px] rounded-full relative cursor-pointer"
                  @click="selectedNetwork = network.id"
                >
                  <app-image-loader
                    :photo-url="network.logo"
                    :class="`h-[56px] w-[56px] ${
                      selectedNetwork == network.id
                        ? 'border-[2px] border-primary-400 rounded-full'
                        : ' rounded-full'
                    } `"
                  />
                  <app-icon
                    v-if="selectedNetwork == network.id"
                    :name="'mark'"
                    :customClass="'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Amount -->
          <div class="w-full flex flex-col space-y-2 pb-4 xs:pb-2">
            <!-- Amount field -->
            <app-text-field
              :placeholder="'Enter amount'"
              :hasTitle="true"
              :type="'tel'"
              :isFormatted="true"
              :rules="[FormValidations.RequiredRule]"
              ref="amount"
              name="Amount"
              v-model="amountValue"
            >
              <template v-slot:title> Amount </template>
              <template v-slot:inner-prefix>
                <app-normal-text class="dark:!text-gray-200">
                  ₦
                </app-normal-text>
              </template>
            </app-text-field>

            <div class="w-full flex flex-row space-x-1 justify-end flex-grow">
              <app-icon :name="'cashback-icon'" :customClass="'h-[20px]'" />
              <app-normal-text
                :customClass="'!whitespace-nowrap dark:!text-white'"
              >
                You'll get
                <span
                  class="font-semibold text-primary-500 dark:!text-primary-200 px-1"
                  >{{
                    Logic.Common.convertToMoney(
                      getProductPoint(
                        "airtime",
                        amountValue?.replace(/,/g, "")
                      ),
                      false,
                      ""
                    )
                  }}
                  Points</span
                >
              </app-normal-text>
            </div>
          </div>
        </app-form-wrapper>
      </div>

      <!-- Bottom section -->
      <fixed-container class="pt-4">
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :bg-color="'bg-primary-400'"
            :text-color="'text-white'"
            :class="`w-full ${formIsValid ? '' : 'opacity-50'} `"
            @click="purchaseAirtime"
          >
            Continue
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppFormWrapper,
  AppTextField,
  AppIcon,
  AppCheckbox,
  AppNormalText,
  AppImageLoader,
  AppButton,
} from "@shpt/ui-components";
import { FormValidations } from "@shpt/ui-components/src/composable";
import {
  getEquivalentPoint,
  getProductPoint,
  makePurchase,
  processProductData,
  selectedBeneficiary,
  setAirtimeProduct,
  SingleProduct,
} from "@shpt/ui-components/src/composable/shop";
import {
  loaderSetup,
  pickAContact,
} from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppFormWrapper,
    AppTextField,
    AppIcon,
    AppCheckbox,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "BuyAirtimePage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Buy Airtime Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Buy Airtime",
    });

    const formComp = ref();

    const amountValue = ref("");
    const phoneNumber = ref("");
    const phoneNumberRef = ref();
    const saveAsBeneficiary = ref(true);

    const selectedNetwork = ref("mtn");
    const allNetworks = reactive([
      {
        logo: "/images/brands/mtn.png",
        id: "mtn",
      },
      {
        logo: "/images/brands/airtel.png",
        id: "airtel",
      },
      {
        logo: "/images/brands/glo.png",
        id: "glo",
      },
      {
        logo: "/images/brands/9mobile.png",
        id: "9mobile",
      },
    ]);

    const airtimeMetadata = ref<{
      service_type: "9mobile" | "mtn" | "airtel" | "glo";
      amount: number;
      billerId: string;
      description: string;
      id: string;
      isActive: boolean;
      isFixedPrice: boolean;
      kudaIdentifier: string;
      name: string;
      vendingAggregator?: string;
    }>({
      service_type: "9mobile",
      amount: 0,
      billerId: "",
      description: "",
      id: "",
      isActive: false,
      isFixedPrice: false,
      kudaIdentifier: "",
      name: "",
      vendingAggregator: "",
    });

    const formIsValid = computed(() => {
      const amount = parseFloat(amountValue?.value.replace(/,/g, ""));
      return phoneNumber.value.length >= 10 && amount > 0;
    });

    const purchaseAirtime = () => {
      phoneNumberRef.value.checkValidation();
      const amount = parseFloat(amountValue?.value.replace(/,/g, ""));
      const state = phoneNumberRef.value.validationStatus;

      if (amount > 0 && phoneNumber.value && state) {
        makePurchase(
          {
            code: SingleProduct.value?.code || "",
            amount: amount.toString(),
            customerId: phoneNumber.value,
          },
          saveAsBeneficiary.value
        );
      }
    };

    const setProductData = () => {
      const airtimeDeals = processProductData(
        Logic.Shop.ShopData.airtime || []
      );

      const productData = airtimeDeals.map((item) => item.data);

      const groupedData = Logic.Common.groupArrayBy(
        productData,
        "merchant_name"
      );

      allNetworks.forEach((item) => {
        const productData = groupedData[item.id][0];
        item.logo = productData.image_url;
      });

      // set airtime Metadata
      setTimeout(() => {
        if (SingleProduct.value) {
          const metadata: any = JSON.parse(SingleProduct.value.metadata || "");
          airtimeMetadata.value = metadata;

          selectedNetwork.value = airtimeMetadata.value.service_type;
          amountValue.value = SingleProduct.value.price;
        }
      }, 300);

      phoneNumber.value = "";

      if (selectedBeneficiary.value) {
        phoneNumber.value = selectedBeneficiary.value.id;
      }
    };

    const searchPhone = async () => {
      phoneNumber.value = (await pickAContact(false)) || "";
    };

    const setTarget = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        selectedNetwork.value =
          currentPathQuery.target?.toString().toLowerCase() || "mtn";
      }
    };

    watch(selectedNetwork, () => {
      setAirtimeProduct(selectedNetwork.value);
    });

    watch(selectedBeneficiary, () => {
      setProductData();
    });

    watch(SingleProduct, () => {
      setProductData();
    });

    onIonViewWillEnter(() => {
      setTarget();
    });

    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        setTarget();
      }
    });

    return {
      allNetworks,
      selectedNetwork,
      FormValidations,
      amountValue,
      phoneNumber,
      saveAsBeneficiary,
      Logic,
      formComp,
      phoneNumberRef,
      loaderSetup,
      SingleProduct,
      formIsValid,
      getEquivalentPoint,
      purchaseAirtime,
      searchPhone,
      getProductPoint,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
