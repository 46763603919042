import { Logic } from '.'
import { ContentType } from '../gql/graphql'
import { reactive } from 'vue'

const currentStoryContents = reactive<{
  type?: ContentType
  cursor?: {
    id: number
    type: string
  }
  former?: {
    type?: ContentType
    cursor?: {
      id: number
      type: string
    }
  }
  business_uuid?: string
}>({})

const setStoryContent = (
  type: ContentType,
  cursor: {
    id: number
    type: string
  },
  business_uuid = '',
  back_route = '',
) => {
  const existingFormer = JSON.parse(JSON.stringify(currentStoryContents))
  currentStoryContents.former = {
    type: existingFormer.type,
    cursor: existingFormer.cursor,
  }
  currentStoryContents.business_uuid = business_uuid
  currentStoryContents.type = type
  currentStoryContents.cursor = cursor
  Logic.Common.GoToRoute(
    `/story${back_route ? `?backRoute=${back_route}` : ''}`,
  )
}

export { currentStoryContents, setStoryContent }
