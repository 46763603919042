<template>
  <app-wrapper>
    <subpage-layout :title="'Challenge Info'">
      <div class="w-full flex flex-col space-y-3 px-4">
        <app-header-text class="!text-left !text-base">
          {{ SingleChallenge?.title }}
        </app-header-text>

        <app-normal-text
          v-html="SingleChallenge?.description"
          class="!text-left !text-grey-700 dark:!text-gray-100"
        />

        <!-- Icon Box -->
        <div class="w-full flex flex-col space-y-4">
          <template v-if="SingleChallenge?.game_type == 'transactional'">
            <div
              class="w-full flex flex-row space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px] items-center justify-center h-[98px]"
              v-if="parseFloat(SingleChallenge?.rule?.reward_amount || '0') > 0"
            >
              <app-icon :name="'reward-white'" :customClass="'h-[48px]'" />
              <div class="flex flex-col justify-center pt-1">
                <app-normal-text class="!font-semibold !text-left !text-[11px]">
                  {{
                    SingleChallenge?.active_instance &&
                    SingleChallenge?.active_instance?.status != "in_progress"
                      ? "POINTS REWARDED"
                      : "REWARD TO EARN"
                  }}
                </app-normal-text>
                <app-normal-text :customClass="'!text-xl !font-bold'">
                  {{
                    Logic.Common.convertToMoney(
                      SingleChallenge?.rule?.reward_amount,
                      false,
                      "",
                      false
                    )
                  }}
                  Pts
                  <span class="!text-xs !text-gray-600 dark:!text-gray-400 !font-semibold"
                    >({{
                      Logic.Common.convertToMoney(
                        SingleChallenge.rule?.reward_game_points,
                        false,
                        "ngn"
                      )
                    }})</span
                  >
                </app-normal-text>
              </div>
            </div>

            <div
              v-if="SingleChallenge?.active_instance?.participant"
              class="w-full flex flex-col space-y-2 px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[10px]"
            >
              <app-normal-text :customClass="'uppercase '">
                Challenge progress
              </app-normal-text>

              <div class="w-full flex flex-row items-center justify-between space-x-2">
                <div class="w-full relative h-[8px] rounded-[30px] bg-white">
                  <div
                    class="absolute top-0 left-0 h-full rounded-[30px] bg-success-main"
                    :style="`width: ${getPercentage(
                      parseFloat(
                        SingleChallenge.active_instance.participant?.points || '0'
                      ),
                      parseFloat(SingleChallenge.target_point || '1')
                    )}%`"
                  ></div>
                </div>
              </div>

              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text>
                  {{
                    Logic.Common.convertToMoney(
                      SingleChallenge.active_instance.participant?.points || 0,
                      false,
                      "",
                      false
                    )
                  }}/{{
                    Logic.Common.convertToMoney(
                      SingleChallenge.target_point,
                      false,
                      "",
                      false
                    )
                  }}
                  Completed
                </app-normal-text>

                <app-normal-text>
                  {{
                    getPercentage(
                      parseFloat(
                        SingleChallenge.active_instance.participant?.points || "0"
                      ),
                      parseFloat(SingleChallenge.target_point || "1")
                    )
                  }}%
                </app-normal-text>
              </div>
            </div>
          </template>

          <template v-if="SingleChallenge?.game_type == 'word_twist'">
            <div
              class="w-full flex flex-row space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px] items-center justify-center h-[98px]"
            >
              <app-icon :name="'reward-white'" :customClass="'h-[48px]'" />
              <div class="flex flex-col pt-1">
                <app-normal-text class="!font-semibold !text-left !uppercase">
                  Reward to earn
                </app-normal-text>
                <app-normal-text :customClass="'!text-xl !font-bold'">
                  {{ SingleChallenge.rule?.reward_amount }} Pts
                  <span class="!text-xs !text-gray-600 !font-semibold"
                    >({{
                      Logic.Common.convertToMoney(
                        SingleChallenge.rule?.reward_game_points,
                        false,
                        "ngn"
                      )
                    }})</span
                  >
                </app-normal-text>
              </div>
            </div>
          </template>
        </div>
      </div>

      <!-- Bottom section -->
      <fixed-container
        v-if="
          !SingleChallenge?.active_instance?.participant ||
          SingleChallenge.game_type == 'word_twist'
        "
      >
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :text-color="'text-white'"
            class="w-full"
            :loading="loaderSetup.loading"
            @click="handleChallenge()"
          >
            {{
              SingleChallenge?.active_instance?.participant
                ? "Play game"
                : "Join challenge"
            }}
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import { AppHeaderText, AppNormalText, AppIcon, AppButton } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppIcon,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "ChallengeInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "SingleChallenge",
        method: "GetChallenge",
        params: [],
        useRouteId: true,
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Challenge Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Challenge Info",
    });

    const SingleChallenge = ref(Logic.Game.SingleChallenge);

    const handleChallenge = () => {
      if (
        SingleChallenge.value?.active_instance?.participant &&
        SingleChallenge.value.game_type == "word_twist"
      ) {
        Logic.Common.GoToRoute(
          `/others/challenges/word_twist/${SingleChallenge.value.uuid}`
        );
        return;
      }
      Logic.Game.JoinChallenge(SingleChallenge.value?.id || "").then((data) => {
        if (data) {
          Logic.Game.GetChallenge(SingleChallenge.value?.uuid || "");

          Logic.Common.showAlert({
            show: true,
            type: "success",
            message: "You've successfully joined this challenge.",
          });
        }
      });
    };

    const getPercentage = (points: number, totalPoints: number) => {
      return parseFloat(Math.min((points / totalPoints) * 100, 100).toFixed(2));
    };

    onIonViewDidEnter(() => {
      Logic.User.SaveUserActivity(
        `Challenge Info - ${SingleChallenge.value?.title}`,
        "page_view",
        undefined,
        {
          end_stage: "",
          lable: `Challenge Info - ${SingleChallenge.value?.title}`,
          stage_type: "neutral",
          get_extra_uuid: () => {
            return SingleChallenge.value?.uuid;
          },
        }
      );
    });

    onMounted(() => {
      Logic.Game.watchProperty("SingleChallenge", SingleChallenge);
      if (Logic.Common.currentBuildType() == "web") {
        Logic.User.SaveUserActivity(
          `Challenge Info - ${SingleChallenge.value?.title}`,
          "page_view",
          undefined,
          {
            end_stage: "",
            lable: `Challenge Info - ${SingleChallenge.value?.title}`,
            stage_type: "neutral",
            get_extra_uuid: () => {
              return SingleChallenge.value?.uuid;
            },
          }
        );
      }
    });

    return {
      SingleChallenge,
      Logic,
      loaderSetup,
      getPercentage,
      handleChallenge,
    };
  },
});
</script>
