import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 relative"
}
const _hoisted_2 = {
  key: 0,
  class: "h-[60px]"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full h-[570px] bg-red-100"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_video_player = _resolveComponent("app-video-player")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.data.videoUrl)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_app_header_text, { customClass: '!text-lg !font-extrabold' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.title), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_app_normal_text, {
                customClass: 'text-left   w-full ',
                htmlContent: _ctx.data.content,
                htmlStyle: '  !text-black !font-semibold'
              }, null, 8, ["htmlContent"]),
              (_ctx.data.hasPadding)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true)
            ], 64))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_app_video_player, {
                customClass: '',
                videoUrl: _ctx.data.videoUrl,
                height: 570
              }, null, 8, ["videoUrl"])
            ]))
      ]))
    : _createCommentVNode("", true)
}