<template>
  <div class="w-full flex flex-col space-y-5 py-4 z-50 justify-between h-full">
    <div class="w-full flex flex-col space-y-3">
      <!-- Logo -->
      <div class="w-full flex flex-row justify-start pl-2 pb-3">
        <app-icon
          :name="`logo${
            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`"
          class="h-[40px]"
        />
      </div>

      <div class="w-full flex flex-col space-y-1 items-start">
        <router-link
          v-for="(tab, index) in webSideBarItems"
          :key="index"
          class="flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-full cursor-pointer"
          @mouseover="hoverTab = tab.icon.name"
          @mouseout="hoverTab = ''"
          :to="getTabRoutePath(tab.route_path)"
          @click.prevent="goToRoute(getTabRoutePath(tab.route_path))"
        >
          <div class="w-[30px] relative">
            <app-icon
              :name="`web-sidebar/${
                hoverTab == tab.icon.name || tabIsActive(tab.routeTag)
                  ? `${tab.icon.name}-active${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`
                  : tab.icon.name +
                    `${
                      Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`
              }`"
              :custom-class="` ${
                tab.icon.name == 'savings' || tab.icon.name == 'wallet'
                  ? '!h-[17px]'
                  : '!h-[20px]'
              }`"
            />
            <app-normal-text
              v-if="tab.routeTag == 'cart' && cartCount > 0"
              class="!text-white h-[16px] w-[16px] bg-red-500 rounded-full flex flex-row items-center justify-center !text-[10px] font-semibold absolute right-[10%] bottom-[60%] z-40"
            >
              {{ cartCount > 9 ? "9+" : cartCount }}
            </app-normal-text>
          </div>

          <app-normal-text
            :class="`text-left !text-base !text-black dark:!text-white ${
              tabIsActive(tab.routeTag) ? '!font-bold' : ''
            }`"
          >
            {{ tab.name }}
          </app-normal-text>
        </router-link>
        <div class="w-full flex flex-row relative">
          <div
            class="flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black hover:!bg-opacity-[10%] rounded-full cursor-pointer relative"
            @click="showMore = true"
          >
            <div class="w-[30px]">
              <app-icon
                :name="`web-sidebar/more${
                  Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`"
                class="!h-[23px]"
              />
            </div>
            <app-normal-text
              class="text-left !text-base !text-black dark:!text-white"
            >
              More
            </app-normal-text>
          </div>
          <div
            v-if="showMore"
            class="w-[90%] md:!w-full flex flex-col space-y-1 items-start bg-white dark:bg-black shadow-md absolute bottom-0 left-0 rounded-[10px] py-2 px-2 border-[1px] border-gray-50 dark:border-gray-500"
            @click.stop="null"
            @mouseleave="showMore = false"
          >
            <router-link
              v-for="(tab, index) in webSideBarMore"
              :key="index"
              class="flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-[10px] cursor-pointer w-full"
              @mouseover="hoverTab = tab.icon.name"
              @mouseout="hoverTab = ''"
              :to="getTabRoutePath(tab.route_path)"
              @click.stop="showMore = false"
            >
              <div class="w-[30px]">
                <app-icon
                  :name="`web-sidebar/${
                    hoverTab == tab.icon.name || tabIsActive(tab.routeTag)
                      ? `${tab.icon.name}-active${
                          Logic.Common.currentAppTheme() == 'light'
                            ? ''
                            : '-white'
                        }`
                      : tab.icon.name +
                        `${
                          Logic.Common.currentAppTheme() == 'light'
                            ? ''
                            : '-white'
                        }`
                  }`"
                  :custom-class="` ${
                    tab.icon.name == 'savings' ? '!h-[18px]' : '!h-[20px]'
                  }`"
                />
              </div>

              <app-normal-text
                :class="`text-left !text-sm !text-black dark:!text-white ${
                  tabIsActive('account_settings') ? '' : ''
                }`"
              >
                {{ tab.name }}
              </app-normal-text>
            </router-link>
            <div
              class="flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-[10px] cursor-pointer w-full"
              @mouseover="hoverTab = 'logout'"
              @mouseout="hoverTab = ''"
              @click.stop="Logic.Auth.SignOut()"
            >
              <div class="w-[30px]">
                <app-icon
                  :name="`web-sidebar/${
                    hoverTab == 'logout' || tabIsActive('logout')
                      ? `${'logout'}-active`
                      : 'logout'
                  }`"
                  :custom-class="`!h-[20px]`"
                />
              </div>

              <app-normal-text
                :class="`text-left !text-sm   !text-error-main ${
                  tabIsActive('account_settings') ? '' : ''
                }`"
              >
                Logout
              </app-normal-text>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        class="w-full flex flex-col space-y-2 pr-4 sticky bottom-0 pt-3 justify-center items-center"
        v-if="AuthUser?.profile?.gender != undefined"
       >
        <div
          class="w-full flex flex-row items-center justify-center px-3 py-3 bg-primary-50 dark:!bg-primary-500 rounded-[999px] cursor-pointer"
          @click.stop="toggleUserAccount()"
        >
          <app-normal-text
            class="!text-primary-400 dark:!text-primary-100"
            v-if="Logic.Auth.GetCurrentBusiness()?.phone_number"
          >
            Switch to
            {{
              Logic.Common.currentAccountType() == "client"
                ? "Shop Account"
                : "Personal Account"
            }}
          </app-normal-text>
          <app-normal-text class="!text-primary-400" v-else>
            <template v-if="Logic.Common.currentAccountType() == 'merchant'">
              Switch to personal account
            </template>
            <template v-else> Create a shop account </template>
          </app-normal-text>
        </div>
      </div> -->
    </div>

    <!-- Profile section -->
    <div
      class="w-full flex flex-col pr-4"
      v-if="
        Logic.Common.currentAccountType() == 'client'
          ? AuthUser?.profile?.gender != undefined
          : Logic.Auth.GetCurrentBusiness()?.business_name != undefined
      "
    >
      <div
        class="bg-grey-50 rounded-[10px] px-3 py-3 flex flex-row items-center justify-between dark:!bg-gray-800"
      >
        <div class="flex flex-row space-x-2 items-center">
          <app-image-loader
            :photoUrl="
              Logic.Common.currentAccountType() == 'merchant'
                ? Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  ? Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  : '/images/temp/vendor.svg'
                : Logic.Auth.AuthUser?.profile?.photo_url
                ? Logic.Auth.AuthUser?.profile?.photo_url
                : '/images/avatar-1.png'
            "
            :customClass="'!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200 cursor-pointer'"
            @click.stop="goToPath('/profile/user')"
          />

          <div class="flex flex-col space-y-[1px] justify-center items-start">
            <app-header-text
              class="!text-sm text-left !line-clamp-1"
              v-if="Logic.Common.currentAccountType() == 'client'"
            >
              {{ Logic.Auth.AuthUser?.first_name }}
              {{ Logic.Auth.AuthUser?.last_name }}
            </app-header-text>
            <app-header-text class="!text-sm text-left !line-clamp-1" v-else>
              {{ Logic.Auth.GetCurrentBusiness()?.business_name }}
            </app-header-text>
            <app-normal-text
              class="!text-primary-400 dark:!text-primary-100 text-left !text-[11px] cursor-pointer"
              v-if="
                Logic.Common.currentAccountType() == 'client' ||
                Logic.Auth.BusinessUserCan('manage_profile')
              "
              @click.stop="
                goToPath(
                  Logic.Common.currentAccountType() == 'merchant'
                    ? '/profile/edit'
                    : '/profile/user'
                )
              "
            >
              {{
                Logic.Common.currentAccountType() == "merchant"
                  ? "Edit Profile"
                  : "View Profile"
              }}
            </app-normal-text>
            <app-normal-text v-else class="!line-clamp-1">
              {{ Logic.Auth.AuthUser?.full_name }}
            </app-normal-text>
          </div>
        </div>

        <div
          class="w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center cursor-pointer"
          v-if="
            Logic.Common.currentAccountType() == 'client' ||
            Logic.Auth.BusinessUserCan('manage_profile')
          "
          @click="
            goToPath(
              Logic.Common.currentAccountType() == 'merchant'
                ? '/profile/edit'
                : '/profile/edit'
            )
          "
        >
          <app-icon :name="'edit-black'" :customClass="'h-[14px]'" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { onMounted, ref, watch } from "vue";
import AppImageLoader from "../AppImageLoader";

export default {
  components: {
    AppNormalText,
    AppHeaderText,
    AppIcon,
    AppImageLoader,
  },
  props: {
    tabIsActive: {
      type: Function,
      required: true,
    },
    options: {
      required: false,
    },
    accountType: {
      type: String,
      default: "",
    },
    spaceBetween: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array as () => any[],
    },
  },
  name: "AppWebSideBar",
  setup() {
    const hoverTab = ref("");

    const AuthUser = ref(Logic.Auth.AuthUser);
    const cartCount = ref(0);
    const UserProductCart = ref(Logic.Shoplist.UserProductCart);

    const webSideBarItems = ref<
      {
        name: string;
        icon: {
          name: string;
          size: string;
          inactive_size: string;
        };
        counter: number;
        route_path: string;
        routeTag: string;
      }[]
    >(Logic.Common.webSideBarItems);
    const webSideBarMore = ref<
      {
        name: string;
        icon: {
          name: string;
          size: string;
          inactive_size: string;
        };
        counter: number;
        route_path: string;
        routeTag: string;
      }[]
    >(Logic.Common.webSideBarMore);

    const showMore = ref(false);

    const sizes = ["h-[25px]", "h-[23px]"];

    const goToPath = (path: string) => {
      Logic.Common.GoToRoute(path);
    };

    const getTabRoutePath = (routePath: string) => {
      if (Logic.Common.currentAccountType() == "client") {
        return AuthUser?.value?.profile?.gender == undefined ? "#" : routePath;
      } else {
        const business = Logic.Auth.GetCurrentBusiness();
        return business ? routePath : "#";
      }
    };

    const goToRoute = (routePath: string) => {
      if (routePath == "/shoplist/cart") {
        Logic.Shoplist.SingleShoplistCollection =
          Logic.Shoplist.UserProductCart;
      }
      Logic.Common.GoToRoute(routePath);
    };

    const toggleUserAccount = () => {
      if (Logic.Common.currentAccountType() == "client") {
        Logic.Common.setCurrentAccount("merchant");
      } else {
        Logic.Common.setCurrentAccount("client");
      }
      window.location.reload();
    };

    watch(UserProductCart, () => {
      cartCount.value =
        (UserProductCart.value?.products?.length || 0) +
        (UserProductCart.value?.collections?.length || 0);
    });

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Shoplist.watchProperty("UserProductCart", UserProductCart);
      cartCount.value =
        (UserProductCart.value?.products?.length || 0) +
        (UserProductCart.value?.collections?.length || 0);

      if (Logic.Common.currentAccountType() == "merchant") {
        if (!Logic.Auth.AuthUser?.profile?.business) {
          webSideBarMore.value = webSideBarMore.value.filter((item) => {
            return item.icon.name != "store-admins";
          });
        }

        if (!Logic.Auth.BusinessUserCan("manage_store_branches")) {
          webSideBarMore.value = webSideBarMore.value.filter((item) => {
            return item.icon.name != "store-locations";
          });
        }

        if (!Logic.Auth.BusinessUserCan("manage_offline_payments")) {
          webSideBarItems.value = webSideBarItems.value.filter((item) => {
            return item.icon.name != "accept-payment";
          });
        }
      }
    });

    return {
      hoverTab,
      webSideBarItems,
      webSideBarMore,
      Logic,
      showMore,
      AuthUser,
      cartCount,
      goToRoute,
      goToPath,
      toggleUserAccount,
      getTabRoutePath,
    };
  },
};
</script>
