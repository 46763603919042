import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row" }
const _hoisted_2 = { class: "pr-1" }
const _hoisted_3 = { class: "pr-1" }
const _hoisted_4 = { class: "w-full flex-col flex pt-2 z-50" }
const _hoisted_5 = { class: "flex items-center justify-center flex-row space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_SSO = _resolveComponent("SSO")!
  const _component_auth_layout = _resolveComponent("auth-layout")!

  return (_openBlock(), _createBlock(_component_auth_layout, {
    onButtonClicked: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/signup'))),
    onGoBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Logic.Common.goBack())),
    "go-back": false,
    "right-button": _ctx.Logic.Auth.currentAccountType() == 'client',
    class: "!overflow-y-auto h-full"
  }, {
    "button-text": _withCtx(() => _cache[6] || (_cache[6] = [
      _createTextVNode("Sign up")
    ])),
    "header-text": _withCtx(() => _cache[7] || (_cache[7] = [
      _createTextVNode("Welcome back")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_app_form_wrapper, {
        parentRefs: _ctx.parentRefs,
        ref: "formComp",
        class: "w-full flex flex-col space-y-4 pt-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_normal_text, { class: "!text-[#664D7E] dark:!text-primary-100" }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Complete the details below to login ")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_AppTextField, {
            "focus-border": 'border-primary-400',
            "has-title": true,
            "custom-class": '!bg-grey-50 dark:!bg-grey-900',
            placeholder: 'Input Value',
            modelValue: _ctx.signInForm.email,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.signInForm.email) = $event)),
            name: "Email or Phone number",
            ref: "emailRef",
            rules: [_ctx.FormValidations.RequiredRule]
          }, {
            title: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Email or Phone number ")
            ])),
            "inner-prefix": _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_app_icon, {
                  name: 'envelope',
                  customClass: 'h-[14px]'
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "rules"]),
          _createVNode(_component_AppTextField, {
            "focus-border": 'border-primary-400',
            type: 'password',
            "has-title": true,
            "custom-class": '!bg-grey-50 dark:!bg-grey-900',
            placeholder: 'Input Value',
            ref: "passwordRef",
            name: "Password",
            modelValue: _ctx.signInForm.password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.signInForm.password) = $event)),
            rules: [_ctx.FormValidations.RequiredRule]
          }, {
            title: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Password ")
            ])),
            "inner-prefix": _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_app_icon, {
                  name: 'padlock',
                  customClass: 'h-[18px]'
                })
              ])
            ]),
            _: 1
          }, 8, ["modelValue", "rules"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AppButton, {
              padding: 'py-3',
              "custom-class": 'rounded-[40px]',
              "bg-color": 'bg-primary-400',
              "text-color": 'text-white',
              loading: _ctx.loaderSetup.loading,
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.login()), ["prevent"]))
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" Login ")
              ])),
              _: 1
            }, 8, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_AppNormalText, { class: "dark:text-white" }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode("Forgot Password?")
              ])),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_AppNormalText, {
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/forgot-password'))),
                "custom-class": 'underline',
                color: 'text-primary-main dark:!text-primary-100',
                class: "cursor-pointer"
              }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode("Reset ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }, 8, ["parentRefs"]),
      _createVNode(_component_SSO, {
        fromAction: 'signIn',
        isRelative: true
      }),
      _cache[14] || (_cache[14] = _createElementVNode("div", {
        class: "w-full flex flex-row justify-center items-center",
        style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
      }, null, -1))
    ]),
    _: 1
  }, 8, ["right-button"]))
}