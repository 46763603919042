import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col h-full flex-grow relative overflow-y-hidden" }
const _hoisted_2 = { class: "xs:w-[90%] w-[85%] flex flex-row px-4" }
const _hoisted_3 = { class: "w-full flex flex-col px-4 h-full pt-3" }
const _hoisted_4 = { class: "w-full flex flex-col flex-grow h-full pt-4 z-50 overflow-y-hidden rounded-t-[10px] relative" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-3 relative overflow-y-auto scrollbar-hide" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_tabs = _resolveComponent("app-tabs")!
  const _component_app_challenge = _resolveComponent("app-challenge")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Cashback Rewards',
        mainSectionClass: "!bg-white dark:!bg-black !overflow-y-hidden !fixed top-0 left-0 mdlg:!relative md:!relative  w-full",
        hasPadding: false,
        topBarStyle: '!bg-transparent',
        parentContClass: 'md:!h-screen',
        class: "!overflow-y-hidden md:!overflow-y-hidden",
        theme: "light",
        style: `padding-bottom: calc(env(safe-area-inset-bottom)) !important;`,
        showBottomPadding: false
      }, {
        default: _withCtx(() => [
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "absolute top-[1%] -right-0" }, [
            _createElementVNode("img", {
              src: "/images/challenge-bg-image.svg",
              class: "h-[100px]"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, {
                class: _normalizeClass('!text-black dark:!text-white')
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Participate in challenges and earn cashback rewards ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_tabs, {
                    data: _ctx.filters,
                    modelValue: _ctx.selectedFilter,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedFilter) = $event))
                  }, null, 8, ["data", "modelValue"]),
                  _createElementVNode("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.challenges, (item, index) => {
                      return (_openBlock(), _createBlock(_component_app_challenge, {
                        key: index,
                        class: "cursor-pointer",
                        data: item,
                        onClick: ($event: any) => (
                    _ctx.Logic.Common.GoToRoute(`/others/challenges/${item.uuid}`)
                  )
                      }, null, 8, ["data", "onClick"]))
                    }), 128)),
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[80px]" }, null, -1))
                  ])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}