<template>
  <div
    :class="`${customClass} flex flex-col h-full place-content-center space-y-4 px-6 w-full`"
  >
    <div class="flex justify-center">
      <slot name="reveal-logo"></slot>
    </div>

    <div
      class="w-full flex flex-col space-y-2 items-center justify-center pt-2"
    >
      <AppHeaderText :customClass="'text-center'">
        <slot name="center-text"></slot>
      </AppHeaderText>
      <AppNormalText :customClass="'text-center'">
        <slot name="sub-center-text"></slot>
      </AppNormalText>
    </div>

    <div class="w-full flex flex-col space-y-3 pt-2">
      <div class="w-full flex flex-col space-y-3 pb-2">
        <AppButton
          :padding="'py-3'"
          :bg-color="'bg-primary-500'"
          :text-color="'text-white'"
          @click="triggerButton()"
        >
          <slot name="button-text"></slot>
          {{ buttonText }}
        </AppButton>
        <AppButton
          :padding="'py-3'"
          :bg-color="'bg-primary-50'"
          :text-color="'text-primary-500'"
          v-if="secondaryButtonText"
          @click="secondaryButtonTrigger()"
        >
          <slot name="secondary-button-text"></slot>
          {{ secondaryButtonText }}
        </AppButton>
      </div>

      <slot name="suffix" />
      <div @click="$emit('last-text-event')" class="text-center">
        <AppNormalText
          :customClass="'justify-center underline text-primary-500 cursor-pointer'"
        >
          <slot name="last-text"></slot>
        </AppNormalText>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
  },
  props: {
    buttonText: {
      type: String,
      required: false,
    },
    triggerButton: {
      type: Function,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
    secondaryButtonText: {
      type: String,
      required: false,
    },
    secondaryButtonTrigger: {
      type: Function,
      required: false,
    },
  },
  emits: ["last-text-event"],
  setup() {
    return {};
  },
  name: "AppReveal",
};
</script>
