<template>
  <auth-layout
    @go-back="Logic.Common.goBack()"
    :go-back="true"
    :hasBottomBorder="hasBottomBorder"
  >
    <template v-slot:header-text>{{ title }}</template>

    <slot name="top-section" />

    <app-form-wrapper
      ref="formComp"
      :parentRefs="parentRefs"
      class="w-full flex flex-col space-y-4 pt-4 pb-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E]" v-if="!hasCustomSubTitle">
          Verify Your Phone Number.
        </app-normal-text>
        <app-header-text v-if="hasCustomSubTitle">
          <slot name="sub-title"></slot>
        </app-header-text>
      </div>

      <app-text-field
        :placeholder="'Enter phone number'"
        v-model="phoneVerifyForm.phone"
        :hasTitle="true"
        type="tel"
        :padding="'py-3 px-3'"
        name="Phone Number"
        ref="phone"
        :updateValue="phoneVerifyForm.phone"
        :rules="[
          FormValidations.RequiredRule,
          FormValidations.customValidator(
            phoneVerifyForm.phone.length == 11,
            'Phone number must be valid'
          ),
        ]"
        :content-rule="{
          addAfterCount: 0,
          characterToAdd: ``,
          max: 11,
        }"
      >
        <template v-slot:title> Phone Number </template>
      </app-text-field>

      <div class="w-full flex-col flex pt-2">
        <AppButton
          :padding="'py-3'"
          :custom-class="'rounded-[40px]'"
          :bg-color="'bg-primary-main'"
          :text-color="'text-white'"
          @click.prevent="verifyPhone()"
          :loading="loaderSetup.loading"
        >
          Continue
        </AppButton>
      </div>
    </app-form-wrapper>
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import {
  AppTextField,
  AppButton,
  AppNormalText,
  AppIcon,
  AppHeaderText,
  AppFormWrapper,
} from "../../../components";
import { FormValidations, routeQueryString } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: "Create Password",
    },
    hasBottomBorder: {
      type: Boolean,
      default: true,
    },
    hasCustomSubTitle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppIcon,
    AppFormWrapper,
    AppHeaderText,
    AuthLayout,
  },
  name: "VerifyPhonePage",
  setup() {
    const phoneVerifyForm = reactive({
      phone: "",
      code: "",
    });

    const showAddPhoneOTP = () => {
      Logic.Common.showModal({
        show: true,
        title: "Enter OTP",
        type: "verify_phone",
        action: () => {
          if (Logic.Common.currentAccountType() == "merchant") {
            const allQueryParams = new URLSearchParams(routeQueryString.value);
            if (allQueryParams.get("invitation_code")) {
              Logic.Common.showLoader({
                show: true,
                loading: true,
              });

              Logic.User.AcceptBusinessInvitation(
                allQueryParams.get("invitation_code") || ""
              )?.then((data) => {
                if (data) {
                  Logic.Auth.GetAuthUser()?.then(async (data) => {
                    if (data) {
                      Logic.Common.hideLoader();
                      window.location.href = "/";
                    }
                  });
                }
              });
            } else {
              Logic.Common.GoToRoute("/onboarding");
            }
          } else {
            Logic.Common.GoToRoute("/auth/onboarding/savings-target");
          }
        },
      });
    };

    const verifyPhone = () => {
      Logic.Auth.SaveAuthPhoneNumberForm = {
        user_uuid: Logic.Auth.AuthUser?.uuid || "",
        phone_number: Logic.Form.getPhoneNumber("+234", phoneVerifyForm.phone),
      };
      localStorage.setItem("acc_phone", phoneVerifyForm.phone);
      Logic.Auth.SaveAuthPhoneNumber()?.then((data) => {
        if (data) {
          showAddPhoneOTP();
        }
      });
    };

    return {
      phoneVerifyForm,
      FormValidations,
      Logic,
      loaderSetup,
      verifyPhone,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
