<template>
  <div
    class="border-grey-50 border-[1px] flex flex-col items-start rounded-[8px] py-3 px-3 space-y-2 w-full"
  >
    <AppIcon :custom-class="'h-[26px]'" :name="icon"></AppIcon>
    <div class="w-full flex flex-col space-y-[2px]">
      <AppNormalText :customClass="'!text-[10px]'" :color="'text-black'">
        {{ title }}
      </AppNormalText>
      <div class="w-full flex items-center space-x-2">
        <AppNormalText :custom-class="'font-bold !text-sm'">
          {{ total }}
        </AppNormalText>
        <div class="w-[10px]">
          <AppIcon
            :custom-class="'h-[10px]'"
            :name="'arrow-stats-up'"
          ></AppIcon>
        </div>
        <AppNormalText :class="'!text-[10px]'" :color="'text-success-500'">
          {{ percentage }}%
        </AppNormalText>
      </div>
      <AppNormalText :color="'text-grey-700'">
        {{ lastText }}
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
  },
  props: {
    icon: {
      type: String,
    },
    total: {
      type: String,
      required: false,
    },
    percentage: {
      type: Number,
      required: false,
    },
    lastText: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppStatisticBox",
};
</script>
