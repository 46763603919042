import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-1 justify-start px-4" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_5 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_6 = { class: "h-[82px]" }
const _hoisted_7 = { class: "flex flex-col items-end w-full justify-between" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_9 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_10 = { class: "w-full flex flex-row space-x-2 justify-between items-center" }
const _hoisted_11 = { class: "flex flex-row justify-end items-center space-x-2" }
const _hoisted_12 = {
  class: "fixed bottom-0 left-0 w-full bg-white pt-4 px-4 flex flex-row space-x-2 justify-between border-t-[1px] border-grey-50",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
}
const _hoisted_13 = { class: "flex flex-col space-y-[3px]" }
const _hoisted_14 = { class: "py-3 px-8 bg-primary-400 flex justify-center items-center rounded-[999px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_header_text, { class: "!text-base" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" Spicy Jollof Rice Recipe ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_normal_text, null, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" iffy’s Kitchen ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, { class: "underline !text-primary-400" }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" Follow ")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productLists, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-50 py-3"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_app_image_loader, {
                        photoUrl: item.base_image,
                        class: "rounded-[4.4px] h-[82px] w-[82px]"
                      }, null, 8, ["photoUrl"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_app_header_text, { class: "!text-sm text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_9, [
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.weight), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900" }, null, -1)),
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.main_category), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.price, true, "ngn")), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_app_normal_text, { class: "px-3 py-[3px] bg-grey-50 rounded-[999px] !text-primary-300" }, {
                            default: _withCtx(() => _cache[4] || (_cache[4] = [
                              _createTextVNode(" Buy Item ")
                            ])),
                            _: 1
                          }),
                          _createVNode(_component_app_icon, {
                            name: 'cart-circle-purple',
                            customClass: '!h-[32px]'
                          })
                        ])
                      ])
                    ])
                  ])
                ]))
              }), 128))
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(240300, true, "ngn")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-[#898989]" }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" 12% Cashback Savings ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                default: _withCtx(() => _cache[7] || (_cache[7] = [
                  _createTextVNode(" Buy All ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}