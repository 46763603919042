<template>
  <app-wrapper>
    <subpage-layout
      :title="'Top Cashback Offers'"
      :mainSectionClass="
        Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      "
      :showBottomPadding="false"
    >
      <template v-slot:extra-topbar-row>
        <!-- Banner ad -->
        <div class="w-full flex flex-col px-4 pb-1">
          <app-banner-ad />
        </div>
        <!-- Categories -->
        <div class="w-full flex flex-col pl-4">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
            id="categoryContainerShoplistProducts"
          >
            <div class="flex flex-row py-2 pr-4">
              <div
                class="flex flex-row cursor-pointer pr-2"
                v-for="(category, index) in categoryOptions"
                :key="index"
                @click="selectedCategory = category.key"
                :id="`category${category.key}`"
              >
                <div
                  :class="` ${
                    selectedCategory == category.key
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-gray-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`"
                >
                  <app-normal-text
                    :class="`!whitespace-nowrap ${
                      selectedCategory == category.key
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-50'
                    }`"
                  >
                    {{ category.value }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-2 px-4">
        <div class="w-full flex flex-col pt-4">
          <app-virtual-scroller
            :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
            :data="topCashbackOffers.data"
            :pagination="topCashbackOffers.paginatorInfo"
            :fetchMore="fetchMoreCashbackOffers"
            :fetchNew="fetchNewCashbackOffers"
            :direction="'vertical'"
            ref="productVirtualScrollerRef"
          >
            <template #item-content="{ index, item }">
              <div class="col-span-1 flex flex-col">
                <app-shoplist-explore
                  class="w-full"
                  :key="index"
                  @click="handleShoplsistClick(item)"
                  :item="item"
                  :mediaBoxStyle="'!h-[140px] mdlg:!h-[200px] md:!h-[200px]'"
                  :theme="Logic.Common.currentAppTheme()"
                />
              </div>
            </template>
            <template #skeleton-loaders>
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
            </template>
          </app-virtual-scroller>
        </div>
        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppVirtualScroller,
  AppShoplistExplore,
  AppBannerAd,
} from "@shpt/ui-components";
import { MediaBox } from "@shpt/ui-components/src/types";
import AppWrapper from "@/components/AppWrapper.vue";
import { SelectOption } from "@shpt/ui-components/src/types";
import { PaginatorInfo } from "@shpt/ui-components/src/gql/graphql";
import {
  getMediaBox,
  getMediaBoxForProduct,
} from "@shpt/ui-components/src/composable";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { scrollToSpecificItem } from "@shpt/ui-components/src/composable";
import { Collection } from "@shpt/ui-components/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import { ContentType } from "@shpt/ui-components/src/gql/graphql";

export default defineComponent({
  components: {
    AppNormalText,
    AppWrapper,
    AppVirtualScroller,
    AppShoplistExplore,
    AppBannerAd,
  },
  name: "ShoppingProductsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategoriesWithProducts",
        method: "GetCategoriesWithProducts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "CashbackOffersCollections",
        method: "GetCashbackOffersCollections",
        params: [1, 10, false],
        requireAuth: true,
        ignoreProperty: true,
        useRouteQuery: true,
        queries: ["key"],
      },
      {
        domain: "Shoplist",
        property: "CashbackOffersProducts",
        method: "GetCashbackOffersProducts",
        params: [1, 10, false],
        requireAuth: true,
        ignoreProperty: true,
        useRouteQuery: true,
        queries: ["key"],
      },
    ],
    tracking_data: {
      lable: "Products By Category Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Products by category",
    });

    const categoryOptions = reactive<SelectOption[]>([]);

    const selectedCategory = ref("All");
    const productVirtualScrollerRef = ref();

    const ManyCategoriesWithProducts = ref(
      Logic.Shoplist.ManyCategoriesWithProducts
    );
    const CashbackOffersProducts = ref(Logic.Shoplist.CashbackOffersProducts);
    const CashbackOffersCollections = ref(
      Logic.Shoplist.CashbackOffersCollections
    );

    const topCashbackOffers = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const setTopCashbackOffers = (force = false) => {
      if (force) {
        topCashbackOffers.data.length = 0;
      }

      let cashbackOffers: any[] = [];

      // For products
      CashbackOffersProducts.value?.data.forEach((item) => {
        const mediaBox = getMediaBoxForProduct(item as ShoplistProduct);
        cashbackOffers.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "product",
          colors: {
            bg: "",
            text: "",
          },
        });
      });

      // For collections
      CashbackOffersCollections.value?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        cashbackOffers.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "collection",
          colors: {
            bg: "",
            text: "",
          },
        });

        // Add results to Logic.User.ExploreContent if the collection item is not there already

        const existingContent = JSON.parse(
          JSON.stringify(Logic.User.ExploreContent)
        );
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // Now sort  the cashback by points (descending)
      cashbackOffers.sort((a, b) => b.data.points - a.data.points);

      // remove all existing data in topCashbackOffers.data in cashbackOffers
      cashbackOffers = cashbackOffers.filter((item) => {
        const isExist = topCashbackOffers.data.some(
          (topItem) => topItem.id === item.id
        );
        return !isExist;
      });

      topCashbackOffers.data.push(...cashbackOffers);

      // Set paginator info. The next page will be the greatest between the two paginator info
      topCashbackOffers.paginatorInfo =
        (CashbackOffersProducts.value?.paginatorInfo?.lastPage || 0) >
        (CashbackOffersCollections.value?.paginatorInfo?.lastPage || 0)
          ? CashbackOffersProducts.value?.paginatorInfo
          : CashbackOffersCollections.value?.paginatorInfo;
    };

    const fetchMoreCashbackOffers = (nextPage: number) => {
      let categoryId = "0";

      if (selectedCategory.value != "All") {
        categoryId =
          categoryOptions.find(
            (category) => category.key === selectedCategory.value
          )?.extras || "0";
      }

      return Promise.all([
        Logic.Shoplist.GetCashbackOffersProducts(
          parseInt(categoryId),
          nextPage,
          10,
          true
        ),
        Logic.Shoplist.GetCashbackOffersCollections(
          parseInt(categoryId),
          nextPage,
          10,
          true
        ),
      ])
        .then((response) => {
          // For GetCashbackOffersProducts
          const cashbackOffersProducts = response[0];
          if (cashbackOffersProducts) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.CashbackOffersProducts)
            );

            if (existingData.data && cashbackOffersProducts.data) {
              existingData.data = existingData.data.concat(
                cashbackOffersProducts.data
              );
              existingData.paginatorInfo = cashbackOffersProducts.paginatorInfo;
            }

            Logic.Shoplist.CashbackOffersProducts = existingData;
          }

          const cashbackOffersCollections = response[1];

          if (cashbackOffersCollections) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.CashbackOffersCollections)
            );

            if (existingData.data && cashbackOffersCollections.data) {
              existingData.data = existingData.data.concat(
                cashbackOffersCollections.data
              );
              existingData.paginatorInfo =
                cashbackOffersCollections.paginatorInfo;
            }

            Logic.Shoplist.CashbackOffersCollections = existingData;
          }

          setTopCashbackOffers();
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const fetchNewCashbackOffers = () => {
      let categoryId = "0";

      if (selectedCategory.value != "All") {
        categoryId =
          categoryOptions.find(
            (category) => category.key === selectedCategory.value
          )?.extras || "0";
      }

      return Promise.all([
        Logic.Shoplist.GetCashbackOffersProducts(
          parseInt(categoryId),
          1,
          10,
          false
        ),
        Logic.Shoplist.GetCashbackOffersCollections(
          parseInt(categoryId),
          1,
          10,
          false
        ),
      ])
        .then(() => {
          setTopCashbackOffers(true);
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const setCategory = () => {
      categoryOptions.length = 0;
      categoryOptions.push({
        key: "All",
        value: "All Categories",
      });
      ManyCategoriesWithProducts.value?.forEach((item) => {
        categoryOptions.push({
          key: item.uuid,
          value: item.name,
          extras: item.id.toString(),
        });
      });
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(
          `/shoplist/product/${item.data.product_data?.uuid}`
        );
      }
    };

    const focusOnCategory = (key: string) => {
      scrollToSpecificItem(
        "categoryContainerShoplistProducts",
        `category${key}`
      );
    };

    onIonViewWillEnter(() => {
      selectedCategory.value = Logic.Common.route?.query.key as string;
      setCategory();
      setTopCashbackOffers();
      setTimeout(() => {
        focusOnCategory(selectedCategory.value);
      }, 500);
    });

    watch(selectedCategory, () => {
      topCashbackOffers.data.length = 0;
      productVirtualScrollerRef.value?.resetList();
      focusOnCategory(selectedCategory.value);
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "CashbackOffersProducts",
        CashbackOffersProducts
      );
      Logic.Shoplist.watchProperty(
        "CashbackOffersCollections",
        CashbackOffersCollections
      );
      Logic.Shoplist.watchProperty(
        "ManyCategoriesWithProducts",
        ManyCategoriesWithProducts
      );
      if (Logic.Common.currentBuildType() == "web") {
        selectedCategory.value = Logic.Common.route?.query.key as string;
        setCategory();
        setTopCashbackOffers();
        setTimeout(() => {
          focusOnCategory(selectedCategory.value);
        }, 500);
      }
    });

    return {
      Logic,
      selectedCategory,
      categoryOptions,
      topCashbackOffers,
      productVirtualScrollerRef,
      fetchNewCashbackOffers,
      fetchMoreCashbackOffers,
      handleShoplsistClick,
    };
  },
});
</script>
