<template>
  <button
    :class="`focus:outline-none shadow-sm rounded-full flex flex-row space-x-1 lg:text-sm mdlg:text-[12px] text-xs items-center justify-center ${padding} ${
      isDisabled ? 'bg-grey-100 text-grey-400' : bgColor + ' ' + textColor
    } ${customClass}`"
  >
    <span v-if="loading">
      <svg
        :class="`animate-spin mr-3 h-5 w-5  ${
          type == 'light' ? 'text-primary-400' : 'text-white'
        }`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>

    <slot />
  </button>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: "bg-primary-main",
    },
    textColor: {
      type: String,
      default: "text-white",
    },
    customClass: {
      type: String,
      default: "",
    },
    padding: {
      type: String,
      default: "py-2 px-4 ",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "dark",
    },
  },
  name: "AppButton",
});
</script>
