<template>
  <app-wrapper>
    <subpage-layout :title="'Cashback History'">
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-2">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in filters"
                :key="index"
                :class="`px-4 py-1 cursor-pointer ${
                  selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-800'
                }  rounded-[20px]  flex flex-row items-center justify-center`"
                @click="selectedFilter = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="w-full flex flex-col space-y-0 h-full flex-grow relative top-0"
      >
        <div class="w-full flex flex-col space-y-3 pt-3 px-4">
          <template v-if="allRewards.length">
            <app-virtual-scroller
              :data="allRewards"
              :pagination="paginationData"
              :fetch-more="getMoreRewards"
            >
              <template #item-content="{ index, item }">
                <app-reward :item="item" :key="index" @click="item.action()" />
              </template>
              <template #skeleton-loaders>
                <div class="w-full flex flex-col space-y-2">
                  <div class="w-[40%] h-[10px] rounded skeleton"></div>
                  <div
                    class="w-full flex flex-col space-y-2 skeleton h-[60px] rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </template>

          <div
            v-else
            class="w-full flex flex-col py-4 px-8 h-[200px] rounded-[10px] bg-primary-50 dark:!bg-gray-800 justify-center items-center space-y-3"
          >
            <app-normal-text :customClass="'text-center'">
              {{ filters[selectedIndex].emptyState.label }}
            </app-normal-text>

            <app-button
              :border="''"
              :padding="'py-1 px-4'"
              @click="filters[selectedIndex].emptyState.action()"
              >{{ filters[selectedIndex].emptyState.btnText }}</app-button
            >
          </div>

          <div class="h-[100px]"></div>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { FlightRevalidationDetails } from "@shpt/logic/src/gql/graphql";
import { RateApp } from "capacitor-rate-app";
import {
  AppNormalText,
  AppButton,
  AppVirtualScroller,
  AppReward,
} from "@shpt/ui-components";
import { PaginatorInfo } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import { RewardData } from "@shpt/ui-components/src/types";
import { getSingleReward } from "@shpt/ui-components/src/composable/game";

export default defineComponent({
  components: {
    AppNormalText,
    AppButton,
    AppVirtualScroller,
    AppWrapper,
    AppReward,
  },
  name: "CashbackRewardsPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Cashback Rewards Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Wallet",
        property: "CashbackRewards",
        subProperty: "scan",
        method: "GetCashbackRewards",
        params: [],
        requireAuth: true,
        silentUpdate: true,
        useRouteQuery: true,
        queries: ["tab"],
      },
    ],
  },
  setup() {
    useMeta({
      title: "Cashback Rewards",
    });

    const selectedFilter = ref<"scan" | "pay" | "bonus" | "shop">("scan");
    const selectedIndex = ref(0);
    const paginationData = ref<PaginatorInfo>();

    const CashbackRewards = ref(Logic.Wallet.CashbackRewards);

    const filters = reactive<
      {
        title: string;
        id: "scan" | "pay" | "bonus" | "shop";
        unread: number;
        emptyState: {
          label: string;
          btnText: string;
          action: () => void;
        };
      }[]
    >([
      {
        title: "Receipt Snap",
        id: "scan",
        unread: 0,
        emptyState: {
          label: "You are yet to scan a receipt",
          btnText: "Snap your first receipt",
          action: () => {
            Logic.Common.GoToRoute("/snap");
          },
        },
      },
      {
        title: "Shop",
        id: "shop",
        unread: 0,
        emptyState: {
          label: "You are yet to purchase an item",
          btnText: "Shop now",
          action: () => {
            Logic.Common.GoToRoute("/shoplist");
          },
        },
      },
      // {
      //   title: "Travel",
      //   id: "travel",
      //   unread: 0,
      //   emptyState: {
      //     label: "You are yet to book a flight",
      //     btnText: "Book Fligth",
      //     action: () => {
      //       Logic.Common.GoToRoute("/shop/category/flight");
      //     },
      //   },
      // },
      {
        title: "Payment",
        id: "pay",
        unread: 0,
        emptyState: {
          label: "You are yet to make a payment",
          btnText: "Send money",
          action: () => {
            Logic.Common.GoToRoute("/pay/user");
          },
        },
      },
      {
        title: "Bonus",
        id: "bonus",
        unread: 0,
        emptyState: {
          label: "You have no bonus yet",
          btnText: "Join a challenge",
          action: () => {
            Logic.Common.GoToRoute("/others/challenges");
          },
        },
      },
    ]);

    const allRewards = reactive<RewardData[]>([]);

    const shopSlugs = [
      "AIRTIME_PURCHASE",
      "DATA_PURCHASE",
      "CABLE_TV_PURCHASE",
      "ELECTRICITY_PURCHASE",
      "SHOPLIST_PURCHASED",
    ];

    const paySlugs = ["EXTERNAL_BANK_TRANSFER", "SHPT_TO_SHPT_TRANSFER"];

    const scanSlugs = ["SCANNED_RECEIPT"];

    const travelSlugs = ["NEW_FLIGHT_BOOKED"];

    const setRewards = () => {
      allRewards.length = 0;

      filters.forEach((item) => {
        item.unread = 0;
      });

      const ManyPointEarned = CashbackRewards.value[selectedFilter.value];

      ManyPointEarned?.forEach((reward) => {
        const rewardData = getSingleReward(reward, selectedFilter.value);

        if (
          shopSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "shop"
        ) {
          allRewards.push(rewardData);
        } else if (
          paySlugs.includes(reward.event_slug) &&
          selectedFilter.value == "pay"
        ) {
          allRewards.push(rewardData);
        } else if (
          scanSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "scan"
        ) {
          allRewards.push(rewardData);
        } else if (
          !shopSlugs.includes(reward.event_slug) &&
          !paySlugs.includes(reward.event_slug) &&
          !scanSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "bonus"
        ) {
          allRewards.push(rewardData);
        } else if (
          travelSlugs.includes(reward.event_slug) &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectedFilter.value == "travel"
        ) {
          const extraData = JSON.parse(
            reward.sale?.booked_flight?.extra_data || ""
          );

          const flightDetails: FlightRevalidationDetails = extraData.details;

          const flightSegments = flightDetails.OriginDestinationOptions[0];

          rewardData.title = `${
            flightSegments.FlightSegments[0].DepartureCity
          } - ${
            flightSegments.FlightSegments[
              flightSegments.FlightSegments.length - 1
            ].ArrivalCity
          }`;
          rewardData.subtitle = `${flightDetails.Airline.Name}`;

          rewardData.icon = "flight-booking";

          allRewards.push(rewardData);
        }

        if (
          rewardData.status_color == "blue" &&
          parseFloat(rewardData.points) > 0
        ) {
          if (shopSlugs.includes(reward.event_slug)) {
            filters[1].unread++;
          } else if (paySlugs.includes(reward.event_slug)) {
            filters[2].unread++;
          } else if (scanSlugs.includes(reward.event_slug)) {
            filters[0].unread++;
          } else {
            filters[3].unread++;
          }
        }
      });
    };

    const getMoreRewards = (nextPage: number) => {
      return Logic.Wallet.GetCashbackRewards(
        selectedFilter.value,
        nextPage,
        true
      )
        ?.then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Wallet.CashbackRewards)
            );
            existingData[selectedFilter.value] = existingData[
              selectedFilter.value
            ].concat(response.data);
            existingData.paginatorInfo = response.paginatorInfo;

            Logic.Wallet.CashbackRewards = existingData;

            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    };

    watch(CashbackRewards, () => {
      paginationData.value = CashbackRewards.value.paginatorInfo;
      setRewards();
    });

    watch(selectedFilter, () => {
      filters.forEach((item, index) => {
        if (item.id == selectedFilter.value) {
          selectedIndex.value = index;
        }
      });

      if (CashbackRewards.value[selectedFilter.value]) {
        setRewards();
        Logic.Wallet.GetCashbackRewards(selectedFilter.value);
      } else {
        Logic.Common.showLoader({ show: true, loading: true });
        Logic.Wallet.GetCashbackRewards(selectedFilter.value)?.then(
          (response) => {
            if (response) {
              Logic.Common.hideLoader();
              setRewards();
            }
          }
        );
      }
    });

    onIonViewDidEnter(() => {
      if (
        CashbackRewards.value &&
        (CashbackRewards.value.scan?.length || 0) > 4
      ) {
        const ratingRequested = localStorage.getItem("ratings_requested");

        if (!ratingRequested) {
          RateApp.requestReview();
          localStorage.setItem("ratings_requested", "true");
        }
      }
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("CashbackRewards", CashbackRewards);
      if (Logic.Common.route?.query?.tab) {
        const tab: any = Logic.Common.route?.query?.tab.toString();

        selectedFilter.value = tab;

        filters.forEach((item, index) => {
          if (item.id == selectedFilter.value) {
            selectedIndex.value = index;
          }
        });
      }
      paginationData.value = CashbackRewards.value.paginatorInfo;
      setRewards();
    });

    return {
      filters,
      selectedFilter,
      allRewards,
      Logic,
      selectedIndex,
      paginationData,
      getMoreRewards,
    };
  },
});
</script>
