import { CombinedError } from 'urql'
import {
  HmoHospital,
  InsuranceClaim,
  InsurancePolicy,
  InsuranceProduct,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'
import { Logic } from '..'

export default class Insurance extends Common {
  constructor() {
    super()
  }

  // Base variables
  public ManyInsuranceProducts: InsuranceProduct[] | undefined
  public SingleInsuranceProduct: InsuranceProduct | undefined
  public ManyInsuranceClaims: InsuranceClaim[] | undefined
  public ManyHMOHospitals: HmoHospital[] | undefined
  public ColorLists: string[] | undefined
  public VehicleTypes: string[] | undefined
  public ManufactureYears: string[] | undefined
  public LocalGovAreas: string[] | undefined
  public Genders: string[] | undefined
  public Countries: string[] | undefined
  public IdentificationTypes: string[] | undefined
  public VehicleBrands: string[] | undefined
  public VehicleModel: string[] | undefined
  public UserInsurancePolicy: InsurancePolicy[] | undefined

  // Query actions
  public GetInsuranceProducts = () => {
    return $api.insurance
      .GetInsuranceProducts()
      .then(async (response) => {
        this.ManyInsuranceProducts = response.data?.GetInsuranceProducts
        return response.data?.GetInsuranceProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetSingleInsuranceProduct = (product_id: string) => {
    return $api.insurance
      .GetSingleInsuranceProducts(product_id)
      .then(async (response) => {
        this.SingleInsuranceProduct = response.data?.GetSingleInsuranceProducts
        return response.data?.GetSingleInsuranceProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetCustomerClaims = (customer_id: string) => {
    return $api.insurance
      .GetCustomerClaims(customer_id)
      .then(async (response) => {
        this.ManyInsuranceClaims = response.data?.GetCustomerClaims
        return response.data?.GetCustomerClaims
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetHMOHospitals = () => {
    return $api.insurance
      .GetHMOHospitals()
      .then(async (response) => {
        this.ManyHMOHospitals = response.data?.GetHMOHospitals
        return response.data?.GetHMOHospitals
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetColorLists = () => {
    return $api.insurance
      .GetColorLists()
      .then(async (response) => {
        this.ColorLists = response.data?.GetColorLists
        return response.data?.GetColorLists
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetVehicleTypes = () => {
    return $api.insurance
      .GetVehicleTypes()
      .then(async (response) => {
        this.VehicleTypes = response.data?.GetVehicleTypes
        return response.data?.GetVehicleTypes
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetManufactureYears = () => {
    return $api.insurance
      .GetManufactureYears()
      .then(async (response) => {
        this.ManufactureYears = response.data?.GetManufactureYears
        return response.data?.GetManufactureYears
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetLocalGovAreas = () => {
    return $api.insurance
      .GetLocalGovAreas()
      .then(async (response) => {
        this.LocalGovAreas = response.data?.GetLocalGovAreas
        return response.data?.GetLocalGovAreas
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetTitles = () => {
    return $api.insurance
      .GetTitles()
      .then(async (response) => {
        return response.data?.GetTitles
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetGenders = () => {
    return $api.insurance
      .GetGenders()
      .then(async (response) => {
        this.Genders = response.data?.GetGenders
        return response.data?.GetGenders
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetCountries = () => {
    return $api.insurance
      .GetCountries()
      .then(async (response) => {
        this.Countries = response.data?.GetCountries
        return response.data?.GetCountries
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetIdentificationTypes = () => {
    return $api.insurance
      .GetIdentificationTypes()
      .then(async (response) => {
        this.IdentificationTypes = response.data?.GetIdentificationTypes
        return response.data?.GetIdentificationTypes
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetVehicleBrands = (manufacture_year: string) => {
    return $api.insurance
      .GetVehicleBrands(manufacture_year)
      .then(async (response) => {
        this.VehicleBrands = response.data?.GetVehicleBrands
        return response.data?.GetVehicleBrands
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetVehicleModel = (manufacture_year: string, brand: string) => {
    return $api.insurance
      .GetVehicleModel(manufacture_year, brand)
      .then(async (response) => {
        this.VehicleModel = response.data?.GetVehicleModel
        return response.data?.GetVehicleModel
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserInsurancePolicy = (insurance_type: string) => {
    return $api.insurance
      .GetUserInsurancePolicy(insurance_type)
      .then(async (response) => {
        this.UserInsurancePolicy = response.data?.GetUserInsurancePolicy
        return response.data?.GetUserInsurancePolicy
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }
}
