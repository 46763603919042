import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-5 py-4 z-50 justify-between h-full pl-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_4 = { class: "w-full flex flex-row pt-3" }
const _hoisted_5 = { class: "px-3 py-[2px] bg-primary-800 rounded-[30px] flex items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_header_text, { class: "!text-base !text-left !font-semibold" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Refer and earn ")
        ])),
        _: 1
      }),
      _createElementVNode("div", {
        class: "w-full flex flex-col bg-primary-400 rounded-[10px] px-3 py-3 relative cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToPath('/others/referral')))
      }, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          src: "/images/referral-coins.svg",
          class: "h-[80%] absolute right-[5%] bottom-0"
        }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_normal_text, { class: "!text-left !text-white !text-xs" }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Refer Friends, Earn more ")
            ])),
            _: 1
          }),
          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white text-left !text-sm" }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode(" Get 10% Bonus ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_normal_text, { class: "!text-white !text-[11px]" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Refer & Earn ")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}