<template>
  <div
    class="border-grey-50 border-[1px] flex flex-row space-x-2 items-center rounded-[8px] py-3 px-3 w-full"
  >
    <div class="w-[40px]">
      <AppIcon :custom-class="'w-8 h-8'" :name="icon"></AppIcon>
    </div>
    <div class="w-full flex flex-col space-y-[1px] items-start">
      <AppNormalText class="!font-semibold !text-sm !text-black dark:!text-white">
        {{ total }}
      </AppNormalText>
      <AppNormalText class="!text-[11px] !text-black dark:!text-white">
        {{ title }}
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
  },
  props: {
    icon: {
      type: String,
    },
    total: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppSummaryBox",
};
</script>
