import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_3 = { class: "w-full flex flex-col sticky bottom-0 left-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0 relative"
  }, {
    default: _withCtx(() => [
      (_ctx.formData.status)
        ? (_openBlock(), _createBlock(_component_app_select, {
            key: 0,
            placeholder: 'Select status',
            modelValue: _ctx.formData.status,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.status) = $event)),
            hasTitle: true,
            paddings: 'py-3 px-4',
            options: _ctx.statusOptions,
            ref: "admin_status"
          }, {
            title: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Account Status ")
            ])),
            _: 1
          }, 8, ["modelValue", "options"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_normal_text, { class: "w-full text-left !font-semibold" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Permissions ")
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.permissions, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
            }, [
              (index != '__typename')
                ? (_openBlock(), _createBlock(_component_app_checkbox, {
                    key: 0,
                    standard: true,
                    modelValue: _ctx.formData.permissions[index],
                    "onUpdate:modelValue": ($event: any) => ((_ctx.formData.permissions[index]) = $event),
                    class: "w-full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "text-left" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(index == "manage_all_orders" ? "Manage all orders" : "") + " " + _toDisplayString(index == "manage_assigned_branch_orders"
                    ? "Manage only assigned orders"
                    : "") + " " + _toDisplayString(index == "manage_offline_payments"
                    ? "Manage offline payment"
                    : "") + " " + _toDisplayString(index == "manage_products" ? "Manage store products" : "") + " " + _toDisplayString(index == "manage_profile" ? "Manage store profile" : "") + " " + _toDisplayString(index == "manage_shoplists" ? "Manage offers" : "") + " " + _toDisplayString(index == "manage_promotions" ? "Manage promotions" : "") + " " + _toDisplayString(index == "manage_store_branches"
                    ? "Manage store branches"
                    : "") + " " + _toDisplayString(index == "manage_settlement_accounts"
                    ? "Manage settlement accounts"
                    : ""), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_button, {
          class: "w-full py-3",
          onClick: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Update ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}