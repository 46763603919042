<template>
  <app-wrapper>
    <subpage-layout
      :title="'Word Twist'"
      class="!overflow-y-hidden !h-screen"
      :hasPadding="false"
      :topBarStyle="'!bg-transparent'"
      :bottomBlankStyle="'!bg-transparent'"
      :mainSectionClass="'!overflow-y-hidden !h-screen'"
      :showBottomPadding="false"
      theme="dark"
    >
      <template v-slot:extra-topbar>
        <span
          class="h-[45px] w-[45px] -mt-1 rounded-full border-[4px] border-primary-100 bg-primary-400 flex flex-row items-center justify-center"
        >
          <app-normal-text
            :color="'!text-white'"
            :customClass="'!text-lg !font-semibold !mt-[4px]'"
          >
            {{ currentCountdown.main }}
          </app-normal-text>
        </span>
      </template>
      <div class="absolute top-0 left-0 w-full h-full z-[0] overflow-y-hidden">
        <img src="/images/word-twist-bg.png" class="w-full" />
      </div>
      <div class="w-full flex flex-col space-y-6 h-full flex-grow z-10 px-4">
        <div class="w-full flex flex-col space-y-2 items-center justify-center">
          <app-normal-text :color="'text-white'">
            Guess the longest possible word
          </app-normal-text>
          <div class="w-full flex flex-col h-[140px] relative">
            <div
              :class="`w-full flex flex-row flex-wrap justify-start items-start h-full absolute top-0 left-0 z-0`"
              id="guessBoxContainer"
            >
              <div
                class="h-[70px] w-[67px] xs:h-[60px] xs:w-[57px] flex flex-row items-center justify-center px-1 py-1 isGuess"
                v-for="(item, index) in guessedAnswer"
                :key="index"
              >
                <div
                  class="h-full w-full bg-primary-300 rounded-[12px] shadow-inner"
                ></div>
              </div>
            </div>
            <div
              :class="`w-full flex flex-row flex-wrap justify-start items-start h-full  z-20`"
              id="guessBoxContainerMain"
            ></div>
          </div>
        </div>

        <!-- Suffle board -->
        <div class="w-full flex flex-col space-y-4 pt-6">
          <!-- Word key -->
          <div class="w-full flex flex-col h-[140px]">
            <div
              :class="`w-full flex flex-row flex-wrap  justify-center items-center h-full`"
              id="wordBoxContainer"
              v-if="showGuessWord"
            >
              <div
                class="h-[70px] w-[67px] xs:h-[60px] xs:w-[57px] flex flex-row items-center justify-center px-1 py-1 draggable-source isWordBox"
                data-movetime="0"
                v-for="(item, index) in guessedWord"
                :key="index"
              >
                <app-image-loader
                  :photoUrl="'/images/word-box-bg.svg'"
                  :customClass="'h-full w-full flex flex-col items-center justify-center'"
                >
                  <span
                    style="font-family: Inter"
                    class="text-black !font-extrablod !text-[40px] xs:!text-[35px] wordText"
                  >
                    {{ item.text }}
                  </span>
                </app-image-loader>
              </div>
            </div>
          </div>

          <div class="w-full flex flex-row items-center justify-center space-x-3">
            <div
              :class="` flex flex-col items-center justify-center h-[50px] w-[180px] relative !bg-primary-main focus:outline-none ${
                shuffleIsClicked ? '' : 'border-b-[3px]'
              }  border-[#bf8f52] rounded-[35px]`"
              @click="clickEffectShuffle()"
            >
              <app-normal-text
                :customClass="'!text-base !font-extrabold'"
                :color="'text-white'"
              >
                Shuffle
              </app-normal-text>
            </div>
            <app-normal-text
              :color="'text-white'"
              v-if="SingleChallenge?.active_instance?.participant?.points != '-1'"
            >
              {{ 3 - gameSettings.trialLeft }}
              more trial{{ 3 - gameSettings.trialLeft > 1 ? "s" : "" }} left
            </app-normal-text>
          </div>
        </div>

        <!-- Enter button -->
        <div
          :class="`w-full flex flex-row items-center justify-center pt-2 ${
            !gameSettings.canPlay ? 'opacity-70' : ''
          } `"
          v-if="gameSettings.canPlay"
        >
          <div
            :class="`  flex flex-col items-center justify-center h-[50px] w-full !bg-primary-main focus:outline-none ${
              enterIsClicked ? '' : 'border-b-[3px]'
            }  border-[#bf8f52] rounded-[30px] `"
            @click="clickEffectEnter()"
          >
            <app-normal-text
              :customClass="'!text-2xl !font-extrabold'"
              :color="'text-white'"
            >
              Enter
            </app-normal-text>
          </div>
        </div>

        <!-- Try again -->
        <fixed-container
          v-else
          :class="`${gameSettings.trialLeft >= 3 ? 'opacity-50' : ''}`"
        >
          <div :class="`w-full flex flex-col `">
            <app-button
              :padding="'px-6 py-3'"
              :border="'border-b-[3px]'"
              :customClass="'w-full'"
              :type="'light'"
              :loading="loaderSetup.loading"
              @click="watchAd()"
            >
              {{
                gameSettings.trialLeft >= 3
                  ? "Try again tomorrow"
                  : "Try again (Watch an Ad)"
              }}
            </app-button>
          </div>
        </fixed-container>
      </div>

      <!-- Start modal -->
      <fixed-container
        class="!top-0 !left-0 items-center justify-center z-[9999999999] !bg-black !bg-opacity-80"
        v-if="!startGame"
      >
        <app-header-text :color="'!text-white'" :customClass="'!text-[60px] text-center'">
          {{ currentCountdown.start }}
        </app-header-text>
        <app-normal-text :color="'text-white'" :customClass="'text-center'">
          Your game will start in
        </app-normal-text>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewDidLeave, onIonViewWillEnter } from "@ionic/vue";
import {
  AppNormalText,
  AppImageLoader,
  AppHeaderText,
  AppButton,
} from "@shpt/ui-components";
import { Draggable, DraggableEventNames, Plugins, Sortable } from "@shopify/draggable";
import { Logic } from "@shpt/logic";
import { gsap } from "gsap";
import { AccountType } from "@shpt/logic/src/gql/graphql";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppHeaderText,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "WorldTwistPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "SingleChallenge",
        method: "GetChallenge",
        params: [],
        useRouteId: true,
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Word twist",
    });

    const SingleChallenge = ref(Logic.Game.SingleChallenge);

    const wordBoxDraggable = ref<Draggable<DraggableEventNames>>();

    const guessWord = ref("Shoppoint".toUpperCase().split(""));

    const mainCountdown = ref();

    const showGuessWord = ref(true);

    const gameSettings = reactive({
      canPlay: false,
      trialLeft: 0,
    });

    const answerWords = ref("");

    const currentCountdown = reactive({
      start: 5,
      main: 15,
    });

    const guessWordCurrentItems = ref(guessWord.value.length);

    const startGame = ref(false);
    const endGame = ref(false);

    const guessedWord = reactive<
      {
        text: string;
        index: number;
      }[]
    >([]);

    const guessedAnswer = reactive<
      {
        text: string;
        index: number;
      }[]
    >([]);

    const shuffleIsClicked = ref(false);
    const enterIsClicked = ref(false);

    const setGuessWord = () => {
      guessedWord.length = 0;
      const mainWords: { text: string; index: number }[] = [];
      guessWord.value.forEach((item, index) => {
        mainWords.push({
          text: item,
          index,
        });
      });

      guessedWord.push(...Logic.Common.shuffleArray(mainWords));
    };

    const clickEffectShuffle = () => {
      shuffleIsClicked.value = true;
      setTimeout(() => {
        shuffleIsClicked.value = false;
      }, 300);

      // shuffle actions
      const guessBoxContainerMain = document.getElementById("guessBoxContainerMain");

      if (guessBoxContainerMain) {
        showGuessWord.value = false;
        guessBoxContainerMain.innerHTML = "";
        wordBoxDraggable.value?.destroy();
        setTimeout(() => {
          setGuessWord();
          showGuessWord.value = true;

          setTimeout(() => {
            // animate
            const items = document.querySelectorAll(".isWordBox");
            items.forEach((item, index) => {
              gsap.fromTo(
                item,
                { opacity: 0, y: 20 }, // Initial state
                { opacity: 1, y: 0, duration: 0.3, delay: index * 0.1 } // Target state
              );
            });
          }, 100);
          setTimeout(() => {
            initiateGameFuctionality();
          }, 1000);
        }, 300);

        answerWords.value = "";
      }
    };

    const clickEffectEnter = () => {
      if (
        parseInt(SingleChallenge.value?.active_instance?.participant?.points || "0") == -1
      ) {
        return;
      }
      enterIsClicked.value = true;
      setTimeout(() => {
        enterIsClicked.value = false;
      }, 300);

      // submit challenge
      if (answerWords.value.length == guessWord.value.length) {
        Logic.Game.SubmitChallengeEntryForm = {
          account_type: AccountType.Client,
          challenge_uuid: SingleChallenge.value?.uuid || "",
          guess_word: answerWords.value,
        };

        Logic.Game.SubmitChallengeEntry()?.then((data) => {
          if (data) {
            //
          }
        });
      } else {
        Logic.Common.showError(
          undefined,
          "Use all available letters to form the longest possible word"
        );
      }
    };

    const initiateGameFuctionality = () => {
      const wordBoxContainer = document.getElementById("wordBoxContainer");
      const guessBoxContainer = document.getElementById("guessBoxContainerMain");
      if (wordBoxContainer && guessBoxContainer) {
        // set default guess box element
        setTimeout(() => {
          // setDefaultGuessBox();
        }, 300);
        wordBoxDraggable.value = new Sortable([wordBoxContainer, guessBoxContainer], {
          draggable: ".draggable-source",
          mirror: {
            constrainDimensions: false,
          },
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          classes: {
            "source:dragging": "opacity-0",
          },
          swapAnimation: {
            duration: 200,
            easingFunction: "ease-in-out",
            horizontal: true,
          },
          plugins: [Plugins.SwapAnimation, Plugins.ResizeMirror], // Or [SwapAnimation]
        });

        wordBoxDraggable.value.on("drag:start", (event) => {
          if (event.originalSource.classList.contains("isGuess")) {
            event.cancel();
            return;
          } else {
            event.source.setAttribute("data-movetime", `${Date.now()}`);
          }
        });

        wordBoxDraggable.value.on("drag:stop", () => {
          Logic.Common.debounce(() => {
            syncGuessWord();
          });
        });
      }
    };

    const syncGuessWord = () => {
      const guessBoxContainer = document.getElementById("guessBoxContainerMain");
      if (guessBoxContainer) {
        const existingWords: string[] = [];
        document.querySelectorAll("#guessBoxContainerMain > *").forEach((element) => {
          if (element.classList.contains("isWordBox")) {
            existingWords.push(element.getElementsByClassName("wordText")[0].innerHTML);
          }
        });

        answerWords.value = existingWords.join("");
      }
    };

    const handleGameStatus = (currentTrial: number) => {
      Logic.Common.showError(
        undefined,
        currentTrial >= 3
          ? "You have exhausted your trials for today. Try again tomorrow"
          : "Time Up! Watch an Ad to try again.",
        currentTrial >= 3 ? "Okay" : "Watch an Ad",
        () => {
          if (currentTrial >= 3) {
            Logic.Common.goBack();
            Logic.Common.showModal({ show: false });
          } else {
            watchAd();
          }
        },
        true,
        () => {
          Logic.Common.goBack();
          Logic.Common.showModal({ show: false });
        }
      );
    };

    const startCountdown = () => {
      let challengeStage = localStorage.getItem(
        `trials-${SingleChallenge.value?.active_instance?.uuid}`
      );
      Logic.Common.hideLoader();
      Logic.Common.showModal({ show: false });

      startGame.value = true;
      currentCountdown.main = 0;

      gameSettings.trialLeft = parseInt(challengeStage || "1");

      if (!challengeStage) {
        localStorage.setItem(
          `trials-${SingleChallenge.value?.active_instance?.uuid}`,
          "1"
        );

        challengeStage = localStorage.getItem(
          `trials-${SingleChallenge.value?.active_instance?.uuid}`
        );
      } else {
        if (parseInt(challengeStage) >= 3) {
          //
        } else {
          localStorage.setItem(
            `trials-${SingleChallenge.value?.active_instance?.uuid}`,
            `${parseInt(challengeStage) + 1}`
          );
        }

        challengeStage = localStorage.getItem(
          `trials-${SingleChallenge.value?.active_instance?.uuid}`
        );
      }

      gameSettings.trialLeft = parseInt(challengeStage || "1");
      gameSettings.canPlay = true;

      currentCountdown.start = 5;
      currentCountdown.main = 15;
      startGame.value = false;
      endGame.value = false;

      const startMainCountdown = () => {
        // initiate main countdown
        mainCountdown.value = setInterval(() => {
          if (currentCountdown.main == 1) {
            clearInterval(mainCountdown.value);
            endGame.value = true;
            gameSettings.canPlay = false;
            handleGameStatus(gameSettings.trialLeft);
          }
          currentCountdown.main--;
        }, 1000);
      };

      // initiate start countdown
      const startCountdownInt = setInterval(() => {
        if (currentCountdown.start == 1) {
          clearInterval(startCountdownInt);
          startMainCountdown();
          startGame.value = true;
        }
        currentCountdown.start--;
      }, 1000);
    };

    const watchAd = () => {
      if (gameSettings.trialLeft < 3) {
        Logic.Common.showRewardAd(() => {
          startCountdown();
        });
      }
    };

    watch(guessWordCurrentItems, () => {
      Logic.Common.debounce(() => {
        syncGuessWord();
      });
    });

    const setUp = () => {
      if (SingleChallenge.value) {
        guessWord.value =
          SingleChallenge.value.active_instance?.participant?.twisted_word?.split("") ||
          [];
        guessWordCurrentItems.value = guessWord.value.length;
      }

      guessWord.value.forEach((item, index) => {
        guessedAnswer.push({
          text: item,
          index,
        });
      });

      setGuessWord();

      initiateGameFuctionality();

      if (
        parseFloat(SingleChallenge.value?.active_instance?.participant?.points || "0") ==
        -1
      ) {
        gameSettings.canPlay = false;
        gameSettings.trialLeft = 3;
        startGame.value = true;
        endGame.value = true;
        currentCountdown.main = 0;
        return;
      }

      const challengeStage = localStorage.getItem(
        `trials-${SingleChallenge.value?.active_instance?.uuid}`
      );

      if (parseInt(challengeStage || "0") == 0) {
        startCountdown();
      } else {
        startGame.value = true;
        endGame.value = true;
        currentCountdown.main = 0;
        gameSettings.canPlay = false;
        gameSettings.trialLeft = parseInt(challengeStage || "0");
        handleGameStatus(gameSettings.trialLeft);
      }

      Logic.Wallet.SinglePointEarned = undefined;
    };

    onIonViewWillEnter(() => {
      setUp();
    });

    onIonViewDidEnter(() => {
      Logic.User.SaveUserActivity("Play Word Twist", "page_view", undefined, {
        end_stage: "",
        lable: "Play Word Twist",
        stage_type: "neutral",
        get_extra_uuid: () => {
          return SingleChallenge.value?.uuid;
        },
      });
    });

    onMounted(() => {
      Logic.Game.watchProperty("SingleChallenge", SingleChallenge);

      if (Logic.Common.currentBuildType() == "web") {
        Logic.User.SaveUserActivity("Play Word Twist", "page_view", undefined, {
          end_stage: "",
          lable: "Play Word Twist",
          stage_type: "neutral",
          get_extra_uuid: () => {
            return SingleChallenge.value?.uuid;
          },
        });

        setUp();
      }
    });

    onIonViewDidLeave(() => {
      clearInterval(mainCountdown.value);
    });

    onUnmounted(() => {
      clearInterval(mainCountdown.value);
    });

    return {
      guessWord,
      shuffleIsClicked,
      enterIsClicked,
      guessedAnswer,
      guessedWord,
      showGuessWord,
      SingleChallenge,
      startGame,
      endGame,
      currentCountdown,
      gameSettings,
      loaderSetup,
      clickEffectShuffle,
      clickEffectEnter,
      setGuessWord,
      watchAd,
    };
  },
});
</script>
