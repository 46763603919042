<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Import Product CSV -->
    <!-- Instruction -->
    <div class="w-full flex flex-row space-x-2 items-center">
      <template v-if="data?.type === 'csv'">
        <ul class="space-y-1">
          <li>
            <app-normal-text>
              1. Copy this
              <a
                href="https://docs.google.com/spreadsheets/d/1ALrwS9HL9etXeVBqUie6xcfwgSnt0t6P7PpjnZKtUcw/edit?usp=sharing"
                target="_blank"
                class="!text-primary-main dark:!text-primary-300 underline cursor-pointer"
                >template</a
              >
              and fill it with your products
            </app-normal-text>
          </li>
          <li>
            <app-normal-text>
              2. Download as CSV and upload below
            </app-normal-text>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="space-y-1">
          <li>
            <app-normal-text>
              1. Go to your store admin and export your products as CSV
            </app-normal-text>
          </li>
          <li>
            <app-normal-text> 2. Upload the CSV file below </app-normal-text>
          </li>
        </ul>
      </template>
    </div>

    <div class="w-full flex flex-col space-y-2">
      <app-file-attachment
        :isWrapper="true"
        :accept="'text/csv'"
        class="cursor-pointer"
        v-model="productCSVFile"
      >
        <template #content>
          <div
            class="w-full flex flex-col space-y-2 items-center justify-center h-[100px] border-[1px] border-dashed border-grey-200 rounded-[10px] dark:border-grey-800"
          >
            <app-normal-text class="!text-grey-500 dark:!text-grey-400">
              Upload CSV file
            </app-normal-text>
          </div>
        </template>
      </app-file-attachment>
      <app-normal-text class="!text-left" v-if="productCSVFile">
        File: <span class="!font-semibold"> {{ productCSVFile?.name }} </span>
      </app-normal-text>
    </div>

    <!-- Bottom button -->
    <div
      class="w-full flex flex-col space-y-2 pb-4 pt-2 bg-white dark:bg-black sticky bottom-0 md:!pb-0 mdlg:!pb-0"
    >
      <app-button
        :class="
          productCSVFile
            ? 'w-full !py-3'
            : 'w-full !py-3 !opacity-50 cursor-not-allowed'
        "
        @click.prevent="importProducts()"
        :loading="loadingImport"
      >
        Import Products
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  watch,
} from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";
import AppButton from "../AppButton";
import { AppFileAttachment } from "../AppForm";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppFileAttachment,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        type: string;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const loadingImport = ref(false);

    const productCSVFile = ref<File | null>(null);

    const importProducts = () => {
      loadingImport.value = true;

      if (props.data?.type === "csv") {
        Logic.Shoplist.ImportCSVProductsForm = {
          product_csv: productCSVFile.value,
        };

        Logic.Shoplist.ImportCSVProducts()
          ?.then((response: any) => {
            if (response) {
              Logic.Common.showAlert({
                show: true,
                type: "success",
                message: "Products started importing",
              });

              Logic.Common.showSuccess(
                "Your products are now importing. You will be notified once it is done."
              );
            }
            loadingImport.value = false;

            // Set insterval that runs at most 5 times every 5 seconds
            let count = 0;
            const interval = setInterval(() => {
              count++;
              if (count >= 5) {
                clearInterval(interval);
                return;
              }
              fetchProducts();
            }, 5000);
          })
          .catch(() => {
            Logic.Common.showAlert({
              show: true,
              type: "error",
              message: "Oops! Something went wrong",
            });
            loadingImport.value = false;
          });
      } else {
        Logic.Shoplist.ImportShopifyProductFromCSVForm = {
          product_csv: productCSVFile.value,
        };

        Logic.Shoplist.ImportShopifyProductFromCSV()
          ?.then((response) => {
            if (response) {
              Logic.Common.showAlert({
                show: true,
                type: "success",
                message: "Products started importing",
              });

              Logic.Common.showSuccess(
                "Your products are now importing. You will be notified once it is done."
              );
            }
            loadingImport.value = false;

            // Set insterval that runs at most 5 times every 5 seconds
            let count = 0;
            const interval = setInterval(() => {
              count++;
              if (count >= 5) {
                clearInterval(interval);
                return;
              }
              fetchProducts();
            }, 5000);
          })
          .catch(() => {
            Logic.Common.showAlert({
              show: true,
              type: "error",
              message: "Oops! Something went wrong",
            });
            loadingImport.value = false;
          });
      }
    };

    const fetchProducts = async (useLoader = false) => {
      await Logic.Shoplist.GetShoplistProducts(
        1,
        15,
        "CREATED_AT",
        "DESC",
        `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                    }`,
        false
      );
    };

    return {
      productCSVFile,
      loadingImport,
      importProducts,
    };
  },
});
</script>
