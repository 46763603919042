import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`w-full h-full flex flex-col space-y-3 ${_ctx.containerClass}`)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
        return _renderSlot(_ctx.$slots, "item-content", {
          item: item,
          index: index
        })
      }), 256)),
      (_ctx.contentLoading)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(2, (item, index) => {
            return _renderSlot(_ctx.$slots, "skeleton-loaders", { key: index })
          }), 64))
        : _createCommentVNode("", true),
      (_ctx.direction === 'horizontal')
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "h-[40px] w-fullz-50",
            id: `sideAnchor-${_ctx.uniqueId}`
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.direction === 'vertical')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "h-[40px] w-fullz-50",
          id: `bottomAnchor-${_ctx.uniqueId}`
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true)
  ]))
}