<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 pb-4 relative md:!pb-0 mdlg:!pb-0"
  >
    <div class="w-full flex flex-col space-y-1">
      <app-normal-text :customClass="'text-left'" class="!text-gray-700">
        {{ data.data.description }}
      </app-normal-text>
    </div>
    <div class="w-full flex flex-row space-x-4 items-center pt-3">
      <a
        v-if="data.data.business_social_links?.facebook"
        :href="`https://www.facebook.com/${data.data.business_social_links?.facebook}`"
        target="_blank"
      >
        <app-icon name="facebook" :class="'!h-[20px]'" />
      </a>
      <a
        v-if="data.data.business_social_links?.instagram"
        :href="`https://www.instagram.com/${data.data.business_social_links?.instagram}`"
        target="_blank"
      >
        <app-icon name="instagram" :class="'!h-[20px]'" />
      </a>
      <a
        v-if="data.data.business_social_links?.whatsapp"
        :href="`https://wa.me/${data.data.business_social_links?.whatsapp}`"
        target="_blank"
      >
        <app-icon name="whatsapp" :class="'!h-[23px]'" />
      </a>
      <a
        v-if="data.data.business_social_links?.website"
        :href="`${data.data.business_social_links?.website}`"
        target="_blank"
      >
        <app-icon name="website" :class="'!h-[20px]'" />
      </a>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import { Business } from "../../gql/graphql";
import AppIcon from "../AppIcon";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppIcon,
  },
  props: {
    data: {
      type: Object as () => {
        data: Business;
      },
    },
  },
  name: "AppModalBusinessInfo",
  setup(props) {
    return {
      Logic,
    };
  },
});
</script>
