import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col items-center justify-start space-y-2 fixed !font-inter h-screen !pt-0 top-0 left-0 z-[9999999999999]  px-4`)
}
const _hoisted_2 = {
  class: "w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_3 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_4 = { class: "flex flex-col space-y-1" }
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`w-[95%] h-full h-min-[300px] flex-grow flex flex-col transparent-center  items-center rounded-[22px] relative  `),
  id: "scanContainer"
}
const _hoisted_6 = {
  key: 0,
  class: "w-full absolute top-0 left-0 h-full flex flex-col rounded-[22px] overflow-y-auto"
}
const _hoisted_7 = { class: "w-full absolute top-[88%] left-0 flex flex-row space-x-3 items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_onboarding_layout = _resolveComponent("onboarding-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, { innerClass: '!bg-transparent' }, {
    default: _withCtx(() => [
      _createVNode(_component_onboarding_layout, {
        class: "!bg-transparent",
        title: 'clear',
        id: "videoContainer"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", {
                  class: _normalizeClass(`w-[40px] h-[40px] xs:w-[43px] xs:h-[43px] rounded-full flex flex-row items-center justify-center border-[1px] border-white`),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {
                _ctx.Logic.Common.goBack();
                _ctx.CameraPreview.stop();
              })
                }, [
                  _createVNode(_component_app_icon, {
                    name: 'chevron-left-white',
                    customClass: 'h-[14px]'
                  })
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_header_text, {
                    color: '!text-white',
                    class: "!font-semibold"
                  }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Snap a receipt ")
                    ])),
                    _: 1
                  })
                ])
              ])
            ]),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full flex flex-row justify-center items-start z-[99999]" }, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_app_icon, {
                name: 'scan-top-left',
                customClass: 'h-auto absolute top-[-1%] left-[-1%] z-50'
              }),
              _createVNode(_component_app_icon, {
                name: 'scan-top-right',
                customClass: 'h-auto absolute top-[-1%] right-[-1%] z-50'
              }),
              _createVNode(_component_app_icon, {
                name: 'scan-bottom-left',
                customClass: 'h-auto absolute bottom-[-1%] left-[-1%] z-50'
              }),
              _createVNode(_component_app_icon, {
                name: 'scan-bottom-right',
                customClass: 'h-auto absolute bottom-[-1%] right-[-1%] z-50'
              }),
              _createElementVNode("div", {
                class: _normalizeClass(`scan bg-shop_purple_400 w-[96%] h-[4px] z-0 shadow-2xl shadow-inherit  ${
              !_ctx.showScan ? 'invisible' : ''
            }`),
                id: "scan"
              }, null, 2),
              (_ctx.snappedImages.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snappedImages, (imageUrl, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "w-full flex flex-col h-full",
                        key: index
                      }, [
                        _createVNode(_component_app_image_loader, {
                          photoUrl: imageUrl.url,
                          customClass: 'w-full h-full'
                        }, null, 8, ["photoUrl"])
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.snappedImages.length == 0)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.captureImage())),
                      class: "w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
                    }, [
                      _createVNode(_component_app_icon, {
                        name: 'scan',
                        customClass: 'h-[28px] xs:h-[22px]'
                      })
                    ]))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      _createElementVNode("div", {
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.reSnap())),
                        class: "w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
                      }, [
                        _createVNode(_component_app_icon, {
                          name: 'redo',
                          customClass: 'h-[28px] xs:h-[22px]'
                        })
                      ]),
                      _createElementVNode("div", {
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.analyzeScannedImage(_ctx.snappedImages[0].blob))),
                        class: "w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-primary-400 shadow-custom flex flex-row justify-center items-center"
                      }, [
                        _createVNode(_component_app_icon, {
                          name: 'send-snap',
                          customClass: 'h-[30px] xs:h-[24px]'
                        })
                      ])
                    ], 64))
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("div", {
              class: "w-full flex flex-row justify-center items-start z-[99999] pt-3",
              style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
            }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}