import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 flex-grow px-4" }
const _hoisted_2 = { class: "w-full flex flex-col py-4 px-4 rounded-[10px] space-y-4 justify-center items-center" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Edit Profile' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_file_attachment, {
                "is-wrapper": true,
                modelValue: _ctx.profileUpdateData.photo,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.profileUpdateData.photo) = $event)),
                "local-file-url": _ctx.photoUrl,
                "onUpdate:localFileUrl": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.photoUrl) = $event)),
                accept: `image/png, image/gif, image/jpeg`,
                class: "flex flex-row items-center justify-center !w-auto"
              }, {
                content: _withCtx(() => [
                  _createElementVNode("div", {
                    style: _normalizeStyle(`background-image: url(${_ctx.photoUrl});  background-size: cover;
       background-repeat: no-repeat;
       background-position: center;`),
                    class: "h-[85px] w-[85px] rounded-full bg-gray-100 flex flex-row items-center justify-center border-[1px] border-grey-100"
                  }, [
                    _createVNode(_component_app_icon, {
                      name: 'image-purple',
                      customClass: 'h-[15px]'
                    })
                  ], 4)
                ]),
                _: 1
              }, 8, ["modelValue", "local-file-url"])
            ]),
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-4 pt-3"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_text_field, {
                  placeholder: 'First name',
                  hasTitle: true,
                  type: 'text',
                  ref: "firstNameRef",
                  modelValue: _ctx.profileUpdateData.first_name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.profileUpdateData.first_name) = $event)),
                  "update-value": _ctx.profileUpdateData.first_name,
                  rules: [_ctx.FormValidations.RequiredRule],
                  name: 'First name'
                }, {
                  title: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" First name ")
                  ])),
                  _: 1
                }, 8, ["modelValue", "update-value", "rules"]),
                _createVNode(_component_app_text_field, {
                  placeholder: 'Last name',
                  hasTitle: true,
                  type: 'text',
                  ref: "lastNameRef",
                  modelValue: _ctx.profileUpdateData.last_name,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.profileUpdateData.last_name) = $event)),
                  "update-value": _ctx.profileUpdateData.last_name,
                  rules: [_ctx.FormValidations.RequiredRule],
                  name: 'Last name'
                }, {
                  title: _withCtx(() => _cache[11] || (_cache[11] = [
                    _createTextVNode(" Last name ")
                  ])),
                  _: 1
                }, 8, ["modelValue", "update-value", "rules"]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Your shoppoint handle',
                    hasTitle: true,
                    type: 'text',
                    ref: "usernameRef",
                    modelValue: _ctx.username,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.username) = $event)),
                    name: 'Username',
                    rules: [
                _ctx.FormValidations.RequiredRule,
                _ctx.FormValidations.customValidator(
                  _ctx.username.indexOf(' ') <= 0,
                  'Username cannot contain white space'
                ),
                _ctx.FormValidations.Alphanumeric,
              ],
                    "update-value": _ctx.username,
                    "content-rule": {
                addAfterCount: 0,
                characterToAdd: ``,
                max: 15,
              }
                  }, {
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, null, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" @ ")
                        ])),
                        _: 1
                      })
                    ]),
                    title: _withCtx(() => _cache[13] || (_cache[13] = [
                      _createTextVNode(" Shoppoint handle ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "rules", "update-value"]),
                  _createElementVNode("div", _hoisted_4, [
                    (_ctx.resolvingUsername)
                      ? (_openBlock(), _createBlock(_component_app_normal_text, {
                          key: 0,
                          color: 'text-primary-main'
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode(" Resolving handle... ")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    (
                  !_ctx.resolvingUsername &&
                  _ctx.username != _ctx.AuthUser?.username &&
                  _ctx.username
                )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (!_ctx.usernameExist)
                            ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 0,
                                color: 'text-success-main'
                              }, {
                                default: _withCtx(() => _cache[15] || (_cache[15] = [
                                  _createElementVNode("span", { class: "px-1" }, "Available", -1),
                                  _createTextVNode(" ✅ ")
                                ])),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_app_normal_text, {
                                key: 1,
                                color: 'text-error-main'
                              }, {
                                default: _withCtx(() => _cache[16] || (_cache[16] = [
                                  _createElementVNode("span", { class: "px-1" }, "Not available", -1),
                                  _createTextVNode(" ❌ ")
                                ])),
                                _: 1
                              }))
                        ], 64))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createVNode(_component_app_text_field, {
                  placeholder: 'Email',
                  hasTitle: true,
                  type: 'email',
                  disabled: true,
                  "custom-class": 'opacity-70 !placeholder-[#313131] dark:!placeholder-grey-100',
                  modelValue: _ctx.profileUpdateData.email,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.profileUpdateData.email) = $event)),
                  "update-value": _ctx.profileUpdateData.email
                }, {
                  title: _withCtx(() => _cache[17] || (_cache[17] = [
                    _createTextVNode(" Email address ")
                  ])),
                  _: 1
                }, 8, ["modelValue", "update-value"]),
                (_ctx.showGender)
                  ? (_openBlock(), _createBlock(_component_app_select, {
                      key: 0,
                      placeholder: 'Select your gender',
                      hasTitle: true,
                      paddings: 'py-3 px-3',
                      options: _ctx.genderOption,
                      modelValue: _ctx.profileUpdateData.gender,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.profileUpdateData.gender) = $event)),
                      theme: _ctx.Logic.Common.currentAppTheme(),
                      "custom-class": 'dark:!bg-grey-800'
                    }, {
                      title: _withCtx(() => _cache[18] || (_cache[18] = [
                        _createTextVNode(" Gender ")
                      ])),
                      _: 1
                    }, 8, ["options", "modelValue", "theme"]))
                  : _createCommentVNode("", true),
                (!_ctx.AuthUser?.profile?.date_of_birth)
                  ? (_openBlock(), _createBlock(_component_app_text_field, {
                      key: 1,
                      placeholder: 'Set your date of birth',
                      hasTitle: true,
                      rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.mustBe18OrAbove(_ctx.profileUpdateData.date_of_birth),
                `You must be 18 years old or above to use Shoppoint`
              ),
            ],
                      modelValue: _ctx.profileUpdateData.date_of_birth,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.profileUpdateData.date_of_birth) = $event)),
                      "update-value": _ctx.profileUpdateData.date_of_birth,
                      name: "Date of birth",
                      ref: "dob",
                      type: "date"
                    }, {
                      title: _withCtx(() => _cache[19] || (_cache[19] = [
                        _createTextVNode(" Date of birth ")
                      ])),
                      _: 1
                    }, 8, ["rules", "modelValue", "update-value"]))
                  : (_openBlock(), _createBlock(_component_app_text_field, {
                      key: 2,
                      placeholder: 'Set your date of birth',
                      hasTitle: true,
                      type: 'text',
                      disabled: true,
                      "custom-class": '!opacity-70 !placeholder-[#313131] dark:!placeholder-grey-100',
                      modelValue: _ctx.profileUpdateData.date_of_birth,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.profileUpdateData.date_of_birth) = $event)),
                      "update-value": _ctx.profileUpdateData.date_of_birth
                    }, {
                      title: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode(" Date of birth ")
                      ])),
                      _: 1
                    }, 8, ["modelValue", "update-value"]))
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "!h-[100px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, null, {
            default: _withCtx(() => [
              _createVNode(_component_app_button, {
                customClass: `w-full ${_ctx.usernameExist ? '!opacity-60' : ''}`,
                padding: 'py-3 xs:py-3',
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.saveProfile())),
                loading: _ctx.loaderSetup.loading
              }, {
                default: _withCtx(() => _cache[22] || (_cache[22] = [
                  _createTextVNode(" Save ")
                ])),
                _: 1
              }, 8, ["customClass", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}