<template>
  <div class="relative h-auto w-full bg-black z-[99999999] pb-3 flex flex-col space-y-2">
    <!-- Top Section -->
    <div class="w-full flex flex-col items-center justify-between">
      <div class="w-full flex flex-row items-center justify-between py-3 px-3">
        <div class="flex flex-row items-center space-x-2">
          <app-icon
            @click="goBackHandler()"
            :custom-class="'h-[32px]'"
            :name="'goback-dark-white'"
          >
          </app-icon>
          <app-normal-text class="!font-semibold !text-sm !text-white">
            {{ title }}
          </app-normal-text>
        </div>

        <app-normal-text :custom-class="'!text-primary-100 '"
          >{{
            "Step " +
            `(${promotionCreationStep.current + "/" + promotionCreationStep.total})`
          }}
        </app-normal-text>
      </div>
      <div
        :class="`${
          promotionCreationStep.current == promotionCreationStep.total
            ? 'bg-highlight-400'
            : 'bg-highlight-50'
        }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
      >
        <div
          v-for="(item, index) in promotionCreationStep.total"
          :key="item"
          :class="`${
            promotionCreationStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
          } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
            index == promotionCreationStep.total - 1 ? 'rounded-r-[999px]' : ' '
          } w-full h-1`"
        ></div>
      </div>
    </div>

    <!-- Content Sections -->
    <div class="w-full h-full flex flex-col justify-between px-3 flex-grow pt-2">
      <!-- Body Section -->
      <div class="w-full flex flex-col space-y-3">
        <!-- Step 1 - Basic Details -->
        <template v-if="promotionCreationStep.current == 1">
          <!-- Promotion name -->
          <div class="w-full flex flex-col">
            <app-text-field
              :has-title="false"
              :placeholder="'Promotion Name'"
              padding="px-3 py-2"
              name="Promotion Name"
              :rules="[FormValidations.RequiredRule]"
              v-model="newPromotion.basic.name"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
            >
            </app-text-field>
          </div>

          <!-- For shoppable -->

          <!-- For website visit -->
          <template v-if="newPromotion.basic.type == 'website_visit'">
            <!-- CTA -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="ctaOptions"
                paddings="px-3 py-2"
                v-model="newPromotion.basic.cta"
                autoComplete
                :placeholder="'Select CTA'"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
                :theme="'dark'"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400">
                    Call To Action (CTA)
                  </app-normal-text>
                </template>
              </app-select>
            </div>

            <!-- Landing page url -->
            <div class="w-full flex flex-col">
              <app-text-field
                :has-title="true"
                :placeholder="'https://example.com'"
                padding="px-3 py-2"
                name="Landing Page URL"
                :rules="[FormValidations.RequiredRule, FormValidations.WebsiteRule]"
                v-model="newPromotion.basic.landing_page_url"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400">
                    Landing Page URL
                  </app-normal-text>
                </template>
              </app-text-field>
            </div>
          </template>

          <!-- For polls -->
          <template v-if="newPromotion.basic.type == 'polls'">
            <!-- Question -->
            <div class="w-full flex flex-col">
              <app-text-field
                :has-title="true"
                :placeholder="'Enter question'"
                padding="px-3 py-2"
                name="Question"
                is-textarea
                :rules="[FormValidations.RequiredRule]"
                :max-character="50"
                :textAreaRow="'1'"
                v-model="newPromotion.basic.polls.question"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400"> Question </app-normal-text>
                </template>
              </app-text-field>
            </div>

            <!-- Options -->
            <div class="w-full flex flex-col">
              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text class="!font-semibold !text-white">
                  Options
                </app-normal-text>

                <app-normal-text
                  class="!text-grey-500"
                  @click="addOption()"
                  v-if="newPromotion.basic.polls.options.length < 4"
                >
                  <span class="!text-primary-200">+ Add New</span>
                </app-normal-text>
              </div>
              <div class="w-full flex flex-col space-y-2">
                <div
                  v-for="(item, index) in newPromotion.basic.polls.options"
                  :key="index"
                  class="w-full flex flex-row justify-between items-center border-b-[1px] border-gray-100 py-2 pr-2"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <app-file-attachment
                      isWrapper
                      :accept="'image/jpeg, image/png, image/gif'"
                      v-model:local-file-url="item.image_url"
                      v-model:model-value="item.image_blob"
                      class="!w-auto"
                    >
                      <app-image-loader
                        :photo-url="item.image_url"
                        class="h-[40px] w-[40px] rounded-[7px] bg-gray-50 flex items-center justify-center"
                      >
                        <app-icon :name="'upload-image'" class="!h-[13px]"> </app-icon>
                      </app-image-loader>
                    </app-file-attachment>

                    <app-content-editable
                      :defaultValue="item.value"
                      v-model="item.value"
                      @contentChanged="item.slug = Logic.Common.convertToSlug(item.value)"
                      class="!text-white !text-xs !outline-none focus:outline-none min-w-[40px] !whitespace-nowrap !bg-grey-800 px-2"
                    />
                  </div>

                  <div class="flex flex-row items-center space-x-3">
                    <app-normal-text
                      class="!text-red-500 !text-[11px] px-2 py-[2px] bg-red-50 rounded-[2px]"
                      v-if="item.image_url"
                      @click="item.image_url = ''"
                    >
                      Remove Image
                    </app-normal-text>
                    <app-icon
                      v-if="newPromotion.basic.polls.options.length > 2"
                      :name="'cross-black'"
                      :customClass="'h-[10px]'"
                      @click="removeOption(index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>

          <!-- For banner -->
          <template v-if="newPromotion.basic.type == 'banner'">
            <!-- Landing page url -->
            <div class="w-full flex flex-col">
              <app-text-field
                :has-title="true"
                :placeholder="'https://example.com'"
                padding="px-3 py-2"
                name="Landing Page URL"
                :rules="[FormValidations.RequiredRule, FormValidations.WebsiteRule]"
                v-model="newPromotion.basic.landing_page_url"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400">
                    Landing Page URL
                  </app-normal-text>
                </template>
              </app-text-field>
            </div>
          </template>
        </template>

        <!-- Step 2 - Ad Targeting -->
        <template v-if="promotionCreationStep.current == 2">
          <!-- Age range -->
          <div class="w-full flex flex-col">
            <app-select
              :has-title="false"
              :options="ageRangeOptions"
              paddings="px-3 py-3"
              v-model="newPromotion.targeting.age_ranges"
              :isMultiple="true"
              autoComplete
              :placeholder="'Select age ranges'"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
              :theme="'dark'"
            >
            </app-select>
          </div>

          <!-- Gender -->
          <div class="w-full flex flex-col">
            <app-select
              :has-title="false"
              :options="genderOptions"
              paddings="px-3 py-3"
              v-model="newPromotion.targeting.gender"
              :placeholder="'Select gender'"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
              :theme="'dark'"
            >
            </app-select>
          </div>

          <!-- Location -->
          <div class="w-full flex flex-col">
            <app-select
              :has-title="false"
              :options="locationOptions"
              paddings="px-3 py-3"
              v-model="newPromotion.targeting.locations"
              :isMultiple="true"
              autoComplete
              :placeholder="'Select locations'"
              :customClass="'!bg-grey-800'"
              ref="locationSelect"
              inputStyle="!text-white"
              :theme="'dark'"
            >
            </app-select>
          </div>

          <!-- Behavioural Demography -->
          <div class="w-full flex flex-col">
            <app-select
              :has-title="false"
              :options="behaviouralDemographiesOptions"
              paddings="px-3 py-3"
              v-model="newPromotion.targeting.behavioural_demographies"
              :isMultiple="true"
              autoComplete
              :placeholder="'Choose behavioural demographies'"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
              ref="behaviouralDemographySelect"
              :theme="'dark'"
            >
            </app-select>
          </div>
        </template>

        <!-- Step 3 - Bidding and budgeting -->
        <template v-if="promotionCreationStep.current == 3">
          <!-- Total Budget -->
          <div class="w-full flex flex-col space-y-1">
            <div class="w-full flex flex-row items-center justify-between">
              <app-normal-text class="!font-semibold !text-white">
                Total Budget
              </app-normal-text>
            </div>
            <app-text-field
              :has-title="false"
              :placeholder="'Total Ad Budget'"
              padding="px-3 py-2"
              name="Total Ad Budget"
              is-formatted
              type="tel"
              :rules="[
                FormValidations.customValidator(
                  parseInt(newPromotion.budgeting.total.replace(/,/g, '')) > 4999,
                  'Budget must be greater than or equals to ₦5,000'
                ),
              ]"
              v-model="newPromotion.budgeting.total"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
            >
              <template v-slot:inner-prefix>
                <app-normal-text class="!text-gray-400"> ₦ </app-normal-text>
              </template>
            </app-text-field>
          </div>

          <!-- Bidding strategy -->
          <div class="w-full flex flex-col">
            <app-select
              :has-title="false"
              :options="biddingStrategiesOptions()"
              paddings="px-3 py-3"
              v-model="newPromotion.budgeting.strategy"
              :placeholder="'Choose bidding stategy'"
              @update:model-value="onBiddingStategySelected"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
              :theme="'dark'"
            >
            </app-select>
          </div>

          <!-- Set maximum strategy -->
          <div class="w-full flex flex-col space-y-1" v-if="showMaximimAmount">
            <app-text-field
              :has-title="true"
              :placeholder="`Maximum ${newPromotion.budgeting.strategy.toUpperCase()}`"
              padding="px-3 py-2"
              :name="`Maximum ${newPromotion.budgeting.strategy.toUpperCase()}`"
              is-formatted
              type="tel"
              :rules="[
                FormValidations.customValidator(
                  parseInt(newPromotion.budgeting.max_amount.replace(/,/g, '')) >=
                    getMinimumStrategyAmount(newPromotion.budgeting.strategy),
                  `${newPromotion.budgeting.strategy.toUpperCase()} amount must be greater than or equals to ${Logic.Common.convertToMoney(
                    getMinimumStrategyAmount(newPromotion.budgeting.strategy),
                    false,
                    'ngn'
                  )}`
                ),
                FormValidations.customValidator(
                  parseInt(newPromotion.budgeting.max_amount.replace(/,/g, '')) <=
                    getMaximumStrategyAmount(newPromotion.budgeting.strategy),
                  `${newPromotion.budgeting.strategy.toUpperCase()} amount must be less than or equals to ${Logic.Common.convertToMoney(
                    getMaximumStrategyAmount(newPromotion.budgeting.strategy),
                    false,
                    'ngn'
                  )}`
                ),
              ]"
              v-model="newPromotion.budgeting.max_amount"
              :customClass="'!bg-grey-800'"
              inputStyle="!text-white"
            >
              <template v-slot:title>
                <app-normal-text class="!text-gray-400">
                  {{ `Set Maximum ${newPromotion.budgeting.strategy.toUpperCase()}` }}
                </app-normal-text>
              </template>
              <template v-slot:inner-prefix>
                <app-normal-text class="!text-gray-400"> ₦ </app-normal-text>
              </template>
            </app-text-field>
          </div>
        </template>

        <!-- Step 4 - Scheduling -->
        <template v-if="promotionCreationStep.current == 4">
          <!-- Start date -->
          <app-text-field
            :has-title="true"
            :placeholder="'Start date'"
            padding="px-3 py-2"
            name="Start date"
            type="date"
            prevent-back-date
            :miminum-date="new Date().toString()"
            :rules="[FormValidations.RequiredRule]"
            v-model="newPromotion.scheduling.start"
            :customClass="'!bg-grey-800'"
            inputStyle="!text-white"
          >
            <template v-slot:title>
              <app-normal-text class="!text-gray-400"> Start date </app-normal-text>
            </template>
          </app-text-field>

          <div class="w-full flex flex-col">
            <app-checkbox :standard="true" v-model="newPromotion.run_until_paused">
              <app-normal-text class="!text-white"> Run until paused </app-normal-text>
            </app-checkbox>
          </div>

          <!-- End date -->
          <app-text-field
            v-if="!newPromotion.run_until_paused"
            :has-title="true"
            :placeholder="'End date'"
            padding="px-3 py-2"
            name="End date"
            type="date"
            prevent-back-date
            :miminum-date="new Date(newPromotion.scheduling.start).toString()"
            :rules="[FormValidations.RequiredRule]"
            v-model="newPromotion.scheduling.end"
            :customClass="'!bg-grey-800'"
            inputStyle="!text-white"
          >
            <template v-slot:title>
              <app-normal-text class="!text-gray-400"> End date </app-normal-text>
            </template>
          </app-text-field>

          <div class="w-full grid grid-cols-2 gap-3" v-if="false">
            <!-- Start time -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="timeOfTheDayOptions"
                paddings="px-3 py-2"
                v-model="newPromotion.scheduling.ad_start_time"
                :placeholder="'Start time'"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
                :theme="'dark'"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400">
                    Ad Start Time
                  </app-normal-text>
                </template>
              </app-select>
            </div>

            <!-- End time -->
            <div class="w-full flex flex-col">
              <app-select
                :has-title="true"
                :options="timeOfTheDayOptions"
                paddings="px-3 py-2"
                :placeholder="'End time'"
                v-model="newPromotion.scheduling.ad_end_time"
                :customClass="'!bg-grey-800'"
                inputStyle="!text-white"
                :theme="'dark'"
              >
                <template v-slot:title>
                  <app-normal-text class="!text-gray-400"> Ad End Time </app-normal-text>
                </template>
              </app-select>
            </div>
          </div>

          <div class="w-full flex flex-row" v-if="!endTimeMustBeMoreThanStartTime()">
            <app-normal-text class="!text-error-main">
              End time must be more than start time
            </app-normal-text>
          </div>
        </template>

        <!-- Step 5 - Legal -->
        <template v-if="promotionCreationStep.current == 5">
          <!-- Terms -->
          <div class="w-full flex flex-col">
            <app-checkbox :standard="true" v-model="newPromotion.legal.merchant_terms">
              <app-normal-text class="!text-white">
                I have read and agree to the Shoppoint Merchant Platform’s
                <span class="!text-primary-200 underline">Terms and Conditions</span>
              </app-normal-text>
            </app-checkbox>
          </div>

          <!-- Privacy -->
          <div class="w-full flex flex-col">
            <app-checkbox :standard="true" v-model="newPromotion.legal.privacy">
              <app-normal-text class="!text-white">
                I understand and agree to the
                <span class="!text-primary-200 underline">Privacy Policy</span>
                regarding user data collected through my promotion
              </app-normal-text>
            </app-checkbox>
          </div>

          <!-- Guidelines -->
          <div class="w-full flex flex-col">
            <app-checkbox :standard="true" v-model="newPromotion.legal.ad_guidelines">
              <app-normal-text class="!text-white">
                I confirm that my promotion complies with Shoppoint’s
                <span class="!text-primary-200 underline">Advertising Guidelines</span>
              </app-normal-text>
            </app-checkbox>
          </div>
        </template>
      </div>

      <!-- Bottom Section -->
      <div class="w-full flex flex-col pt-4">
        <AppButton
          :loading="newPromotion.progress.loading"
          :padding="'py-3'"
          :bg-color="'bg-primary-400'"
          :text-color="'text-white'"
          :class="`w-full ${formIsValid ? 'opacity-100' : 'opacity-70'}`"
          @click="moveForward()"
        >
          {{ promotionCreationStep.current == 5 ? "Submit for review" : "Continue" }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  AppIcon,
  AppNormalText,
  AppButton,
  AppTextField,
  AppImageLoader,
  AppSelect,
  AppCheckbox,
  AppContentEditable,
  AppFileAttachment,
} from "../../components";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { newPromotion, newPromotionMedia } from "../../composable/promote";
import {
  adDemographiesOptions,
  FormValidations,
  supportedAreasOption,
} from "../../composable";
import { Logic } from "../../index";
import { SelectOption } from "../../types";
import { showSettings } from "../../composable/common";
import { Objectives } from "@shpt/logic/src/gql/graphql";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppButton,
    AppTextField,
    AppImageLoader,
    AppSelect,
    AppCheckbox,
    AppContentEditable,
    AppFileAttachment,
  },
  name: "CreatePromotionComponent",
  setup() {
    const title = ref("Basic Details");

    const showMaximimAmount = ref(false);

    const AdWallet = ref(Logic.Wallet.AdWallet);

    const promotionCreationStep = reactive({
      current: 1,
      total: 5,
    });

    const baseShoplist = reactive({
      base_image: "/images/temp/shoplist-sub-2.png",
      base_tag: "Weekly",
      price: 23000,
      title: "Fresh foods",
      reward_percentage: 12,
      reward_type: "savings",
    });

    const locationOptions = ref<SelectOption[]>([]);
    const behaviouralDemographiesOptions = ref<SelectOption[]>([]);

    const generateNumberArray = (start: number, end: number): number[] => {
      const result: number[] = [];
      for (let i = start; i <= end; i++) {
        result.push(i);
      }
      return result;
    };

    const ageRangeOptions = reactive<SelectOption[]>([
      {
        key: generateNumberArray(18, 120),
        value: "All Ages",
      },
      {
        key: generateNumberArray(18, 24),
        value: "18 to 24 years",
      },
      {
        key: generateNumberArray(25, 34),
        value: "25 to 34 years",
      },
      {
        key: generateNumberArray(35, 44),
        value: "35 to 44 years",
      },
      {
        key: generateNumberArray(45, 54),
        value: "45 to 54 years",
      },
      {
        key: generateNumberArray(55, 64),
        value: "55 to 64 years",
      },
      {
        key: generateNumberArray(65, 120),
        value: "65+ years",
      },
    ]);

    const genderOptions = reactive<SelectOption[]>([
      {
        key: ["male", "female"],
        value: "All Genders",
      },
      {
        key: ["male"],
        value: "Male",
      },
      {
        key: ["female"],
        value: "Female",
      },
    ]);

    const timeOfTheDayOptions = reactive<SelectOption[]>([
      { key: "24", value: "12am" },
      { key: "01", value: "1am" },
      { key: "02", value: "2am" },
      { key: "03", value: "3am" },
      { key: "04", value: "4am" },
      { key: "05", value: "5am" },
      { key: "06", value: "6am" },
      { key: "07", value: "7am" },
      { key: "08", value: "8am" },
      { key: "09", value: "9am" },
      { key: "10", value: "10am" },
      { key: "11", value: "11am" },
      { key: "12", value: "12pm" },
      { key: "13", value: "1pm" },
      { key: "14", value: "2pm" },
      { key: "15", value: "3pm" },
      { key: "16", value: "4pm" },
      { key: "17", value: "5pm" },
      { key: "18", value: "6pm" },
      { key: "19", value: "7pm" },
      { key: "20", value: "8pm" },
      { key: "21", value: "9pm" },
      { key: "22", value: "10pm" },
      { key: "23", value: "11pm" },
    ]);

    const endTimeMustBeMoreThanStartTime = () => {
      let startTime = parseInt(newPromotion.scheduling.ad_start_time);
      let endTime = parseInt(newPromotion.scheduling.ad_end_time);

      startTime = startTime == 24 ? 0 : startTime;
      endTime = endTime == 24 ? 0 : endTime;

      return endTime > startTime;
    };

    const ctaOptions = reactive<SelectOption[]>([
      { key: "Shop Now", value: "Shop Now" },
      { key: "Buy Now", value: "Buy Now" },
      { key: "Order Now", value: "Order Now" },
      { key: "Get Offer", value: "Get Offer" },
      { key: "Learn More", value: "Learn More" },
      { key: "Sign Up", value: "Sign Up" },
      { key: "Subscribe", value: "Subscribe" },
      { key: "Download", value: "Download" },
      { key: "Watch Video", value: "Watch Video" },
      { key: "See Menu", value: "See Menu" },
      { key: "Book Now", value: "Book Now" },
      { key: "Get Quote", value: "Get Quote" },
      { key: "Apply Now", value: "Apply Now" },
      { key: "Contact Us", value: "Contact Us" },
      { key: "Get Directions", value: "Get Directions" },
      { key: "Install Now", value: "Install Now" },
      { key: "Play Now", value: "Play Now" },
      { key: "Read More", value: "Read More" },
      { key: "Start Free Trial", value: "Start Free Trial" },
      { key: "View Demo", value: "View Demo" },
    ]);

    const biddingStrategiesOptions = (): SelectOption[] => {
      if (newPromotion.basic.type == "shoppable") {
        return [
          {
            key: "cpm",
            value: "Cost Per 1,000 Impression (CPM)",
          },
          {
            key: "cpc",
            value: "Cost Per Click (CPC)",
          },
        ];
      } else {
        return [
          {
            key: "cpc",
            value: "Cost Per Click (CPC)",
          },
          {
            key: "cpm",
            value: "Cost Per 1,000 Impression (CPM)",
          },
        ];
      }
    };

    const getMaximumStrategyAmount = (type: string) => {
      if (type == "cpc") {
        return 70;
      }
      if (type == "cpm") {
        return 7000;
      }
      if (type == "cpe") {
        return 7000;
      }

      return 0;
    };

    const getMinimumStrategyAmount = (type: string) => {
      if (type == "cpc") {
        return 40;
      }
      if (type == "cpm") {
        return 5000;
      }
      if (type == "cpe") {
        return 5000;
      }

      return 0;
    };

    const formIsValid = computed(() => {
      if (promotionCreationStep.current == 1) {
        return (
          newPromotion.basic.name != "" &&
          ((newPromotion.basic.type == "shoppable" && newPromotion.basic.shoplist_id) ||
            (newPromotion.basic.type == "website_visit" &&
              newPromotion.basic.cta &&
              newPromotion.basic.landing_page_url) ||
            (newPromotion.basic.type == "polls" &&
              newPromotion.basic.polls.options.length > 0) ||
            (newPromotion.basic.type == "banner" &&
              newPromotion.basic.landing_page_url &&
              newPromotion.basic.banner.crop_image_blob))
        );
      } else if (promotionCreationStep.current == 2) {
        return (
          newPromotion.targeting.age_ranges.length > 0 &&
          newPromotion.targeting.behavioural_demographies.length > 0 &&
          newPromotion.targeting.gender.length > 0 &&
          newPromotion.targeting.locations.length > 0
        );
      } else if (promotionCreationStep.current == 3) {
        return (
          newPromotion.budgeting.max_amount != "" &&
          parseInt(newPromotion.budgeting.max_amount.replace(/,/g, "")) >=
            getMinimumStrategyAmount(newPromotion.budgeting.strategy) &&
          parseInt(newPromotion.budgeting.total.replace(/,/g, "")) > 4999 &&
          newPromotion.budgeting.strategy != ""
        );
      } else if (promotionCreationStep.current == 4) {
        return (
          newPromotion.scheduling.start != "" &&
          newPromotion.scheduling.end != "" &&
          newPromotion.scheduling.ad_start_time != "" &&
          newPromotion.scheduling.ad_end_time != ""
        );
      } else if (promotionCreationStep.current == 5) {
        return (
          newPromotion.legal.merchant_terms &&
          newPromotion.legal.privacy &&
          newPromotion.legal.ad_guidelines
        );
      }

      return false;
    });

    const goBackHandler = () => {
      if (promotionCreationStep.current <= 1) {
        showSettings.value = false;
      } else {
        promotionCreationStep.current--;
      }
    };

    const resetForm = () => {
      newPromotion.basic = {
        name: "",
        type: "banner",
        shoplist_id: "",
        cta: "Visit now",
        polls: {
          question: "Is this your favourite food?",
          answer_type: "options",
          option_type: "plain",
          options: [],
        },
        banner: {},
      };
      newPromotion.targeting = {
        age_ranges: [],
        gender: [],
        locations: [],
        behavioural_demographies: [],
      };
      newPromotion.budgeting = {
        max_amount: "",
        strategy: "",
        total: "",
      };
      newPromotion.scheduling = {
        start: Logic.Common.momentInstance(new Date()).format("YYYY-MM-DD"),
        end: Logic.Common.momentInstance(new Date()).add(7, "days").format("YYYY-MM-DD"),
        ad_start_time: "24",
        ad_end_time: "23",
      };
      newPromotion.legal = {
        merchant_terms: false,
        privacy: false,
        ad_guidelines: false,
      };
      newPromotion.run_until_paused = true;
    };
    const handleProgress = (progress: number) => {
      newPromotion.progress.percentage = progress;
      newPromotion.progress.message = "Uploading...";
      if (progress >= 100) {
        newPromotion.progress.message = "Processing...";
      }
    };

    const savePromotion = () => {
      const getMergedValues = (data: any[][]) => {
        const mergedArrays = data.flat();
        return Array.from(new Set(mergedArrays));
      };

      let startTime = parseInt(newPromotion.scheduling.ad_start_time);
      let endTime = parseInt(newPromotion.scheduling.ad_end_time);

      startTime = startTime == 24 ? 0 : startTime;
      endTime = endTime == 24 ? 0 : endTime;

      Logic.Ad.CreateAdvertForm = {
        ad_budget: newPromotion.budgeting.total.replace(/,/g, ""),
        maximum_cost: newPromotion.budgeting.max_amount.replace(/,/g, ""),
        ad_daytime_start: startTime,
        ad_daytime_end: endTime,
        ad_start_date: newPromotion.scheduling.start,
        ad_end_date: newPromotion.scheduling.end,
        bidding_strategy: newPromotion.budgeting.strategy,
        objectives: Objectives.Traffic,
        type: newPromotion.basic.type,
        promotion_title: newPromotion.basic.name,
        source_media_type: newPromotionMedia.type,
        source_media:
          newPromotionMedia.type == "image"
            ? newPromotion.basic.type != "banner"
              ? newPromotionMedia.base_image
              : newPromotion.basic.banner.crop_image_blob
            : newPromotionMedia.base_video,
        cta_rules: {
          cta: newPromotion.basic.cta,
          landing_page_url: newPromotion.basic.landing_page_url,
          polls: {
            answer_type: newPromotion.basic.polls.answer_type,
            option_type: newPromotion.basic.polls.option_type,
            question: newPromotion.basic.polls.question,
            options: newPromotion.basic.polls.options.map((item) => {
              return {
                slug: item.slug,
                value: item.value,
                image: item.image_blob,
              };
            }),
          },
        },
        metadata: {
          media_type: newPromotionMedia.type,
          shoplist_id: newPromotion.basic.shoplist_id,
        },
        target_audience_rules: {
          age_ranges: getMergedValues(newPromotion.targeting.age_ranges).map((item) =>
            parseInt(item)
          ),
          gender: newPromotion.targeting.gender,
          locations: getMergedValues(newPromotion.targeting.locations).map((item) =>
            parseInt(item)
          ),
          demographies: getMergedValues(
            newPromotion.targeting.behavioural_demographies
          ).map((item) => parseInt(item)),
        },
        run_until_paused: newPromotion.run_until_paused,
      };

      newPromotion.progress.loading = true;

      Logic.Ad.CreateAdvert(handleProgress)?.then((data) => {
        if (data) {
          Logic.Ad.GetAdverts(
            1,
            15,
            "CREATED_AT",
            "DESC",
            `{
                      column: USER_ID
                      operator: EQ
                      value: ${Logic.Auth.AuthUser?.id}
                    }`
          ).then(() => {
            newPromotion.progress.loading = false;
            resetForm();
            Logic.Common.showAlert({
              show: true,
              message: "Promotion created successfully",
              type: "success",
            });
          });
        } else {
          newPromotion.progress.loading = false;
        }
      });

      Logic.Common.showAlert({
        show: true,
        message: "Promotion is now being created",
        type: "info",
      });
      Logic.Common.goBack();
    };

    const makeAdPayment = () => {
      Logic.Common.showModal({
        show: true,
        title: "Complete Payment",
        type: "payment_provider",
        extraData: {
          amount: parseFloat(newPromotion.budgeting.total.replace(/,/g, "")),
          currency: "NGN",
          email: Logic.Auth.AuthUser?.email,
          user_uuid: Logic.Auth.AuthUser?.uuid,
          wallet_uuid: AdWallet.value?.uuid,
          type: "cash",
          narration: "Top Up Ad Wallet",
        },
        action: () => {
          Logic.Wallet.GetTransactionsByWalletId(
            "advert",
            parseInt(AdWallet.value?.id || "0"),
            1,
            50,
            "UPDATED_AT",
            "DESC"
          );
          Logic.Wallet.GetAdWallet();
          Logic.Common.showModal({ show: false });

          savePromotion();
        },
        preventClose: true,
        closeAction: () => {
          Logic.Common.showModal({ show: false });
        },
      });
    };

    const moveForward = () => {
      if (formIsValid.value) {
        if (promotionCreationStep.current < promotionCreationStep.total) {
          promotionCreationStep.current += 1;
        } else {
          makeAdPayment();
        }
      }
    };

    const onBiddingStategySelected = () => {
      showMaximimAmount.value = false;

      setTimeout(() => {
        showMaximimAmount.value = true;
      }, 500);
    };

    const addOption = () => {
      newPromotion.basic.polls.options.push({
        slug: "new_option",
        value: "New Option",
        image_url: "",
      });
    };

    const removeOption = (index: number) => {
      newPromotion.basic.polls.options = newPromotion.basic.polls.options.filter(
        (innerItem, innerIndex) => innerIndex != index
      );
    };

    watch(promotionCreationStep, () => {
      if (promotionCreationStep.current == 1) {
        title.value = "Basic Details";
      }
      if (promotionCreationStep.current == 2) {
        title.value = "Ad Targeting";
      }
      if (promotionCreationStep.current == 3) {
        title.value = "Budget and Bidding";
      }
      if (promotionCreationStep.current == 4) {
        title.value = "Scheduling";
      }
      if (promotionCreationStep.current == 5) {
        title.value = "Legal and Compliance";
      }
    });

    onMounted(() => {
      locationOptions.value = supportedAreasOption(true);
      behaviouralDemographiesOptions.value = adDemographiesOptions();
      Logic.Wallet.watchProperty("AdWallet", AdWallet);
    });

    return {
      promotionCreationStep,
      title,
      newPromotion,
      FormValidations,
      baseShoplist,
      Logic,
      ageRangeOptions,
      genderOptions,
      showMaximimAmount,
      showSettings,
      ctaOptions,
      timeOfTheDayOptions,
      formIsValid,
      locationOptions,
      behaviouralDemographiesOptions,
      AdWallet,
      endTimeMustBeMoreThanStartTime,
      addOption,
      removeOption,
      getMaximumStrategyAmount,
      biddingStrategiesOptions,
      goBackHandler,
      moveForward,
      getMinimumStrategyAmount,
      onBiddingStategySelected,
    };
  },
});
</script>
