import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`absolute left-[6%]  bottom-[6%]`)
}
const _hoisted_2 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`px-2 py-1 rounded-[4px] bg-primary-900 flex flex-row items-center justify-center`)
}
const _hoisted_3 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`absolute  z-10  left-[5%]  top-[5%]`)
}
const _hoisted_4 = { class: "w-full flex flex-col space-y-1 pb-2" }
const _hoisted_5 = { class: "w-full flex flex-row items-center justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_badge = _resolveComponent("app-badge")!

  return ($props.data)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`w-full flex flex-col space-y-2 ${$props.customClass} cursor-pointer `)
      }, [
        _createVNode(_component_app_image_loader, {
          photoUrl: $props.data.base_image_url,
          class: "!h-[130px] mdlg:!h-[200px] w-full relative rounded-[8px] border-[1px] border-grey-100 dark:!border-gray-700"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, [
              ($props.showCommission)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                    _createVNode(_component_app_normal_text, { class: "!text-white !text-[10px] text-center" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(!$props.showCommission
                ? `${$props.data.cashback}% Back`
                : `Earn ${$props.data.resale_commission}%`), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            ($props.showCommission)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: $props.data.merchant.profile_image_url,
                    class: _normalizeClass(`!rounded-full h-[20px] w-[20px]`)
                  }, null, 8, ["photoUrl"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["photoUrl"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_normal_text, { class: "!font-semibold text-left !line-clamp-1" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.data.title), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_normal_text, { class: "text-left" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.Logic.Common.convertToMoney($props.data.price, false, "ngn")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_app_badge, {
              color: $props.data.available_stock > 0 ? 'green' : 'red',
              class: "!text-[10px] !px-2 !py-[2px]"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.data.available_stock > 0
              ? `${$props.data.available_stock} available`
              : "Sold out"), 1)
              ]),
              _: 1
            }, 8, ["color"])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}