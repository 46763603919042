import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_7 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide",
  id: "dataServiceContainer"
}
const _hoisted_8 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "flex items-center justify-center" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_13 = { class: "flex flex-row space-x-2 py-2 pr-4" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_16 = ["onClick"]
const _hoisted_17 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_18 = { class: "w-[20px] flex items-center justify-center" }
const _hoisted_19 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_20 = { class: "flex flex-row space-x-[2px] items-center" }
const _hoisted_21 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Buy Data' }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_button, { class: "!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: `beneficiary${
                _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`,
                  "custom-class": "!h-[12px]"
                }, null, 8, ["name"]),
                _createVNode(_component_app_normal_text, { class: "!text-primary-500 dark:!text-primary-100" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Beneficiaries ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter phone number',
                    hasTitle: true,
                    type: 'tel',
                    name: "Phone number",
                    ref: "phoneNumberRef",
                    rules: [
                _ctx.FormValidations.RequiredRule,
                _ctx.FormValidations.customValidator(
                  _ctx.phoneNumber.length == 11,
                  'Phone number must be valid'
                ),
              ],
                    "update-value": _ctx.phoneNumber,
                    "content-rule": {
                addAfterCount: 0,
                characterToAdd: ``,
                max: 11,
              },
                    modelValue: _ctx.phoneNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.phoneNumber) = $event))
                  }, _createSlots({
                    title: _withCtx(() => [
                      _cache[4] || (_cache[4] = _createTextVNode(" Phone number / Customer ID "))
                    ]),
                    _: 2
                  }, [
                    (_ctx.Logic.Common.currentBuildType() == 'mobile')
                      ? {
                          name: "inner-suffix",
                          fn: _withCtx(() => [
                            _createVNode(_component_app_icon, {
                              name: 'phonebook',
                              customClass: 'h-[18px]',
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchPhone()))
                            })
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["rules", "update-value", "modelValue"]),
                  _createVNode(_component_app_checkbox, {
                    modelValue: _ctx.saveAsBeneficiary,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.saveAsBeneficiary) = $event)),
                    standard: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_app_normal_text, { color: 'text-shop_dark_400 dark:!text-white' }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Save as beneficiary ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "w-full border-t-[1px] border-grey-50 dark:!border-grey-500" }, null, -1))
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_normal_text, { class: "!text-[12px] text-left px-4 dark:!text-white" }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Select Network Provider ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[8] || (_cache[8] = _createElementVNode("div", null, null, -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allNetworks, (network, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "flex flex-row pl-3",
                              key: index,
                              id: `cableTv${network.id}`
                            }, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("div", {
                                  class: "w-[56px] h-[56px] rounded-full relative cursor-pointer",
                                  onClick: ($event: any) => (_ctx.selectedNetwork = network.id)
                                }, [
                                  _createVNode(_component_app_image_loader, {
                                    "photo-url": network.logo,
                                    class: _normalizeClass(`h-[56px] w-[56px] dark:border-gray-50 dark:border-[1px] ${
                              _ctx.selectedNetwork == network.id
                                ? 'border-[2px] border-primary-400 rounded-full'
                                : ' rounded-full'
                            } `)
                                  }, null, 8, ["photo-url", "class"]),
                                  (_ctx.selectedNetwork == network.id)
                                    ? (_openBlock(), _createBlock(_component_app_icon, {
                                        key: 0,
                                        name: 'mark',
                                        customClass: 'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '
                                      }))
                                    : _createCommentVNode("", true)
                                ], 8, _hoisted_11)
                              ])
                            ], 8, _hoisted_9))
                          }), 128))
                        ])
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dataDealSections, (section, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(` ${
                  _ctx.selectedPlanFilter == section.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-700'
                } px-4 py-2 rounded-[30px] w-auto cursor-pointer`),
                        key: index,
                        onClick: ($event: any) => (_ctx.selectedPlanFilter = section.id)
                      }, [
                        _createVNode(_component_app_normal_text, {
                          class: _normalizeClass(`!whitespace-nowrap !capitalize ${
                    _ctx.selectedPlanFilter == section.id
                      ? 'text-white'
                      : 'text-gray-600 dark:text-gray-50'
                  }`)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(section.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ], 10, _hoisted_14))
                    }), 128))
                  ])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectionOptions, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "w-full flex flex-row justify-between items-center border-b-[1px] border-[#E9E6ED] dark:border-gray-500 pt-1 pb-3 px-2 cursor-pointer",
                      key: index,
                      onClick: ($event: any) => (_ctx.selectedProduct = item.code)
                    }, [
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_app_icon, {
                            name: 
                      _ctx.selectedProduct == item.code
                        ? `selected${
                            _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                          }`
                        : 'not-selected'
                    ,
                            customClass: 'h-[20px]'
                          }, null, 8, ["name"])
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _createVNode(_component_app_normal_text, { customClass: '!font-semibold dark:!text-white' }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createElementVNode("span", _hoisted_20, [
                            _createVNode(_component_app_icon, {
                              name: 'cashback-icon',
                              customClass: 'h-[20px]'
                            }),
                            _createVNode(_component_app_normal_text, { class: "!text-[#898989]" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.sub_title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ])
                      ]),
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass('!font-semibold !text-sm xs:!text-[13px] !whitespace-nowrap dark:!text-white')
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.amount / 100, false, "ngn")), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ], 8, _hoisted_16))
                  }), 128)),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
                ])
              ]),
              _: 1
            }, 8, ["parentRefs"])
          ]),
          _createVNode(_component_fixed_container, { class: "pt-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_21, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "bg-color": 'bg-primary-400',
                  "text-color": 'text-white',
                  class: _normalizeClass(_ctx.formIsValid ? 'w-full' : 'opacity-50'),
                  onClick: _ctx.purchaseData
                }, {
                  default: _withCtx(() => _cache[10] || (_cache[10] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["class", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}