<template>
  <div class="w-full flex flex-col space-y-3 pt-2 relative">
    <app-normal-text class="w-full text-left !text-gray-600">
      A verification link has been sent to your email ({{
        localStorage.getItem("acc_email")
      }})
    </app-normal-text>
    <div
      class="w-full flex flex-col space-y-4 h-[200px] justify-center items-center"
    >
      <span>
        <svg
          :class="`animate-spin mr-3 h-8 w-8 text-primary-500`"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </span>
      <app-normal-text class="text-center">
        Waiting for you to verify your email...
      </app-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
  },
  props: {
    data: {
      type: Object as () => {
        info: string;
      },
    },
  },
  name: "AppModalVerifyEmail",
  setup() {
    const CheckStatusVerification = ref(Logic.Auth.CheckStatusVerification);

    const startStatusMonitoring = () => {
      Logic.Auth.CheckUserVerification(localStorage.getItem("acc_email") || "");
    };

    onMounted(() => {
      Logic.Auth.watchProperty(
        "CheckStatusVerification",
        CheckStatusVerification
      );
      startStatusMonitoring();
    });

    return {
      Logic,
      localStorage,
    };
  },
});
</script>
