<template>
  <app-wrapper>
    <subpage-layout
      :title="`Buy Gift Card`"
      :type="'image_card'"
      :photoUrl="'/images/gift-card-bg.png'"
      :brandImage="brandImage"
    >
      <template v-slot:extra-topbar-special>
        <div
          class="w-full flex flex-row items-center justify-start px-2 -mb-[33px]"
        >
          <app-header-text :customClass="'!font-bold dark!text-white'">
            {{ SingleProduct.value?.merchant_name }} Gift Cards
          </app-header-text>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-5 xs:space-y-4 pt-[50px]">
        <div class="w-full flex flex-col space-y-4" v-if="SingleProduct">
          <!-- Countries -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text class="!text-[12px] text-left px-4 uppercase">
              Select country
            </app-normal-text>

            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
            >
              <div class="flex flex-row space-x-3 pr-4">
                <div
                  class="flex items-center justify-center"
                  v-for="(country, index) in billerCountries"
                  :key="index"
                >
                  <div
                    class="w-[56px] h-[56px] rounded-full relative cursor-pointer"
                    @click="selectedCountry = country.id"
                  >
                    <app-image-loader
                      :photo-url="country.logo"
                      :class="`h-[56px] w-[56px] dark:border-[1px] dark:border-grey-50 ${
                        selectedCountry == country.id
                          ? 'border-[2px] border-primary-400 rounded-full'
                          : ' rounded-full'
                      } `"
                    />
                    <app-icon
                      v-if="selectedCountry == country.id"
                      :name="'mark'"
                      :customClass="'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Info section -->
          <div class="w-full flex flex-col px-4">
            <div
              class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-500 dark:bg-secondary-200 !bg-opacity-60"
            >
              <span class="!text-black dark:!text-grey-50">
                These gift cards can only be used in
                <span class="!font-semibold">{{ selectedCountry }}</span>
              </span>
            </div>
          </div>

          <!-- Items -->
          <div class="w-full flex flex-col space-y-3">
            <div
              class="w-full flex flex-col space-y-1 items-start justify-start"
            >
              <!-- For custom items -->
              <div
                class="w-full flex flex-col space-y-2 px-4"
                v-if="
                  selectedCountryItems.length == 1 ||
                  selectedCountryItems[0]?.amount_In_Naira == 0
                "
              >
                <!-- Amount field -->

                <app-text-field
                  :placeholder="'Enter amount'"
                  :hasTitle="true"
                  :type="'tel'"
                  :name="'Amount'"
                  :isFormatted="true"
                  v-model="amountValue"
                  :rules="[
                    FormValidations.customValidator(
                      parseFloat(amountValue?.replace(/,/g, '')) <=
                        parseFloat(
                          selectedCountryItems[0].local_Product_Value_Max
                        ) &&
                        parseFloat(amountValue?.replace(/,/g, '')) >=
                          parseFloat(
                            selectedCountryItems[0].local_Product_Value_Min
                          ),
                      `Amount must within the range below`
                    ),
                  ]"
                >
                  <template v-slot:title> Enter amount </template>
                  <template v-slot:inner-prefix>
                    <app-normal-text class="dark:!text-white">
                      {{ selectedCountryItems[0].country.currency }}
                    </app-normal-text>
                  </template>
                  <template v-slot:inner-suffix>
                    <div class="flex flex-row space-x-1 justify-end flex-grow">
                      <app-icon
                        :name="'cashback-icon'"
                        :customClass="'h-[20px]'"
                      />
                      <app-normal-text
                        :customClass="'!whitespace-nowrap dark:!text-white'"
                      >
                        You'll get
                        <span
                          class="font-semibold text-primary-500 dark:!text-primary-300 px-1"
                          >{{
                            Logic.Common.convertToMoney(
                              getProductPoint(
                                selectedCountryItems[0].product_category_slug,
                                (
                                  parseFloat(amountValue?.replace(/,/g, "")) *
                                  selectedCountryItems[0].rate
                                ).toString()
                              ),
                              false,
                              ""
                            )
                          }}
                          Points</span
                        >
                      </app-normal-text>
                    </div>
                  </template>
                </app-text-field>

                <div class="w-full flex flex-row justify-end">
                  <app-normal-text customClass="!pb-1 ">
                    Range:
                    <span class="font-semibold pl-1"
                      >{{
                        Logic.Common.convertToMoney(
                          selectedCountryItems[0].local_Product_Value_Min,
                          false,
                          "",
                          false,
                          `${selectedCountryItems[0].country.currency}`
                        )
                      }}
                      -
                      {{
                        Logic.Common.convertToMoney(
                          selectedCountryItems[0].local_Product_Value_Max,
                          false,
                          "",
                          false,
                          `${selectedCountryItems[0].country.currency}`
                        )
                      }}</span
                    >
                  </app-normal-text>
                </div>
              </div>

              <!-- For defined items -->
              <div
                class="w-full flex flex-col space-y-3 px-4 min-h-[150px]"
                v-else
              >
                <div
                  class="w-full flex flex-row justify-between items-center bg-[#F7F7F7] dark:bg-gray-800 rounded-[14px] px-3 py-3 cursor-pointer"
                  v-for="(item, index) in selectedCountryItems"
                  :key="index"
                  @click="selectItem(item)"
                >
                  <div class="flex flex-row space-x-3 items-center">
                    <div class="w-[24px]">
                      <app-icon
                        :name="
                          billerFormData.payment_code == item.code
                            ? `selected${
                                Logic.Common.currentAppTheme() == 'light'
                                  ? ''
                                  : '-white'
                              }`
                            : 'not-selected'
                        "
                        :customClass="'h-[19px]'"
                      />
                    </div>
                    <div class="flex flex-col">
                      <app-normal-text :customClass="'!font-semibold'">
                        {{
                          `${
                            item.country.currency
                          } ${Logic.Common.convertToMoney(
                            item.local_Product_Value_Max,
                            true,
                            ""
                          )}`
                        }}
                      </app-normal-text>
                      <app-normal-text
                        :class="'!text-gray-600 dark:!text-gray-200'"
                      >
                        You'll get
                        <span
                          class="text-primary-500 dark:!text-primary-200 pl-1"
                        >
                          {{
                            Logic.Common.convertToMoney(
                              getProductPoint(
                                item.product_category_slug,
                                item.amount_In_Naira.toString()
                              ),
                              false,
                              ""
                            )
                          }}
                          pts</span
                        >
                      </app-normal-text>
                    </div>
                  </div>

                  <app-normal-text
                    :class="'!font-semibold !text-sm xs:!text-[13px] !whitespace-nowrap'"
                  >
                    {{
                      Logic.Common.convertToMoney(
                        item.amount_In_Naira,
                        false,
                        "",
                        false,
                        "NGN "
                      )
                    }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h-[100px]"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container class="pt-4">
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :bg-color="'bg-primary-400'"
            :text-color="'text-white'"
            :class="formIsValid ? 'w-full' : 'opacity-50'"
            @click="purchaseGiftCard"
          >
            Continue
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  getProductPoint,
  makePurchase,
  processProductData,
  setCurrentProduct,
  SingleProduct,
} from "@shpt/ui-components/src/composable/shop";
import { SelectOption } from "@shpt/ui-components/src/types";
import { Product } from "@shpt/logic/src/gql/graphql";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import {
  AppHeaderText,
  AppNormalText,
  AppIcon,
  AppImageLoader,
  AppTextField,
  AppButton,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppIcon,
    AppImageLoader,
    AppTextField,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "BuyGiftCardInnerPage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Initiate Buy Giftcard Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Shop",
        property: "ShopData",
        subProperty: "gift_cards",
        method: "GetProductsByCategory",
        params: ["gift_cards"],
        requireAuth: false,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Buy Giftcard",
    });

    const productUuid = ref("");

    const brandImage = ref("");

    const setSelectedProduct = () => {
      setCurrentProduct(productUuid.value, "gift_cards");
      brandImage.value = SingleProduct.value?.image_url || "";
    };

    const billerFormData = reactive({
      biller_name: "",
      payment_code: "",
      meter_number: "",
      amount: "",
    });
    const selectedProductSlug = ref("");
    const billersOptons = reactive<SelectOption[]>([]);
    const billerCountries = reactive<
      {
        id: string;
        logo: string;
        data: any;
      }[]
    >([]);
    const saveAsBeneficiary = ref(false);
    const amountValue = ref("");
    const selectedCountry = ref("");

    const formIsValid = computed(() => {
      const amount = parseFloat(amountValue?.value.replace(/,/g, ""));
      return amount > 0;
    });

    const selectedCountryItems = reactive<
      {
        local_Product_Value_Max: string;
        local_Product_Value_Min: string;
        rate: number;
        kuda_Identifier: string;
        amount_In_Naira: number;
        product_category_slug: string;
        country: {
          currency: string;
        };
        code: string;
      }[]
    >([]);

    const selectItem = (item: any) => {
      billerFormData.payment_code = item.code;
      billerFormData.amount = item.amount_In_Naira;
    };

    const setPaymentItems = () => {
      if (billerFormData.biller_name) {
        const currentBiller = billersOptons.filter((item) => {
          return item.key == billerFormData.biller_name;
        })[0];

        let allItems: any = currentBiller.extras;

        allItems = allItems.map((item: any) => {
          const metaData = JSON.parse(item.metadata);
          item.country = metaData.country.name;
          return item;
        });

        const groupedData = Logic.Common.groupArrayBy(allItems, "country");

        billerCountries.length = 0;

        for (const key in groupedData) {
          if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
            const element = groupedData[key];
            const metaData = JSON.parse(element[0].metadata);
            billerCountries.push({
              id: key,
              logo: metaData.country.image,
              data: element,
            });
          }
        }

        setCountryItems();
      }
    };

    const setCountryItems = () => {
      const allCountryItems = billerCountries.filter((item) => {
        return item.id == selectedCountry.value;
      });

      if (allCountryItems.length) {
        selectedCountryItems.length = 0;

        let countryItems = allCountryItems[0].data;

        countryItems.forEach((item: Product) => {
          const metaData = JSON.parse(item.metadata || "[]");
          metaData.product_category_slug = item.product_category.slug;
          metaData.code = item.code;
          selectedCountryItems.push(metaData);
        });

        amountValue.value = Logic.Common.convertToMoney(
          selectedCountryItems[0].amount_In_Naira.toString(),
          true,
          ""
        );
        billerFormData.amount = (
          parseFloat(
            selectedCountryItems[0].local_Product_Value_Min.toString()
          ) * selectedCountryItems[0].rate
        ).toString();
        billerFormData.payment_code = selectedCountryItems[0].code;
      }
    };

    watch(selectedCountry, () => {
      setCountryItems();
    });

    const setBillers = () => {
      const billerDeals = processProductData(
        Logic.Shop.ShopData.gift_cards || []
      );
      const billerDealsData = billerDeals.map((item) => item.data);

      const groupedData = Logic.Common.groupArrayBy(
        billerDealsData,
        "merchant_name"
      );

      billersOptons.length = 0;

      for (const key in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
          const element = groupedData[key];
          billersOptons.push({
            key,
            value: capitalize(key),
            extras: element,
            imageUrl: element[0].image_url,
          });
        }
      }
    };

    const handleBillerSelected = () => {
      setPaymentItems();
    };

    const setBillerData = () => {
      setBillers();
      if (SingleProduct.value) {
        const metadata: any = JSON.parse(SingleProduct.value.metadata || "");
        billerFormData.biller_name = SingleProduct.value.merchant_name || "";
        amountValue.value = metadata.local_Product_Value_Min?.toString();
        billerFormData.amount = metadata.local_Product_Value_Min;
        selectedCountry.value = metadata.country.name;
        SingleProduct.value.image_url = metadata.package.image;
        billerFormData.payment_code = SingleProduct.value.code;
        setPaymentItems();
      }
    };

    const setProductUuid = () => {
      const windowPath = window.location.pathname.split("/");
      productUuid.value = windowPath[windowPath.length - 1];

      useMeta({
        title: `Buy ${SingleProduct.value?.merchant_name} Giftcard`,
      });
    };

    watch(amountValue, () => {
      const amount = parseFloat(amountValue.value?.replace(/,/g, ""));
      billerFormData.amount = (
        amount * selectedCountryItems[0].rate
      ).toString();
    });

    const purchaseGiftCard = () => {
      if (
        parseFloat(billerFormData.amount) > 0 &&
        billerFormData.payment_code
      ) {
        billerFormData.biller_name = SingleProduct.value?.merchant_name || "";
        makePurchase(
          {
            code: billerFormData.payment_code,
            amount: billerFormData.amount.toString(),
            customerId: "gift_cards",
            phone_no: Logic.Auth.AuthUser?.phone?.replace("234", "0") || "",
          },
          saveAsBeneficiary.value
        );
      }
    };

    watch(productUuid, () => {
      setSelectedProduct();
      setBillerData();
    });

    onMounted(() => {
      setProductUuid();
      setSelectedProduct();
      billerFormData.biller_name = SingleProduct.value?.merchant_name || "";
      setBillerData();
    });

    onIonViewWillEnter(() => {
      setProductUuid();
    });

    return {
      billersOptons,
      billerFormData,
      FormValidations,
      selectedProductSlug,
      Logic,
      saveAsBeneficiary,
      amountValue,
      loaderSetup,
      billerCountries,
      selectedCountry,
      selectedCountryItems,
      brandImage,
      SingleProduct,
      formIsValid,
      handleBillerSelected,
      purchaseGiftCard,
      getProductPoint,
      selectItem,
    };
  },
});
</script>
