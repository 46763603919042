import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-row relative h-full w-full"
}
const _hoisted_2 = {
  key: 1,
  class: "flex justify-center w-full"
}
const _hoisted_3 = { class: "rounded flex flex-row w-fit items-center justify-center relative space-x-2 px-1 py-4 bg-grayBackground border-dashed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", {
    class: "flex w-full flex-col justify-center space-y-2",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.pickFiles()))
  }, [
    (_ctx.isWrapper)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "content"),
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_icon, {
              name: `${_ctx.iconName}`,
              customClass: 'h-[20px]'
            }, null, 8, ["name"]),
            _createVNode(_component_app_normal_text, {
              color: "text-base-black",
              customClass: "w-full text-left !font-semibold line-clamp-1"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.selectedFileName != "" ? _ctx.selectedFileName : _ctx.placeholder), 1)
              ]),
              _: 1
            })
          ])
        ]))
  ]))
}