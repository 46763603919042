<template>
  <app-wrapper>
    <subpage-layout :title="'Membership'">
      <div class="w-full h-full flex flex-col px-4">
        <div
          v-for="(plan, index) in plans"
          :key="index"
          :class="`w-full rounded-t-[20px] px-3 py-3 flex flex-col cursor-pointer ${
            plan.bg_color
          } ${index == plans.length - 1 ? 'pb-[15px]' : 'pb-[30px]'} mb-[-20px] ${
            currentSelectedPlan == index ? 'flex-grow h-full' : ''
          }`"
          @click="currentSelectedPlan = index"
        >
          <div class="w-full flex flex-row justify-between">
            <div class="flex flex-row items-center space-x-1">
              <div class="!w-[48px]">
                <div class="w-[48px]">
                  <app-icon :name="plan.card_bagde_image" :customClass="'h-[44px] '" />
                </div>
              </div>
              <div class="flex flex-col space-y-1">
                <app-normal-text
                  :class="`!font-semibold !text-sm ${plan.text_color} text-left`"
                >
                  {{ plan.title }}
                </app-normal-text>
                <app-normal-text :class="`!text-[11px] ${plan.text_color} text-left`">
                  Up to {{ plan.snaps_per_day }} snaps per day and
                  {{ plan.spend_per_week }} Weekly spend
                </app-normal-text>
              </div>
            </div>

            <div class="flex flex-col">
              <div
                v-if="index == 0"
                :class="`px-4 py-1 !bg-white !bg-opacity-50 rounded-[30px] flex items-center justify-center`"
              >
                <app-normal-text
                  :class="`${plan.text_color} !text-[11px] text-center whitespace-nowrap`"
                >
                  Current Tier
                </app-normal-text>
              </div>
              <app-normal-text v-else :class="`text-right ${plan.text_color} !text-sm`">
                {{
                  plan.price
                    ? `${Logic.Common.convertToMoney(plan.price, false, "ngn")}/Month`
                    : "FREE"
                }}
              </app-normal-text>
            </div>
          </div>

          <!-- Content -->
          <template v-if="currentSelectedPlan == index">
            <div class="w-full flex flex-row items-center justify-end">
              <div
                :class="`px-4 py-1 bg-white  rounded-[30px] flex items-center justify-center cursor-pointer`"
                @click="showSubscriptionPlan(plan)"
              >
                <app-normal-text
                  :class="`!text-black !text-[11px] text-center whitespace-nowrap`"
                >
                  Upgrade
                </app-normal-text>
              </div>
            </div>
            <div class="w-full h-full overflow-y-auto scrollbar-hide">
              <div
                :class="`w-full !text-[12px] py-2 overflow-y-auto scrollbar-hide !leading-tight ${plan.text_color}`"
                v-html="plan.description"
              ></div>
            </div>
          </template>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import { AppIcon, AppNormalText } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

interface SubscriptionPlanData {
  id: string;
  title: string;
  price: number;
  description: string;
  bg_color: string;
  card_bagde_image: string;
  text_color: string;
  snaps_per_day: number;
  spend_per_week: string;
}

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppWrapper,
  },
  name: "MembershipTiersPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Membership Tiers Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "User",
        property: "ManySubscriptionPlans",
        method: "GetSubscriptionPlans",
        params: [],
        requireAuth: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Membership Tiers",
    });

    const ManySubscriptionPlans = ref(Logic.User.ManySubscriptionPlans);

    const AuthUser = ref(Logic.Auth.AuthUser);

    const plans = reactive<SubscriptionPlanData[]>([]);

    const currentSelectedPlan = ref(0);

    const selectedPlanId = ref("0");

    const getSubscriptionConfig = (name: string) => {
      if (name.includes("Bronze")) {
        return {
          bg_color: "!bg-[#9754BF]",
          card_bagde_image: "plans/bronze",
          text_color: "text-white",
        };
      }

      if (name.includes("Silver")) {
        return {
          bg_color: "!bg-[#D9D9D9]",
          card_bagde_image: "plans/silver",
          text_color: "text-black",
        };
      }

      if (name.includes("Gold")) {
        return {
          bg_color: "!bg-[#E89800]",
          card_bagde_image: "plans/gold",
          text_color: "text-white",
        };
      }

      if (name.includes("Platinum")) {
        return {
          bg_color: "!bg-[#E36089]",
          card_bagde_image: "plans/platinum",
          text_color: "text-white",
        };
      }

      if (name.includes("Diamond")) {
        return {
          bg_color: "!bg-[#2AA763]",
          card_bagde_image: "plans/diamond",
          text_color: "text-white",
        };
      }
    };

    const showSubscriptionPlan = (plan: SubscriptionPlanData) => {
      Logic.User.UpdateUserSubscription(plan.id, plan.title)?.then(() => {
        Logic.Auth.GetAuthUser();
      });
    };

    const setSubscriptionPlan = () => {
      plans.length = 0;
      let currentSubscription: SubscriptionPlanData | undefined = undefined;

      ManySubscriptionPlans.value?.forEach((plan) => {
        if (plan) {
          const subscription = {
            id: plan.uuid,
            description: plan.description || "",
            price: parseFloat(plan.price),
            title: plan.name,
            text_color: getSubscriptionConfig(plan.name)?.text_color || "",
            bg_color: getSubscriptionConfig(plan.name)?.bg_color || "",
            card_bagde_image: getSubscriptionConfig(plan.name)?.card_bagde_image || "",
            snaps_per_day: plan.receipt_scan_allowed_days,
            spend_per_week: Logic.Common.convertToMoney(
              plan.receipt_weekly_spend_limit,
              false,
              "ngn"
            ),
          };

          if (AuthUser.value?.profile?.subscription_plan.uuid != plan.uuid) {
            plans.push(subscription);
          } else {
            currentSubscription = subscription;
          }
        }
      });
      if (currentSubscription) {
        plans.unshift(currentSubscription);
      }
    };

    onMounted(() => {
      Logic.User.watchProperty("ManySubscriptionPlans", ManySubscriptionPlans);
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      setSubscriptionPlan();
    });

    return {
      plans,
      Logic,
      AuthUser,
      selectedPlanId,
      currentSelectedPlan,
      showSubscriptionPlan,
    };
  },
});
</script>
