import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_4 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-[1px] justify-center items-center" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_7 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-[1px] justify-center items-center" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-2 justify-center items-center py-3 px-3" }
const _hoisted_10 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_11 = { class: "w-full flex flex-col space-y-[1px] justify-center items-center" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-2 justify-center items-center py-3 px-3" }
const _hoisted_13 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_14 = { class: "w-full flex flex-col space-y-[1px] justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, { class: "w-full text-left text-gray-700" }, {
      default: _withCtx(() => _cache[7] || (_cache[7] = [
        _createTextVNode(" Select promotion type ")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handlePromotionShoppable('1')))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_icon, {
              name: 'shoppable',
              customClass: '!h-[15px]'
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_app_normal_text, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Shoppable ")
              ])),
              _: 1
            }),
            _createVNode(_component_app_normal_text, { class: "!text-[10px] !text-gray-500 text-center" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Promote your Shoplists ")
              ])),
              _: 1
            })
          ])
        ])
      ]),
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer",
        accept: 'image/jpeg, image/png, image/gif, video/mp4',
        "onUpdate:modelValue": _cache[1] || (_cache[1] = (data) => _ctx.handlePromotionWebsiteVisit(data, 'blob')),
        "onUpdate:localFileUrl": _cache[2] || (_cache[2] = 
          (data) => _ctx.handlePromotionWebsiteVisit(data, 'url')
        )
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_app_icon, {
                name: 'website-visit',
                customClass: '!h-[15px]'
              })
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" Website Visit ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-[10px] !text-gray-500 text-center" }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode(" Promote your website links ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer",
        accept: 'image/jpeg, image/png, image/gif, video/mp4',
        "onUpdate:modelValue": _cache[3] || (_cache[3] = (data) => _ctx.handlePromotionPolls(data, 'blob')),
        "onUpdate:localFileUrl": _cache[4] || (_cache[4] = (data) => _ctx.handlePromotionPolls(data, 'url'))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_app_icon, {
                name: 'polls',
                customClass: '!h-[17px]'
              })
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[12] || (_cache[12] = [
                  _createTextVNode(" Polls ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-[10px] !text-gray-500 text-center" }, {
                default: _withCtx(() => _cache[13] || (_cache[13] = [
                  _createTextVNode(" Survey your customers ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer",
        accept: 'image/jpeg, image/png, image/gif',
        "onUpdate:modelValue": _cache[5] || (_cache[5] = (data) => _ctx.handlePromotionBanner(data, 'blob')),
        "onUpdate:localFileUrl": _cache[6] || (_cache[6] = (data) => _ctx.handlePromotionBanner(data, 'url'))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_app_icon, {
                name: 'banner',
                customClass: '!h-[17px]'
              })
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[14] || (_cache[14] = [
                  _createTextVNode(" Banner Ad ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { class: "!text-[10px] !text-gray-500 text-center" }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode(" Promote your brand ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ])
  ]))
}