import { $api } from '../../services'
import {
  AuthResponse,
  MutationPersonalizeAccountArgs,
  MutationResendPhoneOtpArgs,
  MutationResendVerifyEmailArgs,
  MutationSaveAuthPhoneNumberArgs,
  MutationSaveUserVerificationArgs,
  MutationSendResetPasswordEmailArgs,
  MutationSignInArgs,
  MutationSignUpArgs,
  MutationUpdatePasswordArgs,
  MutationVerifyEmailOtpArgs,
  MutationVerifyPhoneOtpArgs,
  StorePermissions,
  User,
} from '../../gql/graphql'
import { CombinedError } from 'urql'
import Common from './Common'
import { Logic } from '..'
import { reactive } from 'vue'
import { NativeBiometric } from 'capacitor-native-biometric'
import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

export default class Auth extends Common {
  constructor() {
    super()
    this.Storage = new Storage({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    })
    this.AuthUser = localStorage.getItem('auth_user')
      ? JSON.parse(localStorage.getItem('auth_user') || '{}')
      : undefined

    this.AccessToken = localStorage.getItem('access_token')

    this.Storage.create().then(() => {
      this.Storage.defineDriver(CordovaSQLiteDriver).then(async () => {
        this.EarningStats = (await this.Storage.get('earnings_stats'))
          ? JSON.parse((await this.Storage.get('earnings_stats')) || '{}')
          : undefined
      })
    })
    this.RequestUuid = this.makeid(16)
  }

  // Default variables
  public RequestUuid: string = ''
  public CheckStatusVerification = reactive({
    active: false,
  })
  public AccessToken: string | null = null
  public AuthUser: User | undefined = undefined
  public SingleUser: User | undefined = undefined
  public LoadingStatus = reactive({
    message: 'Setting up your account...',
    status: '',
  })
  public EarningStats: any | undefined
  public PointAuthSubscriptionActive = false
  public Storage: Storage

  // Mutation input variables
  public SignUpForm: MutationSignUpArgs | undefined
  public SignInForm: MutationSignInArgs | undefined
  public VerifyPhoneOTPForm: MutationVerifyPhoneOtpArgs | undefined
  public VerifyEmailOTPForm: MutationVerifyEmailOtpArgs | undefined
  public ResendPhoneOTPForm: MutationResendPhoneOtpArgs | undefined
  public ResendVerifyEmailForm: MutationResendVerifyEmailArgs | undefined
  public PersonalizeAccountForm: MutationPersonalizeAccountArgs | undefined
  public SendResetPasswordEmailForm:
    | MutationSendResetPasswordEmailArgs
    | undefined
  public UpdatePasswordForm: MutationUpdatePasswordArgs | undefined
  public SaveUserVerificationForm: MutationSaveUserVerificationArgs | undefined
  public SaveAuthPhoneNumberForm: MutationSaveAuthPhoneNumberArgs | undefined

  private SetUpAuth = async (AuthResponse: AuthResponse | undefined) => {
    if (AuthResponse) {
      this.AccessToken = AuthResponse.token
      if (this.AuthUser == undefined) {
        this.AuthUser = AuthResponse.user
      }
      // save to this.Storage
      localStorage.setItem('access_token', this.AccessToken)
      localStorage.setItem('auth_user', JSON.stringify(this.AuthUser))
    }
  }

  public saveAuthUser = () => {
    localStorage.setItem('auth_user', JSON.stringify(this.AuthUser))
  }

  public biometryIsAvailable = () => {
    const result = NativeBiometric.isAvailable().then((response) => {
      return response
    })
    return result
  }

  public performBiometricVerification = async (
    title = 'Continue with biometric',
  ) => {
    const result = await this.biometryIsAvailable()

    if (!result?.isAvailable) return

    // const isFaceID = result.biometryType == BiometryType.FACE_ID;

    const verified = await NativeBiometric.verifyIdentity({
      reason: 'For easy authentication',
      title,
      subtitle: 'Please authenticate',
      description: '',
    })
      .then(() => true)
      .catch(() => false)

    return verified
  }

  public RedirectAuth = (user: User | undefined) => {
    if (!user?.profile) {
      Logic.Common.hideLoader()
      Logic.Common.GoToRoute('/auth/personalize')
      return true
    }

    if (!user.phone_verified_at) {
      Logic.Common.hideLoader()
      Logic.Auth.ResendPhoneOTPForm = {
        phone_number: user.phone || '',
        user_uuid: user.uuid,
      }
      Logic.Auth.ResendPhoneOTP()?.then((data) => {
        if (data) {
          Logic.Common.GoToRoute('/auth/verify-phone')
          return true
        }
      })
      return true
    }

    if (
      !user.email ||
      !user.email?.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      Logic.Common.hideLoader()
      Logic.Common.GoToRoute('/auth/personalize')
      return true
    }

    if (!user.profile?.pin_is_set) {
      Logic.Common.hideLoader()
      Logic.Common.GoToRoute('/auth/transaction-pin')
      return true
    }

    return false
  }

  public CheckUserVerification = (email: string) => {
    if (email) {
      this.CheckStatusVerification.active = true
      $api.auth
        .CheckUserVerification(email)
        .then((response) => {
          if (response.data?.CheckUserVerification) {
            this.CheckStatusVerification.active = false

            Logic.Common.showModal({ show: false })

            if (response.data.CheckUserVerification.phone_verified_at) {
              Logic.Common.GoToRoute('/auth/onboarding')
            } else {
              Logic.Common.GoToRoute('/auth/set-password')
            }
          } else {
            if (this.CheckStatusVerification.active) {
              setTimeout(() => {
                this.CheckUserVerification(email)
              }, 2000)
            }
          }
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public GetCurrentBusiness = () => {
    return Logic.Auth.AuthUser?.profile?.business
      ? Logic.Auth.AuthUser?.profile?.business
      : Logic.Auth.AuthUser?.business_admin?.business
  }

  public CurrentBusinessIsSuperAdmin = () => {
    return Logic.Auth.AuthUser?.profile?.business != undefined
  }

  public BusinessUserCan = (permission: keyof StorePermissions) => {
    if (this.AuthUser?.profile?.business) {
      return true
    }

    return this.AuthUser?.business_admin?.permissions[permission]
  }

  // Query actions
  public GetAuthUser = () => {
    return $api.auth.GetAuthUser().then(async (response) => {
      if (response.data?.GetAuthUser) {
        // FirebaseCrashlytics.setUserId({
        //   userId: response.data?.AuthUser.uuid || '',
        // }).then(() => {})

        this.AuthUser = response.data?.GetAuthUser
        this.SingleUser = response.data.GetAuthUser
        localStorage.setItem('auth_user', JSON.stringify(this.AuthUser))
        if (this.AuthUser.profile?.pin) {
          localStorage.setItem('trans_pin', this.AuthUser.profile?.pin)
        }

        // register subscriptions
        if (this.AuthUser) {
          // save stats
          this.EarningStats = JSON.parse(response.data.GetEarningsStatistics)
          await this.Storage.set(
            'earnings_stats',
            response.data.GetEarningsStatistics,
          )

          Logic.Common.initiateWebSocket()

          if (Logic.Auth.currentAccountType() == 'client') {
            Logic.Wallet.GetUserExchangeRates()
            Logic.User.GetUnreadNotificationsCount()
            Logic.Game.GetAuthUserGameProfile()
            Logic.Shoplist.GetUserProductCart()
          }

          Logic.Shoplist.GetParentCategories()

          if (Logic.Common.currentAccountType() == 'client') {
            Logic.User.GetUnClaimedPointEarnedCount()
          }
        }

        return response.data
      }
    })
  }

  public localSetupIsPending = () => {
    return !localStorage.getItem('auth_user')
  }

  public InitiateAccountSetup = async () => {
    const allActionPromises: any[] = []

    try {
      // user auth data
      allActionPromises.push(async () => {
        await this.GetAuthUser()

        this.LoadingStatus = {
          message: 'Sailing through cyberspace to fetch your rewards ⛵🚀',
          status: 'success',
        }
      })

      // trending products
      allActionPromises.push(async () => {
        await Logic.Shop.GetTrendingProducts()

        this.LoadingStatus = {
          message: 'Loading smiles and rewards... almost there! 😄🎉',
          status: 'success',
        }
      })

      // current products
      allActionPromises.push(async () => {
        await Logic.Shop.GetMerchantByCategories()

        this.LoadingStatus = {
          message: 'Loading good vibes and great deals... patience, Jedi! 🌟⌛',
          status: 'success',
        }

        Logic.Notification.addListeners().then(() => {
          Logic.Notification.registerNotifications()
            .then(() => {
              // registration completed
            })
            .catch(() => {})
        })
      })

      // Active challenges
      allActionPromises.push(async () => {
        await Logic.Game.GetChallenges()

        this.LoadingStatus = {
          message: 'Paging unicorns to deliver your enchanted bonuses 🦄🪄',
          status: 'success',
        }
      })

      for (const promiseAction of allActionPromises) {
        await promiseAction()
      }

      this.LoadingStatus = {
        message: 'Done!',
        status: 'completed',
      }
    } catch (error) {
      this.LoadingStatus = {
        message:
          'Unable to complete setup, please ensure your personal information is correct.',
        status: 'failed',
      }

      Logic.User.SaveUserActivity(`Failed - Account Setup`, 'failed_action', {
        reason: error,
      })

      console.log(error)
    }
  }

  //  Mutation actions
  public SignUp = (formIsValid: boolean) => {
    if (formIsValid && this.SignUpForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .SignUp(this.SignUpForm)
        .then(async (response) => {
          this.AuthUser = response.data?.SignUp
          localStorage.setItem('auth_user', JSON.stringify(this.AuthUser))
          Logic.Common.hideLoader()
          localStorage.setItem('acc_email', this.SignUpForm?.email || '')
          Logic.User.SaveUserActivity('Sign Up', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(`Failed - Sign Up`, 'failed_action', {
            reason: error.graphQLErrors[0].message,
          })
        })
    }
  }

  public SaveUserVerification = () => {
    if (this.SaveUserVerificationForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .SaveUserVerification(this.SaveUserVerificationForm)
        .then((response) => {
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Save User Verification - `,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public SaveAuthPhoneNumber = () => {
    if (this.SaveAuthPhoneNumberForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .SaveAuthPhoneNumber(this.SaveAuthPhoneNumberForm)
        .then((response) => {
          Logic.Common.hideLoader()
          this.GetAuthUser()
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Save Phone Number`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public SignIn = (formIsValid: boolean, background = false) => {
    if (formIsValid && this.SignInForm) {
      if (!background) {
        Logic.Common.showLoader({
          loading: true,
        })
      }
      return $api.auth
        .SignIn(this.SignInForm)
        .then(async (response) => {
          if (response.data?.SignIn) {
            this.SetUpAuth(response.data.SignIn)
          }
          if (!background) {
            localStorage.setItem(
              'acc_password',
              this.SignInForm?.password || '',
            )
            localStorage.setItem('acc_email', this.SignInForm?.email || '')
            await this.GetAuthUser()
            return response.data
          }

          Logic.User.SaveUserActivity('Sign In', 'action')

          await this.GetAuthUser()

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')

          Logic.User.SaveUserActivity(`Failed - Sign In`, 'failed_action', {
            reason: error.graphQLErrors[0].message,
          })
        })
    }
  }

  public VerifyPhoneOTP = (formIsValid: boolean) => {
    if (formIsValid && this.VerifyPhoneOTPForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .VerifyPhoneOTP(this.VerifyPhoneOTPForm)
        .then((response) => {
          this.AuthUser = this.updatedData(
            this.AuthUser,
            response.data?.VerifyPhoneOTP,
          )
          Logic.Common.hideLoader()

          Logic.User.SaveUserActivity('Verify Phone OTP', 'action')

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Verify Phone OTP`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public VerifyEmailOTP = (formIsValid: boolean) => {
    if (formIsValid && this.VerifyEmailOTPForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .VerifyEmailOTP(this.VerifyEmailOTPForm)
        .then((response) => {
          this.AuthUser = this.updatedData(
            this.AuthUser,
            response.data?.VerifyEmailOTP,
          )

          localStorage.setItem(
            'reset_acc_uuid',
            response.data?.VerifyEmailOTP?.uuid || '',
          )

          Logic.User.SaveUserActivity('Verify Email OTP', 'action')

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Verify Email OTP`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public ResendPhoneOTP = (silent = false) => {
    if (this.ResendPhoneOTPForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .ResendPhoneOTP(this.ResendPhoneOTPForm)
        .then((response) => {
          if (!silent) {
            Logic.Common.showSuccess('Phone verification OTP resent')
          } else {
            Logic.Common.hideLoader()
          }

          Logic.User.SaveUserActivity('Resend Phone OTP', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Resend Phone OTP`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public ResendVerifyEmail = () => {
    if (this.ResendVerifyEmailForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .ResendVerifyEmail(this.ResendVerifyEmailForm)
        .then((response) => {
          Logic.Common.showSuccess('Email verification OTP resent')
          Logic.User.SaveUserActivity('Resend Email OTP', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Resend Email OTP`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public PersonalizeAccount = (formIsValid: boolean) => {
    if (formIsValid && this.PersonalizeAccountForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .PersonalizeAccount(this.PersonalizeAccountForm)
        .then(async (response) => {
          this.SetUpAuth(response.data?.PersonalizeAccount)
          this.AuthUser = this.updatedData(
            this.AuthUser,
            response.data?.PersonalizeAccount.user,
          )
          await localStorage.setItem(
            'acc_email',
            this.PersonalizeAccountForm?.email || '',
          )

          Logic.User.SaveUserActivity('Personalize Account', 'action')

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Personalize Account`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public SendResetPasswordEmail = (formIsValid: boolean, isSlient = true) => {
    if (formIsValid && this.SendResetPasswordEmailForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .SendResetPasswordEmail(this.SendResetPasswordEmailForm)
        .then((response) => {
          Logic.Common.hideLoader()
          if (!isSlient) {
            Logic.Common.showSuccess('Reset Password OTP resent')
          }

          Logic.User.SaveUserActivity('Send Reset Password Email', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Send Reset Password Email`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UpdatePassword = (formIsValid: boolean, isReset = false) => {
    if (formIsValid && this.UpdatePasswordForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.auth
        .UpdatePassword(this.UpdatePasswordForm)
        .then((response) => {
          Logic.Common.hideLoader()
          localStorage.setItem(
            'acc_password',
            this.UpdatePasswordForm?.password || '',
          )
          if (isReset) {
            Logic.Common.showSuccess(
              'Your password has been reset',
              () => {
                Logic.Common.showModal({ show: false })
                Logic.Common.GoToRoute('/auth/login')
              },
              'Continue to login',
            )
          }

          Logic.User.SaveUserActivity('Update Password', 'action')
          return response.data?.UpdatePassword
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Update Password`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public CheckIfUsernameExist = (username: string) => {
    return $api.auth.CheckIfUsernameExist(username)
  }

  public SignOut = () => {
    Logic.Common.showLoader({
      loading: true,
    })
    $api.auth
      .SignOut()
      .then(async (_response) => {
        Logic.Common.showLoader({
          loading: false,
        })

        Logic.User.SaveUserActivity('Logout', 'action')

        // clear this.Storage
        await this.Storage.clear()
        localStorage.clear()

        // save activity
        // Logic.User.SaveUserActivity('Logout', 'action')

        // reload window
        if (Logic.Common.currentBuildType() == 'web') {
          window.location.href = '/auth/login'
        } else {
          window.location.href = '/start'
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(`Failed - Logout`, 'failed_action', {
          reason: error.graphQLErrors[0].message,
        })
      })
  }

  // Subscription
  public GlobalAuthUpdate = () => {
    $api.auth.GlobalAuthUpdate(
      (result: {
        GlobalAuthUpdate: {
          uuid: string
        }
      }) => {
        if (result.GlobalAuthUpdate) {
          this.GetAuthUser()
          Logic.User.SaveUserActivity('Global User State Updated', 'action')
        }
      },
      () => {
        this.PointAuthSubscriptionActive = true
      },
    )
  }
}
