import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_5 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-end"
}
const _hoisted_10 = { class: "w-full flex flex-row space-x-1 justify-end flex-grow" }
const _hoisted_11 = { class: "font-semibold text-primary-500 dark:!text-primary-200 px-1" }
const _hoisted_12 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Buy Cable' }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_button, { class: "!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: `beneficiary${
                _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`,
                  "custom-class": "!h-[12px]"
                }, null, 8, ["name"]),
                _createVNode(_component_app_normal_text, { class: "!text-primary-500 dark:!text-primary-100" }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Beneficiaries ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-3"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_app_normal_text, { class: "!text-[12px] text-left px-4 dark:!text-white" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Select Billers ")
                    ])),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allBillers, (biller, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex items-center justify-center",
                          key: index
                        }, [
                          _createElementVNode("div", {
                            class: "w-[56px] h-[56px] rounded-full relative cursor-pointer",
                            onClick: ($event: any) => (_ctx.selectedBiller = biller.id)
                          }, [
                            _createVNode(_component_app_image_loader, {
                              "photo-url": biller.logo,
                              class: _normalizeClass(`h-[56px] w-[56px] dark:border-[1px] dark:border-gray-50 ${
                        _ctx.selectedBiller == biller.id
                          ? 'border-[2px] border-primary-400 rounded-full'
                          : ' rounded-full'
                      } `)
                            }, null, 8, ["photo-url", "class"]),
                            (_ctx.selectedBiller == biller.id)
                              ? (_openBlock(), _createBlock(_component_app_icon, {
                                  key: 0,
                                  name: 'mark',
                                  customClass: 'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '
                                }))
                              : _createCommentVNode("", true)
                          ], 8, _hoisted_6)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _cache[5] || (_cache[5] = _createElementVNode("div", { class: "w-full flex flex-col px-4 pt-3" }, [
                    _createElementVNode("div", { class: "w-full border-t-[1px] border-grey-50 dark:!border-grey-500" })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_select, {
                    paddings: 'py-3 px-4',
                    hasTitle: true,
                    placeholder: 'Select payment plan',
                    options: _ctx.paymentOptions,
                    updateKey: _ctx.selectedPaymentItem,
                    "custom-class": "dark:!bg-grey-900",
                    modelValue: _ctx.selectedPaymentItem,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPaymentItem) = $event)),
                    onOnOptionSelected: _ctx.handlePaymentItemSelected,
                    theme: _ctx.Logic.Common.currentAppTheme()
                  }, {
                    title: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Payment item ")
                    ])),
                    _: 1
                  }, 8, ["options", "updateKey", "modelValue", "onOnOptionSelected", "theme"]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_app_text_field, {
                      placeholder: 'Enter smartcard number',
                      hasTitle: true,
                      type: 'text',
                      rules: [_ctx.FormValidations.RequiredRule],
                      name: "Smartcard number",
                      modelValue: _ctx.smartcardNumber,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.smartcardNumber) = $event)),
                      "update-value": _ctx.smartcardNumber
                    }, {
                      title: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode(" Smartcard number ")
                      ])),
                      _: 1
                    }, 8, ["rules", "modelValue", "update-value"]),
                    (_ctx.smartcardNumber.length >= 7)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(
                    _ctx.isResolvingNumber
                      ? '!text-secondary-main dark:!text-secondary-100'
                      : _ctx.resolveIsError
                      ? '!text-red-500 dark:!text-red-300'
                      : '!text-primary-500 dark:!text-primary-300'
                  )
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.isResolvingNumber
                      ? "Validating number..."
                      : _ctx.resolveIsError
                      ? "Unable to resolve meter number"
                      : _ctx.cableTVBiller), 1)
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_app_checkbox, {
                      modelValue: _ctx.saveAsBeneficiary,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.saveAsBeneficiary) = $event)),
                      standard: true,
                      class: "w-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_normal_text, { class: "dark:!text-white" }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Save as beneficiary ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["modelValue"])
                  ]),
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    "custom-class": '',
                    disabled: true,
                    "update-value": _ctx.amountValue
                  }, {
                    title: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "dark:!text-gray-200" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" ₦ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["update-value"]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_app_icon, {
                      name: 'cashback-icon',
                      customClass: 'h-[20px]'
                    }),
                    _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap dark:!text-white' }, {
                      default: _withCtx(() => [
                        _cache[11] || (_cache[11] = _createTextVNode(" You'll get ")),
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.getProductPoint(
                        "cable_tv",
                        _ctx.amountValue?.replace(/,/g, "")
                      ),
                      false,
                      ""
                    )) + " Points", 1)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createVNode(_component_fixed_container, { class: "pt-4" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_AppButton, {
                        padding: 'py-3',
                        "bg-color": 'bg-primary-400',
                        "text-color": 'text-white',
                        class: _normalizeClass(_ctx.formIsValid ? 'w-full' : 'opacity-50'),
                        onClick: _ctx.purchaseCableTv
                      }, {
                        default: _withCtx(() => _cache[12] || (_cache[12] = [
                          _createTextVNode(" Continue ")
                        ])),
                        _: 1
                      }, 8, ["class", "onClick"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["parentRefs"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}