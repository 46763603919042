<template>
  <app-wrapper>
    <subpage-layout :title="'Send Money'">
      <div class="w-full flex flex-col space-y-4 px-4">
        <!-- Top user card section -->
        <div
          class="w-full flex flex-col py-4 px-4 rounded-[10px] bg-grey-50 dark:bg-gray-900 justify-center items-center space-y-1"
        >
          <app-image-loader
            :photoUrl="
              accountData.photo_url
                ? accountData.photo_url
                : '/images/icons/user-white-bg.svg'
            "
            class="w-[64px] h-[64px] rounded-full"
          />
          <app-normal-text :class="'!font-semibold !text-sm pt-2'">
            To: {{ accountData.account_name }}
          </app-normal-text>
          <app-normal-text :class="'text-grey-main'">
            @{{ accountData.account_number }}
          </app-normal-text>
        </div>

        <!-- Save beneficiary -->
        <app-checkbox v-model="saveBeneficary" :standard="true">
          Save as beneficiary
        </app-checkbox>

        <!-- Amount and narration -->
        <div class="w-full flex flex-col space-y-2">
          <!-- Amount field -->
          <app-text-field
            v-if="showAmount"
            :placeholder="'Enter amount'"
            :hasTitle="true"
            :type="'tel'"
            :isFormatted="true"
            :rules="[FormValidations.RequiredRule]"
            ref="amount"
            name="amount"
            v-model="accountData.amount"
            required
          >
            <template v-slot:title> Amount </template>
            <template v-slot:inner-prefix>
              <app-normal-text class="!text-grey-700 !font-semibold">
                NGN
              </app-normal-text>
            </template>
          </app-text-field>

          <!-- Note -->
          <app-text-field
            :placeholder="'Add comment'"
            :hasTitle="true"
            :type="'text'"
            :rules="[FormValidations.RequiredRule]"
            ref="note"
            name="Additional Notes"
            :update-value="accountData.narration"
            v-model="accountData.narration"
            :is-textarea="true"
          >
            <template v-slot:title> Additional Notes </template>
          </app-text-field>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container>
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :text-color="'text-white'"
            class="w-full"
            @click="makeTransfer"
          >
            Continue
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidLeave, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { FormValidations } from "@shpt/ui-components/src/composable";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import {
  AppImageLoader,
  AppCheckbox,
  AppNormalText,
  AppTextField,
  AppButton,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppImageLoader,
    AppCheckbox,
    AppNormalText,
    AppTextField,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "InitiatePaymentPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Initiate Payment Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Initiate Payment",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const saveBeneficary = ref(false);

    const showAmount = ref(false);

    const accountData = reactive({
      account_number: "",
      account_name: "",
      bank_name: "",
      narration: "",
      amount: "",
      bank_code: "",
      photo_url: "",
      user_uuid: "",
      session_id: "",
      is_merchant: false,
    });

    const setAccountData = () => {
      accountData.amount = "";
      accountData.account_name =
        Logic.Common.route?.query?.account_name?.toString() || "";
      accountData.account_number =
        Logic.Common.route?.query?.account_number?.toString() || "";

      accountData.bank_name =
        Logic.Common.route?.query?.bank_name?.toString() || "";
      accountData.bank_code =
        Logic.Common.route?.query?.bank_code?.toString() || "";

      accountData.user_uuid =
        Logic.Common.route?.query?.user_uuid?.toString() || "";
      accountData.photo_url =
        Logic.Common.route?.query?.photo_url?.toString() || "";
      accountData.is_merchant =
        Logic.Common.route?.query?.is_merchant?.toString() === "true"
          ? true
          : false;
      accountData.amount = Logic.Common.route?.query?.amount?.toString() || "";
      setTimeout(() => {
        showAmount.value = true;
      }, 300);
    };

    onIonViewDidLeave(() => {
      accountData.amount = "";
      accountData.account_name = "";
      accountData.bank_name = "";
      accountData.bank_code = "";
      accountData.user_uuid = "";
      accountData.photo_url = "";
      accountData.narration = "";
    });

    onIonViewWillEnter(() => {
      setAccountData();
      accountData.narration = "";
    });

    onMounted(() => {
      setAccountData();
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      accountData.narration = "";
    });

    const makeTransfer = () => {
      const amount = parseFloat(accountData.amount?.replace(/,/g, ""));

      if (amount >= 50 && accountData.account_number) {
        let isBankTransfer = false;
        if (accountData.bank_code) {
          Logic.Wallet.InitiateTransferToBankForm = {
            account_type: "client",
            amount: amount.toString(),
            beneficiary_account_no: accountData.account_number,
            beneficiary_bank_code: accountData.bank_code,
            beneficiary_name: accountData.account_name,
            narration: accountData.narration
              ? accountData.narration
              : "Outward transfer from Shoppoint rewards",
            use_point: false,
            card_uuid: undefined,
            save_beneficiary: saveBeneficary.value,
            name_enquiry_session_id: accountData.session_id,
          };
          isBankTransfer = true;
        } else {
          Logic.Wallet.InitiateTransferToShoppointUserForm = {
            account_type: "client",
            amount: amount.toString(),
            receiver_user_uuid: accountData.user_uuid,
            use_point: true,
            card_uuid: undefined,
            save_beneficiary: saveBeneficary.value,
            narration: accountData.narration,
            is_business: accountData.is_merchant,
          };
        }

        Logic.Common.showModal({
          show: true,
          type: "payment",
          title: "Review Payment",
          extraData: {
            payment_tag: "Amount",
            amount: amount,
            type: isBankTransfer ? "bank_transfer" : "",
            from_bank_payment: isBankTransfer,
          },
          action: (data: any) => {
            const resultHandler = () => {
              //
            };
            if (accountData.bank_code) {
              if (Logic.Wallet.InitiateTransferToBankForm) {
                if (data.method != "wallet") {
                  Logic.Wallet.InitiateTransferToBankForm.card_uuid =
                    data.method;
                }
                Logic.Wallet.InitiateTransferToBankForm.use_point =
                  data.use_point;
              }

              Logic.Wallet.InitiateTransferToBank()?.then((data) => {
                if (data) {
                  resultHandler();
                }
              });
            } else {
              if (Logic.Wallet.InitiateTransferToShoppointUserForm) {
                Logic.Wallet.InitiateTransferToShoppointUserForm.use_point =
                  true;
              }
              Logic.Wallet.InitiateTransferToShoppointUser()?.then((data) => {
                if (data) {
                  resultHandler();
                }
              });
            }
          },
        });
      } else {
        Logic.Common.showAlert({
          show: true,
          message: `Amount must be up ${Logic.Common.convertToMoney(
            50,
            false,
            "ngn"
          )} and above`,
          type: "error",
        });
      }
    };

    return {
      accountData,
      AuthUser,
      Logic,
      FormValidations,
      saveBeneficary,
      loaderSetup,
      showAmount,
      makeTransfer,
    };
  },
});
</script>
