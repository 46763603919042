import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between p-4 h-full" }
const _hoisted_2 = { class: "flex flex-col space-y-4" }
const _hoisted_3 = { class: "flex justify-between items-center" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_5 = { class: "flex items-center space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeaderText = _resolveComponent("AppHeaderText")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AppHeaderText, null, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("More")
          ])),
          _: 1
        }),
        _renderSlot(_ctx.$slots, "close")
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full h-[1px] bg-grey-50" }, null, -1)),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sideBarRoutes, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex justify-between pr-2 items-center py-2",
            key: index
          }, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_AppIcon, {
                "custom-class": `${item.icon.size}`,
                name: `${item.icon.name}`
              }, null, 8, ["custom-class", "name"]),
              _createVNode(_component_AppNormalText, { color: 'text-base-black' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _createVNode(_component_AppIcon, {
              "custom-class": '',
              name: 'arrow-right-sidebar'
            })
          ]))
        }), 128))
      ])
    ]),
    _createVNode(_component_AppButton, {
      paddings: 'py-3',
      "bg-color": 'bg-red-50',
      "text-color": 'text-red-500'
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode(" Logout ")
      ])),
      _: 1
    })
  ]))
}