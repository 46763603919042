<template>
  <subpage-layout :title="'Support'" mainSectionClass="!h-fit">
    <div class="w-full flex flex-col space-y-4 h-full flex-grow px-4">
      <div class="w-full flex flex-col items-start space-y-2">
        <app-header-text :customClass="'text-left !text-base'">
          Email Us
        </app-header-text>
        <app-nav-card
          :data="item"
          v-for="(item, index) in emailUs"
          @click="item.action()"
          :key="index"
          :hasMasterLink="false"
        />
      </div>

      <div class="w-full flex flex-col items-start space-y-3">
        <app-header-text :customClass="'text-left !text-base'">
          Social media
        </app-header-text>
        <app-nav-card
          :data="item"
          v-for="(item, index) in socialMedia"
          :key="index"
          @click="item.action()"
          :hasMasterLink="false"
        />
      </div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { AppNavCard, AppHeaderText } from "../../../../components";
import { Logic } from "../../../../index";
import SubpageLayout from "../../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppNavCard,
    AppHeaderText,
    SubpageLayout,
  },
  name: "SupportPage",
  setup() {
    const callUs = reactive([
      {
        title: "08131138907",
        icon: "call-us",
        icon_size: "h-[25px]",
      },
      {
        title: "08131138907",
        icon: "call-us",
        icon_size: "h-[25px]",
      },
      {
        title: "08131138907",
        icon: "call-us",
        icon_size: "h-[25px]",
      },
    ]);

    const emailUs = reactive([
      {
        title: "hello@getshoppoint.com",
        icon: "email-us",
        icon_size: "h-[16px]",
        action: () => {
          window.location.href = "mailto:hello@getshoppoint.com";
        },
      },
    ]);

    const socialMedia = reactive([
      {
        title: "@getshoppoint",
        icon: "twitter",
        icon_size: "h-[18px]",
        action: () => {
          window.open("https://x.com/getshoppoint", "_blank");
        },
      },
      {
        title: "getshoppoint",
        icon: "linkedin",
        icon_size: "h-[18px]",
        action: () => {
          window.open("https://www.linkedin.com/company/getshoppoint/about/", "_blank");
        },
      },
      {
        title: "getshoppoint",
        icon: "instagram-purple",
        icon_size: "h-[18px]",
        action: () => {
          window.open("https://www.instagram.com/getshoppoint/", "_blank");
        },
      },
      {
        title: "getshoppoint",
        icon: "facebook-purple",
        icon_size: "h-[18px]",
        action: () => {
          window.open("https://web.facebook.com/getshoppoint", "_blank");
        },
      },
    ]);

    return {
      callUs,
      Logic,
      emailUs,
      socialMedia,
    };
  },
});
</script>
