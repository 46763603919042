import { Logic } from '..'
import { $api } from '../../services'
import { MutationSaveUserPushNotificationDeviceArgs } from './../../gql/graphql'
import Common from './Common'
import { PushNotifications } from '@capacitor/push-notifications'
import { getPlatforms } from '@ionic/vue'

export default class Notification extends Common {
  constructor() {
    super()
  }

  public UnreadNotification = 0

  public Notifications: Notification[] | undefined = undefined

  public PushNotificationDeviceForm: MutationSaveUserPushNotificationDeviceArgs = {
    device_token: '',
    device_type: '',
  }

  public reset = () => {
    this.Notifications = undefined
  }

  public SavePushNotificationDevice = () => {
    $api.user
      .SavePushNotificationDevice(this.PushNotificationDeviceForm)
      .then(() => {
        // do something if you want :)
      })
  }

  public MarkNotificationAsRead = (uuid: string) => {
    $api.user.MarkNotificationAsRead(uuid).then(() => {
      Logic.User.GetNotifications(1, 200)
    })
  }

  public addListeners = async () => {
    await PushNotifications.removeAllListeners()

    await PushNotifications.addListener('registration', (token) => {
      this.PushNotificationDeviceForm.device_token = token.value
      this.PushNotificationDeviceForm.device_type = getPlatforms()[0]
      this.SavePushNotificationDevice()
    })

    await PushNotifications.addListener('registrationError', (_err) => {
      // handle error here
    })

    await PushNotifications.addListener('pushNotificationReceived', () => {
      // handle push notification
      this.UnreadNotification++
      Logic.User.SaveUserActivity('Push Notification Received', 'action')
    })

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification) => {
        // handle notification click

        const uuid = notification.notification.data.uuid

        this.MarkNotificationAsRead(uuid)

        Logic.User.SaveUserActivity('Push Notification Clicked', 'action')
      },
    )
  }

  public registerNotifications = async () => {
    // set unread notification container

    if (localStorage.getItem('unread_notification') == null) {
      localStorage.setItem('unread_notification', '0')
    }

    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      console.error('User denied permissions!')
    }

    await PushNotifications.register()
  }

  public getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications()
    console.log('delivered notifications', notificationList)
  }
}
