<template>
  <div class="countdown" v-if="timerArray.length">
    <div class="bloc-time hours" :data-init-value="timer.hours">
      <div class="figure hours hours-1">
        <span class="top" :id="`${uniqueId}-top-00`">{{ timerArray[0][0] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-00`">
          <span>{{ timerArray[0][0] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-00`">{{ timerArray[0][0] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-00`">
          <span>{{ timerArray[0][0] }}</span>
        </span>
      </div>

      <div class="figure hours hours-2 mb-2">
        <span class="top" :id="`${uniqueId}-top-01`">{{ timerArray[0][1] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-01`">
          <span>{{ timerArray[0][1] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-01`">{{ timerArray[0][1] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-01`">
          <span>{{ timerArray[0][1] }}</span>
        </span>
      </div>

      <app-normal-text :customClass="`count-title !text-[11px]`">Hours</app-normal-text>
    </div>

    <div class="bloc-time min" :data-init-value="timer.minutes">
      <div class="figure min min-1">
        <span class="top" :id="`${uniqueId}-top-10`">{{ timerArray[1][0] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-10`">
          <span>{{ timerArray[1][0] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-10`">{{ timerArray[1][0] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-10`">
          <span>{{ timerArray[1][0] }}</span>
        </span>
      </div>

      <div class="figure min min-2 mb-2">
        <span class="top" :id="`${uniqueId}-top-11`">{{ timerArray[1][1] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-11`">
          <span>{{ timerArray[1][1] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-11`">{{ timerArray[1][1] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-11`">
          <span>{{ timerArray[1][1] }}</span>
        </span>
      </div>

      <app-normal-text :customClass="`count-title !text-[11px]`">Minutes</app-normal-text>
    </div>

    <div class="bloc-time sec" :data-init-value="timer.seconds">
      <div class="figure sec sec-1">
        <span class="top" :id="`${uniqueId}-top-20`">{{ timerArray[2][0] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-20`">
          <span>{{ timerArray[2][0] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-20`">{{ timerArray[2][0] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-20`">
          <span>{{ timerArray[2][0] }}</span>
        </span>
      </div>

      <div class="figure sec sec-2 mb-2">
        <span class="top" :id="`${uniqueId}-top-21`">{{ timerArray[2][1] }}</span>
        <span class="top-back" :id="`${uniqueId}-top-back-21`">
          <span>{{ timerArray[2][1] }}</span>
        </span>
        <span class="bottom" :id="`${uniqueId}-bottom-21`">{{ timerArray[2][1] }}</span>
        <span class="bottom-back" :id="`${uniqueId}-bottom-back-21`">
          <span>{{ timerArray[2][1] }}</span>
        </span>
      </div>

      <app-normal-text :customClass="`count-title !text-[11px]`">Seconds</app-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { watch, defineComponent, onMounted, reactive, ref, toRef } from "vue";
import { AppNormalText } from "../AppTypography";
import gsap, { Quart } from "gsap";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
  },
  props: {
    secondsTarget: {
      type: Number,
      default: 60,
    },
  },
  name: "AppCountdown",
  setup(props) {
    const targetTime = ref(new Date().getTime() + 3600000 * 2);

    const uniqueId = Math.random() * 100000;

    const secondsTargetRef = toRef(props, "secondsTarget");

    const timer = reactive({
      hours: "24",
      minutes: "00",
      seconds: "00",
    });

    const timerArray = ref<string[][]>([
      ["0", "0"],
      ["0", "0"],
      ["0", "0"],
    ]);
    const oldTimerValue = ref<string[][]>([
      ["0", "0"],
      ["0", "0"],
      ["0", "0"],
    ]);

    const remainingTime = () => {
      const now = new Date().getTime();
      const timeDifference = targetTime.value - now;
      return Math.max(0, timeDifference);
    };

    const animateFigure = (index: string) => {
      const top = document.getElementById(`${uniqueId}-top-${index}`);
      const bottom = document.getElementById(`${uniqueId}-bottom-${index}`);
      const back_top = document.getElementById(`${uniqueId}-top-back-${index}`);
      const back_bottom = document.getElementById(`${uniqueId}-bottom-back-${index}`);

      const indices: any = index.split("");

      const oldValue = oldTimerValue.value[indices[0]][indices[1]];
      const currentValue = timerArray.value[indices[0]][indices[1]];

      if (top && oldValue != currentValue) {
        gsap.to(top, {
          duration: 0.8,
          rotationX: "-180deg",
          transformPerspective: 300,
          ease: Quart.easeOut,
          onComplete: () => {
            gsap.set(top, {
              rotationX: 0,
            });
          },
        });
      }

      if (back_top && oldValue != currentValue) {
        gsap.to(back_top, {
          duration: 0.8,
          rotationX: 0,
          transformPerspective: 300,
          ease: Quart.easeOut,
          clearProps: "all",
        });
      }
    };

    const updateTimer = () => {
      const totalSeconds = Math.floor(remainingTime() / 1000);

      oldTimerValue.value = timerArray.value;

      timer.hours = Math.floor(totalSeconds / 3600).toString();
      timer.hours = timer.hours.length == 1 ? `0${timer.hours}` : timer.hours;
      timer.minutes = Math.floor((totalSeconds % 3600) / 60).toString();
      timer.minutes = timer.minutes.length == 1 ? `0${timer.minutes}` : timer.minutes;
      timer.seconds = (totalSeconds % 60).toString();
      timer.seconds = timer.seconds.length == 1 ? `0${timer.seconds}` : timer.seconds;

      timerArray.value = [
        timer.hours.split(""),
        timer.minutes.split(""),
        timer.seconds.split(""),
      ];

      timerArray.value.forEach((period, rootIndex) => {
        period.forEach((item, index) => {
          animateFigure(`${rootIndex}${index}`);
        });
      });
    };

    onMounted(() => {
      targetTime.value = Logic.Common.momentInstance(new Date())
        .add(props.secondsTarget, "seconds")
        .valueOf();
      setTimeout(() => {
        setInterval(updateTimer, 1000);
      }, 1000);
    });

    watch(secondsTargetRef, () => {
      targetTime.value = Logic.Common.momentInstance(new Date())
        .add(secondsTargetRef.value, "seconds")
        .valueOf();
    });

    return {
      timer,
      timerArray,
      uniqueId,
    };
  },
});
</script>
<style scoped>
.countdown {
  width: "100%";
  margin: 0 auto;
}
.countdown .bloc-time {
  float: left;
  margin-right: 12px;
  text-align: center;
}
.countdown .bloc-time:last-child {
  margin-right: 0;
}
.countdown .count-title {
  display: block;
  text-transform: uppercase;
}
.countdown .figure {
  position: relative;
  float: left;
  height: 45px;
  width: 38px;
  margin-right: 5px;
  background-color: #5a189a;
  border-radius: 5px;
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2), inset 2px 4px 0 0 rgba(255, 255, 255, 0.08);
}
.countdown .figure:last-child {
  margin-right: 0;
}
.countdown .figure > span {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  font: normal 38px "Athletics";
  font-weight: 700;
  color: #ffffff;
}
.countdown .figure .top:after,
.countdown .figure .bottom-back:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.countdown .figure .top {
  z-index: 3;
  background-color: #5a189a;
  transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
  -moz-transform: perspective(200px);
  -ms-transform: perspective(200px);
  -webkit-transform: perspective(200px);
  transform: perspective(200px);
}
.countdown .figure .bottom {
  z-index: 1;
}
.countdown .figure .bottom:before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.02);
}
.countdown .figure .bottom-back {
  z-index: 2;
  top: 0;
  height: 50%;
  overflow: hidden;
  background-color: #5a189a;
  -moz-border-radius-topleft: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -moz-border-radius-topright: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.countdown .figure .bottom-back span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.countdown .figure .top,
.countdown .figure .top-back {
  height: 50%;
  overflow: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.countdown .figure .top-back {
  z-index: 4;
  bottom: 0;
  background-color: #5a189a;
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -moz-transform: perspective(200px) rotateX(180deg);
  -ms-transform: perspective(200px) rotateX(180deg);
  -webkit-transform: perspective(200px) rotateX(180deg);
  transform: perspective(200px) rotateX(180deg);
  -moz-border-radius-bottomleft: 10px;
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.countdown .figure .top-back span {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
