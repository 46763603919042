<template>
  <app-wrapper>
    <subpage-layout :title="'Buy Data'">
      <!-- Beneficiaries -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            class="!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center"
          >
            <app-icon
              :name="`beneficiary${
                Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`"
              custom-class="!h-[12px]"
            />
            <app-normal-text class="!text-primary-500 dark:!text-primary-100">
              Beneficiaries
            </app-normal-text>
          </app-button>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-3">
        <app-form-wrapper
          :parentRefs="parentRefs"
          ref="formComp"
          class="w-full flex flex-col space-y-3"
        >
          <!-- Phone number -->
          <div class="w-full flex flex-col space-y-3 px-4">
            <app-text-field
              :placeholder="'Enter phone number'"
              :hasTitle="true"
              :type="'tel'"
              name="Phone number"
              ref="phoneNumberRef"
              :rules="[
                FormValidations.RequiredRule,
                FormValidations.customValidator(
                  phoneNumber.length == 11,
                  'Phone number must be valid'
                ),
              ]"
              :update-value="phoneNumber"
              :content-rule="{
                addAfterCount: 0,
                characterToAdd: ``,
                max: 11,
              }"
              v-model="phoneNumber"
            >
              <template v-slot:title> Phone number / Customer ID </template>
              <template
                v-slot:inner-suffix
                v-if="Logic.Common.currentBuildType() == 'mobile'"
              >
                <app-icon
                  :name="'phonebook'"
                  :customClass="'h-[18px]'"
                  @click="searchPhone()"
                />
              </template>
            </app-text-field>

            <app-checkbox v-model="saveAsBeneficiary" :standard="true">
              <app-normal-text :color="'text-shop_dark_400 dark:!text-white'">
                Save as beneficiary
              </app-normal-text>
            </app-checkbox>

            <div class="w-full border-t-[1px] border-grey-50 dark:!border-grey-500"></div>
          </div>

          <!-- Network -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text class="!text-[12px] text-left px-4 dark:!text-white">
              Select Network Provider
            </app-normal-text>

            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-row space-x-2">
                <div></div>
                <div
                  class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
                  id="dataServiceContainer"
                >
                  <div class="flex flex-row py-2 pr-4">
                    <div
                      class="flex flex-row pl-3"
                      v-for="(network, index) in allNetworks"
                      :key="index"
                      :id="`cableTv${network.id}`"
                    >
                      <div class="flex items-center justify-center">
                        <div
                          class="w-[56px] h-[56px] rounded-full relative cursor-pointer"
                          @click="selectedNetwork = network.id"
                        >
                          <app-image-loader
                            :photo-url="network.logo"
                            :class="`h-[56px] w-[56px] dark:border-gray-50 dark:border-[1px] ${
                              selectedNetwork == network.id
                                ? 'border-[2px] border-primary-400 rounded-full'
                                : ' rounded-full'
                            } `"
                          />
                          <app-icon
                            v-if="selectedNetwork == network.id"
                            :name="'mark'"
                            :customClass="'h-[16px] absolute top-0 right-1 z-20 bg-white rounded-full '"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Data Periods -->
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-2 py-2 pr-4">
              <div
                :class="` ${
                  selectedPlanFilter == section.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-700'
                } px-4 py-2 rounded-[30px] w-auto cursor-pointer`"
                v-for="(section, index) in dataDealSections"
                :key="index"
                @click="selectedPlanFilter = section.id"
              >
                <app-normal-text
                  :class="`!whitespace-nowrap !capitalize ${
                    selectedPlanFilter == section.id
                      ? 'text-white'
                      : 'text-gray-600 dark:text-gray-50'
                  }`"
                >
                  {{ section.name }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <!-- Data Items -->
          <div class="w-full flex flex-col space-y-3 px-4">
            <div
              class="w-full flex flex-row justify-between items-center border-b-[1px] border-[#E9E6ED] dark:border-gray-500 pt-1 pb-3 px-2 cursor-pointer"
              v-for="(item, index) in sectionOptions"
              :key="index"
              @click="selectedProduct = item.code"
            >
              <div class="flex flex-row space-x-3 items-center">
                <div class="w-[20px] flex items-center justify-center">
                  <app-icon
                    :name="
                      selectedProduct == item.code
                        ? `selected${
                            Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                          }`
                        : 'not-selected'
                    "
                    :customClass="'h-[20px]'"
                  />
                </div>
                <div class="flex flex-col space-y-[2px]">
                  <app-normal-text :customClass="'!font-semibold dark:!text-white'">
                    {{ item.title }}
                  </app-normal-text>
                  <span class="flex flex-row space-x-[2px] items-center">
                    <app-icon :name="'cashback-icon'" :customClass="'h-[20px]'" />
                    <app-normal-text class="!text-[#898989]">
                      {{ item.sub_title }}
                    </app-normal-text>
                  </span>
                </div>
              </div>

              <app-normal-text
                :class="'!font-semibold !text-sm xs:!text-[13px] !whitespace-nowrap dark:!text-white'"
              >
                {{ Logic.Common.convertToMoney(item.amount / 100, false, "ngn") }}
              </app-normal-text>
            </div>
            <!-- Spacer  -->
            <div class="h-[120px]"></div>
          </div>
        </app-form-wrapper>
      </div>

      <!-- Bottom section -->
      <fixed-container class="pt-4">
        <div class="w-full flex flex-col">
          <AppButton
            :padding="'py-3'"
            :bg-color="'bg-primary-400'"
            :text-color="'text-white'"
            :class="formIsValid ? 'w-full' : 'opacity-50'"
            @click="purchaseData"
          >
            Continue
          </AppButton>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppFormWrapper,
  AppTextField,
  AppNormalText,
  AppIcon,
  AppCheckbox,
  AppImageLoader,
  AppButton,
} from "@shpt/ui-components";
import {
  FormValidations,
  scrollToItem,
  scrollToSpecificItem,
} from "@shpt/ui-components/src/composable";
import { loaderSetup, pickAContact } from "@shpt/ui-components/src/composable/common";
import {
  getProductPoint,
  makePurchase,
  processProductData,
  selectedBeneficiary,
  SingleProduct,
} from "@shpt/ui-components/src/composable/shop";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

interface DataPlan {
  name: string;
  allowance: string;
  amount: number;
  validity: string;
  datacode: number;
  service_type: string;
  product_code: string;
  cost_per_point: number;
}

export default defineComponent({
  components: {
    AppFormWrapper,
    AppTextField,
    AppNormalText,
    AppIcon,
    AppCheckbox,
    AppImageLoader,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "BuyDataPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Buy Data",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Shop",
        property: "ShopData",
        subProperty: "data",
        method: "GetProductsByCategory",
        params: ["data"],
        requireAuth: false,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Buy Internet Data",
    });

    const selectedNetwork = ref("mtn");
    const selectedProduct = ref("");
    const selectedPlanFilter = ref("");
    const phoneNumberRef = ref();
    const saveAsBeneficiary = ref(true);

    const DataProductMetadata = ref<DataPlan>();

    const phoneNumber = ref("");

    const optionsOrders = [
      "1 day",
      "2 days",
      "3 days",
      "5 days",
      "1 week",
      "2 weeks",
      "3 weeks",
      "1 month",
      "2 months",
      "3 months",
      "4 months",
      "6 months",
      "1 year",
      "Others",
    ];

    const currentNetwork = ref<{
      logo: string;
      id: string;
      key: string;
      groups: string[];
      options: any;
    }>();

    const allNetworks = reactive([
      {
        logo: "/images/brands/mtn.png",
        id: "mtn",
        key: "Mtn",
        groups: [""],
        options: {},
      },
      {
        logo: "/images/brands/airtel.png",
        id: "airtel",
        key: "Airtel",
        groups: [""],
        options: {},
      },
      {
        logo: "/images/brands/glo.png",
        id: "glo",
        key: "Glo",
        groups: [""],
        options: {},
      },
      {
        logo: "/images/brands/9mobile.png",
        id: "9mobile",
        key: "9 Mobile",
        groups: [""],
        options: {},
      },
      {
        logo: "/images/brands/smile.png",
        id: "smile",
        key: "Smile",
        groups: [""],
        options: {},
      },
      {
        logo: "/images/brands/spectranet.png",
        id: "spectranet",
        key: "Spectranet",
        groups: [""],
        options: {},
      },
    ]);

    const dataDealSections = reactive<
      {
        name: string;
        id: string;
      }[]
    >([]);

    const sectionOptions = reactive<
      {
        id: string;
        title: string;
        sub_title: string;
        amount: number;
        code: string;
      }[]
    >([]);

    const formIsValid = computed(() => {
      return phoneNumber.value.length >= 10;
    });

    const setNetworkData = (groupedData: any, groupName: string, nextWorkIndex = 0) => {
      // set options for data
      // extract metadata
      const DataPlans: DataPlan[] = groupedData[groupName].map((item: any) => {
        const metadata = JSON.parse(item.metadata || "");
        metadata["product_code"] = item.code;
        metadata["product_category_slug"] = item.product_category.slug;
        metadata["cash_per_point"] = getProductPoint(
          "data",
          (parseFloat(item.price) / 100).toString()
        );
        metadata["id"] = item.id;
        return metadata;
      });

      // regroup plan by validity
      allNetworks[nextWorkIndex].options = Logic.Common.groupArrayBy(DataPlans, "id");
    };

    const getProductValidity = (name: string) => {
      let label = "Others";
      const productName = name.toLocaleLowerCase();
      if (productName.includes("24 hours")) {
        label = "1 day";
      } else if (
        productName.includes("1 day") ||
        productName.includes("1day") ||
        productName.includes("nigth")
      ) {
        label = "1 day";
      } else if (productName.includes("2 days")) {
        label = "2 days";
      } else if (productName.includes("3 days")) {
        label = "3 days";
      } else if (productName.includes("5 days")) {
        label = "5 days";
      } else if (productName.includes("180 days")) {
        label = "180 days";
      } else if (
        productName.includes("7 days") ||
        productName.includes("1 week") ||
        productName.includes("7days")
      ) {
        label = "1 week";
      } else if (
        productName.includes("13 days") ||
        productName.includes("2 week") ||
        productName.includes("13days") ||
        productName.includes("14days") ||
        productName.includes("14 days")
      ) {
        label = "2 week";
      } else if (
        productName.includes("30 days") ||
        productName.includes("1 month") ||
        productName.includes("monthly") ||
        productName.includes("30days") ||
        productName.includes("35days") ||
        productName.includes("35 days")
      ) {
        label = "1 month";
      } else if (productName.includes("60 days") || productName.includes("2 months")) {
        label = "2 months";
      } else if (
        productName.includes("90 days") ||
        productName.includes("3 months") ||
        productName.includes("100 days")
      ) {
        label = "3 months";
      } else if (productName.includes("120 days")) {
        label = "4 months";
      } else if (productName.includes("6 months")) {
        label = "6 months";
      } else if (
        productName.includes("1 year") ||
        productName.includes("365 days") ||
        productName.includes("365days")
      ) {
        label = "1 year";
      } else {
        label = "Others";
      }

      return label;
    };

    const setAllDataPlans = () => {
      const dataDeals = processProductData(Logic.Shop.ShopData.data || []);

      const productData = dataDeals.map((item) => item.data);

      const modifiedProductData = JSON.parse(
        JSON.stringify(
          productData.map((item) => {
            item.id = getProductValidity(item.name);
            return item;
          })
        )
      );

      const groupedData = Logic.Common.groupArrayBy(modifiedProductData, "merchant_name");

      // set the data plans
      allNetworks.forEach((item, index) => {
        setNetworkData(groupedData, item.id, index);
      });
    };

    const setCurrentOptions = () => {
      const currentPlans = currentNetwork.value?.options[selectedPlanFilter.value];
      sectionOptions.length = 0;
      currentPlans.forEach((item: any) => {
        sectionOptions.push({
          id: item.product_code,
          amount: item.amount,
          title: item.name,
          sub_title: `${Logic.Common.convertToMoney(
            getProductPoint("data", (parseFloat(item.amount) / 100).toString()),
            false,
            ""
          )} points`,
          code: item.product_code,
        });
      });

      selectedProduct.value = sectionOptions[0].code;
    };

    const setCurrentNetwork = () => {
      if (selectedNetwork.value) {
        currentNetwork.value = allNetworks.filter((item) => {
          return item.id == selectedNetwork.value;
        })[0];

        // set filter options
        dataDealSections.length = 0;

        optionsOrders.forEach((label) => {
          if (currentNetwork.value?.options[label]) {
            dataDealSections.push({
              id: label,
              name: label,
            });
          }
        });

        selectedPlanFilter.value = dataDealSections[0].id;

        setCurrentOptions();
      }
    };

    watch(selectedPlanFilter, () => {
      setCurrentOptions();
    });

    watch(selectedNetwork, () => {
      setCurrentNetwork();
    });

    watch(selectedProduct, () => {
      const currentPlans = currentNetwork.value?.options[selectedPlanFilter.value];
      DataProductMetadata.value = currentPlans.filter((item: any) => {
        return item.product_code == selectedProduct.value;
      })[0];
    });

    const setDataDetails = () => {
      setTimeout(() => {
        setAllDataPlans();
        setCurrentNetwork();
        // set default
        if (SingleProduct.value) {
          const metadata: any = JSON.parse(SingleProduct.value.metadata || "");
          selectedNetwork.value = metadata.service_type;

          setTimeout(() => {
            selectedProduct.value = SingleProduct.value?.code || "";
            selectedPlanFilter.value = getProductValidity(
              SingleProduct.value?.name || ""
            );
            metadata["product_code"] = SingleProduct.value?.code;
            metadata["product_category_slug"] =
              SingleProduct.value?.product_category.slug;
            metadata["cash_per_point"] = SingleProduct.value?.cash_per_point;
            DataProductMetadata.value = metadata;

            scrollToItem(`data-vendor-${metadata["service_type"]}`);
          }, 200);
        }
      }, 300);
      phoneNumber.value = "";
      if (selectedBeneficiary.value) {
        phoneNumber.value = selectedBeneficiary.value.id;
      }
    };

    const searchPhone = async () => {
      phoneNumber.value = (await pickAContact(false)) || "";
    };

    const purchaseData = () => {
      phoneNumberRef.value.checkValidation();
      const state = phoneNumberRef.value.validationStatus;
      if (phoneNumber.value && DataProductMetadata.value && state) {
        makePurchase(
          {
            code: DataProductMetadata.value?.product_code || "",
            amount: (DataProductMetadata.value.amount / 100).toString(),
            customerId: phoneNumber.value,
          },
          saveAsBeneficiary.value
        );
      }
    };

    const setTarget = () => {
      const currentPathQuery = Logic.Common.route?.query;

      setTimeout(() => {
        if (currentPathQuery) {
          selectedNetwork.value =
            currentPathQuery.target?.toString().toLowerCase() || "mtn";
          scrollToSpecificItem("dataServiceContainer", `cableTv${selectedNetwork.value}`);
        }
      }, 500);
    };

    watch(selectedBeneficiary, () => {
      setDataDetails();
    });

    watch(SingleProduct, () => {
      setDataDetails();
    });

    onIonViewWillEnter(() => {
      setTarget();
    });

    onMounted(() => {
      setDataDetails();

      if (Logic.Common.currentBuildType() == "web") {
        setTarget();
      }
    });

    return {
      allNetworks,
      selectedNetwork,
      dataDealSections,
      sectionOptions,
      Logic,
      selectedProduct,
      currentNetwork,
      selectedPlanFilter,
      phoneNumber,
      FormValidations,
      DataProductMetadata,
      phoneNumberRef,
      saveAsBeneficiary,
      loaderSetup,
      formIsValid,
      purchaseData,
      searchPhone,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
