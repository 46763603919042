import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row items-center justify-center space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select, {
        placeholder: 'Select Category',
        hasTitle: true,
        paddings: 'py-3 px-4',
        options: _ctx.spendingCategoriesOptions,
        ref: "category",
        "update-key": _ctx.formData.category,
        modelValue: _ctx.formData.category,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.category) = $event))
      }, {
        title: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" Spending Category ")
        ])),
        _: 1
      }, 8, ["options", "update-key", "modelValue"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter name',
        hasTitle: true,
        paddings: 'py-3 px-4',
        rules: [_ctx.FormValidations.RequiredRule],
        modelValue: _ctx.formData.merchant_name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.merchant_name) = $event)),
        name: "Merchant name",
        ref: "merchant_name",
        type: "text",
        "update-value": _ctx.formData.merchant_name
      }, {
        title: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Merchant name ")
        ])),
        "inner-prefix": _withCtx(() => [
          _createVNode(_component_app_icon, {
            name: 'merchant-gray',
            customClass: 'h-[14px]'
          })
        ]),
        _: 1
      }, 8, ["rules", "modelValue", "update-value"]),
      (_ctx.formData.total_amount)
        ? (_openBlock(), _createBlock(_component_app_text_field, {
            key: 0,
            placeholder: 'Enter amount',
            hasTitle: true,
            paddings: 'py-3 px-4',
            rules: [_ctx.FormValidations.RequiredRule],
            modelValue: _ctx.formData.total_amount,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.total_amount) = $event)),
            name: "Total Amount",
            ref: "total_amount",
            type: "tel",
            "is-formatted": true
          }, {
            title: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Total Amount ")
            ])),
            "inner-prefix": _withCtx(() => [
              _createVNode(_component_app_icon, {
                name: 'money-gray',
                customClass: 'h-[14px]'
              })
            ]),
            _: 1
          }, 8, ["rules", "modelValue"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.validateForm()), ["prevent"])),
          customClass: 'w-full',
          class: "py-3"
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode(" Save ")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}