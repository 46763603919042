import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-row items-center justify-center top-0 left-0  fixed z-[999999999999999] `)
}
const _hoisted_2 = {
  class: /*@__PURE__*/_normalizeClass(`w-full  mdlg:!w-[65%] lg:!w-[65%] md:w-full  grid grid-cols-12  `)
}
const _hoisted_3 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row justify-end"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex lg:!flex md:!flex flex-col" }, null, -1)),
              _createElementVNode("div", {
                class: _normalizeClass(`flex flex-col space-y-2  col-span-12 px-4  cursor-pointer mdlg:!col-span-6 md:!col-span-8 items-center justify-center mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 `),
                style: {"padding-top":"calc(env(safe-area-inset-top) + 16px)"},
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(`w-full border-[2px] bg-white dark:bg-gray-900 ${
              _ctx.setup.type === 'success' ? 'border-green-500' : ''
            }  ${_ctx.setup.type === 'error' ? 'border-red-500' : ''}  ${
              _ctx.setup.type === 'info' ? 'border-purple-500' : ''
            } rounded-[40px] px-1 py-1 justify-between items-center flex flex-row `)
                }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_AppIcon, {
                      name: `alert-${_ctx.setup.type}`,
                      class: "h-[35px]"
                    }, null, 8, ["name"]),
                    _createVNode(_component_app_normal_text, { class: "!font-semibold !line-clamp-1 dark:!text-gray-200" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.setup.message), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (_ctx.setup.action)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createElementVNode("span", {
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setup.action?.handler && _ctx.setup.action?.handler(...args))),
                          class: "px-3 py-[2px] bg-grey-50 rounded-[30px]"
                        }, [
                          _createVNode(_component_app_normal_text, { class: "!text-primary-400" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.setup.action.text), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ], 2)
              ]),
              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex mdlg:!col-span-3 flex-col md:!flex md:!col-span-2" }, null, -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}