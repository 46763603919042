<template>
  <div
    :class="`w-full flex flex-col space-y-2 ${customClass} cursor-pointer `"
    v-if="data"
  >
    <app-image-loader
      :photoUrl="data.base_image_url"
      class="!h-[130px] mdlg:!h-[200px] w-full relative rounded-[8px] border-[1px] border-grey-100 dark:!border-gray-700"
    >
      <!-- Cashback -->
      <span :class="`absolute left-[6%]  bottom-[6%]`">
        <span
          v-if="showCommission"
          :class="`px-2 py-1 rounded-[4px] bg-primary-900 flex flex-row items-center justify-center`"
        >
          <app-normal-text class="!text-white !text-[10px] text-center">
            {{
              !showCommission
                ? `${data.cashback}% Back`
                : `Earn ${data.resale_commission}%`
            }}
          </app-normal-text>
        </span>
      </span>

      <!-- Avatar -->
      <span
        v-if="showCommission"
        :class="`absolute  z-10  left-[5%]  top-[5%]`"
      >
        <app-image-loader
          :photoUrl="data.merchant.profile_image_url"
          :class="`!rounded-full h-[20px] w-[20px]`"
        />
      </span>
    </app-image-loader>

    <div class="w-full flex flex-col space-y-1 pb-2">
      <app-normal-text class="!font-semibold text-left !line-clamp-1">
        {{ data.title }}
      </app-normal-text>

      <div class="w-full flex flex-row items-center justify-between">
        <app-normal-text class="text-left">
          {{ Logic.Common.convertToMoney(data.price, false, "ngn") }}
        </app-normal-text>
        <app-badge
          :color="data.available_stock > 0 ? 'green' : 'red'"
          class="!text-[10px] !px-2 !py-[2px]"
        >
          {{
            data.available_stock > 0
              ? `${data.available_stock} available`
              : "Sold out"
          }}
        </app-badge>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppBadge from "../AppBadge";
import { Logic } from "../../composable";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppImageLoader,
    AppIcon,
    AppBadge,
  },
  props: {
    data: {
      type: Object as () => {
        base_image_url: string;
        title: string;
        price: number;
        resale_commission: string;
        cashback: string;
        available_stock: number;
        merchant: {
          name: string;
          profile_image_url: string;
        };
      },
    },
    showCommission: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  setup() {
    const baseStyle = ["h-[18px] xs:h-[18px]", "h-[16px] xs:h-[16px]"];
    return {
      Logic,
    };
  },
  name: "AppProduct",
};
</script>
