<template>
  <div
    class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-3 w-full"
  >
    <div class="flex justify-between w-full">
      <AppNormalText>Order ID: {{ orderId }} </AppNormalText>

      <app-badge :color="'green'" :class="'capitalize'"> {{ status }} </app-badge>
    </div>

    <div class="h-[1px] bg-grey-50 w-full"></div>

    <div class="flex justify-between w-full">
      <AppNormalText :custom-class="'font-[700]'">
        {{ name }}
      </AppNormalText>

      <AppNormalText :custom-class="'font-[700]'">
        N{{ amount }}
      </AppNormalText>
    </div>

    <div class="flex justify-between w-full">
      <AppNormalText :color="'text-grey-700'" :custom-class="'gap-2'">
        {{ date }}
        <span class="h-1 w-1 bg-grey-100 rounded-full"></span>
        {{ time }}
      </AppNormalText>
      <AppNormalText :color="'text-primary-500'"
        >{{ totalItems }} Items
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppBadge from "../AppBadge";
import { time } from "console";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
    AppBadge,
  },
  props: {
    icon: {
      type: String,
    },
    orderId: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    amount: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    time: {
      type: String,
      required: false,
    },
    totalItems: {
      type: Number,
      required: false,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppTransactionSummary",
};
</script>
