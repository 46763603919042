import { AuthUser } from './../../dist/esm/gql/graphql.d'
import { BaseApiService } from './common/BaseService'
import { OperationResult } from 'urql'
import {
  AuthResponse,
  User,
  MutationSignInArgs,
  MutationVerifyEmailOtpArgs,
  MutationResendPhoneOtpArgs,
  MutationResendVerifyEmailArgs,
  MutationPersonalizeAccountArgs,
  MutationSendResetPasswordEmailArgs,
  MutationUpdatePasswordArgs,
  MutationSaveUserVerificationArgs,
  MutationSignUpArgs,
  MutationVerifyPhoneOtpArgs,
  MutationSaveAuthPhoneNumberArgs,
} from '../gql/graphql'

export default class AuthApi extends BaseApiService {
  public GetAuthUser = () => {
    const requestData = `
	query GetAuthUser {
		GetAuthUser {
		  id
		  uuid
		  first_name
		  last_name
		  full_name
		  email
		  phone
		  username
		  email_verified_at
		  phone_verified_at
		  pending_points
		  receipt_week_spend
		  current_month_free_transfers
		  annual_spend
		  business_admin {
		     id
			  business {
			  id
			  uuid
			  business_user_id
			  business_tag
			  connected_accounts
			  manager_info {
			    first_name
				last_name 
			  }
			  description
			  account_type
			  business_name
			  phone_number
			  photo_url
			  verification_status
			  business_social_links {
				facebook
				instagram
				whatsapp
				website
			  }
			 }
			permissions{
                manage_all_orders
                manage_assigned_branch_orders
                manage_products
                manage_shoplists
                manage_promotions
                manage_store_branches
                manage_offline_payments
                manage_settlement_accounts
                manage_profile
              }
		  }
		  profile {
			id
			uuid
			photo_url
			user_verified
			gender
			date_of_birth
			account_tier_level
			claimed_earning_periods
			usage_policy_accepted
            snapping_terms_accepted
			spend_group
			savings_target_amount
			pin
			pin_is_set
			region{
				country
				currency
				phone_code
				flag_image_url
			}
			subscription_plan {
			  id
			  uuid
			  name
			  description
			  receipt_scan_allowed_days
			  scan_processing_period
			  claim_point_expiry
			  preload_cashback_percentage
			  monthly_cashback_growth
			  receipt_weekly_spend_limit
			  free_transfer_per_month
			  annual_spend_cap
			  delivery_type
			  delivery_amount
			  shoplist_commission {
			     category_id
				 percentage 
				 reward_cap_percentage
				 minimum_reward_percentage
			  }
			}
			business {
			  id
			  uuid
			  business_tag
			  business_user_id
			  connected_accounts
			  manager_info {
			    first_name
				last_name 
			  }
			  description
			  account_type
			  business_name
			  phone_number
			  photo_url
			  verification_status
			  business_social_links {
				facebook
				instagram
				whatsapp
				website
			  }
			}
		  }
		  point_categories {
			product_category{
				slug
				id
			}
			cash_per_point
			cap_amount
		  }
		}
		GetEarningsStatistics
	  }
		`

    const response: Promise<OperationResult<{
      GetAuthUser: User
      GetEarningsStatistics: string
    }>> = this.query(requestData, {})

    return response
  }

  public SignUp = (data: MutationSignUpArgs) => {
    const requestData = `
		mutation SignUp(
			$email: String!,
			$first_name: String!
			$is_sso: Boolean!
			$last_name: String
			$sso_id: String
			$phone_number: String
			$password: String
			$referral_code: String
		) {
			SignUp(email: $email, first_name: $first_name, last_name: $last_name, is_sso: $is_sso, sso_id: $sso_id, phone_number: $phone_number, password: $password, referral_code: $referral_code) {
				id,
				uuid,
				email,
				email_verified_at
				phone
				phone_verified_at
				has_password
			}
		}
		`

    const response: Promise<OperationResult<{
      SignUp: User
    }>> = this.mutation(requestData, data)

    return response
  }

  public SignIn = (data: MutationSignInArgs) => {
    const requestData = `
		mutation SignIn(
			$email: String!,
			  $password: String
			  $sso_id: String
		  ) {
			SignIn(email: $email, password: $password, sso_id: $sso_id) {
			  token,
			  user {
				id,
				uuid,
				email,
				first_name,
				last_name,
				phone,
				has_password,
				email_verified_at,
				phone_verified_at
				profile {
					pin_is_set
					user_verified
					gender
					spend_group
			        savings_target_amount
				}
			  }
			}
		  }
		`

    const response: Promise<OperationResult<{
      SignIn: AuthResponse
    }>> = this.mutation(requestData, data)

    return response
  }

  public CheckUserVerification = (email: string) => {
    const requestData = `
            mutation CheckUserVerification(
                $email: String!
            ) {
                CheckUserVerification(email: $email) {
                    id,
                    uuid,
                    email,
					phone
                    first_name,
                    username
                    last_name,
                    email_verified_at,
					phone_verified_at
                }
            }
            `

    const response: Promise<OperationResult<{
      CheckUserVerification: AuthUser
    }>> = this.mutation(requestData, {
      email,
    })

    return response
  }

  public VerifyPhoneOTP = (data: MutationVerifyPhoneOtpArgs) => {
    const requestData = `
		mutation VerifyPhoneOTP(
			$user_uuid: String!,
			  $otp: String!
		  ) {
			VerifyPhoneOTP(user_uuid: $user_uuid, otp: $otp) {
			  uuid,
			  phone,
			  email,
			  email_verified_at
			  phone_verified_at
			}
		  }
		`

    const response: Promise<OperationResult<{
      VerifyPhoneOTP: User
    }>> = this.mutation(requestData, data)

    return response
  }

  public VerifyEmailOTP = (data: MutationVerifyEmailOtpArgs) => {
    const requestData = `
		mutation VerifyEmailOTP(
			$email: String!,
			$otp: String!
		  ) {
			VerifyEmailOTP(email: $email, otp: $otp) {
			  uuid,
			  email,
			  email_verified_at
			  phone_verified_at
			}
		  }
		`

    const response: Promise<OperationResult<{
      VerifyEmailOTP: User
    }>> = this.mutation(requestData, data)

    return response
  }

  public ResendPhoneOTP = (data: MutationResendPhoneOtpArgs) => {
    const requestData = `
		mutation ResendPhoneOTP(
			$user_uuid: String!,
			  $phone_number: String!
			  $use_voice: Boolean
		  ) {
			ResendPhoneOTP(user_uuid: $user_uuid, phone_number: $phone_number, use_voice: $use_voice) 
		  }
		`

    const response: Promise<OperationResult<{
      ResendPhoneOTP: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public ResendVerifyEmail = (data: MutationResendVerifyEmailArgs) => {
    const requestData = `
		mutation ResendVerifyEmail(
			$user_uuid: String!
		  ) {
			ResendVerifyEmail(
			  user_uuid: $user_uuid,
			)
		  }
		`

    const response: Promise<OperationResult<{
      ResendVerifyEmail: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public SaveAuthPhoneNumber = (data: MutationSaveAuthPhoneNumberArgs) => {
    const requestData = `
		mutation SaveAuthPhoneNumber(
			$user_uuid: String!,
			$phone_number: String!
		  ) {
			SaveAuthPhoneNumber(user_uuid: $user_uuid, phone_number: $phone_number)
		  }
		`

    const response: Promise<OperationResult<{
      SaveAuthPhoneNumber: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public PersonalizeAccount = (data: MutationPersonalizeAccountArgs) => {
    const requestData = `
		mutation PersonalizeAccount($user_uuid: String!, $first_name: String!, $last_name: String!, $email: String!, $password: String!, $dob: String) {
			PersonalizeAccount(
			  user_uuid: $user_uuid
			  first_name: $first_name
			  last_name: $last_name
			  email: $email
			  password: $password
			  dob: $dob
			) {
			  token
			  user {
				id
				uuid
				first_name
				last_name
				email
				phone
				phone_verified_at
				email_verified_at
			  }
			}
		  }
		`

    const response: Promise<OperationResult<{
      PersonalizeAccount: AuthResponse
    }>> = this.mutation(requestData, data)

    return response
  }

  public SendResetPasswordEmail = (
    data: MutationSendResetPasswordEmailArgs,
  ) => {
    const requestData = `
		mutation SendResetPasswordEmail(
			$user_uuid: String!
		  ) {
			SendResetPasswordEmail(
			  user_uuid: $user_uuid,
			){
				uuid
			}
		  }
		`

    const response: Promise<OperationResult<{
      SendResetPasswordEmail: User
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdatePassword = (data: MutationUpdatePasswordArgs) => {
    const requestData = `
		mutation UpdatePassword(
			$user_uuid: String!,
			$password: String!
			$old_password: String!
		  ) {
			UpdatePassword(
			  user_uuid: $user_uuid,
			  password: $password
			  old_password: $old_password
			)
		  }
		`

    const response: Promise<OperationResult<{
      UpdatePassword: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public CheckIfUsernameExist = (username: string) => {
    const requestData = `
		mutation CheckIfUsernameExist(
			$username: String!,
		  ) {
			CheckIfUsernameExist(
				username: $username
			)
		  }
		`

    const response: Promise<OperationResult<{
      CheckIfUsernameExist: boolean
    }>> = this.mutation(requestData, { username })

    return response
  }

  public SaveUserVerification = (data: MutationSaveUserVerificationArgs) => {
    const requestData = `
	mutation SaveUserVerification(
		$id_number: String!,
		$selfie_image: Upload!
		$dob: String
		$bvn: String
		$nin: String
		$main_id_type: String
		$first_name: String
		$last_name: String
	  ) {
		SaveUserVerification(
			id_number: $id_number,
			selfie_image: $selfie_image
			dob: $dob
			bvn: $bvn
			nin: $nin
			main_id_type: $main_id_type
			first_name: $first_name
			last_name: $last_name
		)
	  }
	`
    const response: Promise<OperationResult<{
      SaveUserVerification: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public GlobalAuthUpdate = (
    handleSubscription: any,
    handleSubscribed: any,
  ) => {
    const requestData = `
	subscription GlobalAuthUpdate {
		GlobalAuthUpdate{
		   uuid
	      }
  }`

    const response = this.subscription(
      requestData,
      {},
      handleSubscription,
      handleSubscribed,
    )

    return response
  }

  public SignOut = () => {
    const requestData = `
		mutation SignOut{
			SignOut
		  }
		`

    const response: Promise<OperationResult<{
      SignOut: boolean
    }>> = this.mutation(requestData, {})

    return response
  }
}
