<template>
  <div class="w-full h-full grid grid-cols-2 absolute top-0 left-0 z-0">
    <app-image-loader
      :photoUrl="image.url"
      :class="`${image.width} h-full rounded-none`"
      v-for="(image, index) in gridImagesBase"
      :key="index"
      :style="`${image.border} `"
    />
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
import AppImageLoader from "../AppImageLoader";

export default defineComponent({
  name: "AppGridImages",
  components: {
    AppImageLoader,
  },
  props: {
    gridImages: {
      type: Array as () => string[],
      required: true,
    },
    borderRadius: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const gridImagesBase = ref<
      {
        url: string;
        width: string;
        border: string;
      }[]
    >([]);

    const dataRef = toRef(props, "gridImages");

    const setGridImages = () => {
      const propGridImages = props.gridImages;

      let finalImages: string[] = [];
      if (propGridImages) {
        if (propGridImages.length <= 4) {
          finalImages = propGridImages;
        } else {
          finalImages = propGridImages.slice(0, 4);
        }
      }

      gridImagesBase.value.length = 0;

      if (finalImages.length == 4) {
        gridImagesBase.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[2],
          width: `col-span-1 `,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[3],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 3) {
        gridImagesBase.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[2],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 2) {
        gridImagesBase.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImagesBase.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else {
        gridImagesBase.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-bottom-right-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px; border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
      }
    };

    watch(dataRef, () => {
      if (dataRef.value) {
        setGridImages();
      }
    });

    onMounted(() => {
      setGridImages();
    });

    return {
      gridImagesBase,
    };
  },
});
</script>
