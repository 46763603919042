import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center pb-[130px]" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-row py-4 px-4 rounded-[10px] bg-white bg-opacity-10 items-center justify-center space-x-2"
}
const _hoisted_3 = { class: "flex flex-col justify-start" }
const _hoisted_4 = { class: "w-[80%] px-4 items-center justify-center flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_icon, {
      customClass: 'h-[100px]',
      name: 'new-win',
      extension: 'png'
    }),
    (_ctx.data.point)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _createVNode(_component_app_icon, {
              name: 'coin',
              customClass: 'h-[44px]',
              extension: 'png'
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_normal_text, {
              "custom-class": 'uppercase text-[10px]',
              color: 'text-white'
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" You have claimed ")
              ])),
              _: 1
            }),
            _createVNode(_component_app_header_text, { color: 'text-white' }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.data.point) + " pts ", 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_app_normal_text, {
        color: '!text-white',
        customClass: '!font-semibold text-center'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.title), 1)
        ]),
        _: 1
      })
    ])
  ]))
}