import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full h-full flex flex-col ${
      _ctx.title != 'clear' ? ' pt-1 pb-4' : ''
    }  lg:text-sm mdlg:text-[12px] text-xs !bg-transparent overflow-y-hidden !font-inter`),
    style: _normalizeStyle(
      _ctx.title != 'clear'
        ? `
          padding-top: calc(env(safe-area-inset-top) + 8px) !important;
        padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;`
        : ''
    )
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}