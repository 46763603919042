<template>
  <app-wrapper>
    <subpage-layout :title="'Levels'">
      <div class="w-full flex flex-col space-y-4 px-4">
        <div
          :class="`${
            currentLevel == item.id ? 'bg-primary-main' : 'bg-grey-50'
          }  w-full flex flex-col rounded-[10px] relative`"
          v-for="(item, index) in levels"
          :key="index"
        >
          <div
            class="w-full flex flex-row px-4 pt-4"
            v-if="currentLevel == item.id"
          >
            <div
              class="flex flex-row py-1 px-4 bg-secondary-400 rounded-[19px]"
            >
              <app-normal-text
                :customClass="'!uppercase text-[10px] !font-semibold'"
              >
                YOUR CURRENT LEVEL
              </app-normal-text>
            </div>
          </div>
          <img
            src="/images/icons/level-bg.svg"
            class="right-0 top-[10%] h-[90px] absolute"
            v-if="currentLevel == item.id"
          />
          <div
            :class="`px-4 py-3  ${
              currentLevel == item.id
                ? 'border-primary-400 border-b-[1px]'
                : 'border-[#E8E8E8] border-b-[1px]'
            }  flex flex-row items-center justify-between`"
          >
            <div class="flex flex-row space-x-2 items-center">
              <app-image-loader
                :photo-url="item.image_url"
                class="h-[34px] w-[34px]"
              />
              <div class="flex flex-col">
                <app-normal-text
                  :color="
                    currentLevel != item.id ? '!text-black' : 'text-white'
                  "
                  :customClass="'!text-sm !uppercase font-semibold'"
                >
                  {{ item.title }}
                </app-normal-text>
                <app-normal-text
                  :customClass="`!uppercase !text-[10px] ${
                    currentLevel != item.id ? '' : 'opacity-60'
                  }`"
                  :color="
                    currentLevel != item.id ? '!text-grey-800' : 'text-white'
                  "
                >
                  {{ item.nick_name }}
                </app-normal-text>
              </div>
            </div>

            <app-normal-text
              :color="currentLevel != item.id ? '!text-black' : 'text-white'"
              :customClass="'!font-semibold '"
            >
              {{ item.point_range }} pts
            </app-normal-text>
          </div>
          <div class="w-full flex flex-col px-4 py-3">
            <app-normal-text
              :customClass="` !text-left  xs:!text-xs   ${
                currentLevel != item.id ? '' : 'opacity-60'
              }`"
              :color="currentLevel != item.id ? '!text-grey-800' : 'text-white'"
            >
              {{ item.description }}
            </app-normal-text>
          </div>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { tabTitle } from "@shpt/ui-components/src/composable";
import { onIonViewWillEnter } from "@ionic/vue";
import { AppNormalText, AppImageLoader } from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import AppWrapper from "@/components/AppWrapper.vue";

interface AccountLevel {
  id: string;
  title: string;
  nick_name: string;
  image_url: string;
  description: string;
  point_range: string;
}

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppWrapper,
  },
  name: "LevelsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "ManyAccountLevels",
        method: "GetAccountLevels",
        params: [],
        requireAuth: true,
        ignoreProperty: false,
      },
    ],
    tracking_data: {
      lable: "Account Levels Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Levels",
    });

    const currentLevel = ref("1");

    const ManyAccountLevels = ref(Logic.Game.ManyAccountLevels);
    const AuthUser = ref(Logic.Auth.AuthUser);

    const levels = reactive([
      {
        id: "1",
        title: "LEVEL 1",
        nick_name: "BRONZE",
        image_url: "/images/icons/badge-green.svg",
        description: `Users start at this level and earn the "Bronze" badge.`,
        point_range: "10,000-24,999",
      },
      {
        id: "2",
        title: "LEVEL 1",
        nick_name: "BRONZE",
        image_url: "/images/icons/badge-green.svg",
        description: `Users start at this level and earn the "Bronze" badge.`,
        point_range: "10,000-24,999",
      },
    ]);

    const setAccountLevel = () => {
      levels.length = 0;
      if (ManyAccountLevels.value != undefined) {
        let currentLevelData: AccountLevel | undefined = undefined;
        ManyAccountLevels.value?.forEach((item, index) => {
          const accountLevel = {
            id: item.id,
            description: item.offer,
            image_url: item.badge?.photo_url || "",
            nick_name: item.nick_name,
            title: item.title,
            point_range: `${Logic.Common.convertToMoney(
              item.min_points,
              false,
              ""
            )}${
              ManyAccountLevels.value && ManyAccountLevels.value[index + 1]
                ? `-${Logic.Common.convertToMoney(
                    ManyAccountLevels.value[index + 1].min_points,
                    false,
                    ""
                  )}`
                : ""
            }`,
          };
          if (item.id != currentLevel.value) {
            levels.push(accountLevel);
          } else {
            currentLevelData = accountLevel;
          }
        });
        if (currentLevelData) {
          levels.unshift(currentLevelData);
        }
      }
    };

    onMounted(() => {
      Logic.Game.watchProperty("ManyAccountLevels", ManyAccountLevels);
      Logic.Auth.watchProperty("AuthUser", AuthUser);
    });

    onIonViewWillEnter(() => {
      if (AuthUser.value) {
        currentLevel.value =
          AuthUser.value.game_profile?.account_level.id || "1";
      }
      setAccountLevel();
    });

    return {
      levels,
      currentLevel,
      ManyAccountLevels,
    };
  },
});
</script>
