import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col space-y-2"
}
const _hoisted_2 = { class: "flex flex-col space-y-3" }
const _hoisted_3 = { class: "flex flex-row items-center justify-between" }
const _hoisted_4 = { class: "flex flex-row items-center justify-between space-x-3 w-full" }
const _hoisted_5 = {
  key: 1,
  class: "flex items-center w-full justify-between border border-grey-50 rounded-lg py-2.5 px-3"
}
const _hoisted_6 = { class: "flex items-center space-x-2" }
const _hoisted_7 = { class: "flex flex-col space-y-2" }
const _hoisted_8 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_9 = { class: "flex flex-col justify-between h-full space-y-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPlainImageLoader = _resolveComponent("AppPlainImageLoader")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.mode == 'grid')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_AppPlainImageLoader, {
            "custom-class": 'rounded-lg w-full h-[137px]',
            "photo-url": $props.imageUrl
          }, null, 8, ["photo-url"]),
          _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.title), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
                default: _withCtx(() => [
                  _createTextVNode(" N" + _toDisplayString($props.amount), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppNormalText, {
                color: 'text-grey-700',
                size: 'small'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.date), 1)
                ]),
                _: 1
              })
            ]),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[1px] bg-grey-100 w-full" }, null, -1)),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_badge, { color: 'green' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.available) + " Available ", 1)
                ]),
                _: 1
              }),
              _createVNode(_component_AppNormalText, {
                color: 'text-base-black',
                size: 'small'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.sold) + " Sold ", 1)
                ]),
                _: 1
              })
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    ($props.mode == 'list')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_AppPlainImageLoader, {
              "custom-class": 'rounded-lg w-[46px] h-[46px] ',
              "photo-url": $props.imageUrl
            }, null, 8, ["photo-url"]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.title), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_8, [
                ($props.available)
                  ? (_openBlock(), _createBlock(_component_app_badge, {
                      key: 0,
                      color: 'green'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.available) + " Available ", 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                ($props.sold)
                  ? (_openBlock(), _createBlock(_component_AppNormalText, {
                      key: 1,
                      color: 'text-base-black',
                      size: 'small'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.sold) + " Sold ", 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                ($props.quantity)
                  ? (_openBlock(), _createBlock(_component_AppNormalText, {
                      key: 2,
                      color: 'text-base-black',
                      size: 'small'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Qty: " + _toDisplayString($props.quantity), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
              default: _withCtx(() => [
                _createTextVNode(" N" + _toDisplayString($props.amount), 1)
              ]),
              _: 1
            }),
            ($props.date)
              ? (_openBlock(), _createBlock(_component_AppNormalText, {
                  key: 0,
                  color: 'text-grey-700',
                  size: 'small'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString($props.date), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}