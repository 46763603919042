import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center gap-4" }
const _hoisted_2 = { class: "w-full bg-gray-200 rounded-full h-[4px] dark:bg-gray-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(`bg-green-500 h-[4px] rounded-full w-[${$props.progress}%]`)
      }, null, 2)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ]))
}