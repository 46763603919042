<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <app-icon :customClass="'h-[90px]'" :name="data.icon" />

    <div
      class="w-[80%] px-4 items-center justify-center flex-col space-y-3 flex"
    >
      <app-header-text v-if="data.large_title" class="!text-base">
        {{ data.large_title }}
      </app-header-text>
      <app-normal-text class="!text-[#664D7E]" :customClass="`!text-center`">
        {{ data.title }}
      </app-normal-text>
    </div>

    <div
      v-if="data.point"
      class="w-full flex flex-row py-4 px-4 rounded-[12px] bg-gray_50 items-center justify-center space-x-2"
    >
      <div>
        <app-icon :name="'coin'" :customClass="'h-[44px]'" :extension="'png'" />
      </div>
      <div class="flex flex-col justify-start">
        <app-normal-text :custom-class="'uppercase text-[10px]'">
          You have earned
        </app-normal-text>
        <app-header-text> {{ data.point }} pts </app-header-text>
      </div>
    </div>

    <div class="w-full flex flex-col space-y-2 pt-2" v-if="data.buttons.length">
      <app-button
        :customClass="'w-full'"
        :padding="'py-3'"
        @click="data.buttons[0].action()"
      >
        {{ data.buttons[0].label }}
      </app-button>

      <app-button
        :class="'w-full !border-primary-500 !bg-transparent !border-[1px] !text-primary-500'"
        :padding="'py-3'"
        v-if="data.buttons[1]"
        @click="data.buttons[1].action()"
        :type="'light'"
      >
        {{ data.buttons[1].label }}
      </app-button>
    </div>
    <div v-else class="h-[30px] pb-6"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        icon: string;
        title: string;
        point: number;
        large_title?: string;
        buttons: {
          label: string;
          action: Function;
        }[];
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    return {};
  },
});
</script>
