<template>
  <div class="w-full flex flex-col h-full items-center justify-center">
    <div
      class="w-full h-full mdlg:!w-[70%] lg:!w-[65%] mdlg:!h-full lg:text-sm mdlg:text-[12px] grid grid-cols-12 text-xs!font-inter relative"
      :id="`parentContainer-${uniqueKey}`"
    >
      <!-- Left side -->

      <teleport to="#app" :disabled="Logic.Common.currentBuildType() == 'web'">
        <div
          class="hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full md:!flex md:!col-span-4 mdlg:!col-span-3 md:!pl-4 z-[9999999] bg-white dark:bg-black"
          :style="`width: ${sideBarConfig.left.width}px; left: ${sideBarConfig.left.left}px;`"
          :id="`sidbarContainerLeft`"
          v-if="
            showWebSideBar ||
            Logic.Common.currentMediaQuery() != 'md' ||
            (Logic.Common.currentMediaQuery() == 'md' &&
              Logic.Common.currentBuildType() == 'web')
          "
        >
          <app-web-side-bar :tabIsActive="tabIsActive" />
        </div>
      </teleport>

      <div
        class="hidden col-span-3 mdlg:!flex flex-col sticky top-0 md:!flex md:!col-span-4 mdlg:!col-span-3"
      ></div>

      <!-- Main section -->
      <div
        :class="`col-span-12 mdlg:!col-span-6 relative h-full md:!flex md:!col-span-8  flex mdlg:!border-l-[1px] md:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100 flex-col lg:text-sm mdlg:text-[12px] text-xs !font-inter mdlg:dark:!border-grey-700 ${
          Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`"
      >
        <slot name="topBar" />
        <slot />
      </div>

      <!-- Right side -->
      <div class="hidden col-span-3 mdlg:!flex flex-col sticky top-0"></div>

      <div
        class="hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full"
        :style="`width: ${sideBarConfig.left.width}px; right: ${sideBarConfig.left.left}px;`"
        :id="`sidbarContainerRight`"
      >
        <app-board-side-bar />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Logic } from "../../index";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { currentLayout, tabIsActive, topBarTitle } from "../../composable";
import { AppWebSideBar, AppBoardSideBar } from "../AppNavigator";

export default defineComponent({
  components: {
    AppWebSideBar,
    AppBoardSideBar,
  },
  name: "PlainLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const sideBarConfig = reactive({
      left: {
        width: 0,
        left: 0,
      },
      right: {
        width: 0,
        left: 0,
      },
    });

    const route = Logic.Common.route;
    const showWebSideBar = ref(false);
    const uniqueKey = Logic.Common.makeid(13);
    const pagePath = ref("");

    const calculateSideBarPosition = () => {
      const parentContainer = document.getElementById(
        `parentContainer-${uniqueKey}`
      );
      if (parentContainer) {
        const parentWidth = parentContainer.offsetWidth;
        const sidebarWidth =
          parentWidth *
          (Logic.Common.currentMediaQuery() != "md" ? 0.25 : 0.33); // 25% or 33% of parent width
        const parentLeftOffset = parentContainer.offsetLeft;

        // Calculate the right edge of the parent container
        const parentRightEdge = parentLeftOffset + parentWidth;

        // Update sideBarConfig for both left and right sidebars
        sideBarConfig.left = {
          width: sidebarWidth,
          left: parentLeftOffset,
        };
        sideBarConfig.right = {
          width: sidebarWidth,
          left: parentRightEdge,
        };
      }
    };

    watch(currentLayout, () => {
      if (currentLayout.value?.path.toString() === pagePath.value) {
        showWebSideBar.value = true;
      } else {
        showWebSideBar.value = false;
      }
      if (
        Logic.Common.currentMediaQuery() === "md" &&
        Logic.Common.currentBuildType() == "mobile"
      ) {
        setTimeout(calculateSideBarPosition, 100);
      } else {
        calculateSideBarPosition();
      }
    });

    onMounted(() => {
      pagePath.value = route?.path || "";
    });

    return {
      topBarTitle,
      sideBarConfig,
      Logic,
      currentLayout,
      route,
      showWebSideBar,
      uniqueKey,
      tabIsActive,
    };
  },
});
</script>
