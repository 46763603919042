import { Logic } from '.'
import {
  Business,
  DeliveryLocation,
  InstagramMedia,
  Order,
  Product,
  Products,
} from '../gql/graphql'
import { PaginatorInfo } from '../gql/graphql'
import { MediaBox, SelectOption } from '../types'
import { reactive, ref } from 'vue'

const selectedService = ref('airtime')
const searchValue = ref('')
const searchResultsLoading = ref(false)
const businessAdminOptions = reactive<SelectOption[]>([])

const deliveryAreas = reactive<
  {
    location: string
    cost: number
    active: boolean
    id: string
    area: string[]
    data: DeliveryLocation
  }[]
>([])

const pickupAreaIsLoading = ref(false)

const productIsInCart = (
  uuid: string,
  type: 'product' | 'collection' = 'product',
) => {
  if (type == 'product') {
    return Logic.Shoplist.UserProductCart?.products?.find((item) => {
      return item.uuid == uuid
    })
      ? true
      : false
  } else {
    return Logic.Shoplist.UserProductCart?.collections?.find((item) => {
      return item.uuid == uuid
    })
      ? true
      : false
  }
}

const ShoplistSearchResults = reactive<{
  data: {
    data: MediaBox | Business
    id: string
    type: 'media' | 'business'
  }[]
  paginatorInfo: PaginatorInfo | undefined
}>({
  data: [],
  paginatorInfo: undefined,
})

export interface DealData {
  image_url: string
  title: string
  offered_by: string
  merchant_name: string
  points: string
  points_numeric: number
  routePath: string
  product_uuid: string
  merchant_code: string
  data: Product
  created_at: string
  vendor?: {
    name: string
    logo: string
  }
}

const newShoplist = reactive<{
  type: 'video' | 'image'
  file_url: string
  title: string
  expires_on: string
  products: {
    id: string
    image_url: string
    title: string
    price: number
    business_id: number
  }[]
  description: string
  reward_percentage: string
  instagram_data?: InstagramMedia
  file?: Blob
  base_image?: Blob
  base_video?: Blob
  progress: {
    loading: boolean
    message: string
    percentage: number
  }
  shipping_category_id: string
  shipping_category_data: string
  package_dimension_id: string
  package_dimension_data: string
}>({
  type: 'image',
  file_url: '',
  reward_percentage: '',
  title: '',
  expires_on: Logic.Common.momentInstance(new Date())
    .add(7, 'days')
    .format('YYYY-MM-DD'),
  description: '',
  products: [],
  progress: {
    loading: false,
    message: '',
    percentage: 0,
  },
  shipping_category_id: '',
  shipping_category_data: '',
  package_dimension_id: '',
  package_dimension_data: '',
})

const selectedBeneficiary = ref<{
  name: string
  id: string
  photo_url?: string
  product_code: string
  product_uuid: string
}>()

const billerCountries = reactive<
  {
    id: string
    logo: string
    data: any
    name: string
  }[]
>([])

const giftCardCategories = reactive<
  {
    count: number
    label: string
    data: DealData[]
  }[]
>([])

const selectedCountry = ref('')

const ManyProducts = ref(Logic.Shop.ManyProducts)

const SingleProduct = ref(Logic.Shop.SingleProduct)

const setCurrentProduct = (productUuid: string, category_slug: any) => {
  if (productUuid == 'default') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const currentProducts: Product[] = Logic.Shop.ShopData[category_slug] || []

    if (currentProducts?.length) {
      Logic.Shop.SingleProduct = currentProducts[0]
      SingleProduct.value = currentProducts[0]
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const currentProducts: Product[] = Logic.Shop.ShopData[category_slug] || []

    if (currentProducts.length) {
      const currentProduct = currentProducts.filter((item) => {
        return item.uuid == productUuid
      })

      if (currentProduct?.length) {
        Logic.Shop.SingleProduct = currentProduct[0]
        SingleProduct.value = currentProduct[0]
      } else {
        const currentProduct = currentProducts.filter((item) => {
          return item.product_category.slug == category_slug
        })

        if (currentProduct?.length) {
          Logic.Shop.SingleProduct = currentProduct[0]
          SingleProduct.value = currentProduct[0]
        }
      }
    }
  }
}

const setAirtimeProduct = (type: string) => {
  if (ManyProducts.value) {
    // get category products
    Logic.Shop.SingleProduct = ManyProducts.value?.data.filter((item) => {
      const metadata = JSON.parse(item.metadata || '')
      if (item.product_category.slug == 'airtime' && metadata) {
        return metadata['service_type'] == type
      } else {
        return false
      }
    })[0]
  }
}

const getProductPoint = (
  product_category_slug: string,
  product_price: string,
) => {
  let points = 0

  const pointCategory = Logic.Auth.AuthUser?.point_categories.filter(
    (category) => category.product_category.slug == product_category_slug,
  )

  if (pointCategory?.length) {
    points = parseFloat(product_price) / pointCategory[0].cash_per_point

    if (points > pointCategory[0].cap_amount) {
      points = pointCategory[0].cap_amount
    }
  }

  return points
}

const generateProductLogo = (merchant_name: string) => {
  return `/images/merchant-logo/${merchant_name
    ?.toLowerCase()
    .split(' ')
    .join('-')}.svg`
}

const processProductData = (productSource: Product[]) => {
  return productSource.map((item) => {
    return {
      title: item.name,
      image_url: item.image_url
        ? item.image_url
        : `/images/merchant-logo/${item.merchant_name
            ?.toLowerCase()
            .split(' ')
            .join('-')}.svg`,
      merchant_name: item.merchant_name || '',
      offered_by: item.merchant_name?.toUpperCase() || '',
      points: Logic.Common.convertToMoney(
        getProductPoint(item.product_category.slug, item.price),
        false,
        '',
      ),
      points_numeric: getProductPoint(item.product_category.slug, item.price),
      routePath: `/shop/category/${item.product_category.slug}/${item.uuid}`,
      product_uuid: item.uuid,
      data: item,
      vendor: {
        name: item.vendor?.name || '',
        logo: item.vendor?.logo_url || '',
      },
      created_at: item.created_at || '',
      merchant_code: item.merchant_product_code || '',
    }
  })
}

const getEquivalentPoint = (amount: number) => {
  return Logic.Common.convertToMoney(
    amount / (Logic.Shop.SingleProduct?.cash_per_point || 1),
    false,
    '',
  )
}

const makePurchase = (product: Products, saveBeneficiary: boolean) => {
  Logic.Shop.InitiateProductPaymentForm = {
    account_type: 'client',
    products: [product],
    save_beneficiary: saveBeneficiary,
    use_point: false,
    card_uuid: undefined,
  }

  Logic.Common.showModal({
    show: true,
    type: 'payment',
    title: 'Review payment',
    extraData: {
      payment_tag: 'Amount',
      amount: product.amount,
    },
    action: (data: any) => {
      if (Logic.Shop.InitiateProductPaymentForm) {
        if (data.method != 'wallet') {
          Logic.Shop.InitiateProductPaymentForm.card_uuid = data.method
        }
        Logic.Shop.InitiateProductPaymentForm.use_point = data.use_point

        Logic.Shop.InitiateProductPayment()?.then((data) => {
          if (data) {
            // Logic.Common.countDownToAction(60, () => {
            //   if (Logic.Common.loaderSetup.loading) {
            //     Logic.Common.showSuccess(
            //       'Your purchase has been initiated. Please be patient while we finanlize the transaction',
            //       () => {
            //         Logic.Common.showModal({ show: false })
            //         Logic.Common.GoToRoute('/')
            //       },
            //     )
            //   }
            // })
          }
        })
      }
    },
  })
}

const reportReceipt = () => {
  // show referral code
  Logic.Common.showModal({
    show: true,
    title: 'Report Receipt Issue',
    type: 'report_receipt',
    validateForm: true,
    action: (data: any) => {
      Logic.Shop.ReportScannedReceiptForm = {
        report_type: data.selected_report,
        scanned_receipt_uuid:
          Logic.Shop.SingleScannedReceipt?.scanned_receipt.uuid || '',
      }

      Logic.Shop.ReportScannedReceipt()
      Logic.Common.showModal({ show: false })
    },
    actionLabel: 'Send',
  })
}

const getStatusColor = (
  status: string,
): 'yellow' | 'blue' | 'green' | 'red' | 'purple' => {
  if (status == 'pending') {
    return 'yellow'
  } else if (
    status == 'confirmed' ||
    status == 'picked_up' ||
    status == 'in_transit'
  ) {
    return 'blue'
  } else if (status == 'completed') {
    return 'green'
  } else if (status == 'cancelled') {
    return 'red'
  } else if (status.includes('return')) {
    return 'purple'
  }

  return 'yellow'
}

const showOrderInfo = (code: string, data: Order, for_business: boolean) => {
  Logic.Common.showModal({
    show: true,
    title: `Order (${code})`,
    type: 'order_info',
    extraData: {
      data,
      for_business,
    },
  })
}

const setDeliveryLocations = () => {
  deliveryAreas.length = 0

  Logic.Shoplist.ManyDeliveryLocations?.data.forEach((item) => {
    deliveryAreas.push({
      location: `${item.supported_area?.area_name}, ${item.supported_area?.state_name}`,
      active: item.status == 'active',
      cost: parseFloat(item.fixed_cost || '0'),
      id: item.id,
      area: [item.supported_area?.id || '0'],
      data: item,
    })
  })
}

const getDeliveryLocations = async () => {
  pickupAreaIsLoading.value = true
  await Logic.Shoplist.GetDeliveryLocation(
    1,
    300,
    'CREATED_AT',
    'DESC',
    `{
    column: BUSINESS_PROFILE_ID
    operator: EQ
    value: ${Logic.Auth.GetCurrentBusiness()?.id}
  }`,
  )
  setDeliveryLocations()
  pickupAreaIsLoading.value = false
}

const showAddArea = (areaItem: any, index: number) => {
  Logic.Common.showModal({
    show: true,
    type: 'add_delivery_area',
    title: index == -1 ? 'Add Store Location' : 'Update Store Location',
    action: async (data: any, isDelete: boolean) => {
      if (index == -1) {
        Logic.Shoplist.CreateBusinessDeliveryAreasForm = {
          title: data.title,
          address: data.address,
          phone: data.phone,
          user_name: data.user_name,
          user_email: data.user_email,
          business_admin_id: data.business_admin_id,
        }
        Logic.Shoplist.CreateBusinessDeliveryAreas()?.then((data) => {
          if (data) {
            Logic.Common.showModal({ show: false })
            getDeliveryLocations()
            Logic.Common.showAlert({
              show: true,
              type: 'success',
              message: 'Store location created',
            })
          }
        })
      } else {
        if (isDelete) {
          Logic.Shoplist.DeleteDeliveryLocation(areaItem.id).then((data) => {
            if (data) {
              Logic.Common.showModal({ show: false })
              getDeliveryLocations()
            }
          })
        } else {
          Logic.Shoplist.UpdateDeliveryLocationForm = {
            is_business: true,
            delivery_location_id: areaItem.id,
            title: data.title,
            phone: data.phone,
            user_name: data.user_name,
            user_email: data.user_email,
            business_admin_id: data.business_admin_id,
          }

          Logic.Shoplist.UpdateDeliveryLocation()?.then((data) => {
            if (data) {
              Logic.Common.showAlert({
                show: true,
                type: 'success',
                message: 'Store location updated',
              })
              Logic.Common.showModal({ show: false })
              getDeliveryLocations()
            }
          })
        }
      }
    },
    extraData: {
      is_edit: index > -1,
      default: areaItem,
      businessAdminOptions: businessAdminOptions,
    },
  })
}

const setBusinessAdminOptions = () => {
  businessAdminOptions.length = 0

  businessAdminOptions.push({
    key: Logic.Auth.AuthUser?.profile?.business
      ? -1
      : parseInt(Logic.Auth.AuthUser?.business_admin?.id || '-1'),
    value: 'You',
    extras: JSON.stringify({
      name: Logic.Auth.AuthUser?.full_name,
      phone: Logic.Auth.AuthUser?.phone?.replaceAll('234', '0') || '',
      email: Logic.Auth.AuthUser?.email,
    }),
  })

  Logic.User.ManyBusinessAdmins?.data?.forEach((item) => {
    if (item.id != Logic.Auth.AuthUser?.business_admin?.id) {
      businessAdminOptions.push({
        key: parseInt(item.id),
        value: item.user?.full_name || '',
        extras: JSON.stringify({
          name: item.user?.full_name || '',
          phone: item.user?.phone?.replaceAll('234', '0'),
          email: item.user?.email,
        }),
      })
    }
  })
}

export {
  selectedService,
  ManyProducts,
  SingleProduct,
  selectedBeneficiary,
  billerCountries,
  selectedCountry,
  giftCardCategories,
  newShoplist,
  searchValue,
  searchResultsLoading,
  ShoplistSearchResults,
  deliveryAreas,
  pickupAreaIsLoading,
  productIsInCart,
  setBusinessAdminOptions,
  getDeliveryLocations,
  setDeliveryLocations,
  showAddArea,
  generateProductLogo,
  processProductData,
  setCurrentProduct,
  getEquivalentPoint,
  makePurchase,
  setAirtimeProduct,
  getProductPoint,
  reportReceipt,
  getStatusColor,
  showOrderInfo,
}
