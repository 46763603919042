import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full grid grid-cols-2 gap-4" }
const _hoisted_2 = { class: "col-span-1 flex flex-col" }
const _hoisted_3 = { class: "col-span-1 flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: '0000 0000 0000 0000',
        hasTitle: true,
        type: 'tel',
        rules: [_ctx.FormValidations.RequiredRule],
        padding: 'py-4 px-4',
        modelValue: _ctx.formData.card_number,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.card_number) = $event)),
        name: "Card number",
        ref: "card_number",
        "content-rule": {
        addAfterCount: 4,
        characterToAdd: ` `,
        max: 19,
      }
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Enter Card number ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_text_field, {
            placeholder: 'MM/YY',
            hasTitle: true,
            type: 'tel',
            padding: 'py-4 px-4',
            name: "Expiry date",
            ref: "expiry_date",
            modelValue: _ctx.formData.expiry_date,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.expiry_date) = $event)),
            rules: [_ctx.FormValidations.RequiredRule],
            "content-rule": {
            addAfterCount: 2,
            characterToAdd: `/`,
            max: 5,
          }
          }, {
            title: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Enter expiry date ")
            ])),
            _: 1
          }, 8, ["modelValue", "rules"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_app_text_field, {
            placeholder: '123',
            hasTitle: true,
            type: 'tel',
            rules: [_ctx.FormValidations.RequiredRule],
            modelValue: _ctx.formData.cvv,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.cvv) = $event)),
            padding: 'py-4 px-4',
            name: "CVV",
            ref: "cvv",
            "content-rule": {
            addAfterCount: 3,
            characterToAdd: ``,
            max: 3,
          }
          }, {
            title: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Enter cvv ")
            ])),
            _: 1
          }, 8, ["rules", "modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}