import { Logic as BaseLogic } from '@shpt/logic'
import { MediaBox, SelectOption } from '../types'
import { reactive, ref } from 'vue'
import {
  Advert,
  Collection,
  ContentType,
  ShoplistProduct,
  User,
} from '../gql/graphql'

export let Logic: typeof BaseLogic = BaseLogic

export const SetFrontendLogic = (logic: typeof BaseLogic) => {
  Logic = logic
}

export const topBarTitle = ref('')
export const tabTitle = ref('')
export const backRoutePath = ref('')
export const currentLayout = ref(Logic.Common.currentLayout)
export const topBarHasCurrencySwitch = ref(false)
export const isBusinessFocused = ref(false)
export const routeQueryString = ref('')

export const authMoveForward = (data: User) => {
  if (!data?.phone_verified_at) {
    Logic.Common.GoToRoute('/auth/verify-phone')
    return
  }

  if (Logic.Common.currentAccountType() == 'merchant') {
    if (Logic.Auth.GetCurrentBusiness()?.phone_number) {
      const allQueryParams = new URLSearchParams(routeQueryString.value)

      if (allQueryParams.get('force_auth') == '1') {
        const platform = allQueryParams.get('platform')
        const state = allQueryParams.get('state')

        // For shopify
        if (platform == 'shopify') {
          Logic.User.ConnectShopifyStore(state || '').then((data) => {
            if (data) {
              const storeName = state?.split('.')[0]
              if (Logic.Common.currentBuildType() == 'web') {
                window.location.href = `https://admin.shopify.com/store/${storeName}/apps/shoppoint`
              } else {
                Logic.Common.openBrowser(
                  `https://admin.shopify.com/store/${storeName}/apps/shoppoint`,
                  () => {},
                  () => {},
                )
              }
            }
          })
        } else {
          window.location.href = '/'
        }
      } else {
        window.location.href = '/'
      }
    } else {
      window.location.href = '/onboarding' + routeQueryString.value
    }
  } else {
    if (!data?.profile?.spend_group) {
      Logic.Common.GoToRoute('/auth/onboarding/savings-target')
    } else {
      Logic.Common.GoToRoute('/')
    }
  }
}

export const ionHandlers = reactive({
  onPageScroll: (data: any) => {
    console.log(data)
  },
})

export const getColors = (color: string) => {
  let bgColor = ''
  let textColor = ''

  if (typeof color === 'string' && color.startsWith('rgb')) {
    // Convert to a darker variant if the color is light
    const rgbValues = color.match(/\d+/g)
    if (rgbValues && rgbValues.length === 3) {
      const r = parseInt(rgbValues[0], 10)
      const g = parseInt(rgbValues[1], 10)
      const b = parseInt(rgbValues[2], 10)
      const brightness = (r * 299 + g * 587 + b * 114) / 1000
      if (brightness > 128) {
        // If the color is light, darken it
        const darkenFactor = 0.7
        bgColor = `rgb(${Math.floor(r * darkenFactor)}, ${Math.floor(
          g * darkenFactor,
        )}, ${Math.floor(b * darkenFactor)})`
      } else {
        bgColor = color
      }
    } else {
      bgColor = color
    }

    if (rgbValues && rgbValues.length === 3) {
      const r = parseInt(rgbValues[0], 10)
      const g = parseInt(rgbValues[1], 10)
      const b = parseInt(rgbValues[2], 10)

      // Calculate brightness and set text color
      const brightness = (r * 299 + g * 587 + b * 114) / 1000

      textColor = brightness > 128 ? '#000000' : '#FFFFFF'
    } else {
      console.error('Invalid RGB values in color:', color)
    }
  } else {
    console.error('Invalid color format. Expected rgb(r, g, b):', color)
  }

  return {
    bgColor,
    textColor,
  }
}

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'auto',
  })
}

export const numberToAbbrev = (value: number, useDp = true) => {
  const suffixes = ['', 'K', 'M', 'B', 'T']
  let suffixIndex = 0

  if (value < 1000) {
    if (value < 10) {
      if (useDp) {
        return value.toFixed(2)
      } else {
        Math.floor(value).toString()
      }
    }
    return Math.floor(value).toString()
  }

  while (value >= 1000 && suffixIndex < suffixes.length - 1) {
    value /= 1000
    suffixIndex++
  }

  let result = value.toFixed(1)
  if (result.endsWith('.0')) {
    result = result.slice(0, -2)
  }

  // Allow for 1 or 3 characters
  if (result.length > 3) {
    result = result.slice(0, 3)
  } else if (result.length === 2 && !result.includes('.')) {
    result = result.slice(0, 1)
  }

  // Remove trailing dot if present
  if (result.endsWith('.')) {
    result = result.slice(0, -1)
  }

  return `${result}${suffixes[suffixIndex]}`
}

export const isNumber = (value: string) => {
  return !isNaN(parseFloat(value)) && isFinite(Number(value))
}

export const scrollToItem = (id = '') => {
  if (id) {
    const element = document.getElementById(id)
    element?.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
export const scrollToSpecificItem = (containerId: string, itemId: string) => {
  const containerShoplist = document.getElementById(containerId)

  if (containerShoplist) {
    const serviceElement = document.getElementById(itemId)

    if (serviceElement) {
      const containerRect = containerShoplist.getBoundingClientRect()
      const targetRect = serviceElement.getBoundingClientRect()
      const offset = targetRect.left - containerRect.left
      containerShoplist.scrollBy({
        left: offset,
        behavior: 'smooth',
      })
    }
  }
}

export const FormValidations = Logic.Form
export const selectedSavingType = ref('')
export const selectedInvestmentType = ref('')
export const selectedPlanTab = ref('')
export const subPagePadding = ref('')
export const reviewPageSetup = reactive({
  bgColor: '',
  title: '',
  subTitle: '',
})
export const showCurrencySwitch = ref(false)
export const bottomNavStyle = ref('')
export const showSideBar = ref(false)

export const shoppingCategories = () => {
  const allCategories: {
    name: string
    icon: string
    is_parent: boolean
  }[] = []

  Logic.Shoplist.ManyCategories?.data.forEach((item) => {
    allCategories.push({
      name: item.name,
      icon: 'category/base',
      is_parent: item.parent_category_id == null,
    })
  })

  return allCategories
}

export const setAppTheme = (theme: 'light' | 'dark', reload = true) => {
  localStorage.theme = theme

  if (theme === 'dark') {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }

  if (reload) {
    window.location.reload()
  }
}

const getRewardPercentage = (product: ShoplistProduct) => {
  // let baseReward = product.rewards.filter(
  //   (reward) => reward.quantity == '-1',
  // )[0]

  // if (!baseReward) {
  //   baseReward = product.rewards[0]
  // }

  // const cashbackPercentage = parseFloat(
  //   parseFloat(baseReward.percentage).toFixed(2),
  // )

  // let percentageToRemove = 0

  const userPlanCashbackCommission = getUserPlanCashbackCommissionForProduct(
    product,
  )

  // if (userPlanCashbackCommission.cashbackCommission > 0) {
  //   const percentageCommissionToRemove =
  //     userPlanCashbackCommission.cashbackCommission / 100

  //   percentageToRemove = cashbackPercentage * percentageCommissionToRemove
  // }
  // let finalCashbackPercentage = cashbackPercentage - percentageToRemove

  // const capPercentage = userPlanCashbackCommission.commisionCap
  // const minimumPercentage = userPlanCashbackCommission.minimumCommisson

  // if (finalCashbackPercentage > capPercentage) {
  //   finalCashbackPercentage = capPercentage
  // } else if (finalCashbackPercentage < minimumPercentage) {
  //   finalCashbackPercentage = minimumPercentage
  // }

  return {
    cashbackCommission: userPlanCashbackCommission.cashbackCommission,
    commisionCap: userPlanCashbackCommission.commisionCap,
    minimumCommisson: userPlanCashbackCommission.minimumCommisson,
    affiliateCommissionPercentage:
      userPlanCashbackCommission.affiliateCommissionPercentage,
  }
}

export const getMediaBoxForProduct = (
  item: ShoplistProduct,
): MediaBox | undefined => {
  if (!item) {
    return undefined
  }

  const finalCashbackPercentage = getRewardPercentage(item).cashbackCommission

  const percentage = finalCashbackPercentage
  const points =
    (parseFloat(item.regular_price) * finalCashbackPercentage) / 100

  const affiliateCommissionPercentage = getRewardPercentage(item)
    .affiliateCommissionPercentage
  const affiliateCommissionPoints =
    (parseFloat(item.regular_price) * affiliateCommissionPercentage) / 100

  return {
    uuid: item.id,
    base_image: item.primary_image_url || '',
    is_large_view: false,
    user_info: {
      handle: item.business?.business_tag || '',
      image_size: '!h-[23px] !w-[23px]',
      image: item.business?.photo_url || '',
      name: item.business?.business_name || '',
    },
    tags: [],
    is_grid: true,
    grid_images: [item.primary_image_url],
    stats: {
      items: 1,
    },
    reward_type: 'cashback',
    reward_percentage: percentage,
    points: points,
    affiliate_commission_percentage: affiliateCommissionPercentage,
    affiliate_commission_points: affiliateCommissionPoints,
    main_category: item.category.name,
    title: item.name,
    price: parseFloat(item.regular_price),
    product_data: item,
  }
}

export const getMediaBoxForAds = (item: Advert): MediaBox => {
  return {
    uuid: item.id,
    base_image: item.advert_media?.metadata.image_url || '',
    is_large_view: false,
    user_info: {
      handle: item.user?.business?.business_tag || '',
      image_size: '!h-[23px] !w-[23px]',
      image: item.user?.business?.photo_url || '',
      name: item.user?.business?.business_name || '',
    },
    tags: [],
    is_grid: item.media?.metadata.media_type == 'image',
    grid_images: [],
    stats: {
      items: 0,
    },
    reward_type: 'cashback',
    reward_percentage: 0,
    main_category: '',
    title: '',
    price: 0,
    video_url:
      item.advert_media?.metadata.media_type == 'video'
        ? parseFloat(item.advert_media?.media?.percentage?.toString() || '0') ==
          100
          ? item.advert_media?.media?.media_url?.toString()
          : ''
        : '',
  }
}

export const getUserPlanCashbackCommissionForProduct = (
  product: ShoplistProduct,
) => {
  let cashbackCommission = 0
  let commisionCap = 50
  let minimumCommisson = 0
  let affiliateCommissionPercentage = 0

  if (!product) {
    return {
      cashbackCommission: 0,
      commisionCap: 50,
      minimumCommisson: 0,
      affiliateCommissionPercentage: 0,
    }
  }

  const productParentCategortId = product.category.parent_category_id
    ? product.category.parent_category_id
    : product.category.id

  const userSubscriptionPlan = Logic.Auth.AuthUser?.profile?.subscription_plan

  const currentCategory = Logic.Shoplist.ParentCategories?.find(
    (commission) =>
      parseInt(commission.id) == parseInt(productParentCategortId),
  )

  if (currentCategory) {
    const categoryCashbackCommission = currentCategory.subscription_commissions?.find(
      (commission) =>
        commission.plan_id == parseInt(userSubscriptionPlan?.id || '0'),
    )
    const merchantCommission = parseFloat(
      currentCategory.merchant_commission_percentage || '0',
    )

    // Cashback commission is a percentage of the merchant commission
    cashbackCommission =
      merchantCommission *
      (parseFloat(categoryCashbackCommission?.reward_percentage || '0') / 100)

    affiliateCommissionPercentage =
      merchantCommission *
      (parseFloat(currentCategory?.affiliate_commission_percentage || '0') /
        100)
  }

  return {
    cashbackCommission,
    commisionCap,
    minimumCommisson,
    affiliateCommissionPercentage,
  }
}

export const applyDeliveryDiscountForUser = (deliveryAmount: number) => {
  // This can be flat, free, percentage
  const deliveryDiscountType =
    Logic.Auth.AuthUser?.profile?.subscription_plan?.delivery_type ||
    'percentage'
  const deliveryDiscountAmount = parseFloat(
    Logic.Auth.AuthUser?.profile?.subscription_plan?.delivery_amount || '20',
  )

  let comment = ''

  if (deliveryDiscountType == 'percentage') {
    const amountToDiscount = (deliveryAmount * deliveryDiscountAmount) / 100
    const finalAmount = deliveryAmount - amountToDiscount
    comment = `-${deliveryDiscountAmount}%`
    return {
      amount: finalAmount,
      comment: comment,
    }
  } else if (deliveryDiscountType == 'flat') {
    comment = `Flat fee ${Logic.Common.convertToMoney(
      deliveryDiscountAmount,
      false,
      'ngn',
    )}`
    return {
      amount: deliveryDiscountAmount,
      comment: comment,
    }
  } else if (deliveryDiscountType == 'free') {
    return {
      amount: 0,
      comment: 'Free',
    }
  } else {
    return {
      amount: deliveryAmount,
      comment: '',
    }
  }
}

export const getMediaBox = (item: Collection): MediaBox => {
  const productsInfo = item.products.map((product) => {
    const percentResponse = getRewardPercentage(product)

    const finalCashbackPercentage = percentResponse.cashbackCommission
    const affiliateCommissionPercentage =
      percentResponse.affiliateCommissionPercentage

    return {
      percentage: finalCashbackPercentage,
      points:
        (parseFloat(product.regular_price) * finalCashbackPercentage) / 100,
      affiliate_commission_percentage: affiliateCommissionPercentage,
      affiliate_commission_points:
        (parseFloat(product.regular_price) * affiliateCommissionPercentage) /
        100,
    }
  })

  let totalRewardPercentage = Logic.Common.arraySum(
    productsInfo.map((item) => item.percentage),
  )

  let totalRewardPoints = Logic.Common.arraySum(
    productsInfo.map((item) => item.points),
  )

  let totalAffiliateCommissionPoints = Logic.Common.arraySum(
    productsInfo.map((item) => item.affiliate_commission_points),
  )

  const totalAffiliateCommissionPercentage = Logic.Common.arraySum(
    productsInfo.map((item) => item.affiliate_commission_percentage),
  )

  const totalPrice = Logic.Common.arraySum(
    item.products.map((product) => {
      return parseFloat(product.regular_price)
    }),
  )

  // Offer extra percentage
  const collectionRewardPercentage = parseFloat(item.reward_percentage || '0')

  if (collectionRewardPercentage > 0) {
    totalRewardPercentage += collectionRewardPercentage
    totalRewardPoints += (totalPrice * collectionRewardPercentage) / 100
  }

  return {
    uuid: item.uuid,
    base_image: item.base_image_url || '',
    is_large_view: false,
    user_info: {
      handle: item.business?.business_tag || '',
      image_size: '!h-[23px] !w-[23px]',
      image: item.business?.photo_url || '',
      name: item.business?.business_name || '',
    },
    tags: [],
    is_grid: item.media_type == 'image',
    grid_images: item.products.map((product) => product.primary_image_url),
    stats: {
      items: item.products.length,
    },
    reward_type: 'cashback',
    reward_percentage: parseFloat(totalRewardPercentage.toFixed(2)),
    main_category: item.products[0]?.category.name || '',
    affiliate_commission_percentage: totalAffiliateCommissionPercentage,
    affiliate_commission_points: totalAffiliateCommissionPoints,
    title: item.name,
    price: totalPrice,
    points: totalRewardPoints,
    data: item,
    video_url:
      parseFloat(item.media?.percentage?.toString() || '0') == 100
        ? item.media?.media_url?.toString()
        : '',
    expires_at: item.expires_at,
  }
}

export const supportedAreasOption = (addAllLocations = false) => {
  const supportedAreas: SelectOption[] = []

  const supportedAreaGroup = Logic.Common.groupArrayBy(
    Logic.Shoplist.ManySupportedAreas || [],
    'state_name',
  )

  if (addAllLocations) {
    supportedAreas.push({
      key: Logic.Shoplist.ManySupportedAreas?.map((item) => item.id),
      value: 'All Locations',
    })
  }

  for (const key in supportedAreaGroup) {
    if (Object.prototype.hasOwnProperty.call(supportedAreaGroup, key)) {
      const stateArea = supportedAreaGroup[key]

      supportedAreas.push({
        key: stateArea.map((item: any) => item.id),
        value: key,
      })

      stateArea.forEach((area: any) => {
        supportedAreas.push({
          key: [area.id],
          value: `${area.area_name}, ${area.state_name}`,
        })
      })
    }
  }

  return supportedAreas
}

export const adDemographiesOptions = () => {
  const adDemographies = Logic.Ad.ManyAdDemographies || []

  const allOptions: SelectOption[] = []

  // All demographies option
  allOptions.push({
    key: adDemographies.map((item) => item.id),
    value: 'All Demographies',
  })

  adDemographies.forEach((item) => {
    allOptions.push({
      key: [item.id],
      value: item.title,
    })
  })

  return allOptions
}

export const showRecommendationIsLoading = ref(false)
export const showSponsoredShoplistIsLoading = ref(false)
export const showExploreIsLoading = ref(false)

export const onRecommendedScrolledToEnd = (
  event: [IntersectionObserverEntry],
) => {
  const observer = event[0]

  if (observer) {
    if (observer.isIntersecting) {
      const currentPage = Logic.User.RecommendedForYouContent?.current_page || 0
      const totalPage = Logic.User.RecommendedForYouContent?.total_pages || 0

      let nextPage = currentPage

      if (currentPage < totalPage) {
        nextPage += 1
      } else {
        nextPage = -1
      }

      if (nextPage > 1) {
        if (showRecommendationIsLoading.value) {
          return
        }
        showRecommendationIsLoading.value = true
        Logic.User.GetUserContent(
          '',
          ContentType.RecommendedForYou,
          nextPage,
          10,
          false,
          true,
        )
          .then((responseData) => {
            if (responseData) {
              const existingRecommndationContent = JSON.parse(
                JSON.stringify(Logic.User.RecommendedForYouContent?.contents),
              )
              responseData.contents.unshift(...existingRecommndationContent)
              Logic.User.RecommendedForYouContent = responseData

              showRecommendationIsLoading.value = false
            }
          })
          .catch(() => {
            showRecommendationIsLoading.value = false
          })
      }
    } else {
      // console.log("It is not visible");
    }
  }
}

export const onExploreShoplistsScrolledToEnd = (
  event: [IntersectionObserverEntry],
) => {
  const observer = event[0]

  if (observer) {
    if (observer.isIntersecting) {
      const currentPage = Logic.User.ExploreContent?.current_page || 0
      const totalPage = Logic.User.ExploreContent?.total_pages || 0

      let nextPage = currentPage

      if (currentPage < totalPage) {
        nextPage += 1
      } else {
        nextPage = -1
      }

      if (nextPage > 1) {
        if (showExploreIsLoading.value) {
          return
        }
        showExploreIsLoading.value = true
        Logic.User.GetUserContent(
          '',
          ContentType.Explore,
          nextPage,
          10,
          true,
          true,
        )
          .then((responseData) => {
            if (responseData) {
              const existingExploreContent = JSON.parse(
                JSON.stringify(Logic.User.ExploreContent?.contents),
              )
              responseData.contents.unshift(...existingExploreContent)
              Logic.User.ExploreContent = responseData

              showExploreIsLoading.value = false
            }
          })
          .catch(() => {
            showExploreIsLoading.value = false
          })
      }
    }
  }
}

export const onSponsoredShoplistScrolledToEnd = (
  event: [IntersectionObserverEntry],
) => {
  const observer = event[0]

  if (observer) {
    if (observer.isIntersecting) {
      const currentPage = Logic.User.SponsoredShoplistContent?.current_page || 0
      const totalPage = Logic.User.SponsoredShoplistContent?.total_pages || 0

      let nextPage = currentPage

      if (currentPage < totalPage) {
        nextPage += 1
      } else {
        nextPage = -1
      }

      if (nextPage > 1) {
        if (showSponsoredShoplistIsLoading.value) {
          return
        }
        showSponsoredShoplistIsLoading.value = true
        Logic.User.GetUserContent(
          '',
          ContentType.SponsoredShoplist,
          nextPage,
          10,
          false,
          true,
        )
          .then((responseData) => {
            if (responseData) {
              const existingSponsoredShoplistContent = JSON.parse(
                JSON.stringify(Logic.User.SponsoredShoplistContent?.contents),
              )
              responseData.contents.unshift(...existingSponsoredShoplistContent)
              Logic.User.SponsoredShoplistContent = responseData

              showSponsoredShoplistIsLoading.value = false
            }
          })
          .catch(() => {
            showSponsoredShoplistIsLoading.value = false
          })
      }
    }
  }
}

export const fetchMoreSponsoredShoplists = (nextPage: number) => {
  return Logic.User.GetUserContent(
    '',
    ContentType.SponsoredShoplist,
    nextPage,
    10,
    false,
    true,
  )
    .then((responseData) => {
      if (responseData) {
        const existingSponsoredShoplistContent = JSON.parse(
          JSON.stringify(Logic.User.SponsoredShoplistContent?.contents),
        )
        responseData.contents.unshift(...existingSponsoredShoplistContent)
        Logic.User.SponsoredShoplistContent = responseData

        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

export const tabIsActive = (tabName: string) => {
  const mainName = tabName

  if (
    mainName == 'base' &&
    Logic.Common.router?.currentRoute.value.path == '/'
  ) {
    return true
  } else if (
    mainName == 'cart' &&
    Logic.Common.router?.currentRoute.value.path == '/shoplist/cart'
  ) {
    return true
  } else if (
    mainName != 'base' &&
    Logic.Common.router?.currentRoute.value.path.startsWith(`${mainName}`) &&
    !Logic.Common.router?.currentRoute.value.path.includes('cart')
  ) {
    return true
  }

  return false
}
