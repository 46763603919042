import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "w-full h-full flex flex-col",
    id: `pan${$setup.tabIndex}`
  }, [
    _createElementVNode("img", {
      src: 
        $props.url
          ?.replaceAll(
            'https://roof-income.nyc3.digitaloceanspaces.com',
            'https://shpt.blob.core.windows.net/shpt'
          )
          .replaceAll(
            'https://shpt.blob.core.windows.net/',
            'https://shptstorage-grazapdjemh4bzct.z02.azurefd.net/'
          ) || ''
      
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}