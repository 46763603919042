<template>
  <!-- Banner add -->
  <div
    class="w-full flex flex-col space-y-2 relative mdlg:min-h-[80px] min-h-[55px] dark:border-[1px] dark:border-gray-100"
    v-if="allBannerAds.length > 0"
    :id="`bannerAdContainer-${uniqueKey}`"
  >
    <img
      :src="allBannerAds[currentIndex].base_image"
      class="w-full h-auto max-h-[130px] cursor-pointer"
      v-if="imageIsLoaded"
      @click="openBrowser(allBannerAds[currentIndex].landing_page_url)"
    />
    <app-image-loader
      v-else
      :photoUrl="allBannerAds[currentIndex].base_image"
      class="w-full mdlg:!h-[80px] !h-[55px] rounded-[10px]"
      @loaded="imageIsDoneLoading"
    />
  </div>
</template>
<script lang="ts">
import { Advert, ContentType } from "../../gql/graphql";
import { Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import AppImageLoader from "../AppImageLoader";

export default defineComponent({
  components: {
    AppImageLoader,
  },
  props: {
    data: {
      type: Array as () => {
        base_image: string;
        has_video: boolean;
        content: {
          main_caption: string;
          sub_caption: string;
          cta: string;
        };
        variant: "red" | "brown";
        video_content_id?: string;
      }[],
    },
  },
  name: "AppBannerAd",
  setup() {
    const slidePosition = ref(0);
    const currentSlidePosition = ref(0);
    const bannerAdsIsFetching = ref(false);

    const uniqueKey = Logic.Common.makeid(16);

    const allBannerAds = reactive<
      {
        id: string;
        uuid: string;
        base_image: string;
        landing_page_url: string;
      }[]
    >([]);

    const BannerAdsContent = ref(Logic.User.BannerAdsContent);

    const currentIndex = ref(0);
    const imageIsLoaded = ref(false);

    const imageIsDoneLoading = () => {
      imageIsLoaded.value = true;
    };

    const timerInterval = ref<any>(null);

    const timeLoader = reactive({
      current: 0,
      total: 10,
    });

    // Change image every 5 seconds
    const changeImage = () => {
      if (allBannerAds.length > 0) {
        currentIndex.value = (currentIndex.value + 1) % allBannerAds.length;
        imageIsLoaded.value = false;
      }
    };

    const saveMetric = (event_type: string) => {
      Logic.Ad.SaveMetric({
        entity_type: "advert",
        event_type,
        entity_uuid: allBannerAds[currentIndex.value].uuid,
        event_metadata: JSON.stringify({
          type: "advert",
          advert_uuid: allBannerAds[currentIndex.value].uuid,
          story_index: currentIndex.value,
          request_uuid: BannerAdsContent.value?.request_uuid,
        }),
      });
    };

    const openBrowser = async (url: string) => {
      pauseTimer();
      saveMetric("clicked");
      await Logic.Common.openBrowser(
        url,
        () => {},
        () => {
          saveMetric("page_loaded");
        }
      );
    };

    const pauseTimer = () => {
      if (timerInterval.value) {
        clearInterval(timerInterval.value);

        timerInterval.value = null;
      }
    };

    const setTimeLoader = (reset: boolean = true) => {
      if (!imageIsLoaded.value) {
        return;
      }
      // saveMetric("viewed");
      if (reset) {
        timeLoader.current = 0;
        timeLoader.total = 10;
      }

      if (timerInterval.value) {
        clearInterval(timerInterval.value);
      }

      timerInterval.value = setInterval(() => {
        timeLoader.current += 1 / 5;
        if (timeLoader.current >= timeLoader.total) {
          clearInterval(timerInterval.value);
          changeImage();
          timeLoader.current = 0;
          timerInterval.value = null;
        }
      }, 200);
    };

    const resumeTimer = () => {
      setTimeLoader(false);
    };

    const fetchMoreBannerAds = () => {
      if (bannerAdsIsFetching.value) {
        return;
      }

      if (BannerAdsContent.value) {
        let currentPage = BannerAdsContent.value?.current_page || 0;
        let totalPage = BannerAdsContent.value?.total_pages || 0;

        let nextPage = currentPage;

        if (currentPage < totalPage) {
          nextPage += 1;
        } else {
          nextPage = -1;
        }

        if (nextPage > 1) {
          bannerAdsIsFetching.value = true;
          Logic.User.GetUserContent("", ContentType.BannerAds, nextPage, 10, true)
            .then((responseData: any) => {
              if (responseData) {
                const existingRecommndationContent = JSON.parse(
                  JSON.stringify(BannerAdsContent.value?.contents)
                );
                responseData.contents.unshift(...existingRecommndationContent);

                BannerAdsContent.value = responseData;

                bannerAdsIsFetching.value = false;
              }
            })
            .catch(() => {
              bannerAdsIsFetching.value = false;
            });
        }
      }
    };

    const setBannerAds = () => {
      allBannerAds.length = 0;
      BannerAdsContent.value?.contents.forEach((item: any) => {
        allBannerAds.push({
          id: item.id,
          uuid: item.data.uuid,
          base_image: item.data.advert_media?.metadata?.image_url || "",
          landing_page_url: item.data.advert_media.cta_rules?.landing_page_url || "",
        });
      });
    };

    const handleIntersect = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          // play
          resumeTimer();
        } else {
          // Pause
          pauseTimer();
        }
      });
    };

    const createObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(handleIntersect, options);
      const targetElement = document.getElementById(`bannerAdContainer-${uniqueKey}`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    watch(BannerAdsContent, () => {
      setBannerAds();
    });

    watch(imageIsLoaded, () => {
      if (imageIsLoaded.value) {
        saveMetric("viewed");
        resumeTimer();
      }
    });

    watch(currentIndex, () => {
      if (currentIndex.value >= allBannerAds.length - 2) {
        fetchMoreBannerAds();
      }
    });

    onMounted(() => {
      Logic.User.watchProperty("BannerAdsContent", BannerAdsContent);

      if (!Logic.User.BannerAdsContent) {
        Logic.User.GetUserContent("", ContentType.BannerAds, 1, 15, false)?.then(() => {
          setBannerAds();
          setTimeout(() => {
            createObserver();
          }, 400);
        });
      } else {
        BannerAdsContent.value = Logic.User.BannerAdsContent;
        setBannerAds();
        setTimeout(() => {
          createObserver();
        }, 400);
      }
      // Listen for visibility
      document.addEventListener("visibilitychange", () => {
        setTimeout(() => {
          if (document.hidden) {
            pauseTimer();
          } else {
            resumeTimer();
          }
        }, 500);
      });
    });

    return {
      slidePosition,
      currentSlidePosition,
      allBannerAds,
      currentIndex,
      imageIsLoaded,
      uniqueKey,
      imageIsDoneLoading,
      openBrowser,
    };
  },
});
</script>
