import Auth from './Auth'
import Common from './Common'
import Form from './Form'
import Game from './Game'
import User from './User'
import Shop from './Shop'
import Wallet from './Wallet'
import Notification from './Notification'
import Insurance from './Insurance'
import Travel from './Travel'
import Shoplist from './Shoplist'
import Ad from './Ad'

export const Logic = {
  Auth: new Auth(),
  Common: new Common(),
  Form: new Form(),
  Game: new Game(),
  User: new User(),
  Shop: new Shop(),
  Wallet: new Wallet(),
  Notification: new Notification(),
  Insurance: new Insurance(),
  Travel: new Travel(),
  Shoplist: new Shoplist(),
  Ad: new Ad(),
}
