<template>
  <div class="w-full flex flex-col space-y-1 items-center justify-center h-[140px]">
    <img
      :src="`/images/not-found${
        Logic.Common.currentAppTheme() == 'dark' ? '-white' : ''
      }.svg`"
      class="h-[80px]"
    />
    <app-header-text class="text-center !text-base">
      {{ title }}
    </app-header-text>
    <app-normal-text class="text-center !text-grey-main">
      {{ subTitle }}
    </app-normal-text>

    <app-button v-if="action" class="w-auto px-5 py-2">
      {{ action.label }}
    </app-button>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent } from "vue";
import { AppHeaderText, AppNormalText } from "../AppTypography";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    action: {
      type: Object as () => {
        label: string;
        action: Function;
      },
    },
  },
  name: "AppEmptyState",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
