import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-1 items-center justify-center h-[140px]" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: `/images/not-found${
        _ctx.Logic.Common.currentAppTheme() == 'dark' ? '-white' : ''
      }.svg`,
      class: "h-[80px]"
    }, null, 8, _hoisted_2),
    _createVNode(_component_app_header_text, { class: "text-center !text-base" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.title), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_app_normal_text, { class: "text-center !text-grey-main" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.subTitle), 1)
      ]),
      _: 1
    }),
    (_ctx.action)
      ? (_openBlock(), _createBlock(_component_app_button, {
          key: 0,
          class: "w-auto px-5 py-2"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.action.label), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}