import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-center !mt-[22px]" }
const _hoisted_5 = { class: "absolute w-full flex items-center justify-center -bottom-[5%] left-0" }
const _hoisted_6 = { class: "bg-secondary-400 rounded-[40px] items-start justify-start px-2 py-[2px] border-[2px] border-white" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-3 items-center justify-center pt-[55px]" }
const _hoisted_8 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_9 = { class: "flex flex-row space-x-1 items-center justify-center" }
const _hoisted_10 = { class: "w-full px-4 flex flex-col" }
const _hoisted_11 = { class: "w-full flex flex-row items-center justify-between px-2 py-2 rounded-[50px] bg-[#340163]" }
const _hoisted_12 = { class: "flex flex-row items-center space-x-2" }
const _hoisted_13 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_14 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_15 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-center h-[150px]"
}
const _hoisted_16 = {
  key: 1,
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
}
const _hoisted_17 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_20 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_21 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_22 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_23 = { class: "w-full grid grid-cols-3 gap-3 px-4 pt-2" }
const _hoisted_24 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_challenge = _resolveComponent("app-challenge")!
  const _component_plain_layout = _resolveComponent("plain-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_plain_layout, { class: "bg-white" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_image_loader, {
              photoUrl: '/images/purple-bg.svg',
              class: "w-full flex flex-col space-y-3 px-4 !overflow-y-visible sticky top-0",
              style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important","height":"calc(env(safe-area-inset-top) + 135px) !important"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_app_icon, {
                    name: 'back-purple-bg',
                    customClass: '!h-[38px]',
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.goBack()))
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", {
                      class: "flex flex-row items-center justify-centerspace-x-2 bg-primary-300 rounded-[50px] px-4 py-2 space-x-2 cursor-pointer",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/profile/edit')))
                    }, [
                      _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Edit ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_icon, {
                        name: 'edit-white',
                        class: ""
                      })
                    ]),
                    _createElementVNode("div", {
                      class: "flex flex-row items-center justify-centerspace-x-2 bg-primary-300 rounded-[50px] px-4 py-2 space-x-2 cursor-pointer",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showDeleteAccount()))
                    }, [
                      _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" Delete ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_icon, {
                        name: 'delete-white',
                        class: "w-[15px]"
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: _ctx.AuthUser?.profile?.photo_url || '/images/avatar-1.png',
                    class: "w-[120px] h-[120px] rounded-full relative border-[3px] border-white"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_app_normal_text, { class: "text-center !text-white !text-[11px] !font-semibold" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.AuthUser?.game_profile?.total_points,
                        false,
                        ""
                      )) + " Pts ", 1)
                            ]),
                            _: 1
                          })
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["photoUrl"])
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_app_header_text, { class: "!text-base" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.AuthUser?.full_name), 1)
                    ]),
                    _: 1
                  }),
                  (
                  parseInt(_ctx.AuthUser?.profile?.subscription_plan.id || '1') > 1
                )
                    ? (_openBlock(), _createBlock(_component_app_icon, {
                        key: 0,
                        name: `plan-${_ctx.AuthUser?.profile?.subscription_plan.id}`,
                        "custom-class": 'h-[20px]'
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_app_normal_text, { class: "text-center !text-grey-700" }, {
                  default: _withCtx(() => [
                    _createTextVNode(" @" + _toDisplayString(_ctx.AuthUser?.username), 1)
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_app_image_loader, {
                      class: "h-[25px] w-[25px]",
                      "photo-url": 
                    _ctx.AuthUser?.game_profile?.account_level.badge?.photo_url
                  
                    }, null, 8, ["photo-url"]),
                    _createVNode(_component_app_normal_text, {
                      color: 'text-white',
                      class: "!font-semibold"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.AuthUser?.game_profile?.account_level.title), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", {
                    class: "flex flex-row items-center space-x-2 pr-1 cursor-pointer",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/levels')))
                  }, [
                    _createVNode(_component_app_normal_text, { color: '!text-[#BA83E7]' }, {
                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                        _createTextVNode(" View all levels ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_icon, {
                      name: 'chevron-right-purple',
                      customClass: 'h-[8px]'
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] px-4 !text-sm" }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createTextVNode(" Earned Badges ")
                  ])),
                  _: 1
                }),
                (_ctx.badges.filter((item) => item.won).length === 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createVNode(_component_app_empty_state, {
                        title: '',
                        subTitle: 'No earned badges yet'
                      })
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                      _createElementVNode("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.filter((item) => item.won), (badge, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: _normalizeClass(`flex flex-col space-y-2 justify-center items-center w-auto cursor-pointer`),
                            key: index,
                            onClick: ($event: any) => (_ctx.showBadgeInfo(badge.data, badge.won))
                          }, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: badge.image_url,
                              class: "!h-[90px] !w-[90px] mdlg:!h-[100px] mdlg:!w-[100px] rounded-full"
                            }, null, 8, ["photoUrl"]),
                            _createVNode(_component_app_normal_text, { class: "!text-center line-clamp-1 !font-semibold !text-[11px] mdlg:!text-xs" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(badge.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ], 8, _hoisted_18))
                        }), 128))
                      ])
                    ]))
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] px-4 !text-sm" }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Top Challenges ")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.challenges, (challenge, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "w-[280px] flex flex-col"
                      }, [
                        _createVNode(_component_app_challenge, {
                          data: challenge,
                          class: "cursor-pointer",
                          onClick: ($event: any) => (
                      _ctx.Logic.Common.GoToRoute(
                        `/others/challenges/${challenge.uuid}`
                      )
                    )
                        }, null, 8, ["data", "onClick"])
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_22, [
                _createVNode(_component_app_normal_text, { class: "w-full !text-left !text-[#664D7E] px-4 !text-sm" }, {
                  default: _withCtx(() => _cache[9] || (_cache[9] = [
                    _createTextVNode(" Badges to unlock ")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_23, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.badges.filter((item) => !item.won), (badge, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "col-span-1 flex flex-col space-y-1 justify-center items-center cursor-pointer",
                      onClick: ($event: any) => (_ctx.showBadgeInfo(badge.data, badge.won))
                    }, [
                      _createVNode(_component_app_image_loader, {
                        photoUrl: '/images/lock-badge.svg',
                        class: "!h-[75px] !w-[75px] mdlg:!h-[85px] mdlg:!w-[85px] rounded-full"
                      }),
                      _createVNode(_component_app_normal_text, { class: "!text-center line-clamp-1 !font-semibold !text-[10px] mdlg:!text-[12px]" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(badge.title), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ], 8, _hoisted_24))
                  }), 128))
                ])
              ])
            ]),
            _cache[10] || (_cache[10] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}