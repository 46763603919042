import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-[1px]" }
const _hoisted_2 = { class: "w-full flex flex-row justify-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_verify_phone_page = _resolveComponent("app-verify-phone-page")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_verify_phone_page, {
        title: '',
        hasCustomSubTitle: true,
        hasBottomBorder: false
      }, {
        "top-section": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full relative h-[8px] bg-gray-100 rounded-full" }, [
              _createElementVNode("div", { class: "absolute top-0 left-0 w-[40%] h-[8px] bg-primary-500 rounded-full" })
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, { class: "!text-grey-500 dark:!text-grey-400" }, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" 40% ")
                ])),
                _: 1
              })
            ])
          ])
        ]),
        "sub-title": _withCtx(() => [
          _createVNode(_component_app_header_text, null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Verify Your Phone Number ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}