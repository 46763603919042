import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col  space-y-3 bg-transparent rounded-[10px] border-[1px] border-grey-100`)
}
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-between px-3 pt-3" }
const _hoisted_3 = { class: "flex flex-row space-x-2 items-start" }
const _hoisted_4 = { class: "w-[36px]" }
const _hoisted_5 = { class: "h-[35px] w-[35px] rounded-full bg-primary-50 items-center justify-center flex" }
const _hoisted_6 = { class: "flex flex-col space-y-[2px]" }
const _hoisted_7 = { class: "flex flex-col space-y-1 mt-1" }
const _hoisted_8 = { class: "flex flex-col justify-between space-y-[2px]" }
const _hoisted_9 = {
  key: 0,
  class: "px-2 py-[2px] bg-success-main !bg-opacity-10 text-success-main rounded-[40px] !text-[11px] mt-1"
}
const _hoisted_10 = { class: "w-full flex flex-col px-3 pb-3 border-t-[1px] border-[#E8E8E8] pt-3" }
const _hoisted_11 = { class: "flex flex-row space-x-1 items-center justify-between" }
const _hoisted_12 = { class: "font-semibold" }
const _hoisted_13 = {
  key: 0,
  class: "flex flex-row items-center space-x-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_icon, {
                  name: _ctx.data.icon,
                  customClass: 'h-[20px] xs:h-[18px]'
                }, null, 8, ["name"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_normal_text, { customClass: '!font-semibold !text-xs capitalize' }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.title), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_normal_text, {
                  color: 'text-grey-600',
                  class: "!text-xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.data.date), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_app_normal_text, { customClass: '!font-bold text-right !text-xs' }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.data.base_amount), 1)
              ]),
              _: 1
            }),
            (_ctx.data.uniqueId)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.data.uniqueId), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_app_normal_text, {
              color: 'text-grey-500 ',
              customClass: '!whitespace-nowrap !text-xs'
            }, {
              default: _withCtx(() => [
                _cache[0] || (_cache[0] = _createTextVNode(" Earnings: ")),
                _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.data.points) + " Pts (" + _toDisplayString(_ctx.data.amount) + ")", 1)
              ]),
              _: 1
            }),
            (_ctx.data.type == 'receipt')
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createVNode(_component_app_normal_text, { class: "!text-grey-500 !text-xs" }, {
                    default: _withCtx(() => _cache[1] || (_cache[1] = [
                      _createTextVNode(" View ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_icon, {
                    name: "view-more-receipt",
                    customClass: '!text-[20px]'
                  })
                ]))
              : _createCommentVNode("", true),
            (_ctx.data.type == 'card')
              ? (_openBlock(), _createBlock(_component_app_normal_text, {
                  key: 1,
                  class: "!text-grey-500 !text-xs"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.data.extra_info), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}