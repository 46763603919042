import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row h-full items-center justify-center !overflow-y-hidden" }
const _hoisted_2 = { class: "w-full h-full mdlg:!h-screen md:!h-screen lg:text-sm mdlg:text-[12px] grid grid-cols-12 text-xs bg-white dark:bg-black !font-inter relative md:!overflow-y-hidden mdlg:!overflow-y-hidden overflow-y-auto" }
const _hoisted_3 = { class: "col-span-12 mdlg:!col-span-12 md:!col-span-12 flex flex-col px-0 pb-0 md:!pb-4 relative items-start justify-start mdlg:!items-center mdlg:!justify-center md:!items-center md:!justify-center" }
const _hoisted_4 = { class: "w-full hidden mdlg:!flex flex-row justify-center items-center py-4 px-4 z-40 md:!flex pb-8" }
const _hoisted_5 = { class: "w-full flex flex-col mdlg:!w-[30%] md:!w-[60%] relative md:!bg-white px-4 h-screen overflow-y-auto md:!h-fit md:max-h-[calc(100vh-300px)] md:dark:!bg-black mdlg:!bg-white mdlg:dark:!bg-black md:!rounded-xl md:!shadow-custom mdlg:!shadow-custom dark:md:border-[1px] dark:md:border-gray-600 dark:mdlg:border-[1px] dark:mdlg:border-gray-600" }
const _hoisted_6 = { class: "flex items-center flex-row space-x-3" }
const _hoisted_7 = { class: "w-full hidden mdlg:!flex flex-row justify-center items-center py-4 px-4 z-40 md:!flex pt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppHeaderText = _resolveComponent("AppHeaderText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "hidden col-span-5 flex-col sticky top-0 h-screen md:!col-span-5" }, null, -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_icon, {
            name: `logo-full${
              _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
            }`,
            class: "h-[40px]"
          }, null, 8, ["name"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(`flex justify-between  pb-4 ${
              _ctx.hasBottomBorder
                ? 'border-b-[2px] border-grey-50 dark:border-grey-700'
                : ''
            } sticky top-0 bg-white dark:bg-black`),
            style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
          }, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.goBack)
                ? (_openBlock(), _createBlock(_component_AppIcon, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('go-back'))),
                    "custom-class": 'w-[14px] cursor-pointer',
                    name: 
                  _ctx.Logic.Common.currentAppTheme() == 'light'
                    ? 'arrow-left'
                    : 'arrow-left-white'
                
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createVNode(_component_AppHeaderText, null, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "header-text")
                ]),
                _: 3
              })
            ]),
            (_ctx.rightButton)
              ? (_openBlock(), _createBlock(_component_AppButton, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('button-clicked'))),
                  "custom-class": 'rounded-[40px] font-500 text-[14px] ',
                  "bg-color": 'bg-primary-50 dark:bg-primary-400',
                  "text-color": 'text-primary-500 dark:text-primary-50',
                  padding: 'px-3 py-[2px]'
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "button-text")
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true)
          ], 2),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_app_normal_text, { class: "text-center !text-gray-500 dark:!text-gray-300" }, {
            default: _withCtx(() => [
              _createTextVNode(" © " + _toDisplayString(new Date().getFullYear()) + " Shoppoint Rewards. All rights reserved. ", 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}