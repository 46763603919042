import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, toDisplayString as _toDisplayString, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = { class: "w-full flex flex-row items-center justify-center pb-4" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[300px]"
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col space-y-2  cursor-pointer`)
}
const _hoisted_9 = { class: "w-full flex flex-col space-y-1 pb-2" }
const _hoisted_10 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_11 = {
  key: 1,
  class: "w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
}
const _hoisted_12 = ["id"]
const _hoisted_13 = { class: "w-full flex flex-col space-y-2 py-4 bg-white dark:bg-black sticky bottom-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isSetupStage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_app_normal_text, { class: "!text-center" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Please enter your woocommerce website URL to continue ")
            ])),
            _: 1
          }),
          _createVNode(_component_app_text_field, {
            "has-title": false,
            title: "Woocommerce Website URL",
            placeholder: "Enter URL",
            name: "Website URL",
            rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.WebsiteRule],
            modelValue: _ctx.woocommerceUrl,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.woocommerceUrl) = $event))
          }, null, 8, ["rules", "modelValue"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connectWoocommerce())),
              class: "!py-3 w-full",
              loading: _ctx.isConnecting
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Connect ")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_AppTextField, {
              placeholder: `Type here to search`,
              modelValue: _ctx.searchQuery,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchQuery) = $event))
            }, {
              "inner-prefix": _withCtx(() => [
                _createVNode(_component_AppIcon, {
                  "custom-class": '',
                  name: 'search'
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[6] || (_cache[6] = [
                  _createElementVNode("circle", {
                    class: "opacity-25",
                    cx: "12",
                    cy: "12",
                    r: "10",
                    stroke: "currentColor",
                    "stroke-width": "4"
                  }, null, -1),
                  _createElementVNode("path", {
                    class: "opacity-75",
                    fill: "currentColor",
                    d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  }, null, -1)
                ])))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.products.length > 0)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (product, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: _normalizeClass(`col-span-1 flex flex-col px-2 py-2 ${
                product.imported ? '!opacity-[60%]' : ''
              }   ${
                _ctx.selectedProducts.includes(product.id)
                  ? '!border-primary-400 border-[2px]'
                  : '!border-grey-100 dark:!border-gray-800 border-[1px]'
              } rounded-[8px] cursor-pointer`),
                          onClick: ($event: any) => (
                !product.imported
                  ? _ctx.selectedProducts.includes(product.id)
                    ? _ctx.selectedProducts.splice(_ctx.selectedProducts.indexOf(product.id), 1)
                    : _ctx.selectedProducts.push(product.id)
                  : null
              )
                        }, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: product.base_image_url,
                              class: "!h-[130px] mdlg:!h-[200px] w-full relative rounded-[8px] border-[1px] border-grey-200 dark:border-gray-800"
                            }, null, 8, ["photoUrl"]),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_app_normal_text, { class: "!font-semibold text-left !line-clamp-1" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(product.title), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("div", _hoisted_10, [
                                _createVNode(_component_app_normal_text, { class: "text-left" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(product.price, false, "ngn")), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ])
                          ])
                        ], 10, _hoisted_7))
                      }), 128)),
                      (_ctx.backgroundLoading)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                              _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                            ], -1)),
                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                              _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                            ], -1))
                          ], 64))
                        : _createCommentVNode("", true)
                    ], 64))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createVNode(_component_app_empty_state, {
                        title: 'No Products Here',
                        subTitle: 'All available products will show up here'
                      })
                    ]))
              ])),
          _createElementVNode("div", {
            class: "h-[40px] w-full z-50",
            id: `bottomAnchorWoocommerce-${_ctx.uniqueId}`
          }, null, 8, _hoisted_12),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_app_normal_text, { class: "w-full !text-center" }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode(" Maximum Import at a time is "),
                _createElementVNode("span", { class: "!font-semibold" }, "10 products", -1)
              ])),
              _: 1
            }),
            _createVNode(_component_app_button, {
              class: "w-full !py-3",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.importProducts()), ["prevent"])),
              loading: _ctx.loadingImport
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Import Product" + _toDisplayString(_ctx.uniqueSelectedProducts.length > 1 ? "s" : "") + " (" + _toDisplayString(_ctx.uniqueSelectedProducts.length) + ") ", 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ], 64))
  ]))
}