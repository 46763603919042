<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 pb-4 relative items-center justify-center md:!pb-0 mdlg:!pb-0"
  >
    <app-image-loader
      :photoUrl="data.isWon ? data.data.photo_url : '/images/lock-badge.svg'"
      class="!h-[100px] !w-[100px] rounded-full"
    />

    <app-header-text class="!text-base text-center">
      {{ data.data.title }} Badge
    </app-header-text>

    <div class="py-2 flex items-center justify-center px-3">
      <app-normal-text class="!text-[#664D7E] dark:!text-primary-100 text-center">
        {{ data.data.description }}
      </app-normal-text>
    </div>

    <div class="w-full flex flex-col space-y-2">
      <app-button :class="`w-full py-3 ${data.isWon ? '' : 'opacity-50'}`">
        Share Achievement
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import { Badge } from "../../gql/graphql";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppImageLoader,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        data: Badge;
        isWon: Boolean;
      },
    },
  },
  name: "AppModalBadgeInfo",
  setup(props) {
    onMounted(() => {
      if (props.data?.isWon) {
        Logic.Common.showConfetti("confetti-canvas");
        Logic.Game.SaveBadgeAcknowledged(parseInt(props.data?.data?.id));
      }
    });
    return {
      Logic,
    };
  },
});
</script>
