import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-col pt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_otp_input = _resolveComponent("app-otp-input")!
  const _component_app_keyboard = _resolveComponent("app-keyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "w-full flex flex-row justify-center items-center" }, [
      _createElementVNode("div", { class: "h-[90px] w-[90px] rounded-full bg-shop_deep_purple_200 flex flex-col items-center justify-center" }, [
        _createElementVNode("img", {
          src: "/images/lock.png",
          class: "w-[60%]"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_otp_input, {
        numberOfInput: 4,
        alignmentStyle: 'items-center justify-between',
        sizeStyle: 'w-[74px] h-[70px] xs:w-[60px] xs:h-[50px]',
        "otp-value": _ctx.pinValue,
        onOnFilled: _ctx.otpIsFilled,
        onChangeOTP: () => {}
      }, null, 8, ["otp-value", "onOnFilled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_keyboard, {
        hasFingerPrint: true,
        modelValue: _ctx.pinValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pinValue) = $event))
      }, null, 8, ["modelValue"])
    ])
  ]))
}