import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-5 h-full flex-grow px-4 pb-4 relative overflow-y-auto" }
const _hoisted_2 = { class: "w-full flex flex-col items-center justify-center" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 bg-grey-50 rounded-[10px] dark:bg-gray-900" }
const _hoisted_4 = { class: "w-full xs:px-3 xs:py-3 py-4 px-4 flex flex-col space-y-3" }
const _hoisted_5 = { class: "w-full flex flex-col justify-center items-center" }
const _hoisted_6 = { class: "w-[300px] h-[300px] max-w-full bg-secondary-main rounded-[10px] px-3 py-3" }
const _hoisted_7 = { class: "w-full h-full flex flex-col" }
const _hoisted_8 = {
  key: 0,
  class: "w-full grid grid-cols-2 bg-grey-50 rounded-[10px] dark:bg-gray-900"
}
const _hoisted_9 = { class: "col-span-1 py-3 px-3 xs:px-2 xs:py-2 border-r-[1px] flex items-center flex-row justify-center" }
const _hoisted_10 = { class: "flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200" }
const _hoisted_11 = { class: "col-span-1 py-3 px-3 xs:px-2 xs:py-2 flex items-center flex-row justify-center" }
const _hoisted_12 = { class: "flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200" }
const _hoisted_13 = {
  key: 1,
  class: "col-span-2 py-3 px-3 xs:px-2 xs:py-2 flex items-center flex-row justify-center"
}
const _hoisted_14 = { class: "flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_qrcode = _resolveComponent("app-qrcode")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Transaction Details' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, { customClass: '!font-semibold' }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Amount ")
                ])),
                _: 1
              }),
              _createVNode(_component_app_header_text, {
                color: 'text-primary-500 dark:!text-primary-300',
                customClass: '!text-2xl'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.SinglePointTransaction?.dr_or_cr == "credit" ? "+" : "-") + " " + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                _ctx.SinglePointTransaction?.amount,
                true,
                "",
                true,
                "NGN "
              )), 1)
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.details, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "w-full flex flex-row justify-between items-center space-x-3",
                    key: index
                  }, [
                    _createVNode(_component_app_normal_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_component_app_normal_text, {
                      color: '!line-clamp-1 !text-right',
                      customClass: '!font-semibold'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.value), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_qrcode, {
                    qrcodeValue: 
                  JSON.stringify({
                    type: 'point',
                    uuid: _ctx.SinglePointTransaction?.uuid || '',
                  })
                ,
                    customClass: "w-full h-full"
                  }, null, 8, ["qrcodeValue"])
                ])
              ])
            ]),
            (
            _ctx.hasMoreOptions &&
            (_ctx.SinglePointTransaction?.status == 'success' ||
              _ctx.SinglePointTransaction?.status == 'successful') &&
            _ctx.SinglePointTransaction.extra_data
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (!_ctx.isGiftCard)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("div", {
                            class: "flex flex-row items-center space-x-2 cursor-pointer",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (
                  _ctx.Logic.Common.shareReceipt(
                    _ctx.SinglePointTransaction?.reference || '',
                    'image'
                  )
                ))
                          }, [
                            _createElementVNode("span", _hoisted_10, [
                              _createVNode(_component_app_icon, {
                                name: 'share-image',
                                customClass: 'h-[16px] xs:h-[16px]'
                              })
                            ]),
                            _createVNode(_component_app_normal_text, { color: 'text-primary-500 dark:text-primary-300' }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" Share as image ")
                              ])),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", {
                            class: "flex flex-row items-center space-x-2 cursor-pointer",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.Logic.Common.shareReceipt(
                    _ctx.SinglePointTransaction?.reference || '',
                    'pdf'
                  )
                ))
                          }, [
                            _createElementVNode("span", _hoisted_12, [
                              _createVNode(_component_app_icon, {
                                name: 'share-receipt',
                                customClass: 'h-[20px] xs:h-[18px]'
                              })
                            ]),
                            _createVNode(_component_app_normal_text, { color: 'text-primary-500 dark:text-primary-300' }, {
                              default: _withCtx(() => _cache[5] || (_cache[5] = [
                                _createTextVNode(" Share as PDF ")
                              ])),
                              _: 1
                            })
                          ])
                        ])
                      ], 64))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", {
                          class: "flex flex-row items-center space-x-2",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sendGiftCard()))
                        }, [
                          _createElementVNode("span", _hoisted_14, [
                            _createVNode(_component_app_icon, {
                              name: 'badges-icon',
                              customClass: 'h-[18px] xs:h-[18px]'
                            })
                          ]),
                          _createVNode(_component_app_normal_text, { color: 'text-primary-500 dark:text-primary-300' }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Send to a friend ")
                            ])),
                            _: 1
                          })
                        ])
                      ]))
                ]))
              : _createCommentVNode("", true),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}