import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row items-center justify-center space-x-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select, {
        placeholder: 'Select issue',
        hasTitle: true,
        rules: [_ctx.FormValidations.RequiredRule],
        paddings: '!py-3 px-4',
        modelValue: _ctx.formData.selected_report,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.selected_report) = $event)),
        name: "Report type",
        options: _ctx.reportOptions,
        ref: "report_type"
      }, {
        title: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" What is the issue? ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue", "options"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.validateForm()), ["prevent"])),
          customClass: 'w-full',
          class: "py-3"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Report ")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}