import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px] items-center justify-center h-[98px]"
}
const _hoisted_4 = { class: "flex flex-col justify-center pt-1" }
const _hoisted_5 = { class: "!text-xs !text-gray-600 dark:!text-gray-400 !font-semibold" }
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-col space-y-2 px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[10px]"
}
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-between space-x-2" }
const _hoisted_8 = { class: "w-full relative h-[8px] rounded-[30px] bg-white" }
const _hoisted_9 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_10 = {
  key: 1,
  class: "w-full flex flex-row space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px] items-center justify-center h-[98px]"
}
const _hoisted_11 = { class: "flex flex-col pt-1" }
const _hoisted_12 = { class: "!text-xs !text-gray-600 !font-semibold" }
const _hoisted_13 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Challenge Info' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_header_text, { class: "!text-left !text-base" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.SingleChallenge?.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_app_normal_text, {
              innerHTML: _ctx.SingleChallenge?.description,
              class: "!text-left !text-grey-700 dark:!text-gray-100"
            }, null, 8, ["innerHTML"]),
            _createElementVNode("div", _hoisted_2, [
              (_ctx.SingleChallenge?.game_type == 'transactional')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (parseFloat(_ctx.SingleChallenge?.rule?.reward_amount || '0') > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode(_component_app_icon, {
                            name: 'reward-white',
                            customClass: 'h-[48px]'
                          }),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !text-[11px]" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.SingleChallenge?.active_instance &&
                    _ctx.SingleChallenge?.active_instance?.status != "in_progress"
                      ? "POINTS REWARDED"
                      : "REWARD TO EARN"), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_app_normal_text, { customClass: '!text-xl !font-bold' }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.SingleChallenge?.rule?.reward_amount,
                      false,
                      "",
                      false
                    )) + " Pts ", 1),
                                _createElementVNode("span", _hoisted_5, "(" + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.SingleChallenge.rule?.reward_game_points,
                        false,
                        "ngn"
                      )) + ")", 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.SingleChallenge?.active_instance?.participant)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_app_normal_text, { customClass: 'uppercase ' }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode(" Challenge progress ")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("div", {
                                class: "absolute top-0 left-0 h-full rounded-[30px] bg-success-main",
                                style: _normalizeStyle(`width: ${_ctx.getPercentage(
                      parseFloat(
                        _ctx.SingleChallenge.active_instance.participant?.points || '0'
                      ),
                      parseFloat(_ctx.SingleChallenge.target_point || '1')
                    )}%`)
                              }, null, 4)
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.SingleChallenge.active_instance.participant?.points || 0,
                      false,
                      "",
                      false
                    )) + "/" + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.SingleChallenge.target_point,
                      false,
                      "",
                      false
                    )) + " Completed ", 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_app_normal_text, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.getPercentage(
                      parseFloat(
                        _ctx.SingleChallenge.active_instance.participant?.points || "0"
                      ),
                      parseFloat(_ctx.SingleChallenge.target_point || "1")
                    )) + "% ", 1)
                              ]),
                              _: 1
                            })
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                : _createCommentVNode("", true),
              (_ctx.SingleChallenge?.game_type == 'word_twist')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createVNode(_component_app_icon, {
                      name: 'reward-white',
                      customClass: 'h-[48px]'
                    }),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-left !uppercase" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode(" Reward to earn ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, { customClass: '!text-xl !font-bold' }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.SingleChallenge.rule?.reward_amount) + " Pts ", 1),
                          _createElementVNode("span", _hoisted_12, "(" + _toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.SingleChallenge.rule?.reward_game_points,
                        false,
                        "ngn"
                      )) + ")", 1)
                        ]),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          (
          !_ctx.SingleChallenge?.active_instance?.participant ||
          _ctx.SingleChallenge.game_type == 'word_twist'
        )
            ? (_openBlock(), _createBlock(_component_fixed_container, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_AppButton, {
                      padding: 'py-3',
                      "text-color": 'text-white',
                      class: "w-full",
                      loading: _ctx.loaderSetup.loading,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleChallenge()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.SingleChallenge?.active_instance?.participant
                ? "Play game"
                : "Join challenge"), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}