import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full" }
const _hoisted_2 = { class: "flex justify-between w-full" }
const _hoisted_3 = { class: "flex justify-between w-full" }
const _hoisted_4 = { class: "flex justify-between w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppNormalText, { class: "!font-semibold !line-clamp-1" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.data.title), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_app_badge, {
        color: _ctx.data.active ? 'green' : 'red',
        class: _normalizeClass('capitalize')
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.active ? "Active" : "Inactive"), 1)
        ]),
        _: 1
      }, 8, ["color"])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppNormalText, { class: "text-left" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.data.address), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppNormalText, {
        color: 'text-grey-700',
        class: "!text-[12px]",
        "custom-class": 'gap-2'
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" Store Admin ")
        ])),
        _: 1
      }),
      _createVNode(_component_AppNormalText, null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.data.business_admin
            ? _ctx.data.data.business_admin?.user?.full_name
            : "Super Admin"), 1)
        ]),
        _: 1
      })
    ])
  ]))
}