<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Enter Email -->
    <app-text-field
      :placeholder="'Enter email'"
      :rules="[FormValidations.RequiredRule, FormValidations.EmailRule]"
      v-model="formData.email"
      :hasTitle="true"
      type="email"
      :padding="'py-3 px-4'"
      name="Email Address"
      ref="friend_email"
    >
      <template v-slot:title> Email Address </template>
    </app-text-field>

    <div class="w-full flex flex-col space-y-2">
      <app-normal-text class="w-full text-left !font-semibold">
        Permissions
      </app-normal-text>

      <div class="w-full flex flex-col space-y-2">
        <div
          v-for="(item, index) in formData.permissions"
          :key="index"
          class="w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
        >
          <app-checkbox
            :standard="true"
            v-model="formData.permissions[index]"
            class="w-full"
          >
            <app-normal-text class="text-left">
              {{ index == "manage_all_orders" ? "Manage all orders" : "" }}
              {{
                index == "manage_assigned_branch_orders"
                  ? "Manage only assigned orders"
                  : ""
              }}
              {{
                index == "manage_offline_payments"
                  ? "Manage offline payment"
                  : ""
              }}
              {{ index == "manage_products" ? "Manage store products" : "" }}
              {{ index == "manage_profile" ? "Manage store profile" : "" }}
              {{ index == "manage_shoplists" ? "Manage offers" : "" }}
              {{ index == "manage_promotions" ? "Manage promotions" : "" }}
              {{
                index == "manage_store_branches" ? "Manage store branches" : ""
              }}
              {{
                index == "manage_settlement_accounts"
                  ? "Manage settlement accounts"
                  : ""
              }}
            </app-normal-text>
          </app-checkbox>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col">
      <app-button class="w-full py-3" @click.prevent="validateForm">
        Send
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { StorePermissions } from "../../gql/graphql";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppTextField, AppCheckbox } from "../AppForm";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
    AppButton,
    AppNormalText,
    AppCheckbox,
  },
  name: "AppModalSendInvitation",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive<{
      email: string;
      permissions: StorePermissions;
    }>({
      email: "",
      permissions: {
        manage_assigned_branch_orders: true,
        manage_products: true,
        manage_shoplists: true,
        manage_offline_payments: true,
        manage_all_orders: false,
        manage_profile: false,
        manage_promotions: false,
        manage_settlement_accounts: false,
        manage_store_branches: false,
      },
    });

    const admin_email = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(admin_email.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      formData,
      validateForm,
    };
  },
});
</script>
