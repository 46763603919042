import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[45px] w-[45px] rounded-full bg-primary-main flex flex-row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("span", {
    class: "absolute right-0 bottom-[100%] flex flex-row py-2 px-2 z-[999999999999] md:!hidden lg:!hidden mdlg:!hidden",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goToCart && _ctx.goToCart(...args)))
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_app_icon, {
        name: 'shopping-cart-white',
        customClass: 'h-[20px]'
      })
    ]),
    (_ctx.cartCount > 0)
      ? (_openBlock(), _createBlock(_component_app_normal_text, {
          key: 0,
          class: "!text-white h-[16px] w-[16px] bg-red-500 rounded-full flex flex-row items-center justify-center !text-[10px] font-semibold absolute right-[10%] top-[5%] z-40"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.cartCount > 9 ? "9+" : _ctx.cartCount), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}