<template>
  <div v-if="mode == 'grid'" class="flex flex-col space-y-4">
    <AppImageLoader
      :custom-class="'relative rounded-[8px] w-full h-[210px] px-2 py-2 flex flex-col justify-end space-y-3 relative p'"
      :photo-url="imageUrl"
    >
      <div
        class="rounded-[8px] z-30 absolute top-0 bg-[#0000004D] h-full right-0 w-full"
      ></div>

      <div
        class="flex flex-col justify-end absolute z-50 bottom-0 h-full right-0 w-full p-3 space-y-2"
      >
        <div class="flex space-x-2">
          <div class="flex items-center space-x-1">
            <AppIcon :custom-class="'h-2'" :name="'viewed'"></AppIcon>
            <AppNormalText :color="'text-white'"> {{ viewed }} </AppNormalText>
          </div>

          <div class="flex items-center space-x-1">
            <AppIcon :custom-class="'h-2.5'" :name="'length'"></AppIcon>
            <AppNormalText :color="'text-white'"> {{ length }} </AppNormalText>
          </div>
        </div>
        <AppNormalText :color="'text-white'" :size="'small'">
          {{ title }}
        </AppNormalText>
        <div class="flex justify-start flex-row pt-1">
          <app-badge
            :color="'outline-white'"
            :customClass="'!py-[1px] !text-[10px] '"
          >
            {{ buttonText }}
          </app-badge>
        </div>
      </div>
    </AppImageLoader>
  </div>

  <div
    v-if="mode == 'list'"
    :class="`${customClass} flex items-start w-full justify-between rounded-lg py-2 relative`"
  >
    <div class="w-full flex flex-row space-x-3 items-start h-[72px]">
      <div class="flex flex-col h-[72px]">
        <AppImageLoader
          :custom-class="'rounded-lg w-[72px] h-[72px] '"
          :photo-url="imageUrl"
        ></AppImageLoader>
      </div>
      <div class="flex flex-col space-y-1 justify-between !h-full">
        <div class="w-full flex flex-col space-y-1">
          <AppNormalText :color="'text-black !line-clamp-1'">
            {{ title }}
          </AppNormalText>

          <div class="flex space-x-3">
            <div class="flex items-center space-x-2">
              <AppIcon
                :custom-class="'h-[10]'"
                :name="'viewed-black'"
              ></AppIcon>
              <AppNormalText :color="'text-grey-700'">
                {{ viewed }}</AppNormalText
              >
            </div>
            <div class="flex items-center space-x-2">
              <AppIcon
                :custom-class="'h-[16px]'"
                :name="'length-black'"
              ></AppIcon>
              <AppNormalText :color="'text-grey-700'">
                {{ length }}
              </AppNormalText>
            </div>
          </div>
        </div>
        <AppProgress v-if="progress" :progress="progress">
          {{ progress }}%
        </AppProgress>

        <div v-else class="flex flex-row">
          <app-badge
            :color="'outline-black'"
            :customClass="'!py-[1px] !text-[10px]'"
          >
            Category
          </app-badge>
        </div>
      </div>
    </div>

    <div class="absolute right-0 top-[10%]">
      <AppIcon
        v-if="!rightSuffix"
        :custom-class="rightIconSize"
        :name="rightIcon"
      ></AppIcon>
      <slot v-else name="rightSuffix" />
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppPlainImageLoader from "../AppPlainImageLoader";
import { PropType } from "vue";
import AppProgress from "../AppProgress";
import AppBadge from "../AppBadge";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppIcon,
    AppImageLoader,
    AppPlainImageLoader,
    AppProgress,
    AppBadge,
  },
  props: {
    title: {
      type: String,
    },
    viewed: {
      type: Number,
      default: 0,
    },
    length: {
      type: String,
      default: "0:00",
    },
    buttonText: {
      type: String,
    },
    progress: {
      type: Number,
    },
    rightIconSize: {
      type: String,
      default: "h-3",
    },
    rightIcon: {
      type: String,
      default: "options",
    },
    rightSuffix: {
      type: Boolean,
    },
    imageUrl: {
      type: String,
      required: true,
    },
    mode: {
      type: String as PropType<"grid" | "list">,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppVideoList",
};
</script>
