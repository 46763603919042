import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-grey-50 border-[1px] flex flex-col items-start rounded-[8px] py-3 px-3 space-y-2 w-full" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_3 = { class: "w-full flex items-center space-x-2" }
const _hoisted_4 = { class: "w-[10px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppIcon, {
      "custom-class": 'h-[26px]',
      name: $props.icon
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppNormalText, {
        customClass: '!text-[10px]',
        color: 'text-black'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.title), 1)
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AppNormalText, { "custom-class": 'font-bold !text-sm' }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.total), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppIcon, {
            "custom-class": 'h-[10px]',
            name: 'arrow-stats-up'
          })
        ]),
        _createVNode(_component_AppNormalText, {
          class: _normalizeClass('!text-[10px]'),
          color: 'text-success-500'
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.percentage) + "% ", 1)
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_AppNormalText, { color: 'text-grey-700' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.lastText), 1)
        ]),
        _: 1
      })
    ])
  ]))
}