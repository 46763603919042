<template>
  <div class="w-full grid grid-cols-1 gap-3 py-3">
    <div
      v-for="(item, index) in paymentOptions"
      :key="index"
      class="col-span-1 border-[1px] border-gray-100 dark:!border-grey-700 rounded-[8px] py-3 px-4 flex flex-row items-center justify-between space-x-2 relative cursor-pointer"
      @click="item.action()"
    >
      <app-normal-text class="!text-black !font-semibold dark:!text-white">
        {{ item.name }}
      </app-normal-text>

      <app-icon :name="item.logo" :customClass="item.logo_size" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";
import process from "process";

export default defineComponent({
  props: {
    data: {
      type: Object as () => {
        currency: string;
        amount: string;
        email: string;
        user_uuid: string;
        wallet_uuid: string;
        type: "point" | "cash";
        narration: string;
      },
    },
    action: {
      type: Function,
    },
  },
  components: {
    AppIcon,
    AppNormalText,
  },
  name: "AppModalPaymentProvider",
  setup(props) {
    const showPaymentModal = ref(false);

    const paymentOptions = reactive([
      {
        logo: "payment/bank-transfer",
        name: "Bank Transfer",
        logo_size: "h-[32px]",
        is_active: true,
        key: "bank_transfer",
        action: () => {
          showPaymentModal.value = true;
          loadPayment(props.data?.currency || "", "bank_transfer");
        },
      },
      {
        logo: "payment/card",
        logo_size: "h-[32px]",
        name: "Debit or Credit Card",
        is_active: true,
        key: "card",
        action: () => {
          showPaymentModal.value = true;
          loadPayment(props.data?.currency || "", "card");
        },
      },
      {
        logo: "payment/bank",
        name: "Bank",
        logo_size: "h-[32px]",
        is_active: true,
        key: "bank",
        action: () => {
          showPaymentModal.value = true;
          loadPayment(props.data?.currency || "", "bank");
        },
      },
      {
        logo: "payment/ussd",
        name: "USSD",
        logo_size: "h-[32px]",
        is_active: true,
        key: "ussd",
        action: () => {
          showPaymentModal.value = true;
          loadPayment(props.data?.currency || "", "ussd");
        },
      },
      {
        logo: "payment/qrcode",
        name: "QrCode",
        logo_size: "h-[32px]",
        is_active: true,
        key: "qr",
        action: () => {
          showPaymentModal.value = true;
          loadPayment(props.data?.currency || "", "qr");
        },
      },
    ]);

    const loadPayment = (currency: string, channel: string) => {
      if (!props.data) {
        return;
      }

      // @ts-ignore
      if (window.PaystackPop == undefined) {
        return;
      }

      if (currency == "NGN") {
        let finalAmount = Math.ceil(parseFloat(props.data.amount));
        if (finalAmount < 50) {
          finalAmount = 50;
        }
        // @ts-ignore
        const handler = window.PaystackPop.setup({
          key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
          email: props.data.email,
          amount: finalAmount * 100,
          currency: "NGN",
          channels: [channel],
          label: "Shoppoint Voucher",
          metadata: {
            user_uuid: props.data?.user_uuid,
            wallet_uuid: props.data?.wallet_uuid,
            initiator: "Shoppoint",
            type: props.data?.type,
            narration: props.data?.narration,
          },
          onClose: function () {
            showPaymentModal.value = false;
          },
          callback: function (response: any) {
            showPaymentModal.value = false;
            if (response.status == "success") {
              Logic.Wallet.SavePaymentTransactionForm = {
                amount: props.data?.amount.toString() || "0",
                provider: "paystack",
                reference: response.reference,
                wallet_uuid: props.data?.wallet_uuid || "",
                type: props.data?.type || "",
                narration: props.data?.narration || "",
              };
              Logic.Wallet.SavePaymentTransaction(true)?.then((data: any) => {
                if (data) {
                  if (props.action) {
                    props.action();
                  }
                }
              });
            }
          },
          onBankTransferConfirmationPending: function (response: any) {
            showPaymentModal.value = false;
          },
        });

        handler.openIframe();
        watchForPaystackIframe();
      }
    };

    const watchForPaystackIframe = () => {
      const checkForPaystackIframe = setInterval(() => {
        const paystackIframes = document.querySelectorAll(
          'iframe[name^="paystack-checkout"]'
        );

        if (paystackIframes.length > 0) {
          console.log(paystackIframes);
          clearInterval(checkForPaystackIframe);
          paystackIframes.forEach((iframe) => {
            if (iframe instanceof HTMLElement) {
              const paddingTop = window.visualViewport?.offsetTop || 0 + 35;

              iframe.style.paddingTop = `${paddingTop}px`;
            }
          });
        }
      }, 500); // Check every 500ms
    };

    onMounted(() => {
      if (props.data?.currency == "NGN") {
        // @ts-ignore
        if (window.PaystackPop == undefined) {
          Logic.Common.loadScript(
            "https://js.paystack.co/v1/inline.js",
            () => {}
          );
        }
      }

      Logic.Common.laravelEcho
        ?.private(`userCardEnrolled.${Logic.Auth.AuthUser?.uuid}`)
        .listen("UserCardEnrolled", () => {
          Logic.Wallet.GetUserEnrolledCards();
          Logic.Common.showModal({ show: false });
        });
    });
    return {
      showPaymentModal,
      paymentOptions,
    };
  },
});
</script>
