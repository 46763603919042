<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Search and add -->
    <div class="w-full flex flex-row space-x-2 items-center">
      <AppTextField :placeholder="`Type to search`" v-model="searchQuery">
        <template v-slot:inner-prefix>
          <AppIcon :custom-class="''" :name="'search'"></AppIcon>
        </template>
      </AppTextField>
    </div>

    <div class="w-full flex flex-col">
      <app-switch :items="itemsSwitch" v-model="selectedSwitch" />
    </div>

    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[400px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <template v-if="!isLoading">
      <!-- Shoplists -->
      <template v-if="selectedSwitch == 'offers'">
        <div class="w-full flex flex-col">
          <template v-if="shoplists.data.length > 0">
            <app-virtual-scroller
              :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
              :data="shoplists.data"
              :pagination="shoplists.paginatorInfo"
              :fetchMore="fetchMoreShoplists"
              :direction="'vertical'"
              ref="shoplistsVirtualScrollerRef"
            >
              <template #item-content="{ index, item }">
                <div class="col-span-1 flex flex-col" :key="index">
                  <app-shoplist :item="item" @click="selectShoplist(item)" />
                </div>
              </template>
              <template #skeleton-loaders>
                <div class="col-span-1 flex flex-col">
                  <div
                    class="!h-[200px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </template>

          <div
            v-else
            class="w-full flex flex-col h-[300px] justify-center items-center"
          >
            <app-empty-state
              :title="'No Offers Here'"
              :subTitle="'All available offers will show up here'"
            >
            </app-empty-state>
          </div>
        </div>
      </template>

      <!-- Products -->
      <template v-if="selectedSwitch == 'products'">
        <div class="w-full flex flex-col">
          <template v-if="products.data.length > 0">
            <app-virtual-scroller
              :container-class="'w-full grid grid-cols-2 gap-3 !space-y-0'"
              :data="products.data"
              :pagination="products.paginatorInfo"
              :fetchMore="fetchMoreProducts"
              :direction="'vertical'"
              ref="productsVirtualScrollerRef"
            >
              <template #item-content="{ index, item }">
                <div class="col-span-1 flex flex-col" :key="index">
                  <app-product :data="item" @click="selectProduct(item)" />
                </div>
              </template>
              <template #skeleton-loaders>
                <div class="col-span-1 flex flex-col">
                  <div
                    class="!h-[200px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </template>
          <div
            v-else
            class="w-full flex flex-col h-[300px] justify-center items-center"
          >
            <app-empty-state
              :title="'No Products Here'"
              :subTitle="'All available products will show up here'"
            >
            </app-empty-state>
          </div>
        </div>
      </template>
    </template>

    <!-- Spacer -->
    <div class="h-[50px]"></div>
  </div>
</template>
<script lang="ts">
import { getMediaBox, Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppTextField } from "../AppForm";
import {
  CollectionPaginator,
  PaginatorInfo,
  ShoplistProduct,
  ShoplistProductPaginator,
} from "../../gql/graphql";
import AppEmptyState from "../AppEmptyState";
import { MediaBox } from "../../types";
import { AppShoplist } from "../AppShoplist";
import AppSwitch from "../AppSwitch";
import AppVirtualScroller from "../AppVirtualScroller";
import AppProduct from "../AppProduct";

export default defineComponent({
  components: {
    AppIcon,
    AppTextField,
    AppShoplist,
    AppEmptyState,
    AppSwitch,
    AppVirtualScroller,
    AppProduct,
  },
  props: {
    data: {
      type: Object as () => {
        selected: string;
        getMediaBox: Function;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const ManyShoplists = ref<CollectionPaginator>();
    const ManyShoplistProducts = ref<ShoplistProductPaginator>();

    const isLoading = ref(false);

    const selectedShoplist = ref("");

    const shoplists = reactive<{
      data: MediaBox[];
      paginatorInfo?: PaginatorInfo;
    }>({
      data: [],
    });

    const selectedSwitch = ref("offers");

    const products = reactive<{
      data: {
        base_image_url: string;
        title: string;
        price: number;
        resale_commission: string;
        cashback: string;
        available_stock: number;
        merchant: {
          name: string;
          profile_image_url: string;
        };
        data: ShoplistProduct;
      }[];
      paginatorInfo?: PaginatorInfo;
    }>({
      data: [],
    });

    const itemsSwitch = reactive([
      {
        name: "Offers",
        key: "offers",
      },
      {
        name: "Products",
        key: "products",
      },
    ]);

    const setShoplists = () => {
      shoplists.data.length = 0;

      ManyShoplists.value?.data?.forEach((item) => {
        if (item) {
          const mediaData = getMediaBox(item);

          shoplists.data.push(mediaData);
        }
      });
      shoplists.paginatorInfo = ManyShoplists.value?.paginatorInfo;
    };

    const setProducts = async () => {
      products.data.length = 0;

      const setProduct = (product: ShoplistProduct) => {
        const generalReward = product.rewards.filter(
          (item) => item.quantity == "-1"
        );
        return {
          base_image_url: product.primary_image_url,
          title: product.name,
          price: parseFloat(product.regular_price),
          available_stock: product.stock_quantity,
          cashback: generalReward[0]?.percentage,
          merchant: {
            name: product.business?.business_name || "",
            profile_image_url: product.business?.photo_url || "",
          },
          resale_commission: product.affiliate_commission || "",
          data: product,
        };
      };

      ManyShoplistProducts.value?.data?.forEach((product) => {
        products.data.push(setProduct(product));
      });
      products.paginatorInfo = ManyShoplistProducts.value?.paginatorInfo;
    };

    const getShoplists = () => {
      const searchString = searchQuery.value
        ? `AND: {
        column: NAME
        operator: LIKE
        value: "%${searchQuery.value}%"
      }`
        : "";

      isLoading.value = true;
      Logic.Shoplist.GetShoplistCollections(
        1,
        15,
        "CREATED_AT",
        "DESC",
        `{
                        column: BUSINESS_PROFILE_ID
                        operator: EQ
                        value: ${Logic.Auth.GetCurrentBusiness()?.id}
                        ${searchString}
                      }`,
        true
      ).then((data: any) => {
        isLoading.value = false;

        if (data) {
          ManyShoplists.value = data;
          setShoplists();
        }
      });
    };

    const fetchMoreShoplists = (nextPage: number) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      return Logic.Shoplist.GetShoplistCollections(
        nextPage,
        15,
        "CREATED_AT",
        "DESC",
        `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`,
        true
      ).then((data: any) => {
        isLoading.value = false;

        if (data) {
          const existingData = JSON.parse(JSON.stringify(ManyShoplists.value));

          if (existingData.data && data.data) {
            existingData.data = existingData.data.concat(data.data);
            existingData.paginatorInfo = data.paginatorInfo;
          }

          ManyShoplists.value = existingData;

          return true;
        }
      });
    };

    const getProducts = () => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      isLoading.value = true;
      Logic.Shoplist.GetShoplistProducts(
        1,
        15,
        "CREATED_AT",
        "DESC",
        `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.AuthUser?.profile?.business?.id}
                      ${searchString}
                    }`,
        true
      ).then((data: any) => {
        isLoading.value = false;

        if (data) {
          ManyShoplistProducts.value = data;
          setProducts();
        }
      });
    };

    const fetchMoreProducts = (nextPage: number) => {
      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";

      return Logic.Shoplist.GetShoplistProducts(
        nextPage,
        15,
        "CREATED_AT",
        "DESC",
        `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      ${searchString}
                    }`,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(ManyShoplistProducts.value)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            ManyShoplistProducts.value = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const selectShoplist = (data: any) => {
      if (props.action) {
        props.action({
          type: "shoplist",
          data: data,
        });
      }
    };

    const selectProduct = (data: any) => {
      if (props.action) {
        props.action({
          type: "product",
          data: data,
        });
      }
    };

    watch(selectedSwitch, () => {
      if (selectedSwitch.value == "offers") {
        getShoplists();
      } else {
        getProducts();
      }
    });

    watch(ManyShoplists, () => {
      setShoplists();
    });

    watch(ManyShoplistProducts, () => {
      setProducts();
    });

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        if (selectedSwitch.value == "offers") {
          getShoplists();
        } else {
          getProducts();
        }
      }, 400);
    });

    onMounted(() => {
      selectedShoplist.value = props.data?.selected || "";
      getShoplists();
    });

    return {
      searchQuery,
      isLoading,
      ManyShoplists,
      selectedShoplist,
      shoplists,
      itemsSwitch,
      selectedSwitch,
      products,
      selectShoplist,
      fetchMoreShoplists,
      fetchMoreProducts,
      selectProduct,
    };
  },
});
</script>
