<template>
  <span
    :class="`px-3 py-[2px] border-[1px] text-[11px] xs:text-[8px]   whitespace-nowrap rounded-[28px]  ${
      color == 'green'
        ? 'bg-success-50 dark:!bg-success-main dark:!text-success-50  border-success-50 text-success-500'
        : ''
    } first-letter:
${
  color == 'red'
    ? 'bg-error-50   border-error-50 text-error-500 dark:!bg-error-main dark:!text-error-50'
    : ''
}
${
  color == 'purple'
    ? 'bg-primary-50   border-primary-50 text-primary-500 dark:!bg-primary-main dark:!text-primary-50'
    : ''
}
${
  color == 'blue'
    ? '!bg-blue-50   border-blue-50 !text-blue-500 dark:!bg-blue-main dark:!text-blue-50'
    : ''
}
${
  color == 'yellow'
    ? 'bg-secondary-50   border-secondary-50 text-secondary-500 dark:!bg-secondary-main dark:!text-secondary-50'
    : ''
} 

${color == 'outline-white' ? 'bg-transparent   border-white text-white' : ''} 

${color == 'outline-black' ? 'bg-transparent   border-grey-700 text-grey-700' : ''} 


${customClass}`"
  >
    <slot />
  </span>
</template>
<script lang="ts">
export default {
  props: {
    color: {
      type: String as () =>
        | "green"
        | "red"
        | "yellow"
        | "blue"
        | "purple"
        | "outline-white"
        | "outline-black",
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  name: "AppBadge",
};
</script>
