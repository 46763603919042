import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-end"
}
const _hoisted_5 = { class: "w-full flex flex-col space-y-2 pb-4 xs:pb-2" }
const _hoisted_6 = { class: "w-full flex flex-row space-x-1 justify-end flex-grow" }
const _hoisted_7 = { class: "font-semibold text-primary-500 dark:!text-primary-200 px-1" }
const _hoisted_8 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Buy Electricity' }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_button, { class: "!bg-grey-50 dark:!bg-primary-500 flex flex-row space-x-2 items-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: `beneficiary${
                _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-light'
              }`,
                  "custom-class": "!h-[12px]"
                }, null, 8, ["name"]),
                _createVNode(_component_app_normal_text, { class: "!text-primary-500 dark:!text-primary-100" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Beneficiaries ")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_form_wrapper, {
              parentRefs: _ctx.parentRefs,
              ref: "formComp",
              class: "w-full flex flex-col space-y-4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_app_select, {
                  placeholder: 'Select Biller',
                  hasTitle: true,
                  paddings: 'py-3 px-3',
                  options: _ctx.billersOptons,
                  onOnOptionSelected: _ctx.handleBillerSelected,
                  updateKey: _ctx.electricityFormData.biller_name,
                  "auto-complete": true,
                  "custom-class": "dark:!bg-grey-900",
                  modelValue: _ctx.electricityFormData.biller_name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.electricityFormData.biller_name) = $event)),
                  theme: _ctx.Logic.Common.currentAppTheme()
                }, {
                  title: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" Biller ")
                  ])),
                  _: 1
                }, 8, ["options", "onOnOptionSelected", "updateKey", "modelValue", "theme"]),
                _createVNode(_component_app_select, {
                  placeholder: 'Select payment plan',
                  hasTitle: true,
                  paddings: 'py-3 px-3',
                  options: _ctx.billerItems,
                  updateKey: _ctx.electricityFormData.payment_code,
                  modelValue: _ctx.electricityFormData.payment_code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.electricityFormData.payment_code) = $event)),
                  "custom-class": "dark:!bg-grey-900",
                  theme: _ctx.Logic.Common.currentAppTheme()
                }, {
                  title: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Payment item ")
                  ])),
                  _: 1
                }, 8, ["options", "updateKey", "modelValue", "theme"]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter meter number',
                    hasTitle: true,
                    type: "tel",
                    modelValue: _ctx.meterNumber,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.meterNumber) = $event)),
                    rules: [_ctx.FormValidations.RequiredRule],
                    "update-value": _ctx.meterNumber,
                    name: "Meter number",
                    "content-rule": {
                addAfterCount: 0,
                characterToAdd: ``,
                max: 11,
              }
                  }, {
                    title: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Meter number ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "rules", "update-value"]),
                  (_ctx.meterNumber.length >= 7)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_app_normal_text, {
                          class: _normalizeClass(
                  _ctx.isResolvingNumber
                    ? '!text-secondary-main dark:!text-secondary-100'
                    : _ctx.resolveIsError
                    ? '!text-red-500 dark:!text-red-300'
                    : '!text-primary-500 dark:!text-primary-300'
                )
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.isResolvingNumber
                    ? "Validating number..."
                    : _ctx.resolveIsError
                    ? "Unable to resolve meter number"
                    : _ctx.electricityBiller), 1)
                          ]),
                          _: 1
                        }, 8, ["class"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    rules: [_ctx.FormValidations.RequiredRule],
                    ref: "amount",
                    name: "amount",
                    "update-value": _ctx.amountValue,
                    modelValue: _ctx.amountValue,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.amountValue) = $event))
                  }, {
                    title: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "dark:!text-gray-200" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" ₦ ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rules", "update-value", "modelValue"]),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_app_icon, {
                      name: 'cashback-icon',
                      customClass: 'h-[20px] dark:!text-white'
                    }),
                    _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap' }, {
                      default: _withCtx(() => [
                        _cache[10] || (_cache[10] = _createTextVNode(" You'll get ")),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.Logic.Common.convertToMoney(
                      _ctx.getProductPoint(
                        "electricity",
                        parseFloat(_ctx.amountValue?.replace(/,/g, "")).toString()
                      ),
                      false,
                      ""
                    )) + " Points", 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }, 8, ["parentRefs"]),
            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, { class: "pt-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "bg-color": 'bg-primary-400',
                  "text-color": 'text-white',
                  class: _normalizeClass(_ctx.formIsValid ? 'w-full' : 'opacity-50'),
                  onClick: _ctx.purchaseElectricity
                }, {
                  default: _withCtx(() => _cache[12] || (_cache[12] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["class", "onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}