import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-3 w-full" }
const _hoisted_2 = { class: "flex justify-between w-full" }
const _hoisted_3 = { class: "flex justify-between w-full" }
const _hoisted_4 = { class: "flex justify-between w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_app_badge = _resolveComponent("app-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppNormalText, null, {
        default: _withCtx(() => [
          _createTextVNode("Order ID: " + _toDisplayString($props.orderId), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_app_badge, {
        color: 'green',
        class: _normalizeClass('capitalize')
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.status), 1)
        ]),
        _: 1
      })
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[1px] bg-grey-50 w-full" }, null, -1)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
        default: _withCtx(() => [
          _createTextVNode(" N" + _toDisplayString($props.amount), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_AppNormalText, {
        color: 'text-grey-700',
        "custom-class": 'gap-2'
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.date) + " ", 1),
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "h-1 w-1 bg-grey-100 rounded-full" }, null, -1)),
          _createTextVNode(" " + _toDisplayString($props.time), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AppNormalText, { color: 'text-primary-500' }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.totalItems) + " Items ", 1)
        ]),
        _: 1
      })
    ])
  ]))
}