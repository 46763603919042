import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-[165px] mdlg:!w-[240px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-1 items-center justify-center" }
const _hoisted_3 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_4 = { class: "w-full flex flex-row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "w-[165px] mdlg:!w-[240px] h-fit cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute(`/brand?uuid=${_ctx.item.uuid}`)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", null, [
            _createVNode(_component_app_image_loader, {
              "photo-url": _ctx.item.photo_url || '',
              class: _normalizeClass('!w-[80px] !h-[80px] !rounded-full')
            }, null, 8, ["photo-url"])
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_app_normal_text, { customClass: '!text-sm !font-semibold !line-clamp-1' }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.business_name), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_normal_text, { customClass: '!text-gray-500 !text-[12px]' }, {
                default: _withCtx(() => [
                  _createTextVNode(" @" + _toDisplayString(_ctx.item.business_tag), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_app_icon, {
                name: 'verified',
                class: "!h-[14px]"
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              onClick: _withModifiers(_ctx.followUnfollowBusinessInner, ["stop"]),
              customClass: `!w-auto !px-4 !py-1 !text-[11px] ${
            _ctx.item.is_following
              ? '!bg-primary-50 !text-primary-main'
              : '!bg-primary-main !text-white'
          }`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.is_following ? "Following" : "Follow"), 1)
              ]),
              _: 1
            }, 8, ["onClick", "customClass"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}