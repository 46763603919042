<template>
  <subpage-layout :title="'Notifications'" :hasPadding="false">
    <div class="w-full flex flex-col space-y-2 h-full flex-grow relative">
      <div class="w-full flex flex-col space-y-4 pt-3">
        <app-virtual-scroller
          :data="notifications.data"
          :pagination="notifications.paginatorInfo"
          :fetchMore="fetchMoreNotifications"
        >
          <template #item-content="{ index, item }">
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-col px-4 space-y-1">
                <app-normal-text
                  :customClass="'uppercase !font-semibold !text-[11px]'"
                >
                  {{ index }}
                </app-normal-text>
              </div>
              <div
                class="w-full bg-white px-4 py-4 flex flex-row space-x-4 items-start dark:bg-black"
                v-for="(eachitem, index) in item"
                :key="index"
              >
                <div class="w-[46px] relative">
                  <app-icon
                    :name="'notification-bell'"
                    :customClass="'h-[45px]'"
                  >
                  </app-icon>
                  <span
                    v-if="eachitem.is_read"
                    class="absolute top-0 right-[-1%] border-[1.5px] border-white bg-error-500 rounded-full h-[12px] w-[12px]"
                  >
                  </span>
                </div>
                <div class="w-full flex flex-col space-y-1">
                  <app-normal-text :customClass="'!font-bold'">
                    {{ eachitem.title }}
                  </app-normal-text>
                  <app-normal-text
                    :color="'text-grey-700'"
                    :customClass="'!text-left'"
                  >
                    {{ eachitem.body }}
                  </app-normal-text>
                  <div class="flex flex-row space-x-1 items-center pt-2">
                    <app-normal-text
                      :color="'text-primary-main dark:!text-primary-300'"
                      @click="viewNotification(eachitem)"
                      class="underline cursor-pointer"
                    >
                      View details
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #skeleton-loaders>
            <div class="w-full flex flex-col space-y-2 px-4">
              <div class="w-[40%] h-[10px] rounded skeleton"></div>
              <div
                class="w-full flex flex-col space-y-2 skeleton h-[60px] rounded-[10px]"
              ></div>
            </div>
          </template>
        </app-virtual-scroller>
      </div>
      <div
        class="w-full h-full flex flex-col items-center justify-center"
        v-if="Notifications?.data?.length == 0"
      >
        <app-empty-state
          :title="'No Notifications Yet'"
          :subTitle="'Your notifications will show up here'"
        />
      </div>

      <div class="h-[100px]"></div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import {
  AppIcon,
  AppNormalText,
  AppEmptyState,
  AppVirtualScroller,
} from "../../../components";
import { Logic } from "../../../index";
import SubpageLayout from "../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppEmptyState,
    AppVirtualScroller,
    SubpageLayout,
  },
  name: "NotificationsPage",
  setup() {
    const Notifications = ref(Logic.User.Notifications);

    const selectedFilter = ref("all");

    const UnreadNotification = ref(Logic.Notification.UnreadNotification);

    const allNotifications = reactive<
      {
        title: string;
        body: string;
        is_read: boolean;
        created_at: any;
        day: string;
        url: string;
        uuid: string;
      }[]
    >([]);

    const filters = reactive([
      {
        title: "All",
        id: "all",
      },
      {
        title: "Unread",
        id: "unread",
        unread: 0,
      },
    ]);

    const notifications = reactive<any>({
      data: {},
      paginatorInfo: {
        total: 0,
        currentPage: 0,
        lastPage: 0,
      },
    });

    const fetchMoreNotifications = (nextPage: number) => {
      return Logic.User.GetNotifications(
        nextPage,
        notifications.paginatorInfo.perPage,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.User.Notifications)
            );
            existingData.data = existingData.data.concat(response.data);
            existingData.paginatorInfo = response.paginatorInfo;

            Logic.User.Notifications = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const setNotifications = () => {
      allNotifications.length = 0;

      notifications.data = {};

      Notifications.value?.data.forEach((notification) => {
        const notificationData = {
          title: notification.title,
          body: notification.body,
          is_read: !notification.unread,
          created_at: notification.created_at,
          day: Logic.Common.momentInstance(notification.created_at).format(
            "DD-MM-YYYY"
          ),
          url:
            notification.extra_url?.replace(
              "https://app.getshoppoint.com",
              ""
            ) || "/",
          uuid: notification.uuid,
        };

        allNotifications.push(notificationData);
      });

      notifications.paginatorInfo = Notifications.value?.paginatorInfo;

      const groupedData = Logic.Common.groupArrayBy(allNotifications, "day");

      for (const key in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
          const data = groupedData[key];

          notifications.data[Logic.Common.timeFromNow(data[0].created_at)] =
            data;
        }
      }

      allNotifications?.forEach((data: any) => {
        if (data.is_read) {
          Logic.Notification.MarkNotificationAsRead(data.uuid);
        }
      });
    };

    const viewNotification = (data: any) => {
      Logic.Notification.MarkNotificationAsRead(data.uuid);
      Logic.Common.GoToRoute(`${data.url}`);
    };

    watch(selectedFilter, () => {
      setNotifications();
    });

    watch(Notifications, () => {
      filters[1].unread = UnreadNotification.value;
      setNotifications();
    });

    const setupPage = () => {
      localStorage.setItem(
        "last_notification_count",
        Notifications.value?.paginatorInfo.total.toString() || "0"
      );
      Logic.Notification.UnreadNotification = 0;
      setNotifications();
      filters[1].unread = UnreadNotification.value;
    };

    onMounted(() => {
      Logic.User.watchProperty("Notifications", Notifications);
      Logic.Notification.watchProperty(
        "UnreadNotification",
        UnreadNotification
      );
      setupPage();
    });

    return {
      filters,
      selectedFilter,
      notifications,
      Notifications,
      Logic,
      UnreadNotification,
      viewNotification,
      fetchMoreNotifications,
      setupPage,
    };
  },
});
</script>
