import { OperationResult } from 'urql'
import { BaseApiService } from './common/BaseService'
import {
  PointEarned,
  CommercialBank,
  MutationAddUserBankArgs,
  MutationCheckTransferStatusArgs,
  MutationCreatePaymentRequestArgs,
  MutationFundWalletArgs,
  MutationGenerateWalletAccountArgs,
  MutationInitiateTransferToBankArgs,
  MutationInitiateTransferToShoppointUserArgs,
  MutationResolveBankAccountArgs,
  MutationUpdatePaymentRequestArgs,
  PaymentRequest,
  PointTransaction,
  Transaction,
  UserBank,
  UserCard,
  Wallet,
  PointEarnedPaginator,
  PaymentBeneficiary,
  ResolveBankAccountResponse,
  ExchangeRate,
  DynamicFundingAccount,
  MutationEnrolledUserCardArgs,
  EnrolledCard,
  EnrolledCardTransactionPaginator,
  MutationSavePaymentTransactionArgs,
  PointTransactionPaginator,
} from '../gql/graphql'
import { TransactionPaginator } from '../../dist/esm/gql/graphql'

export default class WalletApi extends BaseApiService {
  public ChargeAccessCode = () => {
    const requestData = `
            query ChargeAccessCode {
                ChargeAccessCode 
                `

    const response: Promise<OperationResult<{
      ChargeAccessCode: string
    }>> = this.query(requestData, {})

    return response
  }

  public GetTransactionsByWalletId = (
    wallet_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
  ) => {
    const requestData = `
    query GetTransactionsByWalletId($wallet_id: Int!, $page: Int!, $count: Int!) {
      GetTransactionsByWalletId(wallet_id: $wallet_id, page: $page, first: $count, orderBy: {column: ${
        orderType ? orderType : 'CREATED_AT'
      }, order: ${order}}) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          total
          lastPage
        }
        data {
          id
            uuid
            dr_or_cr
            currency
            amount
            wallet_balance
            point_balance
            charge_id
            chargeable_type
            description
            status
            charges
            reference
            extra_data
            gateway
            created_at
            updated_at
          }
    }
  }`

    const response: Promise<OperationResult<{
      GetTransactionsByWalletId: TransactionPaginator
    }>> = this.query(requestData, { wallet_id, page, count })

    return response
  }

  public GetManyPointTransaction = (
    wallet_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
  ) => {
    const requestData = `
    query GetPointTransactionsByWalletId($wallet_id: Int!, $page: Int!, $count: Int!) {
      GetPointTransactionsByWalletId(wallet_id: $wallet_id, page: $page, first: $count, orderBy: {column: ${
        orderType ? orderType : 'CREATED_AT'
      }, order: ${order}}) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          total
        }
        data {
          id
          uuid
          dr_or_cr
          amount
          point_balance
          charge_id
          chargeable_type
          description
          status
          reference
          extra_data
          created_at
        }
      }
    }`

    const response: Promise<OperationResult<{
      GetPointTransactionsByWalletId: PointTransactionPaginator
    }>> = this.query(requestData, { wallet_id, page, count })

    return response
  }

  public GetTransaction = (uuid: string) => {
    const requestData = `
    query GetTransaction($uuid: String!) {
        Transaction(uuid: $uuid) {
          id
          uuid
          dr_or_cr
          currency
          amount
          wallet_balance
          point_balance
          charge_id
          chargeable_type
          description
          status
          charges
          reference
          extra_data
          gateway
          created_at
        }
      }`

    const response: Promise<OperationResult<{
      Transaction: Transaction
    }>> = this.query(requestData, { uuid })

    return response
  }

  public GetPointTransaction = (uuid: string) => {
    const requestData = `
    query GetTransaction($uuid: String!) {
        PointTransaction(uuid: $uuid) {
          id
          uuid
          dr_or_cr
          amount
          point_balance
          charge_id
          chargeable_type
          description
          status
          reference
          extra_data
          created_at
        }
      }
                `

    const response: Promise<OperationResult<{
      PointTransaction: PointTransaction
    }>> = this.query(requestData, { uuid })

    return response
  }

  public GetUserPaymentRequests = () => {
    const requestData = `
    query GetUserPaymentRequests {
        GetUserPaymentRequests {
          id
          uuid
          amount
          narration
          status
          user {
            full_name
            uuid
            username
            profile {
              photo_url
            }
          }
          receiver_user {
            id
            uuid
            username
            full_name
            profile {
              photo_url
            }
          }
          created_at
          updated_at
        }
      }
                `

    const response: Promise<OperationResult<{
      GetUserPaymentRequests: PaymentRequest[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetMastercardAuthToken = () => {
    const requestData = `
		query GetMastercardAuthToken() {
			GetMastercardAuthToken
		  }
		`
    const response: Promise<OperationResult<{
      GetMastercardAuthToken: String
    }>> = this.query(requestData, {})
    return response
  }

  public GetPaymentBeneficiary = () => {
    const requestData = `
    query GetPaymentBeneficiaries {
      GetPaymentBeneficiaries {
        id
        uuid
        type
        created_at
        updated_at
        shpt_user{
          full_name
          username
          uuid
          profile{
            photo_url
          }
        }
        metadata {
          shoppoint_user_uuid
          narration
          user_id
          beneficiary_first_name
          beneficiary_last_name
          beneficiary_name
          beneficiary_type
          beneficiary_account_no
          beneficiary_bank_code
          shoppoint_username
        }
      }
    }`

    const response: Promise<OperationResult<{
      GetPaymentBeneficiaries: PaymentBeneficiary[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetPointEarned = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    hasUser = '',
  ) => {
    const requestData = `
        query GetPointEarned($page: Int!, $count: Int!){
          GetPointEarned(first: $count, page: $page,
                orderBy: {column: ${
                  orderType ? orderType : 'CREATED_AT'
                }, order: ${order}}
                   ${whereQuery ? `where: ${whereQuery}` : ''} ${
      hasUser ? `hasUser: ${hasUser}` : ''
    } ){
              paginatorInfo{
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                total
                lastPage
              }
              data{
                id
                uuid
                money_amount
                points
                game_points
                description
                scanned_receipt{
                  uuid
                  amount
                  invoice_no
                  category
                  main_category
                  receipt_date
                  items
                  merchant_name
                  status
                  image_url
                  created_at
                }
                sale {
                  uuid
                  total_amount
                  transaction{
                    uuid
                  }
                  point_transaction{
                    uuid
                  }
                  product{
                    name
                    description
                    image_url
                  }
                  booked_flight {
                    id
                    uuid
                    status
                    currency
                    amount
                    reference
                    extra_data
                  }
                }
                order {
                  id
                  uuid
                  total_cashback
                  total_discount
                  delivery_fee
                  user_delivery {
                    title
                    address
                    phone
                  }
                    business {
                      id
                      uuid
                      business_name
                      business_tag
                      photo_url
                    }
                  collection{
                    id
                    uuid
                    name
                  }
                  shipping_info {
                    courier {
                        courier_id
                        courier_name
                        courier_image
                        service_code
                        pickup_eta
                        pickup_eta_time
                        delivery_eta
                        pickup_eta_time
                        info
                        currency
                        ratings
                        total
                      }
                    }
                  items {
                    id
                    unit_price
                    total_price
                    quantity
                    product {
                      id
                      uuid
                      name
                      category {
                        id
                        parent_category_id
                        name
                      }
                      regular_price
                      primary_image_url
                    }
                  }
                  cashback
                  commission
                  total_amount
                  payment_status
                  status
                  updated_at
                }
                transaction{
                  amount
                  description
                  extra_data
                }
                event_slug
                claimed
                processing_status
                entity_uuid
                processed_at
                expire_at
                created_at
                updated_at
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      GetPointEarned: PointEarnedPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetSinglePointEarned = (uuid: string) => {
    const requestData = `
    query PointEarned($uuid: String!) {
      PointEarned(uuid: $uuid) {
        id
        uuid
        money_amount
        points
        game_points
        description
        event_slug
        claimed
        processing_status
        entity_uuid
        processed_at
        expire_at
        created_at
        updated_at
        }
      }
      `

    const response: Promise<OperationResult<{
      PointEarned: PointEarned
    }>> = this.query(requestData, { uuid })

    return response
  }

  public GetUserEnrolledCards = (user_id: number) => {
    const requestData = `
    query GetUserEnrolledCards($user_id: Int!) {
      GetUserEnrolledCards(user_id: $user_id) {
        id
        uuid
        name
        issuer
        is_active
        expiry_date
        last_4_digits
        first_6_digits
        created_at
        updated_at
      }
    }
      `

    const response: Promise<OperationResult<{
      GetUserEnrolledCards: EnrolledCard[]
    }>> = this.query(requestData, { user_id })

    return response
  }

  public GetUserWallet = (type: string) => {
    const requestData = `
    query GetUserWallet($type: String!) {
      GetUserWallet(type: $type) {
        id
        uuid
        total_balance
        point_balance
        cash_point_balance
        banks {
          id
          uuid
          bank_name
          account_no
          is_default
          sub_business_id
          meta_data {
            accountName
            name
          }
        }
        created_at
        updated_at
      }
    }
      `

    const response: Promise<OperationResult<{
      GetUserWallet: Wallet
    }>> = this.query(requestData, { type })

    return response
  }

  public GetEnrolledCardTransations = (
    enrolled_card_id: number,
    page: number,
    count: number,
  ) => {
    const requestData = `
    query GetEnrolledCardTransations($enrolled_card_id: Int!) {
      GetEnrolledCardTransations(
        enrolled_card_id: $enrolled_card_id
        page: ${page}
        first: ${count}
      ) {
        paginatorInfo {
          count
          hasMorePages
          currentPage
          total
        }
        data {
          id
          uuid
          amount
          currency
          last_4_digits
          transaction_date_time
          city
          category
          category
          reference
          created_at
        }
      }
    }
      `

    const response: Promise<OperationResult<{
      GetEnrolledCardTransations: EnrolledCardTransactionPaginator
    }>> = this.query(requestData, { enrolled_card_id })

    return response
  }

  public CommercialBanks = () => {
    const requestData = `
    query CommercialBanks {
        CommercialBanks {
          name
          code
        }
      }
                `

    const response: Promise<OperationResult<{
      CommercialBanks: CommercialBank[]
    }>> = this.query(requestData, {})

    return response
  }

  public AddUserCard = (reference: string) => {
    const requestData = `
    mutation AddUserCard($reference: String!) {
        AddUserCard(reference: $reference) {
          id
          uuid
          meta_data {
            first_6digits
            last4
            card_type
            bank
            brand
            exp_year
            exp_month
          }
        }
      }
		`
    const response: Promise<OperationResult<{
      AddUserCard: UserCard
    }>> = this.mutation(requestData, {
      reference,
    })

    return response
  }

  public AddUserBank = (data: MutationAddUserBankArgs) => {
    const requestData = `
    mutation AddUserBank($account_no: String!, $bank_code: String!, $account_type: String!) {
        AddUserBank(
          account_no: $account_no
          bank_code: $bank_code
          account_type: $account_type
        ) {
          id
          uuid
          meta_data {
            accountName
            firstName
            lastName
          }
          bank_code
          bank_name
          account_no
          is_verified
          created_at
        }
      }
		`
    const response: Promise<OperationResult<{
      AddUserBank: UserBank
    }>> = this.mutation(requestData, data)

    return response
  }

  public SetDefaultBank = (bank_uuid: string) => {
    const requestData = `
    mutation SetDefaultBank($bank_uuid: String!) {
        SetDefaultBank(bank_uuid: $bank_uuid) 
      }
		`

    const response: Promise<OperationResult<{
      SetDefaultBank: boolean
    }>> = this.mutation(requestData, { bank_uuid })

    return response
  }

  public CreatePaymentRequest = (data: MutationCreatePaymentRequestArgs) => {
    const requestData = `
    mutation CreatePaymentRequest($receiver_user_uuid: String!, $amount: String!, $narration: String!) {
        CreatePaymentRequest(
          receiver_user_uuid: $receiver_user_uuid
          amount: $amount
          narration: $narration
        ) {
          id
          uuid
          amount
          narration
          status
          user {
            full_name
            uuid
            username
            profile {
              photo_url
            }
          }
          receiver_user {
            id
            uuid
            full_name
            username
            profile {
              photo_url
            }
          }
          created_at
        }
      }
		`
    const response: Promise<OperationResult<{
      CreatePaymentRequest: PaymentRequest
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdatePaymentRequest = (data: MutationUpdatePaymentRequestArgs) => {
    const requestData = `
    mutation UpdatePaymentRequest($payment_request_uuid: String!, $status: String!, $use_point: Boolean!, $account_type: String!, $card_uuid: String) {
        UpdatePaymentRequest(
          payment_request_uuid: $payment_request_uuid
          status: $status
          use_point: $use_point
          account_type: $account_type
          card_uuid: $card_uuid
        ){
          id
          uuid
          money_amount
          points
          game_points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
        }
      }
		`
    const response: Promise<OperationResult<{
      UpdatePaymentRequest: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public InitiateTransferToBank = (
    data: MutationInitiateTransferToBankArgs,
  ) => {
    const requestData = `
    mutation InitiateTransferToBank($amount: String!, $use_point: Boolean!, $account_type: String!, $narration: String!, $beneficiary_name: String!, $name_enquiry_session_id: String!, $beneficiary_account_no: String!, $beneficiary_bank_code: String!, $card_uuid: String, $save_beneficiary: Boolean) {
        InitiateTransferToBank(
          amount: $amount
          use_point: $use_point
          account_type: $account_type
          narration: $narration
          beneficiary_name: $beneficiary_name
          name_enquiry_session_id: $name_enquiry_session_id
          beneficiary_account_no: $beneficiary_account_no
          beneficiary_bank_code: $beneficiary_bank_code
          card_uuid: $card_uuid
          save_beneficiary: $save_beneficiary
        ) {
          id
          uuid
          money_amount
          points
          game_points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
      }
    }
		`
    const response: Promise<OperationResult<{
      InitiateTransferToBank: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public GetLatestExchangeRates = (base_currency: string) => {
    const requestData = `
      query GetLatestExchangeRates($base_currency: String!) {
        GetLatestExchangeRates(base_currency: $base_currency) {
          id
          uuid
          from
          to
          unit_cost
          created_at
          updated_at
        }
      }
      `
    const response: Promise<OperationResult<{
      GetLatestExchangeRates: ExchangeRate[]
    }>> = this.query(requestData, { base_currency })

    return response
  }

  public InitiateTransferToShoppointUser = (
    data: MutationInitiateTransferToShoppointUserArgs,
  ) => {
    const requestData = `
    mutation InitiateTransferToShoppointUser($amount: String!, $use_point: Boolean!, $account_type: String!, $receiver_user_uuid: String!, $card_uuid: String, $save_beneficiary: Boolean, $narration: String!, $is_business: Boolean) {
        InitiateTransferToShoppointUser(
          amount: $amount
          use_point: $use_point
          account_type: $account_type
          receiver_user_uuid: $receiver_user_uuid
          card_uuid: $card_uuid
          save_beneficiary: $save_beneficiary
          narration: $narration
          is_business: $is_business
        ){
          id
          uuid
          money_amount
          points
          game_points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
        }
      }
		`
    const response: Promise<OperationResult<{
      InitiateTransferToShoppointUser: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public FundWallet = (data: MutationFundWalletArgs) => {
    const requestData = `
    mutation FundWallet($card_uuid: String, $amount: Float!, $currency: String!) {
        FundWallet(amount: $amount, card_uuid: $card_uuid, currency: $currency) {
          id
          uuid
          total_balance
          point_balance
          cash_per_point
          transactions {
            uuid
            id
            dr_or_cr
            currency
            amount
            wallet_balance
            point_balance
            charge_id
            chargeable_type
            status
            charges
            reference
            created_at
          }
          cash_point_balance
        }
      }
		`

    const response: Promise<OperationResult<{
      FundWallet: Wallet
    }>> = this.mutation(requestData, data)
    return response
  }

  public ResolveBankAccount = (data: MutationResolveBankAccountArgs) => {
    const requestData = `
    mutation ResolveBankAccount($account_no: String!, $bank_code: String!, $wallet_uuid: String!) {
        ResolveBankAccount(account_no: $account_no, bank_code: $bank_code, wallet_uuid: $wallet_uuid) {
          account_number
          account_name
          session_id
        }
      }
		`
    const response: Promise<OperationResult<{
      ResolveBankAccount: ResolveBankAccountResponse
    }>> = this.mutation(requestData, data)
    return response
  }

  public GenerateWalletAccount = (data: MutationGenerateWalletAccountArgs) => {
    const requestData = `
    mutation GenerateWalletAccount($account_type: String!, $bvn: String!, $date_of_birth: String, $business_name: String) {
        GenerateWalletAccount(account_type: $account_type, bvn: $bvn, date_of_birth: $date_of_birth, business_name: $business_name) 
      }
		`
    const response: Promise<OperationResult<{
      GenerateWalletAccount: boolean
    }>> = this.mutation(requestData, data)
    return response
  }

  public CheckTransferStatus = (data: MutationCheckTransferStatusArgs) => {
    const requestData = `
		mutation CheckTransferStatus($existing_transactions: Int!, $account_type: String!) {
			CheckTransferStatus(existing_transactions: $existing_transactions, account_type: $account_type)
		  }
		`
    const response: Promise<OperationResult<{
      CheckTransferStatus: Boolean
    }>> = this.mutation(requestData, data)
    return response
  }

  public EnrolledUserCard = (data: MutationEnrolledUserCardArgs) => {
    const requestData = `
		mutation EnrolledUserCard($provider_reference: String!, $name: String!, $issuer: String!) {
			EnrolledUserCard(provider_reference: $provider_reference, name: $name, issuer: $issuer) {
         id
         uuid
         name
         issuer
         is_active
         created_at
      }
		  }
		`
    const response: Promise<OperationResult<{
      EnrolledUserCard: EnrolledCard
    }>> = this.mutation(requestData, data)
    return response
  }

  public DeleteUserCard = (card_uuid: string) => {
    const requestData = `
		mutation DeleteUserCard($card_uuid: String!) {
			DeleteUserCard(card_uuid: $card_uuid)
		  }
		`
    const response: Promise<OperationResult<{
      DeleteUserCard: boolean
    }>> = this.mutation(requestData, {
      card_uuid,
    })

    return response
  }

  public SubscribeToNewPointGain = (
    handleSubscription: any,
    handleSubscribed: any,
  ) => {
    const requestData = `
	subscription NewPointGain {
		NewPointGain{
		  uuid
		  id
		  points
      category
      extra_id
	  }
  }`

    const response = this.subscription(
      requestData,
      {},
      handleSubscription,
      handleSubscribed,
    )

    return response
  }

  public GenerateFundingAccount = (amount: string) => {
    const requestData = `
	mutation GenerateFundingAccount($amount: String!) {
		GenerateFundingAccount(amount: $amount) {
		  id
		  uuid
		  reference
		  account_number
		  resolved
		  amount
		}
	  }
	`
    const response: Promise<OperationResult<{
      GenerateFundingAccount: DynamicFundingAccount
    }>> = this.mutation(requestData, { amount })

    return response
  }

  public SavePaymentTransaction = (
    data: MutationSavePaymentTransactionArgs,
  ) => {
    const requestData = `
	mutation SavePaymentTransaction(
    $amount: String!
    $provider: String!
    $reference: String!
    $wallet_uuid: String! 
    $type: String!
    $narration: String!
  ) {
		SavePaymentTransaction(amount: $amount, provider: $provider, reference: $reference, wallet_uuid: $wallet_uuid, type: $type, narration: $narration)
	  }
	`
    const response: Promise<OperationResult<{
      SavePaymentTransaction: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public ResolveFundingAccount = (dynamic_funding_account_uuid: string) => {
    const requestData = `
	mutation ResolveFundingAccount($dynamic_funding_account_uuid: String!) {
		ResolveFundingAccount(dynamic_funding_account_uuid: $dynamic_funding_account_uuid) 
	  }
	`
    const response: Promise<OperationResult<{
      ResolveFundingAccount: boolean
    }>> = this.mutation(requestData, { dynamic_funding_account_uuid })

    return response
  }
}
