import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center pb-4 md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-[80%] px-4 items-center justify-center flex-col space-y-3 flex" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row py-4 px-4 rounded-[12px] bg-gray_50 items-center justify-center space-x-2"
}
const _hoisted_4 = { class: "flex flex-col justify-start" }
const _hoisted_5 = {
  key: 1,
  class: "w-full flex flex-col space-y-2 pt-2"
}
const _hoisted_6 = {
  key: 2,
  class: "h-[30px] pb-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_icon, {
      customClass: 'h-[90px]',
      name: _ctx.data.icon
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.data.large_title)
        ? (_openBlock(), _createBlock(_component_app_header_text, {
            key: 0,
            class: "!text-base"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.large_title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_app_normal_text, {
        class: "!text-[#664D7E]",
        customClass: `!text-center`
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.title), 1)
        ]),
        _: 1
      })
    ]),
    (_ctx.data.point)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_app_icon, {
              name: 'coin',
              customClass: 'h-[44px]',
              extension: 'png'
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_normal_text, { "custom-class": 'uppercase text-[10px]' }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" You have earned ")
              ])),
              _: 1
            }),
            _createVNode(_component_app_header_text, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.data.point) + " pts ", 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.data.buttons.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_app_button, {
            customClass: 'w-full',
            padding: 'py-3',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.data.buttons[0].action()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.buttons[0].label), 1)
            ]),
            _: 1
          }),
          (_ctx.data.buttons[1])
            ? (_openBlock(), _createBlock(_component_app_button, {
                key: 0,
                class: _normalizeClass('w-full !border-primary-500 !bg-transparent !border-[1px] !text-primary-500'),
                padding: 'py-3',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.data.buttons[1].action())),
                type: 'light'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.data.buttons[1].label), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6))
  ]))
}