import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_2 = {
  key: 0,
  class: "bg-white rounded-full h-[40px] w-[40px] flex flex-row items-center justify-center dark:bg-slate-300"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`w-full flex flex-row items-center space-x-3 px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[10px] justify-between ${_ctx.customClass}`)
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.data.icon)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_app_icon, {
                  name: _ctx.data.icon,
                  customClass: _ctx.data.icon_size
                }, null, 8, ["name", "customClass"])
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_app_normal_text, { customClass: ' !font-semibold' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.title), 1)
            ]),
            _: 1
          })
        ]),
        (_ctx.hasMasterLink)
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 0,
              name: 'chevron-right-grey',
              customClass: 'h-[13px] pr-2'
            }))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ], 2))
    : _createCommentVNode("", true)
}