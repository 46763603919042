<template>
  <app-wrapper :class="'!overflow-y-hidden'">
    <auth-layout
      @button-clicked="Logic.Common.GoToRoute('/auth/login')"
      @go-back="Logic.Common.goBack()"
      :go-back="true"
      :right-button="false"
      :hasBottomBorder="false"
    >
      <div class="w-full flex flex-col space-y-[1px]">
        <div class="w-full relative h-[8px] bg-gray-100 rounded-full">
          <div
            class="absolute top-0 left-0 w-[80%] h-[8px] bg-primary-500 rounded-full"
          ></div>
        </div>
        <div class="w-full flex flex-row justify-end">
          <app-normal-text class="!text-grey-500 dark:!text-grey-400">
            80%
          </app-normal-text>
        </div>
      </div>

      <div class="w-full flex flex-col pt-4 space-y-4">
        <div class="w-full flex flex-col">
          <app-header-text class="!text-left pb-4">
            How much of your monthly spend would you like to save in cashback?
          </app-header-text>

          <div class="w-full grid grid-cols-12 gap-3">
            <div
              :class="`w-full col-span-4 flex flex-col items-center cursor-pointer justify-center space-y-2 border-[1px] h-[50px] border-gray-300 rounded-[5px] px-2 py-2 ${
                selectedSavingsGroup === item.key
                  ? 'border-primary-500 !border-[1.5px]'
                  : ''
              }`"
              v-for="item in mothtlySavingsOptions"
              @click="selectedSavingsGroup = item.key"
              :key="item.key"
            >
              <app-header-text
                class="!text-[13.5px] !text-center !font-semibold"
              >
                {{ item.name }}
              </app-header-text>
            </div>
          </div>

          <app-normal-text class="!text-left !text-gray-500 pb-2 pt-3">
            Tips: Most people save 10% of their monthly spend.
          </app-normal-text>
        </div>

        <div
          class="w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black"
          style="
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
        >
          <AppButton
            :padding="'py-3'"
            :custom-class="`rounded-[40px] ${
              selectedSavingsGroup ? 'opacity-100' : 'opacity-50'
            }`"
            :bg-color="'bg-primary-main'"
            :text-color="'text-white'"
            @click="continueButtonClicked"
          >
            Continue
          </AppButton>
        </div>
      </div>
    </auth-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import { Logic } from "@shpt/logic";
import { AppNormalText, AppHeaderText, AppButton } from "@shpt/ui-components";

export default defineComponent({
  components: {
    AppWrapper,
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  name: "SaveCashbackPage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Set Cahshback Percentage Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Set Cahshback Percentage",
    });

    localStorage.setItem("user_onboarded", "true");

    const selectedSavingsGroup = ref("");

    const mothtlySavingsOptions = reactive([
      {
        key: "5",
        name: "5%",
      },
      {
        key: "10",
        name: "10%",
      },
      {
        key: "12",
        name: "12%",
      },
      {
        key: "15",
        name: "15%",
      },
      {
        key: "20",
        name: "20%",
      },
    ]);

    const continueButtonClicked = () => {
      localStorage.setItem("savings_target_amount", selectedSavingsGroup.value);
      Logic.Common.GoToRoute("/auth/onboarding/spending-categories");
    };

    return {
      Logic,
      mothtlySavingsOptions,
      selectedSavingsGroup,
      continueButtonClicked,
    };
  },
});
</script>
