import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`focus:outline-none shadow-sm rounded-full flex flex-row space-x-1 lg:text-sm mdlg:text-[12px] text-xs items-center justify-center ${_ctx.padding} ${
      _ctx.isDisabled ? 'bg-grey-100 text-grey-400' : _ctx.bgColor + ' ' + _ctx.textColor
    } ${_ctx.customClass}`)
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(`animate-spin mr-3 h-5 w-5  ${
          _ctx.type == 'light' ? 'text-primary-400' : 'text-white'
        }`),
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 24 24"
          }, _cache[0] || (_cache[0] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ]), 2))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}