import { OperationResult } from 'urql'
import { BaseApiService } from './common/BaseService'
import {
  AcceptQuoteForRefundRequest,
  Airports,
  AppliedRefundResponse,
  FlightBooking,
  FlightBookingPaginator,
  FlightRevalidationDetails,
  MutationRequestQuoteforrefundArgs,
  QueryGetFlightFareRulesArgs,
  Quoteforrefund,
  ScheduleChangeResponse,
  SearchFlightRequest,
  SingleFlight,
  SingleFlightFareRules,
  TripDetails,
  UpdateflightoptionsRequest,
} from '../gql/graphql'

export default class TravelApi extends BaseApiService {
  public SearchAirports = (query: string) => {
    const requestData = `
        query SearchAirports($query: String!) {
            SearchAirports(query: $query) {
              id
              uuid
              city_name
              icao_code
              iata_city_code
              iata_country_code
              iata_code
              name
            }
          }
                `

    const response: Promise<OperationResult<{
      SearchAirports: Airports[]
    }>> = this.query(requestData, { query })

    return response
  }

  public GetFlightBooking = (reference: string) => {
    const requestData = `
    query FlightBooking($reference: String!) {
      FlightBooking(
        reference: $reference
      ) {
        id
        uuid
        price_changed
        schedule_changed
        status
        reference
        extra_data
        time_limit
        currency
        amount
        schedule_change_ptr_id
        quote_for_refund_ptr_id
        applied_refund_prt_id
        has_refund
        total_refund
      }
    }`

    const response: Promise<OperationResult<{
      FlightBooking: FlightBooking
    }>> = this.query(requestData, {
      reference,
    })

    return response
  }

  public UserFlightBookings = (page: number, count: number, uuid: string) => {
    const requestData = `
    query GetUserFlightBookings($page: Int!, $count: Int!) {
      GetUserFlightBookings(
        first: $count
        page: $page
        hasUser: {column: UUID, operator: EQ, value: "${uuid}"}
      ) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          perPage
          total
        }
        data {
          id
          uuid
          price_changed
          schedule_changed
          status
          reference
          extra_data
          time_limit
          currency
          amount
          schedule_change_ptr_id
          quote_for_refund_ptr_id
          applied_refund_prt_id
          has_refund
          total_refund
        }
      }
    }`

    const response: Promise<OperationResult<{
      GetUserFlightBookings: FlightBookingPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public SearchFlight = (data: SearchFlightRequest) => {
    const requestData = `
    query SearchFlight($cabin_class: String!, $fligths: [FlightOption!]!, $passengers: [PassengerOption!]!) {
        SearchFlight(
          input: {cabin_class: $cabin_class, fligths: $fligths, passengers: $passengers}
        ) {
          FareSourceCode
          Provider
          Airline {
            Name
            Logo
            LogoFull
            IATACode
          }
          CheckinBaggage {
            Type
            Value
          }
          CabinBaggage {
            Type
            Value
          }
          FareBasisCodes
          Segments {
            Segment {
              DepartureAirportLocationCode
              ArrivalAirportLocationCode
              DepartureDateTime
              ArrivalDateTime
              stops
              JourneyDuration
              Equipment
              OperatingCarrierCode
              OperatingFlightNumber
              MarketingCarriercode
              MarketingFlightNumber
            }
            Info {
              CabinClassCode
              CabinClassType
              RBD
              FareFamily
              SeatsRemaining
            }
            Type
            DepartureCity
            ArrivalCity
          }
          FareInfo {
            FareType
            Currency
            PassengerFare {
              PaxType
              Quantity
              BaseFare
              TaxBreakUp {
                Amount
                TaxCode
                CurrencyCode
                DecimalPlaces
              }
              TotalFare
            }
          }
          PenaltyInfo {
            Penaltydetails {
              PaxType
              RefundPenaltyAmount
              RefundAllowed
              Currency
              ChangePenaltyAmount
              ChangeAllowed
            }
          }
        }
      }
    `

    const response: Promise<OperationResult<{
      SearchFlight: SingleFlight[]
    }>> = this.query(requestData, data)

    return response
  }

  public GetTripDetails = (booking_reference: string) => {
    const requestData = `
    query GetTripDetails($booking_reference: String!) {
        GetTripDetails(booking_reference: $booking_reference) {
          Provider
          Success
          TravelItinerary {
            MFRef
            TripType
            ExtraServices {
              Services {
                Type
                NameNumber
                IsMandatory
                Description
                Behavior
                ServiceId
                CheckInType
                ServiceCost {
                  Amount
                  TaxCode
                  CurrencyCode
                  DecimalPlaces
                }
              }
            }
            TicketStatus
            BookingStatus
            Origin
            Destination
            FareType
            PassengerInfos {
              Passenger {
                Gender
                DateOfBirth
                EmailAddress
                PhoneNumber
                PassportExpiresOn
                PassportNationality
                PassengerNationality
                PostCode
                PassportIssuanceCountry
                PassengerType
                PaxName {
                  PassengerTitle
                  PassengerFirstName
                  PassengerLastName
                }
                PassportNumber
                NationalID
                NameNumber
                TicketStatus
              }
              EachETickets {
                ItemRPH
                ETicketNumber
                ETicketType
              }
            }
            TripDetailsPTC_FareBreakdowns {
              PassengerTypeQuantity {
                Code
                Quantity
              }
              TripDetailsPassengerFare {
                EquiFare {
                  Amount
                  TaxCode
                  CurrencyCode
                  DecimalPlaces
                }
                Tax {
                  Amount
                  TaxCode
                  CurrencyCode
                  DecimalPlaces
                }
                ServiceTax {
                  Amount
                  TaxCode
                  CurrencyCode
                  DecimalPlaces
                }
                TotalFare {
                  Amount
                  TaxCode
                  CurrencyCode
                  DecimalPlaces
                }
                AirportTaxBreakUp
              }
              BaggageInfo
              CabinBaggageInfo
              IsPenaltyDetailsAvailable
            }
            VoidingWindow
            ClientUTCOffset
            Itineraries {
              ItineraryInfo {
                ReservationItems {
                  ItemRPH
                  DepartureDateTime
                  ArrivalDateTime
                  StopQuantity
                  FlightNumber
                  ResBookDesigCode
                  JourneyDuration
                  AirlinePNR
                  NumberInParty
                  DepartureAirportLocationCode
                  DepartureTerminal
                  ArrivalAirportLocationCode
                  ArrivalTerminal
                  OperatingAirlineCode
                  AirEquipmentType
                  MarketingAirlineCode
                  Baggage
                  FlightStatus
                  IsReturn
                  CabinClass
                  CabinClassType
                  ATAinfoRef
                  FareFamily
                }
              }
              Type
            }
            TransactionDetails
            BookingNotes {
              BookingRef
              CreatedByName    
              CreatedOn
              NoteDetails
            }
            TicketingTimeLimit
            BookingMode
            ReroutingAllowed
            SpoilageFee
          }
          BookingCreatedOn
        }
      }
    `

    const response: Promise<OperationResult<{
      GetTripDetails: TripDetails
    }>> = this.query(requestData, { booking_reference })

    return response
  }

  public GetQuoteforrefund = (quote_id: string) => {
    const requestData = `
    query GetQuoteforrefund($quote_id: String!) {
        GetQuoteforrefund(quote_id: $quote_id) {
          PTRId
          PTRType
          PTRStatus
          MFRef
          RefundQuotes {
            FirstName
            LastName
            Title
            PassengerType
            Eticket
            TotalFare
            UnusedFare
            CancellationCharge
            NoShowCharge
            Tax
            AdminCharges
            GSTCharge
            TotalRefundCharges
            TotalRefundAmount
            Currency
            YQ_Tax
            YR_Tax
            OtherTaxesK3
            ExtraServiceCharge
          }
          TraceId
          SLAInMinutes
          Message
        }
      }
    `

    const response: Promise<OperationResult<{
      GetQuoteforrefund: Quoteforrefund
    }>> = this.query(requestData, { quote_id })

    return response
  }

  public GetAppliedRefundRequest = (booking_reference: string) => {
    const requestData = `
    query GetAppliedRefundRequest($booking_reference: String!) {
        GetAppliedRefundRequest(booking_reference: $booking_reference) {
          PTRId
          PTRType
          MFRef
          BookingStatus
          PTRStatus
          CreditNoteNumber
          TotalRefundAmount
          Currency
          CreatedBy
          Resolution
          CreditNoteStatus
          ProcessingMethod
          pTRPaxDetails {
            Id
            PTRId
            PaxId
            TicketNumber
            TicketStatus
            IsActive
            PassengerType
            Title
            FirstName
            LastName
          }
        }
      }
    `

    const response: Promise<OperationResult<{
      GetAppliedRefundRequest: AppliedRefundResponse
    }>> = this.query(requestData, { booking_reference })

    return response
  }

  public GetFlightFareRules = (data: QueryGetFlightFareRulesArgs) => {
    const requestData = `
    query GetFlightFareRules($booking_reference: String, $type: String!, $fare_source_code: String) {
        GetFlightFareRules(
          booking_reference: $booking_reference
          type: $type
          fare_source_code: $fare_source_code
        ) {
          BaggageInfos {
            Arrival
            Baggage
            Departure
            FlightNo
          }
          FareRules {
            Airline
            CityPair
            RuleDetails {
              Category
              Rules
            }
          }
          TraceId
        }
      }
    `

    const response: Promise<OperationResult<{
      GetFlightFareRules: SingleFlightFareRules[]
    }>> = this.query(requestData, data)

    return response
  }

  public RevalidateFlight = (fare_source_code: string) => {
    const requestData = `
    mutation RevalidateFlight($fare_source_code: String!) {
      RevalidateFlight(fare_source_code: $fare_source_code) {
        AirItineraryPricingInfo {
          DivideInPartyIndicator
          FareSourceCode
          FareType
          IsRefundable
          ItinTotalFare {
            ActualFare {
              Amount
              CurrencyCode
              DecimalPlaces
            }
            BaseFare {
              Amount
              CurrencyCode
              DecimalPlaces
            }
            EquivFare {
              Amount
              CurrencyCode
              DecimalPlaces
            }
            TotalFare {
              Amount
              CurrencyCode
              DecimalPlaces
            }
            TotalTax {
              Amount
              CurrencyCode
              DecimalPlaces
            }
          }
          PTC_FareBreakdowns {
            BaggageInfo
            CabinBaggageInfo
            FareBasisCodes
            PassengerFare {
              BaseFare {
                Amount
                CurrencyCode
                DecimalPlaces
              }
              EquivFare {
                Amount
                CurrencyCode
                DecimalPlaces
              }
              Surcharges
              Taxes {
                Amount
                TaxCode
                CurrencyCode
                DecimalPlaces
              }
              TotalFare {
                Amount
                TaxCode
                CurrencyCode
                DecimalPlaces
              }
            }
            PassengerTypeQuantity {
              Code
              Quantity
            }
            PenaltiesInfo {
              Allowed
              Amount
              CurrencyCode
              PenaltyType
            }
          }
        }
        DirectionInd
        IsPassportMandatory
        OriginDestinationOptions {
          FlightSegments {
            ArrivalAirportLocationCode
            ArrivalDateTime
            CabinClassCode
            CabinClassText
            DepartureAirportLocationCode
            DepartureDateTime
            Eticket
            FlightNumber
            JourneyDuration
            MarketingAirlineCode
            MarriageGroup
            MealCode
            OperatingAirline {
              Code
              Equipment
              FlightNumber
            }
            ResBookDesigCode
            ResBookDesigText
            SeatsRemaining {
              BelowMinimum
              Number
            }
            StopQuantity
            StopQuantityInfo {
              ArrivalDateTime
              DepartureDateTime
              Duration
              LocationCode
            }
            DepartureCity
            ArrivalCity
            DepartureAirport
            ArrivalAirport
          }
        }
        Airline {
          Name
          Logo
          LogoFull
          IATACode
        }
        PaxNameCharacterLimit
        RequiredFieldsToBook
        SequenceNumber
        TicketType
        ValidatingAirlineCode
        FareRules {
          BaggageInfos {
            Arrival
            Baggage
            Departure
            FlightNo
          }
          FareRules {
            Airline
            CityPair
            RuleDetails {
              Category
              Rules
            }
          }
        }
      }
    }
    `

    const response: Promise<OperationResult<{
      RevalidateFlight: FlightRevalidationDetails
    }>> = this.mutation(requestData, { fare_source_code })

    return response
  }

  public Updateflightoptions = (data: UpdateflightoptionsRequest) => {
    const requestData = `
    mutation Updateflightoptions($booking_reference: String!, $new_options: String!, $comment: String!, $flight_options: [FlightOptionUpdate!]!) {
        Updateflightoptions(
          input: {booking_reference: $booking_reference, new_options: $new_options, comment: $comment, flight_options: $flight_options}
        ) {
          PTRType
          MFRef
          CRMReferenceNumber
          CreatedTime
          PTRId
          PTRStatus
          TraceId
          SLAInMinutes
        }
      }
    `

    const response: Promise<OperationResult<{
      Updateflightoptions: ScheduleChangeResponse
    }>> = this.mutation(requestData, data)

    return response
  }

  public OrderFlightTicker = (booking_reference: string) => {
    const requestData = `
    mutation OrderFlightTicke($booking_reference: String!) {
        OrderFlightTicket(booking_reference: $booking_reference)
      }
    `
    const response: Promise<OperationResult<{
      OrderFlightTicket: Boolean
    }>> = this.mutation(requestData, { booking_reference })

    return response
  }

  public CancelBooking = (booking_reference: string) => {
    const requestData = `
    mutation CancelBooking($booking_reference: String!) {
        CancelBooking(booking_reference: $booking_reference) {
          id
          uuid
          price_changed
          schedule_changed
          status
          reference
          time_limit
          currency
          amount
          schedule_change_ptr_id
          quote_for_refund_ptr_id
          applied_refund_prt_id
          has_refund
          total_refund
        }
      }
    `
    const response: Promise<OperationResult<{
      CancelBooking: FlightBooking
    }>> = this.mutation(requestData, { booking_reference })

    return response
  }

  public AcceptSchedulechange = (booking_reference: string) => {
    const requestData = `
    mutation AcceptSchedulechange($booking_reference: String!) {
        AcceptSchedulechange(booking_reference: $booking_reference) {
          PTRType
          MFRef
          CRMReferenceNumber
          CreatedTime
          PTRId
          PTRStatus
          TraceId
          SLAInMinutes
        }
      }
    `
    const response: Promise<OperationResult<{
      AcceptSchedulechange: ScheduleChangeResponse
    }>> = this.mutation(requestData, { booking_reference })

    return response
  }

  public ApplyForRefund = (booking_reference: string) => {
    const requestData = `
    mutation ApplyForRefund($booking_reference: String!) {
        ApplyForRefund(booking_reference: $booking_reference) {
          PTRType
          MFRef
          CRMReferenceNumber
          CreatedTime
          PTRId
          PTRStatus
          TraceId
          SLAInMinutes
        }
      }
    `
    const response: Promise<OperationResult<{
      ApplyForRefund: ScheduleChangeResponse
    }>> = this.mutation(requestData, { booking_reference })

    return response
  }

  public RequestQuoteforrefund = (data: MutationRequestQuoteforrefundArgs) => {
    const requestData = `
    mutation RequestQuoteforrefund($booking_reference: String!, $comment: String!) {
        RequestQuoteforrefund(booking_reference: $booking_reference, comment: $comment) {
          PTRType
          MFRef
          CRMReferenceNumber
          CreatedTime
          PTRId
          PTRStatus
          TraceId
          SLAInMinutes
        }
      }
    `
    const response: Promise<OperationResult<{
      RequestQuoteforrefund: ScheduleChangeResponse
    }>> = this.mutation(requestData, data)

    return response
  }

  public AcceptQuoteForRefund = (data: AcceptQuoteForRefundRequest) => {
    const requestData = `
    mutation AcceptQuoteForRefund($booking_reference: String!, $ptr_id: Int!, $accept_quote: String!, $comment: String!, $passengers: [RefundPassenger!]!) {
        AcceptQuoteForRefund(
          input: {booking_reference: $booking_reference, ptr_id: $ptr_id, accept_quote: $accept_quote, comment: $comment, passengers: $passengers}
        ) {
          PTRType
          MFRef
          CRMReferenceNumber
          CreatedTime
          PTRId
          PTRStatus
          TraceId
          SLAInMinutes
        }
      }
    `
    const response: Promise<OperationResult<{
      AcceptQuoteForRefund: ScheduleChangeResponse
    }>> = this.mutation(requestData, data)

    return response
  }
}
