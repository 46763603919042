import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "",
    class: _normalizeClass(`${_ctx.customClass} blend-in  ${
      _ctx.image == '' ? `${_ctx.photoUrl ? 'skeleton' : ''}` : ''
    }`),
    style: _normalizeStyle(`${
      _ctx.image == ''
        ? ''
        : `background-size: cover;
      background-repeat: no-repeat;
      background-position: center;`
    } 
      ${_ctx.image ? `background-image:url(${_ctx.imageUrl});` : ''}  ${_ctx.customStyle}`)
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 6))
}