<template>
  <div
    class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
  >
    <div class="flex justify-between w-full">
      <AppNormalText class="!font-semibold !line-clamp-1"
        >{{ data.data.title }}
      </AppNormalText>

      <app-badge :color="data.active ? 'green' : 'red'" :class="'capitalize'">
        {{ data.active ? "Active" : "Inactive" }}
      </app-badge>
    </div>

    <div class="h-[1px] bg-grey-50 w-full"></div>

    <div class="flex justify-between w-full">
      <AppNormalText class="text-left">
        {{ data.data.address }}
      </AppNormalText>
    </div>
    <div class="flex justify-between w-full">
      <AppNormalText
        :color="'text-grey-700'"
        class="!text-[12px]"
        :custom-class="'gap-2'"
      >
        Store Admin
      </AppNormalText>
      <AppNormalText>
        {{
          data.data.business_admin
            ? data.data.business_admin?.user?.full_name
            : "Super Admin"
        }}
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { DeliveryLocation } from "../../gql/graphql";
import { defineComponent } from "vue";
import AppBadge from "../AppBadge";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppBadge,
    AppNormalText,
  },
  props: {
    data: {
      type: Object as () => {
        location: string;
        cost: number;
        active: boolean;
        id: string;
        area: string[];
        data: DeliveryLocation;
      },
      required: true,
    },
  },
  name: "AppBusinessPickArea",
});
</script>
