<template>
  <div v-if="mode == 'grid'" class="flex flex-col space-y-2">
    <AppPlainImageLoader
      :custom-class="'rounded-lg w-full h-[137px]'"
      :photo-url="imageUrl"
    ></AppPlainImageLoader>
    <AppNormalText :custom-class="'font-[700]'">
      {{ title }}
    </AppNormalText>

    <div class="flex flex-col space-y-3">
      <div class="flex flex-row items-center justify-between">
        <AppNormalText :custom-class="'font-[700]'">
          N{{ amount }}
        </AppNormalText>
        <AppNormalText :color="'text-grey-700'" :size="'small'">
          {{ date }}
        </AppNormalText>
      </div>
      <div class="h-[1px] bg-grey-100 w-full"></div>

      <div class="flex flex-row items-center justify-between space-x-3 w-full">
        <app-badge :color="'green'"> {{ available }} Available </app-badge>

        <AppNormalText :color="'text-base-black'" :size="'small'">
          {{ sold }} Sold
        </AppNormalText>
      </div>
    </div>
  </div>

  <div
    v-if="mode == 'list'"
    class="flex items-center w-full justify-between border border-grey-50 rounded-lg py-2.5 px-3"
  >
    <div class="flex items-center space-x-2">
      <AppPlainImageLoader
        :custom-class="'rounded-lg w-[46px] h-[46px] '"
        :photo-url="imageUrl"
      ></AppPlainImageLoader>
      <div class="flex flex-col space-y-2">
        <AppNormalText :custom-class="'font-[700]'">
          {{ title }}
        </AppNormalText>

        <div class="flex flex-row items-center space-x-1">
          <app-badge v-if="available" :color="'green'">
            {{ available }} Available
          </app-badge>

          <AppNormalText v-if="sold" :color="'text-base-black'" :size="'small'">
            {{ sold }} Sold
          </AppNormalText>

          <AppNormalText v-if="quantity" :color="'text-base-black'" :size="'small'">
            Qty: {{ quantity }}
          </AppNormalText>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between  h-full space-y-2">
      <AppNormalText :custom-class="'font-[700]'">
        N{{ amount }}
      </AppNormalText>
      <AppNormalText v-if="date" :color="'text-grey-700'" :size="'small'">
        {{ date }}
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppPlainImageLoader from "../AppPlainImageLoader";
import { PropType } from "vue";
import AppBadge from "../AppBadge";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppIcon,
    AppPlainImageLoader,
    AppBadge,
  },
  props: {
    title: {
      type: String,
    },
    available: {
      type: Number,
      default: 0,
    },
    sold: {
      type: Number,
      default: 0,
    },
    quantity: {
      type: Number,
      default: 0,
    },
    amount: {
      type: String,
      default: "0,000",
    },
    date: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    mode: {
      type: String as PropType<"grid" | "list">,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppProductList",
};
</script>
