<template>
  <app-wrapper>
    <subpage-layout :centerTitle="'Expenses'">
      <template v-slot:extra-topbar>
        <div
          :class="`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full invisible flex flex-row items-center justify-center border-[1px] border-primary-500 `"
        >
          <App-icon :name="'help'" :customClass="'h-[18px]'" />
        </div>
      </template>

      <!-- Fixed top -->
      <template v-slot:extra-topbar-row>
        <!-- Filter -->
        <div class="w-full flex flex-col px-4">
          <div
            class="w-full grid grid-cols-3 gap-2 border-b-[1px] border-[#EFE8F5] dark:border-grey-700"
          >
            <div
              class="col-span-1 pb-3 flex items-center justify-center relative cursor-pointer"
              v-for="(item, index) in spendOptions"
              :key="index"
              @click="selectedSpend = item.key"
            >
              <app-normal-text
                :class="`!text-[12px]  ${
                  item.key == selectedSpend
                    ? '!font-bold'
                    : '!text-grey-700 dark:!text-grey-400'
                }`"
              >
                {{ item.title }}
              </app-normal-text>
              <div
                v-if="item.key == selectedSpend"
                class="absolute w-full h-[4px] rounded-t-[20px] bg-primary-400 bottom-0 left-0"
              ></div>
            </div>
          </div>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-0">
        <!-- Total expenses -->
        <div
          class="w-full flex flex-col space-y-2 pt-4 pb-1 px-3 bg-white rounded-[10px] dark:bg-black"
        >
          <!--  Expenses -->
          <div class="w-full flex flex-col space-y-[2px] items-center justify-center">
            <app-normal-text
              class="!text-[12px] !text-primary-400 !font-semibold text-center dark:!text-primary-200"
            >
              Total Expenses
            </app-normal-text>
            <app-header-text class="!font-bold text-center">
              {{ Logic.Common.convertToMoney(totalAmount, false, "ngn") }}
            </app-header-text>
          </div>

          <!-- Date filters -->
          <div
            class="w-full flex flex-row space-x-[5px] items-center justify-center pt-2"
          >
            <div
              :class="`px-2 py-[2px] rounded-[50px] cursor-pointer ${
                selectedFilter == item.key
                  ? 'bg-primary-50 border-[1px] border-primary-300 dark:bg-primary-500 dark:border-primary-50'
                  : '!bg-[#F7F7F7] border-[1px] border-[#F7F7F7] dark:!bg-grey-700 dark:border-grey-50'
              }`"
              v-for="(item, index) in periodFilter"
              :key="index"
              @click="selectFilter(item.key)"
            >
              <app-normal-text
                :class="`${
                  selectedFilter == item.key
                    ? '!text-primary-400 dark:!text-primary-50'
                    : '!text-gray-600 dark:!text-grey-50'
                } !text-[11px]`"
              >
                {{ item.title }}
              </app-normal-text>
            </div>
          </div>

          <!-- Expenses chart -->
          <app-expenses-line-chart :data="expensesChartData" />
        </div>

        <!-- Transaction list -->
        <div
          class="w-full flex flex-col space-y-2 pt-4 pb-4 px-3 bg-white dark:bg-black rounded-[10px] !-mt-[10px]"
        >
          <app-header-text :class="'!text-base'"> Transaction History </app-header-text>

          <div
            class="w-full flex flex-col space-y-2"
            v-if="expensesTransactions.length > 0"
          >
            <app-expenses-transaction
              v-for="(transaction, index) in expensesTransactions"
              :key="index"
              class="cursor-pointer"
              :data="transaction"
              @click="transaction.handler()"
            >
            </app-expenses-transaction>
          </div>
          <div
            v-else
            class="h-[200px] w-full flex flex-row items-center justify-center text-center px-4"
          >
            <app-empty-state
              :title="'No Transactions Yet'"
              :subTitle="'Your transactions will show up here'"
            />
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>

      <!-- Snapped receipt modal -->
      <div
        :class="`w-full flex flex-col items-center justify-start space-y-2 fixed h-screen !pt-0 top-0 left-0 z-[9999999999999] bg-black  px-4`"
        v-if="receiptInfoSetup.show"
      >
        <div
          class="w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
        >
          <div class="flex flex-row items-center space-x-3">
            <div class="w-[50px]">
              <div
                :class="`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full flex flex-row items-center justify-center border-[1px] border-white cursor-pointer`"
                @click="receiptInfoSetup.show = false"
              >
                <app-icon :name="'close-white'" :customClass="'h-[12px]'" />
              </div>
            </div>
            <div class="w-full flex flex-col space-y-1">
              <app-header-text :color="'!text-white'"> Snapped Receipt </app-header-text>
            </div>
          </div>
        </div>
        <div
          class="w-full absolute top-0 left-0 h-full flex flex-col rounded-[22px] overflow-y-auto"
        >
          <div class="w-full flex flex-col h-full">
            <app-image-interactive
              :url="receiptInfoSetup.image_url || ''"
              :customClass="''"
            />
          </div>
        </div>
        <div
          class="w-full flex flex-col pt-3 absolute bottom-0 left-0 px-4 z-[9999999999]"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <app-button
            :customClass="`w-full`"
            :padding="'py-3 xs:py-3'"
            @click="showReceiptInfo(receiptInfoSetup.data)"
          >
            See Receipt Details
          </app-button>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import {
  AppIcon,
  AppNormalText,
  AppHeaderText,
  AppExpensesLineChart,
  AppExpensesTransaction,
  AppImageInteractive,
  AppButton,
  AppEmptyState,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import { FilterPeriods, QueryGetExpensesAnalysisArgs } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppExpensesLineChart,
    AppExpensesTransaction,
    AppImageInteractive,
    AppButton,
    AppEmptyState,
    AppWrapper,
  },
  name: "ExpenseManagementPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "ExpensesAnalysis",
        method: "GetExpensesAnalysis",
        params: [
          {
            back_count: 0,
            end_date: Logic.Common.momentInstance(new Date())
              .subtract(1, "month")
              .endOf("month")
              .add(2, "days")
              .format("YYYY-MM-DD HH:mm:ss"),
            filter_type: "all",
            period: FilterPeriods.Week,
            start_date: Logic.Common.momentInstance(new Date())
              .subtract(1, "month")
              .startOf("month")
              .subtract(2, "days")
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        ],
        requireAuth: true,
        silentUpdate: false,
        ignoreProperty: false,
      },
      {
        domain: "Shop",
        property: "ManyExpenses",
        method: "GetManyExpenses",
        params: [
          "all",
          Logic.Common.momentInstance(new Date())
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss"),
          Logic.Common.momentInstance(new Date())
            .subtract(1, "month")
            .endOf("month")
            .add(5, "days")
            .format("YYYY-MM-DD HH:mm:ss"),
        ],
        requireAuth: true,
        silentUpdate: false,
      },
    ],
    tracking_data: {
      lable: "Spend Management Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Spend Management",
    });

    const selectedSpend = ref("all");

    const receiptInfoSetup = reactive<{
      show: boolean;
      image_url: string;
      data: any;
    }>({
      show: false,
      image_url: "",
      data: "",
    });

    const totalAmount = ref(0);

    const filterParams = reactive<QueryGetExpensesAnalysisArgs>({
      back_count: 0,
      end_date: "",
      filter_type: "all",
      period: FilterPeriods.Week,
      start_date: "",
    });

    const expensesChartData = ref<
      {
        x: string;
        y: number;
        label: string;
      }[]
    >([]);

    const ExpensesAnalysis = ref(Logic.User.ExpensesAnalysis);
    const ManyExpenses = ref(Logic.Shop.ManyExpenses);

    const spendOptions = reactive([
      {
        title: "All",
        key: "all",
      },
      {
        title: "Receipts",
        key: "receipts",
      },
      {
        title: "Bill Payment",
        key: "bill_payments",
      },
      // {
      //   title: "Card",
      //   key: "card",
      // },
    ]);

    const selectedFilter = ref("month");

    const expensesTransactions = reactive<
      {
        icon: string;
        type: string;
        title: string;
        date: string;
        base_amount: string;
        uniqueId: string;
        points: string;
        amount: string;
        extra_info: string;
        // eslint-disable-next-line @typescript-eslint/ban-types
        handler: Function;
      }[]
    >([
      // {
      //   icon: "card-expenses",
      //   type: "card",
      //   title: "Prince Ebanor Supermarket",
      //   date: "May 26, 2023 | 5:30pm",
      //   base_amount: "N48,000",
      //   uniqueId: "#112896",
      //   points: "178.38",
      //   amount: "17.84",
      //   extra_info: "Card: **** 5596",
      // },
    ]);

    const periodFilter = reactive([
      {
        title: "Last month",
        key: "month",
      },
      {
        title: "This week",
        key: "week",
      },
      {
        title: "This year",
        key: "year",
      },
      {
        title: "Custom",
        key: "custom",
      },
    ]);

    const showReceiptInfo = (reward: any) => {
      Logic.Shop.SingleScannedReceipt = {
        point_to_claim: reward.points,
        scanned_receipt: reward.scanned_receipt,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        game_points: reward.game_points > 0 ? reward.game_points : reward.points,
      };

      Logic.Common.GoToRoute(`/snap/info?earned=true&isEditable=true`);
    };

    const formatDateByPeriod = (date: string) => {
      let dateFormattedAbv = "";
      let dateFormattedLabel = "";
      let fullDate = date;
      switch (filterParams.period) {
        case FilterPeriods.AllTime:
          dateFormattedAbv = Logic.Common.momentInstance(date).format("MMM");
          dateFormattedLabel = Logic.Common.momentInstance(date).format("MMMM YYYY");
          break;
        case FilterPeriods.Month:
          dateFormattedAbv = Logic.Common.momentInstance(date).format("MMM");
          dateFormattedLabel = Logic.Common.momentInstance(date).format("MMMM YYYY");
          break;
        case FilterPeriods.Year:
          dateFormattedAbv = Logic.Common.momentInstance(date).format("MMM");
          dateFormattedLabel = Logic.Common.momentInstance(date).format("MMMM YYYY");
          break;
        case FilterPeriods.Week:
          dateFormattedAbv = Logic.Common.momentInstance(date).format("MMM D");
          dateFormattedLabel = Logic.Common.momentInstance(date).format("dddd MMM Do");
          break;
        case FilterPeriods.Day:
          dateFormattedAbv = Logic.Common.momentInstance(date).format("ddd");
          dateFormattedLabel = Logic.Common.momentInstance(date).format("dddd");
          break;
        default:
          break;
      }

      return {
        dateFormattedAbv,
        dateFormattedLabel,
        fullDate,
      };
    };

    const selectFilter = (key: string) => {
      if (key == "year" || key == "custom") {
        if (
          Logic.Auth.AuthUser?.profile?.subscription_plan?.name
            .toLocaleLowerCase()
            .includes("bronze")
        ) {
          showUpgradeAccount();
          return;
        }
      }

      selectedFilter.value = key;
      if (key == "custom") {
        showCustomFilter();
      }
    };

    const setExpensesChartData = () => {
      expensesChartData.value = [];
      totalAmount.value = 0;

      const allChartData: any[] = [];
      let finalAmounts = 0;
      ExpensesAnalysis.value?.forEach((item, index) => {
        const formatedDate = formatDateByPeriod(item.date);

        let dateFormattedAbv = formatedDate.dateFormattedAbv;
        let dateFormattedLabel = formatedDate.dateFormattedLabel;

        if (filterParams.period == FilterPeriods.Week) {
          dateFormattedAbv = Logic.Common.momentInstance(formatedDate.fullDate)
            .startOf("month")
            .add(index, "weeks")
            .format("MMM D");

          dateFormattedLabel = Logic.Common.momentInstance(formatedDate.fullDate)
            .startOf("month")
            .add(index, "weeks")
            .format("dddd MMM Do");
          if (index == 3) {
            dateFormattedAbv = Logic.Common.momentInstance(formatedDate.fullDate)
              .endOf("month")
              .format("MMM D");
            dateFormattedLabel = Logic.Common.momentInstance(formatedDate.fullDate)
              .endOf("month")
              .format("dddd MMM Do");
          }
        }

        finalAmounts += item.data;
        allChartData.push({
          x: dateFormattedAbv,
          label: dateFormattedLabel,
          y: item.data,
        });
      });
      totalAmount.value = finalAmounts;
      expensesChartData.value = allChartData;
    };

    const getStatitics = () => {
      Logic.Common.showLoader({ show: true, loading: true });
      const expensesAnalysis = Logic.User.GetExpensesAnalysis(filterParams);

      const manyExpenses = Logic.Shop.GetManyExpenses(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filterParams.filter_type,
        filterParams.start_date,
        filterParams.end_date
      );

      Promise.all([expensesAnalysis, manyExpenses]).then(() => {
        Logic.Common.hideLoader();
      });
    };

    const showUpgradeAccount = () => {
      Logic.Common.showModal({
        type: "request_feedback",
        show: true,
        action: () => {
          Logic.Common.showModal({ show: false });
        },
        title: "",
        extraData: {
          title: `Upgrade to a higher plan to see more insight`,
          icon: "error-warning",
          buttons: [
            {
              label: "Upgrade",
              action: () => {
                Logic.Common.GoToRoute("/others/subscriptions");
              },
            },
          ],
        },
      });
    };

    const setFilterParams = () => {
      if (selectedFilter.value == "year") {
        const startDate = Logic.Common.momentInstance(new Date())
          .startOf("year")
          .subtract(5, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        const endDate = Logic.Common.momentInstance(new Date())
          .add(1, "month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        filterParams.start_date = startDate;
        filterParams.end_date = endDate;
        filterParams.back_count = 0;
        filterParams.period = FilterPeriods.Month;
      }

      if (selectedFilter.value == "month") {
        const startDate = Logic.Common.momentInstance(new Date())
          .subtract(1, "month")
          .startOf("month")
          .startOf("day")
          .subtract(2, "days")
          .format("YYYY-MM-DD HH:mm:ss");
        const endDate = Logic.Common.momentInstance(new Date())
          .subtract(1, "month")
          .endOf("month")
          .add(2, "days")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        filterParams.start_date = startDate;
        filterParams.end_date = endDate;
        filterParams.back_count = 0;
        filterParams.period = FilterPeriods.Week;
      }

      if (selectedFilter.value == "week") {
        const startDate = Logic.Common.momentInstance(new Date())
          .startOf("week")
          .subtract(1, "day")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        const endDate = Logic.Common.momentInstance(new Date())
          .endOf("week")
          .endOf("day")
          // .add(1, "day")
          .format("YYYY-MM-DD HH:mm:ss");
        filterParams.start_date = startDate;
        filterParams.end_date = endDate;
        filterParams.back_count = 0;
        filterParams.period = FilterPeriods.Day;
      }

      if (selectedFilter.value != "custom") {
        filterParams.filter_type = selectedSpend.value;
        getStatitics();
      }
    };

    const setExpensesTransactions = () => {
      expensesTransactions.length = 0;

      const receiptSlugs = ["SCANNED_RECEIPT"];
      const billPaymentSlugs = [
        "AIRTIME_PURCHASE",
        "DATA_PURCHASE",
        "CABLE_TV_PURCHASE",
        "ELECTRICITY_PURCHASE",
      ];

      ManyExpenses.value?.forEach((item) => {
        let date = "";
        let title = "";
        let uniqueId = "";
        let handler = () => {
          //
        };

        if (receiptSlugs.includes(item.event_slug)) {
          date = item.scanned_receipt?.receipt_date || "";
          title = item.scanned_receipt?.merchant_name || "";
          uniqueId = `#${item.scanned_receipt?.invoice_no || ""}`;
          handler = () => {
            const viewCount = localStorage.getItem("view_receipt_image_count");

            let realViewCount = 1;

            if (viewCount) {
              realViewCount = parseInt(viewCount);
            }

            if (realViewCount == 1 || realViewCount == 4) {
              Logic.Common.loadInterstitialAd(() => {
                receiptInfoSetup.image_url = item.scanned_receipt?.image_url || "";
                receiptInfoSetup.data = item;
                receiptInfoSetup.show = true;
                realViewCount += 1;
                localStorage.setItem(
                  "view_receipt_image_count",
                  realViewCount < 5 ? realViewCount.toString() : "1"
                );
              });
            } else {
              Logic.Common.showLoader({ show: true, loading: true });
              setTimeout(() => {
                Logic.Common.hideLoader();
                receiptInfoSetup.image_url = item.scanned_receipt?.image_url || "";
                receiptInfoSetup.data = item;
                receiptInfoSetup.show = true;
                realViewCount += 1;
                localStorage.setItem(
                  "view_receipt_image_count",
                  realViewCount < 5 ? realViewCount.toString() : "1"
                );
              }, 3000);
            }
          };
        }

        if (billPaymentSlugs.includes(item.event_slug)) {
          date = item.created_at || "";
          title = item.description.replace("Shoppoint for ", "");
          handler = () => {
            if (item.sale?.transaction) {
              Logic.Common.GoToRoute(
                `/wallet/make-payment/details?uuid=${item.sale?.transaction?.uuid}&ignoreBackRoute=true`
              );
            } else if (item.sale?.point_transaction) {
              Logic.Common.GoToRoute(
                `/wallet/transactions/points/${item.sale?.point_transaction?.uuid}?ignoreBackRoute=true`
              );
            }
          };
        }

        expensesTransactions.push({
          icon: "receipt-expenses",
          type: "receipt",
          amount: Logic.Common.convertToMoney(item.points, false, "ngn"),
          base_amount: Logic.Common.convertToMoney(item.money_amount, true, "ngn"),
          date: Logic.Common.fomartDate(date || "", "MMM D, YYYY | h:mma"),
          points: Logic.Common.convertToMoney(
            item.game_points ? item.game_points : item.points,
            false,
            "",
            false
          ),
          title: title,
          extra_info: "",
          uniqueId: uniqueId,
          handler,
        });
      });
    };

    const showCustomFilter = () => {
      Logic.Common.showModal({
        show: true,
        title: "Custom Filter",
        type: "filter_expenses",
        actionLabel: "Continue",
        action: (data: any) => {
          const dayDifference = Logic.Common.momentInstance(data.to_date).diff(
            Logic.Common.momentInstance(data.from_date),
            "days"
          );

          let filterPeriod = FilterPeriods.Month;

          if (dayDifference >= 0 && dayDifference <= 10) {
            filterPeriod = FilterPeriods.Day;
          }

          if (dayDifference >= 11 && dayDifference <= 40) {
            filterPeriod = FilterPeriods.Week;
          }

          if (dayDifference > 41) {
            filterPeriod = FilterPeriods.Month;
          }

          filterParams.back_count = 0;
          filterParams.start_date = Logic.Common.momentInstance(data.from_date)
            .startOf("day")
            .format("YYYY-MM-DD HH:mm:ss");
          filterParams.end_date = Logic.Common.momentInstance(data.to_date)
            .endOf("day")
            .format("YYYY-MM-DD HH:mm:ss");
          filterParams.period = filterPeriod;

          filterParams.filter_type = selectedSpend.value;
          getStatitics();
          Logic.Common.showModal({ show: false });
        },
        validateForm: true,
      });
    };

    watch(selectedFilter, () => {
      if (selectedFilter.value == "custom") {
        showCustomFilter();
      } else {
        setFilterParams();
      }
    });

    watch(selectedSpend, () => {
      setFilterParams();
    });

    watch(ExpensesAnalysis, () => {
      setExpensesChartData();
    });

    watch(ManyExpenses, () => {
      setExpensesTransactions();
    });

    onIonViewWillEnter(() => {
      setExpensesChartData();
      setExpensesTransactions();
    });

    onMounted(() => {
      Logic.User.watchProperty("ExpensesAnalysis", ExpensesAnalysis);
      Logic.Shop.watchProperty("ManyExpenses", ManyExpenses);
      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 2000);
      if (Logic.Common.currentBuildType() == "web") {
        setExpensesChartData();
        setExpensesTransactions();
      }
    });

    return {
      spendOptions,
      selectedSpend,
      periodFilter,
      selectedFilter,
      expensesTransactions,
      showCustomFilter,
      expensesChartData,
      ManyExpenses,
      totalAmount,
      selectFilter,
      Logic,
      receiptInfoSetup,
      showReceiptInfo,
    };
  },
});
</script>
