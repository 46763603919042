import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pl-4" }
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-col space-y-7 flex-grow pt-3 px-3"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "w-[25px]" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-row border-l-[1px] border-secondary-400 px-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, {
    title: 'FAQs',
    mainSectionClass: "!h-fit"
  }, {
    "extra-topbar-row": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(`px-4 py-1 ${
                _ctx.selectedFilter == item.id
                  ? 'bg-primary-main'
                  : 'bg-primary-50 dark:bg-gray-700'
              }  rounded-[20px]  flex flex-row items-center justify-center`),
                onClick: ($event: any) => (_ctx.selectedFilter = item.id)
              }, [
                _createVNode(_component_app_normal_text, {
                  color: `${
                  _ctx.selectedFilter == item.id
                    ? 'text-white'
                    : 'text-primary-main dark:text-gray-50'
                }`,
                  "custom-class": 'whitespace-nowrap'
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.title), 1)
                  ]),
                  _: 2
                }, 1032, ["color"])
              ], 10, _hoisted_4))
            }), 128))
          ])
        ])
      ])
    ]),
    default: _withCtx(() => [
      (_ctx.questionsAndAnwsers)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questionsAndAnwsers, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "w-full flex flex-row space-x-3 items-start",
                key: index,
                onClick: ($event: any) => (_ctx.selectedAnswer = item.id)
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_app_icon, {
                    name: _ctx.selectedAnswer == item.id ? 'faq-open' : 'faq-close',
                    customClass: 'h-[23px]'
                  }, null, 8, ["name"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_app_normal_text, { customClass: '!text-xs !font-semibold' }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.title), 1)
                    ]),
                    _: 2
                  }, 1024),
                  (_ctx.selectedAnswer == item.id)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_app_normal_text, {
                          customClass: '!text-left',
                          innerHTML: item.content
                        }, null, 8, ["innerHTML"])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ], 8, _hoisted_6))
            }), 128)),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "!h-[70px]" }, null, -1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}