import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_normalText = _resolveComponent("normalText")!

  return (_openBlock(), _createBlock(_component_normalText, null, {
    default: _withCtx(() => [
      _createElementVNode("span", {
        class: _normalizeClass(`${_ctx.customClass}`),
        ref: "counter"
      }, "00:00", 2)
    ]),
    _: 1
  }))
}