import { OperationResult } from 'urql'
import {
  AppContent,
  Business,
  BusinessAdminPaginator,
  ContentType,
  DataDate,
  LeaderboardUser,
  MutationAddUserToWaitlistArgs,
  MutationConnectShopifyStoreArgs,
  MutationFollowUnfollowBusinessArgs,
  MutationInviteBusinessAdminArgs,
  MutationSaveUserActivityArgs,
  MutationSaveUserPushNotificationDeviceArgs,
  MutationSetTransactionPinArgs,
  MutationUpdateBusinessAdminArgs,
  MutationUpdateBusinessProfileArgs,
  MutationUpdateProfileArgs,
  NotificationPaginator,
  Profile,
  QueryGetExpensesAnalysisArgs,
  Region,
  SubscriptionPlan,
  User,
  UserContent,
  Waitlist,
} from '../gql/graphql'
import { BaseApiService } from './common/BaseService'

export default class UserApi extends BaseApiService {
  private baseCollectionQuery = `
   id
                  uuid
                  name
                  type
                  base_image_url
                  favorites_count
                  user_is_favorite
                  media_type
                  reward_percentage
                  type
                  description
                  expires_at
                  shipping_category {
                    category_id
                    category
                  }
                  dimension_size {
                    name
                    description_image_url
                    height
                    width
                    length
                    max_weight
                  }
                  status
                  media {
                    media_url
                    percentage 
                  }
                  products {
                    id
                    uuid
                    name
                    description
                     variants {
                        id
                        uuid
                        name
                        price
                        base_image_url
                        is_available
                      inventory_quantity
                      selected_options {
                        name
                        value
                      }
                    }
                    medias {
                      id
                      uuid
                      media_type
                      media_url
                    }
                    category {
                      id
                      parent_category_id
                      name
                    }
                    sub_category {
                      id
                      name
                    }
                    rewards {
                      id
                      type
                      percentage
                      quantity
                    }
                    business {
                      id
                      business_tag
                      business_name
                      uuid
                    }
                      brand {
                        id
                        uuid
                        business_name
                        photo_url
                      }
                    regular_price
                    primary_image_url
                    stock_quantity
                    status
                  }
                  business {
                    id
                    uuid
                    business_name
                    business_tag
                    photo_url
                  }`
  public GetUserProfile = () => {
    const requestData = `
        query GetUserProfile {
            GetUserProfile {
              id
              uuid
              country
              city
              photo_url
              gender
              date_of_birth
              pin_is_set
              user_verified
              account_tier_level
              usage_policy_accepted
              snapping_terms_accepted
              spend_group
			        savings_target_amount
              subscription_plan {
                uuid
                name
                price
                description
                created_at
              }
              business {
                uuid
                document
                address
                business_name
                business_tag
                description
                business_social_links
                business_category
                photo_url
                verification_status
                created_at
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      GetUserProfile: Profile
    }>> = this.query(requestData, {})

    return response
  }

  public GetReferralSummary = () => {
    const requestData = `
    query GetReferralSummary {
      GetAuthUser {
         referral_summary {
          point_gained
          point_per_referral
          referrals
          referral_code
         }
      }
    }
    `

    const response: Promise<OperationResult<{
      GetAuthUser: User
    }>> = this.query(requestData, {})

    return response
  }

  public GetBusinessProfile = (uuid: string) => {
    const requestData = `
   query GetBusinessProfile($uuid: String!) {
        GetBusinessProfile(uuid: $uuid) {
          id
          uuid
          business_name
          business_tag
          photo_url
          verification_status
          description
          followers
          is_following
          is_brand
          business_social_links {
            facebook
            instagram
            whatsapp
            website
          }
          phone_number
          created_at
        }
      }
    `

    const response: Promise<OperationResult<{
      GetBusinessProfile: Business
    }>> = this.query(requestData, { uuid })

    return response
  }

  public GetImageThumbnail = (image_url: string) => {
    const requestData = `
    query GetImageThumbnail($image_url: String!) {
      GetImageThumbnail(image_url: $image_url)
    }
    `

    const response: Promise<OperationResult<{
      GetImageThumbnail: string
    }>> = this.query(requestData, { image_url })

    return response
  }

  public GetSubscriptionPlans = () => {
    const requestData = `
    query GetSubscriptionPlans {
        GetSubscriptionPlans {
          id
          uuid
          name
          price
          description
          receipt_scan_allowed_days
          receipt_weekly_spend_limit
        }
      }
                `

    const response: Promise<OperationResult<{
      GetSubscriptionPlans: SubscriptionPlan[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetUnreadNotificationsCount = () => {
    const requestData = `
    query GetUnreadNotificationsCount {
      GetUnreadNotificationsCount
    }
    `

    const response: Promise<OperationResult<{
      GetUnreadNotificationsCount: number
    }>> = this.query(requestData, {})

    return response
  }

  public GetUnClaimedPointEarnedCount = () => {
    const requestData = `
    query GetUnClaimedPointEarnedCount {
      GetUnClaimedPointEarnedCount
    }
    `

    const response: Promise<OperationResult<{
      GetUnClaimedPointEarnedCount: number
    }>> = this.query(requestData, {})

    return response
  }

  public GetClientAppContent = () => {
    const requestData = `
     query AppContent {
      AppContent {
        terms_and_condition
        privacy_policy
        faqs_page
        home_page
        app_version
        usage_policy
        snapping_terms
      }
    }
     `

    const response: Promise<OperationResult<{
      AppContent: AppContent
    }>> = this.query(requestData, {})

    return response
  }

  public GetRegions = () => {
    const requestData = `
    query GetRegions {
      GetRegions {
        id
        uuid
        country
        currency
        flag_image_url
        phone_code
        is_active
      }
    }`

    const response: Promise<OperationResult<{
      GetRegions: Region[]
    }>> = this.query(requestData, {})

    return response
  }

  public SearchUser = (query: string) => {
    const requestData = `
    query SearchUser($query: String!) {
        SearchUser(query: $query) {
          id
          uuid
          full_name
          username
          profile {
            uuid
            photo_url
          }
        }
      }
                `

    const response: Promise<OperationResult<{
      SearchUser: User[]
    }>> = this.query(requestData, { query })

    return response
  }

  public GetGlobalLeaderBoard = (
    filter_type: 'day' | 'week' | 'month' | 'global' | 'my_friends',
  ) => {
    const requestData = `
    query GetGlobalLeaderBoard($filter_type: String!) {
        GetGlobalLeaderBoard(filter_type: $filter_type) {
          user {
            id
            uuid
            full_name
            username
            profile {
              uuid
              photo_url
              subscription_plan {
                id
              }
            }
          }
          points
          index
        }
      }
                `

    const response: Promise<OperationResult<{
      GetGlobalLeaderBoard: LeaderboardUser[]
    }>> = this.query(requestData, { filter_type })

    return response
  }

  public GetExpensesAnalysis = (data: QueryGetExpensesAnalysisArgs) => {
    const requestData = `
    query GetExpensesAnalysis($filter_type: String!
      $back_count: Int!
      $start_date: String!
      $end_date: String!
      $period: FilterPeriods!
      $sub_type: String
    ) {
      GetExpensesAnalysis(filter_type: $filter_type, back_count: $back_count, start_date: $start_date, end_date: $end_date, period: $period, sub_type: $sub_type) {
          data
          date
        }
      }
        `

    const response: Promise<OperationResult<{
      GetExpensesAnalysis: DataDate[]
    }>> = this.query(requestData, data)

    return response
  }

  public GetNotifications = (
    first: number,
    page: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    hasUser = '',
    whereQuery = '',
  ) => {
    const requestData = `
            query GetNotifications($first: Int!, $page: Int!) {
                GetNotifications(
                    first: $first
                    page: $page
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                    ${hasUser ? 'hasUser:' + hasUser : ''}
                    ${whereQuery ? 'where:' + whereQuery : ''}
                    ) {
                  paginatorInfo {
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    perPage
                    lastPage
                    total
                  }
                  data {
                    id
                    uuid
                    title
                    body
                    type
                    unread
                    model_type
                    extra_url
                    model_type_id
                    created_at
                  }
                }
              }
                      `
    const response: Promise<OperationResult<{
      GetNotifications: NotificationPaginator
    }>> = this.query(requestData, {
      first,
      page,
    })

    return response
  }

  public GetBusinessAdmins = (
    first: number,
    page: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    hasBusiness = '',
    whereQuery = '',
  ) => {
    const requestData = `
      query GetBusinessAdmin($first: Int!, $page: Int!) {
          GetBusinessAdmin(
              first: $first
              page: $page
              orderBy: {column: ${
                orderType ? orderType : 'CREATED_AT'
              }, order: ${order}}
              ${hasBusiness ? 'hasBusiness:' + hasBusiness : ''}
              ${whereQuery ? 'where:' + whereQuery : ''}
              ) {
            paginatorInfo {
              count
              currentPage
              firstItem
              hasMorePages
              lastItem
              perPage
              lastPage
              total
            }
            data {
              id
              uuid
              status
              user {
                id
                uuid
                full_name
                phone
                email
                profile {
                  photo_url
                }
              }
              permissions{
                manage_all_orders
                manage_assigned_branch_orders
                manage_products
                manage_shoplists
                manage_promotions
                manage_store_branches
                manage_offline_payments
                manage_settlement_accounts
                manage_profile
              }
              created_at
              invitation_accepted_at
            }
          }
        }
                `

    const response: Promise<OperationResult<{
      GetBusinessAdmin: BusinessAdminPaginator
    }>> = this.query(requestData, {
      first,
      page,
    })

    return response
  }

  public GetUserContent = (
    contentType: ContentType,
    page: number,
    perPage: number,
    business_uuid = '',
    with_product = false,
  ) => {
    const requestData = `
   query GetUserContents($content_type: ContentType!, $page: Int!, $per_page: Int!, $business_uuid: String, $with_product: Boolean!) {
          GetUserContents(
            content_type: $content_type
            page: $page
            per_page: $per_page
            business_uuid: $business_uuid
            with_product: $with_product
          ) {
            current_page
            total_pages
            request_uuid
            contents {
              type
              id
              data {
                ... on ShoplistProduct {
                  id
                  uuid
                  name
                  description
                  category {
                    id
                    parent_category_id
                    name
                  }
                  sub_category {
                    id
                    name
                  }
                  rewards {
                    id
                    type
                    percentage
                    quantity
                  }
                     variants {
                      id
                      uuid
                      name
                      price
                      base_image_url
                      is_available
                    inventory_quantity
                    selected_options {
                      name
                      value
                    }
                  }
                  medias {
                    id
                    uuid
                    media_type
                    media_url
                  }
                  business {
                    id
                    uuid
                    business_name
                    business_tag
                    photo_url
                  }
                     brand {
                      id
                      uuid
                      business_name
                      photo_url
                    }
                  regular_price
                  primary_image_url
                  stock_quantity
                  status
                }
                ... on Collection {
                  id
                  uuid
                  name
                  base_image_url
                  user_is_favorite
                  favorites_count
                  media_type
                  description
                  expires_at
                  status
                  collections {
                         ${this.baseCollectionQuery}
                      }
                  media {
                    media_url
                    percentage
                  }
                  products {
                    id
                    uuid
                    name
                    description
                    category {
                      id
                      parent_category_id
                      name
                    }
                       variants {
                        id
                        uuid
                        name
                        price
                        base_image_url
                        is_available
                      inventory_quantity
                      selected_options {
                        name
                        value
                      }
                    }
                    medias {
                      id
                      uuid
                      media_type
                      media_url
                    }
                    rewards {
                      id
                      type
                      percentage
                      quantity
                    }
                    business {
                      id
                      business_tag
                      business_name
                      uuid
                    }
                       brand {
                        id
                        uuid
                        business_name
                        photo_url
                      }
                    regular_price
                    primary_image_url
                    stock_quantity
                    status
                  }
                  business {
                    id
                    uuid
                    business_name
                    business_tag
                    photo_url
                  }
                }
                ... on Advert {
                  id
                  uuid
                  title
                  status
                  poll_summary {
                    slug
                    value
                    count
                    percentage
                  }
                  user_poll_vote {
                    advert_uuid
                    answer
                  }
                  user {
                    business {
                      id
                      business_tag
                      business_name
                      photo_url
                      uuid
                    }
                  }
                  advert_media {
                    id
                    uuid
                    source_url
                    metadata {
                      image_url
                      video_base_url
                      media_type
                      shoppable_type
                    }
                    media {
                      media_url
                      percentage
                  }
                  product {
                  id
                    uuid
                    name
                    description
                    category {
                      id
                      parent_category_id
                      name
                    }
                    sub_category {
                      id
                      name
                    }
                    rewards {
                      id
                      type
                      percentage
                      quantity
                    }
                       variants {
                      id
                      uuid
                      name
                      price
                      base_image_url
                      is_available
                    inventory_quantity
                    selected_options {
                      name
                      value
                    }
                  }
                  medias {
                    id
                    uuid
                    media_type
                    media_url
                  }
                    business {
                      id
                      business_tag
                      business_name
                      photo_url
                      uuid
                    }
                       brand {
                      id
                      uuid
                      business_name
                      photo_url
                    }
                    regular_price
                    primary_image_url
                    stock_quantity
                    status
                  }
                  shoplist {
                    id
                      uuid
                      name
                      base_image_url
                      media_type
                      description
                      expires_at
                      status
                      media {
                        media_url
                        percentage
                      }
                      products {
                        id
                        uuid
                        name
                        description
                        category {
                          id
                          parent_category_id
                          name
                        }
                        rewards {
                          id
                          type
                          percentage
                          quantity
                        }
                        medias {
                          id
                          uuid
                          media_type
                          media_url
                        }
                        business {
                          id
                          business_tag
                          business_name
                          uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                      }
                      business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                      }
                    }
                    cta_rules {
                      cta
                      landing_page_url
                      polls {
                        question
                        answer_type
                        option_type
                        options {
                          value
                          slug
                          image_url
                        }
                      }
                    }
                    created_at
                    updated_at
                  }
                  type
                }
              }
            }
          }
        }
    `

    const response: Promise<OperationResult<{
      GetUserContents: UserContent
    }>> = this.query(requestData, {
      content_type: contentType,
      page,
      per_page: perPage,
      business_uuid,
      with_product,
    })

    return response
  }

  public SetTransactionPin = (data: MutationSetTransactionPinArgs) => {
    const requestData = `
    mutation SetTransactionPin($new_pin: String!, $old_pin: String) {
        SetTransactionPin(new_pin: $new_pin, old_pin: $old_pin) {
          uuid
          pin_is_set
        }
      }
        `

    const response: Promise<OperationResult<{
      SetTransactionPin: Profile
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateProfile = (data: MutationUpdateProfileArgs) => {
    const requestData = `
    mutation UpdateProfile($first_name: String, $last_name: String, $username: String, $profile_image: Upload, $country: String, $city: String, $date_of_birth: String, $usage_policy_accepted: Boolean, $snapping_terms_accepted: Boolean, $gender: String, $base_location: Location, $user_interests: [String!], $spend_group: String, $savings_target_amount: String) {
        UpdateProfile(
          first_name: $first_name
          last_name: $last_name
          username: $username
          profile_image: $profile_image
          country: $country
          city: $city
          date_of_birth: $date_of_birth
          usage_policy_accepted: $usage_policy_accepted
          snapping_terms_accepted: $snapping_terms_accepted
          gender: $gender
          base_location: $base_location
          user_interests: $user_interests
          spend_group: $spend_group
          savings_target_amount: $savings_target_amount
        ) {
          id
          uuid
          country
          city
          photo_url
          pin_is_set
          user_verified
          account_tier_level
          usage_policy_accepted
          snapping_terms_accepted
          spend_group
          savings_target_amount
          subscription_plan {
            uuid
            name
            price
            description
            created_at
          }
          business {
            uuid
            document
            address
            business_name
            photo_url
            verification_status
            created_at
          }
        }
      }
        `
    const response: Promise<OperationResult<{
      UpdateProfile: Profile
    }>> = this.mutation(requestData, data)

    return response
  }

  public FollowUnfollowBusiness = (
    data: MutationFollowUnfollowBusinessArgs,
  ) => {
    const requestData = `
    mutation FollowUnfollowBusiness($action: FollowAction!, $business_uuid: String!) {
      FollowUnfollowBusiness(action: $action, business_uuid: $business_uuid) 
    }
    `

    const response: Promise<OperationResult<{
      FollowUnfollowBusiness: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateBusinessProfile = (data: MutationUpdateBusinessProfileArgs) => {
    const requestData = `
    mutation UpdateBusinessProfile($business_logo: Upload, $business_document: Upload, $business_name: String, $business_email: String, $business_tag: String, $description: String, $business_social_links: SocialLinks, $business_category: String, $phone_number: String, $account_type: String, $manager_info: ManagerInfo) {
        UpdateBusinessProfile(
          business_logo: $business_logo
          business_name: $business_name
          business_email: $business_email
          business_tag: $business_tag
          description: $description
          business_social_links: $business_social_links
          business_category: $business_category
          business_document: $business_document
          phone_number: $phone_number
          account_type: $account_type
          manager_info: $manager_info
        ) {
          id
          uuid
          document
          address
          business_name
          business_tag
          description
          phone_number
          account_type
          business_social_links {
            website
            facebook
            instagram
            whatsapp
          }
          business_category
          photo_url
          verification_status
          created_at
        }
      }
        `

    const response: Promise<OperationResult<{
      UpdateBusinessProfile: Profile
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateUserSubscription = (subscription_plan_uuid: string) => {
    const requestData = `
    mutation UpdateUserSubscription($subscription_plan_uuid: String!) {
        UpdateUserSubscription(subscription_plan_uuid: $subscription_plan_uuid) {
            subscription_plan {
                uuid
                name
                price
                description
                created_at
              }
        }
      }
        `

    const response: Promise<OperationResult<{
      SetTransactionPin: Profile
    }>> = this.mutation(requestData, { subscription_plan_uuid })

    return response
  }

  public SaveUserActivity = (data: MutationSaveUserActivityArgs) => {
    const requestData = `
		mutation SaveUserActivity($user_uuid: String, $user_id: String, $stage_type: String!, $extra_uuid: String, $task_reference: String, $event: String!, $type: String!, $metadata: String) {
			SaveUserActivity(
			  user_uuid: $user_uuid
			  event: $event
			  type: $type
			  metadata: $metadata
			  user_id: $user_id
			  stage_type: $stage_type
			  extra_uuid: $extra_uuid
			  task_reference: $task_reference
			)
		  }
		`
    const response: Promise<OperationResult<{
      SaveUserActivity: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public SavePushNotificationDevice = (
    data: MutationSaveUserPushNotificationDeviceArgs,
  ) => {
    const requestData = `
    mutation SavePushNotificationDevice($device_type: String!, $device_token: String!) {
        SaveUserPushNotificationDevice(
          device_type: $device_type
          device_token: $device_token
        )
      }
    `
    const response: Promise<OperationResult<{
      SaveUserPushNotificationDevice: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public MarkNotificationAsRead = (notification_uuid: string) => {
    const requestData = `
    mutation MarkNotificationAsRead($notification_uuid: String!) {
      MarkNotificationAsRead(
        notification_uuid: $notification_uuid
      )
      }
    `
    const response: Promise<OperationResult<{
      MarkNotificationAsRead: boolean
    }>> = this.mutation(requestData, {
      notification_uuid,
    })

    return response
  }

  public InviteBusinessAdmin = (data: MutationInviteBusinessAdminArgs) => {
    const requestData = `
    mutation InviteBusinessAdmin($email: String!, $permissions: StorePermissionsInput!) {
      InviteBusinessAdmin(
        email: $email,
        permissions: $permissions
      )
      }
    `
    const response: Promise<OperationResult<{
      InviteBusinessAdmin: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public ConnectShopifyStore = (data: MutationConnectShopifyStoreArgs) => {
    const requestData = `
    mutation ConnectShopifyStore($shopify_store_url: String!) {
      ConnectShopifyStore(
        shopify_store_url: $shopify_store_url,
      )
      }
    `
    const response: Promise<OperationResult<{
      ConnectShopifyStore: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public AcceptBusinessInvitation = (invitation_code: string) => {
    const requestData = `
    mutation AcceptBusinessInvitation($invitation_code: String!) {
      AcceptBusinessInvitation(
        invitation_code: $invitation_code,
      )
      }
    `
    const response: Promise<OperationResult<{
      AcceptBusinessInvitation: boolean
    }>> = this.mutation(requestData, { invitation_code })

    return response
  }

  public UpdateBusinessAdmin = (data: MutationUpdateBusinessAdminArgs) => {
    const requestData = `
    mutation UpdateBusinessAdmin(
        $business_admin_uuid: String!
        $permissions: StorePermissionsInput
        $status: String
        ) {
      UpdateBusinessAdmin(
        business_admin_uuid: $business_admin_uuid,
        permissions: $permissions,
        status: $status
      )
      }
    `
    const response: Promise<OperationResult<{
      UpdateBusinessAdmin: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public ClaimPointGained = (month: string) => {
    const requestData = `
    mutation ClaimPointGained($month: String!) {
      ClaimPointGained(
        month: $month
      )
      }
    `
    const response: Promise<OperationResult<{
      ClaimPointGained: boolean
    }>> = this.mutation(requestData, {
      month,
    })

    return response
  }

  public AddUserToWaitlist = (data: MutationAddUserToWaitlistArgs) => {
    const requestData = `
    mutation AddUserToWaitlist($email: String!, $name: String!, $type: String!, $phone: String!) {
      AddUserToWaitlist(
        email: $email,
        name: $name,
        type: $type,
        phone: $phone
      ){
        uuid
      }
      }
    `
    const response: Promise<OperationResult<{
      AddUserToWaitlist: Waitlist
    }>> = this.mutation(requestData, data)

    return response
  }

  public DeleteUser = () => {
    const requestData = `
		mutation DeleteUser{
			DeleteUser
		  }
		`
    const response: Promise<OperationResult<{
      DeleteUser: boolean
    }>> = this.mutation(requestData, {})

    return response
  }
}
