import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full pt-3 flex flex-row items-center space-x-1 justify-center cursor-pointer"
}
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(` ${_ctx.customClass} `)
  }, [
    _createVNode(_component_swiper, {
      "slides-per-view": _ctx.slidePerView,
      "space-between": _ctx.spaceBetween,
      onSwiper: _ctx.onSwiper,
      modules: _ctx.modules,
      onSlideChange: _ctx.onSlideChange,
      class: _normalizeClass(_ctx.swiperClass),
      direction: _ctx.direction,
      autoplay: _ctx.autoPlay,
      loop: _ctx.loop,
      ref: "swiperRef",
      id: `swiperRef${_ctx.tabIndex}`
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 8, ["slides-per-view", "space-between", "onSwiper", "modules", "onSlideChange", "class", "direction", "autoplay", "loop", "id"]),
    (_ctx.showPagination)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slideCount, (slide, index) => {
            return (_openBlock(), _createElementBlock("span", {
              class: _normalizeClass(`rounded w-[25px] h-[3px] ${
          _ctx.activeSlide == index ? 'bg-primary-400' : 'bg-grey-200 bg-opacity-60'
        }`),
              onClick: ($event: any) => (_ctx.swiperInstance?.slideTo(index)),
              key: index
            }, null, 10, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}