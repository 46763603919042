import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "border-grey-50 border-[1px] flex flex-row space-x-2 items-center rounded-[8px] py-3 px-3 w-full" }
const _hoisted_2 = { class: "w-[40px]" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-[1px] items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppIcon, {
        "custom-class": 'w-8 h-8',
        name: $props.icon
      }, null, 8, ["name"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AppNormalText, { class: "!font-semibold !text-sm !text-black dark:!text-white" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.total), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_AppNormalText, { class: "!text-[11px] !text-black dark:!text-white" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($props.title), 1)
        ]),
        _: 1
      })
    ])
  ]))
}