<template>
  <div
    id=""
    :class="`${customClass} blend-in  ${
      image == '' ? `${photoUrl ? 'skeleton' : ''}` : ''
    }`"
    :style="`${
      image == ''
        ? ''
        : `background-size: cover;
      background-repeat: no-repeat;
      background-position: center;`
    } 
      ${image ? `background-image:url(${imageUrl});` : ''}  ${customStyle}`"
  >
    <slot />
  </div>
</template>
<script lang="ts">
import { Logic } from "../../";
import { defineComponent, ref, onMounted, toRef, watch } from "vue";

export default defineComponent({
  name: "AppImageLoader",
  props: {
    photoUrl: {
      type: String,
      required: false,
    },
    customClass: {
      type: String,
      default: "",
    },
    customStyle: {
      type: String,
      default: "",
    },
    enforceThumbnail: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["loaded"],
  setup(props, { emit }) {
    const image = ref("");
    const imageUrl = ref("");

    const photoUrlRef = toRef(props, "photoUrl");

    const setImage = async () => {
      image.value = "";

      if (props.enforceThumbnail) {
        await Logic.User.GetImageThumbnail(
          props.photoUrl?.replaceAll(
            "https://roof-income.nyc3.digitaloceanspaces.com",
            "https://shpt.blob.core.windows.net/shpt"
          ) || ""
        ).then((response) => {
          imageUrl.value = response || "";
        });
      } else {
        if (props.photoUrl?.replaceAll) {
          imageUrl.value =
            props.photoUrl
              ?.replaceAll(
                "https://roof-income.nyc3.digitaloceanspaces.com",
                "https://shpt.blob.core.windows.net/shpt"
              )
              .replaceAll(
                "https://shpt.blob.core.windows.net/",
                "https://shptstorage-grazapdjemh4bzct.z02.azurefd.net/"
              ) || "";
        } else {
          imageUrl.value = props.photoUrl || "";
        }
      }

      try {
        const highResImage = new Image();

        highResImage.onload = function () {
          image.value = imageUrl.value;
          emit("loaded");
        };

        highResImage.src = imageUrl.value;
      } catch (error) {
        console.log(error);
      }
    };

    onMounted(() => {
      setImage();
    });

    watch(photoUrlRef, () => {
      setImage();
    });

    return {
      image,
      imageUrl,
    };
  },
});
</script>
<style scoped>
/* Add a custom animation for the shimmer effect */
@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}
</style>
