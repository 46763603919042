<template>
  <subpage-layout :title="'Privacy Policy'" mainSectionClass="!h-fit">
    <div class="w-full flex flex-col space-y-4 h-full flex-grow px-4">
      <app-normal-text v-html="ClientAppContent?.privacy_policy || ''"></app-normal-text>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { AppNormalText } from "../../../../components";
import { Logic } from "../../../../index";
import SubpageLayout from "../../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppNormalText,
    SubpageLayout,
  },
  name: "PrivacyPolicyPage",
  setup() {
    const ClientAppContent = ref(Logic.User.ClientAppContent);

    onMounted(() => {
      Logic.User.watchProperty("ClientAppContent", ClientAppContent);
    });

    return {
      ClientAppContent,
    };
  },
});
</script>
