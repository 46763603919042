import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row space-x-2 py-3 px-2 border-b-[1px] items-center border-[#E8E8E8]" }
const _hoisted_2 = { class: "w-[40px]" }
const _hoisted_3 = { class: "flex flex-col space-y-[2px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_image_loader, {
        photoUrl: 
          _ctx.item.photo_url ? _ctx.item.photo_url : '/images/icons/user-grey-bg.svg'
        ,
        class: "w-[40px] h-[40px] rounded-full"
      }, null, 8, ["photoUrl"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_normal_text, { class: "!font-semibold text-left" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.item.name), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_app_normal_text, { class: "!text-[12px] !text-grey-500 text-left" }, {
        default: _withCtx(() => [
          _createTextVNode(" @" + _toDisplayString(_ctx.item.username), 1)
        ]),
        _: 1
      })
    ])
  ]))
}