<template>
  <subpage-layout :title="'Privacy & Support'" mainSectionClass="!h-fit">
    <div class="w-full flex flex-col space-y-3 px-4">
      <div
        v-for="(item, index) in actions"
        :key="index"
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center cursor-pointer"
        @click="handleSettings(item)"
      >
        <div class="flex flex-row space-x-2 items-center">
          <div class="w-[37px]">
            <div
              class="w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center dark:bg-slate-300 bg-white"
            >
              <app-icon :name="item.icon" :class="item.icon_size" />
            </div>
          </div>
          <app-normal-text class="!text-left !font-semibold">
            {{ item.title }}
          </app-normal-text>
        </div>
        <div class="pr-1">
          <app-icon :name="'chevron-right-grey'" class="!h-[13px]" />
        </div>
      </div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { Logic } from "../../../../index";
import { AppIcon, AppNormalText } from "../../../../components";
import SubpageLayout from "../../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    SubpageLayout,
  },
  name: "PrivacySupportPage",
  setup() {
    const actions = reactive([
      {
        title: "Support",
        icon: "support",
        path: "/others/privacy_support/support",
        icon_size: "",
      },
      {
        title: "FAQs",
        icon: "faqs",
        path: "/others/privacy_support/faqs",
        icon_size: "",
      },
      {
        title: "Privacy Policy",
        icon: "privacy-policy",
        path: "/others/privacy_support/policy",
        icon_size: "",
      },
      {
        title: "Terms of use",
        icon: "terms-of-use",
        path: "/others/privacy_support/terms",
        icon_size: "",
      },
    ]);

    const handleSettings = (item: any) => {
      Logic.Common.GoToRoute(item.path);
    };

    return {
      Logic,
      actions,
      handleSettings,
    };
  },
});
</script>
