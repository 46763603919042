<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-1"
  >
    <!-- Title -->
    <app-text-field
      :placeholder="'Name your store location'"
      :hasTitle="true"
      :padding="'py-3 px-4'"
      name="Title"
      ref="title"
      v-model="formData.title"
      :rules="[FormValidations.RequiredRule]"
      :updateValue="formData.title"
      required
    >
      <template v-slot:title>Title </template>
    </app-text-field>

    <app-select
      :placeholder="'Select Store Admin'"
      :hasTitle="true"
      v-model="formData.business_admin_id"
      :paddings="'px-3 py-3'"
      ref="businessAdmin"
      :customClass="'dark:!bg-grey-800'"
      :options="data.businessAdminOptions"
      :theme="Logic.Common.currentAppTheme()"
      autoComplete
      required
    >
      <template v-slot:title> Store Admin </template>
    </app-select>

    <app-select
      v-if="!data.is_edit"
      :placeholder="'Select area'"
      :hasTitle="true"
      v-model="formData.address"
      :paddings="'px-3 py-3'"
      ref="deliveryAddress"
      @OnSearch="handleAreaSearch"
      :options="areaOptions"
      :theme="Logic.Common.currentAppTheme()"
      :customClass="'dark:!bg-grey-800'"
      autoComplete
      :hasSearch="true"
      :searchMessage="'Search for your pick address'"
      :searchIsLoading="areaSearchIsLoading"
      required
    >
      <template v-slot:title> Contact Address </template>
    </app-select>

    <div class="w-full flex flex-col pb-4 mdlg:!pb-0 md:!pb-0">
      <app-button
        :class="`w-full py-3 ${isValid ? '' : 'opacity-50'}`"
        @click.prevent="validateForm(false)"
      >
        {{ data.is_edit ? "Update" : "Add" }}
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { SelectOption } from "../../types";
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppSelect, AppTextField } from "../AppForm";
import { AppNormalText } from "../AppTypography";
import { DeliveryLocation } from "../../gql/graphql";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
    AppSelect,
    AppButton,
    AppNormalText,
  },
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object as () => {
        is_edit: boolean;
        default: {
          location: string;
          cost: number;
          active: boolean;
          id: string;
          area: string[];
          data: DeliveryLocation;
        };
        businessAdminOptions: SelectOption[];
      },
    },
  },
  name: "AppModalAddDeliveryArea",
  setup(props) {
    const FormValidations = Logic.Form;

    const areaSearchIsLoading = ref(false);

    const formData = reactive<{
      title: string;
      phone: string;
      user_email: string;
      user_name: string;
      address: string;
      business_admin_id: string;
    }>({
      address: "",
      phone: "",
      user_email: "",
      user_name: "",
      title: "",
      business_admin_id: "",
    });

    const areaOptions = reactive<SelectOption[]>([]);

    const title = ref();
    const phone = ref();
    const email = ref();
    const user_name = ref();
    const addresss = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const isValid = computed(() => {
      return (
        formData.title.length > 0 &&
        formData.business_admin_id != "" &&
        formData.address != ""
      );
    });

    const handleAreaSearch = async (searchValue: string) => {
      Logic.Common.debounce(() => {
        try {
          const google = window.google;
          if (google) {
            const service = new google.maps.places.AutocompleteService();

            if (service && searchValue.length) {
              areaSearchIsLoading.value = true;
              areaOptions.length = 0;
              service.getQueryPredictions(
                {
                  input: searchValue,
                },
                (predictions: any) => {
                  predictions?.forEach((prediction: any) => {
                    areaOptions.push({
                      key: prediction.description,
                      value: prediction.description,
                    });
                  });
                  areaSearchIsLoading.value = false;
                }
              );
            }
          }
        } catch (error) {
          console.log(error);
        }
      }, 500);
    };

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(
        title.value,
        phone.value,
        email.value,
        user_name.value,
        addresss.value
      );
      if (props.data?.default) {
        formData.title = props.data.default.data.title || "";
        formData.phone = props.data.default.data.phone || "";
        formData.user_email = props.data.default.data.user_email || "";
        formData.user_name = props.data.default.data.user_name || "";
        formData.address = props.data.default.data.address || "";
      }
    });

    const validateForm = (isDelete = false) => {
      const status = formComp.value.validate();

      if (props.data?.businessAdminOptions) {
        const selectedOption = props.data?.businessAdminOptions.find(
          (item) => item.key == parseInt(formData.business_admin_id)
        );

        if (selectedOption) {
          const extraData = JSON.parse(selectedOption.extras || "");
          formData.phone = extraData.phone || "";
          formData.user_email = extraData.email || "";
          formData.user_name = extraData.name || "";
          // @ts-ignore
          formData.business_admin_id =
            parseInt(formData.business_admin_id) == -1
              ? 0
              : parseInt(formData.business_admin_id);
        }
      }
      if (status) {
        props.action(formData, isDelete);
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      formData,
      isValid,
      areaSearchIsLoading,
      areaOptions,
      Logic,
      validateForm,
      handleAreaSearch,
    };
  },
});
</script>
