<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Referral code field -->
    <app-select
      :placeholder="'Select issue'"
      :hasTitle="true"
      :rules="[FormValidations.RequiredRule]"
      :paddings="'!py-3 px-4'"
      v-model="formData.selected_report"
      name="Report type"
      :options="reportOptions"
      ref="report_type"
    >
      <template v-slot:title> What is the issue? </template>
    </app-select>

    <div class="w-full flex flex-row items-center justify-center space-x-3">
      <app-button
        @click.prevent="validateForm()"
        :customClass="'w-full'"
        class="py-3"
      >
        Report
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AppSelect } from "../AppForm";
import { Logic } from "../../composable";
import { AppFormWrapper } from "../AppForm";
import { AppNormalText } from "../AppTypography";
import { SelectOption } from "../../types";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppSelect,
    AppFormWrapper,
    AppNormalText,
    AppButton,
  },
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppModalReportReceipt",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      selected_report: "",
    });

    const reportOptions = reactive<SelectOption[]>([
      {
        key: "wrong_date",
        value: "Date of payment is incorrect",
      },
      {
        key: "wrong_total_amount",
        value: "Total amount is incorrect",
      },
      {
        key: "wrong_item_list",
        value: "The items are incorrect",
      },
      {
        key: "wrong_merchant_name",
        value: "The merchant name is incorrect",
      },
      {
        key: "wrong_status",
        value: "The receipt status is incorrect",
      },
    ]);

    const report_type = ref();
    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(report_type.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      report_type,
      formData,
      reportOptions,
      validateForm,
    };
  },
});
</script>
