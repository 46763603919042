import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pl-4 pb-2" }
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide" }
const _hoisted_3 = { class: "flex flex-row space-x-3 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-0 h-full flex-grow relative top-0" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-3 pt-3 px-4" }
const _hoisted_7 = {
  key: 1,
  class: "w-full flex flex-col py-4 px-8 h-[200px] rounded-[10px] bg-primary-50 dark:!bg-gray-800 justify-center items-center space-y-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_reward = _resolveComponent("app-reward")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Cashback History' }, {
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: _normalizeClass(`px-4 py-1 cursor-pointer ${
                  _ctx.selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-800'
                }  rounded-[20px]  flex flex-row items-center justify-center`),
                    onClick: ($event: any) => (_ctx.selectedFilter = item.id)
                  }, [
                    _createVNode(_component_app_normal_text, {
                      class: _normalizeClass(`${
                    _ctx.selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`),
                      "custom-class": 'whitespace-nowrap'
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["class"])
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.allRewards.length)
                ? (_openBlock(), _createBlock(_component_app_virtual_scroller, {
                    key: 0,
                    data: _ctx.allRewards,
                    pagination: _ctx.paginationData,
                    "fetch-more": _ctx.getMoreRewards
                  }, {
                    "item-content": _withCtx(({ index, item }) => [
                      (_openBlock(), _createBlock(_component_app_reward, {
                        item: item,
                        key: index,
                        onClick: ($event: any) => (item.action())
                      }, null, 8, ["item", "onClick"]))
                    ]),
                    "skeleton-loaders": _withCtx(() => _cache[1] || (_cache[1] = [
                      _createElementVNode("div", { class: "w-full flex flex-col space-y-2" }, [
                        _createElementVNode("div", { class: "w-[40%] h-[10px] rounded skeleton" }),
                        _createElementVNode("div", { class: "w-full flex flex-col space-y-2 skeleton h-[60px] rounded-[10px]" })
                      ], -1)
                    ])),
                    _: 1
                  }, 8, ["data", "pagination", "fetch-more"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_app_normal_text, { customClass: 'text-center' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.filters[_ctx.selectedIndex].emptyState.label), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_app_button, {
                      border: '',
                      padding: 'py-1 px-4',
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters[_ctx.selectedIndex].emptyState.action()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.filters[_ctx.selectedIndex].emptyState.btnText), 1)
                      ]),
                      _: 1
                    })
                  ])),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}