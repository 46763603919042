<template>
  <div class="w-full flex flex-col space-y-3 pt-2 relative">
    <app-normal-text
      :customClass="'text-left '"
      :htmlContent="data.info"
      class="!text-gray-700"
    ></app-normal-text>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
  },
  props: {
    data: {
      type: Object as () => {
        info: string;
      },
    },
  },
  name: "AppModalInfo",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
