<template>
  <app-wrapper>
    <subpage-layout :title="'Badges'">
      <div class="w-full flex flex-col space-y-3">
        <!-- Earned badges -->
        <div class="w-full flex flex-col space-y-2">
          <app-normal-text
            class="w-full !text-left !text-[#664D7E] dark:!text-primary-100 px-4 !text-sm"
          >
            Earned Badges
          </app-normal-text>

          <div
            v-if="badges.filter((item) => item.won).length === 0"
            class="w-full flex flex-row items-center justify-center h-[150px]"
          >
            <app-empty-state :title="''" :subTitle="'No earned badges yet'" />
          </div>

          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 py-2 pr-4">
              <div
                :class="`flex flex-col space-y-2 justify-center items-center w-auto cursor-pointer`"
                v-for="(badge, index) in badges.filter((item) => item.won)"
                :key="index"
                @click="showBadgeInfo(badge.data, badge.won)"
              >
                <app-image-loader
                  :photoUrl="badge.image_url"
                  class="!h-[90px] !w-[90px] mdlg:!h-[100px] mdlg:!w-[100px] rounded-full"
                />
                <app-normal-text
                  class="!text-center line-clamp-1 !font-semibold !text-[11px] mdlg:!text-xs"
                >
                  {{ badge.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Challenges -->
        <div class="w-full flex flex-col space-y-2">
          <app-normal-text
            class="w-full !text-left !text-[#664D7E] px-4 !text-sm dark:!text-primary-100"
          >
            Top Challenges
          </app-normal-text>

          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 py-2 pr-4">
              <div
                v-for="(challenge, index) in challenges"
                :key="index"
                class="w-[280px] flex flex-col"
              >
                <app-challenge
                  :data="challenge"
                  class="cursor-pointer"
                  @click="
                    Logic.Common.GoToRoute(
                      `/others/challenges/${challenge.uuid}`
                    )
                  "
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Badges to unlock -->
        <div class="w-full flex flex-col space-y-2">
          <app-normal-text
            class="w-full !text-left !text-[#664D7E] px-4 !text-sm dark:!text-primary-100"
          >
            Badges to unlock
          </app-normal-text>

          <div class="w-full grid grid-cols-3 gap-3 px-4 pt-2">
            <div
              v-for="(badge, index) in badges.filter((item) => !item.won)"
              :key="index"
              class="col-span-1 flex flex-col space-y-1 justify-center items-center cursor-pointer"
              @click="showBadgeInfo(badge.data, badge.won)"
            >
              <app-image-loader
                :photoUrl="'/images/lock-badge.svg'"
                class="!h-[75px] !w-[75px] mdlg:!h-[85px] mdlg:!w-[85px] rounded-full"
              />
              <app-normal-text
                class="!text-center line-clamp-1 !font-semibold !text-[10px] mdlg:!text-[12px]"
              >
                {{ badge.title }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <div class="h-[60px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppImageLoader,
  AppNormalText,
  AppChallenge,
  AppEmptyState,
} from "@shpt/ui-components";
import {
  badgeIsWon,
  showBadgeInfo,
} from "@shpt/ui-components/src/composable/game";
import { Badge } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppChallenge,
    AppEmptyState,
    AppWrapper,
  },
  name: "BadgesPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "AuthUserGameProfile",
        method: "GetAuthUserGameProfile",
        params: [],
        requireAuth: true,
        silentUpdate: true,
        ignoreProperty: true,
      },
      {
        domain: "Game",
        property: "ManyChallenges",
        method: "GetChallenges",
        params: [],
        requireAuth: true,
        silentUpdate: true,
      },
    ],
    tracking_data: {
      lable: "Badges Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Badges",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const ManyChallenges = ref(Logic.Game.ManyChallenges);

    const badges = reactive<
      {
        title: string;
        image_url: string;
        won: boolean;
        data: Badge;
      }[]
    >([]);

    const challenges = reactive<
      {
        image_url: string;
        title: string;
        points: string;
        uuid: string;
      }[]
    >([]);

    const setChallenges = () => {
      challenges.length = 0;
      ManyChallenges.value?.forEach((item) => {
        if (item.photo_url) {
          challenges.push({
            uuid: item.uuid,
            title: item.title,
            image_url: item.photo_url || "",
            points: Logic.Common.convertToMoney(
              item.rule?.reward_amount || 0,
              false,
              ""
            ),
          });
        }
      });
    };

    const setBadges = () => {
      badges.length = 0;
      AuthUser.value?.game_profile?.pending_badges?.forEach((item) => {
        badges.push({
          title: item.title,
          image_url: badgeIsWon(item.id)
            ? item.photo_url
            : "/images/badges/no-badge.svg",
          won: badgeIsWon(item.id),
          data: item,
        });
      });

      badges.sort((a, b) => {
        return a.won === b.won ? 0 : a.won ? -1 : 1;
      });
    };

    onIonViewWillEnter(() => {
      setBadges();
    });

    watch(ManyChallenges, () => {
      setChallenges();
    });

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Game.watchProperty("ManyChallenges", ManyChallenges);
      setChallenges();
      setBadges();
    });

    return {
      challenges,
      Logic,
      badges,
      showBadgeInfo,
    };
  },
});
</script>
