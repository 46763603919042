import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'Choose date',
        hasTitle: true,
        padding: 'py-4 px-4',
        rules: [_ctx.FormValidations.RequiredRule],
        modelValue: _ctx.formData.from_date,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.from_date) = $event)),
        name: "Start date",
        ref: "from_date",
        type: "date"
      }, {
        title: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode(" Start Date ")
        ])),
        "inner-prefix": _withCtx(() => [
          _createVNode(_component_app_icon, {
            name: 'calendar',
            customClass: 'h-[17px]'
          })
        ]),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Choose date',
        hasTitle: true,
        padding: 'py-4 px-4',
        rules: [
        _ctx.FormValidations.RequiredRule,
        _ctx.FormValidations.customValidator(
          _ctx.Logic.Common.momentInstance(_ctx.formData.from_date).isBefore(
            _ctx.Logic.Common.momentInstance(_ctx.formData.to_date)
          ),
          `Date must be greater that the Start Date`
        ),
      ],
        modelValue: _ctx.formData.to_date,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.to_date) = $event)),
        name: "End date",
        ref: "to_date",
        type: "date",
        "prevent-back-date": true,
        "miminum-date": _ctx.from_date_ref
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" End Date ")
        ])),
        "inner-prefix": _withCtx(() => [
          _createVNode(_component_app_icon, {
            name: 'calendar',
            customClass: 'h-[17px]'
          })
        ]),
        _: 1
      }, 8, ["rules", "modelValue", "miminum-date"])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}