import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "space-y-1"
}
const _hoisted_4 = {
  key: 1,
  class: "space-y-1"
}
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 items-center justify-center h-[100px] border-[1px] border-dashed border-grey-200 rounded-[10px] dark:border-grey-800" }
const _hoisted_7 = { class: "!font-semibold" }
const _hoisted_8 = { class: "w-full flex flex-col space-y-2 pb-4 pt-2 bg-white dark:bg-black sticky bottom-0 md:!pb-0 mdlg:!pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.data?.type === 'csv')
        ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
            _createElementVNode("li", null, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" 1. Copy this "),
                  _createElementVNode("a", {
                    href: "https://docs.google.com/spreadsheets/d/1ALrwS9HL9etXeVBqUie6xcfwgSnt0t6P7PpjnZKtUcw/edit?usp=sharing",
                    target: "_blank",
                    class: "!text-primary-main dark:!text-primary-300 underline cursor-pointer"
                  }, "template", -1),
                  _createTextVNode(" and fill it with your products ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" 2. Download as CSV and upload below ")
                ])),
                _: 1
              })
            ])
          ]))
        : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            _createElementVNode("li", null, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" 1. Go to your store admin and export your products as CSV ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("li", null, [
              _createVNode(_component_app_normal_text, null, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" 2. Upload the CSV file below ")
                ])),
                _: 1
              })
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        accept: 'text/csv',
        class: "cursor-pointer",
        modelValue: _ctx.productCSVFile,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productCSVFile) = $event))
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_app_normal_text, { class: "!text-grey-500 dark:!text-grey-400" }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Upload CSV file ")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue"]),
      (_ctx.productCSVFile)
        ? (_openBlock(), _createBlock(_component_app_normal_text, {
            key: 0,
            class: "!text-left"
          }, {
            default: _withCtx(() => [
              _cache[7] || (_cache[7] = _createTextVNode(" File: ")),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.productCSVFile?.name), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_app_button, {
        class: _normalizeClass(
          _ctx.productCSVFile
            ? 'w-full !py-3'
            : 'w-full !py-3 !opacity-50 cursor-not-allowed'
        ),
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.importProducts()), ["prevent"])),
        loading: _ctx.loadingImport
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode(" Import Products ")
        ])),
        _: 1
      }, 8, ["class", "loading"])
    ])
  ]))
}