<template>
  <div class="w-full flex flex-col space-y-2">
    <div
      v-for="(eachItem, index) in items"
      :key="index"
      :class="`w-full flex ${
        eachItem.value_is_long ? 'flex-col space-y-1' : 'flex-row space-x-2'
      }  items-center `"
    >
      <div
        :class="` ${
          eachItem.value_is_long ? 'w-full' : 'w-[40%]'
        } flex flex-row items-start`"
      >
        <app-normal-text class="!text-grey-700 !capitalize">
          {{ eachItem.name }}:
        </app-normal-text>
      </div>
      <div class="w-full flex flex-row text-left">
        <app-normal-text
          class="!text-base-black !font-semibold !leading-loose"
          v-if="!eachItem.is_badge"
        >
          {{ eachItem.value }}
        </app-normal-text>
        <AppBadge :color="eachItem.badge_color" v-if="eachItem.is_badge">
          {{ eachItem.value }}
        </AppBadge>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import AppBadge from "../AppBadge";
import { AppNormalText } from "../AppTypography";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppBadge,
  },
  props: {
    items: {
      type: Array as () => {
        name: string;
        value: string;
        value_is_long?: boolean;
        is_badge?: boolean;
        badge_color?: string;
      }[],
      required: true,
    },
    customClass: {
      type: String,
    },
  },
  name: "AppSimpleList",
  setup() {
    return {};
  },
});
</script>
