import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[500px]"
}
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "absolute w-full h-full rounded-[10px] bg-black !bg-opacity-30 flex items-center justify-center" }
const _hoisted_7 = {
  key: 1,
  class: "w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[0] || (_cache[0] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ])))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.instagramVideos.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.instagramVideos, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass(`col-span-1 flex flex-col space-y-2 border-[1px] border-gray-300 dark:border-gray-500 rounded-[10px] px-3 py-3 cursor-pointer ${
            _ctx.selectedMedia == item.media_id
              ? 'border-primary-400 dark:border-primary-400 border-[2px]'
              : ''
          }`),
                  onClick: ($event: any) => (_ctx.selectInstagramVideo(item))
                }, [
                  _createVNode(_component_app_image_loader, {
                    "photo-url": item.media_thumbnail,
                    class: "w-full h-[200px] rounded-[10px] md:h-[300px] mdlg:h-[300px] relative"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_app_icon, {
                          name: 'play',
                          class: "!h-[23px]"
                        })
                      ])
                    ]),
                    _: 2
                  }, 1032, ["photo-url"]),
                  _createVNode(_component_app_normal_text, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.media_title), 1)
                    ]),
                    _: 2
                  }, 1024)
                ], 10, _hoisted_5))
              }), 128))
            : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_app_empty_state, {
                  title: 'No Instagram Videos Here',
                  subTitle: 'All available instagram videos will show up here'
                })
              ]))
        ]))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[50px]" }, null, -1))
  ]))
}