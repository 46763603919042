import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-row items-center space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (i <= $props.rating)
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 0,
              name: "star",
              "custom-class": `${$props.customClass}`
            }, null, 8, ["custom-class"]))
          : (_openBlock(), _createBlock(_component_app_icon, {
              key: 1,
              name: "empty-star",
              "custom-class": `${$props.customClass}`
            }, null, 8, ["custom-class"]))
      ], 64))
    }), 64))
  ]))
}