<template>
  <span> <img :src="qrcodeSrc" :class="`${customClass}`" /></span>
</template>
<script lang="ts">
import Qrcode from "qrcode";
import { defineComponent, onMounted, ref } from "vue";
export default defineComponent({
  props: {
    qrcodeValue: {
      type: String,
      required: true,
    },
    customClass: {
      type: String,
    },
  },
  name: "AppQrcode",

  setup(props) {
    const qrcodeSrc = ref("");
    onMounted(() => {
      Qrcode.toDataURL(props.qrcodeValue)
        .then((url: string) => {
          qrcodeSrc.value = url;
        })
        .catch((err: any) => {
          console.error(err);
        });
    });
    return {
      Qrcode,
      qrcodeSrc,
    };
  },
});
</script>
