import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pl-1" }
const _hoisted_2 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3 pb-4 mdlg:!pb-0 md:!pb-0"
}
const _hoisted_3 = {
  key: 0,
  class: "col-span-1 flex flex-col"
}
const _hoisted_4 = {
  key: 2,
  class: "w-full flex flex-col pb-4 mdlg:!pb-0 md:!pb-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select, {
        placeholder: 'Select quantity',
        hasTitle: true,
        modelValue: _ctx.formData.quantity,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.quantity) = $event)),
        paddings: 'px-4 py-3',
        ref: "quantity",
        customClass: 'dark:!bg-grey-800',
        options: _ctx.quantityOptons(),
        updateKey: _ctx.formData.quantity,
        theme: _ctx.Logic.Common.currentAppTheme()
      }, {
        title: _withCtx(() => _cache[6] || (_cache[6] = [
          _createTextVNode(" Quantity ")
        ])),
        _: 1
      }, 8, ["modelValue", "options", "updateKey", "theme"]),
      false
        ? (_openBlock(), _createBlock(_component_app_select, {
            key: 0,
            placeholder: 'Select quantity',
            hasTitle: true,
            paddings: 'py-3 px-4',
            modelValue: _ctx.formData.type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.type) = $event)),
            customClass: 'dark:!bg-grey-800',
            ref: "type",
            options: _ctx.rewardTypeOptions,
            theme: _ctx.Logic.Common.currentAppTheme(),
            updateKey: _ctx.formData.type
          }, {
            title: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Reward type ")
            ])),
            _: 1
          }, 8, ["modelValue", "options", "theme", "updateKey"]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter percentage',
        hasTitle: true,
        type: 'number',
        padding: 'py-3 px-4',
        name: "Reward percentage",
        ref: "percentage",
        modelValue: _ctx.formData.percentage,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.percentage) = $event)),
        rules: [_ctx.FormValidations.RequiredRule],
        updateValue: _ctx.formData.percentage
      }, {
        title: _withCtx(() => _cache[8] || (_cache[8] = [
          _createTextVNode("Reward Percentage ")
        ])),
        "inner-suffix": _withCtx(() => [
          _createElementVNode("span", _hoisted_1, [
            _createVNode(_component_app_normal_text, { color: 'text-grey-700' }, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("%")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "rules", "updateValue"]),
      (_ctx.data.is_edit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(` ${
          _ctx.data.default?.quantity == '-1' ? 'col-span-2' : 'col-span-1'
        } flex flex-col`)
            }, [
              _createVNode(_component_app_button, {
                class: _normalizeClass(`w-full py-3 ${_ctx.isValid ? '' : 'opacity-50'}`),
                onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.validateForm(false)), ["prevent"]))
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode(" Update ")
                ])),
                _: 1
              }, 8, ["class"])
            ], 2),
            (_ctx.data.default?.quantity != '-1')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_app_button, {
                    class: _normalizeClass(`w-full py-3 !bg-error-50 !text-error-main`),
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.validateForm(true)), ["prevent"]))
                  }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Remove ")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_app_button, {
              class: _normalizeClass(`w-full py-3 ${_ctx.isValid ? '' : 'opacity-50'}`),
              onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.validateForm(false)), ["prevent"]))
            }, {
              default: _withCtx(() => _cache[12] || (_cache[12] = [
                _createTextVNode(" Add ")
              ])),
              _: 1
            }, 8, ["class"])
          ]))
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}