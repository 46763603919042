import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Logic.Common.currentBuildType() == 'mobile')
      ? (_openBlock(), _createBlock(_component_ion_page, {
          key: 0,
          registerIonPage: _ctx.registerIonPage,
          id: "ionPageApp",
          class: _normalizeClass(`${_ctx.innerClass}`)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_content, {
              "scroll-y": false,
              id: "mainContent",
              class: _normalizeClass(`${_ctx.innerClass}`)
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["class"])
          ]),
          _: 3
        }, 8, ["registerIonPage", "class"]))
      : _createCommentVNode("", true),
    (_ctx.Logic.Common.currentBuildType() == 'web')
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(`w-full h-full ${_ctx.innerClass} `),
          id: "ionPageApp"
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}