<template>
  <div class="w-full flex flex-col space-y-4">
    <div class="w-full flex flex-row justify-center items-center">
      <div
        class="h-[90px] w-[90px] rounded-full bg-shop_deep_purple_200 flex flex-col items-center justify-center"
      >
        <img src="/images/lock.png" class="w-[60%]" />
      </div>
    </div>
    <div class="w-full flex flex-col space-y-3">
      <app-otp-input
        :numberOfInput="4"
        :alignmentStyle="'items-center justify-between'"
        :sizeStyle="'w-[74px] h-[70px] xs:w-[60px] xs:h-[50px]'"
        :otp-value="pinValue"
        @on-filled="otpIsFilled"
        :onChangeOTP="() => {}"
      />
    </div>

    <div class="w-full flex flex-col pt-3">
      <app-keyboard :hasFingerPrint="true" v-model="pinValue" />
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import { AppOtpInput, AppKeyboard } from "../AppForm";
import { Logic } from "../../composable";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppOtpInput,
    AppKeyboard,
    AppNormalText,
  },
  name: "AppModalEnterTransactionPin",
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object as () => {
        extras: any;
      },
    },
  },
  setup(props) {
    const pinValue = ref("");

    const otpIsFilled = (value: any) => {
      if (localStorage.getItem("trans_pin") == pinValue.value) {
        if (props.action) {
          props.action(props.data);
          Logic.Common.showModal({ show: false });
        }
      } else {
        Logic.Common.showError(
          undefined,
          "Transaction pin is incorrect. Try again"
        );
        pinValue.value = "";
      }
    };
    return {
      pinValue,
      otpIsFilled,
    };
  },
});
</script>
