import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, vModelDynamic as _vModelDynamic, withModifiers as _withModifiers, withDirectives as _withDirectives, vModelText as _vModelText, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col relative" }
const _hoisted_2 = {
  key: 0,
  class: "text-error-main pl-[2px]"
}
const _hoisted_3 = ["tabindex"]
const _hoisted_4 = ["placeholder", "disabled", "type"]
const _hoisted_5 = ["placeholder", "disabled", "type", "rows"]
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-row pt-1 justify-between items-center"
}
const _hoisted_7 = {
  class: "w-full mdlg:!w-[60%] md:!w-[80%] grid grid-cols-12 h-full relative",
  id: "modalContent"
}
const _hoisted_8 = { class: "col-span-12 mdlg:!col-span-6 md:!col-span-8 relative h-full flex flex-col items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center" }
const _hoisted_9 = { class: "flex items-center justify-center sticky top-0 flex-col bg-white w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_calendar = _resolveComponent("app-calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasTitle)
      ? (_openBlock(), _createBlock(_component_app_normal_text, {
          key: 0,
          customClass: "!pb-2 dark:!text-white"
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "title", {}, undefined, true),
            (_ctx.required)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, "*"))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "w-full flex flex-row items-center",
      tabindex: _ctx.tabIndex,
      onFocus: _cache[14] || (_cache[14] = ($event: any) => (_ctx.isFocused = true)),
      onBlur: _cache[15] || (_cache[15] = ($event: any) => (_ctx.isFocused = false)),
      onClick: _cache[16] || (_cache[16] = _withModifiers(($event: any) => (
        _ctx.action ? _ctx.action() : _ctx.fieldType == 'date' ? (_ctx.ShowCalendarModal = true) : ''
      ), ["stop"]))
    }, [
      _renderSlot(_ctx.$slots, "outer-prefix", {}, undefined, true),
      _createElementVNode("div", {
        class: _normalizeClass(`flew-grow w-full space-x-2 flex-row flex items-center focus:border-primary-400 focus:dark:border-primary-200 justify-between rounded-[8px] border-[1px] bg-grey-50 border-grey-50 dark:!bg-grey-900  ${_ctx.padding} ${_ctx.customClass}  ${
          _ctx.isFocused ? `${_ctx.focusBorder} border-[1px]` : ''
        } ${_ctx.validationStatus == false ? '!border-error-500 !border-[1px]' : ''}`),
        onClick: _cache[13] || (_cache[13] = _withModifiers(($event: any) => (
          _ctx.action ? _ctx.action() : _ctx.fieldType == 'date' ? (_ctx.ShowCalendarModal = true) : ''
        ), ["stop"]))
      }, [
        _renderSlot(_ctx.$slots, "inner-prefix", {}, undefined, true),
        (_ctx.fieldType == 'date')
          ? (_openBlock(), _createBlock(_component_app_normal_text, {
              key: 0,
              class: _normalizeClass(`text-left   ${_ctx.inputStyle}`)
            }, {
              default: _withCtx(() => [
                (_ctx.content.length == 0)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(`text-grey-400  ${_ctx.inputStyle}`)
                    }, _toDisplayString(_ctx.placeholder), 3))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(_ctx.content), 1)
              ]),
              _: 1
            }, 8, ["class"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_ctx.isTextarea)
                ? _withDirectives((_openBlock(), _createElementBlock("input", {
                    key: 0,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.content) = $event)),
                    placeholder: _ctx.placeholder,
                    onFocus: _cache[1] || (_cache[1] = ($event: any) => {
              _ctx.isFocused = true;
              _ctx.$emit('focused');
            }),
                    onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.applyContentRule && _ctx.applyContentRule(...args))),
                    onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('keyPressed', _ctx.content))),
                    onBlur: _cache[4] || (_cache[4] = ($event: any) => {
              _ctx.isFocused = false;
              _ctx.checkValidation();
              _ctx.$emit('blurred');
            }),
                    onKeypress: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
                    disabled: _ctx.fieldType == 'date' ? true : _ctx.disabled,
                    type: _ctx.fieldType == 'date' ? 'text' : _ctx.fieldType,
                    onPaste: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handlePasteAction && _ctx.handlePasteAction(...args))),
                    class: _normalizeClass(` text-black dark:!text-white flex-grow bg-transparent placeholder-grey-400 dark:!placeholder-grey-200 focus input w-full    dark:disabled:!placeholder-white focus:outline-none ${_ctx.inputStyle}`),
                    onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (
              _ctx.action ? _ctx.action() : _ctx.fieldType == 'date' ? (_ctx.ShowCalendarModal = true) : ''
            ), ["stop"]))
                  }, null, 42, _hoisted_4)), [
                    [_vModelDynamic, _ctx.content]
                  ])
                : _createCommentVNode("", true),
              (_ctx.isTextarea)
                ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                    key: 1,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.content) = $event)),
                    placeholder: _ctx.placeholder,
                    onFocus: _cache[9] || (_cache[9] = ($event: any) => (_ctx.isFocused = true)),
                    onBlur: _cache[10] || (_cache[10] = ($event: any) => {
              _ctx.isFocused = false;
              _ctx.checkValidation();
            }),
                    onKeypress: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.isNumber && _ctx.isNumber(...args))),
                    disabled: _ctx.disabled,
                    type: _ctx.fieldType,
                    class: _normalizeClass(` text-black dark:!text-white flex-grow bg-transparent placeholder-grey-400 dark:placeholder-grey-200 focus input w-full focus:outline-none ${_ctx.inputStyle} `),
                    rows: _ctx.textAreaRow
                  }, null, 42, _hoisted_5)), [
                    [_vModelText, _ctx.content]
                  ])
                : _createCommentVNode("", true)
            ], 64)),
        _renderSlot(_ctx.$slots, "inner-suffix", {}, undefined, true),
        (_ctx.type == 'password')
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 2,
              name: `${
            _ctx.fieldType == 'password'
              ? `show${_ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-wh'}`
              : `hide${_ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-wh'}`
          }`,
              customClass: `${
            _ctx.fieldType == 'password' ? 'h-[13px]' : 'h-[15px]'
          } cursor-pointer`,
              onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (
            _ctx.fieldType == 'password' ? (_ctx.fieldType = 'text') : (_ctx.fieldType = 'password')
          ), ["stop"]))
            }, null, 8, ["name", "customClass"]))
          : _createCommentVNode("", true)
      ], 2),
      _renderSlot(_ctx.$slots, "outer-suffix", {}, undefined, true)
    ], 40, _hoisted_3),
    (!_ctx.validationStatus || _ctx.maxCharacter > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("span", {
            customClass: ' text-left',
            class: _normalizeClass(`!text-error-500 dark:!text-error-400 ${
          !_ctx.validationStatus ? '' : 'invisible'
        }`)
          }, _toDisplayString(_ctx.errorMessage), 3),
          (_ctx.maxCharacter > 0)
            ? (_openBlock(), _createBlock(_component_app_normal_text, {
                key: 0,
                class: "!text-[12px] text-gray-600"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.content.length) + "/" + _toDisplayString(_ctx.maxCharacter), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.ShowCalendarModal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "fixed top-0 left-0 z-[99999999999999999] bg-black !bg-opacity-30 dark:!bg-opacity-50 flex w-full h-full flex-row items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center",
            onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.ShowCalendarModal = false))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _cache[21] || (_cache[21] = _createElementVNode("div", { class: "hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0" }, null, -1)),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", {
                  onClick: _withModifiers(($event: any) => (true), ["stop"]),
                  class: "rounded-t-2xl mdlg:!rounded-[10px] md:!rounded-[10px] flex flex-col space-y-2 bg-white dark:!bg-black dark:border-[1px] dark:border-gray-100 w-full absolute mdlg:!relative md:!relative overflow-y-auto h-auto max-h-auto bottom-0 left-0 pb-3 px-3 mdlg:!pb-4 md:!pb-4 lg:!text-sm mdlg:!text-[12px] text-xs"
                }, [
                  _cache[19] || (_cache[19] = _createElementVNode("div", { class: "flex items-center justify-center sticky top-0 bg-white dark:!bg-black w-full pt-3" }, [
                    _createElementVNode("span", { class: "bg-gray-500 dark:bg-gray-200 rounded-full w-[30px] h-[4px]" })
                  ], -1)),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_app_normal_text, { "custom-class": "!text-xs font-semibold w-full text-left py-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_app_calendar, {
                    modelValue: _ctx.content,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.content) = $event)),
                    closeModal: 
                  () => {
                    _ctx.ShowCalendarModal = false;
                  }
                ,
                    defaultDate: _ctx.content,
                    preventBackDate: _ctx.preventBackDate,
                    miminumDate: _ctx.miminumDate
                  }, null, 8, ["modelValue", "closeModal", "defaultDate", "preventBackDate", "miminumDate"]),
                  _cache[20] || (_cache[20] = _createElementVNode("div", { class: "pt-2 sticky bottom-0 bg-white w-full" }, null, -1))
                ])
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("div", { class: "hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0" }, null, -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]))
  ]))
}