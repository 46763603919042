import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, {
      htmlContent: _ctx.Logic.User.ClientAppContent?.terms_and_condition || ''
    }, null, 8, ["htmlContent"]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "h-[40px]" }, null, -1))
  ]))
}