import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-row text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_AppBadge = _resolveComponent("AppBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (eachItem, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass(`w-full flex ${
        eachItem.value_is_long ? 'flex-col space-y-1' : 'flex-row space-x-2'
      }  items-center `)
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(` ${
          eachItem.value_is_long ? 'w-full' : 'w-[40%]'
        } flex flex-row items-start`)
        }, [
          _createVNode(_component_app_normal_text, { class: "!text-grey-700 !capitalize" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(eachItem.name) + ": ", 1)
            ]),
            _: 2
          }, 1024)
        ], 2),
        _createElementVNode("div", _hoisted_2, [
          (!eachItem.is_badge)
            ? (_openBlock(), _createBlock(_component_app_normal_text, {
                key: 0,
                class: "!text-base-black !font-semibold !leading-loose"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(eachItem.value), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (eachItem.is_badge)
            ? (_openBlock(), _createBlock(_component_AppBadge, {
                key: 1,
                color: eachItem.badge_color
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(eachItem.value), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
            : _createCommentVNode("", true)
        ])
      ], 2))
    }), 128))
  ]))
}