import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, renderList as _renderList, withModifiers as _withModifiers, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-row px-4 justify-between items-center" }
const _hoisted_4 = { class: "flex flex-col space-y-1 justify-start" }
const _hoisted_5 = {
  key: 0,
  class: "w-full flex flex-row space-x-2 items-center"
}
const _hoisted_6 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_7 = {
  key: 0,
  class: "w-full flex flex-col space-y-2 px-4"
}
const _hoisted_8 = { class: "w-full flex flex-col space-y-1 px-4" }
const _hoisted_9 = {
  key: 0,
  class: "w-full flex flex-row justify-start"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_12 = { class: "h-[82px]" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "flex flex-col items-end w-full justify-between space-y-1" }
const _hoisted_15 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_16 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_17 = { class: "w-full flex flex-row space-x-2 justify-between items-end" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "w-[28px] h-[28px] bg-[#F7F7F7] dark:bg-grey-800 flex items-center justify-center" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = {
  key: 1,
  class: "w-full flex flex-col pt-3"
}
const _hoisted_22 = {
  key: 0,
  class: "w-full flex flex-row justify-start"
}
const _hoisted_23 = ["onClick"]
const _hoisted_24 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_25 = { class: "h-[82px] !w-[120px]" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "flex flex-col items-end w-full justify-between space-y-1" }
const _hoisted_28 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_29 = { class: "w-full flex flex-row items-center space-x-2" }
const _hoisted_30 = { class: "w-full flex flex-row space-x-2 justify-between items-end" }
const _hoisted_31 = {
  key: 2,
  class: "w-full flex flex-col items-center justify-center h-[200px]"
}
const _hoisted_32 = { class: "w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black" }
const _hoisted_33 = { class: "flex flex-col space-y-[3px]" }
const _hoisted_34 = { class: "flex flex-row space-x-1 justify-start flex-grow items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_badge = _resolveComponent("app-badge")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_cart = _resolveComponent("app-cart")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        mainSectionClass: 
        _ctx.Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      
      }, _createSlots({
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_header_text, { class: "!text-base" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.SingleShoplistCollection?.name.includes("base-cart")
                  ? "Your Shopping Cart"
                  : _ctx.SingleShoplistCollection?.name), 1)
                  ]),
                  _: 1
                }),
                (_ctx.SingleShoplistCollection?.collections?.length || _ctx.productLists.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (_ctx.SingleShoplistCollection?.type != 'user-generated')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _createVNode(_component_app_normal_text, { class: "!text-primary-main" }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Expires " + _toDisplayString(_ctx.Logic.Common.timeFromNow(_ctx.SingleShoplistCollection?.expires_at)), 1)
                              ]),
                              _: 1
                            }),
                            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50" }, null, -1))
                          ], 64))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_app_normal_text, { class: "!text-success-500" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.totalReward)) + " Cashback " + _toDisplayString(_ctx.totalDiscount
                      ? `and (${_ctx.Logic.Common.convertToMoney(
                          _ctx.totalDiscount,
                          true,
                          "ngn"
                        )} off)`
                      : ""), 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            (_ctx.SingleShoplistCollection?.description)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_app_normal_text, { class: "!text-left !text-[#5A5A5A] dark:!text-grey-200" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.SingleShoplistCollection?.description), 1)
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (
              _ctx.SingleShoplistCollection?.type == 'user-generated' && _ctx.productLists.length
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                    _createVNode(_component_app_normal_text, { class: "!text-grey-main dark:!text-grey-200" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Product" + _toDisplayString(_ctx.productLists.length > 1 ? "s" : "") + " (" + _toDisplayString(_ctx.productLists.length) + ") ", 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productLists, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 py-3 cursor-pointer",
                  onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(`/shoplist/product/${item.uuid}`))
                }, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createVNode(_component_app_image_loader, {
                        photoUrl: item.base_image,
                        class: "rounded-[4.4px] h-[88px] w-[88px] relative"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.SingleShoplistCollection?.type == 'user-generated')
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: "absolute bottom-0 left-0 flex items-center justify-center w-full px-2 py-2 cursor-pointer",
                                onClick: _withModifiers(($event: any) => (_ctx.removeProductFromCollection(item.id)), ["stop"])
                              }, [
                                _createVNode(_component_app_badge, {
                                  color: 'red',
                                  class: "!text-[9px] !rounded-[10px] !py-[1px]"
                                }, {
                                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                                    _createTextVNode(" Remove ")
                                  ])),
                                  _: 1
                                })
                              ], 8, _hoisted_13))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["photoUrl"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_app_header_text, { class: "!text-sm text-left" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.title), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", _hoisted_16, [
                          _createVNode(_component_app_badge, {
                            color: item.reward_type == 'cashback' ? 'purple' : 'purple',
                            class: "capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" ₦" + _toDisplayString(_ctx.numberToAbbrev(item.reward_point)) + " Cashback ", 1)
                            ]),
                            _: 2
                          }, 1032, ["color"]),
                          _cache[4] || (_cache[4] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200" }, null, -1)),
                          _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900 dark:!text-grey-200" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.main_category), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.price, true, "ngn")), 1)
                          ]),
                          _: 2
                        }, 1024),
                        _createElementVNode("div", {
                          class: "flex flex-col space-y-1 relative pt-2",
                          onClick: _withModifiers(($event: any) => (null), ["stop"])
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(`flex flex-row items-center rounded-[13.83px] ${
                        item.max_qty == 0 ? 'opacity-50' : ''
                      }`)
                          }, [
                            _createElementVNode("div", {
                              onClick: _withModifiers(($event: any) => (item.qty > 1 ? item.qty-- : null), ["stop"]),
                              class: "w-[28px] h-[28px] rounded-l-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                            }, [
                              _createVNode(_component_app_normal_text, { class: "!text-[13px] !text-primary-400 dark:!text-gray-100" }, {
                                default: _withCtx(() => _cache[5] || (_cache[5] = [
                                  _createTextVNode(" - ")
                                ])),
                                _: 1
                              })
                            ], 8, _hoisted_18),
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_app_normal_text, { class: "!text-grey-900 dark:!text-grey-50 !font-semibold" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.max_qty > 0 ? item.qty : "0"), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", {
                              onClick: _withModifiers(($event: any) => (item.qty < item.max_qty ? item.qty++ : null), ["stop"]),
                              class: "w-[28px] h-[28px] rounded-r-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                            }, [
                              _createVNode(_component_app_normal_text, { class: "!text-[13px] !text-primary-400 dark:!text-gray-100" }, {
                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                  _createTextVNode(" + ")
                                ])),
                                _: 1
                              })
                            ], 8, _hoisted_20)
                          ], 2)
                        ])
                      ])
                    ])
                  ])
                ], 8, _hoisted_10))
              }), 128)),
              (_ctx.SingleShoplistCollection?.collections?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    (_ctx.SingleShoplistCollection?.type == 'user-generated')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createVNode(_component_app_normal_text, { class: "!text-grey-main dark:!text-grey-200" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Shoplist" + _toDisplayString(_ctx.SingleShoplistCollection?.collections?.length > 1 ? "s" : "") + " (" + _toDisplayString(_ctx.SingleShoplistCollection?.collections?.length) + ") ", 1)
                            ]),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.SingleShoplistCollection?.collections, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 py-3 cursor-pointer",
                        onClick: ($event: any) => (_ctx.goToShoplist(item, item.uuid))
                      }, [
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createVNode(_component_app_media_box, {
                              data: _ctx.getMediaBox(item),
                              isVideo: false,
                              class: "!h-[82px] !w-[82px] !rounded-[4.4px]",
                              isBasketType: true,
                              enforceGrid: true
                            }, {
                              default: _withCtx(() => [
                                (_ctx.SingleShoplistCollection?.type == 'user-generated')
                                  ? (_openBlock(), _createElementBlock("div", {
                                      key: 0,
                                      class: "absolute bottom-0 left-0 flex items-center justify-center w-full px-2 py-2 cursor-pointer",
                                      onClick: _withModifiers(($event: any) => (_ctx.removeProductFromCollection(item.id, 'collection')), ["stop"])
                                    }, [
                                      _createVNode(_component_app_badge, {
                                        color: 'red',
                                        class: "!text-[9px] !rounded-[10px] !py-[1px]"
                                      }, {
                                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                                          _createTextVNode(" Remove ")
                                        ])),
                                        _: 1
                                      })
                                    ], 8, _hoisted_26))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["data"])
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_app_header_text, { class: "!text-sm text-left" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.name), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("div", _hoisted_29, [
                                _createVNode(_component_app_badge, {
                                  color: 
                          _ctx.getMediaBox(item).reward_type == 'cashback'
                            ? 'purple'
                            : 'purple'
                        ,
                                  class: "capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.getMediaBox(item).points || 0)) + " Cashback ", 1)
                                  ]),
                                  _: 2
                                }, 1032, ["color"]),
                                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200" }, null, -1)),
                                _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-grey-900 dark:!text-grey-200" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.getMediaBox(item).main_category), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_30, [
                              _createVNode(_component_app_header_text, { class: "!text-sm" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                          _ctx.getMediaBox(item).price || 0,
                          true,
                          "ngn"
                        )), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ])
                        ])
                      ], 8, _hoisted_23))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (!_ctx.SingleShoplistCollection?.collections?.length && !_ctx.productLists.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createVNode(_component_app_empty_state, {
                      title: 'No items',
                      subTitle: `Items in ${
                _ctx.SingleShoplistCollection?.name.includes('base-cart')
                  ? 'Your Shopping Cart'
                  : `${_ctx.SingleShoplistCollection?.name} Shoplist`
              } will appear here`
                    }, null, 8, ["subTitle"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ]),
          (_ctx.SingleShoplistCollection?.collections?.length || _ctx.productLists.length)
            ? (_openBlock(), _createBlock(_component_fixed_container, {
                key: 0,
                customClass: "!px-0 !space-y-0"
              }, {
                default: _withCtx(() => [
                  (_ctx.SingleShoplistCollection?.type != 'user-generated')
                    ? (_openBlock(), _createBlock(_component_app_cart, { key: 0 }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_32, [
                    _createElementVNode("div", _hoisted_33, [
                      _createVNode(_component_app_header_text, { class: "!text-base" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.totalCost - _ctx.totalDiscount, true, "ngn")), 1)
                        ]),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_app_icon, {
                          name: 'cashback-icon',
                          customClass: 'h-[15px]'
                        }),
                        _createVNode(_component_app_normal_text, { class: "!text-[#898989] dark:!text-grey-200" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.totalReward)) + " Cashback ", 1)
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    (!_ctx.fromPreview && _ctx.SingleShoplistCollection?.type == 'user-generated')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buyNow && _ctx.buyNow(...args)))
                        }, [
                          _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white" }, {
                            default: _withCtx(() => _cache[10] || (_cache[10] = [
                              _createTextVNode(" Checkout ")
                            ])),
                            _: 1
                          })
                        ]))
                      : _createCommentVNode("", true),
                    (!_ctx.fromPreview && _ctx.SingleShoplistCollection?.type != 'user-generated')
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass(`py-3 px-6 flex flex-row justify-center items-center space-x-2 rounded-[999px] cursor-pointer ${
              _ctx.productIsInCart(_ctx.SingleShoplistCollection?.uuid || '')
                ? 'bg-error-50'
                : 'bg-primary-main'
            }`),
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addOrRemoveFromCart && _ctx.addOrRemoveFromCart(...args)))
                        }, [
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(`!font-semibold ${
                _ctx.productIsInCart(_ctx.SingleShoplistCollection?.uuid || '')
                  ? '!text-error-main'
                  : '!text-white'
              }`)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.productIsInCart(_ctx.SingleShoplistCollection?.uuid || "")
                  ? "Remove from Cart"
                  : "Add to Cart"), 1)
                            ]),
                            _: 1
                          }, 8, ["class"])
                        ], 2))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.SingleShoplistCollection?.type != 'user-generated')
          ? {
              name: "extra-topbar",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (!_ctx.fromPreview)
                    ? (_openBlock(), _createBlock(_component_app_button, {
                        key: 0,
                        class: _normalizeClass(`${
              _ctx.addedToCart
                ? '!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white'
                : '!bg-primary-50 !text-primary-main dark:!bg-primary-500 dark:!text-white'
            } flex flex-row space-x-1 items-center`),
                        onClick: _ctx.handleAddToCart
                      }, {
                        default: _withCtx(() => [
                          (_ctx.addedToCart)
                            ? (_openBlock(), _createBlock(_component_app_icon, {
                                key: 0,
                                name: 'check-green',
                                customClass: 'h-[9px]'
                              }))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", {
                            class: _normalizeClass(
                _ctx.addedToCart
                  ? '!text-success-main dark:!text-white'
                  : '!text-primary-main dark:!text-white'
              )
                          }, _toDisplayString(_ctx.addedToCart ? "Saved Shoplist" : "Save Shoplist"), 3)
                        ]),
                        _: 1
                      }, 8, ["class", "onClick"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["mainSectionClass"])
    ]),
    _: 1
  }))
}