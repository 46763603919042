import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 h-full flex-grow px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, {
    title: 'Terms of use',
    mainSectionClass: "!h-fit"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_normal_text, {
          innerHTML: _ctx.ClientAppContent?.terms_and_condition || ''
        }, null, 8, ["innerHTML"])
      ])
    ]),
    _: 1
  }))
}