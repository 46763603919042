import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 h-full flex-grow relative" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-4 pt-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-col px-4 space-y-1" }
const _hoisted_5 = { class: "w-[46px] relative" }
const _hoisted_6 = {
  key: 0,
  class: "absolute top-0 right-[-1%] border-[1.5px] border-white bg-error-500 rounded-full h-[12px] w-[12px]"
}
const _hoisted_7 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_8 = { class: "flex flex-row space-x-1 items-center pt-2" }
const _hoisted_9 = {
  key: 0,
  class: "w-full h-full flex flex-col items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, {
    title: 'Notifications',
    hasPadding: false
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_virtual_scroller, {
            data: _ctx.notifications.data,
            pagination: _ctx.notifications.paginatorInfo,
            fetchMore: _ctx.fetchMoreNotifications
          }, {
            "item-content": _withCtx(({ index, item }) => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_app_normal_text, { customClass: 'uppercase !font-semibold !text-[11px]' }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(index), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (eachitem, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "w-full bg-white px-4 py-4 flex flex-row space-x-4 items-start dark:bg-black",
                    key: index
                  }, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_app_icon, {
                        name: 'notification-bell',
                        customClass: 'h-[45px]'
                      }),
                      (eachitem.is_read)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_app_normal_text, { customClass: '!font-bold' }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(eachitem.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_app_normal_text, {
                        color: 'text-grey-700',
                        customClass: '!text-left'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(eachitem.body), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_app_normal_text, {
                          color: 'text-primary-main dark:!text-primary-300',
                          onClick: ($event: any) => (_ctx.viewNotification(eachitem)),
                          class: "underline cursor-pointer"
                        }, {
                          default: _withCtx(() => _cache[0] || (_cache[0] = [
                            _createTextVNode(" View details ")
                          ])),
                          _: 2
                        }, 1032, ["onClick"])
                      ])
                    ])
                  ]))
                }), 128))
              ])
            ]),
            "skeleton-loaders": _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "w-full flex flex-col space-y-2 px-4" }, [
                _createElementVNode("div", { class: "w-[40%] h-[10px] rounded skeleton" }),
                _createElementVNode("div", { class: "w-full flex flex-col space-y-2 skeleton h-[60px] rounded-[10px]" })
              ], -1)
            ])),
            _: 1
          }, 8, ["data", "pagination", "fetchMore"])
        ]),
        (_ctx.Notifications?.data?.length == 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_app_empty_state, {
                title: 'No Notifications Yet',
                subTitle: 'Your notifications will show up here'
              })
            ]))
          : _createCommentVNode("", true),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
      ])
    ]),
    _: 1
  }))
}