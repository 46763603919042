<template>
  <div class="flex items-center gap-4">
    <div class="w-full bg-gray-200 rounded-full h-[4px] dark:bg-gray-700">
      <div :class="`bg-green-500 h-[4px] rounded-full w-[${progress}%]`"></div>
    </div>
    <slot />
  </div>
</template>
<script lang="ts">
export default {
  components: {},
  props: {
    progress: {
      type: Number,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {};
  },
  name: "AppProgress",
};
</script>
