import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter old password',
        hasTitle: true,
        type: 'password',
        rules: [_ctx.FormValidations.RequiredRule],
        paddings: 'py-3 px-4',
        modelValue: _ctx.formData.old_password,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.old_password) = $event)),
        name: "Old password",
        ref: "old_password"
      }, {
        title: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode(" Old password ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter new password',
        hasTitle: true,
        type: 'password',
        rules: [_ctx.FormValidations.RequiredRule],
        paddings: 'py-3 px-4',
        modelValue: _ctx.formData.new_password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.new_password) = $event)),
        name: "New password",
        ref: "new_password"
      }, {
        title: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode(" New password ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createVNode(_component_app_text_field, {
        placeholder: 'Confirm new password',
        hasTitle: true,
        type: 'password',
        rules: [
        _ctx.FormValidations.RequiredRule,
        _ctx.FormValidations.handleConfirmPassword(
          _ctx.formData.new_password,
          _ctx.formData.confirm_new_password
        ),
      ],
        paddings: 'py-2 px-4',
        modelValue: _ctx.formData.confirm_new_password,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.confirm_new_password) = $event)),
        name: "Confirm new password",
        ref: "confirm_new_password"
      }, {
        title: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Confirm new password ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: "w-full py-3",
          onClick: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Update Password ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}