<template>
  <div v-if="item" class="col-span-1 flex flex-col space-y-2 !cursor-pointer">
    <div
      class="w-full flex flex-col border-[1px] border-[#E9E6ED] rounded-[10px] dark:border-gray-700"
    >
      <app-media-box
        :data="item.data"
        :isVideo="!item.data.is_grid"
        :class="` ${mediaBoxStyle} !rounded-t-[10px]`"
      >
        <div
          class="absolute bottom-0 left-0 h-[10px] w-full bg-white dark:bg-gray-800"
        ></div>
      </app-media-box>
      <div
        :class="`w-full flex flex-col px-2 py-2 !pt-0 left-0 space-y-2 rounded-b-[10px] bg-white dark:bg-gray-800`"
      >
        <app-normal-text
          class="!text-left !text-[11px] !line-clamp-1 w-full mdlg:!text-[12px]"
        >
          {{ item.data.title }}
        </app-normal-text>
        <div class="flex flex-row justify-between items-center">
          <app-normal-text class="!text-[12px] dark:!text-white">
            {{
              Logic.Common.convertToMoney(
                parseFloat(item.data.price?.toFixed(2) || "0"),
                false,
                "ngn"
              )
            }}
          </app-normal-text>

          <app-normal-text
            :customClass="'!text-[10px]  !text-grey-400 dark:!text-gray-200  !font-light !whitespace-nowrap'"
          >
            <span
              class="!font-semibold !text-[#9D4EDD] !text-[11px] mdlg:!text-[12px]"
            >
              ₦{{ numberToAbbrev(item.data.points || 0) }}
            </span>
            Back
          </app-normal-text>

          <!-- <div
            class="flex flex-row space-x-1 justify-end flex-grow items-center"
          >
            <app-icon :name="'cashback-icon'" :customClass="'h-[15px]'" />
            <app-normal-text
              :customClass="'!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold dark:!text-white'"
            >
              <span class="font-semibold pl-[1px]"
                >₦{{ numberToAbbrev(item.data.points || 0) }}</span
              >
              Back
            </app-normal-text>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";
import { Logic, numberToAbbrev } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppMediaBox,
  },
  props: {
    item: {
      type: Object as () => {
        data: MediaBox;
        id: string;
        colors: {
          bg: string;
          text: string;
        };
      },
    },
    mediaBoxStyle: {
      type: String,
      default: "!h-[130px] mdlg:!h-[200px] md:!h-[200px]",
    },
  },
  setup() {
    return {
      numberToAbbrev,
      Logic,
    };
  },
  name: "AppShoplistExplore",
});
</script>
