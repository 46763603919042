<template>
  <app-wrapper :class="'!overflow-y-hidden'">
    <auth-layout
      @button-clicked="Logic.Common.GoToRoute('/auth/login')"
      @go-back="Logic.Common.goBack()"
      :go-back="true"
      :right-button="false"
      :hasBottomBorder="false"
    >
      <div class="w-full flex flex-col space-y-[1px]">
        <div class="w-full relative h-[8px] bg-gray-100 rounded-full">
          <div
            class="absolute top-0 left-0 w-[60%] h-[8px] bg-primary-500 rounded-full"
          ></div>
        </div>
        <div class="w-full flex flex-row justify-end">
          <app-normal-text class="!text-grey-500 dark:!text-grey-400">
            60%
          </app-normal-text>
        </div>
      </div>

      <div class="w-full flex flex-col pt-4 space-y-4">
        <app-header-text class="!text-left">
          How much do you spend every month?
        </app-header-text>

        <div class="w-full flex flex-col space-y-1">
          <div class="w-full grid grid-cols-12 gap-3">
            <div
              :class="`w-full col-span-6 flex flex-col items-center cursor-pointer justify-center space-y-2 border-[1px] h-[80px] border-gray-300 rounded-[5px] px-2 py-2 ${
                selectedSpendGroup === item.key
                  ? 'border-primary-500 !border-[1.5px]'
                  : ''
              }`"
              v-for="item in monthlySpendGroup"
              @click="selectedSpendGroup = item.key"
              :key="item.key"
            >
              <app-header-text
                class="!text-[13.5px] !text-center !font-semibold"
              >
                {{ item.amount }}
              </app-header-text>
              <app-normal-text class="!text-gray-500 !text-center !text-[12px]">
                {{ item.name }}
              </app-normal-text>
            </div>
          </div>

          <app-normal-text class="!text-left !text-gray-500 pb-2 pt-2">
            Tips: Most people are "Essentials-Only" spenders.
          </app-normal-text>
        </div>

        <div
          class="w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black"
          style="
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
        >
          <AppButton
            :padding="'py-3'"
            :custom-class="`rounded-[40px] ${
              selectedSpendGroup ? 'opacity-100' : 'opacity-50'
            }`"
            :bg-color="'bg-primary-main'"
            :text-color="'text-white'"
            @click="continueButtonClicked"
          >
            Continue
          </AppButton>
        </div>
      </div>
    </auth-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import { Logic } from "@shpt/logic";
import { AppNormalText, AppHeaderText, AppButton } from "@shpt/ui-components";

export default defineComponent({
  components: {
    AppWrapper,
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  name: "SavingsTargetPage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Savings Target Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Savings Target",
    });

    localStorage.setItem("user_onboarded", "true");

    const selectedSpendGroup = ref("");

    const monthlySpendGroup = reactive([
      {
        key: "budget_minders",
        name: "Budget Minders",
        amount: "Under ₦100K",
      },
      {
        key: "essentials_only",
        name: "Essentials-Only",
        amount: "₦100K - ₦200K",
      },
      {
        key: "smart_spenders",
        name: "Smart Spenders",
        amount: "₦200K - ₦300K",
      },
      {
        key: "comfort_seekers",
        name: "Comfort Seekers",
        amount: "₦300K - ₦500K",
      },
      {
        key: "luxury_lifestylers",
        name: "Luxury Lifestylers",
        amount: "₦500K and above",
      },
    ]);

    const continueButtonClicked = () => {
      localStorage.setItem("spend_group", selectedSpendGroup.value);
      Logic.Common.GoToRoute("/auth/onboarding/save-cashback");
    };

    return {
      Logic,
      monthlySpendGroup,
      selectedSpendGroup,
      continueButtonClicked,
    };
  },
});
</script>
