import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter code',
        modelValue: _ctx.formData.code,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.code) = $event)),
        hasTitle: true,
        type: "tel",
        padding: 'py-3 px-4',
        name: "Invitation Code",
        ref: "invitation_code",
        rules: [
        _ctx.FormValidations.RequiredRule,
        _ctx.FormValidations.customValidator(
          _ctx.formData.code.length == 9,
          'Invitation code must be valid'
        ),
      ],
        "content-rule": {
        addAfterCount: 0,
        characterToAdd: ``,
        max: 9,
      }
      }, {
        title: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Invitation Code ")
        ])),
        _: 1
      }, 8, ["modelValue", "rules"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: _normalizeClass(`w-full py-3 ${_ctx.formIsValid ? '' : 'opacity-50'}`),
          onClick: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Join ")
          ])),
          _: 1
        }, 8, ["class", "onClick"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}