<template>
  <div class="w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0">
    <div class="w-full grid grid-cols-2 gap-3">
      <div
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer"
        @click="Logic.Common.GoToRoute('/products/add')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon
              :name="'product'"
              :class="'h-[15px] mdlg:!h-[20px] md:!h-[20px] '"
            />
          </div>

          <app-normal-text class="!text-center !text-[12px] mdlg:!text-[14px]">
            Add a new Product
          </app-normal-text>
        </div>
      </div>

      <div
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer"
        @click="handleAddProduct('csv')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon
              :name="'csv'"
              :class="'h-[17px] mdlg:!h-[20px] md:!h-[20px] '"
            />
          </div>

          <app-normal-text class="!text-center !text-[12px] mdlg:!text-[14px]">
            Import From CSV
          </app-normal-text>
        </div>
      </div>

      <div
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer flex items-center justify-center"
        @click="handleAddProduct('shopify')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'shopify'" :customClass="'!h-[17px]'" />
          </div>

          <app-normal-text class="!text-[12px] mdlg:!text-[14px] !text-center">
            Import from <br />
            Shopify
          </app-normal-text>
        </div>
      </div>
      <div
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer flex items-center justify-center"
        @click="handleAddProduct('woocommerce')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'woocommerce'" :customClass="'!h-[22px]'" />
          </div>

          <app-normal-text class="!text-[12px] mdlg:!text-[14px] !text-center">
            Import from <br />
            WooCommerce
          </app-normal-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import { AppFileAttachment } from "../AppForm";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppFileAttachment,
  },
  name: "ModalShoplistType",
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const uploadeData = reactive<{
      image: {
        url: string;
        blob?: Blob;
      };
      video: {
        url: string;
        blob?: Blob;
      };
    }>({
      image: {
        url: "",
      },
      video: {
        url: "",
      },
    });

    const handleAddProduct = (type: "shopify" | "woocommerce" | "csv") => {
      const currentBusiness = Logic.Auth.GetCurrentBusiness();

      const connectedAccounts = currentBusiness?.connected_accounts || [];

      if (type === "shopify") {
        Logic.Common.showModal({
          show: true,
          title: "Import Products From Shopify",
          type: "add_shopify_products",
          extraData: {
            type,
          },
          action: () => {
            //
          },
        });
      } else if (type === "woocommerce") {
        if (!connectedAccounts.includes("woocommerce")) {
          Logic.Common.showModal({
            show: true,
            title: "Connect your WooCommerce store",
            type: "add_woocommerce_products",
            extraData: {
              type,
            },
            action: () => {
              //
            },
          });
        } else {
          Logic.Common.showModal({
            show: true,
            title: "Import Products From WooCommerce",
            type: "add_woocommerce_products",
            extraData: {
              type,
            },
            action: () => {
              //
            },
          });
        }
      } else if (type === "csv") {
        Logic.Common.showModal({
          show: true,
          title: "Import Products From CSV",
          type: "add_shopify_products",
          extraData: {
            type,
          },
          action: () => {
            //
          },
        });
      }
    };

    onMounted(() => {
      Logic.Auth.GetAuthUser();
    });

    return {
      Logic,
      uploadeData,
      handleAddProduct,
    };
  },
});
</script>
