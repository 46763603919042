import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-5 py-4 z-50 justify-between h-full" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_3 = { class: "w-full flex flex-row justify-start pl-2 pb-3" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-1 items-start" }
const _hoisted_5 = { class: "w-[30px] relative" }
const _hoisted_6 = { class: "w-full flex flex-row relative" }
const _hoisted_7 = { class: "w-[30px]" }
const _hoisted_8 = { class: "w-[30px]" }
const _hoisted_9 = { class: "w-[30px]" }
const _hoisted_10 = {
  key: 0,
  class: "w-full flex flex-col pr-4"
}
const _hoisted_11 = { class: "bg-grey-50 rounded-[10px] px-3 py-3 flex flex-row items-center justify-between dark:!bg-gray-800" }
const _hoisted_12 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_13 = { class: "flex flex-col space-y-[1px] justify-center items-start" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_header_text = _resolveComponent("app-header-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_icon, {
          name: `logo${
            $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
          }`,
          class: "h-[40px]"
        }, null, 8, ["name"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.webSideBarItems, (tab, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: index,
            class: "flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-full cursor-pointer",
            onMouseover: ($event: any) => ($setup.hoverTab = tab.icon.name),
            onMouseout: _cache[0] || (_cache[0] = ($event: any) => ($setup.hoverTab = '')),
            to: $setup.getTabRoutePath(tab.route_path),
            onClick: _withModifiers(($event: any) => ($setup.goToRoute($setup.getTabRoutePath(tab.route_path))), ["prevent"])
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_app_icon, {
                  name: `web-sidebar/${
                $setup.hoverTab == tab.icon.name || $props.tabIsActive(tab.routeTag)
                  ? `${tab.icon.name}-active${
                      $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`
                  : tab.icon.name +
                    `${
                      $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                    }`
              }`,
                  "custom-class": ` ${
                tab.icon.name == 'savings' || tab.icon.name == 'wallet'
                  ? '!h-[17px]'
                  : '!h-[20px]'
              }`
                }, null, 8, ["name", "custom-class"]),
                (tab.routeTag == 'cart' && $setup.cartCount > 0)
                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                      key: 0,
                      class: "!text-white h-[16px] w-[16px] bg-red-500 rounded-full flex flex-row items-center justify-center !text-[10px] font-semibold absolute right-[10%] bottom-[60%] z-40"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.cartCount > 9 ? "9+" : $setup.cartCount), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_app_normal_text, {
                class: _normalizeClass(`text-left !text-base !text-black dark:!text-white ${
              $props.tabIsActive(tab.routeTag) ? '!font-bold' : ''
            }`)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(tab.name), 1)
                ]),
                _: 2
              }, 1032, ["class"])
            ]),
            _: 2
          }, 1032, ["onMouseover", "to", "onClick"]))
        }), 128)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black hover:!bg-opacity-[10%] rounded-full cursor-pointer relative",
            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showMore = true))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_app_icon, {
                name: `web-sidebar/more${
                  $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                }`,
                class: "!h-[23px]"
              }, null, 8, ["name"])
            ]),
            _createVNode(_component_app_normal_text, { class: "text-left !text-base !text-black dark:!text-white" }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode(" More ")
              ])),
              _: 1
            })
          ]),
          ($setup.showMore)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "w-[90%] md:!w-full flex flex-col space-y-1 items-start bg-white dark:bg-black shadow-md absolute bottom-0 left-0 rounded-[10px] py-2 px-2 border-[1px] border-gray-50 dark:border-gray-500",
                onClick: _withModifiers(($event: any) => (null), ["stop"]),
                onMouseleave: _cache[7] || (_cache[7] = ($event: any) => ($setup.showMore = false))
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.webSideBarMore, (tab, index) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    key: index,
                    class: "flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-[10px] cursor-pointer w-full",
                    onMouseover: ($event: any) => ($setup.hoverTab = tab.icon.name),
                    onMouseout: _cache[2] || (_cache[2] = ($event: any) => ($setup.hoverTab = '')),
                    to: $setup.getTabRoutePath(tab.route_path),
                    onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.showMore = false), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, [
                        _createVNode(_component_app_icon, {
                          name: `web-sidebar/${
                    $setup.hoverTab == tab.icon.name || $props.tabIsActive(tab.routeTag)
                      ? `${tab.icon.name}-active${
                          $setup.Logic.Common.currentAppTheme() == 'light'
                            ? ''
                            : '-white'
                        }`
                      : tab.icon.name +
                        `${
                          $setup.Logic.Common.currentAppTheme() == 'light'
                            ? ''
                            : '-white'
                        }`
                  }`,
                          "custom-class": ` ${
                    tab.icon.name == 'savings' ? '!h-[18px]' : '!h-[20px]'
                  }`
                        }, null, 8, ["name", "custom-class"])
                      ]),
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`text-left !text-sm !text-black dark:!text-white ${
                  $props.tabIsActive('account_settings') ? '' : ''
                }`)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(tab.name), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ]),
                    _: 2
                  }, 1032, ["onMouseover", "to"]))
                }), 128)),
                _createElementVNode("div", {
                  class: "flex flex-row space-x-3 items-center px-4 py-3 hover:bg-black dark:hover:bg-white hover:!bg-opacity-[10%] rounded-[10px] cursor-pointer w-full",
                  onMouseover: _cache[4] || (_cache[4] = ($event: any) => ($setup.hoverTab = 'logout')),
                  onMouseout: _cache[5] || (_cache[5] = ($event: any) => ($setup.hoverTab = '')),
                  onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => ($setup.Logic.Auth.SignOut()), ["stop"]))
                }, [
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_app_icon, {
                      name: `web-sidebar/${
                    $setup.hoverTab == 'logout' || $props.tabIsActive('logout')
                      ? `${'logout'}-active`
                      : 'logout'
                  }`,
                      "custom-class": `!h-[20px]`
                    }, null, 8, ["name"])
                  ]),
                  _createVNode(_component_app_normal_text, {
                    class: _normalizeClass(`text-left !text-sm   !text-error-main ${
                  $props.tabIsActive('account_settings') ? '' : ''
                }`)
                  }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" Logout ")
                    ])),
                    _: 1
                  }, 8, ["class"])
                ], 32)
              ], 32))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    (
        $setup.Logic.Common.currentAccountType() == 'client'
          ? $setup.AuthUser?.profile?.gender != undefined
          : $setup.Logic.Auth.GetCurrentBusiness()?.business_name != undefined
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_app_image_loader, {
                photoUrl: 
              $setup.Logic.Common.currentAccountType() == 'merchant'
                ? $setup.Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  ? $setup.Logic.Auth.GetCurrentBusiness()?.photo_url || ''
                  : '/images/temp/vendor.svg'
                : $setup.Logic.Auth.AuthUser?.profile?.photo_url
                ? $setup.Logic.Auth.AuthUser?.profile?.photo_url
                : '/images/avatar-1.png'
            ,
                customClass: '!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200 cursor-pointer',
                onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => ($setup.goToPath('/profile/user')), ["stop"]))
              }, null, 8, ["photoUrl"]),
              _createElementVNode("div", _hoisted_13, [
                ($setup.Logic.Common.currentAccountType() == 'client')
                  ? (_openBlock(), _createBlock(_component_app_header_text, {
                      key: 0,
                      class: "!text-sm text-left !line-clamp-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.Logic.Auth.AuthUser?.first_name) + " " + _toDisplayString($setup.Logic.Auth.AuthUser?.last_name), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_app_header_text, {
                      key: 1,
                      class: "!text-sm text-left !line-clamp-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.Logic.Auth.GetCurrentBusiness()?.business_name), 1)
                      ]),
                      _: 1
                    })),
                (
                $setup.Logic.Common.currentAccountType() == 'client' ||
                $setup.Logic.Auth.BusinessUserCan('manage_profile')
              )
                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                      key: 2,
                      class: "!text-primary-400 dark:!text-primary-100 text-left !text-[11px] cursor-pointer",
                      onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (
                $setup.goToPath(
                  $setup.Logic.Common.currentAccountType() == 'merchant'
                    ? '/profile/edit'
                    : '/profile/user'
                )
              ), ["stop"]))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.Logic.Common.currentAccountType() == "merchant"
                  ? "Edit Profile"
                  : "View Profile"), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_app_normal_text, {
                      key: 3,
                      class: "!line-clamp-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.Logic.Auth.AuthUser?.full_name), 1)
                      ]),
                      _: 1
                    }))
              ])
            ]),
            (
            $setup.Logic.Common.currentAccountType() == 'client' ||
            $setup.Logic.Auth.BusinessUserCan('manage_profile')
          )
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center cursor-pointer",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (
            $setup.goToPath(
              $setup.Logic.Common.currentAccountType() == 'merchant'
                ? '/profile/edit'
                : '/profile/edit'
            )
          ))
                }, [
                  _createVNode(_component_app_icon, {
                    name: 'edit-black',
                    customClass: 'h-[14px]'
                  })
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}