<template>
  <div class="w-full flex flex-col space-y-3 relative" v-if="data">
    <template v-if="!data.videoUrl">
      <app-header-text :customClass="'!text-lg !font-extrabold'">
        {{ data.title }}
      </app-header-text>
      <app-normal-text
        :customClass="'text-left   w-full '"
        :htmlContent="data.content"
        :htmlStyle="'  !text-black !font-semibold'"
      >
      </app-normal-text>
      <div class="h-[60px]" v-if="data.hasPadding"></div>
    </template>

    <template v-else>
      <div class="w-full h-[570px] bg-red-100">
        <app-video-player
          :customClass="''"
          :videoUrl="data.videoUrl"
          :height="570"
        />
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import AppVideoPlayer from "../AppVideoPlayer";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppVideoPlayer,
  },
  props: {
    data: {
      type: Object as () => {
        title: string;
        content: string;
        hasPadding: boolean;
        videoUrl: string;
      },
    },
  },
  name: "AppModalPolicyContent",
  setup(props) {
    return {
      Logic,
    };
  },
});
</script>
