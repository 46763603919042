<template>
  <app-wrapper>
    <subpage-layout :title="'Receipt'" :hasSearch="false" :hasCart="false">
      <template v-slot:extra-topbar>
        <div
          class="px-4 py-1 bg-primary-50 dark:bg-primary-500 rounded-[999px] flex items-center justify-center flex-row cursor-pointer"
          @click="reportReceipt()"
        >
          <app-normal-text
            class="!text-primary-500 !font-semibold dark:!text-primary-50"
          >
            Report
          </app-normal-text>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-3 h-full flex-grow">
        <!-- Weekly spend limit -->
        <div class="w-full flex flex-col px-4">
          <app-image-loader
            :photoUrl="'/images/purple-bg.svg'"
            class="w-full flex flex-col space-y-3 px-3 py-3 rounded-[10px]"
            v-if="!isEditable"
          >
            <div class="flex flex-row items-center justify-between">
              <app-normal-text class="!text-white"
                >Weekly Spend limit</app-normal-text
              >

              <app-normal-text
                @click="Logic.Common.GoToRoute('/others/membership')"
                class="!underline !text-[#D8B8F1] cursor-pointer"
                >Upgrade
              </app-normal-text>
            </div>

            <div class="w-full flex flex-col space-y-1">
              <div
                class="w-full h-[8px] rounded-[10px] bg-secondary-50 relative"
              >
                <div
                  class="absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D]"
                  :style="`width: ${
                    ((AuthUser?.receipt_week_spend || 0) /
                      parseFloat(
                        AuthUser?.profile?.subscription_plan
                          .receipt_weekly_spend_limit || '0'
                      ) <=
                    1
                      ? (AuthUser?.receipt_week_spend || 0) /
                        parseFloat(
                          AuthUser?.profile?.subscription_plan
                            .receipt_weekly_spend_limit || '0'
                        )
                      : 1) * 100
                  }%`"
                ></div>
              </div>

              <div class="w-full flex flex-row items-center justify-between">
                <div class="space-x-1 flex flex-row items-center">
                  <span class="h-[10px] w-[10px] rounded-[2px] bg-[#FFC44D]">
                  </span>
                  <app-normal-text class="!text-white !font-semibold">
                    {{
                      Logic.Common.convertToMoney(
                        AuthUser?.receipt_week_spend || 0,
                        false,
                        "ngn"
                      )
                    }}
                  </app-normal-text>
                </div>

                <div class="space-x-1 flex flex-row items-center">
                  <app-normal-text class="!text-white !font-semibold">
                    {{
                      Logic.Common.convertToMoney(
                        AuthUser?.profile?.subscription_plan
                          .receipt_weekly_spend_limit || 0,
                        false,
                        "ngn"
                      )
                    }}
                  </app-normal-text>
                  <span class="h-[10px] w-[10px] rounded-[2px] bg-white">
                  </span>
                </div>
              </div>
            </div>
          </app-image-loader>
        </div>

        <!-- Point to earn -->
        <div class="w-full flex flex-col px-4">
          <div
            class="w-full flex flex-row justify-center items-center h-[98px] space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px]"
          >
            <app-icon :name="'point-white'" class="!h-[48px]" />
            <div class="flex flex-col space-y-0 items-start pt-[4px]">
              <app-normal-text
                class="!text-[11px] uppercase !text-[#240046] dark:!text-gray-50 !font-semibold"
              >
                {{ isEarned ? "Points Rewarded" : "Points to earn" }}
              </app-normal-text>
              <app-header-text class="!text-[#240046] !font-semibold !text-xl">
                {{
                  Logic.Common.convertToMoney(
                    isEarned
                      ? SingleScannedReceipt?.game_points
                      : receiptInfo.points,
                    false,
                    ""
                  )
                }}
                pts
                <span class="!font-normal !text-xs">
                  {{
                    isEarned
                      ? `(${Logic.Common.convertToMoney(
                          SingleScannedReceipt?.point_to_claim,
                          false,
                          "ngn"
                        )})`
                      : ""
                  }}</span
                >
              </app-header-text>
            </div>
          </div>
        </div>

        <div
          class="w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[12px] py-4 px-4 items-center"
        >
          <!-- Purchase details -->
          <div
            class="w-full flex flex-row items-center justify-between pb-3 border-b-[1px] border-[#E8E8E8]"
            v-if="isEditable"
          >
            <app-normal-text class="!font-semibold">
              Purchase Details
            </app-normal-text>

            <div
              class="px-3 py-[3px] bg-primary-main rounded-[80px] flex flex-row items-center space-x-2 cursor-pointer"
              @click="showEditReceipt()"
            >
              <app-icon :name="'edit'" class="!h-[13px]" />
              <app-normal-text class="!text-white">Edit</app-normal-text>
            </div>
          </div>

          <!-- Merchant and date -->
          <div class="w-full grid grid-cols-2 gap-3">
            <div class="col-span-1 flex flex-col space-y-1 items-start">
              <app-normal-text class="!text-sm !font-semibold">
                Merchant Name
              </app-normal-text>
              <app-normal-text class="!text-grey-700 dark:!text-gray-300">
                {{ receiptInfo.merchant_name }}
              </app-normal-text>
            </div>

            <div class="col-span-1 flex flex-col space-y-1 items-end">
              <app-normal-text :customClass="'!font-semibold !text-sm '">
                Date of Payment
              </app-normal-text>
              <app-normal-text class="!text-grey-700 dark:!text-gray-300">
                {{ receiptInfo.date }}
              </app-normal-text>
            </div>
          </div>

          <div
            class="w-full flex flex-col space-y-2 border-t-[1px] border-[#E8E8E8] pt-3"
          >
            <app-normal-text class="!text-sm !font-semibold">
              Item Description
            </app-normal-text>
            <div class="w-full flex flex-col space-y-3">
              <div
                class="w-full flex flex-row items-center justify-between"
                v-for="(item, index) in receiptInfo.items"
                :key="index"
              >
                <app-normal-text class="!text-gray-700 dark:!text-gray-300">
                  {{ item.name }}
                </app-normal-text>
                <app-normal-text class="!font-semibold">
                  {{ item.price }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col space-y-1 border-t-[1px] border-[#E8E8E8] pt-3"
          >
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text class="!text-gray-700 dark:!text-gray-300">
                  Total
                </app-normal-text>
                <app-normal-text class="!font-semibold">
                  {{ receiptInfo.total }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col space-y-1 border-t-[1px] border-[#E8E8E8] pt-3"
          >
            <div class="w-full flex flex-col space-y-2">
              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text> Processing status </app-normal-text>
                <span
                  :class="`px-4 py-1  ${
                    receiptInfo.status == 'expired' ? 'bg-red-500' : ''
                  } ${
                    receiptInfo.status == 'successful' ? 'bg-green-500' : ''
                  }  ${
                    receiptInfo.status == 'claimed' ? 'bg-secondary-500' : ''
                  } rounded-[20px]`"
                >
                  <app-normal-text class="!text-white !capitalize">
                    {{ receiptInfo.status }}
                  </app-normal-text>
                </span>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-primary-50"
            v-if="receiptInfo.status == 'expired'"
          >
            <app-normal-text>
              As a
              <span class="!font-bold">{{
                AuthUser?.profile?.subscription_plan.name
              }}</span>
              subscription member, you can only claim reward on receipts that
              are less than or at
              <span class="!font-bold"
                >{{
                  AuthUser?.profile?.subscription_plan.receipt_scan_allowed_days
                }}
                days</span
              >
              old.
            </app-normal-text>
          </div>

          <div class="!h-[100px] w-full flex"></div>
        </div>
      </div>

      <!-- Continue button -->
      <fixed-container class="z-50 bg-[#F9F9FF]" custom-class="pt-4">
        <div
          :class="`w-full grid ${
            receiptInfo.status != 'successful' ? 'grid-cols-1' : 'grid-cols-1'
          } gap-4`"
        >
          <div class="col-span-1 flex flex-col" v-if="!isEarned">
            <app-button
              :customClass="`w-full ${
                receiptInfo.status != 'successful' ? '' : ''
              }`"
              :padding="'py-3 xs:py-3'"
              @click="processReceipt()"
              :border="
                receiptInfo.status != 'successful' ? '' : 'border-b-[3px]'
              "
            >
              Continue
            </app-button>
          </div>
          <div class="col-span-1 flex flex-col" v-else>
            <app-button
              :customClass="`w-full`"
              :padding="'py-3 xs:py-3'"
              @click="exploreOffers()"
            >
              Explore Offers
            </app-button>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewWillEnter } from "@ionic/vue";
import {
  AppIcon,
  AppButton,
  AppNormalText,
  AppHeaderText,
  AppImageLoader,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import { reportReceipt } from "@shpt/ui-components/src/composable/shop";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import { ContentType } from "@shpt/logic/src/gql/graphql";

export default defineComponent({
  components: {
    AppIcon,
    AppButton,
    AppNormalText,
    AppHeaderText,
    AppImageLoader,
    AppWrapper,
    FixedContainer,
  },
  name: "ScanInfoPage",
  middlewares: {
    tracking_data: {
      lable: "Snap Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Snap Info",
    });

    const SingleScannedReceipt = ref(Logic.Shop.SingleScannedReceipt);

    const AuthUser = ref(Logic.Auth.AuthUser);

    const isEarned = ref(false);
    const isEditable = ref(false);

    const receiptInfo = reactive({
      points: 10,
      merchant_name: "Shop Supermarket",
      date: "01/05/2019",
      items: [
        {
          name: "Item One",
          price: "₦3,500",
        },
      ],
      total: "₦0",
      status: "successful",
    });

    const setReceiptInfo = () => {
      if (SingleScannedReceipt.value) {
        receiptInfo.points = SingleScannedReceipt.value.point_to_claim;
        receiptInfo.merchant_name =
          SingleScannedReceipt.value?.scanned_receipt?.merchant_name || "";
        receiptInfo.date =
          SingleScannedReceipt.value.scanned_receipt.receipt_date || "No date";

        const receiptItems: {
          Description: string;
          Quantity: number;
          TotalPrice: number;
        }[] = JSON.parse(SingleScannedReceipt.value.scanned_receipt.items);

        receiptInfo.items.length = 0;

        receiptItems.forEach((item) => {
          receiptInfo.items.push({
            name: `${item.Description}${
              item.Quantity > 1 ? ` (x${item.Quantity})` : ""
            }`,
            price: Logic.Common.convertToMoney(item.TotalPrice, false, "ngn"),
          });
        });

        receiptInfo.total = Logic.Common.convertToMoney(
          SingleScannedReceipt.value.scanned_receipt.amount,
          false,
          "ngn"
        );
        receiptInfo.status = SingleScannedReceipt.value.scanned_receipt.status;
      }
    };

    const showForceContinue = (title: string) => {
      const buttons = [
        {
          label: "Upgrade Plan",
          action: () => {
            Logic.Common.GoToRoute("/others/membership");
          },
        },
        {
          label: "Claim with 0pts",
          action: () => {
            Logic.Shop.ProcessScannedReceiptForm = {
              account_type: "client",
              scanned_receipt_uuid:
                SingleScannedReceipt.value?.scanned_receipt.uuid || "",
              is_force_continue: true,
            };

            Logic.Shop.ProcessScannedReceipt()?.then((data) => {
              if (data) {
                //
              }
            });
          },
        },
      ];

      Logic.Common.showModal({
        type: "request_feedback",
        show: true,
        action: () => {
          Logic.Common.showModal({ show: false });
        },
        title: "",
        preventClose: true,
        closeAction: () => {
          Logic.Common.showModal({
            show: false,
          });
        },
        extraData: {
          title,
          icon: "error-warning",
          buttons,
        },
      });
    };

    const exploreOffers = () => {
      setStoryContent(
        ContentType.Explore,
        {
          id: 0,
          type: "collection",
        },
        "",
        "/shoplist"
      );
    };

    const processReceipt = () => {
      if (receiptInfo.status == "successful") {
        Logic.Shop.ProcessScannedReceiptForm = {
          account_type: "client",
          scanned_receipt_uuid:
            SingleScannedReceipt.value?.scanned_receipt.uuid || "",
        };

        Logic.Shop.ProcessScannedReceipt()
          ?.then((data) => {
            if (data) {
              //
            }
          })
          .catch((error: any) => {
            const message: string = error.graphQLErrors[0].message;
            if (message.includes("Weekly spend limit")) {
              showForceContinue(
                `You have exceeded your Weekly Spend Limit as a ${AuthUser.value?.profile?.subscription_plan.name} subscription member`
              );
            }
          });
      } else {
        //
        showForceContinue(
          `Your receipt has expired as a ${AuthUser.value?.profile?.subscription_plan.name} subscription member`
        );
      }
    };

    const showEditReceipt = () => {
      Logic.Common.showModal({
        show: true,
        title: "Edit Details",
        type: "edit_receipt",
        actionLabel: "Save",
        action: (data: any) => {
          Logic.Shop.UpdateScannedReceiptForm = {
            scanned_receipt_uuid:
              SingleScannedReceipt.value?.scanned_receipt?.uuid || "",
            main_category: data.category,
            merchant_name: data.merchant_name,
            amount: data.total_amount,
          };
          Logic.Shop.UpdateScannedReceipt()?.then((data) => {
            if (data) {
              if (SingleScannedReceipt.value) {
                SingleScannedReceipt.value.scanned_receipt =
                  data.UpdateScannedReceipt;
                setReceiptInfo();
              }
            }
          });
        },
        validateForm: true,
        extraData: {
          total_amount: SingleScannedReceipt.value?.scanned_receipt.amount || 0,
          category:
            SingleScannedReceipt.value?.scanned_receipt.main_category || "",
          merchant_name:
            SingleScannedReceipt.value?.scanned_receipt.merchant_name,
        },
      });
    };

    onIonViewDidEnter(() => {
      setReceiptInfo();
    });

    onIonViewWillEnter(() => {
      if (Logic.Common.route?.query.earned) {
        isEarned.value = true;
      } else {
        isEarned.value = false;
      }

      if (Logic.Common.route?.query.isEditable?.toString() == "true") {
        isEditable.value = true;
      } else {
        isEditable.value = false;
      }
    });

    onMounted(() => {
      Logic.Shop.watchProperty("SingleScannedReceipt", SingleScannedReceipt);
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      setReceiptInfo();
      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 2000);

      if (Logic.Common.currentBuildType() == "web") {
        if (Logic.Common.route?.query.earned) {
          isEarned.value = true;
        } else {
          isEarned.value = false;
        }

        if (Logic.Common.route?.query.isEditable?.toString() == "true") {
          isEditable.value = true;
        } else {
          isEditable.value = false;
        }
      }
    });

    return {
      receiptInfo,
      Logic,
      AuthUser,
      SingleScannedReceipt,
      isEarned,
      isEditable,
      processReceipt,
      reportReceipt,
      showEditReceipt,
      exploreOffers,
    };
  },
});
</script>
