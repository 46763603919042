<template>
  <subpage-layout :title="'Account Settings'">
    <div class="w-full flex flex-col space-y-3 px-4">
      <div
        v-for="(item, index) in actions"
        :key="index"
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center cursor-pointer"
        @click="handleSettings(item)"
      >
        <div class="flex flex-row space-x-2 items-center">
          <div class="w-[37px]">
            <div
              class="w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center bg-white dark:bg-slate-300"
            >
              <app-icon :name="item.icon" :class="item.icon_size" />
            </div>
          </div>
          <app-normal-text
            :class="`!text-left !font-semibold ${
              item.icon == 'delete' ? '!text-red-500' : ''
            }`"
          >
            {{ item.title }}
          </app-normal-text>
        </div>
        <div class="pr-1">
          <app-icon :name="'chevron-right-grey'" class="!h-[13px]" />
        </div>
      </div>

      <div
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center"
      >
        <div class="flex flex-row space-x-2 items-center">
          <app-normal-text class="!text-left !font-semibold">
            Choose Theme
          </app-normal-text>
        </div>
        <div class="flex flex-row space-x-3 items-center">
          <div
            @click="setAppTheme('light')"
            :class="`w-[40px] h-[40px] rounded-full border-[2px] !bg-white cursor-pointer ${
              Logic.Common.currentAppTheme() == 'light'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`"
          ></div>
          <div
            @click="setAppTheme('dark')"
            :class="`w-[40px] h-[40px] rounded-full border-[2px] !bg-black  cursor-pointer ${
              Logic.Common.currentAppTheme() == 'dark'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`"
          ></div>
        </div>
      </div>
      <div
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center"
        @click="showDeleteAccount"
      >
        <div class="flex flex-row space-x-2 items-center">
          <div class="w-[37px]">
            <div
              class="w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center bg-white dark:bg-slate-300"
            >
              <app-icon :name="'delete'" :class="'!h-[16px]'" />
            </div>
          </div>
          <app-normal-text :class="`!text-left !font-semibold !text-red-500`">
            Delete Account
          </app-normal-text>
        </div>
      </div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive } from "vue";
import { Logic } from "../../../index";
import { AppIcon, AppNormalText } from "../../../components";
import { setAppTheme } from "../../../composable";
import SubpageLayout from "../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    SubpageLayout,
  },
  name: "AccountSettingsPage",
  setup() {
    const actions = reactive([
      {
        title: "Change Password",
        icon: "setting-change-password",
        path: "",
        icon_size: "",
      },
      // {
      //   title: "Change Transaction Pin",
      //   icon: "setting-change-pin",
      //   path: "",
      //   icon_size: "",
      // },
    ]);

    const handleChangePassword = () => {
      Logic.Common.showModal({
        show: true,
        title: "Change password",
        type: "change_password",
        actionLabel: "Update",
        action: (data: any) => {
          Logic.Auth.UpdatePasswordForm = {
            old_password: data.old_password,
            password: data.confirm_new_password,
            user_uuid: Logic.Auth.AuthUser?.uuid || "",
          };
          Logic.Auth.UpdatePassword(true, true);
        },
        validateForm: true,
      });
    };

    const showDeleteAccount = () => {
      Logic.Common.showModal({
        type: "request_feedback",
        show: true,
        action: () => {
          Logic.Common.showModal({ show: false });
        },
        title: "",
        extraData: {
          title: `We're sad to see you go. Are you sure you want to delete your account?`,
          icon: "error-warning",
          buttons: [
            {
              label: "No",
              action: () => {
                Logic.Common.showModal({ show: false });
              },
            },
            {
              label: "Yes",
              action: () => {
                Logic.User.DeleteUser();
                Logic.Common.showModal({ show: false });
              },
            },
          ],
        },
      });
    };

    const handleSettings = (item: any) => {
      if (item.icon == "settings-membership") {
        Logic.Common.GoToRoute("/others/membership");
      }

      if (item.icon == "setting-change-password") {
        handleChangePassword();
      }

      if (item.icon == "setting-change-pin") {
        Logic.Common.GoToRoute("/auth/verify-phone?pin_update=yes");
      }

      if (item.icon == "sidebar/expense-tracker") {
        Logic.Common.GoToRoute("/settlement_accounts");
      }

      if (item.icon == "delete") {
        showDeleteAccount();
      }
    };

    onMounted(() => {
      if (Logic.Auth.currentAccountType() == "client") {
        actions.unshift({
          title: "Membership",
          icon: "settings-membership",
          path: "/others/membership",
          icon_size: " ",
        });
      } else {
        actions.unshift({
          title: "Settlement Accounts",
          icon: "sidebar/expense-tracker",
          path: "/settlement_accounts",
          icon_size: "!h-[16px]",
        });
      }
    });

    return {
      Logic,
      actions,
      handleSettings,
      setAppTheme,
      showDeleteAccount,
    };
  },
});
</script>
