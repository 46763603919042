import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[400px]"
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_6 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_7 = {
  key: 1,
  class: "w-full flex flex-col h-[300px] justify-center items-center"
}
const _hoisted_8 = {
  key: 1,
  class: "w-full flex flex-col"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex flex-col h-[300px] justify-center items-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_switch = _resolveComponent("app-switch")!
  const _component_app_shoplist = _resolveComponent("app-shoplist")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_product = _resolveComponent("app-product")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppTextField, {
        placeholder: `Type to search`,
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
      }, {
        "inner-prefix": _withCtx(() => [
          _createVNode(_component_AppIcon, {
            "custom-class": '',
            name: 'search'
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_app_switch, {
        items: _ctx.itemsSwitch,
        modelValue: _ctx.selectedSwitch,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedSwitch) = $event))
      }, null, 8, ["items", "modelValue"])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(), _createElementBlock("svg", _hoisted_5, _cache[2] || (_cache[2] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ])))
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.selectedSwitch == 'offers')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                (_ctx.shoplists.data.length > 0)
                  ? (_openBlock(), _createBlock(_component_app_virtual_scroller, {
                      key: 0,
                      "container-class": 'w-full grid grid-cols-2 gap-3 !space-y-0',
                      data: _ctx.shoplists.data,
                      pagination: _ctx.shoplists.paginatorInfo,
                      fetchMore: _ctx.fetchMoreShoplists,
                      direction: 'vertical',
                      ref: "shoplistsVirtualScrollerRef"
                    }, {
                      "item-content": _withCtx(({ index, item }) => [
                        (_openBlock(), _createElementBlock("div", {
                          class: "col-span-1 flex flex-col",
                          key: index
                        }, [
                          _createVNode(_component_app_shoplist, {
                            item: item,
                            onClick: ($event: any) => (_ctx.selectShoplist(item))
                          }, null, 8, ["item", "onClick"])
                        ]))
                      ]),
                      "skeleton-loaders": _withCtx(() => _cache[3] || (_cache[3] = [
                        _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                          _createElementVNode("div", { class: "!h-[200px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                        ], -1)
                      ])),
                      _: 1
                    }, 8, ["data", "pagination", "fetchMore"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_app_empty_state, {
                        title: 'No Offers Here',
                        subTitle: 'All available offers will show up here'
                      })
                    ]))
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectedSwitch == 'products')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_ctx.products.data.length > 0)
                  ? (_openBlock(), _createBlock(_component_app_virtual_scroller, {
                      key: 0,
                      "container-class": 'w-full grid grid-cols-2 gap-3 !space-y-0',
                      data: _ctx.products.data,
                      pagination: _ctx.products.paginatorInfo,
                      fetchMore: _ctx.fetchMoreProducts,
                      direction: 'vertical',
                      ref: "productsVirtualScrollerRef"
                    }, {
                      "item-content": _withCtx(({ index, item }) => [
                        (_openBlock(), _createElementBlock("div", {
                          class: "col-span-1 flex flex-col",
                          key: index
                        }, [
                          _createVNode(_component_app_product, {
                            data: item,
                            onClick: ($event: any) => (_ctx.selectProduct(item))
                          }, null, 8, ["data", "onClick"])
                        ]))
                      ]),
                      "skeleton-loaders": _withCtx(() => _cache[4] || (_cache[4] = [
                        _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                          _createElementVNode("div", { class: "!h-[200px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                        ], -1)
                      ])),
                      _: 1
                    }, 8, ["data", "pagination", "fetchMore"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_component_app_empty_state, {
                        title: 'No Products Here',
                        subTitle: 'All available products will show up here'
                      })
                    ]))
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[50px]" }, null, -1))
  ]))
}