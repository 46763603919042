import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "mainApp" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IonRouterOutlet = _resolveComponent("IonRouterOutlet")!
  const _component_ion_app = _resolveComponent("ion-app")!
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_app_bottom_bar = _resolveComponent("app-bottom-bar")!
  const _component_app_side_bar = _resolveComponent("app-side-bar")!
  const _component_app_loader = _resolveComponent("app-loader")!
  const _component_app_alert = _resolveComponent("app-alert")!
  const _component_app_modal = _resolveComponent("app-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.Logic.Common.currentBuildType() == 'mobile')
      ? (_openBlock(), _createBlock(_component_ion_app, {
          key: 0,
          id: "mainApp",
          class: "!bg-transparent"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IonRouterOutlet, { class: "lg:text-sm mdlg:text-[12px] text-xs !font-inter !bg-transparent" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.Logic.Common.currentBuildType() == 'web')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_metainfo, null, {
            title: _withCtx(({ content }) => [
              _createTextVNode(_toDisplayString(content ? `${content} | Shoppoint` : `Shoppoint`), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view, { class: "lg:text-sm mdlg:text-[12px] text-xs !font-inter !bg-transparent" })
          ])
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.showBottomNav)
      ? (_openBlock(), _createBlock(_component_app_bottom_bar, {
          key: 2,
          tabs: _ctx.Logic.Common.bottomBarItems,
          "tab-is-active": _ctx.tabIsActive,
          class: _normalizeClass(_ctx.bottomNavStyle)
        }, null, 8, ["tabs", "tab-is-active", "class"]))
      : _createCommentVNode("", true),
    (_ctx.showSideBar)
      ? (_openBlock(), _createBlock(_component_app_side_bar, {
          key: 3,
          closeBar: 
      () => {
        _ctx.showSideBar = false;
      }
    
        }, null, 8, ["closeBar"]))
      : _createCommentVNode("", true),
    (_ctx.loaderSetup.loading)
      ? (_openBlock(), _createBlock(_component_app_loader, {
          key: 4,
          setup: _ctx.loaderSetup
        }, null, 8, ["setup"]))
      : _createCommentVNode("", true),
    (_ctx.alertSetup.show)
      ? (_openBlock(), _createBlock(_component_app_alert, {
          key: 5,
          setup: _ctx.alertSetup
        }, null, 8, ["setup"]))
      : _createCommentVNode("", true),
    (_ctx.modalSetup.show && !_ctx.loaderSetup.loading)
      ? (_openBlock(), _createBlock(_component_app_modal, {
          key: 6,
          setup: _ctx.modalSetup
        }, null, 8, ["setup"]))
      : _createCommentVNode("", true)
  ], 64))
}