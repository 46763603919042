<template>
  <div
    class="w-full flex flex-col space-y-4 border-[1px] border-[#E9E6ED] rounded-[18px] px-3 py-3 md:!pb-0 mdlg:!pb-0"
  >
    <div
      class="h-[250px] w-full bg-[#F7F7F7] rounded-[12px] items-center justify-center flex flex-col"
    >
      <img :src="codeUrl" />
    </div>

    <div class="w-full flex flex-col">
      <app-button
        :customClass="'w-full flex flex-row !items-center !space-x-2'"
        @click="shareQrCode()"
      >
        <app-icon :name="'share-white'" :customClass="'h-[16px]'" />
        <app-normal-text :color="'text-white'" :customClass="'!font-bold'">
          Share code
        </app-normal-text>
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AppButton from "../AppButton";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import QRCode from "qrcode";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppButton,
    AppNormalText,
    AppIcon,
  },
  name: "AppModalQRCode",
  props: {
    data: {
      type: Object as () => {
        data: {
          amount: number;
          u_uuid: string;
        };
      },
      default: () => {},
    },
  },
  setup(props) {
    const codeUrl = ref("");

    const dataToCode = () => {
      QRCode.toDataURL(JSON.stringify(props.data), {
        width: 220,
        color: {
          dark: "#5A189A",
          light: "#F5EDFC",
        },
      })
        .then((url: any) => {
          codeUrl.value = url;
        })
        .catch((err: any) => {
          console.error(err);
        });
    };

    const shareQrCode = () => {
      const base64String = codeUrl.value.split(",")[1];
      Logic.Common.shareImageFile(base64String);
    };

    onMounted(() => {
      dataToCode();
    });
    return {
      shareQrCode,
      codeUrl,
    };
  },
});
</script>
