<template>
  <div
    class="border-grey-50 border flex flex-col items-center rounded-lg py-3 px-3 space-y-2 w-full"
  >
    <div class="flex justify-between w-full">
      <AppNormalText class="!text-black dark:!text-white">
        Order ID: {{ data.code }}
      </AppNormalText>

      <app-badge :color="data.status.type" :class="'capitalize'">
        {{ data.status.name }}
      </app-badge>
    </div>

    <div class="h-[1px] bg-grey-50 w-full"></div>

    <div class="flex justify-between w-full">
      <AppNormalText
        :custom-class="'!font-semibold'"
        class="!text-black dark:!text-white"
      >
        {{ data.product_name }}
      </AppNormalText>

      <AppNormalText class="font-semibold !text-black dark:!text-white">
        {{ Logic.Common.convertToMoney(data.amount, false, "ngn") }}
      </AppNormalText>
    </div>

    <div class="flex justify-between w-full">
      <AppNormalText
        :color="'text-grey-700'"
        class="!text-[12px]"
        :custom-class="'gap-2'"
      >
        Resale Commission
      </AppNormalText>
      <AppNormalText>
        {{ Logic.Common.convertToMoney(data.resale_commission, false, "ngn") }}
      </AppNormalText>
    </div>
    <div class="flex justify-between w-full">
      <AppNormalText
        :color="'text-grey-700'"
        class="!text-[12px]"
        :custom-class="'gap-2'"
      >
        Cashback
      </AppNormalText>
      <AppNormalText>
        {{ Logic.Common.convertToMoney(data.cashback, false, "ngn") }}
      </AppNormalText>
    </div>

    <div class="flex justify-between w-full">
      <AppNormalText :color="'text-grey-800'" :custom-class="'gap-2'">
        {{ data.date }}
      </AppNormalText>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import AppBadge from "../AppBadge";
import { Logic } from "../../composable";

export default {
  components: {
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
    AppBadge,
  },
  props: {
    data: {
      type: Object as () => {
        id: string;
        code: string;
        product_name: string;
        amount: number;
        resale_commission: number;
        cashback: number;
        date: string;
        status: {
          name: string;
          type: "green" | "red" | "yellow" | "blue" | "purple";
        };
      },
    },
  },
  setup() {
    return {
      Logic,
    };
  },
  name: "AppOrder",
};
</script>
