import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_2 = { class: "w-[18px] h-[18px] bg-primary-400 rounded-full grid place-content-center" }
const _hoisted_3 = { class: "flex flex-col space-y-1" }
const _hoisted_4 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_5 = { class: "flex space-x-2 items-center" }
const _hoisted_6 = {
  key: 0,
  class: "flex flex-row items-center justify-between border-t-[1px] border-primary-300 pt-3"
}
const _hoisted_7 = { class: "flex flex-row items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_AppHeaderText = _resolveComponent("AppHeaderText")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_AppImageLoader = _resolveComponent("AppImageLoader")!

  return (_openBlock(), _createBlock(_component_AppImageLoader, {
    "custom-class": 'px-4 py-4 rounded-[10px] flex flex-col space-y-3 bg-primary-500',
    "photo-url": '/images/card-bg.svg'
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppIcon, {
            "custom-class": 'h-[11px]',
            name: 'wallet'
          })
        ]),
        _createVNode(_component_AppNormalText, { color: 'text-white' }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($props.title), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppHeaderText, { color: 'text-white' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.canView ? `${$props.balance}` : "*********"), 1)
            ]),
            _: 1
          }),
          ($setup.canView)
            ? (_openBlock(), _createBlock(_component_AppIcon, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.canView = false)),
                "custom-class": 'w-[16px]',
                name: 'show-white'
              }))
            : _createCommentVNode("", true),
          (!$setup.canView)
            ? (_openBlock(), _createBlock(_component_AppIcon, {
                key: 1,
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.canView = true)),
                "custom-class": 'w-[16px]',
                name: 'hide-white'
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_AppIcon, {
            "custom-class": 'w-1.5',
            name: 'arrow-right-up'
          }),
          _createVNode(_component_AppNormalText, {
            size: 'small',
            "custom-class": '!font-extralight',
            class: "font-extralight",
            color: 'text-white'
          }, {
            default: _withCtx(() => [
              _createTextVNode(" + " + _toDisplayString($props.statsAmount) + " This Week ", 1)
            ]),
            _: 1
          })
        ])
      ]),
      ($props.showBottomArea)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AppNormalText, {
                color: 'text-white',
                size: 'small'
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Total Offers: " + _toDisplayString($props.totalProduct), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_AppButton, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('button-clicked'))),
              "bg-color": 'bg-primary-400',
              padding: 'py-[2px] px-3',
              "custom-class": '!text-[12px]'
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" View all ")
              ])),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}