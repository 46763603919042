import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex w-fit flex-col space-y-2"
}
const _hoisted_2 = { class: "pt-[2px] w-[25px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!

  return (!_ctx.standard)
    ? (_openBlock(), _createElementBlock("label", {
        key: 0,
        class: "flex items-center cursor-pointer space-x-2 w-full justify-between",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selected = _ctx.selected ? false : true))
      }, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(_component_AppIcon, {
          name: `${_ctx.selected ? 'checked' : 'not-checked'}`,
          customClass: `h-[15px]`
        }, null, 8, ["name"])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selected = _ctx.selected ? false : true)),
          class: "flex w-full flex-row space-x-2 cursor-pointer items-start"
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_AppIcon, {
              name: `${_ctx.selected ? 'checked' : 'not-checked'}`,
              customClass: `h-[15px]`
            }, null, 8, ["name"])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
}