import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(`px-3 py-[2px] border-[1px] text-[11px] xs:text-[8px]   whitespace-nowrap rounded-[28px]  ${
      $props.color == 'green'
        ? 'bg-success-50 dark:!bg-success-main dark:!text-success-50  border-success-50 text-success-500'
        : ''
    } first-letter:
${
  $props.color == 'red'
    ? 'bg-error-50   border-error-50 text-error-500 dark:!bg-error-main dark:!text-error-50'
    : ''
}
${
  $props.color == 'purple'
    ? 'bg-primary-50   border-primary-50 text-primary-500 dark:!bg-primary-main dark:!text-primary-50'
    : ''
}
${
  $props.color == 'blue'
    ? '!bg-blue-50   border-blue-50 !text-blue-500 dark:!bg-blue-main dark:!text-blue-50'
    : ''
}
${
  $props.color == 'yellow'
    ? 'bg-secondary-50   border-secondary-50 text-secondary-500 dark:!bg-secondary-main dark:!text-secondary-50'
    : ''
} 

${$props.color == 'outline-white' ? 'bg-transparent   border-white text-white' : ''} 

${$props.color == 'outline-black' ? 'bg-transparent   border-grey-700 text-grey-700' : ''} 


${$props.customClass}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}