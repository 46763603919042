import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row rounded-[10px] bg-secondary-50" }
const _hoisted_2 = { class: "w-[103px]" }
const _hoisted_3 = { class: "flex flex-col space-y-2 justify-between w-full px-3 py-3" }
const _hoisted_4 = { class: "h-full flex flex-col justify-center" }
const _hoisted_5 = { class: "w-full flex flex-row items-center border-t-[1px] border-secondary-300 pt-1 space-x-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_image_loader, {
        photoUrl: _ctx.data.image_url,
        class: "!rounded-l-[10px] w-[100px] h-[100px]"
      }, null, 8, ["photoUrl"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_app_normal_text, { class: "w-full !font-semibold !text-primary-400 !line-clamp-2 !text-[12px] mdlg:!text-xs" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.title), 1)
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_app_icon, {
          name: 'badge-game',
          customClass: 'h-[13px]'
        }),
        _createVNode(_component_app_normal_text, { class: "text-left !text-[12px] dark:!text-black" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data.points, false, "ngn")) + " Cashback ", 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}