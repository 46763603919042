<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Enter Name -->

    <app-text-field
      :placeholder="'What should we call your friend?'"
      :hasTitle="true"
      :type="'text'"
      :padding="'py-4 px-4'"
      name="Friend name"
      ref="friend_name"
      v-model="formData.name"
      :rules="[FormValidations.RequiredRule]"
    >
      <template v-slot:title> Name </template>
    </app-text-field>

    <app-text-field
      :placeholder="'Enter email'"
      :rules="[FormValidations.RequiredRule, FormValidations.EmailRule]"
      v-model="formData.email"
      :hasTitle="true"
      type="email"
      :padding="'py-4 px-4'"
      name="Friend Email"
      ref="friend_email"
    >
      <template v-slot:title> Email Address </template>
    </app-text-field>

    <div class="w-full flex flex-col">
      <app-button class="w-full py-3" @click.prevent="validateForm">
        Send
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import { AppFormWrapper, AppTextField } from "../AppForm";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
  },
  name: "AppModalSendGiftCard",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      name: "",
      email: "",
    });

    const friendName = ref("");
    const bankCode = ref("");

    const friend_name = ref();
    const friend_email = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const bankOptions = reactive([
      {
        key: "90",
        value: "Access bank",
      },
      {
        key: "902",
        value: "First bank",
      },
      {
        key: "910",
        value: "FCMB",
      },
    ]);

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(friend_name.value, friend_email.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      friend_name,
      friend_email,
      formData,
      bankOptions,
      friendName,
      bankCode,
      validateForm,
    };
  },
});
</script>
