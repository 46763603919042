import AuthApi from './AuthApi'
import GameApi from './GameApi'
import ShopApi from './ShopApi'
import UserApi from './UserApi'
import WalletApi from './WalletApi'
import InsuranceApi from './InsuranceApi'
import TravelApi from './TravelApi'
import ShoplistApi from './ShoplistApi'
import AdApi from './AdApi'

export const $api = {
  auth: new AuthApi(),
  game: new GameApi(),
  shop: new ShopApi(),
  user: new UserApi(),
  wallet: new WalletApi(),
  insurance: new InsuranceApi(),
  travel: new TravelApi(),
  shoplist: new ShoplistApi(),
  ad: new AdApi(),
}
