import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row justify-between py-1" }
const _hoisted_2 = { class: "space-x-2 flex flex-row" }
const _hoisted_3 = { class: "w-[37px]" }
const _hoisted_4 = { class: "w-[37px]" }
const _hoisted_5 = { class: "flex flex-col space-y-1" }
const _hoisted_6 = { class: "flex flex-col space-y-1 items-end" }
const _hoisted_7 = { class: "px-2 py-[2px] bg-[#F5EDFC] flex items-center justify-center rounded-[40px] dark:!bg-primary-300" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_icon, {
            name: _ctx.data.type,
            customClass: 'h-[36px] w-[36px]'
          }, null, 8, ["name"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_app_header_text, { class: "!text-left !line-clamp-1 text-xs !font-semibold" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.description), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_app_normal_text, { class: "!text-left !text-grey-500 !text-[11px] !font-light" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.time), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_app_normal_text, { class: "text-right !whitespace-nowrap !font-semibold" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.type == "credit" ? "+" : "-") + _toDisplayString(_ctx.Logic.Common.convertToMoney(_ctx.data.amount, false, "ngn")), 1)
        ]),
        _: 1
      }),
      _createElementVNode("span", _hoisted_7, [
        _createVNode(_component_app_normal_text, { class: "!text-[9px] !text-[#9D4EDD] !whitespace-nowrap dark:!text-white" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.category), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}