<template>
  <div class="w-full flex flex-col space-y-3 pt-2 relative">
    <app-normal-text class="w-full text-left !text-gray-600">
      A 4 digit code has been sent to your phone ({{
        localStorage.getItem("acc_phone")
      }})
    </app-normal-text>

    <!-- Otp Number -->
    <div class="w-full flex flex-col space-y-3">
      <app-otp-input
        :numberOfInput="4"
        :sizeStyle="'w-[74px] h-[70px] xs:w-[60px] xs:h-[50px]'"
        :otp-value="otpForm.otp"
        @on-filled="onOtpIsFilled"
        :type="'text'"
        :onChangeOTP="onChangeOTP"
      />
    </div>

    <div class="w-full flex flex-col pt-1 pb-4">
      <app-button
        :customClass="'w-full'"
        :padding="'py-4'"
        @click="verifyOtp()"
        :loading="loading"
      >
        Verify
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { AppNormalText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppOtpInput } from "../AppForm";

export default defineComponent({
  components: {
    AppNormalText,
    AppButton,
    AppOtpInput,
  },
  props: {
    data: {
      type: Object as () => {
        info: string;
      },
    },
    action: {
      type: Function,
    },
  },
  name: "AppModalVerifyPhone",
  setup(props, { emit }) {
    const otpForm = reactive({
      otp: "",
    });

    const otpIsFilled = ref(false);
    const loading = ref(false);

    const onOtpIsFilled = () => {
      otpIsFilled.value = true;
    };

    const onChangeOTP = (value: string) => {
      otpForm.otp = value;
    };

    const verifyOtp = () => {
      if (loading.value) return;
      loading.value = true;
      Logic.Auth.VerifyPhoneOTPForm = {
        otp: otpForm.otp.toString(),
        user_uuid: Logic.Auth.AuthUser?.uuid || "",
      };

      Logic.Auth.VerifyPhoneOTP(true)
        ?.then((data: any) => {
          if (data) {
            if (props.action) {
              props.action();
            }
            Logic.Common.showAlert({
              message: "Your phone number has been verified",
              show: true,
              type: "success",
            });
            Logic.Common.showModal({
              show: false,
            });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      Logic,
      localStorage,
      otpForm,
      onChangeOTP,
      onOtpIsFilled,
      verifyOtp,
      loading,
    };
  },
});
</script>
