import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!

  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('clicked', $props.text))),
    class: _normalizeClass(`border ${
      $props.isSelected ? 'bg-primary-50 border-primary-400' : 'border-grey-100'
    }   h-fit py-5 px-10 rounded-lg flex flex-col space-y-3 items-center`)
  }, [
    _createVNode(_component_AppIcon, {
      "custom-class": `${$props.iconSize}`,
      name: $props.icon
    }, null, 8, ["custom-class", "name"]),
    _createVNode(_component_AppNormalText, { "custom-class": 'capitalize !font-semibold' }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString($props.text), 1)
      ]),
      _: 1
    })
  ], 2))
}