import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_2 = { class: "w-full flex flex-col py-4 px-4 rounded-[10px] bg-grey-50 dark:bg-gray-900 justify-center items-center space-y-1" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_4 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Send Money' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_image_loader, {
                photoUrl: 
              _ctx.accountData.photo_url
                ? _ctx.accountData.photo_url
                : '/images/icons/user-white-bg.svg'
            ,
                class: "w-[64px] h-[64px] rounded-full"
              }, null, 8, ["photoUrl"]),
              _createVNode(_component_app_normal_text, {
                class: _normalizeClass('!font-semibold !text-sm pt-2')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" To: " + _toDisplayString(_ctx.accountData.account_name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_app_normal_text, {
                class: _normalizeClass('text-grey-main')
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" @" + _toDisplayString(_ctx.accountData.account_number), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_app_checkbox, {
              modelValue: _ctx.saveBeneficary,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.saveBeneficary) = $event)),
              standard: true
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Save as beneficiary ")
              ])),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              (_ctx.showAmount)
                ? (_openBlock(), _createBlock(_component_app_text_field, {
                    key: 0,
                    placeholder: 'Enter amount',
                    hasTitle: true,
                    type: 'tel',
                    isFormatted: true,
                    rules: [_ctx.FormValidations.RequiredRule],
                    ref: "amount",
                    name: "amount",
                    modelValue: _ctx.accountData.amount,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accountData.amount) = $event)),
                    required: ""
                  }, {
                    title: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode(" Amount ")
                    ])),
                    "inner-prefix": _withCtx(() => [
                      _createVNode(_component_app_normal_text, { class: "!text-grey-700 !font-semibold" }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode(" NGN ")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["rules", "modelValue"]))
                : _createCommentVNode("", true),
              _createVNode(_component_app_text_field, {
                placeholder: 'Add comment',
                hasTitle: true,
                type: 'text',
                rules: [_ctx.FormValidations.RequiredRule],
                ref: "note",
                name: "Additional Notes",
                "update-value": _ctx.accountData.narration,
                modelValue: _ctx.accountData.narration,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.accountData.narration) = $event)),
                "is-textarea": true
              }, {
                title: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Additional Notes ")
                ])),
                _: 1
              }, 8, ["rules", "update-value", "modelValue"])
            ]),
            _cache[7] || (_cache[7] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_AppButton, {
                  padding: 'py-3',
                  "text-color": 'text-white',
                  class: "w-full",
                  onClick: _ctx.makeTransfer
                }, {
                  default: _withCtx(() => _cache[8] || (_cache[8] = [
                    _createTextVNode(" Continue ")
                  ])),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}