<template>
  <div class="w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0">
    <app-normal-text class="w-full text-left text-gray-700">
      Select promotion type
    </app-normal-text>
    <div class="w-full grid grid-cols-2 gap-3">
      <div
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer"
        @click="handlePromotionShoppable('1')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'shoppable'" :customClass="'!h-[15px]'" />
          </div>

          <div
            class="w-full flex flex-col space-y-[1px] justify-center items-center"
          >
            <app-normal-text> Shoppable </app-normal-text>

            <app-normal-text class="!text-[10px] !text-gray-500 text-center">
              Promote your Shoplists
            </app-normal-text>
          </div>
        </div>
      </div>

      <app-file-attachment
        :isWrapper="true"
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer"
        :accept="'image/jpeg, image/png, image/gif, video/mp4'"
        @update:modelValue="(data) => handlePromotionWebsiteVisit(data, 'blob')"
        @update:localFileUrl="
          (data) => handlePromotionWebsiteVisit(data, 'url')
        "
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'website-visit'" :customClass="'!h-[15px]'" />
          </div>

          <div
            class="w-full flex flex-col space-y-[1px] justify-center items-center"
          >
            <app-normal-text> Website Visit </app-normal-text>

            <app-normal-text class="!text-[10px] !text-gray-500 text-center">
              Promote your website links
            </app-normal-text>
          </div>
        </div>
      </app-file-attachment>
      <app-file-attachment
        :isWrapper="true"
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer"
        :accept="'image/jpeg, image/png, image/gif, video/mp4'"
        @update:modelValue="(data) => handlePromotionPolls(data, 'blob')"
        @update:localFileUrl="(data) => handlePromotionPolls(data, 'url')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'polls'" :customClass="'!h-[17px]'" />
          </div>

          <div
            class="w-full flex flex-col space-y-[1px] justify-center items-center"
          >
            <app-normal-text> Polls </app-normal-text>

            <app-normal-text class="!text-[10px] !text-gray-500 text-center">
              Survey your customers
            </app-normal-text>
          </div>
        </div>
      </app-file-attachment>
      <app-file-attachment
        :isWrapper="true"
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[190px] cursor-pointer"
        :accept="'image/jpeg, image/png, image/gif'"
        @update:modelValue="(data) => handlePromotionBanner(data, 'blob')"
        @update:localFileUrl="(data) => handlePromotionBanner(data, 'url')"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'banner'" :customClass="'!h-[17px]'" />
          </div>
          <div
            class="w-full flex flex-col space-y-[1px] justify-center items-center"
          >
            <app-normal-text> Banner Ad </app-normal-text>

            <app-normal-text class="!text-[10px] !text-gray-500 text-center">
              Promote your brand
            </app-normal-text>
          </div>
        </div>
      </app-file-attachment>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from "vue";
import { Logic } from "../../composable";
import { AppFileAttachment } from "../AppForm";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppFileAttachment,
  },
  name: "ModalPromotionType",
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object as () => {
        getMediaBox: Function;
      },
    },
  },
  setup(props) {
    const promotionData = reactive<{
      shoppable: {
        shoplist_id: string;
      };
      polls: {
        url: string;
        blob?: Blob;
      };
      website_visit: {
        url: string;
        blob?: Blob;
      };
      banner: {
        url: string;
        blob?: Blob;
      };
    }>({
      shoppable: {
        shoplist_id: "",
      },
      polls: {
        url: "",
      },
      website_visit: {
        url: "",
      },
      banner: {
        url: "",
      },
    });

    const showAddShoplist = () => {
      Logic.Common.showModal({
        show: true,
        type: "add_shoplist",
        title: "Select a Shoplist",
        action: (data: any) => {
          props.action("shoppable", "0", data);
        },
        extraData: {
          selected: "",
          getMediaBox: props.data?.getMediaBox,
        },
      });
    };

    const handlePromotionShoppable = (id: string) => {
      promotionData.shoppable.shoplist_id = id;
      if (props.action) {
        // props.action("shoppable", id);
        showAddShoplist();
      }
    };

    const handlePromotionPolls = (data: any, type: "url" | "blob") => {
      if (type == "url") {
        promotionData.polls.url = data;

        if (props.action) {
          props.action(
            "polls",
            promotionData.polls.url,
            promotionData.polls.blob
          );
        }
      } else {
        promotionData.polls.blob = data;
      }
    };

    const handlePromotionWebsiteVisit = (data: any, type: "url" | "blob") => {
      if (type == "url") {
        promotionData.website_visit.url = data;

        if (props.action) {
          props.action(
            "website_visit",
            promotionData.website_visit.url,
            promotionData.website_visit.blob
          );
        }
      } else {
        promotionData.website_visit.blob = data;
      }
    };

    const handlePromotionBanner = (data: any, type: "url" | "blob") => {
      if (type == "url") {
        promotionData.banner.url = data;
        if (props.action) {
          props.action(
            "banner",
            promotionData.banner.url,
            promotionData.banner.blob
          );
        }
      } else {
        promotionData.banner.blob = data;
      }
    };

    return {
      Logic,
      promotionData,
      showAddShoplist,
      handlePromotionShoppable,
      handlePromotionPolls,
      handlePromotionWebsiteVisit,
      handlePromotionBanner,
    };
  },
});
</script>
