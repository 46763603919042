<template>
  <div class="w-full flex flex-col space-y-2 cursor-pointer" v-if="item">
    <app-media-box
      :data="item"
      :is-video="!item.is_grid"
      :is-grid="item.is_grid"
      class="!h-[160px] mdlg:!h-[200px] !rounded-[10px] border-[1px] border-grey-200 dark:border-grey-800"
    >
      <!-- Group buy -->
      <span :class="`absolute right-[6%]  top-[6%]`">
        <span
          :class="`px-2 py-1 rounded-[4px] ${
            item.reward_type == 'cashback' ? 'bg-primary-900' : 'bg-[#FFAA00]'
          } flex flex-row items-center justify-center`"
        >
          <app-normal-text class="!text-white !text-[10px] text-center">
            {{
              item.reward_type == "cashback"
                ? `${parseFloat(
                    item?.reward_percentage?.toFixed(2) || "0"
                  )}% Back`
                : `Save ${parseFloat(
                    item?.reward_percentage?.toFixed(2) || "0"
                  )}%`
            }}
          </app-normal-text>
        </span>
      </span>

      <!-- Processing -->
      <div
        v-if="!item.is_grid && !item.video_url"
        class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 !rounded-[10px] z-[999] flex flex-row items-center justify-center"
      >
        <app-normal-text class="!text-white !text-[12px]">
          Processing...
        </app-normal-text>
      </div>
    </app-media-box>
    <!-- Title -->
    <div class="w-full flex flex-col space-y-[2px] items-start justify-start">
      <app-normal-text
        class="!line-clamp-1 text-left !text-black dark:!text-white !text-xs font-semibold"
      >
        {{ item.title }}
      </app-normal-text>
      <div class="w-full flex flex-row space-x-2 items-center">
        <app-icon :name="'shoplist-icon'" :customClass="'h-[13px]'" />
        <app-normal-text class="!text-[11px] dark:!text-white">
          {{ item.stats?.items }} Items
        </app-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppMediaBox,
  },
  props: {
    item: {
      type: Object as () => MediaBox,
    },
  },
  setup() {
    return {};
  },
  name: "AppShoplist",
});
</script>
