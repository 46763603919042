import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_4 = { class: "w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_6 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2 justify-center items-center py-3 px-3" }
const _hoisted_8 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_file_attachment = _resolveComponent("app-file-attachment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, { class: "w-full text-left text-gray-700" }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode(" Select shoplist media type ")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      false
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "col-span-2 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInstagramSelected && _ctx.handleInstagramSelected(...args)))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_app_icon, {
                  name: 'instagram',
                  class: _normalizeClass('h-[15px] mdlg:!h-[20px] md:!h-[20px] ')
                })
              ]),
              _createVNode(_component_app_normal_text, { class: "!text-center" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" Import video from Instagram ")
                ])),
                _: 1
              })
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer",
        accept: 'video/mp4',
        "onUpdate:modelValue": _ctx.handleVideoSelected,
        "onUpdate:localFileUrl": _ctx.handleVideoURLReady
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_icon, {
                name: 'video',
                customClass: '!h-[15px]'
              })
            ]),
            _createVNode(_component_app_normal_text, null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Upload Video ")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"]),
      _createVNode(_component_app_file_attachment, {
        isWrapper: true,
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer",
        accept: 'image/jpeg,image/png,image/gif',
        "onUpdate:modelValue": _ctx.handleImageSelected,
        "onUpdate:localFileUrl": _ctx.handleImageURLReady
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_app_icon, {
                name: 'image',
                customClass: '!h-[17px]'
              })
            ]),
            _createVNode(_component_app_normal_text, null, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Updload Image ")
              ])),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onUpdate:modelValue", "onUpdate:localFileUrl"])
    ])
  ]))
}