<template>
  <app-wrapper>
    <auth-layout
      @button-clicked="Logic.Common.GoToRoute('/auth/login')"
      @go-back="Logic.Common.goBack()"
      :go-back="false"
      :right-button="false"
    >
      <template v-slot:button-text>Login</template>
      <template v-slot:header-text>Welcome to Shoppoint!</template>

      <div class="w-full flex flex-col pt-0 space-y-4">
        <app-normal-text
          class="!text-gray-500 dark:!text-gray-400 !text-left hidden md:!block pt-4"
        >
          Unlock personalized cashback savings and rewards.
        </app-normal-text>

        <div
          class="w-full flex flex-col space-y-4 md:!justify-center lg:!justify-center mdlg:!justify-center justify-center md:!h-[450px] lg:!h-[450px] mdlg:!h-[450px] h-[70vh]"
        >
          <div
            class="w-full md:!hidden flex flex-col items-center justify-center pb-3 pt-4"
          >
            <app-icon
              :name="'logo-large'"
              :customClass="'h-[140px] w-[140px]'"
            />
          </div>

          <div class="w-full flex flex-col space-y-4 md:!hidden">
            <app-normal-text
              class="!text-gray-500 dark:!text-gray-400 !text-left"
            >
              Unlock personalized cashback savings and rewards.
            </app-normal-text>
          </div>

          <app-header-text
            class="type-welcome !text-2xl text-left !text-primary-500 dark:!text-primary-300 md:!text-center lg:!text-center mdlg:!text-center min-h-[100px]"
          ></app-header-text>

          <div
            class="w-full flex flex-col pt-4 justify-center items-center pb-4"
          >
            <app-button
              class="!w-fit !px-5 !py-2"
              @click="Logic.Common.GoToRoute('/auth/signup')"
              >Get Started</app-button
            >
          </div>
        </div>
      </div>
    </auth-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppHeaderText,
  AppButton,
  AppIcon,
} from "@shpt/ui-components";

export default defineComponent({
  components: {
    AppWrapper,
    AppNormalText,
    AppHeaderText,
    AppButton,
    AppIcon,
  },
  name: "StartWelcomePage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Welcome Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Welcome to Shoppoint",
    });

    localStorage.setItem("user_onboarded", "true");

    return {
      Logic,
    };
  },
});
</script>
