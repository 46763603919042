import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full grid grid-cols-2 absolute top-0 left-0 z-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridImagesBase, (image, index) => {
      return (_openBlock(), _createBlock(_component_app_image_loader, {
        photoUrl: image.url,
        class: _normalizeClass(`${image.width} h-full rounded-none`),
        key: index,
        style: _normalizeStyle(`${image.border} `)
      }, null, 8, ["photoUrl", "class", "style"]))
    }), 128))
  ]))
}