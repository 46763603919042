import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!

  return (_ctx.allBannerAds.length > 0)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "w-full flex flex-col space-y-2 relative mdlg:min-h-[80px] min-h-[55px] dark:border-[1px] dark:border-gray-100",
        id: `bannerAdContainer-${_ctx.uniqueKey}`
      }, [
        (_ctx.imageIsLoaded)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _ctx.allBannerAds[_ctx.currentIndex].base_image,
              class: "w-full h-auto max-h-[130px] cursor-pointer",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openBrowser(_ctx.allBannerAds[_ctx.currentIndex].landing_page_url)))
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createBlock(_component_app_image_loader, {
              key: 1,
              photoUrl: _ctx.allBannerAds[_ctx.currentIndex].base_image,
              class: "w-full mdlg:!h-[80px] !h-[55px] rounded-[10px]",
              onLoaded: _ctx.imageIsDoneLoading
            }, null, 8, ["photoUrl", "onLoaded"]))
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}