<template>
  <div class="flex flex-row items-center space-x-1">
    <template v-for="i in 5" :key="i">
      <app-icon
        v-if="i <= rating"
        name="star"
        :custom-class="`${customClass}`"
      />
      <app-icon v-else name="empty-star" :custom-class="`${customClass}`" />
    </template>
  </div>
</template>
<script lang="ts">
import AppIcon from "../AppIcon";

export default {
  components: {
    AppIcon,
  },
  props: {
    rating: {
      type: Number,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  name: "AppRatings",
};
</script>
