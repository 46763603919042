<template>
  <app-wrapper>
    <app-login-page />
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { AppLoginPage } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppLoginPage,
    AppWrapper,
  },
  name: "LoginPage",
  layout: "Auth",
  middlewares: {
    tracking_data: {
      lable: "Login Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Sign In",
    });

    return {};
  },
});
</script>
