<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Select category -->

    <app-select
      :placeholder="'Select Category'"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      :options="spendingCategoriesOptions"
      ref="category"
      :update-key="formData.category"
      v-model="formData.category"
    >
      <template v-slot:title> Spending Category </template>
    </app-select>

    <!-- Merchant name -->

    <app-text-field
      :placeholder="'Enter name'"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      :rules="[FormValidations.RequiredRule]"
      v-model="formData.merchant_name"
      name="Merchant name"
      ref="merchant_name"
      type="text"
      :update-value="formData.merchant_name"
    >
      <template v-slot:title> Merchant name </template>
      <template v-slot:inner-prefix>
        <app-icon :name="'merchant-gray'" :customClass="'h-[14px]'" />
      </template>
    </app-text-field>

    <!-- Amount -->

    <app-text-field
      v-if="formData.total_amount"
      :placeholder="'Enter amount'"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      :rules="[FormValidations.RequiredRule]"
      v-model="formData.total_amount"
      name="Total Amount"
      ref="total_amount"
      type="tel"
      :is-formatted="true"
    >
      <template v-slot:title> Total Amount </template>
      <template v-slot:inner-prefix>
        <app-icon :name="'money-gray'" :customClass="'h-[14px]'" />
      </template>
    </app-text-field>

    <div class="w-full flex flex-row items-center justify-center space-x-3">
      <app-button
        @click.prevent="validateForm()"
        :customClass="'w-full'"
        class="py-3"
      >
        Save
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { Logic } from "../../composable";
import { AppFormWrapper, AppSelect, AppTextField } from "../AppForm";
import AppIcon from "../AppIcon";
import { SelectOption } from "../../types";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
    AppIcon,
    AppSelect,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        total_amount: number;
        category: string;
        merchant_name: string;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },

  name: "AppModalEditReceipt",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      category: "",
      merchant_name: "",
      total_amount: "",
    });

    const defaultAmount = ref("");

    const spendingCategoriesOptions = reactive<SelectOption[]>([]);

    const ManySpendingCategory = ref(Logic.Shop.ManySpendingCategory);

    const category = ref();
    const merchant_name = ref();
    const total_amount = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const setSpendingCategoriesOptions = () => {
      if (ManySpendingCategory.value) {
        spendingCategoriesOptions.length = 0;

        ManySpendingCategory.value.forEach((category: any) => {
          spendingCategoriesOptions.push({
            key: category.id,
            value: category.name,
          });
        });
      }
    };

    watch(ManySpendingCategory, () => {
      setSpendingCategoriesOptions();
    });

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(
        category.value,
        merchant_name.value,
        total_amount.value
      );
      Logic.Shop.watchProperty("ManySpendingCategory", ManySpendingCategory);
      Logic.Shop.GetSpendingCategories();
      setSpendingCategoriesOptions();

      if (props.data) {
        formData.category = props.data.category;
        formData.merchant_name = props.data.merchant_name;
        formData.total_amount = props.data.total_amount.toString();
        defaultAmount.value = props.data.total_amount.toString();
      }
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      formData.total_amount = parseFloat(
        formData.total_amount.replace(/,/g, "")
      ).toString();

      if (status) {
        if (props.action) {
          props.action(formData);
        }
        return formData;
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      category,
      formData,
      merchant_name,
      total_amount,
      spendingCategoriesOptions,
      Logic,
      defaultAmount,
      validateForm,
    };
  },
});
</script>
