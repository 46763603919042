<template>
  <app-wrapper>
    <dashboard-layout
      topBarType="plain"
      :has-cart="true"
      title=""
      :pageKey="'pay'"
      :hasStory="false"
      :hasShoppingBasket="false"
    >
      <template #extra-top-bar-right>
        <div class="flex flex-row space-x-3 items-center">
          <span
            class="px-4 py-[6px] bg-primary-50 rounded-[40px] flex items-center justify-center cursor-pointer space-x-1 mdlg:!hidden"
            @click="Logic.Common.GoToRoute(`/pay/merchant`)"
          >
            <app-icon :name="'request-purple'" :customClass="'h-[18px]'" />
            <app-normal-text class="!text-primary-500"> Pay </app-normal-text>
          </span>

          <span
            class="px-4 py-[6px] bg-primary-50 rounded-[40px] flex items-center justify-center cursor-pointer space-x-1"
            @click="Logic.Common.GoToRoute(`/pay/user`)"
          >
            <app-icon :name="'send-purple'" :customClass="'h-[17px]'" />
            <app-normal-text class="!text-primary-500"> Send </app-normal-text>
          </span>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-4 pt-2">
        <!-- Banner Ads -->
        <div class="w-full flex flex-col space-y-3 px-4 pt-3">
          <app-banner-ad :data="bannerAds" />
        </div>

        <!-- Services -->
        <div class="w-full flex flex-col space-y-2">
          <div class="w-full flex flex-row space-x-2">
            <div></div>
            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
              id="serviceContainerShoplist"
            >
              <div class="flex flex-row py-2 pr-4">
                <div
                  class="flex flex-row pl-2 cursor-pointer"
                  v-for="(service, index) in services"
                  :key="index"
                  @click="slidePosition = index"
                  :id="`service${index}`"
                >
                  <div
                    :class="` ${
                      selectedService == service.id
                        ? 'bg-primary-main'
                        : 'bg-[#f3f3f3] dark:bg-gray-700'
                    } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`"
                  >
                    <div class="w-[18px]">
                      <app-icon
                        :name="
                          selectedService == service.id
                            ? `services/${service.icon}-active`
                            : `services/${service.icon}${
                                Logic.Common.currentAppTheme() == 'light'
                                  ? ''
                                  : '-active'
                              }`
                        "
                        :customClass="`${service.icon_size}`"
                      />
                    </div>
                    <app-normal-text
                      :class="`!whitespace-nowrap ${
                        selectedService == service.id
                          ? 'text-white'
                          : 'text-gray-600 dark:text-gray-50'
                      }`"
                    >
                      {{ service.name }}
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Service Items -->
          <div class="w-full flex flex-col space-y-0 h-full">
            <app-swiper
              :free-mode="false"
              :show-pagination="false"
              :space-between="1"
              :slide-per-view="1"
              :currentSlidePosition="currentSlidePosition"
              custom-class="!h-auto "
              :swiperClass="''"
              v-model="slidePosition"
              id="swiperContainerServices"
            >
              <swiper-slide
                v-for="(main_item, main_index) in services"
                :key="main_index"
                class="!w-full flex flex-col !h-auto"
              >
                <div class="w-full grid grid-cols-2 gap-4 px-4">
                  <div
                    v-for="(item, index) in services.filter(
                      (eachItem) => eachItem.id == selectedService
                    )[0].items"
                    :key="index"
                    :class="`col-span-1 rounded-[8px] flex flex-col justify-center items-center space-y-3 h-[115px] mdlg:!h-[140px] md:!h-[140px] cursor-pointer`"
                    :style="`background-color: ${item.bg_color};`"
                    @click="
                      Logic.Common.GoToRoute(
                        `/cashback/${
                          services.filter(
                            (eachItem) => eachItem.id == selectedService
                          )[0].id
                        }?target=${item.name}`
                      )
                    "
                  >
                    <img
                      :src="`images/brands/${item.icon}.png`"
                      class="!h-[55px]"
                    />

                    <app-normal-text
                      class="!text-center !font-semibold !capitalize dark:!text-black"
                    >
                      {{ item.name }}
                    </app-normal-text>
                  </div>
                </div>
              </swiper-slide>
            </app-swiper>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import { BannerAd } from "@/modules/common";
import {
  AppSwiper,
  AppIcon,
  AppNormalText,
  AppBannerAd,
} from "@shpt/ui-components";
import { SwiperSlide } from "swiper/vue";
import { scrollToSpecificItem } from "@shpt/ui-components/src/composable";
import AppWrapper from "@/components/AppWrapper.vue";
import { selectedService } from "@shpt/ui-components/src/composable/shop";
import { onIonViewWillEnter } from "@ionic/vue";

export default defineComponent({
  components: {
    AppSwiper,
    AppIcon,
    AppNormalText,
    AppWrapper,
    AppBannerAd,
    SwiperSlide,
  },
  name: "CashbackPage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Cashback Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Cashback",
    });

    const authUser = ref(Logic.Auth.AuthUser);

    const slidePosition = ref(0);

    const currentSlidePosition = ref(0);

    const services = reactive([
      {
        id: "airtime",
        name: "Airtime",
        icon: "airtime",
        icon_size: "!h-[17px]",
        items: [
          {
            icon: "mtn",
            name: "MTN",
            bg_color: "#FFF6E6",
          },
          {
            icon: "airtel",
            name: "Airtel",
            bg_color: "#FAEBEB",
          },

          {
            icon: "glo",
            name: "Glo",
            bg_color: "#EAF6EF",
          },
          {
            icon: "9mobile",
            name: "9mobile",
            bg_color: "#F3F3F3",
          },
        ],
      },
      {
        id: "data",
        name: "Data",
        icon: "data",
        icon_size: "!h-[17px]",
        items: [
          {
            icon: "spectranet",
            name: "Spectranet",
            bg_color: "#D8E6FF",
          },
          {
            icon: "smile",
            name: "Smile",
            bg_color: "#EAF5E3",
          },
          {
            icon: "mtn",
            name: "MTN",
            bg_color: "#FFF6E6",
          },
          {
            icon: "airtel",
            name: "Airtel",
            bg_color: "#FAEBEB",
          },
          {
            icon: "9mobile",
            name: "9mobile",
            bg_color: "#F3F3F3",
          },
          {
            icon: "glo",
            name: "Glo",
            bg_color: "#EAF6EF",
          },
        ],
      },
      {
        id: "electricity",
        name: "Power",
        icon: "power",
        icon_size: "!h-[17px]",
        items: [
          {
            icon: "abuja-electric",
            name: "Abuja Electric",
            bg_color: "#F1F0FF",
          },
          {
            icon: "yola-electric",
            name: "Yola Electric",
            bg_color: "#FAEBEB",
          },
          {
            icon: "portharcourt-electric",
            name: "Portharcourt Electric",
            bg_color: "#F3F3F3",
          },
          {
            icon: "kedco-electric",
            name: "Kedco Electric",
            bg_color: "#E5E7FD",
          },
          {
            icon: "kaduna-electric",
            name: "Kaduna Electric",
            bg_color: "#FFFFF2",
          },
          {
            icon: "jos-electric",
            name: "Jos Electric",
            bg_color: "#FFF8EB",
          },
          {
            icon: "ikeja-electric",
            name: "Ikeja Electric",
            bg_color: "#FFF9EC",
          },
          {
            icon: "ibadan-electric",
            name: "Ibadan Electric",
            bg_color: "#FFF5E5",
          },
          {
            icon: "enugu-electric",
            name: "Enugu Electric",
            bg_color: "#FFF1F1",
          },
          {
            icon: "eko-electric",
            name: "Eko Electric",
            bg_color: "#F2EEFF",
          },
          {
            icon: "benin-electric",
            name: "Benin Electric",
            bg_color: "#FFFEEC",
          },
        ],
      },
      {
        id: "cable",
        name: "Cable TV",
        icon: "cable",
        icon_size: "!h-[17px]",
        items: [
          {
            icon: "dstv",
            name: "DSTV",
            bg_color: "#EAEEF8",
          },
          {
            icon: "gotv",
            name: "GoTV",
            bg_color: "#EAF6EF",
          },
          {
            icon: "startimes",
            name: "startimes",
            bg_color: "#FFF6E6",
          },
          {
            icon: "showmax",
            name: "showmax",
            bg_color: "#FCEFF3",
          },
        ],
      },
      {
        id: "gift_card",
        name: "Gift Cards",
        icon: "gift-card",
        icon_size: "!h-[17px]",
        items: [
          {
            icon: "fashion",
            name: "Fashion",
            bg_color: "#F7EBFF",
          },
          {
            icon: "home-decor",
            name: "Home Decor",
            bg_color: "#EAEEF8",
          },
          {
            icon: "beauty",
            name: "Beauty",
            bg_color: "#FCEFF3",
          },
          {
            icon: "film-tv",
            name: "Film & TV",
            bg_color: "#FFF6E6",
          },
          {
            icon: "app-store",
            name: "App Store",
            bg_color: "#EAF6EF",
          },
          {
            icon: "resturant",
            name: "Restaurant",
            bg_color: "#FAEBEB",
          },
          {
            icon: "hotels",
            name: "Hotels",
            bg_color: "#F3F3F3",
          },
          {
            icon: "gaming",
            name: "Gaming",
            bg_color: "#FFF6E6",
          },
          {
            icon: "electronics",
            name: "Electronics",
            bg_color: "#F5EDFC",
          },
          {
            icon: "shopping",
            name: "Shopping",
            bg_color: "#FCEFF3",
          },
          {
            icon: "food-delivery",
            name: "Food Delivery",
            bg_color: "#FAEBEB",
          },
        ],
      },
    ]);

    const bannerAds = reactive<BannerAd[]>([
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "red",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "brown",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
    ]);

    const focusOnService = (index: number) => {
      scrollToSpecificItem("serviceContainerShoplist", `service${index}`);
    };

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
      switch (slidePosition.value) {
        case 0:
          selectedService.value = "airtime";
          break;
        case 1:
          selectedService.value = "data";
          break;
        case 2:
          selectedService.value = "electricity";
          break;
        case 3:
          selectedService.value = "cable";
          break;
        case 4:
          selectedService.value = "gift_card";
          break;
        default:
          break;
      }
      focusOnService(slidePosition.value);
    });

    onIonViewWillEnter(() => {
      Logic.Game.CheckGameStatus();
    });

    onMounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
      }
    });

    return {
      Logic,
      authUser,
      bannerAds,
      services,
      selectedService,
      slidePosition,
      currentSlidePosition,
    };
  },
});
</script>
