import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 pb-4 relative md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_3 = { class: "w-full flex flex-row space-x-4 items-center pt-3" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = ["href"]
const _hoisted_7 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_normal_text, {
        customClass: 'text-left',
        class: "!text-gray-700"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.data.description), 1)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.data.data.business_social_links?.facebook)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            href: `https://www.facebook.com/${_ctx.data.data.business_social_links?.facebook}`,
            target: "_blank"
          }, [
            _createVNode(_component_app_icon, {
              name: "facebook",
              class: _normalizeClass('!h-[20px]')
            })
          ], 8, _hoisted_4))
        : _createCommentVNode("", true),
      (_ctx.data.data.business_social_links?.instagram)
        ? (_openBlock(), _createElementBlock("a", {
            key: 1,
            href: `https://www.instagram.com/${_ctx.data.data.business_social_links?.instagram}`,
            target: "_blank"
          }, [
            _createVNode(_component_app_icon, {
              name: "instagram",
              class: _normalizeClass('!h-[20px]')
            })
          ], 8, _hoisted_5))
        : _createCommentVNode("", true),
      (_ctx.data.data.business_social_links?.whatsapp)
        ? (_openBlock(), _createElementBlock("a", {
            key: 2,
            href: `https://wa.me/${_ctx.data.data.business_social_links?.whatsapp}`,
            target: "_blank"
          }, [
            _createVNode(_component_app_icon, {
              name: "whatsapp",
              class: _normalizeClass('!h-[23px]')
            })
          ], 8, _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.data.data.business_social_links?.website)
        ? (_openBlock(), _createElementBlock("a", {
            key: 3,
            href: `${_ctx.data.data.business_social_links?.website}`,
            target: "_blank"
          }, [
            _createVNode(_component_app_icon, {
              name: "website",
              class: _normalizeClass('!h-[20px]')
            })
          ], 8, _hoisted_7))
        : _createCommentVNode("", true)
    ])
  ]))
}