<template>
  <div class="w-full flex flex-col space-y-3 pt-2">
    <div
      class="w-full py-4 px-4 bg-gray_50 rounded-[12px] items-center justify-center space-y-1 flex flex-col"
    >
      <app-normal-text :color="'text-shop_dark_400'">
        Amount to pay
      </app-normal-text>
      <app-header-text :custom-class="'!font-extrabold !text-xl'">
        {{ Logic.Common.convertToMoney(data.amount, true, "ngn") }}
      </app-header-text>
    </div>

    <div
      class="w-full flex flex-col space-y-3 px-0 py-3 bg-gray_50 rounded-[14px]"
    >
      <app-normal-text
        :customClass="'w-full px-4  pb-2   border-b-[1px] border-opacity-30 border-[#BDA3D7]'"
        :color="'text-shop_dark_400'"
      >
        Send money to
      </app-normal-text>
      <div class="px-4 flex flex-row items-center space-x-2 justify-between">
        <div class="flex flex-col space-y-1">
          <app-normal-text :color="'text-shop_dark_200 '">
            Bank Name
          </app-normal-text>
          <app-normal-text :color="'text-shop_dark_400 !font-semibold '">
            Kuda Microfinance Bank
          </app-normal-text>
        </div>
      </div>

      <div
        class="px-4 flex pb-4 flex-row items-center space-x-2 justify-between"
      >
        <div class="flex flex-col space-y-1">
          <app-normal-text :color="'text-shop_dark_200 '">
            Account Number
          </app-normal-text>
          <app-normal-text :color="'text-shop_dark_400 !font-semibold '">
            {{ data.funding_account?.account_number }}
          </app-normal-text>
        </div>

        <app-button
          :padding="'px-3 py-2'"
          :border="'border-b-[3px]'"
          :customClass="'!flex flex-row space-x-2 items-center'"
          @click="copyAccountNumber(data.funding_account?.account_number)"
        >
          {{ accountCopied ? "Copied!" : "Copy" }}
          <app-icon :name="'copy-white'" :customClass="'h-[17px] pl-2'" />
        </app-button>
      </div>
    </div>

    <div class="w-full flex flex-col space-y-2" v-if="timer.instance">
      <div class="w-full flex flex-row justify-between">
        <app-normal-text class="!font-semibold">
          Waiting for payment...
        </app-normal-text>
        <span>
          <svg
            :class="`animate-spin  h-5 w-5  text-shop_purple_400`"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              class="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="4"
            ></circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </span>
      </div>
      <div
        class="w-full flex flex-col space-y-3 px-0 py-3 items-center justify-center bg-gray_50 rounded-[14px]"
      >
        <div class="flex flex-row space-x-2">
          <app-normal-text
            class="!text-base !font-bold !text-shop_purple_400"
            >{{ formattedTime }}</app-normal-text
          >
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col pt-1 xs:pb-3" v-if="!timer.instance">
      <app-button
        :customClass="'w-full'"
        :padding="'py-4'"
        @click="continuePayment()"
      >
        I've made the payment
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import AppNavCard from "../AppNavCard";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppCheckbox } from "../AppForm";
import { User } from "../../gql/graphql";

export default defineComponent({
  components: {
    AppNavCard,
    AppIcon,
    AppNormalText,
    AppButton,
    AppHeaderText,
    AppCheckbox,
  },
  props: {
    data: {
      type: Object as () => {
        funding_account: any;
        amount: number;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppModalTopupCashback",
  setup(props) {
    const accountCopied = ref(false);

    const continuePayment = () => {
      Logic.Wallet.ResolveFundingAccount(props?.data?.funding_account?.uuid);
      startCountdown();
    };

    const timer = reactive<{
      instance: any;
      totalSeconds: number;
    }>({
      instance: null,
      totalSeconds: 5 * 60,
    });

    const formattedTime = computed(() => {
      const minutes = Math.floor(timer.totalSeconds / 60);
      const seconds = timer.totalSeconds % 60;
      return `${String(minutes).padStart(2, "0")} : ${String(seconds).padStart(
        2,
        "0"
      )}`;
    });

    const countDown = () => {
      if (timer.totalSeconds === 0) {
        clearInterval(timer.instance);
        timer.instance = null;
        Logic.Common.showModal({
          show: true,
          title: "",
          type: "request_feedback",
          preventClose: true,
          closeAction: () => {
            Logic.Common.goBack();
          },
          action: () => {
            //
          },
          extraData: {
            icon: "error-warning",
            title:
              "We have not received your payment. You will be notified when your payment is received",
            large_title: "Payment Pending",
            buttons: [
              {
                label: "Continue",
                action: () => {
                  Logic.Common.goBack();
                },
              },
            ],
          },
        });
        // Handle timer completion
      } else {
        timer.totalSeconds--;
      }
    };
    const startCountdown = () => {
      timer.instance = setInterval(countDown, 1000);
    };

    const copyAccountNumber = (text: string) => {
      accountCopied.value = true;
      Logic.Common.copytext(text);
      setTimeout(() => {
        accountCopied.value = false;
      }, 3000);
    };

    onUnmounted(() => {
      clearInterval(timer.instance);
      Logic.Wallet.CheckStatusState.active = false;
    });

    return {
      Logic,
      accountCopied,
      formattedTime,
      timer,
      startCountdown,
      continuePayment,
      copyAccountNumber,
    };
  },
});
</script>
