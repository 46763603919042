<template>
  <app-wrapper>
    <subpage-layout :title="'Buy Gift Card'">
      <div class="w-full flex flex-col space-y-3">
        <!-- Category -->
        <div class="w-full flex flex-row space-x-2">
          <div></div>
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
            id="giftCardMainContainer"
          >
            <div class="flex flex-row pr-4">
              <div
                class="flex flex-row pl-2"
                v-for="(category, index) in giftCardCategories"
                :key="index"
                @click="selectedCategory = category.name"
                :id="`giftcard${category.name
                  .toLowerCase()
                  .replaceAll(' ', '_')}`"
              >
                <div
                  :class="` ${
                    selectedCategory == category.name
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-grey-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1 cursor-pointer`"
                >
                  <app-normal-text
                    :class="`!whitespace-nowrap ${
                      selectedCategory == category.name
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-100'
                    }`"
                  >
                    {{ category.name }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search -->
        <div class="w-full flex flex-col px-4">
          <app-text-field
            :placeholder="'Search Giftcards'"
            :hasTitle="true"
            :type="'text'"
            name="Search_Giftcards"
            ref="SearchGiftcards"
            v-model="searchQuery"
          >
            <template v-slot:inner-prefix>
              <app-icon :name="'search-grey'" :customClass="'h-[18px]'" />
            </template>
          </app-text-field>
        </div>

        <!-- Gift cards -->
        <div class="w-full grid grid-cols-2 gap-3 px-4">
          <div
            v-for="(item, index) in billersOptons"
            :key="index"
            class="col-span-1 rounded-[9.26px] flex flex-col border-[1px] border-[#E9E6ED] dark:border-gray-700 cursor-pointer"
            @click="Logic.Common.GoToRoute(`/cashback/gift_card/${item.uuid}`)"
          >
            <app-image-loader
              :photoUrl="''"
              class="!w-full h-[101px] mdlg:!h-[130px] md:!h-[130px] rounded-t-[9.26px] relative"
            >
              <div
                :class="`w-full flex flex-row items-center h-full justify-center absolute rounded-t-[9.26px] top-0 left-0`"
                :style="`background-color: ${item.base_color};`"
              >
                <img :src="item.base_image" class="h-[80px]" />
              </div>
            </app-image-loader>
            <div class="px-2 py-2 w-full flex flex-col space-y-2">
              <app-normal-text class="!font-semibold text-left !line-clamp-1">
                {{ item.merchant_name }} Gift Cards
              </app-normal-text>

              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text
                  class="!text-[#664D7E] dark:!text-primary-100 !text-[11px] !line-clamp-1"
                >
                  {{ item.currencies.join(",") }}
                </app-normal-text>

                <div class="flex flex-row items-center">
                  <app-image-loader
                    v-for="(image, index) in item.flags"
                    :key="index"
                    :photoUrl="image"
                    class="h-[15px] w-[15px]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h-[100px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  getProductPoint,
  processProductData,
} from "@shpt/ui-components/src/composable/shop";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import {
  FormValidations,
  scrollToSpecificItem,
} from "@shpt/ui-components/src/composable";
import {
  AppNormalText,
  AppImageLoader,
  AppIcon,
  AppTextField,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

interface MerchantGiftCard {
  base_image: string;
  base_color: string;
  merchant_name: string;
  currencies: string[];
  flags: string[];
  uuid: string;
}

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppIcon,
    AppTextField,
    AppWrapper,
  },
  name: "BuyGiftCardPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Buy Gift Card Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Shop",
        property: "ShopData",
        subProperty: "gift_cards",
        method: "GetProductsByCategory",
        params: ["gift_cards"],
        requireAuth: false,
        silentUpdate: true,
      },
      {
        domain: "Shop",
        property: "MerchantCategories",
        method: "GetMerchantByCategories",
        params: [],
        requireAuth: false,
        silentUpdate: true,
      },
    ],
  },
  setup() {
    useMeta({
      title: "Buy Gift Card",
    });

    const giftCardCategories = ref(
      Logic.Shop.MerchantCategories?.filter(
        (item) => item.slug == "gift_cards"
      )[0].sub_categories.filter((item) => item.name != "All")
    );

    const searchQuery = ref("");

    const selectedCategory = ref(
      giftCardCategories.value ? giftCardCategories.value[0].name : ""
    );

    const selectedProductSlug = ref("");
    const billersOptons = reactive<MerchantGiftCard[]>([]);

    const setBillers = async () => {
      const billerDeals = processProductData(
        Logic.Shop.ShopData.gift_cards || []
      );
      const billerDealsData = billerDeals
        .map((item) => {
          const metaData = JSON.parse(item.data.metadata || "");
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          item.data.category = metaData.package.category;
          return item.data;
        })
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .filter((item) => item.category == selectedCategory.value);

      const groupedData = Logic.Common.groupArrayBy(
        billerDealsData,
        "merchant_name"
      );

      billersOptons.length = 0;

      for (const key in groupedData) {
        if (Object.prototype.hasOwnProperty.call(groupedData, key)) {
          const element = groupedData[key];

          const allItems = element.map((item: any) => {
            const metaData = JSON.parse(item.metadata);
            item.country = metaData.country.image;
            item.currency = metaData.country.currency;
            return item;
          });

          const groupedDataCountry = Logic.Common.groupArrayBy(
            allItems,
            "country"
          );
          const groupedDataCurrency = Logic.Common.groupArrayBy(
            allItems,
            "currency"
          );
          const baseColor = await Logic.Common.getMostFrequentColorFromURL(
            element[0].image_url
          );

          billersOptons.push({
            uuid: element[0].uuid,
            merchant_name: element[0].merchant_name,
            base_image: element[0].image_url,
            currencies: Object.keys(groupedDataCurrency),
            flags: Object.keys(groupedDataCountry),
            base_color: baseColor,
          });
        }
      }
    };

    const setBillerData = async () => {
      await setBillers();
    };

    const setTarget = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        selectedCategory.value =
          currentPathQuery.target?.toString() || "Fashion";

        const itemId = `giftcard${selectedCategory.value
          .toLowerCase()
          .replaceAll(" ", "_")}`;

        setTimeout(() => {
          scrollToSpecificItem("giftCardMainContainer", itemId);
        }, 300);
      }
    };

    watch(selectedCategory, () => {
      setBillerData();
    });

    onIonViewWillEnter(() => {
      setTarget();
    });

    onMounted(() => {
      setBillerData();
      if (Logic.Common.currentBuildType() == "web") {
        setTarget();
      }
    });

    return {
      billersOptons,
      FormValidations,
      selectedProductSlug,
      Logic,
      loaderSetup,
      giftCardCategories,
      selectedCategory,
      searchQuery,
      getProductPoint,
    };
  },
});
</script>
