import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(`w-full grid ${
      _ctx.data.length == 2 ? 'grid-cols-2' : 'grid-cols-3'
    }  px-1 py-1 bg-primary-50 rounded-[8px] dark:!bg-gray-700 sticky top-0 z-40 ${_ctx.customClass}`)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(`flex flex-row items-center justify-center py-2 space-x-1 col-span-1 cursor-pointer ${
        item.id == _ctx.selectedItem
          ? 'bg-white dark:bg-gray-900 rounded-[6px] customShadow '
          : ''
      }`),
            onClick: ($event: any) => (_ctx.selectedItem = item.id)
          }, [
            _createVNode(_component_app_normal_text, {
              class: _normalizeClass(` !font-semibold !text-xs ${
          item.id == _ctx.selectedItem
            ? '!text-black dark:!text-white'
            : '!text-grey-700 dark:!text-gray-400'
        }`)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["class"]),
            (item.unread)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass(`w-[16px] h-[16px] rounded-full  ${
          item.id == _ctx.selectedItem ? _ctx.badgeColors.active : _ctx.badgeColors.inactive
        } flex flex-row items-center justify-center`)
                }, [
                  _createVNode(_component_app_normal_text, {
                    color: 'text-white',
                    customClass: '!text-[10px]'
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.unread), 1)
                    ]),
                    _: 2
                  }, 1024)
                ], 2))
              : _createCommentVNode("", true)
          ], 10, _hoisted_1))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}