<template>
  <!-- Shopping Cart -->
  <span
    class="absolute right-0 bottom-[100%] flex flex-row py-2 px-2 z-[999999999999] md:!hidden lg:!hidden mdlg:!hidden"
    @click="goToCart"
  >
    <span
      class="h-[45px] w-[45px] rounded-full bg-primary-main flex flex-row items-center justify-center"
    >
      <app-icon :name="'shopping-cart-white'" :customClass="'h-[20px]'" />
    </span>
    <!-- Total Items -->
    <app-normal-text
      v-if="cartCount > 0"
      class="!text-white h-[16px] w-[16px] bg-red-500 rounded-full flex flex-row items-center justify-center !text-[10px] font-semibold absolute right-[10%] top-[5%] z-40"
    >
      {{ cartCount > 9 ? "9+" : cartCount }}
    </app-normal-text>
  </span>
</template>
<script lang="ts">
import { Logic } from "@shpt/logic";
import { defineComponent, onMounted, ref } from "vue";
import AppIcon from "../AppIcon/index.vue";
import { AppNormalText } from "../AppTypography";
import { watch } from "vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
  },
  setup() {
    const cartCount = ref(0);

    const UserProductCart = ref(Logic.Shoplist.UserProductCart);

    watch(UserProductCart, () => {
      cartCount.value =
        (UserProductCart.value?.products?.length || 0) +
        (UserProductCart.value?.collections?.length || 0);
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("UserProductCart", UserProductCart);
      cartCount.value =
        (UserProductCart.value?.products?.length || 0) +
        (UserProductCart.value?.collections?.length || 0);
    });

    const goToCart = () => {
      Logic.Shoplist.SingleShoplistCollection = Logic.Shoplist.UserProductCart;
      Logic.Common.GoToRoute("/shoplist/cart");
    };

    return {
      cartCount,
      goToCart,
    };
  },
});
</script>
