<template>
  <app-wrapper>
    <subpage-layout :title="'Send Money'">
      <div class="w-full flex flex-col space-y-4 px-4">
        <!-- User handle -->
        <app-text-field
          :placeholder="`Enter user’s handle`"
          :hasTitle="true"
          v-model="searchQuery"
          :update-value="searchQuery"
          @keyPressed="searchUsers"
        >
          <template v-slot:title> User Handle </template>

          <template v-slot:inner-prefix>
            <app-icon :name="'at'" :customClass="'h-[18px]'" />
          </template>

          <template v-slot:inner-suffix>
            <app-loading
              class="!text-primary-400 dark:!text-white"
              v-if="searchQuery.length > 1 && isResolvingHandle"
            />
            <app-icon
              v-if="
                !(searchQuery.length > 1 && isResolvingHandle) &&
                Logic.Common.currentBuildType() == 'mobile'
              "
              :name="'phonebook'"
              :customClass="'h-[18px]'"
              @click="searchWithPhone()"
            />
          </template>
        </app-text-field>

        <template v-if="searchResult.length && searchQuery.length > 1">
          <div class="w-full flex flex-col space-y-2">
            <template v-if="searchResult.length">
              <app-beneficiary
                v-for="(item, index) in searchResult"
                :key="index"
                :item="{ ...item, user_uuid: item.uuid }"
                class="cursor-pointer"
                @click="
                  Logic.Common.GoToRoute(
                    `/pay/initiate?account_number=${item.id}&account_name=${item.name}&photo_url=${item.photo_url}&user_uuid=${item.uuid}`
                  )
                "
              />
            </template>
            <div
              v-else
              class="w-full flex flex-col h-[300px] items-center justify-center"
            >
              <app-empty-state
                :title="'Nothing here yet'"
                :subTitle="`No user found`"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <!-- Beneficiaries -->
          <div class="w-full flex flex-col space-y-3">
            <div class="w-full flex flex-row items-center justify-between">
              <app-normal-text class="!font-semibold !text-sm text-left">
                Beneficiaries
              </app-normal-text>

              <app-normal-text class="!text-[#9D4EDD] underline cursor-pointer">
                View all
              </app-normal-text>
            </div>

            <div class="w-full flex flex-col space-y-2">
              <template v-if="beneficiaries.length">
                <app-beneficiary
                  v-for="(item, index) in beneficiaries"
                  :key="index"
                  :item="item"
                  class="cursor-pointer"
                  @click="
                    Logic.Common.GoToRoute(
                      `/pay/initiate?account_number=${item.id}&account_name=${item.name}&photo_url=${item.photo_url}&user_uuid=${item.id}`
                    )
                  "
                />
              </template>
              <div
                v-else
                class="w-full flex flex-col h-[300px] items-center justify-center"
              >
                <app-empty-state
                  :title="'Nothing here yet'"
                  :subTitle="'There are no saved beneficiaries yet'"
                />
              </div>
            </div>
          </div>
        </template>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { pickAContact } from "@shpt/ui-components/src/composable/common";
import {
  AppTextField,
  AppIcon,
  AppNormalText,
  AppEmptyState,
  AppLoading,
  AppBeneficiary,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppIcon,
    AppNormalText,
    AppEmptyState,
    AppLoading,
    AppBeneficiary,
    AppWrapper,
  },
  name: "PayUserPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "ManyPaymentBeneficiary",
        method: "GetPaymentBeneficairies",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        ignoreProperty: true,
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Send Money To Shoppoint User Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Send Money",
    });

    const isResolvingHandle = ref(false);

    const searchQuery = ref("");

    const SearchResult = ref(Logic.User.SearchResult);

    const ManyPaymentBeneficiary = ref(Logic.Wallet.ManyPaymentBeneficiary);

    const beneficiaries = reactive<
      {
        name: string;
        id: string;
        photo_url: string;
        user_uuid: string;
        username: string;
      }[]
    >([]);

    const searchResult = reactive<
      {
        name: string;
        id: string;
        uuid: string;
        photo_url: string;
        username: string;
      }[]
    >([]);

    const setSearchResults = () => {
      searchResult.length = 0;
      SearchResult.value?.forEach((user) => {
        if (user.uuid != Logic.Auth.AuthUser?.uuid) {
          searchResult.push({
            id: user.username || "",
            name: user.full_name || "",
            photo_url: user.profile?.photo_url || "",
            uuid: user.uuid,
            username: user.username || "",
          });
        }
      });
    };

    const setBeneficiaries = () => {
      beneficiaries.length = 0;

      ManyPaymentBeneficiary.value?.forEach((beneficiary) => {
        if (beneficiary.type == "shpt") {
          beneficiaries.push({
            id: `@${beneficiary.shpt_user?.username}`,
            name: `${beneficiary.shpt_user?.full_name}`,
            photo_url: beneficiary.shpt_user?.profile?.photo_url || "",
            user_uuid: beneficiary.uuid,
            username: beneficiary.shpt_user?.username || "",
          });
        }
      });
    };

    const searchUsers = () => {
      if (searchQuery.value.length > 1) {
        Logic.Common.debounce(() => {
          isResolvingHandle.value = true;
          Logic.User.SearchUser(searchQuery.value)
            .then(() => {
              isResolvingHandle.value = false;
            })
            .catch(() => {
              isResolvingHandle.value = false;
            });
        }, 300);
      }
    };

    const searchWithPhone = async () => {
      const phoneNumber = await pickAContact();
      searchQuery.value = phoneNumber || "";
      searchUsers();
    };

    watch(SearchResult, () => {
      setSearchResults();
    });

    const setToDefault = () => {
      searchResult.length = 0;
      searchQuery.value = "";
    };

    onIonViewWillEnter(() => {
      setBeneficiaries();
      setToDefault();
      Logic.Game.CheckGameStatus();
    });

    watch(ManyPaymentBeneficiary, () => {
      setBeneficiaries();
    });

    onMounted(() => {
      Logic.User.watchProperty("SearchResult", SearchResult);
      Logic.Wallet.watchProperty(
        "ManyPaymentBeneficiary",
        ManyPaymentBeneficiary
      );
      setBeneficiaries();
      setToDefault();

      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
      }
    });

    return {
      beneficiaries,
      searchQuery,
      isResolvingHandle,
      searchResult,
      Logic,
      searchWithPhone,
      searchUsers,
    };
  },
});
</script>
