<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3"
  >
    <!-- From date -->

    <app-text-field
      :placeholder="'Choose date'"
      :hasTitle="true"
      :padding="'py-4 px-4'"
      :rules="[FormValidations.RequiredRule]"
      v-model="formData.from_date"
      name="Start date"
      ref="from_date"
      type="date"
    >
      <template v-slot:title> Start Date </template>
      <template v-slot:inner-prefix>
        <app-icon :name="'calendar'" :customClass="'h-[17px]'" />
      </template>
    </app-text-field>

    <!-- To date -->

    <app-text-field
      :placeholder="'Choose date'"
      :hasTitle="true"
      :padding="'py-4 px-4'"
      :rules="[
        FormValidations.RequiredRule,
        FormValidations.customValidator(
          Logic.Common.momentInstance(formData.from_date).isBefore(
            Logic.Common.momentInstance(formData.to_date)
          ),
          `Date must be greater that the Start Date`
        ),
      ]"
      v-model="formData.to_date"
      name="End date"
      ref="to_date"
      type="date"
      :prevent-back-date="true"
      :miminum-date="from_date_ref"
    >
      <template v-slot:title> End Date </template>
      <template v-slot:inner-prefix>
        <app-icon :name="'calendar'" :customClass="'h-[17px]'" />
      </template>
    </app-text-field>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { Logic } from "../../composable";
import { AppFormWrapper, AppTextField } from "../AppForm";
import AppIcon from "../AppIcon";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
    AppIcon,
  },
  name: "AppModalFilterExpenses",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      from_date: "",
      to_date: "",
    });

    const from_date_ref = ref("");

    const from_date = ref();
    const to_date = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(from_date.value, to_date.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        return formData;
      } else {
        return false;
      }
    };

    watch(formData, () => {
      from_date_ref.value = formData.from_date;
    });
    return {
      FormValidations,
      formComp,
      parentRefs,
      from_date,
      formData,
      to_date,
      validateForm,
      Logic,
      from_date_ref,
    };
  },
});
</script>
