<template>
  <app-wrapper>
    <app-verify-phone-page
      :title="''"
      :hasCustomSubTitle="true"
      :hasBottomBorder="false"
    >
      <template v-slot:top-section>
        <div class="w-full flex flex-col space-y-[1px]">
          <div class="w-full relative h-[8px] bg-gray-100 rounded-full">
            <div
              class="absolute top-0 left-0 w-[40%] h-[8px] bg-primary-500 rounded-full"
            ></div>
          </div>
          <div class="w-full flex flex-row justify-end">
            <app-normal-text class="!text-grey-500 dark:!text-grey-400">
              40%
            </app-normal-text>
          </div>
        </div>
      </template>
      <template v-slot:sub-title>
        <app-header-text> Verify Your Phone Number </app-header-text>
      </template>
    </app-verify-phone-page>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import {
  AppVerifyPhonePage,
  AppHeaderText,
  AppNormalText,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppVerifyPhonePage,
    AppHeaderText,
    AppNormalText,
    AppWrapper,
  },
  name: "VerifyPhonePage",
  layout: "Auth",
  middlewares: {
    tracking_data: {
      lable: "Verify Phone Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Verify Your Phone Number",
    });

    return {};
  },
});
</script>
