<template>
  <div class="w-full flex flex-col" :id="`lineChart${tabIndex}`"></div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, toRef, watch } from "vue";
import ApexCharts from "apexcharts";
import { Logic } from "../../composable";

export default defineComponent({
  name: "AppExpensesLineChart",
  props: {
    data: {
      type: Array as () => {
        x: string;
        y: number;
        label: string;
      }[],
      default: [
        { x: "Sun", y: 800, label: "Sunday" },
        { x: "Mon", y: 600, label: "Monday" },
        { x: "Tue", y: 700, label: "Tuesday" },
        { x: "Wed", y: 800, label: "Wednesday" },
        { x: "Thu", y: 1283, label: "Thursday" },
        { x: "Fri", y: 900, label: "Friday" },
        { x: "Sat", y: 1000, label: "Saturday" },
      ],
    },
  },
  setup(props) {
    const tabIndex = Math.floor(Math.random() * 100000);
    // Sample data
    const chartData = toRef(props, "data");

    const chartComponent = ref();

    const setUpChart = () => {
      // Configuration for ApexCharts
      const options = {
        chart: {
          type: "area",
          height: 230,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: [Logic.Common.currentAppTheme() == "light" ? "#9DD7B7" : "#9DD7B7"],
        stroke: {
          curve: "smooth",
          width: 2,
        },
        series: [
          {
            name: "Expenses",
            data: chartData.value.map((item) => item.y),
          },
        ],
        xaxis: {
          categories: chartData.value.map((item) => item.x),
          labels: {
            style: {
              fontSize: "11px",
              fontFamily: "Athletics",
              color: Logic.Common.currentAppTheme() == "light" ? "#848A9C" : "#3B3F4A",
            },
          },
          crosshairs: {
            width: 1.2,
            stroke: {
              color: Logic.Common.currentAppTheme() == "light" ? "#80D89B" : "#2A6F53",
              width: 1.2,
              dashArray: 0,
            },
            fill: {
              type: "solid",
              color: Logic.Common.currentAppTheme() == "light" ? "#80D89B" : "#2A6F53",
            },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false, // Hide y-axis labels
          axisTicks: {
            show: true,
            borderType: "dotted",
            color: Logic.Common.currentAppTheme() == "light" ? "#E1E3E8" : "#1C1E22",
          },
          // min: 100, // Start y-axis from 0
        },
        tooltip: {
          // @ts-ignore
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            return `<div class="min-w-[110px] max-w-[190px] py-1 px-2 h-auto bg-white dark:bg-black shadow-custom rounded-[7.45px] flex flex-col space-y-[1px] items-center justify-center font-inter text-[11px]">
               <span class="uppercase text-[#717171] dark:text-[#A0A0A0]">${
                 chartData.value[dataPointIndex].label
               }</span>
               <span class="font-extrabold text-[#243465] dark:text-[#9DD7B7] text-[12px]">${Logic.Common.convertToMoney(
                 series[seriesIndex][dataPointIndex],
                 true,
                 "ngn"
               )}</span>
              </div>`;
          },
        },
      };

      if (chartComponent.value != undefined) {
        chartComponent.value.destroy();
      }

      chartComponent.value = new ApexCharts(
        document.querySelector(`#lineChart${tabIndex}`),
        options
      );

      chartComponent.value.render();
    };

    watch(chartData, () => {
      setUpChart();
    });

    onMounted(() => {
      setTimeout(() => {
        // Render the chart
        setUpChart();
      }, 500);
    });

    return {
      tabIndex,
    };
  },
});
</script>
