<template>
  <div
    id="footer"
    class="fixed bottom-0 w-full border-t-[1px] border-primary-100 pt-4 bg-white lg:text-sm mdlg:text-[12px] text-xs"
    style="
      padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
      font-family: BricolageGrotesque;
    "
  >
    <div class="flex flex-row space-x-2 items-center justify-between px-4">
      <div
        v-for="(v, i) in tabs"
        :key="i"
        @click="v.action ? v.action() : Logic.Common.GoToRoute(v.path)"
        class="flex flex-col spacey-y-[2px] items-center"
      >
        <div class="flex flex-col items-center justify-center space-y-2">
          <AppIcon
            :custom-class="'w-5 h-5'"
            :name="tabIsActive(v.routeTag) ? `${v.icon}-active` : v.icon"
          ></AppIcon>
          <AppNormalText
            :color="`${
              tabIsActive(v.routeTag) ? 'text-primary-500' : 'text-grey-main'
            }`"
            :class="'!text-[11px]'"
          >
            {{ v.name }}
          </AppNormalText>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import { Logic } from "../../composable";
import { Prop, PropType } from "vue";

export default {
  components: {
    AppNormalText,
    AppIcon,
  },
  props: {
    tabIsActive: {
      type: Function,
    },
    tabs: {
      type: Object,
      required: true,
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {
      Logic,
    };
  },
  name: "AppDashboardFooter",
};
</script>
