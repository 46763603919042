import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-center" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2 items-center justify-center pt-2" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-3 pt-2" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-3 pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeaderText = _resolveComponent("AppHeaderText")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${$props.customClass} flex flex-col h-full place-content-center space-y-4 px-6 w-full`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _renderSlot(_ctx.$slots, "reveal-logo")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppHeaderText, { customClass: 'text-center' }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "center-text")
        ]),
        _: 3
      }),
      _createVNode(_component_AppNormalText, { customClass: 'text-center' }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "sub-center-text")
        ]),
        _: 3
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_AppButton, {
          padding: 'py-3',
          "bg-color": 'bg-primary-500',
          "text-color": 'text-white',
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($props.triggerButton()))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "button-text"),
            _createTextVNode(" " + _toDisplayString($props.buttonText), 1)
          ]),
          _: 3
        }),
        ($props.secondaryButtonText)
          ? (_openBlock(), _createBlock(_component_AppButton, {
              key: 0,
              padding: 'py-3',
              "bg-color": 'bg-primary-50',
              "text-color": 'text-primary-500',
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($props.secondaryButtonTrigger()))
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "secondary-button-text"),
                _createTextVNode(" " + _toDisplayString($props.secondaryButtonText), 1)
              ]),
              _: 3
            }))
          : _createCommentVNode("", true)
      ]),
      _renderSlot(_ctx.$slots, "suffix"),
      _createElementVNode("div", {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('last-text-event'))),
        class: "text-center"
      }, [
        _createVNode(_component_AppNormalText, { customClass: 'justify-center underline text-primary-500 cursor-pointer' }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "last-text")
          ]),
          _: 3
        })
      ])
    ])
  ], 2))
}