import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 relative" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-4 h-[200px] justify-center items-center" }
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-8 w-8 text-primary-500`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, { class: "w-full text-left !text-gray-600" }, {
      default: _withCtx(() => [
        _createTextVNode(" A verification link has been sent to your email (" + _toDisplayString(_ctx.localStorage.getItem("acc_email")) + ") ", 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, [
        (_openBlock(), _createElementBlock("svg", _hoisted_3, _cache[0] || (_cache[0] = [
          _createElementVNode("circle", {
            class: "opacity-25",
            cx: "12",
            cy: "12",
            r: "10",
            stroke: "currentColor",
            "stroke-width": "4"
          }, null, -1),
          _createElementVNode("path", {
            class: "opacity-75",
            fill: "currentColor",
            d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          }, null, -1)
        ])))
      ]),
      _createVNode(_component_app_normal_text, { class: "text-center" }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Waiting for you to verify your email... ")
        ])),
        _: 1
      })
    ])
  ]))
}