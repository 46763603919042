<template>
  <div
    :class="`w-full flex flex-row items-center space-x-3 px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[10px] justify-between ${customClass}`"
    v-if="data"
  >
    <div class="flex flex-row items-center space-x-3">
      <div
        v-if="data.icon"
        class="bg-white rounded-full h-[40px] w-[40px] flex flex-row items-center justify-center dark:bg-slate-300"
      >
        <app-icon :name="data.icon" :customClass="data.icon_size" />
      </div>

      <app-normal-text :customClass="' !font-semibold'">
        {{ data.title }}
      </app-normal-text>
    </div>

    <app-icon
      v-if="hasMasterLink"
      :name="'chevron-right-grey'"
      :customClass="'h-[13px] pr-2'"
    />
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from "vue";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";

export default defineComponent({
  components: {
    AppImageLoader,
    AppNormalText,
    AppIcon,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    data: {
      type: Object as () => {
        title: string;
        icon?: string;
        icon_size?: string;
      },
    },
    hasMasterLink: {
      type: Boolean,
      default: true,
    },
  },
  name: "AppNavCard",
  setup() {
    return {};
  },
});
</script>
