import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 border-[1px] border-[#E9E6ED] rounded-[18px] px-3 py-3 md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "h-[250px] w-full bg-[#F7F7F7] rounded-[12px] items-center justify-center flex flex-col" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", { src: _ctx.codeUrl }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_app_button, {
        customClass: 'w-full flex flex-row !items-center !space-x-2',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareQrCode()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_app_icon, {
            name: 'share-white',
            customClass: 'h-[16px]'
          }),
          _createVNode(_component_app_normal_text, {
            color: 'text-white',
            customClass: '!font-bold'
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode(" Share code ")
            ])),
            _: 1
          })
        ]),
        _: 1
      })
    ])
  ]))
}