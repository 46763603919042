<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Old password field -->
    <app-text-field
      :placeholder="'Enter old password'"
      :hasTitle="true"
      :type="'password'"
      :rules="[FormValidations.RequiredRule]"
      :paddings="'py-3 px-4'"
      v-model="formData.old_password"
      name="Old password"
      ref="old_password"
    >
      <template v-slot:title> Old password </template>
    </app-text-field>

    <app-text-field
      :placeholder="'Enter new password'"
      :hasTitle="true"
      :type="'password'"
      :rules="[FormValidations.RequiredRule]"
      :paddings="'py-3 px-4'"
      v-model="formData.new_password"
      name="New password"
      ref="new_password"
    >
      <template v-slot:title> New password </template>
    </app-text-field>

    <app-text-field
      :placeholder="'Confirm new password'"
      :hasTitle="true"
      :type="'password'"
      :rules="[
        FormValidations.RequiredRule,
        FormValidations.handleConfirmPassword(
          formData.new_password,
          formData.confirm_new_password
        ),
      ]"
      :paddings="'py-2 px-4'"
      v-model="formData.confirm_new_password"
      name="Confirm new password"
      ref="confirm_new_password"
    >
      <template v-slot:title> Confirm new password </template>
    </app-text-field>

    <div class="w-full flex flex-col">
      <app-button class="w-full py-3" @click.prevent="validateForm">
        Update Password
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AppTextField } from "../AppForm";
import { Logic } from "../../composable";
import { AppFormWrapper } from "../AppForm";
import AppButton from "../AppButton";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {
        //
      },
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
    AppButton,
  },
  name: "AppModalChangePassword",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });

    const old_password = ref();
    const new_password = ref();
    const confirm_new_password = ref();
    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(
        old_password.value,
        new_password.value,
        confirm_new_password.value
      );
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      old_password,
      new_password,
      confirm_new_password,
      formData,
      validateForm,
    };
  },
});
</script>
