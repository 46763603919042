<template>
  <div
    class="w-full flex flex-row space-x-2 py-3 px-2 border-b-[1px] items-center border-[#E8E8E8]"
  >
    <div class="w-[40px]">
      <app-image-loader
        :photoUrl="
          item.photo_url ? item.photo_url : '/images/icons/user-grey-bg.svg'
        "
        class="w-[40px] h-[40px] rounded-full"
      />
    </div>
    <div class="flex flex-col space-y-[2px]">
      <app-normal-text class="!font-semibold text-left">
        {{ item.name }}
      </app-normal-text>
      <app-normal-text class="!text-[12px] !text-grey-500 text-left">
        @{{ item.username }}
      </app-normal-text>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText } from "../AppTypography";

type Beneficiary = {
  name: string;
  id: string;
  photo_url: string;
  user_uuid: string;
  username: string;
};

export default defineComponent({
  components: {
    AppImageLoader,
    AppNormalText,
  },
  props: {
    item: {
      type: Object as () => Beneficiary,
      required: true,
    },
  },
  name: "AppBeneficiary",
});
</script>
