import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-[1px]" }
const _hoisted_2 = { class: "w-full flex flex-row justify-end" }
const _hoisted_3 = { class: "w-full flex flex-col pt-4 space-y-4" }
const _hoisted_4 = { class: "w-full flex flex-col" }
const _hoisted_5 = { class: "w-full grid grid-cols-12 gap-3" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  class: "w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black",
  style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_auth_layout = _resolveComponent("auth-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, {
    class: _normalizeClass('!overflow-y-hidden')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_auth_layout, {
        onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/auth/login'))),
        onGoBack: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.goBack())),
        "go-back": true,
        "right-button": false,
        hasBottomBorder: false
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "w-full relative h-[8px] bg-gray-100 rounded-full" }, [
              _createElementVNode("div", { class: "absolute top-0 left-0 w-[80%] h-[8px] bg-primary-500 rounded-full" })
            ], -1)),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_app_normal_text, { class: "!text-grey-500 dark:!text-grey-400" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" 80% ")
                ])),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_header_text, { class: "!text-left pb-4" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode(" How much of your monthly spend would you like to save in cashback? ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mothtlySavingsOptions, (item) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(`w-full col-span-4 flex flex-col items-center cursor-pointer justify-center space-y-2 border-[1px] h-[50px] border-gray-300 rounded-[5px] px-2 py-2 ${
                _ctx.selectedSavingsGroup === item.key
                  ? 'border-primary-500 !border-[1.5px]'
                  : ''
              }`),
                    onClick: ($event: any) => (_ctx.selectedSavingsGroup = item.key),
                    key: item.key
                  }, [
                    _createVNode(_component_app_header_text, { class: "!text-[13.5px] !text-center !font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_6))
                }), 128))
              ]),
              _createVNode(_component_app_normal_text, { class: "!text-left !text-gray-500 pb-2 pt-3" }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Tips: Most people save 10% of their monthly spend. ")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AppButton, {
                padding: 'py-3',
                "custom-class": `rounded-[40px] ${
              _ctx.selectedSavingsGroup ? 'opacity-100' : 'opacity-50'
            }`,
                "bg-color": 'bg-primary-main',
                "text-color": 'text-white',
                onClick: _ctx.continueButtonClicked
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Continue ")
                ])),
                _: 1
              }, 8, ["custom-class", "onClick"])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}