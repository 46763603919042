<template>
  <div
    v-if="item"
    :class="`w-full flex flex-col  space-y-3 bg-white dark:bg-black rounded-[8px] border-[1px] border-grey-50 cursor-pointer`"
  >
    <div class="w-full flex flex-row items-center justify-between px-3 pt-3">
      <div class="flex flex-row space-x-2 items-start">
        <div class="w-[40px]">
          <div
            v-if="!item.is_image"
            class="h-[40px] w-[40px] rounded-full bg-primary-50 items-center justify-center flex"
          >
            <app-icon :name="item.icon" :customClass="'h-[20px] xs:h-[18px]'" />
          </div>
          <app-image-loader
            v-else
            :photo-url="item.icon"
            :enforce-thumbnail="item.enforce_thumbnail"
            :customClass="'h-[40px] w-[40px] rounded-full border-[1px] border-grey-100'"
          />
        </div>

        <div class="flex flex-col">
          <app-normal-text
            :color="'text-[#240046]'"
            :customClass="'!font-semibold  capitalize !text-left'"
          >
            {{ item.title }}
          </app-normal-text>
          <div class="flex flex-col space-y-1">
            <app-normal-text class="text-grey-700 !text-left">
              {{ item.subtitle }}
            </app-normal-text>
            <app-normal-text class="text-grey-700 !text-left">
              {{ item.datetime }}
            </app-normal-text>
          </div>
        </div>
      </div>

      <div class="flex flex-col h-[60px] justify-between">
        <app-normal-text
          :color="'text-[#2A2A2A] dark:!text-white'"
          :customClass="'!font-bold text-right !text-sm'"
        >
          {{ item.total_spend }}
        </app-normal-text>

        <div class="flex flex-row">
          <app-badge :color="item.status_color">{{ item.status }}</app-badge>
        </div>
      </div>
    </div>

    <div
      class="w-full flex flex-col px-3 pb-3 border-t-[1px] border-grey-50 pt-3"
    >
      <div class="flex flex-row space-x-1">
        <app-normal-text
          :color="'text-grey-700 dark:!text-gray-400'"
          :customClass="'!whitespace-nowrap '"
        >
          Earnings:
          <span class="font-semibold text-black dark:text-white">
            {{ item.points }}
            Pts ({{ item.amount }})</span
          >
        </app-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";
import AppBadge from "../AppBadge";
import { AppNormalText } from "../AppTypography";

interface RewardData {
  subtitle: string;
  title: string;
  icon: string;
  is_image: boolean;
  enforce_thumbnail: boolean;
  points: string;
  status: string;
  datetime: string;
  status_color: "green" | "red" | "yellow" | "blue";
  uuid: string;
  amount: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: Function;
  total_spend: string;
}

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppImageLoader,
    AppBadge,
  },
  props: {
    item: {
      type: Object as () => RewardData,
    },
  },
  setup() {
    const isVisible = ref(true);

    const close = () => {
      isVisible.value = false;
    };

    onMounted(() => {
      isVisible.value = true;
      setTimeout(() => {
        isVisible.value = false;
      }, 5000);
    });

    return {
      isVisible,
      close,
    };
  },
});
</script>
