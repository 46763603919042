<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-1"
  >
    <!-- Select quantity -->
    <app-select
      :placeholder="'Select quantity'"
      :hasTitle="true"
      v-model="formData.quantity"
      :paddings="'px-4 py-3'"
      ref="quantity"
      :customClass="'dark:!bg-grey-800'"
      :options="quantityOptons()"
      :updateKey="formData.quantity"
      :theme="Logic.Common.currentAppTheme()"
    >
      <template v-slot:title> Quantity </template>
    </app-select>

    <!-- Select reward type -->
    <app-select
      v-if="false"
      :placeholder="'Select quantity'"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      v-model="formData.type"
      :customClass="'dark:!bg-grey-800'"
      ref="type"
      :options="rewardTypeOptions"
      :theme="Logic.Common.currentAppTheme()"
      :updateKey="formData.type"
    >
      <template v-slot:title> Reward type </template>
    </app-select>

    <!-- Percentage -->
    <app-text-field
      :placeholder="'Enter percentage'"
      :hasTitle="true"
      :type="'number'"
      :padding="'py-3 px-4'"
      name="Reward percentage"
      ref="percentage"
      v-model="formData.percentage"
      :rules="[FormValidations.RequiredRule]"
      :updateValue="formData.percentage"
    >
      <template v-slot:title>Reward Percentage </template>
      <template v-slot:inner-suffix>
        <span class="pl-1">
          <app-normal-text :color="'text-grey-700'">%</app-normal-text>
        </span>
      </template>
    </app-text-field>

    <div
      class="w-full grid grid-cols-2 gap-3 pb-4 mdlg:!pb-0 md:!pb-0"
      v-if="data.is_edit"
    >
      <div
        :class="` ${
          data.default?.quantity == '-1' ? 'col-span-2' : 'col-span-1'
        } flex flex-col`"
      >
        <app-button
          :class="`w-full py-3 ${isValid ? '' : 'opacity-50'}`"
          @click.prevent="validateForm(false)"
        >
          Update
        </app-button>
      </div>
      <div class="col-span-1 flex flex-col" v-if="data.default?.quantity != '-1'">
        <app-button
          :class="`w-full py-3 !bg-error-50 !text-error-main`"
          @click.prevent="validateForm(true)"
        >
          Remove
        </app-button>
      </div>
    </div>
    <div v-else class="w-full flex flex-col pb-4 mdlg:!pb-0 md:!pb-0">
      <app-button
        :class="`w-full py-3 ${isValid ? '' : 'opacity-50'}`"
        @click.prevent="validateForm(false)"
      >
        Add
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppSelect, AppTextField } from "../AppForm";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
    AppSelect,
    AppButton,
    AppNormalText,
  },
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object as () => {
        existing_quantities: string[];
        is_edit: boolean;
        default?: {
          quantity: string;
          type: string;
          percentage: string;
        };
      },
    },
  },
  name: "AppModalProductRewardSetting",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      quantity: "",
      type: "cashback",
      percentage: "",
    });

    const quantity = ref();
    const type = ref();
    const percentage = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const quantityOptons = () => {
      let baseItems = reactive([
        {
          key: "-1",
          value: "Any",
        },
        {
          key: "1",
          value: "1",
        },
        {
          key: "2",
          value: "2",
        },
        {
          key: "3",
          value: "3",
        },
        {
          key: "4",
          value: "4",
        },
        {
          key: "5",
          value: "5",
        },
        {
          key: "6",
          value: "6",
        },
        {
          key: "7",
          value: "7",
        },
        {
          key: "8",
          value: "8",
        },
        {
          key: "9",
          value: "9",
        },
        {
          key: "10",
          value: "10",
        },
      ]);

      if (!props.data?.is_edit) {
        baseItems = baseItems.filter(
          (item) => !props.data?.existing_quantities.includes(item.key)
        );
      } else {
        baseItems = baseItems.filter(
          (item) =>
            !props.data?.existing_quantities.includes(item.key) ||
            item.key == props.data?.default?.quantity
        );
      }

      return baseItems;
    };

    const rewardTypeOptions = reactive([
      {
        key: "cashback",
        value: "Cashback",
      },
      {
        key: "discount",
        value: "Discount",
      },
    ]);

    const isValid = computed(() => {
      return formData.percentage != "" && formData.quantity != "" && formData.type != "";
    });

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(quantity.value, type.value, percentage.value);

      //   Set defaults

      if (props.data?.default) {
        const data = props.data.default;
        formData.percentage = data.percentage;
        formData.quantity = data.quantity;
        formData.type = data.type;
      }
    });

    const validateForm = (isDelete = false) => {
      const status = formComp.value.validate();

      if (status && isValid.value) {
        // @ts-ignore
        formData.quantity = parseFloat(formData.quantity);
        props.action(formData, isDelete);
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      quantity,
      type,
      percentage,
      formData,
      rewardTypeOptions,
      isValid,
      Logic,
      validateForm,
      quantityOptons,
    };
  },
});
</script>
