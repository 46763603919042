import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col space-y-2" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center justify-between w-full" }
const _hoisted_4 = { class: "flex flex-row space-x-2 items-center justify-start" }
const _hoisted_5 = { class: "h-full flex items-start justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        onClick: _withModifiers(($event: any) => ($setup.selectedOption = option.key), ["stop"]),
        class: "flex w-full flex-row items-center justify-between cursor-pointer py-3 border-b-[1px] border-gray-300 dark:border-gray-600"
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createElementVNode("span", {
                class: _normalizeClass(`h-[11px] w-[11px] rounded-full ${
                $setup.selectedOption == option.key ? '!bg-secondary-500' : 'bg-[#D9D9D9]'
              }`)
              }, null, 2)
            ]),
            (option.imageUrl)
              ? (_openBlock(), _createBlock(_component_app_image_loader, {
                  key: 0,
                  "photo-url": option.imageUrl,
                  customClass: `h-[26px] w-[26px] rounded-full `
                }, null, 8, ["photo-url"]))
              : _createCommentVNode("", true),
            _createVNode(_component_app_normal_text, { customClass: '!font-semibold dark:!text-white' }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option.alt_value ? option.alt_value : option.value), 1)
              ]),
              _: 2
            }, 1024)
          ])
        ])
      ], 8, _hoisted_2))
    }), 128))
  ]))
}