import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-1" }
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full flex flex-col space-y-2  bottom-0 left-0 items-center justify-center pt-4  ${
      _ctx.isRelative ? 'relative' : 'fixed'
    } `)
  }, [
    _createVNode(_component_app_normal_text, { class: "!text-[#240046] dark:!text-primary-200" }, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" Or continue with ")
      ])),
      _: 1
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_app_button, {
        bgColor: '!bg-grey-50 dark:!bg-grey-800',
        "text-color": '!text-black dark:!text-white',
        padding: 'py-3',
        onClick: _ctx.authenticateGoogle
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, [
            _createVNode(_component_app_icon, {
              name: "google-logo",
              customClass: '!h-[20px]'
            }),
            _createVNode(_component_app_normal_text, { class: "!text-black pt-[1px] dark:!text-white" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.fromAction == "signUp"
                ? "Sign up with Google"
                : "Sign in with Google"), 1)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["onClick"]),
      (_ctx.canUseApp)
        ? (_openBlock(), _createBlock(_component_app_button, {
            key: 0,
            bgColor: '!bg-grey-50 dark:!bg-grey-800',
            "text-color": '!text-black dark:!text-white',
            padding: 'py-3',
            onClick: _ctx.authenticateApple
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_app_icon, {
                  name: "apple-logo",
                  customClass: '!h-[20px]'
                }),
                _createVNode(_component_app_normal_text, { class: "!text-black pt-[2px] dark:!text-white" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.fromAction == "signUp"
                ? "Sign up with Apple"
                : "Sign in with Apple"), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}