import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full flex flex-col px-4" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "w-full flex flex-row justify-between" }
const _hoisted_4 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_5 = { class: "!w-[48px]" }
const _hoisted_6 = { class: "w-[48px]" }
const _hoisted_7 = { class: "flex flex-col space-y-1" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = {
  key: 0,
  class: /*@__PURE__*/_normalizeClass(`px-4 py-1 !bg-white !bg-opacity-50 rounded-[30px] flex items-center justify-center`)
}
const _hoisted_10 = { class: "w-full flex flex-row items-center justify-end" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "w-full h-full overflow-y-auto scrollbar-hide" }
const _hoisted_13 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Membership' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plans, (plan, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: _normalizeClass(`w-full rounded-t-[20px] px-3 py-3 flex flex-col cursor-pointer ${
            plan.bg_color
          } ${index == _ctx.plans.length - 1 ? 'pb-[15px]' : 'pb-[30px]'} mb-[-20px] ${
            _ctx.currentSelectedPlan == index ? 'flex-grow h-full' : ''
          }`),
                onClick: ($event: any) => (_ctx.currentSelectedPlan = index)
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(_component_app_icon, {
                          name: plan.card_bagde_image,
                          customClass: 'h-[44px] '
                        }, null, 8, ["name"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`!font-semibold !text-sm ${plan.text_color} text-left`)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(plan.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"]),
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`!text-[11px] ${plan.text_color} text-left`)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Up to " + _toDisplayString(plan.snaps_per_day) + " snaps per day and " + _toDisplayString(plan.spend_per_week) + " Weekly spend ", 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    (index == 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(`${plan.text_color} !text-[11px] text-center whitespace-nowrap`)
                          }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode(" Current Tier ")
                            ])),
                            _: 2
                          }, 1032, ["class"])
                        ]))
                      : (_openBlock(), _createBlock(_component_app_normal_text, {
                          key: 1,
                          class: _normalizeClass(`text-right ${plan.text_color} !text-sm`)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(plan.price
                    ? `${_ctx.Logic.Common.convertToMoney(plan.price, false, "ngn")}/Month`
                    : "FREE"), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"]))
                  ])
                ]),
                (_ctx.currentSelectedPlan == index)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", {
                          class: _normalizeClass(`px-4 py-1 bg-white  rounded-[30px] flex items-center justify-center cursor-pointer`),
                          onClick: ($event: any) => (_ctx.showSubscriptionPlan(plan))
                        }, [
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(`!text-black !text-[11px] text-center whitespace-nowrap`)
                          }, {
                            default: _withCtx(() => _cache[1] || (_cache[1] = [
                              _createTextVNode(" Upgrade ")
                            ])),
                            _: 1
                          })
                        ], 8, _hoisted_11)
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("div", {
                          class: _normalizeClass(`w-full !text-[12px] py-2 overflow-y-auto scrollbar-hide !leading-tight ${plan.text_color}`),
                          innerHTML: plan.description
                        }, null, 10, _hoisted_13)
                      ])
                    ], 64))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}