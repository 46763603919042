<template>
  <app-wrapper>
    <subpage-layout>
      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-col space-y-1 justify-start px-4">
          <app-header-text class="!text-base"> Spicy Jollof Rice Recipe </app-header-text>
          <div class="w-full flex flex-row space-x-2 items-center">
            <app-normal-text> iffy’s Kitchen </app-normal-text>
            <app-normal-text class="underline !text-primary-400">
              Follow
            </app-normal-text>
          </div>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-2 px-4">
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-50 py-3"
          >
            <div class="w-full flex flex-row space-x-2">
              <div class="h-[82px]">
                <app-image-loader
                  :photoUrl="item.base_image"
                  class="rounded-[4.4px] h-[82px] w-[82px]"
                />
              </div>
              <div class="flex flex-col items-end w-full justify-between">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.weight }}
                    </app-normal-text>
                    <span class="h-[3px] w-[3px] rounded-full bg-grey-900"> </span>
                    <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-row space-x-2 justify-between items-center">
                  <app-header-text class="!text-sm">
                    {{ Logic.Common.convertToMoney(item.price, true, "ngn") }}
                  </app-header-text>

                  <div class="flex flex-row justify-end items-center space-x-2">
                    <app-normal-text
                      class="px-3 py-[3px] bg-grey-50 rounded-[999px] !text-primary-300"
                    >
                      Buy Item
                    </app-normal-text>
                    <app-icon :name="'cart-circle-purple'" :customClass="'!h-[32px]'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Bottom section -->
      <div
        class="fixed bottom-0 left-0 w-full bg-white pt-4 px-4 flex flex-row space-x-2 justify-between border-t-[1px] border-grey-50"
        style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important"
      >
        <div class="flex flex-col space-y-[3px]">
          <app-header-text class="!text-sm">
            {{ Logic.Common.convertToMoney(240300, true, "ngn") }}
          </app-header-text>
          <app-normal-text class="!text-[#898989]">
            12% Cashback Savings
          </app-normal-text>
        </div>
        <div
          class="py-3 px-8 bg-primary-400 flex justify-center items-center rounded-[999px]"
        >
          <app-normal-text class="!font-semibold !text-white"> Buy All </app-normal-text>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppIcon,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppIcon,
    AppWrapper,
  },
  name: "ShoplistItemsPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Shopplist Items Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Shoplist Info Page",
    });

    const productLists = reactive<
      {
        title: string;
        weight: string;
        main_category: string;
        price: number;
        base_image: string;
      }[]
    >([
      {
        title: "Thai Basmati Rice",
        main_category: "100% Organic Natural",
        base_image: "/images/temp/product-1.png",
        price: 18000,
        weight: "5kg",
      },
      {
        title: "Chilli Pooker Pepper",
        main_category: "Fresh & Dry",
        base_image: "/images/temp/product-2.png",
        price: 8000,
        weight: "30g",
      },
      {
        title: "Fresh Tomatoes",
        main_category: "100% Organic Natural",
        base_image: "/images/temp/product-3.png",
        price: 4000,
        weight: "500g",
      },
      {
        title: "Fresh Cabbage",
        main_category: "100% Organic Natural",
        base_image: "/images/temp/product-4.png",
        price: 4030,
        weight: "500g",
      },
      {
        title: "Tilapia Fish",
        main_category: "Frozen",
        base_image: "/images/temp/product-5.png",
        price: 5500,
        weight: "1kg",
      },
    ]);

    return {
      Logic,
      productLists,
    };
  },
});
</script>
