import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center w-full justify-between rounded-lg py-2.5" }
const _hoisted_2 = { class: "flex items-center space-x-3 w-3/4" }
const _hoisted_3 = { class: "flex flex-col space-y-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex items-center space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppNormalText = _resolveComponent("AppNormalText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", {
          class: _normalizeClass(`w-[40px] h-[40px] rounded-full ${$props.bgColor} grid place-content-center`)
        }, [
          _createVNode(_component_AppIcon, {
            "custom-class": $props.iconSize,
            name: $props.isSuccess ? 'success-icon' : $props.isError ? 'failure-icon' : $props.icon
          }, null, 8, ["custom-class", "name"])
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_AppNormalText, {
          color: $props.upperTextColor,
          "custom-class": 'font-[700]'
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "upperText")
          ]),
          _: 3
        }, 8, ["color"]),
        _createVNode(_component_AppNormalText, { color: 'text-grey-700' }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "bottomText")
          ]),
          _: 3
        })
      ])
    ]),
    ($props.isSuccess || $props.isError)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_AppNormalText, { "custom-class": 'font-[700]' }, {
            default: _withCtx(() => [
              _createTextVNode("N" + _toDisplayString($props.amount), 1)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", {
              class: _normalizeClass(`w-[5px] h-[5px] rounded-full ${
            $props.isSuccess ? 'bg-green-500' : $props.isError ? 'bg-red-500' : ''
          }`)
            }, null, 2),
            _createVNode(_component_AppNormalText, {
              color: `${
            $props.isSuccess ? 'text-green-500' : $props.isError ? 'text-red-500' : ''
          }`
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode(" Successful ")
              ])),
              _: 1
            }, 8, ["color"])
          ])
        ]))
      : _createCommentVNode("", true),
    (!$props.isSuccess && !$props.isError)
      ? _renderSlot(_ctx.$slots, "rightSuffix", { key: 1 })
      : _createCommentVNode("", true)
  ]))
}