<template>
  <app-wrapper>
    <subpage-layout :title="'Shopping Basket'">
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-2">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in filters"
                :key="index"
                :class="`px-4 py-1 ${
                  selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-700'
                }  rounded-[20px]  flex flex-row items-center justify-center cursor-pointer`"
                @click="selectedFilter = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="w-full flex flex-col space-y-2">
        <div class="w-full flex flex-col space-y-3 px-4">
          <template v-if="items.length == 0">
            <div
              class="w-full flex flex-col items-center justify-center space-y-2 h-[400px] px-4"
            >
              <app-empty-state
                v-if="selectedFilter == 'your-lists'"
                :title="'No lists here'"
                :subTitle="'Your lists will appear here'"
              ></app-empty-state>
              <app-empty-state
                v-if="selectedFilter == 'saved-lists'"
                :title="'No saved lists here'"
                :subTitle="'Your saved lists will appear here'"
              ></app-empty-state>
              <app-empty-state
                v-else-if="selectedFilter == 'orders'"
                :title="'No orders yet'"
                :subTitle="'You can order shoplists by clicking the buy now button'"
              ></app-empty-state>
            </div>
          </template>

          <div
            v-for="(item, index) in items"
            :key="index"
            class="w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 dark:border-grey-main py-3 cursor-pointer"
            @click="handleItemClick(item)"
          >
            <div class="w-full flex flex-row space-x-2">
              <div class="h-[82px] !w-[120px]">
                <app-media-box
                  v-if="selectedFilter == 'your-lists'"
                  :data="item"
                  :isVideo="false"
                  class="!h-[82px] !w-[82px] !rounded-[4.4px]"
                  :isBasketType="true"
                  :enforceGrid="true"
                >
                </app-media-box>
                <app-media-box
                  v-if="selectedFilter == 'saved-lists'"
                  :data="item"
                  :isVideo="false"
                  class="!h-[82px] !w-[82px] !rounded-[4.4px]"
                  :isBasketType="true"
                  :enforceGrid="true"
                >
                </app-media-box>
                <app-media-box
                  v-if="selectedFilter == 'orders'"
                  :data="item"
                  :isVideo="false"
                  class="!h-[82px] !w-[82px] !rounded-[4.4px]"
                  :isBasketType="true"
                  :enforceGrid="false"
                >
                </app-media-box>
              </div>
              <div class="flex flex-col items-end w-full justify-between">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-gray-300"
                    >
                      {{
                        selectedFilter == "your-lists" ||
                        selectedFilter == "saved-lists"
                          ? item.stats?.items + " Items"
                          : item.order_info?.date
                      }}
                    </app-normal-text>
                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:!bg-gray-300"
                    >
                    </span>
                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-gray-300"
                    >
                      {{
                        selectedFilter == "your-lists" ||
                        selectedFilter == "saved-lists"
                          ? item.main_category
                          : "#" + item.order_info?.id
                      }}
                    </app-normal-text>
                  </div>
                </div>

                <div
                  class="w-full flex flex-row space-x-2 justify-between items-center"
                >
                  <app-header-text class="!text-sm">
                    {{
                      selectedFilter == "your-lists" ||
                      selectedFilter == "saved-lists"
                        ? Logic.Common.convertToMoney(item.price, true, "ngn")
                        : item.delivery_state
                    }}
                  </app-header-text>

                  <div class="flex flex-row justify-end items-center space-x-2">
                    <app-button
                      :padding="'!px-4 !py-1'"
                      class="!bg-primary-50 !text-primary-500 dark:!bg-primary-500 dark:!text-primary-50"
                    >
                      {{
                        selectedFilter == "orders"
                          ? `${
                              item.delivery_state != "Delivered"
                                ? "Track"
                                : "Review"
                            }`
                          : "Buy Now"
                      }}
                    </app-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppButton,
  AppHeaderText,
  AppMediaBox,
  AppNormalText,
  AppEmptyState,
} from "@shpt/ui-components";
import { getMediaBox } from "@shpt/ui-components/src/composable";
import { MediaBox } from "@shpt/ui-components/src/types";
import { showOrderInfo } from "@shpt/ui-components/src/composable/shop";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppButton,
    AppMediaBox,
    AppEmptyState,
    AppWrapper,
  },
  name: "ShoppingBasketPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyUserShoplistCarts",
        method: "GetUserShoplistCarts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shop",
        property: "ManyUserOrders",
        method: "GetUserOrders",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyShoplistCollections",
        method: "GetShoplistCollections",
        params: [
          1,
          40,
          "CREATED_AT",
          "DESC",
          `{
            column: PROFILE_ID
            operator: EQ
            value: ${Logic.Auth.AuthUser?.profile?.id}
          }
            `,
        ],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shopping Basket Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Shopping Basket",
    });

    const ManyUserShoplistCarts = ref(Logic.Shoplist.ManyUserShoplistCarts);
    const ManyUserOrders = ref(Logic.Shop.ManyUserOrders);
    const ManyShoplistCollections = ref(Logic.Shoplist.ManyShoplistCollections);
    const selectedFilter = ref("your-lists");

    const items = reactive<MediaBox[]>([]);

    const filters = reactive([
      {
        id: "your-lists",
        title: "Your Lists",
      },
      {
        id: "saved-lists",
        title: "Saved Lists",
      },
      {
        id: "orders",
        title: "Orders",
      },
    ]);

    const setItems = () => {
      items.length = 0;

      filters[0].title = `Your Lists ${
        ManyShoplistCollections.value?.data?.length
          ? `(${ManyShoplistCollections.value?.data?.length})`
          : ""
      }`;

      filters[1].title = `Saved Lists ${
        ManyUserShoplistCarts.value?.length &&
        ManyUserShoplistCarts.value?.length > 0
          ? `(${ManyUserShoplistCarts.value?.length})`
          : ""
      }`;

      filters[2].title = `Orders ${
        ManyUserOrders.value?.length ? `(${ManyUserOrders.value?.length})` : ""
      }`;

      if (selectedFilter.value == "your-lists") {
        ManyShoplistCollections.value?.data?.forEach((item) => {
          if (item) {
            const mediaBox = getMediaBox(item);
            items.push(mediaBox);
          }
        });
      } else if (selectedFilter.value == "saved-lists") {
        ManyUserShoplistCarts.value?.forEach((item) => {
          if (item.collection) {
            const mediaBox = getMediaBox(item.collection);
            items.push(mediaBox);
          }
        });
      } else {
        ManyUserOrders.value?.forEach((item) => {
          const orderItems = item.items;

          const mediaBox: MediaBox = {
            uuid: item.id,
            base_image: orderItems[0].product.primary_image_url,
            tags: [],
            is_large_view: false,
            is_grid: true,
            grid_images: orderItems.map(
              (item) => item.product.primary_image_url
            ),
            title:
              item.collection?.name ||
              (orderItems?.length > 1
                ? "New Order"
                : `${orderItems[0].product.name}`),
            price: item.total_amount,
            stats: {
              items: orderItems.length,
            },
            main_category: orderItems[0].product.category.name,
            delivery_state: capitalize(item.status),
            order_info: {
              id: item.uuid.split("-")[0].toUpperCase(),
              date: Logic.Common.fomartDate(item.updated_at, "ddd, MMM DD"),
            },
            user_info: {
              image: "",
              image_size: "small",
              handle: "",
            },
            order_data: item,
          };
          items.push(mediaBox);
        });
      }
    };

    const handleItemClick = (item: MediaBox) => {
      if (
        selectedFilter.value == "your-lists" ||
        selectedFilter.value == "saved-lists"
      ) {
        Logic.Shoplist.SingleShoplistCollection = item.data;
        Logic.Common.GoToRoute(`/shoplist/${item.uuid}`);
      } else {
        showOrderInfo(`#${item.order_info?.id || ""}`, item.order_data, false);
      }
    };

    const setState = () => {
      const tab = Logic.Common.route?.query.tab?.toString();
      if (tab == "orders") {
        selectedFilter.value = "orders";
      } else {
        selectedFilter.value = selectedFilter.value ?? "your-lists";
      }
    };

    watch([ManyUserShoplistCarts, ManyUserOrders], () => {
      setItems();
    });

    onIonViewWillEnter(() => {
      setItems();
      setState();
    });

    watch(selectedFilter, () => {
      setItems();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyUserShoplistCarts",
        ManyUserShoplistCarts
      );
      Logic.Shop.watchProperty("ManyUserOrders", ManyUserOrders);
      Logic.Shoplist.watchProperty(
        "ManyShoplistCollections",
        ManyShoplistCollections
      );

      if (Logic.Common.currentBuildType() == "web") {
        setItems();
        setState();
      } else {
        setState();
      }
    });

    return {
      filters,
      Logic,
      selectedFilter,
      items,
      handleItemClick,
    };
  },
});
</script>
