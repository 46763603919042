<template>
  <app-wrapper>
    <subpage-layout :title="'Referral'">
      <div class="w-full flex flex-col space-y-4 px-4">
        <!-- Top section -->
        <div class="w-full flex flex-col space-y-3">
          <app-image-loader
            :photoUrl="'/images/purple-bg.svg'"
            class="w-full flex flex-col space-y-3 h-[150px] mdlg:!h-[200px] px-2 py-2 !overflow-y-visible relative rounded-[10px]"
          >
            <img
              src="/images/dropping-coins.svg"
              class="absolute bottom-[5%] right-[2%] h-[85%] z-0"
            />
            <!-- content -->
            <div class="w-full flex flex-col z-40 h-full justify-between">
              <div class="w-full flex flex-col space-y-1 px-3">
                <app-normal-text class="text-left !text-white">
                  Refer Friends, Earn more
                </app-normal-text>
                <app-header-text class="!text-xl !text-white">
                  Get {{ AuthUser?.referral_summary?.point_per_referral }}%
                  Bonus
                </app-header-text>
              </div>

              <div
                class="w-full flex flex-row rounded-[38px] px-2 py-2 bg-[#340163] items-center space-x-2"
              >
                <div class="w-[22px]">
                  <app-icon :name="'referral-badge'" class="!h-[25px]" />
                </div>
                <app-normal-text class="!text-left !text-white !font-light">
                  Referral Points:
                  <span class="font-semibold">
                    {{
                      Logic.Common.convertToMoney(
                        AuthUser?.referral_summary?.point_gained,
                        true,
                        ""
                      )
                    }}
                    pts
                  </span>
                </app-normal-text>
              </div>
            </div>
          </app-image-loader>
        </div>

        <!-- Referral code section -->
        <div
          class="w-full flex flex-col px-4 py-4 rounded-[10px] bg-[#F7F7F7] space-y-3"
        >
          <app-normal-text class="!text-left !text-[#664D7E] !text-[11px]">
            REFERAL CODE
          </app-normal-text>
          <div
            class="w-full flex flex-row items-center justify-between px-3 py-2 rounded-[40px] border-[1px] border-[#E8E8E8]"
          >
            <app-normal-text class="!text-[#240046]">
              SHPT-{{ AuthUser?.referral_summary?.referral_code }}
            </app-normal-text>

            <div
              class="flex flex-row space-x-1 items-center justify-end cursor-pointer"
              @click="copyCode()"
            >
              <app-normal-text class="!text-[#898989]">
                {{ codeCopied ? "Copied!" : "Copy" }}
              </app-normal-text>
              <app-icon :name="'copy-grey'" class="!h-[16px]" />
            </div>
          </div>

          <app-button
            class="w-full flex flex-row items-center space-x-2 bg-[#9D4EDD]"
            :padding="'py-2'"
            @click="shareCode()"
          >
            Share Code
          </app-button>
        </div>

        <!-- How it works -->
        <div class="w-full flex flex-col space-y-3">
          <app-header-text class="!text-left"> How it works </app-header-text>
          <div class="w-full flex flex-col space-y-5">
            <div
              v-for="(item, index) in howItWorks"
              :key="index"
              class="w-full flex flex-row items-center space-x-2"
            >
              <div class="w-[42px]">
                <div class="w-[42px]">
                  <app-icon :name="item.icon" class="h-[40px]" />
                </div>
              </div>

              <app-normal-text class="!text-left !text-[12px] mdlg:!text-sm">
                {{ item.content }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import { Share } from "@capacitor/share";
import {
  AppImageLoader,
  AppNormalText,
  AppHeaderText,
  AppIcon,
  AppButton,
} from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppImageLoader,
    AppNormalText,
    AppHeaderText,
    AppIcon,
    AppButton,
    AppWrapper,
  },
  name: "ReferralPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "ReferralSummary",
        method: "GetReferralSummary",
        params: [],
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Referral Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Referral",
    });

    const codeCopied = ref(false);

    const AuthUser = ref(Logic.Auth.AuthUser);

    const howItWorks = reactive([
      {
        icon: "referral-link",
        content:
          "Share your unique referral code with your friends. They claim their referral bonus using your code",
      },
      {
        icon: "referral-snap",
        content:
          "When your friends snap reciepts, shop & pay, they earn cashback rewards",
      },
      {
        icon: "referral-earn",
        content:
          "You automatically get 10% of the cashback rewards your friends earn.",
      },
    ]);

    const copyCode = () => {
      codeCopied.value = true;
      Logic.Common.copytext(
        `SHPT-${AuthUser?.value?.referral_summary?.referral_code}`
      );
      setTimeout(() => {
        codeCopied.value = false;
      }, 3000);
    };

    const shareCode = async () => {
      await Share.share({
        title: "Join Shoppoint",
        text: `Join Shoppoint with my code - SHPT-${AuthUser?.value?.referral_summary?.referral_code}, and get rewarded for spending`,
        url: `https://getshoppoint.com/download`,
        dialogTitle: "Refer you friends to Shoppoint",
      });
    };

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);
    });

    return {
      AuthUser,
      Logic,
      codeCopied,
      howItWorks,
      copyCode,
      shareCode,
    };
  },
});
</script>
