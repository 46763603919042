import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col h-full items-center justify-center" }
const _hoisted_2 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_web_side_bar = _resolveComponent("app-web-side-bar")!
  const _component_app_board_side_bar = _resolveComponent("app-board-side-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "w-full h-full mdlg:!w-[70%] lg:!w-[65%] mdlg:!h-full lg:text-sm mdlg:text-[12px] grid grid-cols-12 text-xs!font-inter relative",
      id: `parentContainer-${_ctx.uniqueKey}`
    }, [
      (_openBlock(), _createBlock(_Teleport, {
        to: "#app",
        disabled: _ctx.Logic.Common.currentBuildType() == 'web'
      }, [
        (
            _ctx.showWebSideBar ||
            _ctx.Logic.Common.currentMediaQuery() != 'md' ||
            (_ctx.Logic.Common.currentMediaQuery() == 'md' &&
              _ctx.Logic.Common.currentBuildType() == 'web')
          )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full md:!flex md:!col-span-4 mdlg:!col-span-3 md:!pl-4 z-[9999999] bg-white dark:bg-black",
              style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; left: ${_ctx.sideBarConfig.left.left}px;`),
              id: `sidbarContainerLeft`
            }, [
              _createVNode(_component_app_web_side_bar, { tabIsActive: _ctx.tabIsActive }, null, 8, ["tabIsActive"])
            ], 4))
          : _createCommentVNode("", true)
      ], 8, ["disabled"])),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col sticky top-0 md:!flex md:!col-span-4 mdlg:!col-span-3" }, null, -1)),
      _createElementVNode("div", {
        class: _normalizeClass(`col-span-12 mdlg:!col-span-6 relative h-full md:!flex md:!col-span-8  flex mdlg:!border-l-[1px] md:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100 flex-col lg:text-sm mdlg:text-[12px] text-xs !font-inter mdlg:dark:!border-grey-700 ${
          _ctx.Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`)
      }, [
        _renderSlot(_ctx.$slots, "topBar"),
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col sticky top-0" }, null, -1)),
      _createElementVNode("div", {
        class: "hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full",
        style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; right: ${_ctx.sideBarConfig.left.left}px;`),
        id: `sidbarContainerRight`
      }, [
        _createVNode(_component_app_board_side_bar)
      ], 4)
    ], 8, _hoisted_2)
  ]))
}