import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "col-span-1 flex flex-col space-y-2 !cursor-pointer"
}
const _hoisted_2 = { class: "w-full flex flex-col border-[1px] border-[#E9E6ED] rounded-[10px] dark:border-gray-700" }
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-col px-2 py-2 !pt-0 left-0 space-y-2 rounded-b-[10px] bg-white dark:bg-gray-800`)
}
const _hoisted_4 = { class: "flex flex-row justify-between items-center" }
const _hoisted_5 = { class: "!font-semibold !text-[#9D4EDD] !text-[11px] mdlg:!text-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_media_box, {
            data: _ctx.item.data,
            isVideo: !_ctx.item.data.is_grid,
            class: _normalizeClass(` ${_ctx.mediaBoxStyle} !rounded-t-[10px]`)
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "absolute bottom-0 left-0 h-[10px] w-full bg-white dark:bg-gray-800" }, null, -1)
            ])),
            _: 1
          }, 8, ["data", "isVideo", "class"]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_app_normal_text, { class: "!text-left !text-[11px] !line-clamp-1 w-full mdlg:!text-[12px]" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.data.title), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_app_normal_text, { class: "!text-[12px] dark:!text-white" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                parseFloat(_ctx.item.data.price?.toFixed(2) || "0"),
                false,
                "ngn"
              )), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_app_normal_text, { customClass: '!text-[10px]  !text-grey-400 dark:!text-gray-200  !font-light !whitespace-nowrap' }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_5, " ₦" + _toDisplayString(_ctx.numberToAbbrev(_ctx.item.data.points || 0)), 1),
                  _cache[1] || (_cache[1] = _createTextVNode(" Back "))
                ]),
                _: 1
              })
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}