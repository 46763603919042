<template>
  <div
    @click="$emit('clicked', text)"
    :class="`border ${
      isSelected ? 'bg-primary-50 border-primary-400' : 'border-grey-100'
    }   h-fit py-5 px-10 rounded-lg flex flex-col space-y-3 items-center`"
  >
    <AppIcon :custom-class="`${iconSize}`" :name="icon"></AppIcon>
    <AppNormalText :custom-class="'capitalize !font-semibold'">{{
      text
    }}</AppNormalText>
  </div>
</template>
<script lang="ts">
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";

export default {
  components: {
    AppNormalText,
    AppIcon,
  },
  props: {
    isSelected: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
      required: true,
    },
    iconSize: {
      type: String,
      default: "h-[36px]",
    },
    customClass: {
      type: String,
      required: false,
    },
  },
  emits: ["clicked"],
  setup() {
    return {};
  },
  name: "AppList",
};
</script>
