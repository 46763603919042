import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_2 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_3 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide",
  id: "giftCardMainContainer"
}
const _hoisted_4 = { class: "flex flex-row pr-4" }
const _hoisted_5 = ["onClick", "id"]
const _hoisted_6 = { class: "w-full flex flex-col px-4" }
const _hoisted_7 = { class: "w-full grid grid-cols-2 gap-3 px-4" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "px-2 py-2 w-full flex flex-col space-y-2" }
const _hoisted_11 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_12 = { class: "flex flex-row items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Buy Gift Card' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _cache[1] || (_cache[1] = _createElementVNode("div", null, null, -1)),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.giftCardCategories, (category, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "flex flex-row pl-2",
                      key: index,
                      onClick: ($event: any) => (_ctx.selectedCategory = category.name),
                      id: `giftcard${category.name
                  .toLowerCase()
                  .replaceAll(' ', '_')}`
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(` ${
                    _ctx.selectedCategory == category.name
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-grey-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1 cursor-pointer`)
                      }, [
                        _createVNode(_component_app_normal_text, {
                          class: _normalizeClass(`!whitespace-nowrap ${
                      _ctx.selectedCategory == category.name
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-100'
                    }`)
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(category.name), 1)
                          ]),
                          _: 2
                        }, 1032, ["class"])
                      ], 2)
                    ], 8, _hoisted_5))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_text_field, {
                placeholder: 'Search Giftcards',
                hasTitle: true,
                type: 'text',
                name: "Search_Giftcards",
                ref: "SearchGiftcards",
                modelValue: _ctx.searchQuery,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
              }, {
                "inner-prefix": _withCtx(() => [
                  _createVNode(_component_app_icon, {
                    name: 'search-grey',
                    customClass: 'h-[18px]'
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billersOptons, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-span-1 rounded-[9.26px] flex flex-col border-[1px] border-[#E9E6ED] dark:border-gray-700 cursor-pointer",
                  onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(`/cashback/gift_card/${item.uuid}`))
                }, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: '',
                    class: "!w-full h-[101px] mdlg:!h-[130px] md:!h-[130px] rounded-t-[9.26px] relative"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(`w-full flex flex-row items-center h-full justify-center absolute rounded-t-[9.26px] top-0 left-0`),
                        style: _normalizeStyle(`background-color: ${item.base_color};`)
                      }, [
                        _createElementVNode("img", {
                          src: item.base_image,
                          class: "h-[80px]"
                        }, null, 8, _hoisted_9)
                      ], 4)
                    ]),
                    _: 2
                  }, 1024),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_app_normal_text, { class: "!font-semibold text-left !line-clamp-1" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.merchant_name) + " Gift Cards ", 1)
                      ]),
                      _: 2
                    }, 1024),
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_component_app_normal_text, { class: "!text-[#664D7E] dark:!text-primary-100 !text-[11px] !line-clamp-1" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.currencies.join(",")), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createElementVNode("div", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.flags, (image, index) => {
                          return (_openBlock(), _createBlock(_component_app_image_loader, {
                            key: index,
                            photoUrl: image,
                            class: "h-[15px] w-[15px]"
                          }, null, 8, ["photoUrl"]))
                        }), 128))
                      ])
                    ])
                  ])
                ], 8, _hoisted_8))
              }), 128))
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}