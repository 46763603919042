<template>
  <div class="flex w-full flex-col space-y-2">
    <div
      v-for="(option, index) in options"
      :key="index"
      @click.stop="selectedOption = option.key"
      class="flex w-full flex-row items-center justify-between cursor-pointer py-3 border-b-[1px] border-gray-300 dark:border-gray-600"
    >
      <div class="flex flex-row space-x-2 items-center justify-between w-full">
        <div class="flex flex-row space-x-2 items-center justify-start">
          <span class="h-full flex items-start justify-center">
            <span
              :class="`h-[11px] w-[11px] rounded-full ${
                selectedOption == option.key ? '!bg-secondary-500' : 'bg-[#D9D9D9]'
              }`"
            >
            </span>
          </span>

          <app-image-loader
            v-if="option.imageUrl"
            :photo-url="option.imageUrl"
            :customClass="`h-[26px] w-[26px] rounded-full `"
          />
          <app-normal-text :customClass="'!font-semibold dark:!text-white'">
            {{ option.alt_value ? option.alt_value : option.value }}
          </app-normal-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import AppNormalText from "../AppTypography/normalText.vue";
import { onMounted, ref, watch } from "vue";
import { SelectOption } from "../../types";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";

export default {
  components: {
    AppNormalText,
    AppIcon,
    AppImageLoader,
  },
  props: {
    options: {
      type: Array as () => SelectOption[],
      required: true,
    },
    modelValue: {
      required: false,
    },
  },
  name: "AppRadio",
  emits: ["update:modelValue"],
  setup(props: any, context: any) {
    const selectedOption = ref("");

    watch(selectedOption, () => {
      context.emit("update:modelValue", selectedOption.value);
    });

    onMounted(() => {
      if (props.modelValue) {
        selectedOption.value = props.modelValue;
      }
    });

    return {
      selectedOption,
    };
  },
};
</script>
