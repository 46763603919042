<template>
  <app-wrapper>
    <subpage-layout :title="'All transactions'">
      <div class="w-full flex flex-col space-y-4 px-4">
        <template v-if="loadingTransactions">
          <div
            class="w-full flex flex-col justify-center items-center h-[200px]"
          >
            <app-loading-state class="text-primary-500" />
          </div>
        </template>
        <app-virtual-scroller
          :data="transactions"
          :pagination="pagination"
          :fetch-more="fetchMoreTransactions"
        >
          <template #item-content="{ index, item }">
            <app-transaction
              :key="index"
              :data="item"
              class="cursor-pointer"
              @click="
                item.transaction_type == 'wallet'
                  ? Logic.Common.GoToRoute(`/savings/transactions/${item.uuid}`)
                  : Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
              "
            />
          </template>
          <template #skeleton-loaders>
            <div class="w-full flex flex-row space-x-3">
              <div class="w-[30px] h-[30px] rounded-full skeleton"></div>
              <div class="flex flex-col space-y-2 w-full">
                <div class="w-[40%] h-[10px] rounded skeleton"></div>
                <div
                  class="w-full flex flex-col space-y-2 skeleton h-[20px] rounded-[10px]"
                ></div>
              </div>
            </div>
          </template>
        </app-virtual-scroller>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import {
  AppTransaction,
  AppVirtualScroller,
  AppLoadingState,
} from "@shpt/ui-components";
import {
  PaginatorInfo,
  PointTransaction,
  Transaction,
} from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppTransaction,
    AppVirtualScroller,
    AppLoadingState,
    AppWrapper,
  },
  name: "SavingsTransactionsPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Savings Transactions Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Savings Transactions",
    });

    const loadingTransactions = ref(false);
    const UserWallet = ref(Logic.Wallet.UserWallet);

    const ClientWalletTransactions = ref(Logic.Wallet.ClientWalletTransactions);
    const ManyPointTransaction = ref(Logic.Wallet.ManyPointTransaction);

    const pagination = ref<PaginatorInfo>();

    const transactions = reactive<
      {
        id: string;
        uuid: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
        real_date: string;
        transaction_type: string;
      }[]
    >([]);

    const setTransactions = () => {
      transactions.length = 0;

      const allTransactions: (Transaction | PointTransaction | any)[] = [
        ...(ClientWalletTransactions.value?.data || []),
        ...(ManyPointTransaction.value?.data || []),
      ];

      allTransactions.forEach((transaction) => {
        transactions.push({
          id: transaction.id,
          uuid: transaction.uuid,
          description: transaction.description || "",
          type: transaction.dr_or_cr,
          time: Logic.Common.fomartDate(
            transaction.created_at,
            "MMM D, YYYY | h:mmA"
          ),
          amount: transaction.amount,
          category: capitalize(
            transaction.chargeable_type?.split("_").join(" ") || ""
          ),
          real_date: transaction.created_at,
          transaction_type:
            transaction.__typename == "Transaction" ? "wallet" : "point",
        });
      });

      transactions.sort((a, b) => {
        return (
          new Date(b.real_date).getTime() - new Date(a.real_date).getTime()
        );
      });
    };

    const fetchMoreTransactions = (nextPage: number) => {
      return fetchTransactions(true, nextPage, true);
    };

    const fetchTransactions = (silent = false, page = 1, isUpdate = false) => {
      if (!silent) {
        loadingTransactions.value = true;
      }
      const allFetchActions: any[] = [];
      allFetchActions.push(
        Logic.Wallet.GetTransactionsByWalletId(
          "client",
          parseInt(UserWallet.value?.id || "0") || 0,
          page,
          10,
          "CREATED_AT",
          "DESC",
          isUpdate
        ).then((res) => {
          if (res && isUpdate) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Wallet.ClientWalletTransactions)
            );
            existingData.data = existingData.data.concat(res.data);
            existingData.paginatorInfo = res.paginatorInfo;

            Logic.Wallet.ClientWalletTransactions = existingData;
          }
        })
      );
      allFetchActions.push(
        Logic.Wallet.GetManyPointTransaction(
          parseInt(UserWallet.value?.id || "0") || 0,
          page,
          10,
          "CREATED_AT",
          "DESC",
          isUpdate
        ).then((res) => {
          if (res && isUpdate) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Wallet.ManyPointTransaction)
            );
            existingData.data = existingData.data.concat(res.data);
            existingData.paginatorInfo = res.paginatorInfo;

            Logic.Wallet.ManyPointTransaction = existingData;
          }
        })
      );
      return Promise.all(allFetchActions)
        .then(() => {
          //
          loadingTransactions.value = false;
          pagination.value = ClientWalletTransactions.value?.paginatorInfo;

          if (
            (pagination.value?.lastPage || 0) <
            (ManyPointTransaction.value?.paginatorInfo?.lastPage || 0)
          ) {
            pagination.value = ManyPointTransaction.value?.paginatorInfo;
          }
          setTransactions();
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    watch([ClientWalletTransactions, ManyPointTransaction], () => {
      setTransactions();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("UserWallet", UserWallet);

      Logic.Wallet.watchProperty(
        "ClientWalletTransactions",
        ClientWalletTransactions
      );
      Logic.Wallet.watchProperty("ManyPointTransaction", ManyPointTransaction);
      fetchTransactions();
    });

    return {
      Logic,
      transactions,
      loadingTransactions,
      pagination,
      fetchMoreTransactions,
    };
  },
});
</script>
