import { Logic } from '.'
import { MediaBox } from '../types'
import { reactive } from 'vue'

const newPromotion = reactive<{
  basic: {
    name: string
    shoplist_id?: string
    shoplist?: MediaBox
    shoppable_data?: any
    shoppable_type?: 'product' | 'shoplist'
    type: 'banner' | 'shoppable' | 'website_visit' | 'polls'
    cta?: string
    landing_page_url?: string
    base_image?: Blob
    base_video?: Blob
    polls: {
      question: string
      answer_type: 'binary' | 'options'
      option_type: 'plain' | 'image'
      options: {
        value: string
        slug: string
        image_url?: string
        image_blob?: Blob
      }[]
    }
    banner: {
      crop_image_blob?: Blob
    }
  }
  targeting: {
    age_ranges: any[]
    gender: string[]
    locations: any[]
    behavioural_demographies: any[]
  }
  budgeting: {
    total: string
    strategy: string
    max_amount: string
  }
  scheduling: {
    start: string
    end: string
    ad_start_time: string
    ad_end_time: string
  }
  legal: {
    merchant_terms: boolean
    privacy: boolean
    ad_guidelines: boolean
  }
  progress: {
    loading: boolean
    message: string
    percentage: number
  }
  run_until_paused: boolean
}>({
  basic: {
    name: '',
    type: 'shoppable',
    shoplist_id: '',
    shoppable_data: '',
    shoppable_type: 'shoplist',
    cta: 'Visit now',
    polls: {
      question: 'Is this your favourite food?',
      answer_type: 'options',
      option_type: 'plain',
      options: [
        {
          slug: 'option_1',
          value: 'Option 1',
        },
        {
          slug: 'option_2',
          value: 'Option 2',
        },
      ],
    },
    banner: {},
  },
  targeting: {
    age_ranges: [],
    gender: [],
    locations: [],
    behavioural_demographies: [],
  },
  budgeting: {
    max_amount: '',
    strategy: '',
    total: '',
  },
  scheduling: {
    start: Logic.Common.momentInstance(new Date()).format('YYYY-MM-DD'),
    end: Logic.Common.momentInstance(new Date())
      .add(7, 'days')
      .format('YYYY-MM-DD'),
    ad_start_time: '24',
    ad_end_time: '23',
  },
  legal: {
    merchant_terms: false,
    privacy: false,
    ad_guidelines: false,
  },
  progress: {
    loading: false,
    message: '',
    percentage: 0,
  },
  run_until_paused: true,
})

const newPromotionMedia = reactive<{
  type: 'image' | 'video'
  file_url: string
  file?: Blob
  base_image?: Blob
  base_video?: Blob
}>({
  type: 'image',
  file_url: '',
})

export { newPromotion, newPromotionMedia }
