<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3"
  >
    <!-- Card number field -->
    <app-text-field
      :placeholder="'0000 0000 0000 0000'"
      :hasTitle="true"
      :type="'tel'"
      :rules="[FormValidations.RequiredRule]"
      :padding="'py-4 px-4'"
      v-model="formData.card_number"
      name="Card number"
      ref="card_number"
      :content-rule="{
        addAfterCount: 4,
        characterToAdd: ` `,
        max: 19,
      }"
    >
      <template v-slot:title> Enter Card number </template>
    </app-text-field>

    <div class="w-full grid grid-cols-2 gap-4">
      <div class="col-span-1 flex flex-col">
        <app-text-field
          :placeholder="'MM/YY'"
          :hasTitle="true"
          :type="'tel'"
          :padding="'py-4 px-4'"
          name="Expiry date"
          ref="expiry_date"
          v-model="formData.expiry_date"
          :rules="[FormValidations.RequiredRule]"
          :content-rule="{
            addAfterCount: 2,
            characterToAdd: `/`,
            max: 5,
          }"
        >
          <template v-slot:title>Enter expiry date </template>
        </app-text-field>
      </div>

      <div class="col-span-1 flex flex-col">
        <app-text-field
          :placeholder="'123'"
          :hasTitle="true"
          :type="'tel'"
          :rules="[FormValidations.RequiredRule]"
          v-model="formData.cvv"
          :padding="'py-4 px-4'"
          name="CVV"
          ref="cvv"
          :content-rule="{
            addAfterCount: 3,
            characterToAdd: ``,
            max: 3,
          }"
        >
          <template v-slot:title> Enter cvv </template>
        </app-text-field>
      </div>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { AppTextField } from "../AppForm";
import { Logic } from "../../composable";
import { AppFormWrapper } from "../AppForm";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
  },
  name: "appModalAddCard",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      card_number: "",
      expiry_date: "",
      cvv: "",
    });

    const card_number = ref();
    const cvv = ref();
    const expiry_date = ref();
    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(card_number.value, cvv.value, expiry_date.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        return formData;
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      card_number,
      cvv,
      expiry_date,
      formData,
      validateForm,
    };
  },
});
</script>
