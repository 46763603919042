import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full flex flex-col h-[300px] items-center justify-center"
}
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-col space-y-3"
}
const _hoisted_5 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_7 = {
  key: 1,
  class: "w-full flex flex-col h-[300px] items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_beneficiary = _resolveComponent("app-beneficiary")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'Send Money' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_text_field, {
              placeholder: `Enter user’s handle`,
              hasTitle: true,
              modelValue: _ctx.searchQuery,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
              "update-value": _ctx.searchQuery,
              onKeyPressed: _ctx.searchUsers
            }, {
              title: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" User Handle ")
              ])),
              "inner-prefix": _withCtx(() => [
                _createVNode(_component_app_icon, {
                  name: 'at',
                  customClass: 'h-[18px]'
                })
              ]),
              "inner-suffix": _withCtx(() => [
                (_ctx.searchQuery.length > 1 && _ctx.isResolvingHandle)
                  ? (_openBlock(), _createBlock(_component_app_loading, {
                      key: 0,
                      class: "!text-primary-400 dark:!text-white"
                    }))
                  : _createCommentVNode("", true),
                (
                !(_ctx.searchQuery.length > 1 && _ctx.isResolvingHandle) &&
                _ctx.Logic.Common.currentBuildType() == 'mobile'
              )
                  ? (_openBlock(), _createBlock(_component_app_icon, {
                      key: 1,
                      name: 'phonebook',
                      customClass: 'h-[18px]',
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchWithPhone()))
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue", "update-value", "onKeyPressed"]),
            (_ctx.searchResult.length && _ctx.searchQuery.length > 1)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.searchResult.length)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.searchResult, (item, index) => {
                        return (_openBlock(), _createBlock(_component_app_beneficiary, {
                          key: index,
                          item: { ...item, user_uuid: item.uuid },
                          class: "cursor-pointer",
                          onClick: ($event: any) => (
                  _ctx.Logic.Common.GoToRoute(
                    `/pay/initiate?account_number=${item.id}&account_name=${item.name}&photo_url=${item.photo_url}&user_uuid=${item.uuid}`
                  )
                )
                        }, null, 8, ["item", "onClick"]))
                      }), 128))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_app_empty_state, {
                          title: 'Nothing here yet',
                          subTitle: `No user found`
                        })
                      ]))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_app_normal_text, { class: "!font-semibold !text-sm text-left" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Beneficiaries ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "!text-[#9D4EDD] underline cursor-pointer" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" View all ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    (_ctx.beneficiaries.length)
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.beneficiaries, (item, index) => {
                          return (_openBlock(), _createBlock(_component_app_beneficiary, {
                            key: index,
                            item: item,
                            class: "cursor-pointer",
                            onClick: ($event: any) => (
                    _ctx.Logic.Common.GoToRoute(
                      `/pay/initiate?account_number=${item.id}&account_name=${item.name}&photo_url=${item.photo_url}&user_uuid=${item.id}`
                    )
                  )
                          }, null, 8, ["item", "onClick"]))
                        }), 128))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                          _createVNode(_component_app_empty_state, {
                            title: 'Nothing here yet',
                            subTitle: 'There are no saved beneficiaries yet'
                          })
                        ]))
                  ])
                ]))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}