import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-3" }
const _hoisted_3 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_4 = { class: "w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_6 = { class: "w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_7 = { class: "w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3" }
const _hoisted_8 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-2 justify-center items-center py-3 px-3" }
const _hoisted_10 = { class: "w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/products/add')))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_app_icon, {
              name: 'product',
              class: _normalizeClass('h-[15px] mdlg:!h-[20px] md:!h-[20px] ')
            })
          ]),
          _createVNode(_component_app_normal_text, { class: "!text-center !text-[12px] mdlg:!text-[14px]" }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Add a new Product ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleAddProduct('csv')))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_app_icon, {
              name: 'csv',
              class: _normalizeClass('h-[17px] mdlg:!h-[20px] md:!h-[20px] ')
            })
          ]),
          _createVNode(_component_app_normal_text, { class: "!text-center !text-[12px] mdlg:!text-[14px]" }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Import From CSV ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer flex items-center justify-center",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleAddProduct('shopify')))
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_app_icon, {
              name: 'shopify',
              customClass: '!h-[17px]'
            })
          ]),
          _createVNode(_component_app_normal_text, { class: "!text-[12px] mdlg:!text-[14px] !text-center" }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Import from "),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" Shopify ")
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", {
        class: "col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer flex items-center justify-center",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleAddProduct('woocommerce')))
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_app_icon, {
              name: 'woocommerce',
              customClass: '!h-[22px]'
            })
          ]),
          _createVNode(_component_app_normal_text, { class: "!text-[12px] mdlg:!text-[14px] !text-center" }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Import from "),
              _createElementVNode("br", null, null, -1),
              _createTextVNode(" WooCommerce ")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}