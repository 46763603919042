<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Enter Email -->
    <app-text-field
      :placeholder="'Enter code'"
      v-model="formData.code"
      :hasTitle="true"
      type="tel"
      :padding="'py-3 px-4'"
      name="Invitation Code"
      ref="invitation_code"
      :rules="[
        FormValidations.RequiredRule,
        FormValidations.customValidator(
          formData.code.length == 9,
          'Invitation code must be valid'
        ),
      ]"
      :content-rule="{
        addAfterCount: 0,
        characterToAdd: ``,
        max: 9,
      }"
    >
      <template v-slot:title> Invitation Code </template>
    </app-text-field>

    <div class="w-full flex flex-col">
      <app-button
        :class="`w-full py-3 ${formIsValid ? '' : 'opacity-50'}`"
        @click.prevent="validateForm"
      >
        Join
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppTextField, AppCheckbox } from "../AppForm";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    AppTextField,
    AppFormWrapper,
    AppButton,
    AppNormalText,
    AppCheckbox,
  },
  name: "AppModalJoinBusiness",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive<{
      code: string;
    }>({
      code: "",
    });

    const invitation_code = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const formIsValid = computed(() => {
      return formData.code.length == 9;
    });

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(invitation_code.value);
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status && formData.code.length == 9) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      formData,
      formIsValid,
      validateForm,
    };
  },
});
</script>
