import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col px-4 pb-1" }
const _hoisted_2 = { class: "w-full flex flex-col pl-4" }
const _hoisted_3 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide",
  id: "categoryContainerShoplistProducts"
}
const _hoisted_4 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_5 = ["onClick", "id"]
const _hoisted_6 = { class: "w-full flex flex-col space-y-2 px-4" }
const _hoisted_7 = { class: "w-full flex flex-col pt-4" }
const _hoisted_8 = { class: "col-span-1 flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_banner_ad = _resolveComponent("app-banner-ad")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Top Cashback Offers',
        mainSectionClass: 
        _ctx.Logic.Common.currentBuildType() == 'web' ? '!min-h-screen !h-fit' : ''
      ,
        showBottomPadding: false
      }, {
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_banner_ad)
          ]),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categoryOptions, (category, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "flex flex-row cursor-pointer pr-2",
                    key: index,
                    onClick: ($event: any) => (_ctx.selectedCategory = category.key),
                    id: `category${category.key}`
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(` ${
                    _ctx.selectedCategory == category.key
                      ? 'bg-primary-main'
                      : 'bg-[#f3f3f3] dark:bg-gray-700'
                  } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`)
                    }, [
                      _createVNode(_component_app_normal_text, {
                        class: _normalizeClass(`!whitespace-nowrap ${
                      _ctx.selectedCategory == category.key
                        ? 'text-white'
                        : 'text-gray-600 dark:text-gray-50'
                    }`)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(category.value), 1)
                        ]),
                        _: 2
                      }, 1032, ["class"])
                    ], 2)
                  ], 8, _hoisted_5))
                }), 128))
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_app_virtual_scroller, {
                "container-class": 'w-full grid grid-cols-2 gap-3 !space-y-0',
                data: _ctx.topCashbackOffers.data,
                pagination: _ctx.topCashbackOffers.paginatorInfo,
                fetchMore: _ctx.fetchMoreCashbackOffers,
                fetchNew: _ctx.fetchNewCashbackOffers,
                direction: 'vertical',
                ref: "productVirtualScrollerRef"
              }, {
                "item-content": _withCtx(({ index, item }) => [
                  _createElementVNode("div", _hoisted_8, [
                    (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                      class: "w-full",
                      key: index,
                      onClick: ($event: any) => (_ctx.handleShoplsistClick(item)),
                      item: item,
                      mediaBoxStyle: '!h-[140px] mdlg:!h-[200px] md:!h-[200px]',
                      theme: _ctx.Logic.Common.currentAppTheme()
                    }, null, 8, ["onClick", "item", "theme"]))
                  ])
                ]),
                "skeleton-loaders": _withCtx(() => _cache[0] || (_cache[0] = [
                  _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                    _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                  ], -1),
                  _createElementVNode("div", { class: "col-span-1 flex flex-col" }, [
                    _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]" })
                  ], -1)
                ])),
                _: 1
              }, 8, ["data", "pagination", "fetchMore", "fetchNew"])
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ])
        ]),
        _: 1
      }, 8, ["mainSectionClass"])
    ]),
    _: 1
  }))
}