<template>
  <cropper
    class="cropper"
    :src="image_url"
    :stencil-props="{
      aspectRatio: aspectRatio,
    }"
    @change="onChange"
  />
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent } from "vue";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default defineComponent({
  components: {
    Cropper,
  },
  props: {
    image_url: {
      type: String,
      default: "",
    },
    aspectRatio: {
      type: Number,
      default: 7.04 / 1,
    },
  },
  name: "AppImageCropper",
  emits: ["update:modelValue"],
  setup(props, context) {
    const onChange = (data: any) => {
      data.canvas.toBlob(
        (blob: any) => {
          const imageBlob = blob;
          context.emit("update:modelValue", imageBlob);
        },
        "image/jpeg",
        0.95
      );
    };

    return {
      onChange,
    };
  },
});
</script>
