<template>
  <div
    class="flex flex-col justify-center rounded-t-2xl space-y-6 bg-white w-full absolute overflow-y-auto max-h-[50%] bottom-0 left-0 px-6 pt-6 pb-10"
  >
    <div class="flex flex-col space-y-6">
      <div class="flex justify-between">
        <normalText :custom-class="'font-[700]'"> Recieve Payment </normalText>
        <div>
          <AppIcon :custom-class="'h-6'" :name="'close-modal'"></AppIcon>
        </div>
      </div>
      <div>
        <div
          v-for="(v, i) in modalList"
          @click="Logic.Common.GoToRoute(v.link)"
          :key="i"
          :class="` ${
            i + 1 == modalList.length ? 'border-none' : ''
          } flex justify-between p-3 items-center border-b border-grey-50`"
        >
          <div class="flex items-center space-x-3">
            <AppIcon :custom-class="'h-9'" :name="v.icon"></AppIcon>
            <normalText :color="'text-base-black'">
              {{ v.text }}
            </normalText>
          </div>
          <AppIcon :custom-class="''" :name="'arrow-right-sidebar'"></AppIcon>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppIcon from "../AppIcon";

import normalText from "../AppTypography/normalText.vue";

export default defineComponent({
  components: { AppIcon, normalText },
  name: "showRecieveOptions",

  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    const modalList = ref([
      {
        icon: "share-details-icon",
        text: "Share Account Details",
        link: "/page/earnings/details",
      },
      {
        icon: "generate-link",
        text: "Generate Payment Link",
        link: "/page/earnings/qrcode",
      },
      {
        icon: "share-qrcode",
        text: "Share QR Code",
        link: "/page/earnings/qrcode",
      },
    ]);

    onMounted(() => {
      console.log("S");
    });

    return {
      modalList,
      Logic,
    };
  },
});
</script>
