<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-2 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  >
    <!-- Select bank field -->
    <app-select
      :placeholder="'Select...'"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      v-model="bankCode"
      :auto-complete="true"
      ref="bank_code"
      :customClass="'dark:!bg-grey-800'"
      :theme="Logic.Common.currentAppTheme()"
      :options="CommercialBanksOptions"
    >
      <template v-slot:title> Select bank </template>
    </app-select>

    <app-text-field
      :placeholder="'Enter account number'"
      :hasTitle="true"
      :type="'tel'"
      :padding="'py-3 px-4'"
      name="Account number"
      ref="bank_account"
      v-model="accountNumber"
      :rules="[FormValidations.RequiredRule]"
      :content-rule="{
        addAfterCount: 0,
        characterToAdd: ``,
        max: 10,
      }"
    >
      <template v-slot:title>Account number </template>
    </app-text-field>

    <div class="w-full flex flex-row justify-end">
      <app-normal-text
        :class="'!text-secondary-main'"
        v-if="!accountResolved && accountNumber.length >= 10"
      >
        Resolving account...
      </app-normal-text>
      <template v-if="accountResolved && formData.account_name">
        <app-normal-text>
          {{ formData.account_name }}
        </app-normal-text>
      </template>
    </div>

    <div class="w-full flex flex-col items-center justify-center pt-2">
      <app-button
        @click.prevent="validateForm"
        :class="`!py-3 w-full  ${accountResolved ? '' : 'opacity-50'}`"
      >
        Add account
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppSelect, AppTextField } from "../AppForm";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppTextField,
    AppFormWrapper,
    AppSelect,
    AppButton,
    AppNormalText,
  },
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppModalAddBankAccount",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive({
      bank_code: "",
      bank_account: "",
      account_name: "",
    });

    const accountNumber = ref("");
    const bankCode = ref("");

    const accountResolved = ref(false);

    const bank_code = ref();
    const bank_account = ref();
    const account_name = ref();
    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    const CommercialBanksOptions = ref(Logic.Wallet.CommercialBanksOptions);

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status && accountResolved.value) {
        if (props.action) {
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    watch([bankCode, accountNumber], () => {
      Logic.Common.debounce(() => {
        if (bankCode && accountNumber.value.length >= 10) {
          accountResolved.value = false;

          Logic.Wallet.ResolveBankAccountForm = {
            account_no: accountNumber.value,
            bank_code: bankCode.value,
            wallet_uuid: "empty",
          };

          Logic.Wallet.ResolveBankAccount(true)?.then((data: any) => {
            if (data && data.data?.ResolveBankAccount) {
              formData.account_name =
                data.data.ResolveBankAccount.account_name || "";
            }
            formData.bank_account = accountNumber.value;
            formData.bank_code = bankCode.value;
            accountResolved.value = true;
          });
        } else {
          accountResolved.value = false;
        }
      });
    });

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(
        bank_code.value,
        bank_account.value,
        account_name.value
      );
    });

    return {
      FormValidations,
      formComp,
      parentRefs,
      bank_code,
      account_name,
      bank_account,
      formData,
      CommercialBanksOptions,
      accountNumber,
      bankCode,
      accountResolved,
      Logic,
      validateForm,
    };
  },
});
</script>
