<template>
  <div class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative">
    <template v-if="isSetupStage">
      <app-normal-text class="!text-center">
        Please enter your woocommerce website URL to continue
      </app-normal-text>

      <app-text-field
        :has-title="false"
        title="Woocommerce Website URL"
        placeholder="Enter URL"
        name="Website URL"
        :rules="[FormValidations.RequiredRule, FormValidations.WebsiteRule]"
        v-model="woocommerceUrl"
      />

      <div class="w-full flex flex-row items-center justify-center pb-4">
        <app-button
          @click="connectWoocommerce()"
          class="!py-3 w-full"
          :loading="isConnecting"
        >
          Connect
        </app-button>
      </div>
    </template>
    <template v-else>
      <!-- Search and add -->
      <div class="w-full flex flex-row space-x-2 items-center">
        <AppTextField :placeholder="`Type here to search`" v-model="searchQuery">
          <template v-slot:inner-prefix>
            <AppIcon :custom-class="''" :name="'search'"></AppIcon>
          </template>
          <!-- <template v-slot:inner-suffix>
            <AppIcon :custom-class="''" :name="'filter-icon'"></AppIcon>
          </template> -->
        </AppTextField>
      </div>
      <!-- Loading -->
      <div
        class="w-full flex flex-col items-center justify-center h-[300px]"
        v-if="isLoading"
      >
        <svg
          :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>

      <template v-else>
        <!-- Products -->
        <div class="w-full grid grid-cols-2 gap-3">
          <template v-if="products.length > 0">
            <div
              v-for="(product, index) in products"
              :key="index"
              :class="`col-span-1 flex flex-col px-2 py-2 ${
                product.imported ? '!opacity-[60%]' : ''
              }   ${
                selectedProducts.includes(product.id)
                  ? '!border-primary-400 border-[2px]'
                  : '!border-grey-100 dark:!border-gray-800 border-[1px]'
              } rounded-[8px] cursor-pointer`"
              @click="
                !product.imported
                  ? selectedProducts.includes(product.id)
                    ? selectedProducts.splice(selectedProducts.indexOf(product.id), 1)
                    : selectedProducts.push(product.id)
                  : null
              "
            >
              <div :class="`w-full flex flex-col space-y-2  cursor-pointer`">
                <app-image-loader
                  :photoUrl="product.base_image_url"
                  class="!h-[130px] mdlg:!h-[200px] w-full relative rounded-[8px] border-[1px] border-grey-200 dark:border-gray-800"
                />

                <div class="w-full flex flex-col space-y-1 pb-2">
                  <app-normal-text class="!font-semibold text-left !line-clamp-1">
                    {{ product.title }}
                  </app-normal-text>

                  <div class="w-full flex flex-row items-center justify-between">
                    <app-normal-text class="text-left">
                      {{ Logic.Common.convertToMoney(product.price, false, "ngn") }}
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>

            <!-- Skeleton -->
            <template v-if="backgroundLoading">
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
              <div class="col-span-1 flex flex-col">
                <div
                  class="!h-[170px] mdlg:!h-[230px] w-full skeleton !rounded-[10px]"
                ></div>
              </div>
            </template>
          </template>

          <div
            v-else
            class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
          >
            <app-empty-state
              :title="'No Products Here'"
              :subTitle="'All available products will show up here'"
            >
            </app-empty-state>
          </div>
        </div>
      </template>

      <!-- Spacer -->
      <div class="h-[40px] w-full z-50" :id="`bottomAnchorWoocommerce-${uniqueId}`"></div>

      <!-- Bottom button -->
      <div
        class="w-full flex flex-col space-y-2 py-4 bg-white dark:bg-black sticky bottom-0"
      >
        <app-normal-text class="w-full !text-center">
          Maximum Import at a time is
          <span class="!font-semibold">10 products</span>
        </app-normal-text>
        <app-button
          class="w-full !py-3"
          @click.prevent="importProducts()"
          :loading="loadingImport"
        >
          Import Product{{ uniqueSelectedProducts.length > 1 ? "s" : "" }} ({{
            uniqueSelectedProducts.length
          }})
        </app-button>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import { FormValidations, Logic } from "../../composable";
import { computed, defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import AppSwitch from "../AppSwitch";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { AppTextField } from "../AppForm";
import AppProduct from "../AppProduct";
import AppEmptyState from "../AppEmptyState";
import AppButton from "../AppButton";
import AppGridImages from "../AppGridImages";
import AppImageLoader from "../AppImageLoader";
import { WoocommerceProduct } from "../../gql/graphql";

interface BaseProduct {
  base_image_url: string;
  title: string;
  price: number;
  id: number;
  imported: boolean;
}

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppSwitch,
    AppTextField,
    AppProduct,
    AppEmptyState,
    AppButton,
    AppImageLoader,
    AppGridImages,
  },
  props: {
    data: {
      type: Object as () => {
        selected: {
          id: string;
          image_url: string;
          title: string;
          price: number;
        }[];
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const isSetupStage = ref(false);

    const woocommerceUrl = ref("");

    const uniqueId = Logic.Common.makeid(16);
    const loadingImport = ref(false);
    const isConnecting = ref(false);

    const currentPage = ref("1");
    const totalPages = ref("1");

    const ManyWoocommerceProducts = ref(Logic.Shoplist.ManyWoocommerceProducts);

    const isLoading = ref(false);
    const backgroundLoading = ref(false);

    const selectedProducts = reactive<number[]>([]);

    const products = reactive<BaseProduct[]>([]);

    const getSingleProducts = (product: WoocommerceProduct) => {
      const mediaCount = product.images?.length || 0;

      if (mediaCount > 0) {
        return {
          id: parseInt(product.id),
          title: product.name,
          base_image_url: product.images[0]?.src || "",
          price: parseFloat(product.price || "0"),
          imported: product.imported,
        };
      }

      return null;
    };

    const setProducts = () => {
      products.length = 0;

      // For products
      ManyWoocommerceProducts.value?.products?.forEach((product) => {
        if (product) {
          const productData = getSingleProducts(product);

          if (productData) {
            products.push(productData);
          }
        }
      });
    };

    const getWoocommerceProducts = () => {
      isLoading.value = true;

      Promise.all([
        Logic.Shoplist.GetWoocommerceProducts("1", 10, false, searchQuery.value),
      ])
        .then(([products]) => {
          // Products
          isLoading.value = false;
          currentPage.value = "1";
          totalPages.value = products?.total_pages || "1";
        })
        .catch(() => {
          isLoading.value = false;
        });
    };

    const importProducts = () => {
      if (uniqueSelectedProducts.value.length == 0) {
        Logic.Common.showAlert({
          show: true,
          message: "Please select at least one product",
          type: "error",
        });
        return;
      }
      if (uniqueSelectedProducts.value.length > 10) {
        Logic.Common.showAlert({
          show: true,
          message: "Maximum Import at a time is 10 products",
          type: "error",
        });
        return;
      }

      loadingImport.value = true;

      Logic.Shoplist.ImportWooCommerceProducts(uniqueSelectedProducts.value)
        ?.then((response) => {
          if (response) {
            Logic.Shoplist.GetShoplistProducts(
              1,
              15,
              "CREATED_AT",
              "DESC",
              `{
                        column: BUSINESS_PROFILE_ID
                        operator: EQ
                        value: ${Logic.Auth.GetCurrentBusiness()?.id}
                      }`
            ).then(() => {
              Logic.Common.showAlert({
                show: true,
                message: "Products Imported Successfully",
                type: "success",
              });

              Logic.Common.showModal({
                show: false,
              });
            });

            loadingImport.value = false;
          } else {
            loadingImport.value = false;
          }
        })
        .catch(() => {
          loadingImport.value = false;
          Logic.Common.showAlert({
            show: true,
            message: "Oops! An error occurred",
            type: "error",
          });
        });
    };

    const uniqueSelectedProducts = computed(() => {
      return selectedProducts.filter((item, index, self) => self.indexOf(item) === index);
    });

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        getWoocommerceProducts();
      }, 400);
    });

    watch([ManyWoocommerceProducts], () => {
      setProducts();
    });

    const onScrolledToEnd = () => {
      // Fetch more

      if (backgroundLoading.value) {
        return;
      }

      if (isLoading.value) {
        return;
      }

      // For products

      if (currentPage.value < totalPages.value) {
        backgroundLoading.value = true;
        Logic.Shoplist.GetWoocommerceProducts(
          currentPage.value,
          10,
          true,
          searchQuery.value
        )
          .then((response) => {
            const existingProducts = JSON.parse(
              JSON.stringify(ManyWoocommerceProducts.value)
            );

            const newProducts = response?.products || [];

            existingProducts.products.push(...newProducts);
            existingProducts.total_pages = response?.total_pages || "1";

            Logic.Shoplist.ManyWoocommerceProducts = existingProducts;
            backgroundLoading.value = false;
          })
          .catch(() => {
            backgroundLoading.value = false;
          });
      }
    };

    const connectWoocommerce = () => {
      if (
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi.test(
          woocommerceUrl.value
        )
      ) {
        // remove trailing slash
        woocommerceUrl.value = woocommerceUrl.value.replace(/\/$/, "");

        isConnecting.value = true;
        Logic.Shoplist.GetWoocommerceAuthUrl(woocommerceUrl.value)
          .then((response) => {
            if (response) {
              isConnecting.value = false;
              window.open(response, "_blank");
              Logic.Common.showModal({
                show: false,
              });
            }
          })
          .catch(() => {
            isConnecting.value = false;
          });
      }
    };

    const createObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        rootMargin: "0px",
        threshold: 0.1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `bottomAnchorWoocommerce-${uniqueId}`
      );

      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    onMounted(() => {
      Logic.Shoplist.watchProperty("ManyWoocommerceProducts", ManyWoocommerceProducts);

      setTimeout(() => {
        createObserver();
      }, 1000);

      const currentBusiness = Logic.Auth.GetCurrentBusiness();

      const connectedAccounts = currentBusiness?.connected_accounts || [];

      if (!connectedAccounts.includes("woocommerce")) {
        isSetupStage.value = true;
      }

      if (!isSetupStage.value) {
        getWoocommerceProducts();
      }
    });

    return {
      searchQuery,
      products,
      isLoading,
      selectedProducts,
      Logic,
      uniqueId,
      backgroundLoading,
      uniqueSelectedProducts,
      loadingImport,
      isSetupStage,
      woocommerceUrl,
      FormValidations,
      isConnecting,
      connectWoocommerce,
      importProducts,
    };
  },
});
</script>
