<template>
  <app-wrapper :class="'!overflow-y-hidden'">
    <auth-layout
      @button-clicked="Logic.Common.GoToRoute('/auth/login')"
      @go-back="Logic.Common.goBack()"
      :go-back="true"
      :right-button="false"
      :hasBottomBorder="false"
    >
      <div class="w-full flex flex-col space-y-[1px]">
        <div class="w-full relative h-[8px] bg-gray-100 rounded-full">
          <div
            class="absolute top-0 left-0 w-[98%] h-[8px] bg-primary-500 rounded-full"
          ></div>
        </div>
        <div class="w-full flex flex-row justify-end">
          <app-normal-text class="!text-grey-500 dark:!text-grey-400">
            98%
          </app-normal-text>
        </div>
      </div>

      <div class="w-full flex flex-col pt-4 space-y-4">
        <div class="w-full flex flex-col">
          <app-header-text class="!text-left pb-4">
            Finally, a little about you.
          </app-header-text>

          <div class="w-full flex flex-col space-y-4">
            <app-text-field
              :focus-border="'border-primary-400'"
              :has-title="true"
              :custom-class="'!bg-grey-50'"
              :placeholder="'Select date'"
              v-model="aboutForm.date_of_birth"
              name="Date of birth"
              type="date"
              ref="dob"
              required
              :rules="[
                FormValidations.RequiredRule,
                FormValidations.customValidator(
                  mustBe18OrAbove(aboutForm.date_of_birth),
                  `You must be 18 years old or above`
                ),
              ]"
            >
              <template v-slot:title> Your date of birth </template>
            </app-text-field>

            <app-select
              :placeholder="'Select your gender'"
              :hasTitle="true"
              :paddings="'py-3 px-4'"
              :options="genderOption"
              required
              ref="gender"
              v-model="aboutForm.gender"
            >
              <template v-slot:title> Your gender </template>
            </app-select>
          </div>
        </div>

        <div
          class="w-full flex-col flex sticky bottom-0 pt-4 bg-white dark:bg-black"
          style="
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
        >
          <AppButton
            :padding="'py-3'"
            :custom-class="`rounded-[40px] ${
              aboutForm.date_of_birth && aboutForm.gender
                ? 'opacity-100'
                : 'opacity-50'
            }`"
            :bg-color="'bg-primary-main'"
            :text-color="'text-white'"
            @click="continueButtonClicked"
          >
            Continue
          </AppButton>
        </div>
      </div>
    </auth-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppHeaderText,
  AppButton,
  AppSelect,
  AppTextField,
  FormValidations,
} from "@shpt/ui-components";
import { SelectOption } from "@shpt/ui-components/src/types";
import { Geolocation } from "@capacitor/geolocation";

export default defineComponent({
  components: {
    AppWrapper,
    AppNormalText,
    AppHeaderText,
    AppButton,
    AppSelect,
    AppTextField,
  },
  name: "OnboardingAboutPage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "About You Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "About You",
    });

    localStorage.setItem("user_onboarded", "true");

    const aboutForm = reactive({
      date_of_birth: "",
      gender: "",
      location: {
        lat: 6.465422,
        lng: 3.406448,
      },
    });

    const genderOption = reactive<SelectOption[]>([
      {
        key: "male",
        value: "Male",
      },
      {
        key: "female",
        value: "Female",
      },
    ]);

    const saveProfileDetails = () => {
      Logic.User.UpdateProfileForm = {
        base_location: aboutForm.location,
        date_of_birth: aboutForm.date_of_birth,
        gender: aboutForm.gender,
        user_interests: JSON.parse(
          localStorage.getItem("user_interests") || "[]"
        ),
        spend_group: localStorage.getItem("spend_group") || "",
        savings_target_amount:
          localStorage.getItem("user_savings_target") || "",
      };

      Logic.User.UpdateProfile()?.then(async (data) => {
        if (data) {
          Logic.Common.hideLoader();
          Logic.Common.GoToRoute("/");
        }
      });
    };

    const askForNotification = () => {
      Logic.Common.showModal({
        show: true,
        title: "Get Notified",
        type: "ask_for_permission",
        extraData: {
          info: "Be the first to know about top cashback deals and rewards",
          cta_copy: "Enable Notification",
          icon: "notification-badge",
        },
        action: async () => {
          try {
            await Logic.Notification.registerNotifications();
          } catch (error) {
            //
          }

          Logic.Common.showModal({ show: false });
          saveProfileDetails();
        },
      });
    };

    const askForLocation = () => {
      Logic.Common.showModal({
        show: true,
        title: "Location Based Rewards",
        type: "ask_for_permission",
        extraData: {
          info: "Shoppoint uses your location to show you relevant cashback rewards and deals.",
          cta_copy: "Allow Location Access",
          icon: "location-badge",
        },
        action: async () => {
          try {
            await getCurrentPosition();
          } catch (error) {
            //
          }
          if (Logic.Common.currentBuildType() == "mobile") {
            askForNotification();
          } else {
            Logic.Common.showModal({ show: false });
            saveProfileDetails();
          }
        },
      });
    };

    const mustBe18OrAbove = (date: string) => {
      const yearDifference = Logic.Common.momentInstance(new Date()).diff(
        Logic.Common.momentInstance(date),
        "years"
      );

      return yearDifference > 17;
    };

    const getCurrentPosition = async () => {
      const coordinates = await Geolocation.getCurrentPosition();

      if (coordinates.coords) {
        aboutForm.location.lat = coordinates.coords.latitude;
        aboutForm.location.lng = coordinates.coords.longitude;
      }

      localStorage.setItem("user_coord", JSON.stringify(aboutForm.location));
    };

    const continueButtonClicked = () => {
      askForLocation();
    };

    return {
      Logic,
      aboutForm,
      genderOption,
      FormValidations,
      mustBe18OrAbove,
      continueButtonClicked,
    };
  },
});
</script>
