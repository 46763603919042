import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!

  return (_openBlock(), _createBlock(_component_cropper, {
    class: "cropper",
    src: _ctx.image_url,
    "stencil-props": {
      aspectRatio: _ctx.aspectRatio,
    },
    onChange: _ctx.onChange
  }, null, 8, ["src", "stencil-props", "onChange"]))
}