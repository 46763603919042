<template>
  <div
    :class="`w-full h-full flex flex-col ${
      title != 'clear' ? ' pt-1 pb-4' : ''
    }  lg:text-sm mdlg:text-[12px] text-xs !bg-transparent overflow-y-hidden !font-inter`"
    :style="
      title != 'clear'
        ? `
          padding-top: calc(env(safe-area-inset-top) + 8px) !important;
        padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;`
        : ''
    "
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { Logic } from "@shpt/logic";
import { defineComponent } from "vue";
import { topBarTitle } from "../../composable";

export default defineComponent({
  components: {},
  name: "OnboardingLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const router = Logic.Common.router;

    const goToRoute = (route: string) => {
      router?.push(route);
    };

    const route = Logic.Common.route;

    const ignoreBackRoute = route?.query.ignoreBackRoute
      ? route?.query.ignoreBackRoute.toString()
      : null;

    const goBack = () => {
      const routeMiddlewares: any = route?.meta.middlewares;
      const goBackRoute = routeMiddlewares?.goBackRoute;
      if (typeof goBackRoute == "function" && !ignoreBackRoute) {
        goToRoute(goBackRoute());
      } else if (typeof goBackRoute == "string" && !ignoreBackRoute) {
        goToRoute(goBackRoute);
      } else {
        window.history.length > 1 ? router?.go(-1) : router?.push("/");
      }
    };

    return {
      goBack,
      goToRoute,
      topBarTitle,
    };
  },
});
</script>
