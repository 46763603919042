import { CombinedError } from 'urql'
import {
  AdDemography,
  Advert,
  AdvertPaginator,
  MutationCreateAdvertArgs,
  MutationCreateOrUpdateAdvertPollArgs,
  MutationSaveMetricArgs,
} from './../../gql/graphql'
import Common from './Common'
import { $api } from '../../services'
import { Logic } from '..'

export default class Ad extends Common {
  // Default variables
  public ManyAdDemographies: AdDemography[] | undefined
  public ManyAdverts: AdvertPaginator | undefined
  public SingleAdvert: Advert | undefined

  // Mutation forms
  public CreateAdvertForm: MutationCreateAdvertArgs | undefined
  public CreateOrUpdateAdvertPollForm:
    | MutationCreateOrUpdateAdvertPollArgs
    | undefined

  // Queries
  public GetAdDemographies = () => {
    return $api.ad
      .GetAdDemographies()
      .then(async (response) => {
        this.ManyAdDemographies = response.data?.GetAdDemographies

        return response.data?.GetAdDemographies
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetAdverts = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    isSearch = false,
  ) => {
    return $api.ad
      .GetAdverts(page, count, orderType, order, whereQuery)
      .then(async (response) => {
        if (!isSearch) {
          this.ManyAdverts = response.data?.GetAdverts
        }
        return response.data?.GetAdverts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetSingleAdvert = (uuid: string) => {
    return $api.ad
      .GetSingleAdvert(uuid)
      .then(async (response) => {
        this.SingleAdvert = response.data?.GetSingleAdvert
        return response.data?.GetSingleAdvert
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  // Mutations

  public CreateAdvert = (progressCb: (progress: number) => void) => {
    if (this.CreateAdvertForm) {
      return $api.ad
        .CreateAdvert(this.CreateAdvertForm, progressCb)
        .then((response) => {
          this.SingleAdvert = response.data?.CreateAdvert
          return response.data?.CreateAdvert
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CreateOrUpdateAdvertPoll = () => {
    if (this.CreateOrUpdateAdvertPollForm) {
      return $api.ad
        .CreateOrUpdateAdvertPoll(this.CreateOrUpdateAdvertPollForm)
        .then((response) => {
          return response.data?.CreateOrUpdateAdvertPoll
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public SaveMetric = (data: MutationSaveMetricArgs) => {
    return $api.ad.SaveMetric(data)
  }

  public SubscribeToAdvertPoll = (
    advert_uuid: string,
    handleSubscribed: Function,
  ) => {
    Logic.Common.laravelEcho
      ?.private(`pollVote.${advert_uuid}`)
      .listen('NewPollVote', (data: any) => {
        handleSubscribed(data)
      })
  }
}
