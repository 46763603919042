<template>
  <app-form-wrapper
    ref="formComp"
    :parentRefs="parentRefs"
    class="w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0 relative"
  >
    <!-- Enter Email -->
    <app-select
      v-if="formData.status"
      :placeholder="'Select status'"
      v-model="formData.status"
      :hasTitle="true"
      :paddings="'py-3 px-4'"
      :options="statusOptions"
      ref="admin_status"
    >
      <template v-slot:title> Account Status </template>
    </app-select>

    <div class="w-full flex flex-col space-y-2">
      <app-normal-text class="w-full text-left !font-semibold">
        Permissions
      </app-normal-text>

      <div class="w-full flex flex-col space-y-2">
        <div
          v-for="(item, index) in formData.permissions"
          :key="index"
          class="w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
        >
          <template v-if="index != '__typename'">
            <app-checkbox
              :standard="true"
              v-model="formData.permissions[index]"
              class="w-full"
            >
              <app-normal-text class="text-left">
                {{ index == "manage_all_orders" ? "Manage all orders" : "" }}
                {{
                  index == "manage_assigned_branch_orders"
                    ? "Manage only assigned orders"
                    : ""
                }}
                {{
                  index == "manage_offline_payments"
                    ? "Manage offline payment"
                    : ""
                }}
                {{ index == "manage_products" ? "Manage store products" : "" }}
                {{ index == "manage_profile" ? "Manage store profile" : "" }}
                {{ index == "manage_shoplists" ? "Manage offers" : "" }}
                {{ index == "manage_promotions" ? "Manage promotions" : "" }}
                {{
                  index == "manage_store_branches"
                    ? "Manage store branches"
                    : ""
                }}
                {{
                  index == "manage_settlement_accounts"
                    ? "Manage settlement accounts"
                    : ""
                }}
              </app-normal-text>
            </app-checkbox>
          </template>
        </div>
      </div>
    </div>

    <div class="w-full flex flex-col sticky bottom-0 left-0">
      <app-button class="w-full py-3" @click.prevent="validateForm">
        Update
      </app-button>
    </div>
  </app-form-wrapper>
</template>
<script lang="ts">
import { StorePermissions } from "../../gql/graphql";
import { defineComponent, onMounted, reactive, ref } from "vue";
import { Logic } from "../../composable";
import AppButton from "../AppButton";
import { AppFormWrapper, AppSelect, AppCheckbox } from "../AppForm";
import { AppNormalText } from "../AppTypography";
import { SelectOption } from "../../types";

export default defineComponent({
  props: {
    action: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object as () => {
        permissions: StorePermissions;
        status: string;
      },
    },
  },
  components: {
    AppSelect,
    AppFormWrapper,
    AppButton,
    AppNormalText,
    AppCheckbox,
  },
  name: "AppModalStoreAdminUpdate",
  setup(props) {
    const FormValidations = Logic.Form;

    const formData = reactive<{
      status: string;
      permissions: StorePermissions | undefined;
    }>({
      status: "",
      permissions: undefined,
    });

    const statusOptions = reactive<SelectOption[]>([
      {
        key: "active",
        value: "Active",
      },
      {
        key: "inactive",
        value: "Inactive",
      },
    ]);

    const admin_status = ref();

    const parentRefs = ref<any[]>([]);
    const formComp = ref();

    onMounted(() => {
      parentRefs.value.length = 0;
      parentRefs.value.push(admin_status.value);

      if (props.data) {
        formData.status = props.data.status;
        formData.permissions = props.data.permissions;
      }
    });

    const validateForm = () => {
      const status = formComp.value.validate();

      if (status) {
        if (props.action) {
          // Remove __typename from formData.permissions
          if (formData.permissions && "__typename" in formData.permissions) {
            delete formData.permissions?.__typename;
          }
          props.action(formData);
        }
      } else {
        return false;
      }
    };

    return {
      FormValidations,
      formComp,
      parentRefs,
      formData,
      admin_status,
      statusOptions,
      validateForm,
    };
  },
});
</script>
