<template>
  <div class="w-full flex flex-row rounded-[10px] bg-secondary-50">
    <div class="w-[103px]">
      <app-image-loader
        :photoUrl="data.image_url"
        class="!rounded-l-[10px] w-[100px] h-[100px]"
      />
    </div>
    <div class="flex flex-col space-y-2 justify-between w-full px-3 py-3">
      <div class="h-full flex flex-col justify-center">
        <app-normal-text
          class="w-full !font-semibold !text-primary-400 !line-clamp-2 !text-[12px] mdlg:!text-xs"
        >
          {{ data.title }}
        </app-normal-text>
      </div>

      <div
        class="w-full flex flex-row items-center border-t-[1px] border-secondary-300 pt-1 space-x-1"
      >
        <app-icon :name="'badge-game'" :customClass="'h-[13px]'" />
        <app-normal-text class="text-left !text-[12px] dark:!text-black">
          {{ Logic.Common.convertToMoney(data.points, false, "ngn") }} Cashback
        </app-normal-text>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent } from "vue";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppIcon,
  },
  props: {
    data: {
      type: Object as () => {
        image_url: string;
        title: string;
        points: string;
      },
    },
  },
  name: "AppChallenge",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
