import { CombinedError } from 'urql'
import { Logic } from '..'
import {
  AccountLevel,
  Badge,
  Challenge,
  GameProfile,
  LeaderboardUser,
  MutationSubmitChallengeEntryArgs,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'
import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

export default class Game extends Common {
  constructor() {
    super()

    this.Storage = new Storage({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    })

    this.Storage.create().then(() => {
      this.Storage.defineDriver(CordovaSQLiteDriver).then(async () => {
        this.ManyChallenges = (await this.Storage.get('current_challenges'))
          ? JSON.parse((await this.Storage.get('current_challenges')) || '{}')
          : undefined
      })
    })
  }

  // Default variables
  public ManyChallenges: Challenge[] | undefined
  public SingleChallenge: Challenge | undefined
  public Leaderboard: LeaderboardUser[] | undefined
  public ManyAccountLevels: AccountLevel[] | undefined
  public AuthUserGameProfile: GameProfile | undefined
  public BadgesToAcknowledge: number[] | undefined
  public BadgesToAcknowledgeData: Badge[] | undefined
  public Storage: Storage

  // mutation input variable
  public SubmitChallengeEntryForm: MutationSubmitChallengeEntryArgs | undefined

  // Query actions
  public GetChallenges = () => {
    return $api.game
      .GetChallenges()
      .then(async (response) => {
        this.ManyChallenges = response.data?.GetChallenges
        if (response.data?.GetChallenges) {
          await this.Storage.set(
            'current_challenges',
            JSON.stringify(response.data.GetChallenges),
          )
        }
        return response.data?.GetChallenges
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public CheckGameStatus = () => {
    return $api.game
      .CheckGameStatus()
      .then(async (response) => {
        this.BadgesToAcknowledge = response.data?.CheckGameStatus

        if (!this.AuthUserGameProfile) {
          await this.GetAuthUserGameProfile()
        }

        const badgesData = this.AuthUserGameProfile?.badges

        this.BadgesToAcknowledgeData = badgesData?.filter((badge) =>
          this.BadgesToAcknowledge?.includes(parseInt(badge.id)),
        )

        // show first badge
        if (this.BadgesToAcknowledgeData?.length) {
          Logic.Common.showModal({
            show: true,
            type: 'badge_info',
            extraData: {
              data: this.BadgesToAcknowledgeData[0],
              isWon: true,
            },
          })
        }

        return response.data?.CheckGameStatus
      })
      .catch(() => {
        // throw error
        // since this is a background process, we can ignore the error
      })
  }

  public SaveBadgeAcknowledged = (badge_id: number) => {
    return $api.game
      .SaveBadgeAcknowledged(badge_id)
      .then((response) => {
        if (response.data?.SaveBadgeAcknowledged) {
          this.BadgesToAcknowledge = this.BadgesToAcknowledge?.filter(
            (badge) => badge !== badge_id,
          )
          return true
        }
        return false
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetAuthUserGameProfile = () => {
    return $api.game
      .GetAuthUserGameProfile()
      .then((response) => {
        this.AuthUserGameProfile = response.data?.GetAuthUser
          ?.game_profile as GameProfile
        if (Logic.Auth.AuthUser) {
          Logic.Auth.AuthUser.game_profile = this.AuthUserGameProfile
          Logic.Auth.saveAuthUser()
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetAccountLevels = () => {
    return $api.game
      .GetAccountLevels()
      .then((response) => {
        this.ManyAccountLevels = response.data?.GetAccountLevels
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetChallenge = (uuid: string) => {
    return $api.game
      .GetChallenge(uuid)
      .then((response) => {
        this.SingleChallenge = response.data?.GetChallenge
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetChallengeLeaderboard = (challenge_uuid: string) => {
    return $api.game
      .GetChallengeLeaderboard(challenge_uuid)
      .then((response) => {
        this.Leaderboard = response.data?.GetChallengeLeaderboard
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  // Mutation actions
  public JoinChallenge = (challenge_id: string) => {
    Logic.Common.showLoader({
      loading: true,
    })

    return $api.game
      .JoinChallenge(challenge_id)
      .then((response) => {
        Logic.Common.hideLoader()
        Logic.User.SaveUserActivity('Join Challenge', 'action')
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Join Challenge`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public SubmitChallengeEntry = () => {
    if (this.SubmitChallengeEntryForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: true,
      })

      return $api.game
        .SubmitChallengeEntry(this.SubmitChallengeEntryForm)
        .then((response) => {
          if (response.data?.SubmitChallengeEntry) {
            Logic.Wallet.ReactToPointEarned(response.data.SubmitChallengeEntry)
          }
          Logic.User.SaveUserActivity('Submit Challenge Entry', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          this.GetChallenge(this.SubmitChallengeEntryForm?.challenge_uuid || '')
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Submit Challenge Entry`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public ClaimReward = (point_earned_uuid: string) => {
    Logic.Common.showLoader({
      loading: true,
      isInteractive: false,
      isAd: true,
    })

    return $api.game
      .ClaimReward(point_earned_uuid)
      .then((response) => {
        if (response.data?.ClaimReward) {
          //
          Logic.Common.hideLoader()
        }
        Logic.User.SaveUserActivity('Claim Reward', 'action')
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(`Failed - Claim Reward`, 'failed_action', {
          reason: error.graphQLErrors[0].message,
        })
      })
  }

  public UpdateProcessingTime = (point_earned_uuid: string) => {
    if (Logic.Wallet.SinglePointEarned) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.game
        .UpdateProcessingTime(point_earned_uuid)
        .then(async (response) => {
          if (response.data?.UpdateProcessingTime) {
            Logic.Common.showSuccess(
              `Hurray! Receipt processing time got reduced by ${
                response.data?.UpdateProcessingTime
              } hour${response.data?.UpdateProcessingTime > 1 ? 's' : ''}`,
              () => {
                Logic.Common.showModal({ show: false })
              },
              'Got it!',
            )
            await localStorage.setItem(
              `ad-next-time-${point_earned_uuid}`,
              Logic.Common.momentInstance(new Date()).add(3, 'hours').format(),
            )
            Logic.Wallet.GetSinglePointEarned(point_earned_uuid)
          }
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }
}
