import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 px-4" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_transaction = _resolveComponent("app-transaction")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, { title: 'All transactions' }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.loadingTransactions)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_app_loading_state, { class: "text-primary-500" })
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_app_virtual_scroller, {
              data: _ctx.transactions,
              pagination: _ctx.pagination,
              "fetch-more": _ctx.fetchMoreTransactions
            }, {
              "item-content": _withCtx(({ index, item }) => [
                (_openBlock(), _createBlock(_component_app_transaction, {
                  key: index,
                  data: item,
                  class: "cursor-pointer",
                  onClick: ($event: any) => (
                item.transaction_type == 'wallet'
                  ? _ctx.Logic.Common.GoToRoute(`/savings/transactions/${item.uuid}`)
                  : _ctx.Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
              )
                }, null, 8, ["data", "onClick"]))
              ]),
              "skeleton-loaders": _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("div", { class: "w-full flex flex-row space-x-3" }, [
                  _createElementVNode("div", { class: "w-[30px] h-[30px] rounded-full skeleton" }),
                  _createElementVNode("div", { class: "flex flex-col space-y-2 w-full" }, [
                    _createElementVNode("div", { class: "w-[40%] h-[10px] rounded skeleton" }),
                    _createElementVNode("div", { class: "w-full flex flex-col space-y-2 skeleton h-[20px] rounded-[10px]" })
                  ])
                ], -1)
              ])),
              _: 1
            }, 8, ["data", "pagination", "fetch-more"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}