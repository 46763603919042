import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col pb-4 mdlg:!pb-0 md:!pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'Name your store location',
        hasTitle: true,
        padding: 'py-3 px-4',
        name: "Title",
        ref: "title",
        modelValue: _ctx.formData.title,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.title) = $event)),
        rules: [_ctx.FormValidations.RequiredRule],
        updateValue: _ctx.formData.title,
        required: ""
      }, {
        title: _withCtx(() => _cache[4] || (_cache[4] = [
          _createTextVNode("Title ")
        ])),
        _: 1
      }, 8, ["modelValue", "rules", "updateValue"]),
      _createVNode(_component_app_select, {
        placeholder: 'Select Store Admin',
        hasTitle: true,
        modelValue: _ctx.formData.business_admin_id,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.business_admin_id) = $event)),
        paddings: 'px-3 py-3',
        ref: "businessAdmin",
        customClass: 'dark:!bg-grey-800',
        options: _ctx.data.businessAdminOptions,
        theme: _ctx.Logic.Common.currentAppTheme(),
        autoComplete: "",
        required: ""
      }, {
        title: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" Store Admin ")
        ])),
        _: 1
      }, 8, ["modelValue", "options", "theme"]),
      (!_ctx.data.is_edit)
        ? (_openBlock(), _createBlock(_component_app_select, {
            key: 0,
            placeholder: 'Select area',
            hasTitle: true,
            modelValue: _ctx.formData.address,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.address) = $event)),
            paddings: 'px-3 py-3',
            ref: "deliveryAddress",
            onOnSearch: _ctx.handleAreaSearch,
            options: _ctx.areaOptions,
            theme: _ctx.Logic.Common.currentAppTheme(),
            customClass: 'dark:!bg-grey-800',
            autoComplete: "",
            hasSearch: true,
            searchMessage: 'Search for your pick address',
            searchIsLoading: _ctx.areaSearchIsLoading,
            required: ""
          }, {
            title: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode(" Contact Address ")
            ])),
            _: 1
          }, 8, ["modelValue", "onOnSearch", "options", "theme", "searchIsLoading"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_button, {
          class: _normalizeClass(`w-full py-3 ${_ctx.isValid ? '' : 'opacity-50'}`),
          onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.validateForm(false)), ["prevent"]))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.data.is_edit ? "Update" : "Add"), 1)
          ]),
          _: 1
        }, 8, ["class"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}