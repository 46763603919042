<template>
  <app-wrapper>
    <app-privacy-policy-page />
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import { AppPrivacyPolicyPage } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppPrivacyPolicyPage,
    AppWrapper,
  },
  name: "PrivacyPolicyPage",
  middlewares: {
    tracking_data: {
      lable: "Privacy Policy Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Privacy Policy",
    });

    return {};
  },
});
</script>
