import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col py-4 pt-3" }
const _hoisted_2 = { class: "w-full grid grid-cols-2 gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppBoxBotton = _resolveComponent("AppBoxBotton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modalList, (item, index) => {
        return (_openBlock(), _createBlock(_component_AppBoxBotton, {
          onClick: ($event: any) => {
          _ctx.Logic.Common.showModal({ show: false });
          _ctx.Logic.Common.GoToRoute(item.link);
        },
          icon: item.icon,
          text: item.text,
          "icon-size": item.icon_size,
          key: index
        }, null, 8, ["onClick", "icon", "text", "icon-size"]))
      }), 128))
    ])
  ]))
}