import { CombinedError } from 'urql'
import { Logic } from '..'
import {
  Beneficiary,
  BusinessDashboardSummary,
  MerchantCategory,
  MutationInitiateProductPaymentArgs,
  MutationMakeCommercePurchaseArgs,
  MutationProcessScannedReceiptArgs,
  MutationReportScannedReceiptArgs,
  MutationSendGiftCardArgs,
  MutationUpdateScannedReceiptArgs,
  MutationUpdateShoplistOrderStatusArgs,
  MutationValidatePaymentCustomerArgs,
  Order,
  OrderPaginator,
  PaginatorInfo,
  PointEarned,
  Product,
  ProductCategory,
  ProductPaginator,
  RecommendationResponse,
  ScannedReceiptResponse,
  SpendingCategory,
  Vendor,
} from '../../gql/graphql'
import { $api } from '../../services'
import Common from './Common'
import { Drivers, Storage } from '@ionic/storage'
import CordovaSQLiteDriver from 'localforage-cordovasqlitedriver'

export default class Shop extends Common {
  constructor() {
    super()

    this.Storage = new Storage({
      driverOrder: [
        CordovaSQLiteDriver._driver,
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    })

    this.Storage.create().then(() => {
      this.Storage.defineDriver(CordovaSQLiteDriver).then(async () => {
        this.TrendingProducts = (await this.Storage.get('trending_products'))
          ? JSON.parse((await this.Storage.get('trending_products')) || '{}')
          : undefined

        this.ShopData = (await this.Storage.get('shop_data'))
          ? JSON.parse(await this.Storage.get('shop_data'))
          : JSON.parse(
              (await this.Storage.get('shop_data')) ||
                JSON.stringify({
                  airtime: undefined,
                  cable_tv: undefined,
                  data: undefined,
                  electricity: undefined,
                  gift_cards: undefined,
                  betting: undefined,
                  merchants: undefined,
                  paginatorInfo: undefined,
                }),
            )
      })
    })
  }

  // Default variables
  public ShopData: {
    merchants: MerchantCategory[] | undefined
    airtime: Product[] | undefined
    data: Product[] | undefined
    cable_tv: Product[] | undefined
    electricity: Product[] | undefined
    betting: Product[] | undefined
    gift_cards: Product[] | undefined
    paginatorInfo: PaginatorInfo | undefined
  } = {
    airtime: undefined,
    cable_tv: undefined,
    data: undefined,
    electricity: undefined,
    gift_cards: undefined,
    betting: undefined,
    merchants: undefined,
    paginatorInfo: undefined,
  }
  public ManyProducts: ProductPaginator | undefined
  public SingleProduct: Product | undefined
  public ManyProductCategory: ProductCategory[] | undefined
  public ManyBeneficiary: Beneficiary[] | undefined
  public SingleScannedReceipt: ScannedReceiptResponse | undefined
  public TrendingProducts: Product[] | undefined
  public MerchantCategories: MerchantCategory[] | undefined
  public ReceiptTrials: number = 0
  public Storage: Storage
  public CurrentProductRecommendation: RecommendationResponse | undefined
  public SingleVendor: Vendor | undefined
  public VendorProducts: ProductPaginator | undefined
  public ManySpendingCategory: SpendingCategory[] | undefined
  public ManyExpenses: PointEarned[] | undefined
  public ManyUserOrders: Order[] | undefined
  public ManyBusinessOrders: OrderPaginator | undefined
  public BusinessDashboardSummary: BusinessDashboardSummary | undefined

  // Mutation input variables
  public ProcessScannedReceiptForm:
    | MutationProcessScannedReceiptArgs
    | undefined
  public InitiateProductPaymentForm:
    | MutationInitiateProductPaymentArgs
    | undefined
  public ValidatePaymentCustomerForm:
    | MutationValidatePaymentCustomerArgs
    | undefined
  public ReportScannedReceiptForm: MutationReportScannedReceiptArgs | undefined
  public SendGiftCardForm: MutationSendGiftCardArgs | undefined
  public UpdateScannedReceiptForm: MutationUpdateScannedReceiptArgs | undefined
  public MakeCommercePurchaseForm: MutationMakeCommercePurchaseArgs | undefined
  public UpdateOrderStatusForm:
    | MutationUpdateShoplistOrderStatusArgs
    | undefined

  // Query actions
  public GetProducts = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    hasProductCategoryQuery?: string,
    isSearch?: boolean,
  ) => {
    return $api.shop
      .GetProducts(
        page,
        count,
        orderType,
        order,
        whereQuery,
        hasProductCategoryQuery,
      )
      .then(async (response) => {
        if (isSearch) {
          //
        } else {
          this.ManyProducts = response.data?.GetProducts
          if (response.data?.GetProducts) {
            await this.Storage.set(
              'current_products',
              JSON.stringify(response.data.GetProducts),
            )
          }
        }

        return response.data?.GetProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public SearchProducts = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
    hasProductCategoryQuery?: string,
  ) => {
    return $api.shop
      .SearchProducts(
        page,
        count,
        orderType,
        order,
        whereQuery,
        hasProductCategoryQuery,
      )
      .then(async (response) => {
        return response.data?.SearchProducts
      })
  }

  public GetBusinessOrders = (
    business_profile_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
  ) => {
    return $api.shop
      .GetBusinessOrders(
        business_profile_id,
        page,
        count,
        orderType,
        order,
        whereQuery,
      )
      .then(async (response) => {
        this.ManyBusinessOrders = response.data?.GetBusinessOrders
        return response.data?.GetBusinessOrders
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetBusinessDashboardSummary = () => {
    return $api.shop
      .GetBusinessDashboardSummary()
      .then(async (response) => {
        this.BusinessDashboardSummary =
          response.data?.GetBusinessDashboardSummary
        return response.data?.GetBusinessDashboardSummary
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetTrendingProducts = () => {
    return $api.shop
      .GetTrendingProducts()
      .then(async (response) => {
        this.TrendingProducts = response.data?.TrendingProducts
        if (response.data?.TrendingProducts) {
          await this.Storage.set(
            'trending_products',
            JSON.stringify(response.data.TrendingProducts),
          )
        }
        return response.data?.TrendingProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserOrders = () => {
    return $api.shop
      .GetUserOrders()
      .then((response) => {
        this.ManyUserOrders = response.data?.GetOrders
        return response.data?.GetOrders
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetSpendingCategories = () => {
    return $api.shop
      .GetSpendingCategories()
      .then(async (response) => {
        this.ManySpendingCategory = response.data?.GetSpendingCategories

        return response.data?.GetSpendingCategories
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetManyExpenses = (
    type: 'all' | 'receipts' | 'bill_payments',
    start_date: string,
    end_date: string,
    override_slugs = [],
    page = 1,
    isUpdate = false,
  ) => {
    let whereQuery = ``

    if (type == 'all') {
      whereQuery = `{
      column: EVENT_SLUG,
      operator: IN,
      value: ["SCANNED_RECEIPT", "AIRTIME_PURCHASE", "DATA_PURCHASE", "CABLE_TV_PURCHASE", "ELECTRICITY_PURCHASE"],
      AND: {
        column: CREATED_AT,
        operator: BETWEEN,
        value: ["${start_date}", "${end_date}"]
      }
     } `
    } else if (type == 'receipts') {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: ["SCANNED_RECEIPT"]
        AND: {
          column: CREATED_AT,
          operator: BETWEEN,
          value: ["${start_date}", "${end_date}"]
        }
      }
      `
    } else if (type == 'bill_payments') {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: ["AIRTIME_PURCHASE", "DATA_PURCHASE", "CABLE_TV_PURCHASE", "ELECTRICITY_PURCHASE"]
        AND: {
          column: CREATED_AT,
          operator: BETWEEN,
          value: ["${start_date}", "${end_date}"]
        }
      }
      `
    }

    if (override_slugs.length) {
      let slugString = '['

      override_slugs.forEach((slug) => {
        slugString += `"${slug}",`
      })

      slugString += ']'

      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: ${slugString}
        AND: {
          column: CREATED_AT,
          operator: BETWEEN,
          value: ["${start_date}", "${end_date}"]
        }
      }
      `
    }

    return $api.wallet
      .GetPointEarned(
        page,
        10,
        'UPDATED_AT',
        'DESC',
        whereQuery,
        `
    {
      column: UUID,
      operator: EQ,
      value: "${Logic.Auth.AuthUser?.uuid}"
    }
    `,
      )
      .then((response) => {
        if (isUpdate) {
          return response.data?.GetPointEarned
        }
        this.ManyExpenses = response.data?.GetPointEarned.data
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetVendor = (merchant_name: string) => {
    return $api.shop
      .GetVendor(merchant_name)
      .then(async (response) => {
        this.SingleVendor = response.data?.GetVendor
        return response.data?.GetVendor
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetVendorProducts = (
    merchant_name: string,
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
  ) => {
    return $api.shop
      .GetAllProducts(
        page,
        count,
        orderType,
        order,
        `
      {
        column: MERCHANT_NAME,
        operator: EQ
        value: "${merchant_name}" 
      }
      `,
      )
      .then(async (response) => {
        this.VendorProducts = response.data?.GetAllProducts
        return response.data?.GetAllProducts
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetProductsByCategory = (slug: string, page = 1, isUpdate = false) => {
    return $api.shop
      .SearchProducts(
        page,
        8000,
        'CREATED_AT',
        'DESC',
        '',
        `
        {
          column: SLUG
          operator: EQ
          value: "${slug}"
        }
        `,
      )
      .then(async (response) => {
        const allProducts = response.data?.SearchProducts.data

        const ShopData = this.ShopData

        if (isUpdate) {
          return response.data?.SearchProducts
        }

        if (allProducts) {
          if (slug == 'airtime') {
            ShopData.airtime = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          if (slug == 'cable_tv') {
            ShopData.cable_tv = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          if (slug == 'data') {
            ShopData.data = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          if (slug == 'electricity') {
            ShopData.electricity = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          if (slug == 'gift_cards') {
            ShopData.gift_cards = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          if (slug == 'betting') {
            ShopData.betting = allProducts
            ShopData.paginatorInfo = response.data?.SearchProducts.paginatorInfo
          }

          this.ShopData = ShopData

          await this.Storage.set('shop_data', JSON.stringify(this.ShopData))
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetMerchantByCategories = () => {
    return $api.shop
      .GetMerchantByCategories()
      .then(async (response) => {
        this.MerchantCategories = response.data?.MerchantByCategories
        if (response.data?.MerchantByCategories) {
          const ShopData = this.ShopData
          ShopData.merchants = response.data?.MerchantByCategories
          this.ShopData = ShopData
          await this.Storage.set('shop_data', JSON.stringify(this.ShopData))
        }
        return response.data?.MerchantByCategories
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetProduct = (uuid: string) => {
    return $api.shop
      .GetProduct(uuid)
      .then((response) => {
        this.SingleProduct = response.data?.GetProduct
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetProductCategories = () => {
    return $api.shop
      .GetProductCategories()
      .then((response) => {
        this.ManyProductCategory = response.data?.GetProductCategories
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetProductRecommendations = (
    category: string,
    sub_category: string | undefined = undefined,
  ) => {
    return $api.shop
      .GetProductRecommendation({
        category,
        sub_category,
      })
      .then((response) => {
        this.CurrentProductRecommendation =
          response.data?.GetProductRecommendation
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetProductBeneficiaries = (product_category_uuid: string) => {
    return $api.shop
      .GetProductBeneficiaries(product_category_uuid)
      .then((response) => {
        this.ManyBeneficiary = response.data?.GetProductBeneficiaries
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  // Mutation actions

  public ValidatePaymentCustomer = () => {
    if (this.ValidatePaymentCustomerForm) {
      return $api.shop
        .ValidatePaymentCustomer(this.ValidatePaymentCustomerForm)
        .then((response) => {
          Logic.User.SaveUserActivity('Validate Payment Customer', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.User.SaveUserActivity(
            `Failed - Validate Payment Customer`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
          throw error
        })
    }
  }

  public MakeCommercePurchase = () => {
    if (this.MakeCommercePurchaseForm) {
      return $api.shop
        .MakeCommercePurchase(this.MakeCommercePurchaseForm)
        .then((response) => {
          return response.data?.MakeCommercePurchase
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          throw error
        })
    }
  }

  public UpdateScannedReceipt = () => {
    if (this.UpdateScannedReceiptForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: false,
      })
      return $api.shop
        .UpdateScannedReceipt(this.UpdateScannedReceiptForm)
        .then((response) => {
          Logic.User.SaveUserActivity('Update Snapped Receipt', 'action')
          Logic.Common.hideLoader()
          Logic.Common.showSuccess('Receipt Information Updated')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.User.SaveUserActivity(
            `Failed - Update Snapped Receipt`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public UpdateOrderStatus = () => {
    if (this.UpdateOrderStatusForm) {
      return $api.shop
        .UpdateOrderStatus(this.UpdateOrderStatusForm)
        .then((response) => {
          if (response.data?.UpdateShoplistOrderStatus) {
            Logic.Common.showAlert({
              show: true,
              message: 'Order status updated',
              type: 'success',
            })
          }
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showAlert({
            show: true,
            message: error.graphQLErrors[0].message,
            type: 'error',
          })
        })
    }
  }

  public ScanReciept = (image: Blob, reSnap: Function) => {
    Logic.Common.showLoader({
      loading: true,
      isInteractive: false,
      isAd: true,
      // message: 'Analysing your receipt...',
    })
    return $api.shop
      .ScanReciept(image)
      .then((response) => {
        Logic.Common.hideLoader()
        this.SingleScannedReceipt = response.data?.ScanReciept
        Logic.User.SaveUserActivity('Analyze Snap Receipt', 'action')
        return response.data
      })
      .catch((error: CombinedError) => {
        // Logic.Common.showError(error, 'Oops!', 'Retry')
        Logic.Common.hideLoader()
        Logic.Shop.ReceiptTrials += 1

        const buttons = [
          {
            label: 'Report receipt',
            action: () => {
              const extraData: any =
                error.graphQLErrors[0].extensions.extra_data
              this.ReportScannedReceiptForm = {
                report_type: 'OUTWRITE_ERROR',
                scanned_receipt_uuid: extraData['x-data'].receipt_uuid,
              }
              this.ReportScannedReceipt(true)
            },
          },
        ]

        if (Logic.Shop.ReceiptTrials < 2) {
          buttons.push({
            label: 'Try again',
            action: () => {
              reSnap()
              Logic.Common.showModal({ show: false })
            },
          })
        }

        Logic.Common.showModal({
          type: 'request_feedback',
          show: true,
          action: () => {
            Logic.Common.showModal({ show: false })
          },
          title: '',
          preventClose: true,
          closeAction: () => {
            Logic.Common.goBack()
          },
          extraData: {
            title: `${error.graphQLErrors[0].message}. If you believe this is wrong, report the receipt.`,
            icon: 'error-warning',
            buttons,
          },
        })

        Logic.User.SaveUserActivity(
          `Failed - Analyze Snap Receipt`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public ProcessScannedReceipt = () => {
    if (this.ProcessScannedReceiptForm) {
      if (Logic.Auth.AuthUser?.profile?.subscription_plan?.id == '1') {
        Logic.Common.showLoader({
          loading: true,
          isInteractive: false,
          isAd: true,
        })
      } else {
        Logic.Common.showLoader({
          loading: true,
          isInteractive: true,
        })
      }

      return $api.shop
        .ProcessScannedReceipt(this.ProcessScannedReceiptForm)
        .then((response) => {
          if (response.data?.ProcessScannedReceipt) {
            Logic.Wallet.ReactToPointEarned(response.data.ProcessScannedReceipt)
          }
          Logic.User.SaveUserActivity('Process Snapped Receipt', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Process Snapped Receipt`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
          throw error
        })
    }
  }

  public InitiateProductPayment = () => {
    if (this.InitiateProductPaymentForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: true,
      })
      return $api.shop
        .InitiateProductPayment(this.InitiateProductPaymentForm)
        .then((response) => {
          if (response.data?.InitiateProductPayment) {
            Logic.Wallet.ReactToPointEarned(
              response.data.InitiateProductPayment,
            )
          }
          Logic.User.SaveUserActivity('Initiate Product Payment', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Initiate Product Payment`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public ReportScannedReceipt = (goBack = false) => {
    if (this.ReportScannedReceiptForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: false,
      })
      return $api.shop
        .ReportScannedReceipt(this.ReportScannedReceiptForm)
        .then((response) => {
          Logic.Common.hideLoader()

          if (response.data?.ReportScannedReceipt) {
            Logic.Common.showSuccess(
              'Your report has been sent. You will get notified about the status of your report within the next 24 hours.',
              () => {
                if (goBack) {
                  Logic.Common.goBack()
                } else {
                  Logic.Common.showModal({ show: false })
                }
              },
            )
          }
          Logic.User.SaveUserActivity('Report Receipt Issue', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Report Receipt Issue`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public SendGiftCard = () => {
    if (this.SendGiftCardForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: false,
      })
      return $api.shop
        .SendGiftCard(this.SendGiftCardForm)
        .then((response) => {
          Logic.Common.hideLoader()
          if (response.data?.SendGiftCard) {
            Logic.Common.showAlert({
              show: true,
              message: `Gift card has been sent to ${this.SendGiftCardForm?.name}`,
              type: 'success',
            })
          }
          Logic.User.SaveUserActivity('Send Gift Card', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Send Gift Card`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UploadFile = (attachment: any, type = 'image') => {
    return $api.shop
      .UploadFile(attachment, type)
      .then((response) => {
        return response
      })
      .catch((error: CombinedError) => {
        throw error
      })
  }
}
