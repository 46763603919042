<template>
  <div class="w-full flex flex-col space-y-3 pt-2 relative">
    <app-normal-text
      :htmlContent="Logic.User.ClientAppContent?.terms_and_condition || ''"
    ></app-normal-text>

    <div class="h-[40px]"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
  },
  name: "ShptModalCardTermsAndConditions",
  setup() {
    const acceptCardTerms = () => {
      localStorage.setItem("accepted_card_terms", "true");
    };
    return {
      Logic,
      acceptCardTerms,
    };
  },
});
</script>
