<template>
  <app-wrapper>
    <plain-layout class="bg-white">
      <div class="w-full flex flex-col space-y-4">
        <!-- Top section -->
        <app-image-loader
          :photoUrl="'/images/purple-bg.svg'"
          class="w-full flex flex-col space-y-3 px-4 !overflow-y-visible sticky top-0"
          style="
            padding-top: calc(env(safe-area-inset-top) + 16px) !important;
            height: calc(env(safe-area-inset-top) + 135px) !important;
          "
        >
          <div class="w-full flex flex-row justify-between items-center">
            <app-icon
              :name="'back-purple-bg'"
              :customClass="'!h-[38px]'"
              @click="Logic.Common.goBack()"
            />

            <div class="flex flex-row space-x-3 items-center">
              <div
                class="flex flex-row items-center justify-centerspace-x-2 bg-primary-300 rounded-[50px] px-4 py-2 space-x-2 cursor-pointer"
                @click="Logic.Common.GoToRoute('/profile/edit')"
              >
                <app-normal-text class="!text-white"> Edit </app-normal-text>
                <app-icon :name="'edit-white'" class="" />
              </div>
              <div
                class="flex flex-row items-center justify-centerspace-x-2 bg-primary-300 rounded-[50px] px-4 py-2 space-x-2 cursor-pointer"
                @click="showDeleteAccount()"
              >
                <app-normal-text class="!text-white"> Delete </app-normal-text>
                <app-icon :name="'delete-white'" class="w-[15px]" />
              </div>
            </div>
          </div>

          <!-- Profile Image -->
          <div
            class="w-full flex flex-row items-center justify-center !mt-[22px]"
          >
            <app-image-loader
              :photoUrl="AuthUser?.profile?.photo_url || '/images/avatar-1.png'"
              class="w-[120px] h-[120px] rounded-full relative border-[3px] border-white"
            >
              <div
                class="absolute w-full flex items-center justify-center -bottom-[5%] left-0"
              >
                <div
                  class="bg-secondary-400 rounded-[40px] items-start justify-start px-2 py-[2px] border-[2px] border-white"
                >
                  <app-normal-text
                    class="text-center !text-white !text-[11px] !font-semibold"
                  >
                    {{
                      Logic.Common.convertToMoney(
                        AuthUser?.game_profile?.total_points,
                        false,
                        ""
                      )
                    }}
                    Pts
                  </app-normal-text>
                </div>
              </div>
            </app-image-loader>
          </div>
        </app-image-loader>

        <!-- User info -->
        <div
          class="w-full flex flex-col space-y-3 items-center justify-center pt-[55px]"
        >
          <div class="w-full flex flex-col items-center justify-center">
            <div class="flex flex-row space-x-1 items-center justify-center">
              <app-header-text class="!text-base">
                {{ AuthUser?.full_name }}
              </app-header-text>
              <app-icon
                v-if="
                  parseInt(AuthUser?.profile?.subscription_plan.id || '1') > 1
                "
                :name="`plan-${AuthUser?.profile?.subscription_plan.id}`"
                :custom-class="'h-[20px]'"
              />
            </div>
            <app-normal-text class="text-center !text-grey-700">
              @{{ AuthUser?.username }}
            </app-normal-text>
          </div>

          <!-- Badges and level -->
          <div class="w-full px-4 flex flex-col">
            <div
              class="w-full flex flex-row items-center justify-between px-2 py-2 rounded-[50px] bg-[#340163]"
            >
              <div class="flex flex-row items-center space-x-2">
                <app-image-loader
                  class="h-[25px] w-[25px]"
                  :photo-url="
                    AuthUser?.game_profile?.account_level.badge?.photo_url
                  "
                />
                <app-normal-text :color="'text-white'" class="!font-semibold">
                  {{ AuthUser?.game_profile?.account_level.title }}
                </app-normal-text>
              </div>

              <div
                class="flex flex-row items-center space-x-2 pr-1 cursor-pointer"
                @click="Logic.Common.GoToRoute('/others/levels')"
              >
                <app-normal-text :color="'!text-[#BA83E7]'">
                  View all levels
                </app-normal-text>
                <app-icon
                  :name="'chevron-right-purple'"
                  :customClass="'h-[8px]'"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- Badges and challenges -->
        <div class="w-full flex flex-col space-y-3">
          <!-- Earned badges -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text
              class="w-full !text-left !text-[#664D7E] px-4 !text-sm"
            >
              Earned Badges
            </app-normal-text>

            <div
              v-if="badges.filter((item) => item.won).length === 0"
              class="w-full flex flex-row items-center justify-center h-[150px]"
            >
              <app-empty-state :title="''" :subTitle="'No earned badges yet'" />
            </div>
            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
              v-else
            >
              <div class="flex flex-row space-x-3 py-2 pr-4">
                <div
                  :class="`flex flex-col space-y-2 justify-center items-center w-auto cursor-pointer`"
                  v-for="(badge, index) in badges.filter((item) => item.won)"
                  :key="index"
                  @click="showBadgeInfo(badge.data, badge.won)"
                >
                  <app-image-loader
                    :photoUrl="badge.image_url"
                    class="!h-[90px] !w-[90px] mdlg:!h-[100px] mdlg:!w-[100px] rounded-full"
                  />
                  <app-normal-text
                    class="!text-center line-clamp-1 !font-semibold !text-[11px] mdlg:!text-xs"
                  >
                    {{ badge.title }}
                  </app-normal-text>
                </div>
              </div>
            </div>
          </div>

          <!-- Challenges -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text
              class="w-full !text-left !text-[#664D7E] px-4 !text-sm"
            >
              Top Challenges
            </app-normal-text>

            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
            >
              <div class="flex flex-row space-x-3 py-2 pr-4">
                <div
                  v-for="(challenge, index) in challenges"
                  :key="index"
                  class="w-[280px] flex flex-col"
                >
                  <app-challenge
                    :data="challenge"
                    class="cursor-pointer"
                    @click="
                      Logic.Common.GoToRoute(
                        `/others/challenges/${challenge.uuid}`
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Badges to unlock -->
          <div class="w-full flex flex-col space-y-2">
            <app-normal-text
              class="w-full !text-left !text-[#664D7E] px-4 !text-sm"
            >
              Badges to unlock
            </app-normal-text>

            <div class="w-full grid grid-cols-3 gap-3 px-4 pt-2">
              <div
                v-for="(badge, index) in badges.filter((item) => !item.won)"
                :key="index"
                class="col-span-1 flex flex-col space-y-1 justify-center items-center cursor-pointer"
                @click="showBadgeInfo(badge.data, badge.won)"
              >
                <app-image-loader
                  :photoUrl="'/images/lock-badge.svg'"
                  class="!h-[75px] !w-[75px] mdlg:!h-[85px] mdlg:!w-[85px] rounded-full"
                />
                <app-normal-text
                  class="!text-center line-clamp-1 !font-semibold !text-[10px] mdlg:!text-[12px]"
                >
                  {{ badge.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[100px]"></div>
      </div>
    </plain-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import {
  AppIcon,
  AppImageLoader,
  AppNormalText,
  AppHeaderText,
  AppChallenge,
  AppEmptyState,
} from "@shpt/ui-components";
import {
  badgeIsWon,
  showBadgeInfo,
} from "@shpt/ui-components/src/composable/game";
import { Badge } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppImageLoader,
    AppNormalText,
    AppHeaderText,
    AppChallenge,
    AppEmptyState,
    AppWrapper,
  },
  name: "UserProfilePage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Game",
        property: "AuthUserGameProfile",
        method: "GetAuthUserGameProfile",
        params: [],
        requireAuth: true,
        silentUpdate: true,
        ignoreProperty: true,
      },
      {
        domain: "Game",
        property: "ManyChallenges",
        method: "GetChallenges",
        params: [],
        requireAuth: true,
        silentUpdate: true,
      },
    ],
    tracking_data: {
      lable: "User Profile Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Profile",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const showDeleteAccount = () => {
      Logic.Common.showModal({
        type: "request_feedback",
        show: true,
        action: () => {
          Logic.Common.showModal({ show: false });
        },
        title: "",
        extraData: {
          title: `We're sad to see you go. Are you sure you want to delete your account?`,
          icon: "error-warning",
          buttons: [
            {
              label: "No",
              action: () => {
                Logic.Common.showModal({ show: false });
              },
            },
            {
              label: "Yes",
              action: () => {
                Logic.User.DeleteUser();
                Logic.Common.showModal({ show: false });
              },
            },
          ],
        },
      });
    };

    const ManyChallenges = ref(Logic.Game.ManyChallenges);

    const badges = reactive<
      {
        title: string;
        image_url: string;
        won: boolean;
        data: Badge;
      }[]
    >([]);

    const challenges = reactive<
      {
        image_url: string;
        title: string;
        points: string;
        uuid: string;
      }[]
    >([]);

    const setChallenges = () => {
      challenges.length = 0;
      ManyChallenges.value?.forEach((item) => {
        if (item.photo_url) {
          challenges.push({
            uuid: item.uuid,
            title: item.title,
            image_url: item.photo_url || "",
            points: Logic.Common.convertToMoney(
              item.rule?.reward_amount || 0,
              false,
              ""
            ),
          });
        }
      });
    };

    const setBadges = () => {
      badges.length = 0;
      AuthUser.value?.game_profile?.pending_badges?.forEach((item) => {
        badges.push({
          title: item.title,
          image_url: badgeIsWon(item.id)
            ? item.photo_url
            : "/images/badges/no-badge.svg",
          won: badgeIsWon(item.id),
          data: item,
        });
      });

      badges.sort((a, b) => {
        return a.won === b.won ? 0 : a.won ? -1 : 1;
      });
    };

    watch([ManyChallenges, AuthUser], () => {
      setBadges();
      setChallenges();
    });

    onMounted(() => {
      // Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Game.watchProperty("ManyChallenges", ManyChallenges);
      setBadges();
      setChallenges();
    });

    return {
      challenges,
      Logic,
      badges,
      AuthUser,
      showDeleteAccount,
      showBadgeInfo,
    };
  },
});
</script>
