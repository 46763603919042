import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 relative" }
const _hoisted_2 = {
  key: 0,
  class: "w-full flex flex-row items-start justify-start"
}
const _hoisted_3 = { class: "px-3 py-1 bg-gray_50 rounded-[22px]" }
const _hoisted_4 = {
  key: 1,
  class: "w-full flex flex-col pt-3 sticky bottom-0 left-0 pb-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.data.is_current)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_app_normal_text, {
              color: '!text-shop_dark_400',
              customClass: '!text-[10px] !uppercase'
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Your current tier ")
              ])),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_app_normal_text, { "custom-class": '!text-sm' }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.price
          ? `${_ctx.Logic.Common.convertToMoney(
              _ctx.data.price,
              false,
              "",
              false,
              "NGN "
            )}/Month`
          : "Free"), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(`w-full flex flex-col items-start rounded-[16px] px-4 py-4 ${_ctx.data.bg_color} bg-opacity-10`)
    }, [
      _createVNode(_component_app_normal_text, {
        customClass: 'text-left',
        htmlContent: _ctx.data.description
      }, null, 8, ["htmlContent"])
    ], 2),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h-[40px]" }, null, -1)),
    (!_ctx.data.is_current)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            customClass: 'w-full',
            padding: 'py-3',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.action()))
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode(" Subscribe ")
            ])),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}