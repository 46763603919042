import {
  HmoHospital,
  InsuranceClaim,
  InsurancePolicy,
  InsuranceProduct,
} from '../gql/graphql'
import { BaseApiService } from './common/BaseService'
import { OperationResult } from 'urql'

export default class InsuranceApi extends BaseApiService {
  public GetInsuranceProducts = () => {
    const requestData = `
        query GetInsuranceProducts {
            GetInsuranceProducts {
              id
              name
              key_benefits
              full_benefits_html
              full_benefits_json {
                name
                description
              }
              description
              prefix
              renewable
              claimable
              certificateable
              inspectable
              is_dynamic_pricing
              price
              cover_period
              active
              how_it_works
              how_to_claim
              business_how_it_works
              business_how_to_claim
              document
              created_at
              product_category_id
              provider_id
              provider {
                company_name
              }
              productCategory {
                name
              }
            }
          }
                `

    const response: Promise<OperationResult<{
      GetInsuranceProducts: InsuranceProduct[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetSingleInsuranceProducts = (product_id: string) => {
    const requestData = `
    query GetSingleInsuranceProducts($product_id: String!) {
        GetSingleInsuranceProducts(product_id: $product_id) {
          id
          name
          key_benefits
          full_benefits_html
          full_benefits_json {
            name
            description
          }
          description
          prefix
          renewable
          claimable
          certificateable
          inspectable
          is_dynamic_pricing
          price
          cover_period
          active
          how_it_works
          how_to_claim
          business_how_it_works
          business_how_to_claim
          document
          created_at
          product_category_id
          provider_id
          provider {
            company_name
          }
          productCategory {
            name
          }
        }
      }
                `

    const response: Promise<OperationResult<{
      GetSingleInsuranceProducts: InsuranceProduct
    }>> = this.query(requestData, { product_id })

    return response
  }

  public GetCustomerClaims = (customer_id: string) => {
    const requestData = `
    query GetCustomerClaims($customer_id: String!) {
        GetCustomerClaims(customer_id: $customer_id) {
          id
          app_mode
          claim_description
          claim_owner
          claim_owner_first_name
          claim_owner_last_name
          decline_reason
          claim_owner_email
          claim_owner_phone_number
          vehicle_claim_meta
          gadget_claim_meta {
            date
            time
            address
            bank_code
            bank_name
            back_image
            side_image
            description
            front_image
            account_name
            incident_type
            police_report
            policy_number
            account_number
            cost_of_repair
            repair_receipt
            payment_receipt
            status_time_line {
              name
              time_stamp
            }
          }
          claim_type
          claim_amount
          estimate_amount
          offer_amount
          betterment_deduction_amount
          excess_deduction_amount
          incident_date
          claim_status
          claim_invoice_url
          offer_form_url
          customer_id
          policy_id
          created_at
          updated_at
        }
      }
                `

    const response: Promise<OperationResult<{
      GetCustomerClaims: InsuranceClaim[]
    }>> = this.query(requestData, { customer_id })

    return response
  }

  public GetHMOHospitals = () => {
    const requestData = `
    query GetHMOHospitals {
        GetHMOHospitals {
          name
          location
          address
          type
        }
      }
                `

    const response: Promise<OperationResult<{
      GetHMOHospitals: HmoHospital[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetColorLists = () => {
    const requestData = `
    query GetColorLists {
        GetColorLists 
      }
                `

    const response: Promise<OperationResult<{
      GetColorLists: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetVehicleTypes = () => {
    const requestData = `
    query GetVehicleTypes {
        GetVehicleTypes 
      }
                `

    const response: Promise<OperationResult<{
      GetVehicleTypes: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetManufactureYears = () => {
    const requestData = `
    query GetManufactureYears {
        GetManufactureYears 
      }
                `

    const response: Promise<OperationResult<{
      GetManufactureYears: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetLocalGovAreas = () => {
    const requestData = `
    query GetLocalGovAreas {
        GetLocalGovAreas 
      }
                `

    const response: Promise<OperationResult<{
      GetLocalGovAreas: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetTitles = () => {
    const requestData = `
    query GetTitles {
      GetTitles 
      }
                `

    const response: Promise<OperationResult<{
      GetTitles: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetGenders = () => {
    const requestData = `
    query GetGenders {
        GetGenders 
      }
                `

    const response: Promise<OperationResult<{
      GetGenders: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetCountries = () => {
    const requestData = `
    query GetCountries {
        GetCountries 
      }
                `

    const response: Promise<OperationResult<{
      GetCountries: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetIdentificationTypes = () => {
    const requestData = `
    query GetIdentificationTypes {
        GetIdentificationTypes 
      }
                `

    const response: Promise<OperationResult<{
      GetIdentificationTypes: string[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetVehicleBrands = (manufacture_year: string) => {
    const requestData = `
    query GetVehicleBrands($manufacture_year: String!) {
        GetVehicleBrands(manufacture_year: $manufacture_year)
      }
                `

    const response: Promise<OperationResult<{
      GetVehicleBrands: string[]
    }>> = this.query(requestData, { manufacture_year })

    return response
  }

  public GetVehicleModel = (manufacture_year: string, brand: string) => {
    const requestData = `
    query GetVehicleModel($manufacture_year: String!, $brand: String!) {
        GetVehicleModel(manufacture_year: $manufacture_year, brand: $brand)
      }
                `

    const response: Promise<OperationResult<{
      GetVehicleModel: string[]
    }>> = this.query(requestData, { manufacture_year, brand })

    return response
  }

  public GetUserInsurancePolicy = (insurance_type: string) => {
    const requestData = `
    query GetUserInsurancePolicy($insurance_type: String!) {
        GetUserInsurancePolicy(insurance_type: $insurance_type) {
          id
          uuid
          policy_name
          insurance_type
          customer_id
          policy_purchase_history {
            policy_id
            purchased_on
            activation_date
            start_date
            expiration_date
            price
          }
        }
      }
    `

    const response: Promise<OperationResult<{
      GetUserInsurancePolicy: InsurancePolicy[]
    }>> = this.query(requestData, { insurance_type })

    return response
  }

  public GetUserFlightBookings = (insurance_type: string) => {
    const requestData = `
    query GetUserInsurancePolicy($insurance_type: String!) {
        GetUserInsurancePolicy(insurance_type: $insurance_type) {
          id
          uuid
          policy_name
          insurance_type
          customer_id
          policy_purchase_history {
            policy_id
            purchased_on
            activation_date
            start_date
            expiration_date
            price
          }
        }
      }
    `

    const response: Promise<OperationResult<{
      GetUserFlightBookings: InsurancePolicy[]
    }>> = this.query(requestData, { insurance_type })

    return response
  }
}
