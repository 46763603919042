<template>
  <app-wrapper>
    <subpage-layout :hideTopBar="showConfettiSet" :title="'Reward Info'">
      <div
        class="w-full flex flex-col space-y-3 h-full flex-grow items-center justify-center px-4"
      >
        <div class="w-full flex flex-row items-center justify-center">
          <app-icon :name="'success-check'" :customClass="'h-[134px]'" />
        </div>

        <app-normal-text
          :customClass="'!text-base !font-bold w-[80%] text-center'"
          class="!text-black"
        >
          {{ successMessage }}
        </app-normal-text>

        <div
          class="w-full flex flex-row space-x-2 bg-grey-50 dark:!bg-gray-900 rounded-[10px] items-center justify-center h-[98px]"
        >
          <app-icon :name="'reward-white'" :customClass="'h-[48px]'" />
          <div class="flex flex-col pt-1">
            <app-normal-text class="!font-semibold !text-left !uppercase">
              {{ earningsCopy }}
            </app-normal-text>
            <app-normal-text :customClass="'!text-xl !font-bold'">
              {{
                Logic.Common.convertToMoney(pointGained, false, "", false, "")
              }}
              pts
            </app-normal-text>
          </div>
        </div>

        <template v-if="(SinglePointEarned?.points || 0) > 0">
          <div
            :class="`w-full flex flex-col py-3 px-3 rounded-[12px] ${
              expired
                ? 'bg-secondary-50 items-center justify-center dark:!bg-secondary-main'
                : 'bg-success-50 dark:!bg-success-main'
            }`"
            v-if="
              currentCategory != 'SCANNED_RECEIPT' &&
              processingStatus == 'processed' &&
              !SinglePointEarned?.claimed
            "
          >
            <app-normal-text v-if="!expired">
              <span class="!font-bold">Congratulations! </span> You have earned
              it. <span class="!font-bold">Claim your points</span> now or it
              will expire
              <span class="!font-bold">{{ expiryTime }}</span>
              as a
              <span class="!font-bold">{{
                AuthUser?.profile?.subscription_plan.name
              }}</span>
              subscription member.
            </app-normal-text>
            <app-normal-text v-else>
              Your earned point has expired.
            </app-normal-text>
          </div>

          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
            v-if="
              currentCategory == 'EXTERNAL_BANK_TRANSFER' &&
              processingStatus == 'processing'
            "
          >
            <app-normal-text>
              We are processing your money transfer. This might take
              <span class="!font-bold">5 minutes</span> to
              <span class="!font-bold">24 hours</span> to be completed.
            </app-normal-text>
          </div>

          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
            v-if="
              currentCategory == 'SCANNED_RECEIPT' &&
              processingStatus == 'rejected'
            "
          >
            <app-normal-text>
              Sorry, we couldn't verify your scanned receipt.
            </app-normal-text>
          </div>

          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-secondary-50 dark:!bg-secondary-main !text-center justify-center items-center"
            v-if="
              currentCategory == 'EXTERNAL_BANK_TRANSFER' &&
              processingStatus == 'failed'
            "
          >
            <app-normal-text>
              We couldn't process your money transfer.
            </app-normal-text>
          </div>

          <div
            class="w-full flex flex-col py-3 px-3 rounded-[12px] bg-blue-50 dark:!bg-blue-main"
            v-if="
              currentCategory == 'SCANNED_RECEIPT' && secondsDifference > 10
            "
          >
            <app-normal-text>
              We are
              <span class="!font-bold">verifying and processing</span>
              your receipt. Processing peroid might take up to
              <span class="!font-bold"
                >{{
                  AuthUser?.profile?.subscription_plan.scan_processing_period
                }}
                hours</span
              >
              as a
              <span class="!font-bold">{{
                AuthUser?.profile?.subscription_plan.name
              }}</span>
              subscription member. You can
              <span class="!font-bold">speed up</span> the processing time by
              <span class="!font-bold"> Watching an Ad</span>.
            </app-normal-text>
          </div>

          <!-- Countdown timer -->
          <div
            class="w-full flex flex-row items-center justify-center py-4 pb-2"
            v-if="
              secondsDifference > 60 && currentCategory == 'SCANNED_RECEIPT'
            "
          >
            <app-countdown :secondsTarget="secondsDifference" />
          </div>
        </template>

        <div class="w-full h-[100px]"></div>
      </div>

      <fixed-container>
        <div
          :class="`w-full grid ${
            showConfettiSet &&
            !(
              currentCategory == 'EXTERNAL_BANK_TRANSFER' &&
              SinglePointEarned?.processing_status == 'processing'
            )
              ? 'grid-cols-2'
              : 'grid-cols-2'
          }  gap-3 pt-3`"
        >
          <div class="col-span-1 flex flex-col" v-if="showConfettiSet">
            <app-button
              :padding="'px-6 py-3'"
              :customClass="'w-full'"
              @click="exploreOffers()"
            >
              Explore offers
            </app-button>
          </div>

          <template v-if="currentCategory != 'SCANNED_RECEIPT'">
            <template
              v-if="SinglePointEarned?.processing_status != 'processing'"
            >
              <div
                :class="`col-span-1 flex flex-col ${
                  SinglePointEarned?.claimed || expired ? 'opacity-50' : ''
                }`"
              >
                <app-button
                  :padding="'px-6 py-3'"
                  :border="'border-b-[3px]'"
                  :customClass="'w-full'"
                  :loading="loaderSetup.loading"
                  @click="
                    SinglePointEarned?.claimed || expired
                      ? null
                      : actions.claimed()
                  "
                >
                  Claim Point
                </app-button>
              </div>
              <div class="col-span-1 flex flex-col">
                <app-button
                  :padding="'px-6 py-3'"
                  :customClass="'w-full'"
                  @click="exploreOffers()"
                >
                  Explore offers
                </app-button>
              </div>
            </template>
          </template>
          <template v-else>
            <div
              :class="`col-span-2 flex flex-col ${
                showWatchAdd && !SinglePointEarned?.claimed ? '' : 'opacity-60'
              }`"
            >
              <app-button
                :padding="'px-6 py-3'"
                :border="'border-b-[3px]'"
                :customClass="'w-full !bg-transparent !text-[#5A189A] !border-[#5A189A] border-[1px]'"
                @click="watchAd()"
                :loading="loaderSetup.loading && !showConfettiSet"
              >
                {{
                  showWatchAdd
                    ? `🚀 Boost ${showConfettiSet ? "" : "(Watch an Ad)"}`
                    : `🚀 Boost (${nextAdTime})`
                }}
              </app-button>
            </div>
            <div class="col-span-2 flex flex-col">
              <app-button
                :padding="'px-6 py-3'"
                :customClass="'w-full'"
                @click="exploreOffers()"
              >
                Explore offers
              </app-button>
            </div>
          </template>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppNormalText,
  AppIcon,
  AppButton,
  AppCountdown,
} from "@shpt/ui-components";
import "../../composables/confetti";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import { useRoute } from "vue-router";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { ContentType } from "@shpt/logic/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppButton,
    AppCountdown,
    AppWrapper,
    FixedContainer,
  },
  name: "NewPointEarnedPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "SinglePointEarned",
        method: "GetSinglePointEarned",
        params: [],
        useRouteQuery: true,
        queries: ["point_earned_uuid"],
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "New Point Earned Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "New Point Earned",
    });

    const router = useRoute();

    const routeQuery = ref(router?.query);

    const showTransactionButton = ref(true);

    const showWatchAdd = ref(false);

    const nextAdTime = ref();

    const AuthUser = ref(Logic.Auth.AuthUser);

    const processingStatus = ref("");

    const claimed = ref(false);

    const expiryTime = ref("");
    const expired = ref(false);

    const showConfettiSet = ref(true);
    const SinglePointEarned = ref(Logic.Wallet.SinglePointEarned);

    const actions = reactive({
      continue: () => {
        setStoryContent(
          ContentType.Explore,
          {
            id: 0,
            type: "collection",
          },
          "",
          "/shoplist"
        );
      },
      claimed: () => {
        Logic.Game.ClaimReward(SinglePointEarned.value?.uuid || "").then(
          (data) => {
            if (data) {
              let title = SinglePointEarned.value?.description;

              if (
                title?.toLocaleLowerCase().includes("shoppoint for shoppoint")
              ) {
                title = "Transfer from Shoppoint to Shoppoint Member";
              }

              Logic.Common.showAlert({
                show: true,
                message: "You have successfully claimed your points",
                type: "success",
              });

              Logic.Wallet.GetSinglePointEarned(
                SinglePointEarned.value?.uuid || ""
              );

              actions.continue();
            }
          }
        );
      },
    });

    const secondsDifference = ref(0);

    const earningsCopy = ref("You have earned");

    const successMessage = ref("Purchase successful");
    const pointGained = ref("50");
    const currentCategory = ref("");

    const extraId = ref("");

    const adIsLocked = () => {
      const nextAdWatchTime = localStorage.getItem(
        `ad-next-time-${SinglePointEarned.value?.uuid}`
      );

      if (nextAdWatchTime) {
        const timeLeft = Logic.Common.momentInstance(nextAdWatchTime).diff(
          Logic.Common.momentInstance(new Date()),
          "minute"
        );

        if (timeLeft > 0) {
          return true;
        }
      }
      return false;
    };

    const exploreOffers = () => {
      setStoryContent(
        ContentType.Explore,
        {
          id: 0,
          type: "collection",
        },
        "",
        "/shoplist"
      );
    };

    const setDefaultInfo = () => {
      currentCategory.value = SinglePointEarned.value?.event_slug || "";
      pointGained.value =
        ((SinglePointEarned.value?.game_points || 0) > 0
          ? SinglePointEarned.value?.game_points.toString()
          : SinglePointEarned.value?.points.toString()) || "0";

      extraId.value = SinglePointEarned.value?.entity_uuid || "";
      processingStatus.value = SinglePointEarned.value?.processing_status || "";
      claimed.value = SinglePointEarned.value?.claimed || false;

      secondsDifference.value = Logic.Common.momentInstance(
        SinglePointEarned.value?.processed_at
      ).diff(Logic.Common.momentInstance(new Date()), "seconds");
      showTransactionButton.value = true;

      // actions.continue = () => {
      //   Logic.Common.GoToRoute(`/others/rewards?tab=shop&backRoute=/shoplist`);
      // };

      if (currentCategory.value == "NEW_FLIGHT_BOOKED") {
        successMessage.value = "Flight Booked";

        // actions.continue = () => {
        //   Logic.Common.GoToRoute(`/others/rewards?tab=travel&backRoute=/shoplist`);
        // };
      }

      if (currentCategory.value == "EXTERNAL_BANK_TRANSFER") {
        if (SinglePointEarned.value?.processing_status == "processing") {
          successMessage.value = "Money Transfer Initiated";
        } else if (SinglePointEarned.value?.processing_status == "processed") {
          successMessage.value = "Money Transfer Completed";
        } else {
          successMessage.value = "Money Transfer Failed";
        }

        earningsCopy.value = "You will earn";

        // actions.continue = () => {
        //   Logic.Common.GoToRoute(`/others/rewards?tab=pay&backRoute=/savings`);
        // };
      }
      if (currentCategory.value == "SCANNED_RECEIPT") {
        if (SinglePointEarned.value?.claimed) {
          successMessage.value = "Receipt Point Claimed!";
          earningsCopy.value = "You earned";
        } else {
          successMessage.value = "Receipt Processing Initiated!";
          earningsCopy.value = "You will earn";
        }

        showTransactionButton.value = false;

        // actions.continue = () => {
        //   Logic.Common.GoToRoute(`/others/rewards?tab=scan&backRoute=/shoplist`);
        // };
      }

      if (currentCategory.value == "WORD_TWIST_WON") {
        successMessage.value = "Congratulations! You formed the right word";
        showTransactionButton.value = false;
        earningsCopy.value = "You have won";

        // actions.continue = () => {
        //   Logic.Common.GoToRoute(`/others/rewards?tab=bonus&backRoute=/`);
        // };
      }

      if (currentCategory.value == "SHPT_TO_SHPT_TRANSFER") {
        successMessage.value = "Money Transfer Successful";
        // actions.continue = () => {
        //   Logic.Common.GoToRoute(`/others/rewards?tab=pay&backRoute=/savings`);
        // };
      }

      const expiryMinutesLeft = Logic.Common.momentInstance(
        SinglePointEarned.value?.expire_at
      ).diff(Logic.Common.momentInstance(new Date()), "minute");

      if (expiryMinutesLeft > 0) {
        expired.value = false;
      } else {
        expired.value = true;
      }

      expiryTime.value = `${Logic.Common.momentInstance(
        SinglePointEarned.value?.expire_at
      ).fromNow(false)}`;

      if (adIsLocked()) {
        const nextAdWatchTime = localStorage.getItem(
          `ad-next-time-${SinglePointEarned.value?.uuid}`
        );

        showWatchAdd.value = false;
        nextAdTime.value =
          Logic.Common.momentInstance(nextAdWatchTime).fromNow();
      } else {
        showWatchAdd.value = true;
      }
    };

    const watchAd = () => {
      if (!adIsLocked() && !SinglePointEarned.value?.claimed) {
        Logic.Common.showRewardAd(() => {
          Logic.Game.UpdateProcessingTime(SinglePointEarned.value?.uuid || "");
        });
      }
    };

    onIonViewDidEnter(() => {
      if (routeQuery.value?.trigger_confetti) {
        showConfettiSet.value = true;
      } else {
        showConfettiSet.value = false;
      }
      setDefaultInfo();
    });

    onIonViewWillEnter(() => {
      setTimeout(() => {
        if (routeQuery.value?.trigger_confetti) {
          Logic.Common.showConfetti();
        }
      }, 600);
    });

    watch(SinglePointEarned, () => {
      setDefaultInfo();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("SinglePointEarned", SinglePointEarned);
      if (routeQuery.value?.trigger_confetti) {
        showConfettiSet.value = true;
      } else {
        showConfettiSet.value = false;
      }
      setDefaultInfo();
      setTimeout(() => {
        if (routeQuery.value?.trigger_confetti) {
          Logic.Common.showConfetti();
        }
      }, 600);

      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 1000);
    });

    return {
      Logic,
      successMessage,
      pointGained,
      extraId,
      currentCategory,
      showTransactionButton,
      earningsCopy,
      secondsDifference,
      AuthUser,
      processingStatus,
      actions,
      SinglePointEarned,
      showConfettiSet,
      expiryTime,
      expired,
      showWatchAdd,
      nextAdTime,
      loaderSetup,
      watchAd,
      exploreOffers,
    };
  },
});
</script>
